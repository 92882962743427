import React, { useMemo } from "react";
import styled from "styled-components";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // choose your lib
import moment from "moment";
import FormHelperText from "@mui/material/FormHelperText";
import { TimePicker, LocalizationProvider } from "@mui/lab";

import { format } from "date-fns";

const PickerContainer = styled.div`
  position: relative !important;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

export default function DentTimePicker({
  InputData,
  onChange,
  LabelData,
  icon,
  changed,
}) {
  const time = useMemo(() => {
    if (InputData.value && InputData.value !== "") {
      const dateObj = new Date();
      const dateStr = dateObj.toISOString().split("T").shift();
      return moment(dateStr + " " + InputData.value).toDate();
    } else return moment().startOf("hour");
  }, [InputData.value]);

  return (
    <PickerContainer>
      <InputContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            variant="inline"
            ampm={false}
            autoOk={true}
            label={LabelData.text}
            inputVariant="outlined"
            disabled={InputData.disable}
            inputAdornmentProps={{ position: "start" }}
            value={time}
            onChange={(val) => onChange(format(val, "HH:mm"))}
          />
          <FormHelperText>{LabelData.error}</FormHelperText>
        </LocalizationProvider>
      </InputContainer>
    </PickerContainer>
  );
}
