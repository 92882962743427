import appointments from './appointments.json'
import practiceData from './data.json'

const initialState = {
  appointments: [],
  selectedId: null,
  list: [],
  practiceData,
  practices: [],
}

const escape = str => String(str)
  .replace(/&amp;/g, '')
  .replace(/&lt;/g, '')
  .replace(/&gt;/g, '')
  .replace(/&quot;/g, '')
  .replace(/&apos;/g, '')
  .replace('.', '')

const transformAddresses = list => [...new Set(
  list.map(p => p.Address.split(', ').map(str => escape(str)))
    .reduce((p, c) => p.concat(c)),
)].map((Name, id) => ({ id, Name, ...{ icon: 'fa-map-marker-alt' } }))

const transformDentists = list => list.map(p => ({
  id: p.id, Name: escape(p.Name), ...{ icon: 'fa-building' },
}))

const transformList = list => ([
  ...transformAddresses(list),
  ...transformDentists(list),
])

let selectedPractice = null
let selectedAppointments = null

const search = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_LIST':
      return {
        ...state,
        list: transformList(state.practiceData),
        practiceData: state.practiceData.filter(p => p.Lat)
          .map(p => ({ ...p, Name: escape(p.Name) })),
      }
    case 'LOAD_APPOINTMENTS':
      return {
        ...state,
        appointments,
        practices: state.practiceData.filter(s => s.Address.toLowerCase()
          .includes(action.search.toLowerCase())),
      }
    case 'SELECT_APPOINTMENT':
      selectedPractice = state.practices.find(p => (
        p.DentistId === appointments.find(a => a.id === action.payload).DentistId
      ))
      if (!selectedPractice) return state
      selectedAppointments = appointments.filter(a => a.DentistId === selectedPractice.DentistId)
      return {
        ...state,
        selectedId: action.payload,
        selectedPractice,
        selectedAppointments,
      }
    case 'MARKER_CLICK':
      return {
        ...state,
        selectedPractice: action.payload,
        selectedAppointments: appointments.filter(a => a.DentistId === action.payload.DentistId),
      }
    default:
      return state
  }
}

export default search
