import {
  Button,
  Dialog,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";
import React from "react";

const ConfirmModal = ({
  showModal,
  hideModal,
  continueClick,
  updating = false,
  title = "Are you sure you want to remove this item?",
}) => {
  return (
    <Dialog open={showModal} onClose={hideModal}>
      <DialogContent width="24rem">
        <h2>{title}</h2>
        <DialogActions
          style={{
            display: "grid",
            gridTemplateColumns: "auto auto",
            gridGap: "1em",
          }}
        >
          <Button
            variant="contained"
            style={{
              background: colors.danger,
              color: colors.white,
            }}
            startIcon={!updating && <Delete />}
            onClick={continueClick}
          >
            {updating ? (
              <DoubleBounce size={20} color="white"></DoubleBounce>
            ) : (
              "Yes continue"
            )}
          </Button>
          <Button variant="contained" onClick={hideModal}>
            No Cancel
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmModal;
