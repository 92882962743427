import React, { useReducer, useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { reducer, initialState } from "../../reducer";
import { fetchGraph } from "./actions";
import StandardLayout from "../../../../layouts/standard";
import colors from "../../../../library/styled-components/colors";
import Databox from "./components/DataBox";
import UsersFeaIcon from "../../../../icon/fea-icons/user";
import CalFeaIcon from "../../../../icon/fea-icons/calendar";
import MailFeaIcon from "../../../../icon/fea-icons/mail";
import GlobeFeaIcon from "../../../../icon/fea-icons/globe";
import DoubleBounce from "../../../../components/loaders/double-bounce";
import CloseCircleFeaIcon from "../../../../icon/fea-icons/close-circle";
import BookFeaIcon from "../../../../icon/fea-icons/book";
import { setMonth, setDay, endOfMonth, startOfMonth } from "date-fns";

import {
  LineChart,
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";

const Container = styled.div`
  display: grid;
  margin: 1rem;
  overflow: auto;
  height: calc(100vh - 6rem);
  grid-template-columns: auto auto;
`;

const Tooltip = styled.div`
  background: ${colors.info};
  color: ${colors.white};
  margin: 1px;
  padding: 0.41em;
  border-radius: 0.2em;
  font-size: 1.4em;
  line-height: 16px;
`;

const GraphWrapper = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  font-size: 1.2rem;
  @media (min-width: 1024px) {
    grid-template-rows: repeat(2, minmax(auto, 1fr));
  }
`;

const GraphContainer = styled.div`
  border-radius: 4px;
  font-weight: normal;
  margin-left: 1rem;
  color: ${colors.dark};
  cursor: pointer;
  background-color: ${colors.txtColor};
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  transition: all 0.5s ease;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 0.5rem;
  display: grid;
`;

const DataWrapper = styled.div`
  display: grid;
  height: fit-content;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  font-size: 1rem;
  grid-gap: 1rem;
  margin-bottom: 1rem;
  @media (min-width: 1024px) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    grid-template-columns: repeat(2, minmax(auto, 1fr));
  }
  @media (max-device-width: 481px) {
    grid-template-columns: repeat(1, auto);
  }
`;

export default function Admin({ user }) {
  const history = useHistory();
  const ctrEl = useRef(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [graphData, setGraphState] = useState(null);

  const { list } = state;

  async function navigateTo(pathname, state = false) {
    history.push({
      pathname: pathname,
      state,
    });
  }

  async function loadData() {
    setTimeout(async () => {
      const graph = await fetchGraph()(dispatch, navigateTo);
      setGraphState(graph && graph.data);
    }, 500);
  }

  useEffect(() => {
    loadData();
  }, [ctrEl]);

  const prevMonth = graphData && graphData.months.slice(-2, -1)[0];
  const thisMonth = graphData && graphData.months.slice(-1)[0];

  return (
    <StandardLayout menu={true} openSide={false} meta={list.meta} user={user}>
      <Container ref={ctrEl}>
        {!graphData && <DoubleBounce color={colors.primary} />}
        {graphData && (
          <DataWrapper>
            <Databox
              color="#FF6B6B"
              select={(data) => {
                const mN = new Date(data.month + "-1-01").getMonth() + 2;
                const from = startOfMonth(setMonth(new Date(), mN));
                const to = endOfMonth(setMonth(new Date(), mN));
                navigateTo("/admin/home/target", { from, to, metric: data.id, color: colors[data.id] });
              }}
              data={{
                name: "Cancelled",
                id: "cancelled",
                icon: <CloseCircleFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.canceled,
                prevMonth: prevMonth.canceled,
              }}
            />
            <Databox
              color="#CC7351"
              data={{
                name: "Failed to Attend",
                icon: <BookFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.failed,
                prevMonth: prevMonth.failed,
              }}
            />
            <Databox
              color="#3598dc"
              data={{
                name: "New Appointments",
                icon: <CalFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.appointments,
                prevMonth: prevMonth.appointments,
              }}
            />
            <Databox
              color="#8E44AD"
              data={{
                name: "New Patients",
                icon: <UsersFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.patients,
                prevMonth: prevMonth.patients,
              }}
            />
            <Databox
              color="#6B7AA1"
              data={{
                name: "Sent Sms",
                icon: <MailFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.sms,
                prevMonth: prevMonth.sms,
              }}
            />
            <Databox
              color="#5FC29D"
              data={{
                name: "Online Bookings",
                icon: <GlobeFeaIcon size="32" />,
                month: prevMonth.label,
                thisMonth: thisMonth.slots,
                prevMonth: prevMonth.slots,
              }}
            />
          </DataWrapper>
        )}
        {graphData && (
          <GraphWrapper>
            <GraphContainer>
              <ResponsiveContainer width="100%" height="100%">
                <LineChart data={graphData.months}>
                  <XAxis dataKey="name" />
                  <YAxis />
                  <CartesianGrid strokeDasharray="2 2" />
                  <Tooltip />
                  <Legend />
                  <Line
                    type="monotone"
                    dataKey="patients"
                    stroke="#e83e8c"
                    strokeWidth="2"
                    activeDot={{ r: 8 }}
                  />
                  <Line
                    type="monotone"
                    dataKey="appointments"
                    stroke="#007bff"
                    strokeWidth="2"
                  />
                </LineChart>
              </ResponsiveContainer>
            </GraphContainer>

            <GraphContainer>
              <ResponsiveContainer width="100%" height="100%">
                <BarChart data={graphData.months}>
                  <CartesianGrid strokeDasharray="2 2" />
                  <XAxis dataKey="name" />
                  <YAxis
                    type="number"
                    domain={[0, "dataMax + 9"]}
                    allowDataOverflow={true}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="sms" stroke="black" fill="#E67D21" />
                  <Bar dataKey="slots" stroke="black" fill="#5FC29D" />
                </BarChart>
              </ResponsiveContainer>
            </GraphContainer>
          </GraphWrapper>
        )}
      </Container>
    </StandardLayout>
  );
}
