import styled, { css } from "styled-components";
import { FadeDiv } from "../../library/styled-components";
import {
  btnPrimary,
  btnPrimaryInvert,
  btnSecondary,
  btnWarning,
  btnDanger,
  btn,
} from "../../library/styled-components/buttons";
import colors from "../../library/styled-components/colors";
import MoreIcon from "../../icon/fea-icons/more-vertical";

export const NotesContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 40rem;
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -2rem;
`;

export const Notes = styled.div`
  border-radius: 3px;
  background: white;
  overflow: auto;
  flex: 1 0 auto;
  border: 1px solid ${colors.rgbGreyLight};
  height: 20rem;
  margin-bottom: 1rem;
`;

export const NoteHeader = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-style: italic;
`;

export const NoteName = styled.div`
  margin-right: 0.4rem;
  font-weight: bold;
  font-size: 0.8rem;
`;

export const NoteDate = styled.div`
  color: ${colors.primary};
  font-weight: bold;
  text-align: left;
  font-size: 0.8rem;
`;

export const NoteText = styled.div`
  border-top: 1px solid ${colors.rgbGreyLight};
  padding: 1rem 0;
  white-space: pre-wrap;
`;

export const Note = styled.div`
  text-align: left;
  padding: 0.2rem 1rem;
  margin: 0.1rem 0.1rem 0.4rem 0.1rem;
  border: 1px solid ${colors.rgbGreyLight};
  background-color: ${colors.veryLightYellow};
  border-radius: 3px;
  min-width: 4rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2) !important;
  style= {
     {
      color: colors.veryLightYellow;
    }
  }
`;

export const MoreCont = styled.div`
  position: absolute;
  right: 1rem;
  z-index: 2;
  top: 2.2rem;
`;

export const MoreIconRight = styled(MoreIcon)`
  cursor: pointer;
  border-radius: 50%;
  color: ${colors.rgbGrey};
  &:hover {
    color: ${colors.primary};
    background-color: ${colors.light};
  }
`;

export const StyledIconButtonWrapper = styled.div`
  position: fixed;
  bottom: 0;
  border-radius: 50%;
  margin: 0.5rem;
  z-index: 101;
  background: ${colors.primary};
  &:hover {
    opacity: 0.8;
  }
`;

export const InputWrap = styled.div`
  width: 6rem;
  input {
    text-align: end;
    ${(props) => css`
      color: ${colors[props.color]};
    `}
  }
`;

export const Container = styled.div`
  display: grid;
  width: 100%;
  justify-items: center;
`;

export const ActionContainer = styled.div`
  display: grid;
`;

export const DateWrap = styled.div`
  margin: 1rem 1rem 1rem 0rem;
  display: grid;
  background: white;
`;

export const IconButton = styled.button`
  outline: none;
  position: relative !important;
  font-size: 0.9rem;
  line-height: 1.2rem;
  border: 1px solid ${colors.info};
  background-color: ${(props) => (!props.val ? colors.txtColor : colors.info)};
  cursor: pointer;
  align-self: center;
  margin: 0.5em;
  padding: 0.5em;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  color: ${(props) => (!props.val ? colors.info : colors.txtColor)};
  svg {
    color: ${(props) => (!props.val ? colors.info : colors.txtColor)};
  }
  &:disabled {
    background-color: ${colors.light};
    border: 1px solid ${colors.rgbGrey};
    color: ${colors.rgbGrey};
    cursor: not-allowed;
    svg {
      color: ${colors.rgbGrey};
    }
  }
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &.focus {
    background-color: ${colors.info};
    color: ${colors.txtColor};
    svg {
      color: ${colors.txtColor};
    }
    filter: brightness(110%);
    &:disabled {
      background-color: ${colors.light};
      border: 1px solid ${colors.rgbGrey};
      color: ${colors.rgbGrey};
      filter: brightness(100%);
      svg {
        color: ${colors.rgbGrey};
      }
    }
  }
`;

export const Logo = styled.img`
  align-self: center;
  justify-self: center;
`;

export const Link = styled.div`
  color: ${colors.primColor};
  display: block;
  text-decoration: underline;
  text-align: center;
  font-size: ${(props) => props.size}em;
  margin: 0.4em 0;
  cursor: pointer;
  :hover {
    color: ${colors.accentColor};
  }
`;

export const LinkButton = styled.button`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: ${(props) => props.size}em;
  height: 3rem;
  padding: 0em 1em;
  background-color: ${colors.txtColor};
  border: 1px solid ${colors.txtColor};
  color: ${colors.primColor};
`;

export const SubmitBtn = styled(btnPrimary)`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: ${(props) => props.fontSize || "1.2rem"};
  padding: 12px;
  height: ${(props) => props.height || "3rem"};
  ${(props) => (props.center ? "justify-self: center;" : "")}
  align-self: center;
`;

export const WarnSubmitBtn = styled(btnWarning)`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: 1.2rem;
  padding: 12px;
  height: 3rem;
  ${(props) => (props.center ? "justify-self: center;" : "")}
  align-self: center;
`;

export const BtnContainer = styled.div`
  display: flex;
  margin-top: 0.25rem;
  button {
    margin-right: 1rem;
  }
`;

export const BtnMarginContainer = styled.div`
  ${(props) =>
    props.row
      ? css`
          display: flex;
          button {
            margin: 0.25rem;
          }
          flex-wrap: wrap;
        `
      : css`
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-gap: 0.25rem;
          button {
            width: 100%;
          }
        `}
`;

export const DynamicIconBtn = styled(btn)`
  position: relative !important;
  outline: none;
  height: 2.2rem;
  cursor: ${(props) => (!props.disabled ? "pointer" : "not-allowed")};
  width: auto;
  background-color: ${(props) =>
    props.disabled || props.marked
      ? colors.lightGrey
      : props.color || colors.primary};
  border-color: ${(props) => props.color || colors.primary};
  border: 1px solid
    ${(props) =>
      props.disabled || props.marked
        ? colors.lightGrey
        : props.color || colors.primary};
  color: ${(props) =>
    props.disabled || props.marked
      ? colors.dark
      : props.textColor || colors.dark};
  text-align: center;
  padding-left: 2rem;

  ${(props) =>
    props.fontSize ? `font-size:${props.fontSize}` : `font-size: 0.7rem`}
  ${(props) => (props.center ? "justify-self: center;" : "")}
  align-self: center;
  :hover {
    filter: brightness(110%);
  }
`;

export const DangerSubmitBtn = styled(btnDanger)`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: 1.2rem;
  padding: 12px;
  height: 3rem;
  ${(props) => (props.center ? "justify-self: center;" : "")}
  align-self: center;
  min-width: 10rem;
`;

export const SubmitIconBtn = styled(SubmitBtn)`
  display: grid;
  grid-template-columns: auto 1em;
`;

export const WarnIconBtn = styled(WarnSubmitBtn)`
  display: grid;
  grid-template-columns: auto 1em;
`;

export const FormBtn = styled(btnSecondary)`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  padding: 12px;
  font-size: 1rem;
  text-align: center;
  height: 3rem;
  ${(props) =>
    props.center
      ? css`
          justify-self: center;
          align-self: center;
        `
      : ""}
`;

export const AltSubmitBtn = styled(btnPrimaryInvert)`
  outline: none;
  cursor: pointer;
  display: grid;
  width: auto;
  text-align: center;
  font-size: ${(props) => props.fontSize || "1.2rem"};
  padding: 12px;
  height: ${(props) => props.height || "3rem"};
  ${(props) => (props.center ? "justify-self: center;" : "")}
  align-self: center;
  min-width: 10rem;
`;

export const CheckBoxButton = styled.button`
  outline: none;
  font-size: 0.9rem;
  line-height: 1.2rem;
  border: 1px solid ${colors.info};
  background-color: ${(props) => (!props.val ? colors.white : colors.info)};
  cursor: pointer;
  align-self: center;
  margin-top: 0;
  padding: 11px 25px;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => (!props.val ? colors.info : colors.white)};
  svg {
    color: ${(props) => (!props.val ? colors.info : colors.white)};
  }
`;

export const FormContainer = styled.div`
  display: grid;
  padding: 1rem;
  font-size: 0.9em;
  text-align: left;
  min-width: 12rem;
  background: white;
`;

export const FilterBlock = styled.div`
  margin-bottom: 1rem;
  border: 1px dashed ${colors.rgbGrey};
  border-radius: 0.3rem;
  height: 3rem;
  overflow: auto;
  div {
    margin: 0.2rem;
  }
`;

export const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

export const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;

export const FormIconBtn = styled.button`
  outline: none;
  font-size: 0.9rem;
  line-height: 1.2rem;
  border: 1px solid ${colors.info};
  background-color: ${colors.white};
  cursor: pointer;
  align-self: center;
  margin-top: 0;
  padding: 11px 25px;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  color: ${colors.info};
  svg {
    color: ${colors.info};
  }
  :hover {
    background-color: ${colors.info};
    color: ${colors.white};
    svg {
      color: ${colors.white};
    }
  }
`;

const Alert = styled.div`
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 16px;
  text-align: center;
`;

export const Warning = styled(Alert)`
  background-color: ${colors.warning};
  color: #ffffff;
  border-color: ${colors.warning};
`;

export const Danger = styled(Alert)`
  background-color: ${colors.danger};
  color: #ffffff;
  border-color: ${colors.danger};
`;

const Message = styled(FadeDiv)`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 1em;
  padding: 8px 15px;
  border-radius: 3px;
  font-size: 16px;
  text-align: left;
`;

export const InfoMessage = styled(Message)`
  cursor: pointer;
  background-color: ${colors.rgbInfo};
  color: #ffffff;
  z-index: 103;
  p {
    color: #ffffff !important;
    padding-top: 1em;
  }
  &:hover {
    background-color: ${colors.info};
    border-color: ${colors.info};
    filter: brightness(110%);
  }
  border-color: ${colors.rgbInfo};
`;

export const WarningMessage = styled(Message)`
  cursor: pointer;
  background-color: ${colors.warning};
  color: #ffffff;
  z-index: 103;
  p {
    color: #ffffff !important;
    padding-top: 1em;
  }
  &:hover {
    background-color: ${colors.warning};
    border-color: ${colors.warning};
    filter: brightness(110%);
  }
  border-color: ${colors.warning};
`;

/* Booking Slot Styles */
export const SlotsContainer = styled.div`
  display: grid;
  width: 100%;
  @media (min-width: 481px) {
    justify-content: stretch;
    width: 481px;
  }

  @media (max-width: 481px) {
    justify-content: stretch;
    overflow: auto;
    max-height: calc(100vh - ${(props) => props.bottom || "10em"});
  }
`;

export const SlotsContainerNoOverflow = styled.div`
  display: grid;
  width: 100%;
  @media (min-width: 481px) {
    justify-content: stretch;
    width: 481px;
  }

  @media (max-width: 481px) {
    justify-content: stretch;
    overflow: auto;
  }
`;

export const EmptySlots = styled.div`
  display: grid;
  position: relative;
  text-align: center;
  border-radius: 3px;
  color: ${(props) => props.color};
  padding: 10px 0;
  font-size: 1.2rem;
  padding: 1rem;
`;

export const Slot = styled.button`
  position: relative;
  text-decoration: none;
  margin: 1em 1em 0em 1em;
  display: grid;
  outline: none;
  border-radius: 3px !important;
  grid-template-rows: auto 3rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  pointer-events: all;
  border: 1px solid ${colors.muted};
  color: ${colors.dark};
  background: ${colors.txtColor};
  transition: all 0.5s ease;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  :hover {
    color: ${colors.accentColor};
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background: ${colors.primLight};
    border: 1px solid ${colors.primary};
  }
  ${(props) =>
    props.active &&
    css`
      color: ${colors.accentColor};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      background: ${colors.primLight};
      border: 1px solid ${colors.primary};
    `}

  svg {
    margin: auto;
  }
  label {
    padding: 1 rem;
  }
  cursor: pointer;
`;

export const SlotDiv = styled.div`
  position: relative;
  text-decoration: none;
  margin: 1em 1em 0em 1em;
  display: grid;
  outline: none;
  border-radius: 3px !important;
  grid-template-rows: auto 3rem;
  justify-items: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  font-size: 1.2rem;
  text-align: center;
  pointer-events: all;
  border: 1px solid ${colors.muted};
  color: ${colors.dark};
  background: ${colors.txtColor};
  transition: all 0.5s ease;
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15) !important;
  :hover {
    color: ${colors.accentColor};
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
    background: ${colors.primLight};
    border: 1px solid ${colors.primary};
  }
  ${(props) =>
    props.active &&
    css`
      color: ${colors.accentColor};
      box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
      background: ${colors.primLight};
      border: 1px solid ${colors.primary};
    `}

  svg {
    margin: auto;
  }
  label {
    padding: 1 rem;
  }
  cursor: pointer;
`;

export const SlotItems = styled.div`
  display: grid;
  grid-template-columns: 2fr 8fr 2fr;
  align-items: center;
  font-size: 1.2rem;
  margin-bottom: 1em;
`;

export const TimeCont = styled.div`
  font-size: 0.8rem;
  line-height: 0.8rem;
`;

export const ModalBox = styled.div`
  position: fixed;
  background-color: ${colors.rgbGreyLight};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 103;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s;
  padding: 1em;
  ${(props) =>
    props.show &&
    css`
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    `}

  header {
    font-weight: bold;
  }
  h1 {
    font-size: 150%;
    margin: 0 0 15px;
  }
`;

export const ModalContent = styled.div`
  position: absolute;
  overflow: auto;
  box-shadow: 0 0 3px ${colors.dark} !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: #ffffff;
  text-align: center;
  width: ${(props) => props.width || "auto"};
  height: ${(props) => props.height || "auto"};
  background-color: ${(props) => props.backgroundColor || "none"};
`;

export const PopToSide = styled.div`
  width: fit-content;
  position: absolute;
  top: ${(props) => props.top || "12rem"};
  left: ${(props) => props.left || "100%"};
  border-radius: 3px;
  z-index: 104;
  border: 1px solid rgba(60, 72, 88, 0.8);
  background-color: ${colors.white};
  margin: 0 0.2rem;
  -webkit-box-shadow: 0 0 4px rgba(60, 72, 88, 0.8);
  box-shadow: 0 0 4px rgba(60, 72, 88, 0.8);

  &::after {
    position: absolute;
    top: 9rem;
    left: -1rem;
    content: "";
    width: 0;
    height: 0;
    border-right: solid 1rem rgba(60, 72, 88, 0.8);
    border-bottom: solid 1rem transparent;
    border-top: solid 1rem transparent;
  }
`;

export const PopToSideTop = styled.div`
  width: fit-content;
  position: absolute;
  top: ${(props) => (props.pos ? `${props.pos.y}px` : props.top || "12rem")};
  left: ${(props) =>
    props.pos ? `${props.pos.x}px` : (!props.right && props.left) || "none"};
  right: ${(props) => (!props.left && props.right) || "none"};
  border-radius: 3px;
  z-index: 109;
  border: 1px solid ${colors.primLight};
  background-color: ${colors.primLight};
  margin: 0 0.2rem;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);

  &::after {
    position: absolute;
    top: 2rem;
    left: -1rem;
    content: "";
    width: 0;
    height: 0;
    border-right: solid 1rem ${colors.primLight};
    border-bottom: solid 1rem transparent;
    border-top: solid 1rem transparent;
  }
`;

export const PopBottom = styled.div`
  width: fit-content;
  position: fixed;
  right: ${(props) => props.right || "0"};
  bottom: ${(props) => props.bottom || "0"};
  border-radius: 3px;
  z-index: 109;
  border: 1px solid ${colors.grey};
  background-color: ${colors.white};
  margin: 0 0.2rem;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
`;

export const DynamicPopOut = styled.div`
  width: fit-content;
  position: fixed;
  top: ${(props) => (props.pos ? `${props.pos.y}px` : props.top || "0")};
  left: ${(props) => (props.pos ? `${props.pos.x}px` : "")};

  border-radius: 3px;
  z-index: 105;
  border: 1px solid ${colors.primLight};
  background-color: ${colors.primLight};
  margin: 0 0.2rem;
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
`;

export const WrapIcon = styled.div`
  position: absolute;
  cursor: pointer;
  right: 2rem;
  top: 1rem;
  z-index: 102;
  color: ${colors.lightGrey};
  svg {
    background: ${colors.primary};
    margin: 0;
    padding: 0;
    border-radius: 50%;
  }
`;

export const PosIcon = styled.span`
  position: absolute;
  left: 0.5rem;
  top: 0.4rem;
`;

export const CalendarStyles = styled.div`
  pointer-events: auto;
  .dashed-event-border {
      border-style: dashed !important;
    }

    .solid-event-border {
      border-style: solid !important;
    }
  .split {
    display: grid;
  }
  .fc-event {
    max-width: 80%;
  }
  .calendar {


    .fc-col-header {
    }
    .fc-theme-standard tbody td {
      border-color: ${colors.grey};

      font-size: 1.2rem;
      &.fc-timegrid-slot {
        height: 2.8rem;
      }
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
      height: 3rem;
      font-size: 0.9rem;
    }

    .fc .fc-timegrid-event {
      border-width: 2px;
      overflow: hidden;
    }

    .fc-button-group {
      align-items: center;
      display: flex;
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button-group button {
      display: flex;
      background-color: ${(props) => props.color};
      color: ${(props) => (!!props.color ? colors.white : colors.white)};
      :hover {
        ${(props) =>
          props.color &&
          css`
            filter: brightness(120%);
          `}
      }
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button-group button.fc-button-active {
      ${(props) =>
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-rangeButton-button {
      ${(props) =>
        props.showDateChanger &&
        !props.color &&
        css`
          background-color: #151e27;
        `}
      ${(props) =>
        props.showDateChanger &&
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
      background-color: #2c3e50;
      height: 3rem;
      font-size: 0.9rem;
      background-color: ${(props) => props.color};
      color: ${(props) => (!!props.color ? colors.dark : colors.white)};
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
      background-color: ${(props) => props.color};
      color: ${colors.white};
      font-size: 0.9rem;
      font-weight: 300;
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-filterButton-button {
      width: 12rem;
      ${(props) =>
        props.showSelect &&
        !props.color &&
        css`
          background-color: #151e27;
        `}
      ${(props) =>
        props.showSelect &&
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button {
      border-radius: 0;
      outline: none;
      border-style: none !important;
    }
    .fc .fc-non-business {
      background: rgba(215, 215, 215, 0.5);
    }

    .out-of-range {
      background: rgb(226, 226, 226, 0.9);
    }

    .out-of-range-secondary {
      background: rgba(90, 109, 144, 0.9);
    }

    .fc-media-screen .fc-timegrid-event {
      cursor: pointer;
    }
  }
`;

export const PreviewCalendarStyles = styled.div`
  .preview {
    .fc-col-header {
      background-color: ${colors.grey};
    }
    .fc-theme-standard td {
      border-color: ${colors.grey};
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
      height: 3rem;
      font-size: 1.2rem;
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button-group button {
      background-color: ${(props) => props.color};
      color: ${(props) => (!!props.color ? colors.white : colors.white)};
      :hover {
        ${(props) =>
          props.color &&
          css`
            filter: brightness(120%);
          `}
      }
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button-group button.fc-button-active {
      ${(props) =>
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-rangeButton-button {
      ${(props) =>
        props.showDateChanger &&
        !props.color &&
        css`
          background-color: #151e27;
        `}
      ${(props) =>
        props.showDateChanger &&
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-toolbar.fc-header-toolbar {
      margin: 0;
      background-color: #2c3e50;
      height: 3rem;
      font-size: 1.2rem;
      background-color: ${(props) => props.color};
      color: ${(props) => (!!props.color ? colors.dark : colors.white)};
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
      background-color: ${(props) => props.color};
      color: ${colors.white};
      font-size: 1.2rem;
      font-weight: 300;
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-filterButton-button {
      width: 12rem;
      ${(props) =>
        props.showSelect &&
        !props.color &&
        css`
          background-color: #151e27;
        `}
      ${(props) =>
        props.showSelect &&
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }

    .fc .fc-toolbar.fc-header-toolbar .fc-button {
      background-color: ${(props) => props.color};
      border-radius: 0;
      outline: none;
      border-style: none !important;
    }

    .out-of-range {
      background: rgb(226, 226, 226, 0.4);
    }

    .out-of-range-secondary {
      background: rgba(90, 109, 144, 0.4);
    }

    .fc-media-screen .fc-timegrid-event {
      cursor: pointer;
    }
  }
`;

export const BlockWrap = styled.div`
  display: grid;
`;

export const AddWrapIcon = styled.div`
  position: relative;
  cursor: pointer;
  color: ${colors.rgbGreyLight};
  border: 0;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  line-height: 2rem;
  font-weight: 900;

  :hover {
    color: ${colors.primary};
  }
  svg {
    margin: 0.25rem;
  }
`;

export const CloseWrapIcon = styled.div`
  position: relative;
  cursor: pointer;
  color: ${colors.rgbGreyLight};
  border: 0;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  line-height: 2rem;
  line-height: 2rem;
  font-weight: 900;

  :hover {
    color: ${colors.danger};
  }
  svg {
    margin: 0.25rem;
  }
`;

export const BoxWrap = styled.div`
  display: inline-flex;
  min-width: auto;
`;

export const ClickBox = styled.div`
  border-radius: 2px;
  cursor: pointer;
  background: ${(props) =>
    props.disabled
      ? colors.white
      : props.active
      ? colors[props.variant]
      : colors.lightGrey};
  &:hover {
    background: ${(props) => colors[props.variant]};
    opacity: 0.4;
  }
  width: 12px;
  height: 12px;
  margin: 1px;
  text-align: center;
`;

export const PreviewContainer = styled.div`
  display: grid;
  height: 80vh;
  position: relative;
`;
