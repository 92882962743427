import React from "react";
import * as Joi from "@hapi/joi";
import colors from "../../../../library/styled-components/colors";
import UserFeaIcon from "../../../../icon/fea-icons/user";
import ClockFeaIcon from "../../../../icon/fea-icons/clock";
import ClipBoardFeaIcon from "../../../../icon/fea-icons/clipboard";
import FileFeaIcon from "../../../../icon/fea-icons/file-text";
import ListFeaIcon from "../../../../icon/fea-icons/list";
import EuroFeaIcon from "../../../../icon/fea-icons/euro";
import CreditCardFeaIcon from "../../../../icon/fea-icons/credit-card";
import ReactCountryFlag from "react-country-flag";
import MailFeaIcon from "../../../../icon/fea-icons/mail";

const validation = {
  Name: Joi.string().required(),
  Description: Joi.string().required(),
  Information: Joi.string().required(),
  Comment: Joi.string().required(),
  Price: Joi.number().required(),
  Phone: Joi.number().min(8).required(),
  Email: Joi.string()
    .min(5)
    .max(255)
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    }),
  Amount: Joi.number().required(),
  Method: Joi.string().required(),
  Reason: Joi.string().required(),
  Date: Joi.date().required(),
  Start: Joi.string()
    .regex(/^([0-9]{2})\:([0-9]{2})$/)
    .required(),
  End: Joi.string()
    .regex(/^([0-9]{2})\:([0-9]{2})$/)
    .required(),
  Patients: Joi.string().min(1).max(255).required(),
  Practitioners: Joi.string().min(1).max(255).required(),
  Treatments: Joi.string().min(1).max(255).required(),
};

export const appointmentFormSchema = {
  Date: {
    key: 1,
    domRef: null,
    type: "date-picker",
    text: "Start",
    val: null,
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
    validation: Joi.object({ Date: validation.Date }),
    call: "change-date",
  },
  Start: {
    key: 2,
    domRef: null,
    type: "time-picker",
    text: "Start",
    val: null,
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
    validation: Joi.object({ Start: validation.Start }),
  },
  End: {
    key: 3,
    domRef: null,
    type: "time-picker",
    text: "End",
    val: null,
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
    validation: Joi.object({ End: validation.End }),
  },
  Patients: {
    key: 4,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Patients",
    type: "select",
    options: [],
    defaultValue: "Choose",
    call: "select-patient",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Patients: validation.Patients }),
  },
  Practitioners: {
    key: 5,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Practitioners",
    placeholder: "Select Practitioner",
    type: "select",
    options: [],
    defaultValue: "Choose",
    call: "select-practitioner",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Practitioners: validation.Practitioners }),
  },
  Treatments: {
    key: 6,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Treatment",
    type: "select",
    options: [],
    defaultValue: "Choose Treatment",
    call: "select-treatment",
    icon: <ClipBoardFeaIcon size="16" />,
    validation: Joi.object({ Treatments: validation.Treatments }),
  },
};

export const treatmentFormSchema = {
  Name: {
    key: 1,
    domRef: null,
    type: "text",
    val: null,
    text: "Name",
    placeholder: "treatment name ...",
    icon: <ClipBoardFeaIcon size="16" />,
    validation: Joi.object({ Name: validation.Name }),
  },
  Description: {
    key: 2,
    domRef: null,
    type: "textarea",
    text: "Description",
    placeholder: "description ...",
    val: null,
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
    validation: Joi.object({ Description: validation.Description }),
  },
  Price: {
    key: 3,
    domRef: null,
    type: "number",
    text: "Price",
    placeholder: "€ Price",
    val: null,
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
    validation: Joi.object({ Price: validation.Price }),
  },
};

export const patientFormSchema = {
  Name: {
    key: 1,
    domRef: null,
    type: "text",
    val: "",
    text: "Name",
    placeholder: "Please enter patient name",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Name: validation.Name }),
  },
  Phone: {
    key: 6,
    domRef: null,
    tel: true,
    text: "Phone",
    type: "tel",
    val: "",
    placeholder: "Please enter patient phone Number",
    icon: (
      <ReactCountryFlag
        aria-label="+353"
        countryCode="IE"
        svg
        style={{
          width: "1.6em",
        }}
      />
    ),
    validation: Joi.object({ Phone: validation.Phone }),
  },
  Email: {
    key: 7,
    domRef: null,
    email: true,
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter patients Email",
    icon: <MailFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email }),
  },
};

export const cancelFormSchema = {
  Reason: {
    key: 1,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Reason",
    type: "select",
    options: [
      { key: 1, name: "Forgot", value: "FORGOT" },
      { key: 2, name: "Emergency", value: "EMERGENCY" },
      { key: 3, name: "Did not Attend", value: "FAILED" },
      { key: 4, name: "Illness", value: "SICK" },
      { key: 5, name: "Work", value: "WORK" },
      { key: 6, name: "Personal reasons", value: "PERSONAL" },
    ],
    defaultValue: "Reason",
    icon: <ListFeaIcon size="16" />,
    validation: Joi.object({ Reason: validation.Reason }),
  },
  Information: {
    key: 2,
    domRef: null,
    type: "textarea",
    text: "Additional Info",
    placeholder: "Additional Info",
    val: null,
    disable: false,
    icon: <FileFeaIcon size="16" />,
    validation: Joi.object({ Information: validation.Information }),
  },
};

export const commentFormSchema = {
  Comment: {
    key: 2,
    domRef: null,
    type: "textarea",
    text: "Comment",
    placeholder: "Add a comment",
    val: null,
    disable: false,
    icon: <FileFeaIcon size="16" />,
    validation: Joi.object({ Comment: validation.Comment }),
  },
};

export const paymentFormSchema = {
  Method: {
    key: 1,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Method",
    type: "select",
    options: [
      { key: 1, name: "Cash", value: "CASH" },
      { key: 2, name: "Credit Card", value: "CREDIT" },
      { key: 3, name: "Debit Card", value: "DEBIT" },
      { key: 4, name: "Invoice", value: "INVOICE" },
      { key: 5, name: "Practice Plan", value: "PLAN" },
      { key: 6, name: "Cheque", value: "CHEQUE" },
      { key: 7, name: "Refund", value: "REFUND" },
    ],
    defaultValue: "Cash",
    icon: <CreditCardFeaIcon size="16" />,
    validation: Joi.object({ Method: validation.Method }),
  },
  Amount: {
    key: 2,
    domRef: null,
    type: "number",
    text: "Amount",
    placeholder: "Amount",
    val: null,
    disable: true,
    icon: <EuroFeaIcon size="16" />,
    validation: Joi.object({ Amount: validation.Amount }),
  },
};

export const createButtons = [
  {
    text: "Save",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const appointmentCreateButtons = [
  {
    text: "Save",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const appointmentUpdateButtons = [
  {
    text: "Update",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];
