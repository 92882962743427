import React, { useEffect, useState } from "react";
import {
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
  Popover,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import { Payment } from "@mui/icons-material";
import { AppContext } from "hooks/context";
import { useContext } from "react";
import styled from "styled-components";
import { Alert } from "@mui/lab";

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
  width: 18rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

const CreatePayment = ({
  selectedPractitionerId,
  selectedPatient,
  createPayment,
}) => {
  const { state } = useContext(AppContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = useState(false);
  const openPop = Boolean(anchorEl);
  const [warning, setWarning] = useState(null);
  const popId = openPop ? "simple-popover" : undefined;

  const practitioners = state.list;

  const [form, setForm] = useState({
    amount: null,
    method: "CREDIT",
    currency: "EUR",
    patientId: selectedPatient?.PatientId,
    practitionerId:
      selectedPractitionerId || practitioners?.data[0]?.data.PractitionerId,
  });

  useEffect(() => {
    setForm({
      ...form,
      practitionerId:
        selectedPractitionerId || practitioners?.data[0]?.data.PractitionerId,
      patientId: selectedPatient?.PatientId,
    });
  }, [practitioners, selectedPatient]);

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="small"
        startIcon={<Payment />}
        onClick={handlePopClick}
      >
        Add Payment
      </Button>
      <Popover
        id={popId}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <FormContainer>
          <h4>Add a Payment</h4>
          <FormControl variant="outlined">
            <InputContainer>
              <InputLabel>Practitioner</InputLabel>
              {practitioners?.data.length && form.practitionerId && (
                <Select
                  disabled={loading}
                  value={form.practitionerId}
                  defaultValue={form.practitionerId}
                  onChange={(e) => {
                    setForm({ ...form, practitionerId: e.target.value });
                  }}
                  label="Practitioner"
                >
                  {practitioners.data.map((p) => (
                    <MenuItem
                      key={p.data.PractitionerId}
                      value={p.data.PractitionerId}
                    >
                      {p.data.Name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </InputContainer>
          </FormControl>
          <FormControl variant="outlined">
            <InputContainer>
              <InputLabel>Payment Types</InputLabel>

              <Select
                disabled={loading}
                value={form.method}
                defaultValue={form.method}
                onChange={(e) => {
                  setForm({ ...form, method: e.target.value });
                }}
                label="Payment Type"
              >
                <MenuItem value={"CASH"}>Cash</MenuItem>
                <MenuItem value={"CREDIT"}>Credit Card</MenuItem>
                <MenuItem value={"DEBIT"}>Debit Card</MenuItem>
                <MenuItem value={"INVOICE"}>Invoice</MenuItem>
                <MenuItem value={"PLAN"}>Practice Plan</MenuItem>
                <MenuItem value={"CHEQUE"}>Cheque</MenuItem>
                <MenuItem value={"REFUND"}>Refund</MenuItem>
              </Select>
            </InputContainer>
          </FormControl>

          <FormControl fullWidth variant="outlined">
            <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
            <OutlinedInput
              id="outlined-adornment-amount"
              value={form.amount}
              onChange={(e) => {
                setForm({ ...form, amount: e.target.value });
              }}
              startAdornment={
                <InputAdornment position="start">€</InputAdornment>
              }
              labelWidth={60}
            />
          </FormControl>
          {warning && (
            <Alert style={{ marginTop: "1rem" }} severity="warning">
              {warning}
            </Alert>
          )}
          <Row>
            <FormControl>
              <Button
                color="primary"
                onClick={async (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (!form.amount) {
                    setWarning("Please enter an amount");
                    return;
                  }
                  // check is decimal with a precision of 2
                  if (!/^\d+(\.\d{1,2})?$/.test(form.amount)) {
                    setWarning("Please enter a valid amount");
                    return;
                  }

                  setLoading(true);
                  await createPayment({
                    ...form,
                    amount:
                      form.method === "REFUND" ? -form.amount : form.amount,
                  });
                  setForm({
                    ...form,
                    amount: null,
                  });
                  setWarning(null);
                  setLoading(false);
                  setAnchorEl(null);
                }}
              >
                Create
              </Button>
            </FormControl>
            <FormControl>
              <Button
                onClick={() => {
                  setAnchorEl(null);
                  setForm({
                    ...form,
                    amount: null,
                  });
                }}
              >
                Cancel
              </Button>
            </FormControl>
          </Row>
        </FormContainer>
      </Popover>
    </>
  );
};

export default CreatePayment;
