import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { format } from "date-fns";
import colors from "../../library/styled-components/colors";
import { ADMIN_NS } from "../../config";
import { getPatientSmsFeed, marekAsRead, sendSms } from "../../actions/sms";
import DoubleBounce from "../loaders/double-bounce";
import Typography from "@mui/material/Typography";
import DropdownSearch from "../dropdown-search/sms-list";
import { AppContext } from "../../hooks/context";
import { Badge, IconButton, Popover } from "@mui/material";
import BottomToolBar from "./ToolBar";
import { AppBar, Toolbar } from "@mui/material";
import { StyledIconButtonWrapper } from "components/common";
import { ChatBubble, ArrowBackIosNew } from "@mui/icons-material";
import SmsMessageBlock from "./SmsMessageBlock";
import FilterToolbar from "./FilterToolbar";
import {
  Container,
  Header,
  Body,
  Time,
  MessagePanel,
  Footer,
  Message,
  WrapMessage,
} from "./styles";
import { updateList } from "library/resources";

const SmsClient = ({ showUser }) => {
  const { state, app, dispatch } = useContext(AppContext);
  const { sms, patients } = state;
  const [newEvents] = app.events;
  const messagesEndRef = useRef(null);
  const [patientLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [sentMessage, setSentMessage] = useState(null);
  const [showSms, setShowSms] = useState(false);
  const [pageSize, setPageSize] = useState(20);
  const [search, setSearch] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const [anchorEl] = React.useState(null);
  const [starred, setStarred] = React.useState([]);

  React.useEffect(() => {
    // Initialize starred from sessionStorage on component mount
    const sessionStarred = sessionStorage.getItem("starred");
    if (sessionStarred) {
      setStarred(JSON.parse(sessionStarred));
    }
  }, []);

  const toggleItemStarred = (id) => {
    setStarred((currentStarred) => {
      const newStarred = currentStarred.includes(id)
        ? currentStarred.filter((i) => i !== id) // Remove id from the starred list
        : [...currentStarred, id]; // Add id to the starred list

      // Update session storage after state is updated
      sessionStorage.setItem("starred", JSON.stringify(newStarred));
      return newStarred;
    });
  };

  React.useEffect(() => {
    // Update sessionStorage whenever starred changes
    sessionStorage.setItem("starred", JSON.stringify(starred));
  }, [starred]); // Dependency array ensures this runs whenever starred changes

  const patientsWithLatestSmsDate = patients?.data
    ?.map((p) => {
      const userSms = sms.data
        .filter((s) => s.data?.PatientId === p.data.PatientId)
        .sort(
          (a, b) => new Date(a.data.createdAt) - new Date(b.data.createdAt)
        );

      const latestSmsDate = userSms.length
        ? new Date(userSms[userSms.length - 1].data.createdAt)
        : new Date(0); // If no SMS, set a very old date.

      return {
        patient: p,
        latestSmsDate: latestSmsDate,
        userSms: userSms,
      };
    })
    ?.sort((a, b) => b.latestSmsDate - a.latestSmsDate);

  // Now map over this sorted array to get the desired shape and take top 20.
  const pagedPatients = patientsWithLatestSmsDate
    ?.filter((p) => {
      if (!search) return true;
      const name = p.patient?.data?.Name?.toLowerCase() || "";
      const phone = p.patient?.data?.Phone?.toLowerCase() || "";
      const searchLower = search.toLowerCase();
      return name.includes(searchLower) || phone.includes(searchLower);
    })
    ?.filter((p) => {
      if (selectedFilter === "all") return true;
      if (selectedFilter === "important") {
        const starred = JSON.parse(sessionStorage.getItem("starred"));
        if (p.userSms.length > 0) {
          console.log("starred", starred);
          console.log("p.userSms", p.patient.data.id);
          console.log(starred?.includes(p.patient.data.id));
        }
        return starred?.includes(p.patient.data.id);
      }
      if (selectedFilter === "unread") {
        return p.userSms.some(
          (s) =>
            (!s.data.ReadUserId && s.data.TypeId === 4) ||
            s.data.UserId === selectedUserId
        );
      }
    })
    .slice(0, pageSize)
    .map(({ patient, userSms }) => ({
      ...patient.data,
      SmsMessages: userSms.map((s) => s.data),
    }));
    

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  useEffect(() => {
    const user = patients.data.find((s) => s.data.PatientId === showUser)?.data;
    if (user) {
      setSelectedUserId(user.UserId);
    }
  }, [showUser]);

  const selectedUser =
    pagedPatients.find((s) => s?.UserId === selectedUserId) || null;

  useEffect(() => {
    if (selectedUser?.SmsMessages?.length) scrollToBottom();
  }, [selectedUser?.SmsMessages?.length]);

  useEffect(() => {
    setTimeout(() => {
      scrollToBottom();
    }, 100);
  }, [messagesEndRef, showUser]);

  const userGroup = pagedPatients?.reduce((group, curP) => {
    let newkey = curP["Phone"];
    if (!group[newkey] && newkey) {
      group[newkey] = [];
    }

    if (newkey) {
      group[newkey].push(curP);
    }
    return group;
  }, []);

  const options =
    userGroup &&
    Object.entries(userGroup).map(([_, items]) => {
      return {
        ...items[0],
        Name: items.map((item) => item.Name).join(" | "),
        Patients: items.map((item) => item?.Patients || []).flat(),
        SmsMessages: items.map((item) => item.SmsMessages || []).flat(),
      };
    });

  const newSmsCount = sms.data?.filter(
    (m) => m?.data?.TypeId === 4 && !m?.data?.ReadUserId
  )?.length;

  useEffect(() => {
    setSentMessage(null);
  }, [selectedUser?.SmsMessages.length]);

  return (
    <>
      <StyledIconButtonWrapper>
        <Badge
          style={{ width: "100%" }}
          badgeContent={newSmsCount}
          color="secondary"
        >
          <IconButton
            color="primary"
            aria-label="upload picture"
            component="span"
            onClick={() => {
              setShowSms(true);
            }}
            size="large"
          >
            <ChatBubble style={{ color: "white" }} />
          </IconButton>{" "}
        </Badge>
      </StyledIconButtonWrapper>
      <Popover
        open={showSms}
        onClose={() => setShowSms(false)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Container open={!!selectedUser}>
          <div>
            <FilterToolbar
              unreadCount={newSmsCount}
              selectedFilter={selectedFilter}
              setSelectedFilter={(filter) => {
                setSelectedFilter(filter);
                setSelectedUserId(null);
              }}
              search={search}
              setSearch={setSearch}
            />
            <DropdownSearch
              options={options}
              open={true}
              dim={{ top: "0", height: "calc(80vh - 5rem)" }}
              setPageSize={setPageSize}
              pageSize={pageSize}
              selectedUser={selectedUser}
              setSelectedUser={async (item) => {
                setMessageLoading(true);
                setSentMessage(null);
                setSelectedUserId(item.UserId);
                await item.SmsMessages.filter(
                  (m) => !m.ReadUserId && m.TypeId === 4
                ).forEach(async (unread) => {
                  await marekAsRead(ADMIN_NS, unread);
                });
                await updateList("sms", getPatientSmsFeed, {
                  patientId: item.PatientId,
                })(dispatch);
                setMessageLoading(false);
              }}
              loading={patientLoading}
              toggleItemStarred={toggleItemStarred}
              starred={starred}
            />
          </div>
          {selectedUser && (
            <MessagePanel>
              <AppBar position="static">
                <Toolbar
                  variant="dense"
                  disableGutters
                  sx={{
                    backgroundColor: colors.dark,
                    color: colors.white,
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography color="inherit" component="div" ml={1}>
                    To: <strong>{selectedUser?.Name}</strong> -{" "}
                    {selectedUser?.Phone}
                  </Typography>

                  <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={() => {
                      setSelectedUserId(null);
                    }}
                  >
                    <ArrowBackIosNew />
                  </IconButton>
                </Toolbar>
              </AppBar>

              <Body>
                {!messageLoading && selectedUser?.SmsMessages?.length <= 0 && (
                  <Time
                    style={{
                      fontSize: "0.8rem",
                      margin: "1rem",
                      textAlign: "center",
                    }}
                  >
                    There are no messages for this user
                  </Time>
                )}
                {selectedUser?.SmsMessages?.map((message) => (
                  <SmsMessageBlock key={message.Sid} message={message} />
                ))}

                {sentMessage && sentMessage.UserId === selectedUser.UserId && (
                  <WrapMessage>
                    <Time style={{ fontSize: "0.8rem" }}>
                      {sentMessage.createdAt &&
                        format(
                          Date.parse(sentMessage.createdAt),
                          "EEEE do MMM hh:mmaa"
                        )}
                    </Time>
                    <Message type={sentMessage.TypeId}>
                      <Typography component="div">
                        {sentMessage.Text}{" "}
                      </Typography>
                      <div
                        style={{
                          position: "absolute",
                          bottom: "0.5rem",
                          right: "8rem",
                        }}
                      >
                        {" "}
                        <DoubleBounce color={colors.grey} />
                      </div>
                    </Message>
                  </WrapMessage>
                )}
                {messageLoading && (
                  <DoubleBounce color={colors.primary} margin="1rem" />
                )}
                <div ref={messagesEndRef} />
              </Body>
              <Footer>
                <BottomToolBar
                  selectedUser={selectedUser}
                  disabled={!!sentMessage}
                  sendSms={async (textToSend) => {
                    const transformed = {
                      UserId: selectedUser.UserId,
                      Phone: selectedUser.Phone,
                      PhonePrefix: selectedUser?.MetaData?.PhonePrefix,
                      PracticeId: selectedUser.PracticeId,
                      PatientId: selectedUser.PatientId,
                      Text: textToSend,
                      updatedAt: new Date(),
                      createdAt: new Date(),
                    };
                    setSentMessage({
                      createdAt: new Date(),
                      updatedAt: new Date(),
                      UserId: selectedUser.UserId,
                      Text: textToSend,
                      TypeId: 0,
                    });

                    setTimeout(() => {
                      scrollToBottom();
                    }, 1000);
                    await sendSms(ADMIN_NS, transformed);
                  }}
                />
              </Footer>
            </MessagePanel>
          )}
        </Container>
      </Popover>
    </>
  );
};

export default SmsClient;
