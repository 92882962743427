import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { CheckBoxButton } from "../common";
import colors from "../../library/styled-components/colors";

const Container = styled.div`
  display: grid;
  margin: 1em;
`;

const Area = styled.label`
  display: grid;
  color: ${colors.primColor};
  cursor: pointer;
  justify-items: center;
  border-radius: 4px;
`;

const CheckButton = ({ onChange, icon, val, LabelData, name = false }) => {
  return (
    <Container>
      <Area>
        <CheckBoxButton val={val} onClick={() => onChange(!val, name)}>
          <div>{LabelData.text}</div>
          {icon}
        </CheckBoxButton>
      </Area>
    </Container>
  );
};

export default CheckButton;
