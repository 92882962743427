import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);
//dayjs.extend(timezone)
export const ADMIN_NS = "admin-state";
export const PUBLIC_NS = "public-state";
export const timeZone = "Europe/Dublin";

dayjs.tz.setDefault(timeZone);

export const openingHours = {
  slotMinTime: "06:00:00",
  slotMaxTime: "22:00:00",
};

export { dayjs };
