import one from "./img/teeth/table3/18b.png";
import oneImplant from "./img/teeth/table3/implants/18b.png";
import oneRemoved from "./img/teeth/table3/removed/18b.png";

import two from "./img/teeth/table3/17b.png";
import twoImplant from "./img/teeth/table3/implants/17b.png";
import twoRemoved from "./img/teeth/table3/removed/17b.png";

import three from "./img/teeth/table3/16b.png";
import threeImplant from "./img/teeth/table3/implants/16b.png";
import threeRemoved from "./img/teeth/table3/removed/16b.png";

import four from "./img/teeth/table3/15b.png";
import fourImplant from "./img/teeth/table3/implants/15b.png";
import fourRemoved from "./img/teeth/table3/removed/15b.png";

import five from "./img/teeth/table3/14b.png";
import fiveImplant from "./img/teeth/table3/implants/14b.png";
import fiveRemoved from "./img/teeth/table3/removed/14b.png";

import six from "./img/teeth/table3/13b.png";
import sixImplant from "./img/teeth/table3/implants/13b.png";
import sixRemoved from "./img/teeth/table3/removed/13b.png";

import seven from "./img/teeth/table3/12b.png";
import sevenImplant from "./img/teeth/table3/implants/12b.png";
import sevenRemoved from "./img/teeth/table3/removed/12b.png";

import eight from "./img/teeth/table3/11b.png";
import eightImplant from "./img/teeth/table3/implants/11b.png";
import eightRemoved from "./img/teeth/table3/removed/11b.png";

export const threeSettings = {
  imgs: [
    {
      id: 18,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      },
      pos: "0 23px",
      width: "51px",
    },
    {
      id: 17,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      },
      pos: "0 25px",
      width: "57px",
    },
    {
      id: 16,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      },
      pos: "0 23px",
      width: "63px",
    },
    {
      id: 15,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 16px",
      width: "43px",
    },
    {
      id: 14,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 16px",
      width: "44px",
    },
    {
      id: 13,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "0 16px",
      width: "44px",
    },
    {
      id: 12,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 16px",
      width: "45px",
    },
    {
      id: 11,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 13px",
      width: "48px",
    },
  ],
};

export const threeState = {
  margin: [
    [5, 63],
    [25.5, 63],
    [44, 63],

    [58, 63],
    [78, 63],
    [98, 63],

    [112.5, 63],
    [136.5, 63],
    [161.5, 63],

    [177, 63],
    [190, 63],
    [204, 63],

    [223, 63],
    [236, 63],
    [249, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [310.5, 63],
    [322, 63],
    [336, 63],

    [352, 63],
    [367, 63],
    [383.5, 63],
  ],
  depth: [
    [5, 63],
    [25.5, 63],
    [44, 63],

    [58, 63],
    [78, 63],
    [98, 63],

    [112.5, 63],
    [136.5, 63],
    [161.5, 63],

    [177, 63],
    [190, 63],
    [204, 63],

    [223, 63],
    [236, 63],
    [249, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [310.5, 63],
    [322, 63],
    [336, 63],

    [352, 63],
    [367, 63],
    [383.5, 63],
  ],
  imgState: [
    {
      id: 18,
      furcation: [
        { state: null, pos: [10, 82] },
        { state: null, pos: [36, 88] },
      ],
    },
    {
      id: 17,
      furcation: [
        { state: null, pos: [64, 94] },
        { state: null, pos: [87, 98] },
      ],
    },
    {
      id: 16,

      furcation: [
        { state: null, pos: [118, 94] },
        { state: null, pos: [154, 84] },
      ],
    },
    {
      id: 15,
    },
    {
      id: 14,
      furcation: [
        { state: null, pos: [247, 92] },
        { state: null, pos: [228, 98] },
      ],
    },
    {
      id: 13,
    },
    {
      id: 12,
    },
    {
      id: 11,
    },
  ],
};
