import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AccountCircle, CloudUpload } from "styled-icons/material";
import { uploadProfileImage } from "../../actions/practitioners";
import colors from "../../library/styled-components/colors";
import { ADMIN_NS } from "../../config";

const FileUploadContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const ProfileImage = styled.img`
  object-fit: cover;
  width: 120px;
  height: 120px;
  border-radius: 4px;
`;

const UploadInput = styled.input`
  display: none;
`;

const UploadArea = styled.label`
  display: grid;
  border: 1px solid ${colors.borderColor};
  cursor: pointer;
  justify-items: center;
  grid-gap: 10px;
  padding: 10px;
  border-radius: 4px;
`;

const UplaodButton = styled.button`
  outline: none;
  font-size: 1rem;
  background-color: ${colors.borderColor};
  border: 1px solid ${colors.borderColor};
  color: ${colors.textColor};
  cursor: pointer;
  align-self: center;
  margin-top: 0;
  padding: 11px 25px;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
`;

const ButtonItems = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
  color: ${colors.errorColor};
  font-size: 10px;
  margin: 14px 0 0 0;
  line-height: 1.91667;
  font-weight: 400;
  text-align: right;
`;

const FileUploadButton = ({ text }) => (
  <UplaodButton type="submit">
    <ButtonItems>
      <CloudUpload size="16" />
      <div>{text}</div>
    </ButtonItems>
  </UplaodButton>
);

const FileUpload = ({ DomRef, defaultUrl, onChange, LabelData }) => {
  const [profileURL, setProfileURL] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);

  const selectImage = (event) => {
    const [first] = event.target.files;
    setProfileURL(URL.createObjectURL(first));
    setUploadFile({ file: first, uploaded: false });
  };

  const uploadImage = async (e) => {
    e.preventDefault();
    const file = new FormData();
    file.append("file", uploadFile.file);
    const response = await uploadProfileImage(file, ADMIN_NS);
    if (response && response.fileName) {
      setUploadFile({ file: uploadFile.file, uploaded: true });
      onChange(response.fileName);
    }
  };
  useEffect(() => {
    setProfileURL(
      defaultUrl
        ? `${process.env.REACT_APP_APP_API}/uploads/${defaultUrl}`
        : profileURL
    );
  }, [defaultUrl]);

  return (
    <FileUploadContainer>
      <LabelContainer>
        <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
        {LabelData.error && (
          <ErrorMessage htmlFor={LabelData.text}>
            {LabelData.error}
          </ErrorMessage>
        )}
      </LabelContainer>
      <UploadArea>
        {profileURL && <ProfileImage htmlFor="myprofile" src={profileURL} />}
        {!profileURL && <AccountCircle htmlFor="myprofile" size="48" />}
        <div>
          <form onSubmit={uploadImage} method="POST">
            <UploadInput
              name="myprofile"
              id="myprofile"
              type="file"
              onChange={selectImage}
            />
            {uploadFile && uploadFile.file && !uploadFile.uploaded && (
              <FileUploadButton text="Confim" />
            )}
          </form>
        </div>
      </UploadArea>
    </FileUploadContainer>
  );
};

export default FileUpload;
