import localforage from "localforage";

const patientsUpdate = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const patients = await localforage.getItem("patients");
  let patientsData = patients?.map((p) => {
    if (p.PatientId === Patient.PatientId) {
      return Patient;
    }
    return p;
  });
  dispatch({
    type: "LOAD",
    context: "patients",
    data: patientsData,
  });

  await localforage.setItem("patients", patientsData);
  await patientUpdate(eventData)(dispatch);
};

const patientUpdate = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Patient.PatientId) return;
  let data = { ...Patient, ...patient, User: { ...Patient.User, ...Patient } };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const patientsCreate = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const patients = await localforage.getItem("patients");
  const data = [
    ...patients.filter((p) => p.PatientId !== Patient.PatientId),
    Patient,
  ];
  dispatch({
    type: "LOAD",
    context: "patients",
    data,
  });
  await localforage.setItem("patients", data);
};

const deletePatient = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const patients = await localforage.getItem("patients");
  const data = patients.filter((p) => p.PatientId !== Patient.PatientId);
  dispatch({
    type: "LOAD",
    context: "patients",
    data,
  });
  await localforage.setItem("patients", data);
};

const deletePatientAppointments = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const appointments = await localforage.getItem("appointments");
  const data = appointments.filter((a) => a.PatientId !== Patient.PatientId);
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const deletePatientItem = (eventData) => async (dispatch) => {
  const { Patient } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Patient.PatientId) return;
  await localforage.removeItem("patient");
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data: null,
  });
};

const patientsDelete = (eventData) => async (dispatch) => {
  deletePatient(eventData)(dispatch);
  deletePatientAppointments(eventData)(dispatch);
  deletePatientItem(eventData)(dispatch);
};

export default {
  create: patientsCreate,
  update: patientsUpdate,
  delete: patientsDelete,
};
