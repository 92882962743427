import * as React from "react";
import styled, { css } from "styled-components";
import colors from "../../../../library/styled-components/colors";

const stateColors = [colors.darkBlue, colors.danger, colors.softGreen];

const fillCss = (selector, status) => {
  return css`
    ${selector} {
      fill: ${stateColors[status || 0]} !important;
      & > path {
        fill: none;
      }
      stroke-width: 2 !important;
    }
  `;
};

const strokeFillCss = (selector, status, fill) =>
  css`
    ${selector} {
      stroke: ${stateColors[status || 0]} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
  `;

const showFillCss = (selector, status) => {
  return css`
    #procedures #implant {
      & > path {
        stroke: ${stateColors[status || 0]} !important;
        fill: ${stateColors[status || 0]} !important;
      }
      display: block;
    }
    #root {
      display: none;
    }
  `;
};

const rootCanal = (selector, status, surface) => {
  const hit = selector.includes("#root");
  let regselect = null;
  if (hit) {
    regselect = selector;
  } else {
    regselect = "#root > path:not([id*='b'])";
  }
  switch (surface) {
    case "rct-temporary":
      return strokeFillCss(regselect, status, "rgba(233, 58, 145, 0.831)");
    default:
      return fillCss(regselect, status);
  }

};

const restoration = (selector, status, surface) => {
  const hit = selector.includes("#surface");
  if (hit) {
    switch (surface) {
      case "resto-amalgam":
        return strokeFillCss(selector, status, "rgba(129, 114, 122, 0.557)");
      case "resto-temporary":
        return strokeFillCss(selector, status, "rgba(214, 18, 116, 0.774)");
      default:
        return fillCss(selector, status);
    }
  }
};

const CrownColors = {
  0: {
    crownMetal: {
      stroke: "rgb(33, 5, 160)",
      fill: "rgba(13, 63, 101, 0.824)",
    },
    crownPorcelain: {
      stroke: "rgb(33, 5, 160)",
      fill: "rgba(212, 217, 230, 0.29)",
    },
    crownPfm: {
      stroke: "rgb(33, 5, 160)",
      fill: "rgba(26, 103, 170, 0.29)",
    },
  },
  1: {
    crownMetal: {
      stroke: "rgb(160, 16, 5)",
      fill: "rgba(101, 13, 13, 0.824)",
    },
    crownPorcelain: {
      stroke: "rgb(160, 16, 5)",
      fill: "rgba(230, 212, 221, 0.29)",
    },
    crownPfm: {
      stroke: "rgb(160, 16, 5)",
      fill: "rgba(170, 26, 98, 0.29)",
    },
  },
  2: {
    crownMetal: {
      stroke: "rgb(20, 160, 5)",
      fill: "rgba(13, 101, 13, 0.824)",
    },
    crownPorcelain: {
      stroke: "rgb(20, 160, 5)",
      fill: "rgba(213, 230, 212, 0.29)",
    },
    crownPfm: {
      stroke: "rgb(20, 160, 5)",
      fill: "rgba(26, 170, 33, 0.29)",
    },
  },
};

const crownCss = (stroke, fill) => {
  return css`
    #crown #crown-2,
    #crown #main {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
    #surface > path {
      stroke: none;
      fill: none;
    }
    #surface #shape {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
  `;
};

const bridgeCss = (stroke, fill) => {
  return css`
    #crown #crown-2,
    #crown #main {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
    #procedures #bridge {
      display: block;
      path {
        stroke: ${stroke} !important;
      }
    }
    #surface > path {
      stroke: none;
      fill: none;
    }
    #surface #shape {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
  `;
};

const removableCss = (stroke, fill) => {
  return css`
    #crown #crown-2,
    #crown #main {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
    #procedures #crr {
      display: block;
      path {
        stroke: ${stroke} !important;
      }
    }
    #surface > path {
      stroke: none;
      fill: none;
    }
    #surface #shape {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
  `;
};

const veneerCss = (stroke, fill) => {
  return css`
    #crown #crown-2,
    #crown #main {
      stroke: ${stroke} !important;
      fill: ${fill} !important;
      stroke-width: 2 !important;
    }
  `;
};

const crowns = (_, status, surface) => {
  let fill = "none";
  let stroke = stateColors[status || 0];
  switch (surface) {
    case "crown-metal":
      stroke = CrownColors[status || 0].crownMetal.stroke;
      fill = CrownColors[status || 0].crownMetal.fill;
      return crownCss(stroke, fill);
    case "crown-porcelain":
      stroke = CrownColors[status || 0].crownPorcelain.stroke;
      fill = CrownColors[status || 0].crownPorcelain.fill;
      return crownCss(stroke, fill);
    case "crown-pfm":
      stroke = CrownColors[status || 0].crownPfm.stroke;
      fill = CrownColors[status || 0].crownPfm.fill;
      return crownCss(stroke, fill);
    case "bridge-metal":
      stroke = CrownColors[status || 0].crownMetal.stroke;
      fill = CrownColors[status || 0].crownMetal.fill;
      return bridgeCss(stroke, fill);
    case "bridge-porcelain":
      stroke = CrownColors[status || 0].crownPorcelain.stroke;
      fill = CrownColors[status || 0].crownPorcelain.fill;
      return bridgeCss(stroke, fill);
    case "bridge-pfm":
      stroke = CrownColors[status || 0].crownPfm.stroke;
      fill = CrownColors[status || 0].crownPfm.fill;
      return bridgeCss(stroke, fill);
    case "remove-acrylic":
      return removableCss(stroke, fill);
    case "remove-metal":
      stroke = CrownColors[status || 0].crownMetal.stroke;
      fill = CrownColors[status || 0].crownMetal.fill;
      return removableCss(stroke, fill);
    case "veneer-porcelain":
      return veneerCss(stroke, fill);
    default:
      return crownCss(stroke, fill);
  }
};

const hygiene = (_, status, surface) => {
  let stroke = stateColors[status || 0];
  switch (surface) {
    case "hyg-seal":
      return css`
        #procedures #seal {
          display: block;
          path {
            stroke: ${stroke} !important;
          }
        }
      `;
    case "hyg-clean":
      return css`
        #procedures #hyg {
          display: block;
          path {
            stroke: ${stroke} !important;
          }
        }
      `;
    default:
  }
};

const surgery = (_, status, surface) => {
  let stroke = stateColors[status || 0];
  switch (surface) {
    case "ext-resection":
      return css`
        #procedures #resection {
          display: block;
          path {
            stroke: ${stroke} !important;
          }
        }
      `;
    case "ext-grafting":
      return css`
        #procedures #sinuslift {
          display: block;
          path {
            stroke: ${stroke} !important;
          }
        }
      `;
    default:
  }
};

const codeSet = {
  //RESTORATION
  resto: restoration,
  //ROOT CANAL
  rct: rootCanal,
  // CROWNS
  crown: crowns,
  // Hygiene
  hyg: hygiene,
  // Surgery
  ext: surgery,
  // IMPLANTS
  imp: (_, status) => showFillCss("#procedures #implant", status),
};

const Svg = styled.svg`
  #procedures > g {
    display: none;
  }
  ${(props) =>
    props?.selected?.map(
      (selector) => css`
        ${selector} {
          stroke: ${colors.darkBlue} !important;
          stroke-width: 2 !important;
        }
      `
    )}
  ${(props) =>
    props?.proceedures?.map((p) => {
      return p.selected.map(
        (selector) =>
          codeSet[p?.Type] &&
          codeSet[p?.Type](selector, p.status, p?.SubCategory?.Id)
      );
    })}
`;

const ToothSvg = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="246"
      viewBox="0 0 72 246"
      id={props.id}
      selected={props?.state?.selected}
      proceedures={(props?.proceedures?.length && props?.proceedures) || []}
      onClick={(e) => props.toothClick(e.target.id, e.target.parentElement.id)}
    >
      {props.children}
    </Svg>
  );
};

export default ToothSvg;
