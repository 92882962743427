import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Button,
  CircularProgress,
  Dialog,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
  Typography,
  Zoom,
} from "@mui/material";
import {
  Comment,
  AddAlert,
  Close as CloseIcon,
  Event,
  EventAvailable,
  AccountBalanceWallet,
  CreditCard as CreditCardIcon,
  Chat,
  NotificationsNone as NotificationsNoneIcon,
  NotificationsActive as NotificationsActiveIcon,
  NotificationImportant as NotificationImportantIcon,
} from "@mui/icons-material";
import styled from "styled-components";
import colors from "library/styled-components/colors";
import AppointmentActions from "components/calendar/components/AppointmentActions";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import { useHistory } from "react-router-dom";
import { EventBlock, EventBlockContainer } from "../Common";
import { format } from "date-fns";
import { InputContainer, Row } from "components/common";
import { AppContext } from "hooks/context";

const ButtonWrap = styled.div`
  display: grid;
  font-weight: normal;
  height: 2.2rem;
`;

const StyledAvatar = styled.div`
  display: flex;
  color: ${colors.whiteColor};
  padding: 1em;
  cursor: pointer;
  margin: 0 0.5em;
  border-radius: 50%;
  background-color: ${colors.altLightColor};
  :hover {
    filter: brightness(110%);
  }
`;

function getActiveReminders(data) {
  const reminders = data?.Reminders || [];
  const reminderEvents = data?.ReminderEvents || [];

  const latestReminderEvents = {};

  reminders?.forEach((reminderId) => {
    // Filter events for the specific reminder
    const filteredEvents = reminderEvents.filter(
      (event) => event.reminder === reminderId && !event.deleted
    );

    // Find the latest event based on the created timestamp
    let latestEvent = null;
    filteredEvents?.forEach((event) => {
      if (
        !latestEvent ||
        new Date(event.created) > new Date(latestEvent.created)
      ) {
        latestEvent = event;
      }
    }) || {};

    if (latestEvent) {
      latestReminderEvents[reminderId] = latestEvent;
    }
  });

  return latestReminderEvents || {};
}

function renderReminderIcons(latestReminderEvents) {
  return (
    <div>
      {Object.values(latestReminderEvents).map((event) => {
        if ((event.type === "SMS_QUEUE" || !event.type) && !event.sent) {
          return (
            <NotificationsNoneIcon
              key={event.id}
              style={{ color: colors.primary }}
            />
          );
        } else if (event.type === "SMS_SENT" && event.sent) {
          return (
            <NotificationsActiveIcon
              key={event.id}
              style={{ color: colors.green }}
            />
          );
        } else if (event.type === "SMS_FAILED") {
          return (
            <NotificationImportantIcon
              key={event.id}
              style={{ color: colors.danger }}
            />
          );
        }
        return null;
      })}
    </div>
  );
}

const AppointmentEventBlock = ({
  info,
  updateAppointment,
  setCreateInvoice,
  setCreateNewInvoice,
  setNewInvoice,
  setAppointmentPreview,
  setNewAppointment,
  setShowAccounts,
  setShowPatient,
  setSmsScheduler,
  sendCancelData,
  sendUndoCancelData,
}) => {
  const { state } = useContext(AppContext);
  const { patients } = state;
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const [cancel, setCancel] = useState(false);
  const [unCancel, setUnCancel] = useState(false);
  const [form, setForm] = useState(null);
  const [loading, setLoading] = useState(false);
  const eventRef = useRef();

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const Notes =
    info.event.extendedProps?.Meta?.Comments?.map((c) => c.text) || [];

  useEffect(() => {
    const innerEventContainer =
      eventRef?.current?.parentElement?.parentElement?.parentElement;

    if (innerEventContainer) {
      const offset = `${(innerEventContainer.style?.zIndex || 1) - 1 || 0}rem`;
      const insetArray = innerEventContainer.style.inset.split(" ");
      if (insetArray.length > 3) insetArray[3] = offset;
      else insetArray.push(offset);
      if (innerEventContainer.style.inset !== "")
        innerEventContainer.style.inset = insetArray.join(" ");
      if (info.event.extendedProps?.CancelationId)
        innerEventContainer.style.width = "2.5rem";
    }
  }, [eventRef]);

  const userDetals = info.event?.extendedProps?.Patient?.User || {};
  const outstanding =
    patients?.data?.find(
      (p) => p.data.PatientId === info.event?.extendedProps?.PatientId
    )?.data?.Outstanding || 0;

  const activeReminders = getActiveReminders(
    info.event.extendedProps?.Meta || {
      Reminders: [],
      ReminderEvents: [],
    }
  );

  const reminderIcons = renderReminderIcons(activeReminders);

  return (
    <EventBlockContainer ref={eventRef} onClick={handleClick}>
      <Tooltip
        arrow
        placement="top-end"
        title={
          <React.Fragment>
            <Typography color="inherit">
              {info.event.extendedProps?.AppointmentId ? info.event.title : ""}
            </Typography>
            {Notes.map((n) => (
              <Typography key={n}> - {n}</Typography>
            ))}
          </React.Fragment>
        }
      >
        <EventBlock>
          {info.event.extendedProps?.CancelationId ? (
            ""
          ) : (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }}>
                <i>{info.timeText}</i>
              </div>
              <div style={{ paddingLeft: "0.5rem" }}>
                <strong>{info.event.title}</strong>
                {Notes.map((note) => (
                  <div key={note}>
                    {note.length > 90
                      ? `- ${note.substring(0, 90)} ${
                          note.length > 90 ? "..." : ""
                        }`
                      : note}
                  </div>
                ))}
              </div>{" "}
            </div>
          )}
          <div style={{ display: "flex" }}>
            {reminderIcons}
            <Comment color={Notes.length ? "primary" : "disabled"} />
          </div>
        </EventBlock>
      </Tooltip>
      <Dialog open={unCancel}>
        <div
          style={{
            display: "grid",
            margin: "1rem",
          }}
        >
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            ""
          )}
          <h3>UnDo Appointment Cancelation</h3>
          <p> Are you sure you want to undo this cancelation?</p>
          <Row>
            <FormControl>
              <Button
                onClick={async () => {
                  setLoading(true);
                  const data = {
                    ...form?.cancelation,
                    AppointmentId: info.event?.extendedProps?.AppointmentId,
                    PatientId: info.event?.extendedProps?.PatientId,
                  };
                  sendUndoCancelData(
                    data,
                    info.event.extendedProps.AppointmentId
                  );
                }}
                variant="contained"
                color={"primary"}
              >
                Undo Cancelation
              </Button>
            </FormControl>
            <FormControl>
              <Button
                onClick={() => {
                  setCancel(null);
                  setUnCancel(null);
                }}
                variant="contained"
              >
                Close
              </Button>
            </FormControl>
          </Row>
        </div>
      </Dialog>
      <Dialog open={cancel}>
        <div
          style={{
            display: "grid",
            margin: "1rem",
          }}
        >
          {loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CircularProgress />
            </div>
          ) : (
            ""
          )}
          <h3>Cancel Appointment</h3>
          <FormControl variant="outlined">
            <InputContainer>
              <InputLabel>Reason</InputLabel>
              <Select
                style={{ background: "white", width: "100%" }}
                value={form?.cancelation?.Reason || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    cancelation: {
                      ...form?.cancelation,
                      Reason: e.target.value,
                    },
                  });
                }}
                label="Reason"
              >
                <MenuItem value={"FORGOT"}>{"Forgot"}</MenuItem>
                <MenuItem value={"EMERGENCY"}>{"Emergency"}</MenuItem>
                <MenuItem value={"FAILED"}>{"Did not Attend"}</MenuItem>
                <MenuItem value={"SICK"}>{"Illness"}</MenuItem>
                <MenuItem value={"WORK"}>{"Work"}</MenuItem>
                <MenuItem value={"PERSONAL"}>{"Personal Reasons"}</MenuItem>
              </Select>
            </InputContainer>
          </FormControl>
          <FormControl>
            <InputContainer>
              <TextField
                variant="outlined"
                label="Additional Info"
                multiline
                maxRows={4}
                style={{ background: "white", width: "100%" }}
                value={form?.cancelation?.Information || ""}
                onChange={(e) => {
                  setForm({
                    ...form,
                    cancelation: {
                      ...form?.cancelation,
                      Information: e.target.value,
                    },
                  });
                }}
              />
            </InputContainer>
          </FormControl>
          <Row>
            <FormControl>
              <Button
                onClick={async () => {
                  setLoading(true);
                  const data = {
                    ...form?.cancelation,
                    AppointmentId: info.event?.extendedProps?.AppointmentId,
                    PatientId: info.event?.extendedProps?.PatientId,
                  };
                  sendCancelData(data, info.event.extendedProps.AppointmentId);
                }}
                variant="contained"
                color={"primary"}
              >
                Cancel Appointment
              </Button>
            </FormControl>
            <FormControl>
              <Button
                onClick={() => {
                  setCancel(null);
                  setUnCancel(null);
                }}
                variant="contained"
              >
                Close
              </Button>
            </FormControl>
          </Row>
        </div>
      </Dialog>
      <Popover
        id={id}
        open={open || false}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Zoom in={open}>
          <div
            style={{
              display: "flex",
              margin: "2rem",
            }}
          >
            <div
              style={{
                display: "flex",
                position: "absolute",
                right: "0",
                top: "0",
              }}
            >
              <IconButton aria-label="close" onClick={handleClose} size="large">
                <CloseIcon />
              </IconButton>
            </div>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    textAlign: "center",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <StyledAvatar
                    alt={userDetals?.Name}
                    onClick={() =>
                      setAppointmentPreview(info.event.extendedProps)
                    }
                  >
                    <Event />
                  </StyledAvatar>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "0.5rem",
                    }}
                  >
                    {info.event?.extendedProps?.Start && (
                      <strong>
                        {format(
                          new Date(info.event?.extendedProps?.Start),
                          "PPP, HH:mm"
                        )}
                      </strong>
                    )}
                    <span>
                      &nbsp;with {info.event?.extendedProps?.Practitioner?.Name}
                    </span>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      margin: "0.5rem 0",
                    }}
                  >
                    <Typography
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      color="inherit"
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                        }}
                      >
                        {userDetals?.Name}{" "}
                      </span>
                      <Tooltip
                        title={
                          <React.Fragment>
                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              color="inherit"
                            >
                              <span>Total Charges: &nbsp;</span>
                              <span style={{ alignSelf: "flex-end" }}>
                                {new Intl.NumberFormat("en-IE", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(
                                  !isNaN(outstanding?.TotalCharges)
                                    ? outstanding?.TotalCharges
                                    : 0
                                )}
                              </span>
                            </Typography>

                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              color="inherit"
                            >
                              <span>Total Paid: &nbsp;</span>
                              <span style={{ alignSelf: "flex-end" }}>
                                {new Intl.NumberFormat("en-IE", {
                                  style: "currency",
                                  currency: "EUR",
                                }).format(
                                  !isNaN(outstanding?.TotalPaid)
                                    ? outstanding?.TotalPaid
                                    : 0
                                )}
                              </span>
                            </Typography>

                            <Typography
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                              color="inherit"
                            >
                              <span>Total Owed: &nbsp;</span>
                              {new Intl.NumberFormat("en-IE", {
                                style: "currency",
                                currency: "EUR",
                              }).format(
                                !isNaN(outstanding?.TotalOwed)
                                  ? outstanding?.TotalOwed
                                  : 0
                              )}
                            </Typography>
                          </React.Fragment>
                        }
                      >
                        <CreditCardIcon
                          style={{
                            cursor: "pointer",
                            color: outstanding?.TotalOwed > 0 ? "red" : "green",
                          }}
                        />
                      </Tooltip>
                    </Typography>
                    {userDetals?.Email ? (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        color="inherit"
                      >
                        <span>Email: </span>
                        <span>{userDetals?.Email}</span>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {userDetals?.Phone ? (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        color="inherit"
                      >
                        <span>Phone: </span>
                        <span>{userDetals?.Phone}</span>
                      </Typography>
                    ) : (
                      ""
                    )}
                    {userDetals?.DateOfBirth ? (
                      <Typography
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        color="inherit"
                      >
                        <span>DoB: </span>
                        <span>
                          {new Date(userDetals?.DateOfBirth).toLocaleDateString(
                            "en-GB"
                          )}
                        </span>
                      </Typography>
                    ) : (
                      ""
                    )}
                  </div>
                  <AppointmentActions
                    id={
                      info.event.extendedProps
                        ? info.event.extendedProps.AppointmentId
                        : null
                    }
                    updateAppointment={updateAppointment}
                    appointmentData={info.event.extendedProps}
                    setCancel={() => {
                      if (info.event.extendedProps?.CancelationId?.length) {
                        setUnCancel(!unCancel);
                      } else setCancel(!cancel);
                    }}
                    setCreateInvoice={() =>
                      setCreateInvoice(info.event.extendedProps)
                    }
                    setNewInvoice={setNewInvoice}
                    setReSchedule={() =>
                      setAppointmentPreview(info.event.extendedProps)
                    }
                    showAccounts={() =>
                      setShowAccounts(info.event.extendedProps)
                    }
                  />
                </div>
                <Divider />
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "1fr 1fr",
                    gridGap: "0.5rem",
                    marginTop: "1rem",
                  }}
                >
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      onClick={() =>
                        setSmsScheduler("reminder", info.event.extendedProps)
                      }
                      startIcon={<Chat size="small" />}
                    >
                      Send Reminder Sms
                    </Button>
                  </ButtonWrap>
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        backgroundColor: colors.lightPurple,
                        color: colors.black,
                      }}
                      size="small"
                      onClick={() =>
                        setSmsScheduler("recall", info.event.extendedProps)
                      }
                      startIcon={<AddAlert size="small" />}
                    >
                      Schedule Recall Sms
                    </Button>
                  </ButtonWrap>
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="small"
                      style={{
                        backgroundColor: colors.altLightColor,
                        color: colors.black,
                      }}
                      onClick={() =>
                        setAppointmentPreview(info.event.extendedProps)
                      }
                      startIcon={<EventAvailable size="small" />}
                    >
                      Edit Appointment
                    </Button>
                  </ButtonWrap>

                  <ButtonWrap>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: colors.dark,
                        color: colors.white,
                      }}
                      size="small"
                      onClick={() => setNewAppointment(true)}
                      startIcon={<Event size="small" />}
                    >
                      New Appointment
                    </Button>
                  </ButtonWrap>
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: colors.success }}
                      size="small"
                      onClick={() =>
                        setCreateNewInvoice(info.event.extendedProps)
                      }
                      startIcon={<AccountBalanceWallet size="small" />}
                    >
                      Create new Invoice
                    </Button>
                  </ButtonWrap>
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={() => setShowAccounts(info.event?.extendedProps)}
                      startIcon={<AccountBalanceIcon size="small" />}
                    >
                      Accounts
                    </Button>
                  </ButtonWrap>
                  <ButtonWrap>
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => setShowPatient(info.event?.extendedProps)}
                      startIcon={<AssignmentIndIcon size="small" />}
                    >
                      Go To Patient
                    </Button>
                  </ButtonWrap>
                </div>
              </Grid>
            </Grid>
          </div>
        </Zoom>
      </Popover>
    </EventBlockContainer>
  );
};

export default AppointmentEventBlock;
