import React from "react";
import styled from "styled-components";
import Editor from "../../editor";
import { formSchema, createButtons } from "./formSettings";
import { FadeDiv } from "../../../library/styled-components";

const UpdatePassContainer = styled(FadeDiv)`
  display: grid;
  grid-template-rows: auto 8em 2em;
  background-color: ${(props) => props.colors.secColor};
`;

function UpdatePasswordBlock({ resetPassword, fade, colors, meta }) {
  return (
    <UpdatePassContainer fade={fade} colors={colors}>
      <Editor
        submitError={meta && meta.error}
        warning={meta && meta.warning}
        formSchema={formSchema}
        metaData={meta}
        buttons={createButtons}
        sendData={resetPassword}
        colors={colors}
        settings={{
          width: { mobile: "100%", desktop: "100%" },
        }}
      />
    </UpdatePassContainer>
  );
}

export default UpdatePasswordBlock;
