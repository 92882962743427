import jsPDFInvoiceTemplate from "jspdf-invoice-template";
import { format, parseISO } from "date-fns";
import download from "downloadjs";
import "jspdf-autotable";
import { PDFDocument, StandardFonts } from "pdf-lib";
import dayjs from "dayjs";
import { timeZone } from "../../config";
import converter from "number-to-words";
import printJS from "print-js";
import { jsPDF } from "jspdf";

const treatmentCodes = new Map([
  [
    "A",
    {
      Box: "Check Box1",
      DatesT: [
        "Dates on which treatment was carried outA",
        "Dates on which treatment was carried outA_2",
        "Dates on which treatment was carried outA_3",
      ],
      DatesP: [
        "Dates on which payments were madeA",
        "Dates on which payments were madeA_2",
        "Dates on which payments were madeA_3",
      ],
      Amount: ["Text31", "Text32", "Text33"],
    },
  ],
  [
    "B",
    {
      Box: "Check Box2",
      DatesT: [
        "Dates on which treatment was carried outB",
        "Dates on which treatment was carried outB_2",
        "Dates on which treatment was carried outB_3",
      ],
      DatesP: [
        "Dates on which payments were madeB",
        "Dates on which payments were madeB_2",
        "Dates on which payments were madeB_3",
      ],
      Amount: ["Text34", "Text35", "Text36"],
    },
  ],
  [
    "C",
    {
      Box: "Check Box3",
      DatesT: [
        "Dates on which treatment was carried outC",
        "Dates on which treatment was carried outC_2",
        "Dates on which treatment was carried outC_3",
      ],
      DatesP: [
        "Dates on which payments were madeC",
        "Dates on which payments were madeC_2",
        "Dates on which payments were madeC_3",
      ],
      Amount: ["Text37", "Text38", "Text39"],
    },
  ],
  [
    "D",
    {
      Box: "Check Box4",
      DatesT: [
        "Dates on which treatment was carried outD",
        "Dates on which treatment was carried outD_2",
        "Dates on which treatment was carried outD_3",
      ],
      DatesP: [
        "Dates on which payments were madeD",
        "Dates on which payments were madeD_2",
        "Dates on which payments were madeD_3",
      ],
      Amount: ["Text40", "Text41", "Text42"],
    },
  ],
  [
    "E",
    {
      Box: "Check Box5",
      DatesT: [
        "Dates on which treatment was carried outE",
        "Dates on which treatment was carried outE_2",
        "Dates on which treatment was carried outE_3",
      ],
      DatesP: [
        "Dates on which payments were madeE",
        "Dates on which payments were madeE_2",
        "Dates on which payments were madeE_3",
      ],
      Amount: ["Text43", "Text44", "Text45"],
    },
  ],
  [
    "F",
    {
      Box: "Check Box6",
      DatesT: [
        "Dates on which treatment was carried outF",
        "Dates on which treatment was carried outF_2",
        "Dates on which treatment was carried outF_3",
      ],
      DatesP: [
        "Dates on which payments were madeF",
        "Dates on which payments were madeF_2",
        "Dates on which payments were madeF_3",
      ],
      Amount: ["Text46", "Text47", "Text48"],
    },
  ],
  [
    "G",
    {
      Box: "Check Box7",
      DatesT: [
        "Dates on which treatment was carried outG",
        "Dates on which treatment was carried outG_2",
        "Dates on which treatment was carried outG_3",
      ],
      DatesP: [
        "Dates on which payments were madeG",
        "Dates on which payments were madeG_2",
        "Dates on which payments were madeG_3",
      ],
      Amount: ["Text49", "Text50", "Text51"],
    },
  ],
  [
    "H",
    {
      Box: "Check Box8",
      DatesT: [
        "Dates on which treatment was carried outH",
        "Dates on which treatment was carried outH_2",
        "Dates on which treatment was carried outH_3",
      ],
      DatesP: [
        "Dates on which payments were madeH",
        "Dates on which payments were madeH_2",
        "Dates on which payments were madeH_3",
      ],
      Amount: ["Text52", "Text53", "Text54"],
    },
  ],
  [
    "I",
    {
      Box: "Check Box9",
      DatesT: [
        "Dates on which treatment was carried outI",
        "Dates on which treatment was carried outI_2",
        "Dates on which treatment was carried outI_3",
      ],
      DatesP: [
        "Dates on which payments were madeI",
        "Dates on which payments were madeI_2",
        "Dates on which payments were madeI_3",
      ],
      Amount: ["Text55", "Text56", "Text57"],
    },
  ],
  [
    "J",
    {
      Box: "Check Box10",
      DatesT: [
        "Dates on which treatment was carried outJ",
        "Dates on which treatment was carried outJ_2",
        "Dates on which treatment was carried outJ_3",
      ],
      DatesP: [
        "Dates on which payments were madeJ",
        "Dates on which payments were madeJ_2",
        "Dates on which payments were madeJ_3",
      ],
      Amount: ["Text58", "Text59", "Text60"],
    },
  ],
]);

const generateInvoiceTemplate = (invoice, user, practice) => {
  const totalCost = invoice.Charges.map((c) => c.Amount).reduce(
    (sum, i) => sum + i,
    0
  );
  const totalPayed = invoice.Payments.map((c) => c.Amount).reduce(
    (sum, i) => sum + i,
    0
  );
  const totalOwed = totalCost - totalPayed;

  const parameters = {
    outputType: "blob",
    returnJsPDFDocObject: true,
    fileName: "Invoice 2022",
    orientationLandscape: false,
    //compress: true,
    logo: {
      src: `${process.env.REACT_APP_DOMAIN}/logos/glanmire.png`,
      width: 53.33, //aspect ratio = width/height
      height: 26.66,
      margin: {
        top: 0, //negative or positive num, from the current position
        left: 0, //negative or positive num, from the current position
      },
    },
    business: {
      name: practice?.PracticeName,
      address: `${practice?.Address}, ${practice?.County}`,
      phone: practice?.Phone,
      email: practice?.Email,
      website: practice?.Webstie,
    },
    contact: {
      label: `Invoice #: ${invoice?.Code}`,
      name: user?.Name,
      address: `${user?.Address}, ${user?.County}`,
      phone: user?.Phone,
      email: user?.Email,
    },
    invoice: {
      label: `Receipt`,
      num: " ",
      invDate: `Invoice Date:  ${format(
        parseISO(invoice?.FromDate),
        "MMM d yyy"
      )}`,
      invGenDate: `Created Date: ${format(
        parseISO(invoice?.FromDate),
        "MMM d yyy"
      )}`,
      headerBorder: false,
      tableBodyBorder: false,
      tableHeaderBorder: false,
      header: [
        {
          title: "Detail",
          style: {
            width: 170,
          },
        },
        { title: "Total", style: { width: 10 } },
        { title: "", style: { width: 10 } },
      ],
      //sort by updatedAt date
      table: [...(invoice.Charges || []), ...(invoice.Payments || [])]
        .sort((a, b) => {
          return new Date(a.updatedAt) - new Date(b.updatedAt);
        })
        .map((item, index) =>
          item.PaymentId
            ? [
                `Payment - ${item?.Method} -${new Date(
                  item?.createdAt
                ).toLocaleDateString('en-GB')}`,
                item?.Amount,
                "EUR",
              ]
            : [item?.Treatment?.Name || "", item?.Amount, "EUR"]
        ),
      additionalRows: [
        {
          col1: "Total:",
          col2: totalCost?.toString(),
          col3: "EUR",
          style: {
            fontSize: 10, //optional, default 12
          },
        },
        {
          col1: "Recieved:",
          col2: totalPayed?.toString(),
          col3: "EUR",
          style: {
            fontSize: 10, //optional, default 12
          },
        },
        {
          col1: "Amount Due:",
          col2: totalOwed?.toString(),
          col3: "EUR",
          style: {
            fontSize: 10, //optional, default 12
          },
        },
      ],
    },

    pageEnable: true,
    pageLabel: "Page ",
  };
  const pdfCreated = jsPDFInvoiceTemplate({ ...parameters });
  return pdfCreated;
};

export const printMedForm = async (patient, selected) => {
  const url = `${process.env.REACT_APP_DOMAIN}/med2.pdf`; //med2.pdf
  const existingPdfBytes = await fetch(url).then((res) => res.arrayBuffer());
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  await pdfDoc.embedFont(StandardFonts.Helvetica);

  const form = pdfDoc.getForm();

  const fields = form.getFields();
  fields.forEach((field) => {
    const type = field.constructor.name;
    const name = field.getName();
    //console.log(type, name);
  });

  const charges = new Map();

  const practitioner = patient.Invoices[0]?.Practitioner;

  const nameAndAddress = [
    practitioner?.Name,
    `${practitioner?.Address},${practitioner?.County}`,
  ];

  patient.Invoices.forEach((i) => {
    i.Charges.filter(
      (c) => c.Treatment?.Data?.MedCode && selected.includes(c.id)
    ).forEach((c) => {
      charges.set(
        c.Treatment.Data.MedCode,
        charges.get(c.Treatment.Data.MedCode)
          ? [...charges.get(c.Treatment.Data.MedCode), c]
          : [c]
      );
    });
  });

  let total = 0;
  charges.forEach((value, key) => {
    const treatment = treatmentCodes.get(key);
    if (!treatment) return null;
    const checkBox = form.getCheckBox(treatment.Box);
    checkBox.check();

    value.forEach((c, i) => {
      if (!c) return;
      if (treatment.DatesT[i])
        form
          .getTextField(treatment.DatesT[i])
          .setText(dayjs.tz(c.createdAt, timeZone).format("DD/MM/YYYY"));
      if (treatment.DatesP[i] && c.Payment)
        form
          .getTextField(treatment.DatesP[i])
          .setText(
            dayjs.tz(c.Payment.createdAt, timeZone).format("DD/MM/YYYY")
          );
      if (treatment.Amount[i]) {
        total = total + c.Amount;
        form.getTextField(treatment.Amount[i]).setText(c.Amount?.toString());
      }
    });
  });

  let nameArray = [`${patient.User.Name}`];
  if (patient.User.Address) nameArray.push(patient.User.Address);
  if (patient.User.County) nameArray.push(patient.User.County);

  form.getTextField("Name and Address").setText(nameArray.join("\n"));
  if (patient.User.SocialSecurityNumber)
    form
      .getTextField("PPS No")
      .setText(patient.User.SocialSecurityNumber.substring(0, 9));

  form.getTextField("Date").setText(nameAndAddress.join("\n"));

  if (practitioner?.Qualification)
    form
      .getTextField("Qualifications of Dental Practitioner")
      .setText(practitioner?.Qualification);

  if (practitioner?.RegistrationNo)
    form.getTextField("undefined_15").setText(practitioner?.RegistrationNo);

  form.getTextField("undefined_151").setText(total.toString());

  form
    .getTextField("confirmAmtInWords")
    .setText(`${converter.toWords(total)} euro`);

  form
    .getTextField("Text30")
    .setText(dayjs.tz(dayjs(), timeZone).format("DDMMYY"));

  const pdfBytes = await pdfDoc.save();

  download(pdfBytes, "pdf-lib_modification_example.pdf", "application/pdf");
};

export const printInvoice = (invoice, user, practice) => {
  const blob = generateInvoiceTemplate(invoice, user, practice).blob;
  const blobUrl = URL.createObjectURL(blob);
  printJS(blobUrl);
};

export const dataInvoice = (charges, user, practice) => {
  const blob = generateInvoiceTemplate(charges, user, practice);
  return blob.blob;
};
