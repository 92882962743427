import React, { useMemo } from "react";
import styled from "styled-components";
import FeaCheckIcon from "icon/fea-icons/check";
import FeaCloseIcon from "icon/fea-icons/close";
import { BtnContainer } from "components/common";
import colors from "library/styled-components/colors";
import AppointmentActions from "components/calendar/components/AppointmentActions";
import { dayjs, timeZone } from "config";
import {
  AllInclusive as AllInclusiveIcon,
  Event as EventIcon,
} from "@mui/icons-material";

const CancelationReasons = {
  FORGOT: "Forgot",
  EMERGENCY: "Emergency",
  FAILED: "Did not Attend",
  SICK: "Illness",
  WORK: "Work",
  PERSONAL: "Personal reasons",
};

const ContainerBlock = styled.div`
  /* Container around content */
  padding: 0 1rem;
  position: relative;
  background-color: inherit;
  text-align: left;

  &::after {
    content: "";
    position: absolute;
    width: 0.2rem;
    background-color: ${(props) => props.complete};
    top: 2rem;
    bottom: -2rem;
    left: 2rem;
  }
`;

const WrapIcon = styled.div`
  position: absolute;
  width: 2rem;
  height: 2rem;
  left: 0.9rem;
  color: ${(props) => props.text};
  background-color: ${(props) => props.fill};
  border: 0.2rem solid ${(props) => props.border};
  top: 0;
  border-radius: 50%;
  z-index: 1;
  text-align: center;
  font-size: 1rem;
  line-height: 1.9rem;
  line-height: 2rem;
  font-weight: 900;
  svg {
    margin: 0.25rem;
  }
`;

const Content = styled.div`
  padding: 0.1rem 4rem;
  position: relative;
  border-radius: 3px;
  margin: 1rem 0 1rem 0;
  padding-bottom: 1rem;
`;

const ConsultHeader = styled.div`
  font-weight: bold;
  font-size: 1.1rem;
  margin: 0.25rem;
  display: flex;
  align-items: center;
  span {
    font-weight: normal;
    font-style: italic;
  }
`;

const ConsultInfo = styled.div`
  color: ${colors.rgbGreyDark};
  margin: 0.25rem;
  max-width: 40rem;
`;

const TimelineBlockItem = ({
  first,
  number,
  appointment,
  patientName,
  showInvoices,
  setCreateInvoice,
  setCancel,
  setUnCancel,
  updateAppointment,
  setAppointmentData,
  selected,
}) => {
  const completed = appointment?.Invoices?.length;
  const status = useMemo(
    () => ({
      completed: !!completed,
      cancelled: !!appointment.Cancelation,
    }),
    [completed, appointment.Cancelation]
  );

  const statusColor = useMemo(
    () =>
      status.completed
        ? colors.softGreen
        : (status.cancelled && colors.softRed) || colors.white,
    [status]
  );

  const borderColor = useMemo(
    () =>
      selected
        ? colors.primary
        : status.completed
        ? colors.softGreen
        : (status.cancelled && colors.softRed) || colors.rgbGreyLight,
    [status, selected]
  );

  const textColor = useMemo(
    () =>
      selected
        ? colors.primary
        : status.completed || status.cancelled
        ? colors.white
        : colors.rgbGreyLight,
    [status]
  );

  return (
    <ContainerBlock complete={first ? colors.white : borderColor}>
      <WrapIcon fill={statusColor} border={borderColor} text={textColor}>
        {(completed && <FeaCheckIcon size="23" />) || ""}
        {(appointment?.Cancelation && <FeaCloseIcon size="23" />) || ""}
        {!completed && !appointment?.Cancelation && number}
      </WrapIcon>
      <Content>
        <ConsultHeader>
          {!appointment?.Meta?.Recall ? (
            <EventIcon style={{ color: colors.primary }} />
          ) : (
            <AllInclusiveIcon style={{ color: colors.warning }} />
          )}
          &nbsp;
          {patientName},&nbsp;
          <span>
            {appointment.Practitioner && appointment.Practitioner.Name}
          </span>{" "}
          &nbsp;
          {status.cancelled && (
            <span>
              , Cancelled:{" "}
              {CancelationReasons[appointment?.Cancelation?.Reason]}
            </span>
          )}
          {status.completed && <div>Invoice Created</div>}
        </ConsultHeader>

        <ConsultInfo>
          {!status.complete &&
            `${
              !appointment?.Meta?.Recall ? "Appointment" : "Recall Appointment"
            } for ${patientName} scheduled ${
              appointment.Start &&
              dayjs(appointment.Start, timeZone).format(
                "[on] MMM. D, YYYY [at] HH:mm"
              )
            } with ${
              appointment.Practitioner && appointment.Practitioner.Name
            }`}
          {!!status.completed &&
            `${
              !appointment?.Meta?.Recall ? "Appointment" : "Recall Appointment"
            } for ${patientName} completed ${
              appointment.Start &&
              dayjs(appointment.Start, timeZone).format(
                "[on the] MMM. D, YYYY [at] HH:mm"
              )
            } with ${
              appointment.Practitioner && appointment.Practitioner.Name
            }`}
          {!!status.cancelled &&
            `${
              !appointment?.Meta?.Recall ? "Appointment" : "Recall Appointment"
            }  scheduled ${
              appointment.Start &&
              dayjs(appointment.Start, timeZone).format(
                "[for the] MMM. D, YYYY [at] HH:mm"
              )
            } cancelled ${
              appointment.Start &&
              dayjs(appointment.Cancelation.createdAt, timeZone).format(
                "[on the] MMM. D, YYYY [at] HH:mm"
              )
            }.`}
        </ConsultInfo>

        <span
          style={{
            margin: "0.25rem",
            color: colors.grey,
            fontStyle: "italic",
            fontSize: "0.9rem",
          }}
        >
          created{" "}
          {dayjs(appointment?.createdAt)
            .tz(timeZone)
            .format("MMM DD, YYYY HH:mm")}
          &nbsp; last updated{" "}
          {dayjs(appointment?.updatedAt)
            .tz(timeZone)
            .format("MMM DD, YYYY HH:mm")}
        </span>
        <span
          style={{
            margin: "0.25rem",
            color: colors.grey,
            fontStyle: "bold",
            fontSize: "0.9rem",
          }}
        >
          {appointment?.Cancelation?.Information
            ? `Addtional Info: ${appointment?.Cancelation?.Information}`
            : ""}
        </span>
        <BtnContainer>
          <AppointmentActions
            id={appointment.AppointmentId}
            row={true}
            updateAppointment={(data) =>
              updateAppointment(data, appointment.AppointmentId)
            }
            appointmentData={appointment}
            setCancel={() => {
              if (appointment?.Cancelation) {
                setUnCancel(appointment.AppointmentId);
              } else setCancel(appointment.AppointmentId);
            }}
            showInvoices={showInvoices}
            setCreateInvoice={setCreateInvoice}
            setReSchedule={() => setAppointmentData(appointment)}
          />
        </BtnContainer>
      </Content>
    </ContainerBlock>
  );
};

export default TimelineBlockItem;
