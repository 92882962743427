import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";

import colors from "library/styled-components/colors";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "28rem",
  },
  bar: {
    display: "flex",
    backgroundColor: colors.rgbGreyDark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.rgbGreyDark,
    "&:hover": {
      backgroundColor: colors.rgbGreyLight,
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "18ch",
      "&:focus": {
        width: "21ch",
      },
    },
  },
  leftItem: {
    width: "17rem",
  },
}));

export default function TreatmentListFlat({ list, closeList, addProceedure }) {
  const classes = useStyles();
  const [search, setSearch] = React.useState("");

  return (
    <div>
      <div className={classes.bar}>
        <AppBar className={classes.bar} position="static">
          <Toolbar>
            {closeList && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setSearch("");
                  closeList();
                }}
                size="large">
                <CloseOutlined />
              </IconButton>
            )}
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
            ></Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Treatments…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <List component="nav">
        {list
          ?.filter((treat) => {
            const str =
              treat.data?.Code?.toLowerCase() +
              " " +
              treat.data?.Name?.toLowerCase();
            return str && str.search(search.toLowerCase()) >= 0;
          })
          .sort((a, b) => a.data?.Name.localeCompare(b?.data?.Name))
          ?.map((treat) => (
            <ListItem
              button
              key={treat.data.id}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                addProceedure(treat.data);
              }}
            >
              <ListItemText
                className={classes.leftItem}
                primary={
                  <span
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      fontSize: "12px",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "bold",
                        width: "5rem",
                      }}
                    >
                      {treat.data.Code}
                    </span>{" "}
                    {treat.data?.Data?.SubCategory?.Name && (
                      <span
                        style={{
                          fontStyle: "italic",
                        }}
                      >
                        {treat.data?.Data?.SubCategory?.Name}
                      </span>
                    )}
                    <span
                      style={{
                        width: "10rem",
                      }}
                    >
                      {treat.data?.Name}
                    </span>
                  </span>
                }
              />{" "}
            </ListItem>
          ))}
      </List>
    </div>
  );
}
