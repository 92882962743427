import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Form from "../model-form/form";
import { Warning, Danger } from "../../components/common";
import MoreIcon from "../../icon/fea-icons/more-vertical";
import colors from "../../library/styled-components/colors";
import DeletePop from "./more-popover/delete";

const Container = styled.div`
  display: grid;
  font-weight: normal;
  padding: 1rem;
`;

const Header = styled.div`
  display: grid;
  margin-bottom: 1em;
`;

const MoreCont = styled.div`
  position: absolute;
  right: 1rem;
  z-index: 2;
  top: 2.2rem;
`;

const MoreIconRight = styled(MoreIcon)`
  cursor: pointer;
  border-radius: 50%;
  color: ${colors.rgbGrey};
  &:hover {
    color: ${colors.primary};
    background-color: ${colors.light};
  }
`;

function Editor({
  reset,
  defaultData,
  prePopData,
  metaData,
  warning,
  formSchema,
  submitError,
  buttons,
  cancelMethod,
  sendData,
  title,
  settings,
  callBack,
  onChange,
  more,
  removeClick,
}) {
  const [formSettings, setFormSettings] = useState(formSchema);
  const [formChanged, setFormChanged] = useState(false);
  const [moreOpen, setMoreOpen] = useState(false);

  useEffect(() => {
    initFrom(defaultData, formSchema, reset);
  }, [defaultData, formSchema, reset]);

  const checkParity = (change, current) => {
    if (prePopData && Object.values(prePopData).length > 0) return false;

    const newData = Object.values(formSettings)
      .filter((e) => !e.ignore)
      .map((e) =>
        e.val ? Number(e.val !== "" || e.val === undefined) : Number(!!e.val)
      );
    return current
      ? Object.entries(current)
          .filter(([name]) => {
            return change[name] && change[name].val; // && !change[name].ignore;
          })
          .map(([name, val]) => (change[name].val === val ? 0 : 1))
          .reduce((a, b) => a + b) === 0
      : newData.reduce((a, b) => a + b) !== newData.length;
  };

  const changeForm = (el, param, val) => {
    formSettings[el][param] = val;
    setFormSettings({ ...formSettings });
    setFormChanged(!checkParity(formSettings, defaultData));
  };

  const initFrom = async (defaultData, formSchema) => {
    if (!!submitError) return;
    const newData = { ...defaultData, ...prePopData };
    const passedInData = await Object.entries(formSchema)
      .map((entry) => entry)
      .reduce((obj, entry) => {
        const [key, element] = entry;
        obj[key] = {
          ...element,
          val: newData ? newData[key] : element.val,
        };
        return obj;
      }, {});

    if ((!!passedInData && !metaData) || !metaData.updating) {
      setFormSettings({ ...passedInData });
      setFormChanged(!checkParity(passedInData, defaultData));

      await setFormSettings(passedInData);
    } else {
      setFormChanged(false);
      await setFormSettings(formSchema);
    }
  };

  if (typeof metaData.disabled !== 'undefined' && !metaData.disabled)
    return <div />;

  return (
    <Container
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setMoreOpen(false);
      }}
    >
      {(submitError || warning) && (
        <Header>
          {submitError && <Danger> {submitError}</Danger>}
          {warning && <Warning> {warning}</Warning>}
        </Header>
      )}
      {defaultData && more && (
        <MoreCont>
          <MoreIconRight
            size={24}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setMoreOpen(!moreOpen);
            }}
          />
          {more && (
            <DeletePop
              showPop={moreOpen}
              setShowPop={setMoreOpen}
              removeClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setMoreOpen(!moreOpen);
                removeClick();
              }}
            />
          )}
        </MoreCont>
      )}
      <Form
        enabled={formChanged}
        title={
          metaData
            ? metaData.error
              ? metaData.error
              : metaData.warning
              ? metaData.warning
              : title
            : title
        }
        warning={metaData && (!!metaData.error || !!metaData.warning)}
        defaultData={defaultData}
        prePopData={prePopData}
        metaData={metaData}
        formData={formSettings}
        changeForm={changeForm}
        completeForm={(data) => {
          setFormChanged(false);
          sendData(data);
        }}
        cancelMethod={cancelMethod}
        buttons={buttons}
        submitError={submitError}
        settings={settings}
        callBack={callBack}
        onChange={onChange}
      />
    </Container>
  );
}

export default Editor;
