import React, { useContext, useEffect, useState } from "react";
import MotherIcon from "../../../../icon/mother";
import { Header, Body } from "../styles";
import StandardLayout from "../../../../layouts/standard";
import AddLayout from "../../../../layouts/components/add";
import InfoPanel from "../../../../layouts/components/info";
import { SubmitIconBtn } from "../../../../components/common";
import ChevRightFeaIcon from "../../../../icon/fea-icons/chevron-right";
import { AppContext } from "../../../../hooks/context";
import DoubleBounce from "../../../../components/loaders/double-bounce";
import colors from "../../../../library/styled-components/colors";
import { Add, Edit } from "styled-icons/material";
import Context from "../../../../components/context";
import PatientsTable from "./table";
import PatientForm from "../calendar/components/event-blocks/appointment-event/PatientForm";
import { Drawer } from "@mui/material";
import PatientDialog from "../patient/dialog";
import Accounts from "components/accounts";
import RecallHistory from "./RecallHistory";

export default function AdminPatients({ match, user }) {
  // const [state, dispatch] = useReducer(reducer, initialState);
  const { state } = useContext(AppContext);
  const [PatientId, setPatientId] = useState(null);
  const [overlay, setOverlay] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPatient, setShowPatient] = useState(false);
  const [showAccounts, setShowAccounts] = useState(false);
  const [showRecallHistory, setShowRecallHistory] = useState(false);
  const [appointmentExtendedProps, setAppointmentExtendedProps] =
    useState(null);
  const { patients } = state;

  async function loadData(param) {
    await setLoading(true);
    if (param.id) {
      await setPatientId(param.id);
      await setOverlay(false);
    }
    await setLoading(false);
  }

  useEffect(() => {
    loadData(match && match.params);
  }, [match]);

  return (
    <StandardLayout
      menu={true}
      meta={patients.meta}
      user={user}
      menuContent={
        <Header>
          <MotherIcon size="48" color="#ffffff" />
        </Header>
      }
    >
      <Body>
        <Context
          targetId="data-contentid"
          header="Options"
          menu={[
            {
              text: "Edit",
              key: "edit",
              icon: <Edit size="18" />,
              callback: (uuid) => {
                setPatientId(uuid);
                setOverlay(false);
              },
            },
          ]}
        />
        {patients?.data?.length > 0 ? (
          <PatientsTable
            list={patients.data}
            uuid={PatientId}
            navigateTo={(patient) => {
              setShowPatient(true);
              setAppointmentExtendedProps(patient);
            }}
            setShowRecallHistory={() => setShowRecallHistory(true)}
            showAccounts={(patient) => {
              setShowAccounts(true);
              setAppointmentExtendedProps({
                ...patient,
                User: patient,
              });
            }}
            clickable={true}
            schema={{
              title: "Name",
              content: [
                { key: "id", label: "Id" },
                { key: "Name", label: "Name" },
                { key: "Last", label: "Last Appointment" },
                { key: "Start", label: "Next Appointment" },
                //{ key: "Recall", label: "Recall Sent" },
                { key: "Address", label: "Address" },
                { key: "County", label: "County" },
                { key: "Email", label: "Email" },
                { key: "Phone", label: "Phone" },
              ],
              active: { key: "Consultations", find: "Active" },
              image: null,
              link: { path: "admin/home/patients", id: "PatientId" },
            }}
          />
        ) : loading ? (
          <DoubleBounce color={colors.primary} />
        ) : (
          <InfoPanel>
            <h1>Add a Patient</h1>
            <p>
              You must have at leaset one Patient in your pratice before you can
              start
            </p>
            <SubmitIconBtn onClick={() => setOverlay(true)} center={true}>
              Create Patient <ChevRightFeaIcon size="22" />
            </SubmitIconBtn>
          </InfoPanel>
        )}
        <AddLayout
          actions={[
            {
              key: "add",
              active: true,
              method: () => {
                setOverlay(true);
                setPatientId(null);
              },
              icon: <Add size="24" />,
            },
          ]}
        />
        <Drawer
          anchor={"right"}
          open={overlay}
          onClose={() => setOverlay(false)}
        >
          <div
            style={{
              margin: "1rem 0",
              padding: "0 1rem",
              width: "28rem",
            }}
          >
            <h4>Create a Patient</h4>
            <PatientForm close={() => setOverlay(false)} />
          </div>
        </Drawer>
        {showAccounts && appointmentExtendedProps && (
          <Accounts
            selectedPractitionerId={appointmentExtendedProps?.PractitionerId}
            patient={{
              ...appointmentExtendedProps,
              Name: appointmentExtendedProps?.User.Name,
              Practice: user?.data?.Practice,
            }}
            show={showAccounts}
            close={() => {
              setShowAccounts(false);
              setAppointmentExtendedProps(null);
            }}
          ></Accounts>
        )}
        {showRecallHistory ? (
          <RecallHistory
            show={showRecallHistory}
            close={() => setShowRecallHistory(false)}
          />
        ) : null}
      </Body>
      {showPatient && appointmentExtendedProps && (
        <PatientDialog // this is the patient dialog
          user={user}
          show={showPatient}
          appointmentExtendedProps={appointmentExtendedProps}
          close={() => {
            setShowPatient(false);
            setAppointmentExtendedProps(null);
          }}
        />
      )}
    </StandardLayout>
  );
}
