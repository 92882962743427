import React, { useState, useContext, useRef } from "react";
import ReactDomServer from "react-dom/server";
import {
  Button,
  ButtonGroup,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import {
  Add,
  Delete,
  Edit,
  EmojiEmotions,
  Message,
  Send,
} from "@mui/icons-material";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";
import { AppContext } from "hooks/context";
import { updatePractice } from "actions/practices";
import { ADMIN_NS } from "config";
import uuidv4 from "uuid/v4";
import ContentEditable from "react-contenteditable";

function getCaretPosition(node) {
  var range = window.getSelection().getRangeAt(0),
    preCaretRange = range.cloneRange(),
    caretPosition,
    tmp = document.createElement("div");

  preCaretRange.selectNodeContents(node);
  preCaretRange.setEnd(range.endContainer, range.endOffset);
  tmp.appendChild(preCaretRange.cloneContents());
  caretPosition = tmp.innerHTML.length;
  return caretPosition;
}

const TextareaAutosizeCustom = styled(TextareaAutosize)`
  resize: none;
  width: 100%;
  max-height: 16rem;
  font-size: 1rem;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  overflow: hidden;
  min-height: 4rem;
  margin: 0.5rem;
  border: 1px solid ${colors.borderColor};
  border-radius: 4px;
  outline: none;
  background: ${colors.light};
  padding: 1rem 9rem 1rem 1rem;
`;

const defaultMessage = `Here you can Type your own custom template \n {variables.patientName} with paramaters {variables.practitionerName}`;

const ParesedTempate = ({
  contentSaveString,
  variables,
  setContentSaveString,
}) => {
  return contentSaveString
    .replaceAll("{", ":")
    .replaceAll("}", ":")
    .split(":")
    .map((str) => {
      if (str.match("variables.")) {
        if (!variables) return;
        const element = variables[str.replace("variables.", "")];
        if (!element) return;
        return element.el(() => {
          if (!setContentSaveString) return;
          const removedTag = contentSaveString.replace(`:${element.id}:`, "");
          setContentSaveString(removedTag);
        });
      }
      return str;
    });
};
const EditableContent = ({
  setAddSms,
  saveTemplate,
  variables,
  templateTitle,
  setTemplateTitle,
  contentString,
}) => {
  const [contentSaveString, setContentSaveString] = useState(contentString);
  const text = useRef("");
  const [caret, setCaret] = useState({ start: 0, end: 0 });
  const handleBlur = () => {
    console.log(text.current);
  };

  function handleKeyDown(e) {
    const start = e.target.selectionStart;
    const end = e.target.selectionEnd;
    setCaret({ start, end });
  }

  return (
    <div
      style={{
        padding: "1rem",
        border: "1px dashed",
        borderRadius: "4px",
        background: colors.lightGrey,
      }}
    >
      <TextField
        style={{ margin: "0.5rem" }}
        value={templateTitle}
        onChange={(e) => setTemplateTitle(e.target.value)}
        label="Title"
        variant="outlined"
      />
      <TextareaAutosizeCustom
        id="editable"
        style={{
          width: "24rem",
          padding: "1rem",
        }}
        ref={text}
        value={contentSaveString}
        onKeyDown={handleKeyDown}
        onBlur={handleBlur}
        onChange={(e) => {
          let parsed = e.target.value;
          setContentSaveString(parsed);
          text.current = parsed;
        }}
      />
      <div
        style={{
          margin: "1rem 0.5rem",
        }}
      >
        {Object.values(variables).map((variable) => (
          <Chip
            style={{
              margin: "0.2rem",
              background: colors.accentColor,
              color: "white",
            }}
            size="small"
            label={variable.label}
            onClick={() => {
              const firstSection = contentSaveString.slice(0, caret.start);
              const secondSection = contentSaveString?.slice(
                caret.end,
                contentSaveString.length
              );
              const newString = `${firstSection ?? ""} {${variable.id}} ${
                secondSection ?? ""
              }`;

              setContentSaveString(newString);
            }}
          />
        ))}
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "end",
        }}
      >
        <Button
          style={{ margin: "0.2rem" }}
          color="primary"
          variant="contained"
          onClick={() => {
            saveTemplate(templateTitle, contentSaveString);
          }}
        >
          Save
        </Button>
        <Button
          style={{ margin: "0.2rem" }}
          variant="outlined"
          onClick={() => setAddSms(false)}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

const ToolBar = ({ disabled, sendSms, selectedUser }) => {
  const { state } = useContext(AppContext);
  const { practice, list } = state;

  const [textToSend, setTextToSend] = useState(null);

  const [emojiAnchorEl, setEmojiAnchorEl] = React.useState(null);
  const emojiOpen = Boolean(emojiAnchorEl);

  const [autoAnchorEl, setAutoAnchorEl] = useState(null);
  const autoOpen = Boolean(autoAnchorEl);

  const [addSms, setAddSms] = useState(false);
  const [edit, setEdit] = useState([]);

  const [deleteAnchorEl, setDeleteAnchorEl] = React.useState(null);
  const deleteOpen = Boolean(deleteAnchorEl);

  const [contentString, setContentString] = useState(defaultMessage);
  const [templateTitle, setTemplateTitle] = useState("Template Title");

  let variables = {
    patientName: {
      id: "variables.patientName",
      value: selectedUser?.Name,
      label: "Patient Name",
      el: (callback) => (
        <Chip
          id="variables.patientName"
          variant="outlined"
          size="small"
          color="primary"
          contenteditable="false"
          label="Patient Name"
          //onDelete={callback}
        />
      ),
    },
  };
  list?.data.forEach((prac) => {
    variables[prac.data.Name] = {
      id: `variables.${[prac.data.Name]}`,
      value: `${prac.data.Name}`,
      label: `${prac.data.Name}`,
      el: (callback) => (
        <Chip
          id={`variables.${prac.data.Name}`}
          variant="outlined"
          size="small"
          color="primary"
          contenteditable="false"
          label={`${prac.data.Name}`}
          //onDelete={callback}
        />
      ),
    };
  });

  return (
    <>
      <TextareaAutosizeCustom
        id="textinput"
        minRows={4}
        maxRows={12}
        disabled={disabled}
        value={textToSend}
        onChange={(e) => setTextToSend(e.target.value)}
      />
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          right: "1rem",
          display: "flex",
        }}
      >
        {(practice?.data.Data?.features ?? []).includes("templates") && (
          <div
            style={{
              position: "relative",
            }}
          >
            <IconButton
              onClick={(event) => setAutoAnchorEl(event.currentTarget)}
              style={{ color: colors.info }}
              size="large"
            >
              <Message />
            </IconButton>
            <Popover
              id={"templates"}
              open={autoOpen}
              anchorEl={autoAnchorEl}
              onClose={() => setAutoAnchorEl(null)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <List
                component="nav"
                style={{
                  width: "30rem",
                  margin: "1rem",
                  maxHeight: "30rem",
                  overflow: "auto",
                }}
              >
                {addSms ? (
                  <EditableContent
                    title="Tempate Name"
                    contentString={contentString}
                    setContentString={setContentString}
                    templateTitle={templateTitle}
                    setTemplateTitle={setTemplateTitle}
                    variables={variables}
                    setAddSms={setAddSms}
                    saveTemplate={(title, template) => {
                      updatePractice(ADMIN_NS, {
                        ...practice?.data,
                        Data: {
                          ...practice?.data.Data,
                          templates: [
                            ...(practice?.data.Data?.templates || []),
                            {
                              id: uuidv4(),
                              type: "sms",
                              title,
                              body: template,
                            },
                          ],
                        },
                      });
                      setAddSms(false);
                    }}
                  />
                ) : (
                  <Button
                    style={{ marginBottom: "1rem" }}
                    variant="outlined"
                    color="primary"
                    startIcon={<Add />}
                    onClick={() => {
                      setAddSms(true);
                      setTemplateTitle("");
                      setContentString("");
                    }}
                  >
                    Add New Template
                  </Button>
                )}
                {practice?.data.Data?.templates &&
                  practice?.data.Data?.templates
                    .filter((t) => t?.type === "sms")
                    ?.map((tmp) => {
                      let tmpText = tmp.body;
                      Object.values(variables).forEach((v) => {
                        tmpText = tmpText.replaceAll(`{${v.id}}`, v.value);
                      });

                      return edit === tmp.id ? (
                        <EditableContent
                          contentString={contentString}
                          setContentString={setContentString}
                          templateTitle={templateTitle}
                          setTemplateTitle={setTemplateTitle}
                          variables={variables}
                          setAddSms={() => {
                            setEdit(null);
                          }}
                          saveTemplate={(title, template) => {
                            updatePractice(ADMIN_NS, {
                              ...practice?.data,
                              Data: {
                                ...practice?.data.Data,
                                templates: practice?.data.Data?.templates.map(
                                  (t) =>
                                    tmp.id === t.id
                                      ? {
                                          id: tmp.id,
                                          type: "sms",
                                          title,
                                          body: template,
                                        }
                                      : t
                                ),
                              },
                            });
                            setAddSms(false);
                            setEdit(null);
                          }}
                        />
                      ) : (
                        <>
                          <ListItem button>
                            <div
                              style={{
                                position: "absolute",
                                right: "0",
                                top: "0",
                              }}
                            >
                              <IconButton
                                aria-label="delete"
                                color="secondary"
                                size="large"
                              >
                                <Delete
                                  style={{
                                    border: "1px solid",
                                    borderRadius: "50%",
                                    padding: "2px",
                                  }}
                                  onClick={(event) =>
                                    setDeleteAnchorEl(event.currentTarget)
                                  }
                                />
                              </IconButton>
                              <IconButton
                                color="primary"
                                onClick={(e) => {
                                  setEdit(tmp.id);
                                  setContentString(tmp.body);
                                  setTemplateTitle(tmp.title);
                                }}
                                size="large"
                              >
                                <Edit
                                  style={{
                                    border: "1px solid",
                                    borderRadius: "50%",
                                    padding: "2px",
                                  }}
                                />
                              </IconButton>

                              <Popover
                                id={"confirm"}
                                open={deleteOpen}
                                anchorEl={deleteAnchorEl}
                                onClose={() => setDeleteAnchorEl(null)}
                                anchorOrigin={{
                                  vertical: "top",
                                  horizontal: "center",
                                }}
                                transformOrigin={{
                                  vertical: "bottom",
                                  horizontal: "left",
                                }}
                              >
                                <div
                                  style={{
                                    margin: "0.4rem",
                                  }}
                                >
                                  <Typography align="center" variant="h6">
                                    Are you sure?
                                  </Typography>
                                  <div>
                                    <Button
                                      style={{
                                        margin: "0.4rem",
                                      }}
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => {
                                        updatePractice(ADMIN_NS, {
                                          ...practice?.data,
                                          Data: {
                                            ...practice?.data.Data,
                                            templates:
                                              practice?.data.Data?.templates.filter(
                                                (t) => t.id !== tmp.id
                                              ),
                                          },
                                        });
                                        setDeleteAnchorEl(null);
                                      }}
                                    >
                                      Delete
                                    </Button>
                                    <Button
                                      style={{
                                        margin: "0.4rem",
                                      }}
                                      onClick={() => setDeleteAnchorEl(null)}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </Popover>
                            </div>
                            <ListItemText
                              style={{
                                width: "20rem",
                              }}
                              onClick={() => {
                                setTextToSend(tmpText);
                                setAutoAnchorEl(null);
                              }}
                              primary={tmp.title}
                              secondary={
                                <div
                                  style={{
                                    whiteSpace: "pre-wrap",
                                  }}
                                >
                                  <ParesedTempate
                                    contentSaveString={tmp.body}
                                    variables={variables}
                                  />
                                </div>
                              }
                            />
                          </ListItem>
                          <Divider />
                        </>
                      );
                    })}
              </List>
            </Popover>
          </div>
        )}
        <div
          style={{
            position: "relative",
          }}
        >
          <IconButton
            onClick={(event) => setEmojiAnchorEl(event.currentTarget)}
            style={{ color: colors.info }}
            size="large"
          >
            <EmojiEmotions />
          </IconButton>
          <Popover
            id={"emoji"}
            open={emojiOpen}
            anchorEl={emojiAnchorEl}
            onClose={() => setEmojiAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <EmojiPicker
              onEmojiClick={(data) => {
                setTextToSend(
                  textToSend ? `${textToSend} ${data.emoji}` : data.emoji
                );
                setEmojiAnchorEl(null);
              }}
            />
          </Popover>
        </div>
        <Button
          variant="contained" 
          endIcon={<Send />}
          onClick={async () => {
            sendSms(textToSend);
            setTextToSend("");
          }}
          disabled={!textToSend}
        >
          Send
        </Button>
      </div>
    </>
  );
};

export default ToolBar;
