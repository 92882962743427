/*
 * Name: rdbreducer
 * Persistant Relational data Management Reducer
 * This library contains the most common mutations for managing relational content
 *
 * to do: Add Schema defintion support
 * to do: Add Meta Data Definition support
 * to do: Add Graphql integration
 */

import store from "store";

function saveStore(storeState, saveState, ctx, namespace) {
  if (saveState[ctx].data && saveState[ctx].data.length > 0)
    store.set(namespace, {
      ...storeState,
      [ctx]: saveState[ctx].data?.map((data) => data.data),
    });
}

export function baseReducer(namespace) {
  return function reducer(state, action) {
    let saveState = null;
    let storeState = false; //namespace === "admin-state" && store.get(namespace);
    switch (action.type) {
      case "REPLACE":
        saveState = {
          ...state,
          [action.context]: {
            data: action.data,
            meta: { updating: action.updating },
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      case "LOAD_ONE":
        saveState = {
          ...state,
          [action.context]: { data: action.data, meta: { updating: false } },
        };
        return saveState;
      case "LOAD":
        const data = action?.data?.map((data) => ({
          data,
          meta: { updating: false },
        }));
        saveState = {
          ...state,
          [action.context]: { data, meta: { updating: false } },
        }; 
        return saveState;
      case "ADD":
        saveState = {
          ...state,
          [action.context]: {
            ...state[action.context],
            data: [
              ...state[action.context].data,
              { data: action.data, meta: { updating: false } },
            ],
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      case "UPDATE":
        saveState = {
          ...state,
          [action.context]: {
            ...state[action.context],
            data: action.data ? action.data : state[action.context].data,
            meta: action.meta ? action.meta : state[action.context].meta,
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      case "REMOVE_ITEM":
        saveState = {
          ...state,
          [action.context]: {
            meta: state[action.context].meta,
            data: state[action.context].data.filter(
              (i) =>
                Object.values(action.uuid)[0] !==
                i.data[Object.keys(action.uuid)]
            ),
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      case "UPDATE_ITEM":
        saveState = {
          ...state,
          [action.context]: {
            meta: state[action.context].meta,
            data: state[action.context].data.map((i) => {
              if (
                Object.values(action.uuid)[0] ===
                i.data[Object.keys(action.uuid)]
              ) {
                let item = {
                  meta: { updating: action.updating },
                };

                if (action.data) {
                  item = { ...item, data: action.data };
                }
                return {
                  ...i,
                  ...item,
                };
              }
              return i;
            }),
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      case "UPDATE_ITEM_CHILD":
        saveState = {
          ...state,
          [action.context]: {
            meta: state[action.context].meta,
            data: state[action.context].data.map((i) => {
              if (
                Object.values(action.uuid)[0] ===
                i.data[Object.keys(action.uuid)]
              ) {
                let item = {
                  meta: { updating: action.updating },
                };

                if (action.data) {
                  item = {
                    ...item,
                    data: {
                      ...i.data,
                      [action.childContext]: [
                        ...(i.data[action.childContext] || []), 
                        ...action.data[action.childContext],
                      ],
                    },
                  };
                }
                return {
                  ...i,
                  ...item,
                };
              }
              return i;
            }),
          },
        };
        if (storeState)
          saveStore(storeState, saveState, action.context, namespace);
        return saveState;
      default:
        return state;
    }
  };
}
