import { handleResponse, authHeader } from "../../library/handler";

export const loadPractice = (payload) => ({
  type: "LOAD_PRACTICE_SINGLE",
  payload,
});

export async function getPublicPractice(uuid) {
  return await fetch(`${process.env.REACT_APP_APP_API}/practices/id/${uuid}`)
    .then((response) => response.json())
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getLastEventUuid() {
  return await fetch(`${process.env.REACT_APP_APP_API}/meta/lastEventUuid`)
    .then((response) => response.json())
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getPractice(uuid) {
  return await fetch(`${process.env.REACT_APP_APP_API}/practices/id/${uuid}`)
    .then((response) => response.json())
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getAuthPractice = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/practices/token/auth`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updatePractice = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/practices/update`, {
    headers: await authHeader(state),
    method: "post",
    body: JSON.stringify(data),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export async function getPracticeByRef(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/practices/ref/${uuid}`)
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function createPractice(data) {
  return fetch(`${process.env.REACT_APP_APP_API}/practices/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getGraphData = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/graph`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getCommentTags = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/tags`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getDocuments = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/files/${uuid}`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
