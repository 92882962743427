import React, {  useContext, useEffect, useState } from "react";
import DentIcon from "../../../../icon/dentist";
import { Header, Body } from "../styles";
import DataTable from "../../../../components/table";
import StandardLayout from "../../../../layouts/standard";
import AddLayout from "../../../../layouts/components/add";
import InfoPanel from "../../../../layouts/components/info";
import { SubmitIconBtn } from "../../../../components/common";
import styled from "styled-components";
import { removeTreatment } from "./actions";
import { Add, Edit, CalendarToday } from "styled-icons/material";
import EditFeaIcon from "../../../../icon/fea-icons/edit";
import { Overlay, OverlayBackGround } from "../../../../layouts/lib/common";
import ChevRightFeaIcon from "../../../../icon/fea-icons/chevron-right";
import Context from "../../../../components/context";
import colors from "../../../../library/styled-components/colors";
import { getList } from "library/resources";
import { getTreatments } from "actions/treatments";
import { getCategories } from "actions/categories";
import TreatmentForm from "./componenets/TreatmentForm";
import { AppContext } from "hooks/context";

const EditAction = styled.button`
  display: grid;
  width: 4rem;
  grid-template-columns: 1em auto;
  align-items: center;
  cursor: pointer;
  background: ${colors.grey};
  border: none;
  outline: none;
  color: white;
  border-radius: 0.2rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;


export default function AdminTreatments({ match, user }) {
  const { state, dispatch } = useContext(AppContext);
  const [TreatmentId, setTreatmentId] = useState(null);
  const [overlay, setOverlay] = useState(0);
  const [loading, setLoading] = useState(true);
  const { treatments, categories } = state;

  async function loadData(param) {
    await setLoading(true);
    if (param.id) {
      await setTreatmentId(param.id);
      await setOverlay(1);
    }
    await setLoading(false);
  }

  async function removeItem() {
    const uuid = TreatmentId;
    await setTreatmentId(null);
    await removeTreatment(uuid)(dispatch);
    dispatch({
      type: "UPDATE",
      context: "treatments",
      meta: { updating: false, warning: null },
    });
    setOverlay(2);
  }

  useEffect(() => {
    loadData(match && match.params);
  }, [match]);

  const selectedTreatment =
    TreatmentId &&
    treatments.data.find((l) => l.data.TreatmentId === TreatmentId)?.data;

  return (
    <StandardLayout
      menu={true}
      meta={treatments.meta}
      user={user}
      menuContent={
        <Header>
          <DentIcon size="48" color="#ffffff" />
        </Header>
      }
    >
      <OverlayBackGround status={overlay}>
        <Overlay key="editorOverlay" status={overlay}>
          <TreatmentForm
            selectedTreatment={selectedTreatment}
            categories={categories}
            cancelMethod={() => {
              setOverlay(2);
              setTreatmentId(null);
            }}
          ></TreatmentForm>
        </Overlay>
      </OverlayBackGround>
      <Body>
        <Context
          targetId="data-contentid"
          header="Options"
          menu={[
            {
              text: "Edit",
              icon: <Edit size="18" />,
              callback: (uuid) => {
                setTreatmentId(uuid);
                setOverlay(1);
              },
            },
            {
              text: "Edit Schedule",
              icon: <CalendarToday size="18" />,
              callback: async (uuid) => {},
            },
          ]}
        />
        {treatments && treatments.data.length > 0 ? (
          <DataTable
            data={{
              title: "Treatments",
              placeholder: "Search for Treatments",
            }}
            list={treatments.data.map((t) => ({
              data: { ...t.data, Category: t.data?.Category?.Name },
            }))}
            uuid={TreatmentId}
            editItem={async (uuid) => {
              setTreatmentId(uuid);
              setOverlay(1);
            }}
            actions={[
              <EditAction
                key="edit"
                onClick={async (e) => {
                  e.preventDefault();
                  const uuid = e.target.parentNode.parentNode.id;
                  await setTreatmentId(uuid);
                  await setOverlay(1);
                }}
              >
                <EditFeaIcon size="14" />
                <span>Edit</span>
              </EditAction>,
            ]}
            schema={{
              title: "Name",
              content: [
                { key: "Name", label: "Name" },
                { key: "Code", label: "Code" },
                { key: "Description", label: "Description" },
                { key: "Category", label: "Category" },
                { key: "Price", label: "Price" },
              ],
              image: "Profile",
              link: { path: "admin/home/treatments", id: "TreatmentId" },
            }}
          />
        ) : (
          !loading && (
            <InfoPanel>
              <h1>Add a Treatment</h1>
              <p>
                You must have at lease one Treatment before you can start
                creating appointments
              </p>
              <SubmitIconBtn onClick={() => setOverlay(1)} center={true}>
                Create Treatment <ChevRightFeaIcon size="22" />
              </SubmitIconBtn>
            </InfoPanel>
          )
        )}
        <AddLayout
          actions={[
            {
              key: "add",
              active: true,
              method: () => {
                setOverlay(1);
                setTreatmentId(null);
              },
              icon: <Add size="24" />,
            },
          ]}
        />
      </Body>
    </StandardLayout>
  );
}
