import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";

const TimeSlideContainer = styled.div`
  display: grid;
  margin: 1rem 0;
`;

const SlideContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 9fr;
  margin: 1em 0;
  align-items: center;
`;

const Slide = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 2em;
  margin-right: 1em;
  ${props =>
    `background: linear-gradient(90deg, ${colors.primColor} ${(props.value /
      props.max) *
      100}%, ${colors.primColor} ${(props.value / props.max) * 100 + 0.1}%);`}

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2em;
    height: 2em;
    background: ${colors.accentColor};
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 2em;
    height: 2em;
    background: ${colors.accentColor};
    cursor: pointer;
    border-radius: 50%;
  }
`;

const SlideVal = styled.span`
  display: inline-block;
  position: relative;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: ${colors.borderColor};
  color: ${colors.primColor};
  padding: 10px 0;
  font-size: 18px;
`;

const TimeContainer = styled.div`
  display: grid;
  margin: 10px;
  grid-template-columns: auto 50px 10px 50px auto;
  grid-gap: 2px;
  align-items: end;
`;

const Hours = styled.div`
  display: grid;
  grid-column-start: 2;
  grid-column-end: 2;
`;

const Label = styled.div`

`;

const Seperator = styled.div`
  display: grid;
  grid-column-start: 3;
  grid-column-end: 3;
  display: inline-block;
  position: relative;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  color: ${colors.primColor};
  padding: 10px 0;
  font-size: 18px;
`;

const Minutes = styled.div`
  display: grid;
  grid-column-start: 4;
  grid-column-end: 4;
`;

const TimeSlider = ({ time, setTime, defaultTime }) => {
  const [hour, setHour] = useState({
    min: 0,
    max: 23,
    val: time.hour,
    step: 1
  });
  const [minute, setMinute] = useState({
    min: 0,
    max: 60,
    val: time.min,
    step: 1
  });

  useEffect(() => {
    setHour({
      ...hour,
      val: defaultTime ? defaultTime.time.hour : time.hour
    });
    setMinute({
      ...minute,
      val: defaultTime ? defaultTime.time.min : time.min
    });
  }, [defaultTime]);

  return (
    <TimeSlideContainer>
      <TimeContainer>
        <Hours>
          <SlideVal>{hour.val.toString().padStart(2, "0")}</SlideVal>
        </Hours>
        <Seperator>:</Seperator>
        <Minutes>
          <SlideVal>{minute.val.toString().padStart(2, "0")}</SlideVal>
        </Minutes>
      </TimeContainer>
      <SlideContainer>
        <Label>Hours</Label>
        <Slide
          type="range"
          value={hour.val}
          min={hour.min}
          max={hour.max}
          step={hour.step}
          onChange={e => {
            setHour({
              ...hour,
              val: e.target.value
            });
            setTime({ min: minute.val, hour: e.target.value });
          }}
        />
      </SlideContainer>
      <SlideContainer>
        <Label>Minutes</Label>
        <Slide
          type="range"
          value={minute.val}
          min={minute.min}
          max={minute.max}
          step={minute.step}
          onChange={e => {
            setMinute({
              ...minute,
              val: e.target.value
            });
            setTime({ hour: hour.val, min: e.target.value });
          }}
        />
      </SlideContainer>
    </TimeSlideContainer>
  );
};

export default TimeSlider;
