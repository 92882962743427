import React, { useLayoutEffect, useMemo, useState } from "react";
import styled from "styled-components";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import {
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import Alert from "@mui/lab/Alert";
import OutlinedInput from "@mui/material/OutlinedInput";
import DropdownFilter from "components/dropdown-filter";
import TreatmentListDrawer from "../../patient/components/treaments/TreatmentListDrawer";

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
  width: 18rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

const ChargeForm = ({
  patientId,
  treatmentId,
  patients,
  practitioners,
  treatments,
  createCharge,
  cancelMethod,
  error,
  disableAppointment,
}) => {
  const [openPatientList, setOpenPatientList] = useState(false);
  const [list, setList] = useState(false);
  const [form, setForm] = useState({
    amount: null,
    patientId,
    treatmentId,
  });

  const selectedUser = useMemo(
    () =>
      form.patientId &&
      patients.data &&
      patients.data.find((p) => p.data.PatientId === form.patientId).data,
    [form, patients]
  );

  useLayoutEffect(() => {
    setForm({
      ...form,
      practitionerId: practitioners?.data[0]?.data.PractitionerId,
      patientId,
    });
  }, [practitioners, patientId]);
 
  return (
    <FormContainer>
      <h4>Add a Charge</h4>
      {error && (
        <InputContainer>
          <Alert severity="error">{error}</Alert>
        </InputContainer>
      )}
      <FormControl variant="outlined">
        <InputContainer>
          <InputLabel>Practitioner</InputLabel>
          {practitioners?.data.length && form.practitionerId && (
            <Select
              disabled={disableAppointment}
              value={form.practitionerId}
              defaultValue={form.practitionerId}
              onChange={(e) => {
                //if (e.target.value === "new-pracitioner")
                //setOpenPratitioner(true);
                //else
                setForm({ ...form, practitionerId: e.target.value });
              }}
              label="Practitioner"
            >
              {practitioners.data.map((p) => (
                <MenuItem
                  key={p.data.PractitionerId}
                  value={p.data.PractitionerId}
                >
                  {p.data.Name}
                </MenuItem>
              ))}
            </Select>
          )}
        </InputContainer>
      </FormControl>

      <FormControl variant="outlined">
        <InputContainer>
          {!selectedUser && (
            <DropdownFilter
              onFocus={() => setOpenPatientList(true)}
              open={openPatientList}
              dim={{
                position: "absolute",
                height: 188,
                width: 244,
                itemSize: 10,
                margin: "0rem",
              }}
              placeholder={"Search Patient"}
              options={patients ? patients.data.map((p) => p.data) : []}
              selectedUser={selectedUser}
              setSelectedUser={(item) => {
                setForm({ ...form, patientId: item.PatientId });
                setOpenPatientList(false);
              }}
              loading={false}
            />
          )}
          {selectedUser && (
            <ListItem
              component="nav"
              button
              alignitems="flex-start"
              onClick={() => setForm({ ...form, patientId: null })}
            >
              <ListItemAvatar>
                <Avatar alt={selectedUser && selectedUser.Name}>
                  {selectedUser &&
                    selectedUser.Name.split(" ")
                      .map((i) => i.charAt(0).toUpperCase())
                      .slice(0, 2)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography component="div">{selectedUser && selectedUser.Name}</Typography>
                }
                secondary={"Click here to change patient!"}
              />
            </ListItem>
          )}
        </InputContainer>
      </FormControl>

      <FormControl variant="outlined">
        <InputContainer>
          {!form.proceedure && (
            <Button
              disabled={disableAppointment}
              onClick={() => {
                setForm({ ...form, proceedure: null });
                setList(true);
              }}
            >
              Select Treatment
            </Button>
          )}
          {form.proceedure && (
            <ListItem
              component="nav"
              button
              alignitems="flex-start"
              onClick={() => setList(true)}
            >
              <ListItemAvatar>
                <Avatar alt={form.proceedure.Name}>
                  {form.proceedure.Name.split(" ")
                    .map((i) => i.charAt(0).toUpperCase())
                    .slice(0, 1)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Typography component="div">
                    {form.proceedure.Name.substring(0, 18)}...
                  </Typography>
                }
                secondary={"Click here to change treatment!"}
              />
            </ListItem>
          )}
        </InputContainer>
      </FormControl>

      <FormControl fullWidth variant="outlined">
        <InputLabel htmlFor="outlined-adornment-amount">Amount</InputLabel>
        <OutlinedInput
          id="outlined-adornment-amount"
          value={form.amount}
          onChange={(e) => {
            setForm({ ...form, amount: e.target.value });
          }}
          startAdornment={<InputAdornment position="start">€</InputAdornment>}
          labelWidth={60}
        />
      </FormControl>

      <Row>
        <FormControl>
          <Button
            onClick={() => createCharge(form)}
            variant="contained"
            disabled={disableAppointment}>
            Create
          </Button>
        </FormControl>
        <FormControl>
          <Button onClick={cancelMethod} variant="contained" >
            Cancel
          </Button>
        </FormControl>
      </Row>

      <TreatmentListDrawer
        open={!!list}
        status={2}
        singleSelect
        list={treatments.data}
        closeList={() => setList(false)}
        addProceedure={(proceedure) => {
          setForm({ ...form, proceedure, amount: proceedure.Price });
          setList(false);
        }}
      />
    </FormContainer>
  );
};

export default ChargeForm;
