import React from "react";

function EuroIcon(props) {
  const { size } = props;
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 325.799 325.799"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M285.306 296.302c-73.7 28.4-156.5-8.4-184.9-82.2-.5-1.4-1-2.7-1.5-4.1h147.8c5.5 0 10-4.5 10-10s-4.5-10-10-10h-153.1c-3-15.8-3.3-32.1-1-48h170.1c5.5 0 10-4.5 10-10s-4.5-10-10-10h-165.6c22.7-75.6 102.5-118.5 178.2-95.8 3.3 1 6.7 2.1 10.1 3.4 5.1 2 10.9-.5 13-5.7 2-5.1-.5-10.9-5.7-13h-.1c-84-32.3-178.3 9.6-210.7 93.6-2.2 5.7-4.1 11.5-5.6 17.4h-39.6c-5.5 0-10 4.5-10 10s4.5 10 10 10h35.7c-2 16-1.7 32.1.9 48h-36.6c-5.5 0-10 4.5-10 10s4.5 10 10 10h41.3c26.1 86.2 117.1 134.9 203.2 108.9 3.8-1.2 7.6-2.5 11.3-3.9 5.1-2 7.7-7.8 5.7-12.9-2-5.1-7.8-7.7-12.9-5.7z" />
    </svg>
  );
}

export default EuroIcon;
