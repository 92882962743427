import {
  IconButton,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import React from "react";
import CurrencyTextField from "./CurrencyField";
import colors from "library/styled-components/colors";

const paymentOptions = [
  { key: 1, name: "Cash", value: "CASH" },
  { key: 2, name: "Credit Card", value: "CREDIT" },
  { key: 3, name: "Debit Card", value: "DEBIT" },
  { key: 4, name: "Invoice", value: "INVOICE" },
  { key: 5, name: "Practice Plan", value: "PLAN" },
  { key: 6, name: "Cheque", value: "CHEQUE" },
  { key: 7, name: "Refund", value: "REFUND" },
];

const RenderPaymentItem = ({
  proc,
  form,
  handleToggle,
  setForm,
  practitioners,
}) => {
  return (
    <TableRow
      button
      disabled={proc.disabled}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleToggle(proc.id)();
      }}
    >
      <TableCell padding="checkbox">
        <IconButton
          disabled={false}
          onClick={(e) =>
            setForm({
              ...form,
              payments: form.payments.filter((p) => p.id !== proc.id),
            })
          }
          aria-label="up"
          size="large"
        >
          <HighlightOffIcon size="small" />
        </IconButton>
      </TableCell>

      <TableCell>
        Payment by - &nbsp;
        <Select
          onChange={(e) =>
            setForm({
              ...form,
              payments: form.payments.map((p) => {
                if (p.id === proc.id) {
                  return {
                    ...p,
                    Method: e.target.value,
                    Price: e.target.value === "REFUND" ? -p.Price : p.Price,
                  };
                }
                return p;
              }),
            })
          }
          label={"Choose Method"}
          defaultValue="Choose Method"
          value={proc?.Method}
          options={paymentOptions}
          disabled={proc?.Method === "REFUND"}
        >
          <MenuItem value={"CASH"}>Cash</MenuItem>
          <MenuItem value={"CREDIT"}>Credit Card</MenuItem>
          <MenuItem value={"DEBIT"}>Debit Card</MenuItem>
          <MenuItem value={"INVOICE"}>Invoice</MenuItem>
          <MenuItem value={"PLAN"}>Practice Plan</MenuItem>
          <MenuItem value={"CHEQUE"}>Cheque</MenuItem>
          <MenuItem value={"REFUND"}>Refund</MenuItem>
        </Select>
      </TableCell>
      <TableCell align="right">
        <Select
          variant="outlined"
          onChange={(e) =>
            setForm({
              ...form,
              payments: form.payments.map((p) => {
                if (p.id === proc.id) {
                  return {
                    ...p,
                    PractitionerId: e.target.value,
                  };
                }
                return p;
              }),
            })
          }
          label={"Choose Practitioner"}
          defaultValue="Choose Practitioner"
          value={proc?.PractitionerId}
        >
          {practitioners?.data.map((p) => (
            <MenuItem key={p.data.PractitionerId} value={p.data.PractitionerId}>
              {p.data.Name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="right"> </TableCell>
      <TableCell align="right">
        <CurrencyTextField
          label="Amount"
          variant="standard"
          style={{ width: "100%", color: colors.green }}
          value={
            form.payments.find((c) => c.id === proc.id)?.UnParsedPrice ||
            form.payments.find((c) => c.id === proc.id)?.Price
          }
          maximumValue={
            form.payments.find((c) => c.id === proc.id)?.Price < 0
              ? "0"
              : "1000000000000000"
          }
          //defaultValue={form.payments.find((c) => c.id === proc.id)?.Price}
          currencySymbol="€"
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          //onFocus={(event) => {
          //event.target.select();
          //}}
          selectOnFocus={false}
          onChange={(e, value) => {
            setForm({
              ...form,
              payments: form.payments.map((c) => {
                if (c.id === proc.id) {
                  return {
                    ...c,
                    UnParsedPrice: value,
                  };
                }
                return c;
              }),
            });
          }}
          onBlur={(e, value) => {
            setForm({
              ...form,
              payments: form.payments.map((c) => {
                if (c.id === proc.id) {
                  return {
                    ...c,
                    Price: parseFloat(value) || 0,
                    UnParsedPrice: null,
                  };
                }
                return c;
              }),
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default RenderPaymentItem;
