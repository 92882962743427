import React from "react";
import styled from "styled-components";

const PopoverContainer = styled.div`
  position: absolute;
  display: ${(props) => (props.show ? `grid` : `none`)};
  top: ${(props) => props.top};
  left: ${(props) => props.left};
  right: ${(props) => props.right};
  background: white;
  box-shadow: 0px 2px 10px #999999;
  z-index: 408;
  padding: 1em;
  margin: 0.5rem;
  border-radius: 4px;
`;

const Popover = ({ visible, left, right, top, children }) => {
  return (
    <PopoverContainer left={left} right={right} top={top} show={visible}>
      {children}
    </PopoverContainer>
  );
};

export default Popover;
