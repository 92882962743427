import React from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { Button, ButtonGroup, Checkbox } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import CloseOutlined from "@mui/icons-material/CloseOutlined";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxRounded from "@mui/icons-material/AddBoxRounded";
import IndeterminateCheckBoxRounded from "@mui/icons-material/IndeterminateCheckBoxRounded";
import colors from "library/styled-components/colors";

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "28rem",
  },
  bar: {
    display: "flex",
    backgroundColor: colors.rgbGreyDark,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.rgbGreyDark,
    "&:hover": {
      backgroundColor: colors.rgbGreyLight,
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  leftItem: {
    width: "17rem",
  },
}));

export default function TreatmentList({
  status,
  list,
  closeList,
  addProceedure,
  singleSelect = false,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [proceedures, setProceedures] = React.useState([]);
  const [search, setSearch] = React.useState("");

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
      setProceedures([
        ...proceedures,
        {
          id: value,
          data: list.find((t) => t.data.id === value).data,
          status,
          qty: 1,
        },
      ]);
    } else {
      newChecked.splice(currentIndex, 1);
      setProceedures(proceedures.filter((q) => q.id !== value));
    }

    setChecked(newChecked);
  };

  return (
    <div>
      <div className={classes.bar}>
        <AppBar className={classes.bar} position="static">
          <Toolbar>
            {closeList && (
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={() => {
                  setChecked([]);
                  setProceedures([]);
                  setSearch("");
                  closeList();
                }}
                size="large">
                <CloseOutlined />
              </IconButton>
            )}
            <Typography
              className={classes.title}
              variant="h6"
              noWrap
            ></Typography>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </Toolbar>
        </AppBar>
      </div>
      <List component="nav">
        {list
          ?.filter((cat) => {
            const str =
              cat.data?.Code?.toLowerCase() +
              " " +
              cat.data?.Name?.toLowerCase();
            return str && str.search(search.toLowerCase()) >= 0;
          })
          ?.map((cat) =>
            singleSelect ? (
              <ListItem
                button
                key={cat.data.id}
                onClick={() => {
                  addProceedure(cat.data);
                  setProceedures([]);
                  setChecked([]);
                }}
              >
                <ListItemText
                  className={classes.leftItem}
                  primary={
                    <span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {cat.data.Code}
                      </span>{" "}
                      {cat.data?.Data?.SubCategory?.Name && (
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          {cat.data?.Data?.SubCategory?.Name}
                        </span>
                      )}
                    </span>
                  }
                  secondary={cat.data.Name}
                />{" "}
              </ListItem>
            ) : (
              <ListItem
                button
                key={cat.data.id}
                onClick={handleToggle(cat.data.id)}
              >
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(cat.data.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText
                  className={classes.leftItem}
                  primary={
                    <span>
                      <span
                        style={{
                          fontWeight: "bold",
                        }}
                      >
                        {cat.data.Code}
                      </span>{" "}
                      {cat.data?.Data?.SubCategory?.Name && (
                        <span
                          style={{
                            fontStyle: "italic",
                          }}
                        >
                          {cat.data?.Data?.SubCategory?.Name}
                        </span>
                      )}
                    </span>
                  }
                  secondary={cat.data.Name}
                />
                {checked.indexOf(cat.data.id) !== -1 && (
                  <ButtonGroup
                    variant="text"
                    size="small"
                    orientation="vertical"
                  >
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setProceedures(
                          proceedures.map((q) => {
                            if (q.id === cat.data.id) {
                              return { ...q, qty: q.qty + 1 };
                            }
                            return q;
                          })
                        );
                      }}
                      aria-label="delete"
                      size="large">
                      <AddBoxRounded />
                    </IconButton>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        setProceedures(
                          proceedures.map((q) => {
                            if (q.id === cat.data.id) {
                              return {
                                ...q,
                                qty: q.qty > 1 ? q.qty - 1 : 1,
                              };
                            }
                            return q;
                          })
                        );
                      }}
                      aria-label="delete"
                      size="large">
                      <IndeterminateCheckBoxRounded />
                    </IconButton>
                  </ButtonGroup>
                )}
                <ListItemText
                  primary={`€${
                    proceedures.find((q) => q.id === cat.data.id)?.qty *
                      cat.data.Price || cat.data.Price
                  }`}
                  secondary={`QTY: ${
                    proceedures.find((q) => q.id === cat.data.id)?.qty || 0
                  }`}
                />
              </ListItem>
            )
          )}
      </List>
      {!singleSelect && (
        <div
          style={{
            position: "fixed",
            bottom: 0,
            width: "28rem",
            background: colors.softGreen,
          }}
        >
          <Button
            onClick={() => {
              addProceedure(proceedures);
              setProceedures([]);
              setChecked([]);
            }}
            className={classes.fullWidth}
          >
            Add Proceedure
          </Button>
        </div>
      )}
    </div>
  );
}
