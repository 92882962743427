import React, { useContext, useState } from "react";
import { AppContext } from "hooks/context";
import styled, { css } from "styled-components";
import DataTable from "components/table";
import { DeleteAction, EditAction } from "layouts/lib/common";
import TrashFeaIcon from "icon/fea-icons/trash";
import ImageIcon from "@mui/icons-material/Image";
import { formatRelative, format } from "date-fns";
import ConfirmModal from "components/editor/more-popover/modal";
import FileUpload from "./components/FileUpload";
import PreviewFileLoader from "components/file-handlers/components/PreviewFileLoader";
import { ADMIN_NS } from "config";
import { deleteDocument } from "actions/consultations";

const Container = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 3px;
  height: calc(100% - 8rem);
  overflow-y: auto;
`;

const Viewer = styled.div`
  display: grid;
  ${(props) =>
    props.perview &&
    css`
      grid-template-columns: auto 30%;
    `}
`;

function DocumentsTab() {
  const { state, dispatch } = useContext(AppContext);
  const { patient } = state;

  const [selectedFileId, setSelectedFileId] = useState(null);
  const [deleteFileId, setDeleteFileId] = useState(null);
  return (
    <Container>
      {patient.data.Files?.length > 0 ? (
        <Viewer perview={!!selectedFileId}>
          <DataTable
            data={{
              title: "Patient Documents",
              placeholder: "Search for Documents",
            }}
            addItem={
              <FileUpload
                patientId={patient.data.PatientId}
                practiceId={patient.data.PracticeId}
                user={patient.data.User}
              />
            }
            list={patient.data.Files?.map((row) => ({
              data: {
                ...row,
                Size: `${(row.Data.size / 1048576).toFixed(2)} MB`,
                Type: row.Data.mimetype,
                createdAt: format(new Date(row.createdAt), "Mo MMMM yyyy"),
                updatedAt: formatRelative(new Date(row.updatedAt), new Date()),
              },
            }))}
            uuid={selectedFileId}
            schema={{
              title: "Name",
              content: [
                { key: "id", label: "Id" },
                { key: "Name", label: "Name" },
                { key: "Size", label: "Size" },
                { key: "Type", label: "Type" },
                { key: "createdAt", label: "Created" },
                { key: "updatedAt", label: "Last updated" },
              ],
              active: { key: "Consultations", find: "Active" },
              image: null,
              link: { id: "FileId" },
            }}
            actions={[
              <EditAction
                key="preview"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  const uuid = e.target.parentNode.parentNode.id;
                  setSelectedFileId(uuid);
                }}
              >
                <ImageIcon style={{ width: "12px", height: "12px" }} />
                <span>preview</span>
              </EditAction>,
              <DeleteAction
                key="delete"
                onClick={(e) => {
                  e.preventDefault();
                  const uuid = e.target.parentNode.parentNode.id;
                  setDeleteFileId(uuid);
                }}
              >
                <TrashFeaIcon size="14" />
                <span>delete</span>
              </DeleteAction>,
            ]}
          />
          {selectedFileId && (
            <PreviewFileLoader
              data={patient.data.Files?.find(
                (row) => row?.FileId === selectedFileId
              )}
            />
          )}
        </Viewer>
      ) : (
        <FileUpload
          patientId={patient.data.PatientId}
          practiceId={patient.data.PracticeId}
          user={patient.data.User}
          noFiles
        />
      )}
      <ConfirmModal
        showModal={!!deleteFileId}
        hideModal={(e) => {
          e.stopPropagation();
          setDeleteFileId(false);
        }}
        continueClick={async () => {
          setDeleteFileId(false);
          const response = await deleteDocument(
            ADMIN_NS,
            deleteFileId,
            patient.data.PatientId
          );
          response &&
            response.code === "SUCCESS" &&
            dispatch({
              type: "LOAD_ONE",
              context: "patient",
              data: {
                ...patient.data,
                Files: patient.data.Files.filter(
                  (n) => n.FileId !== deleteFileId
                ),
              },
              updating: false,
            });
        }}
      />
    </Container>
  );
}

export default DocumentsTab;
