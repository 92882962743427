import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";

const Container = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: 2.2em;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
  color: ${colors.danger};
  line-height: 0.8rem;
  font-size: 0.8rem;
  margin: 1rem 0 0.2rem 0;
  font-weight: 400;
  text-align: right;
`;

const Area = styled.label`
  display: grid;
  color: ${colors.info};
  cursor: pointer;
  justify-items: center;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 1fr;
  border: 1px solid #bdc4c9;
  border-radius: 4px;
  padding: 1em;
`;

const Button = styled.button`
  outline: none;
  font-size: 0.9rem;
  line-height: 1.2rem;
  border: 1px solid ${colors.info};
  background-color: ${(props) => (!props.val ? colors.txtColor : colors.info)};
  cursor: pointer;
  align-self: center;
  margin-top: 0;
  padding: 11px 25px;
  justify-items: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => (!props.val ? colors.info : colors.txtColor)};
  svg {
    color: ${(props) => (!props.val ? colors.info : colors.txtColor)};
  }

  :hover {
    background-color: ${colors.info};
    color: ${colors.txtColor};
    svg {
      color: ${colors.txtColor};
    }
  }
`;

export default function FormButton({ onCallback, LabelData, icon }) {
  const [btn, toggleBtn] = useState(false);

  return (
    <Container>
      <LabelContainer>
        <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
        {LabelData.error && (
          <ErrorMessage htmlFor={LabelData.text}>
            {LabelData.error}
          </ErrorMessage>
        )}
      </LabelContainer>
      <Area>
        <Button
          val={btn}
          onClick={() => {
            toggleBtn(!btn);
            onCallback();
          }}
        >
          <div>{LabelData.text}</div>
          {icon}
        </Button>
      </Area>
    </Container>
  );
}
