import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74.373"
      height="246"
      viewBox="0 0 74.373 246"
    >
      <g id="bottom6" transform="translate(-708.126 -498.367)">
        <g id="bounds">
          <line
            id="bound"
            x2="70"
            transform="translate(709.5 744.367)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="70"
            transform="translate(709.5 498.367)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(716.271 509.151)">
          <path
            id="shape"
            d="M43.346.545A19.606,19.606,0,0,1,54.57,9.1c2.309,3.87,2.822,8.329,2.733,12.724-.088,4.425-.739,8.757-3.383,12.122-2.644,3.334-7.244,5.641-11.957,7.947-4.713,2.276-9.538,4.521-15.083,5.447-5.545.9-11.736.442-16.474-1.887A19.506,19.506,0,0,1,.894,34.545C-.592,30.26-.391,25.9.224,21.832.8,17.769,1.718,14.006,4.1,10.969S10.3,5.681,14.413,4.03A60.451,60.451,0,0,1,27.9.488C32.878-.292,38.5-.858,43.346.545Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="o"
            d="M15.874,28.253c-.358-.716,1.094-1.889.983-3.9s-2.479-3.685-1.429-4.152c.426-.19,2.462-.039,4.307-.735,1.99-.75,3.1-2.391,3.926-2.389s2.461.861,5.846.2c1.773-.348,4.208-2.373,6.069-2.164.611.068,1.406.954,3.057,1.964s3.659.87,3.548,2.075a54.429,54.429,0,0,0-.223,5.69c.043,1.147.424,2.611-.2,3.057s-2.038.192-3.124.892c-2.428,1.583-4.618,3.3-5.533,3.325s-.807-.722-3.28-.645c-2.007.064-5.136,1.179-7.386.645-.766-.182-.737-.919-1.629-1.315C18.233,29.673,16.232,28.969,15.874,28.253Z"
            fill="#eaeaea"
          ></path>
          <path
            id="m"
            d="M10.406,45.453A19.506,19.506,0,0,1,.894,34.545C-.592,30.26-.391,25.9.224,21.832c.257-1.808,11.688-3.721,16.668,2.652s-2.176,7.425-3.948,12.488C11.144,42.125,11.8,46.139,10.406,45.453Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="mb"
            d="M23.2,47.725a27.619,27.619,0,0,1-12.11-1.953c-.043-.015-.239-.11-.239-.11s.423-2.063,1.242-5.317c.328-1.32.954-4.136,2.28-5.78.5-.622,1.829-2.022,2.966-3.344a13.7,13.7,0,0,0,.912-1.209s4.742.659,6.277.857C29.31,31.486,24.651,47.643,23.2,47.725Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="b"
            d="M39.532,43.052A48.421,48.421,0,0,1,26.88,47.34c-1.015.164-3.411.373-3.411.373A20.363,20.363,0,0,0,25.721,41.6a32.567,32.567,0,0,0,.856-6.832,8.325,8.325,0,0,0-.869-3.442s6.942-3.4,8.176-3.789C39.53,25.776,40.528,42.584,39.532,43.052Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="db"
            d="M53.92,33.946c-2.644,3.334-7.244,5.641-11.957,7.947-.495.239-2.215,1.055-2.215,1.055a52.232,52.232,0,0,0-1.185-10.4,9.053,9.053,0,0,0-2.771-4.807s3.678-4.408,4.957-5.972C45.027,16.545,56.564,30.582,53.92,33.946Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="d"
            d="M48.393,2.841A23.07,23.07,0,0,1,54.57,9.1c2.309,3.87,2.822,8.329,2.733,12.724-.078,3.938-.6,7.8-2.745,11.234-.027.046.026-3.646-4.68-8.531A17.692,17.692,0,0,0,45.486,21.2a9.242,9.242,0,0,0-2.911-.626S41.081,15.73,40.3,13.718C37.682,6.97,48.393,2.841,48.393,2.841Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="dl"
            d="M43.346.545A19.748,19.748,0,0,1,48.2,2.707c.59.353-4.54,1.872-7.008,5.253a8.554,8.554,0,0,0-1.272,4.319s-4.286-.778-5.973-.96C28.3,10.71,30.991.085,32.406-.08a26.259,26.259,0,0,1,10.94.625Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="l"
            d="M14.413,4.03A60.451,60.451,0,0,1,27.9.488c1.251-.2,2.542-.378,4.164-.552.587-.063-1.894,3-1.894,5.736a9.848,9.848,0,0,0,1.9,5.209s-5.7,5.426-8.156,6C19.111,18.011,12.462,4.813,14.413,4.03Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
          <path
            id="ml"
            d="M.34,21.507s1.378-7.5,3.762-10.538S13.881,4.3,13.881,4.3a21.764,21.764,0,0,0,3.136,7.834,17.008,17.008,0,0,0,4.874,4.518,9.473,9.473,0,0,1-2.34,3.226A22.536,22.536,0,0,1,15.189,22.7a14.351,14.351,0,0,0-2.957-1.536A20.081,20.081,0,0,0,6.878,20.03,11,11,0,0,0,.34,21.507Z"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 9.009, -6.177)"
            fill="#f5f5f5"
          ></path>
        </g>
        <g id="crown">
          <path
            id="crown-2"
            data-name="crown"
            d="M3410.22,518.492s14.609-30.467,9.99-37.273-10.324-13.127-20.241-11.02-12.09,9.561-12.09,9.561-6.113-9.886-16.98-6.969c-10.487,2.815-15.914,16.8-13.457,23.056.031.08.937,6.694,3.306,10.755,3.086,5.286,7.135,8.072,7.135,11.3,0,2.672,9.392,2.957,19.339,2.512C3398.37,519.914,3410.22,518.492,3410.22,518.492Z"
            transform="translate(-2641.61 115.078)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root">
          <path
            id="rb2"
            d="M53.8,0s2.807,40.971-4.121,53.876S30.064,85.554,26.124,80.935,28.31,71.169,30.1,57.08c.882-6.946,3.78-15.168,3.88-19.539.089-3.9-2.633-3.847-2.633-3.847a62.143,62.143,0,0,1-1.7-14.867c.069-3.2,1.82-6.332,2.691-9.35A9.866,9.866,0,0,0,32,3.1s5-.513,5.536-.577C45.709,1.555,53.8,0,53.8,0"
            transform="translate(714.801 630.751) rotate(3)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="rb1"
            d="M726.275,633.545a292.831,292.831,0,0,1-6.222,36.622c-3.469,14.225-9.752,28.553-9.755,36.179-.008,17.175,8.726,3.7,15.393-4.461s6.458-18.989,11.275-28.188c2.909-5.556,4.854-6.793,6.312-7.351a6.808,6.808,0,0,1,1.026-.335l-1.026-8.234a30.315,30.315,0,0,1,.469-8.278c.887-4.217,2.719-6.035,3.079-8.591.787-5.592-.4-5.5-.4-5.5s-9.3.429-14.351,0S726.275,633.545,726.275,633.545Z"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r3"
            d="M3913.05,2852.74c.662,1.967-.726,22.751-6.836,36.4s-15.033,25.3-16.006,24.18,11.074-25.291,14.765-40.434-1.128-19.248,0-20.14S3912.39,2850.77,3913.05,2852.74Z"
            transform="translate(-3146.474 -2206.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r2"
            d="M3909.18,2854.41c.662,1.967,1.267,21.153-4.843,34.8s-8.713,22.978-9.687,21.859,9.3-28.1,8.731-38.62-1.128-16.378,0-17.271S3908.51,2852.44,3909.18,2854.41Z"
            transform="translate(-3154.474 -2206.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M3900.16,2847.2c.282.839-.543,18.74-5.786,34.142s-14.023,28.181-15.186,27.464,8.288-22.708,12.223-36.813,2.2-22.435,2.963-24.792c.331-1.019,1.354-.207,2.753-.307C3898.24,2846.79,3899.78,2846.08,3900.16,2847.2Z"
            transform="translate(-3164.684 -2200.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(729.872 540.089)">
            <g id="abscess" transform="translate(-21.154 163.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(37.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g
              id="cracked"
              transform="matrix(-0.766, 0.643, -0.643, -0.766, 29.176, 66.549)"
            >
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(-12 97.133)">
              <path
                id="p1-4"
                data-name="p1"
                d="M1110.9,670.327s12.889,8.54,25.173,8.54,23.962-8.54,23.962-8.54"
                transform="translate(-1107.654 -674.222)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures" transform="translate(-397.156 -37)">
            <g id="hyg" transform="translate(1111.374 630.784)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(1126.88 641.485)">
              <path
                id="path1"
                d="M3388.69,501s-1.06-2.4-1.555-9.665,3.914-15.252-.2-17.7-4.725,2.429-8.488,2.873c-2.948.348-10.74-3.932-13.038-1.443-3.043,3.286-.844,6.718,0,13.788a56.142,56.142,0,0,1,0,13.03s5.918.39,13.038.11C3386.43,501.672,3388.69,501,3388.69,501Z"
                transform="translate(-3360.682 -470.566)"
                fill="red"
              ></path>
            </g>
            <g id="resection" transform="translate(1116.481 695.856)">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982l43.767,51.806"
                transform="translate(-3.415 -66.982)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="seal" transform="translate(1126.88 556.708)">
              <path
                id="seal-2"
                data-name="seal"
                d="M752.659,514.944s-32.148,3.852-32.148,9.139c0,2.8,10.771,5.6,19.353,7.4,7.183,1.508,12.25,2,12.8,4.607,1.16,5.551-27.51,10.2-27.51,10.2"
                transform="translate(-720.511 -514.944)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="implant" transform="translate(1126.88 658.499)">
              <path
                id="p5"
                d="M806.424,410.878l-1.793-15.719s7.166-8.37,12.64-11.2,3.523-2.77,9.136,0,13.258,10.96,13.258,10.96l-.153,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M809.01,384.517l-2.138-10.682,31.8-8.6-1.81,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3"
                d="M810.643,366.894l-1.814-10.675,27.966-7.444L835.27,360.31Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M812.375,348.918l-1.4-10.534,24-5.9-1.92,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-801.474 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(1105.282 570.5)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0H74.373"
                transform="translate(0 83)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H74.373"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(1154.667 713.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(1122.029 658.874)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2-2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-7"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
