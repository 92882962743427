import React from "react";
import { Button, Box } from "@mui/material";
import { WrapMessage, Message } from "components/sms-client/styles";
import colors from "library/styled-components/colors";
import { format } from "date-fns";
import SendIcon from "@mui/icons-material/Send";
import DoubleBounce from "components/loaders/double-bounce";

const remindAppointment = (recipient, date) =>
  `Hi ${
    recipient.split(" ")[0]
  },\nYou have a dental appointment at Glanmire Dental Practice ${
    date ? `on ${format(new Date(date), "yyyy-MM-dd hh:mm a")}` : ""
  }.\nPlease call 021 4821133 if you need to change this appointment.`;

const recallAppointment = (recipient) =>
  `Hi ${
    recipient.split(" ")[0]
  },\nYou are due a dental appointment at Glanmire Dental Practice\n
  Please call 021 4821133 to arrange an appointment.`;

const SMSInputEditor = ({
  updating,
  date,
  patientName,
  sendMessageToQueue,
  close,
  type,
}) => {
  return (
    <Box margin={2}>
      <WrapMessage
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: colors.lightGrey,
          padding: "1rem",
          borderRadius: "4px",
          whiteSpace: "pre-line",
        }}
      >
        <Message type={1}>
          {type === "reminder"
            ? remindAppointment(patientName, date)
            : recallAppointment(patientName)}
        </Message>
      </WrapMessage>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "1rem",
        }}
      >
        <Button
          variant="outlined"
          style={{
            display: "flex",
            marginTop: "1rem",
          }}
          onClick={close}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          style={{
            display: "flex",
            marginTop: "1rem",
            marginLeft: "1rem",
          }}
          onClick={sendMessageToQueue}
          startIcon={<SendIcon />}
        >
          {updating ? "Sending..." : "Send to Queue"}
          {updating && <DoubleBounce color={colors.white} />}
        </Button>
      </div>
    </Box>
  );
};

export default SMSInputEditor;
