import React, { useState } from "react";
import styled, { css } from "styled-components";
import colors from "../../../../library/styled-components/colors";
import { IconButton } from "../../../common";

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  margin-left: 0.5em;
`;

const Switch = styled.div`
  position: relative;
  display: grid;
  width: 60px;
  height: 34px;
  margin: 0.5em;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
`;

const Slider = styled.div`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
  }
  ${(props) =>
    props.selected &&
    css`
      background-color: ${colors.info};
      box-shadow: 0 0 1px ${colors.info};;
      &:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    `}
`;

const Icon = styled.span`
  position: absolute;
  right: 14px;
`;

const ToogleItem = ({ onCallback, index, active, label, icon }) => {
  const [toggle, setToggle] = useState(active);
  return (
    <ItemContainer>
      <Switch>
        <input readOnly={true} checked={toggle} type="checkbox" />
        <Slider
          selected={toggle}
          onClick={() => {
            setToggle(!toggle);
          }}
        ></Slider>
      </Switch>
      <IconButton
        disabled={!toggle}
        onClick={() => {
          onCallback(index);
        }}
        size="large">
        {label}
        <Icon>{icon}</Icon>
      </IconButton>
    </ItemContainer>
  );
};

export default ToogleItem;
