import localforage from "localforage";

const smsUpdate = (eventData) => async (dispatch) => {
  const messages = await localforage.getItem("sms");
  let data =  messages.map((a) => {
    if (a.Sid === eventData.Sid) {
      return { ...a, ...eventData };
    }
    return a;
  });
  
  dispatch({
    type: "LOAD",
    context: "sms",
    data,
  });
  await localforage.setItem("sms", data);
};

const smsAdd = (eventData) => async (dispatch) => {
  const messages = await localforage.getItem("sms");
  let data = [...messages.filter((a) => a.Sid !== eventData.Sid), eventData];

  dispatch({
    type: "LOAD",
    context: "sms",
    data,
  });
  await localforage.setItem("sms", data);
};

export default {
  updateSms: smsUpdate,
  addSms: smsAdd,
};
