import React from "react";
import img from "../../imgs";
import styled from "styled-components";
import colors from "../../../../library/styled-components/colors";

const Section = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.reverse ? "column-reverse" : "column")};

  cursor: pointer;
  width: 70px;
  text-align: center;
  font-size: 1rem;
  :hover {
    background-color: ${colors.lightGrey};
  }
  background-color: ${(props) =>
    props.active ? colors.lightGrey : colors.white};

  #surface,
  #root {
    path:hover {
      stroke: ${colors.darkBlue} !important;
      stroke-width: 3 !important;
    }
  }
`;

const ToothNumber = styled.div`
  padding: 1rem;
  background-color: ${(props) => (props.active ? colors.white : colors.dark)};
  background-color: ${(props) =>
    props.active ? colors.rgbInfo : colors.white};
  :hover {
    background-color: ${colors.lightGrey};
  }
`;

const Tooth = (props) => img[props.id](props);

const ToothSelector = (props) => {
  function setToothState(selector) {
    const state = {
      ...props.state,
      selected: props.state.selected.includes(selector)
        ? props.state.selected.filter((s) => s !== selector)
        : [...props.state.selected, selector],
    };
    props.setChartState(state);
  }

  return (
    <Section active={props.state.selected.length} reverse={props.reverse}>
      <Tooth
        {...props}
        toothClick={(id, parentId) => {
          if (id) {
            const selector = `#${parentId} #${id}`;
            setToothState(selector);
          }
        }}
      ></Tooth>
      <ToothNumber
        active={props.state.selected.length}
        onClick={() => {
          if (props.state.selected.length) {
            props.setChartState({
              ...props.state,
              selected: [],
            });
          } else {
            const selector = `# #${props.id}`;
            setToothState(selector);
          }
        }}
      >
        {props.id.slice(-1)}
      </ToothNumber>
    </Section>
  );
};

export default ToothSelector;
