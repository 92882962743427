import moment from 'moment'

const initialState = {
  list: [],
}

const practitioners = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_PRACTITIONER_LIST':
      return {
        ...state,
        list: action.payload
      }
    case 'LOAD_PRACTITIONER_SINGLE':
      return {
        ...state,
        list: action.payload,
      }
    default:
      return state
  }
}

export default practitioners
