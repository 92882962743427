import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/lab";
import FormHelperText from "@mui/material/FormHelperText";
import { format } from "date-fns";

const PickerContainer = styled.div`
  position: relative !important;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

export default function DentDatePicker({
  defaultDate,
  InputData,
  onChange,
  LabelData,
  onCallback,
}) {
  const [date, setDate] = useState(null);

  useEffect(() => {
    const update =
      InputData.value || (defaultDate ? defaultDate.format() : moment());
    setDate(update);
    onChange(update);
  }, [defaultDate, InputData.value]);

  return (
    <PickerContainer>
      <InputContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={LabelData.text}
            defaultValue={InputData.defaultValue}
            disabled={InputData.disable}
            value={date}
            inputAdornmentProps={{ position: "start" }}
            onChange={(date) => {
              const tDate = format(new Date(date), "yyyy-MM-dd");
              onChange(tDate);
              onCallback(tDate);
            }}
          />
          <FormHelperText>{LabelData.error}</FormHelperText>
        </LocalizationProvider>
      </InputContainer>
    </PickerContainer>
  );
}
