import React from "react";
import * as Joi from '@hapi/joi';
import MailFeaIcon from "../../../icon/fea-icons/mail";
import LockFeaIcon from "../../../icon/fea-icons/lock";

const validation = {
  Email: Joi.string()
    .min(5)
    .max(255)
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false }
    }),
  Password: Joi.string()
    .min(10)
    .max(255)
    .required()
};

export const formSchema = {
  Email: {
    domRef: null,
    email: true,
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter your Email address",
    icon: <MailFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email })
  },
  Password: {
    domRef: null,
    isRequired: true,
    password: true,
    text: "Password",
    type: "password",
    val: "",
    placeholder: "Please enter a secure password",
    icon: <LockFeaIcon size="16" />,
    validation: Joi.object({ Password: validation.Password })
  }
};

export const createButtons = [
  {
    text: "Login",
    type: "Submit",
  }
];
