import React from "react";
import { Container, Content, Header, HeaderWrap } from "./styles";
import dentlogo from "../../img/dent_w_logo.png";
import BookSimpleLogo from "../../logos/BookSimpleLogo";
import { Logo } from "../../components/common";

export default function Layout({ menuContent, children, menu = false }) {
  return (
    <Container>
      {menu && (
        <Header>
          <HeaderWrap>
            {process.env.REACT_APP_NAME === "book-simple" ? (
              <BookSimpleLogo
                style={{ height: "38px", placeSelf: "center" }}
                size="38px"
              />
            ) : (
              <Logo src={dentlogo} alt="logo" height="38px" />
            )}
            {menuContent ? menuContent : <div />}
          </HeaderWrap>
        </Header>
      )}
      <Content>{children}</Content>
    </Container>
  );
}
