import React from "react";
import colors from "../../library/styled-components/colors";
import DoubleBounce from "../../components/loaders/double-bounce";
import { SideContainer, SideHeader, Heading, SideContent } from "./styles";

export default function Layout({ meta, children, heading }) {
  return (
    <SideContainer>
      {heading && (
        <SideHeader>
          <Heading>{heading}</Heading>
        </SideHeader>
      )}
      <SideContent>
        {meta.updating && (
          <div>
            <DoubleBounce color={colors.primColor} margin="50%" />
          </div>
        )}
        {children}
      </SideContent>
    </SideContainer>
  );
}
