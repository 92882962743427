import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { Comment } from "@mui/icons-material";
import { EventBlock, EventBlockContainer } from "./Common";

const CommentEventBlock = ({ info, setAppointmentEventPreview }) => {
  const Notes =
    info.event.extendedProps?.Meta?.Comments?.map((c) => c.text) || [];

  return (
    <EventBlockContainer
      onClick={(e) => setAppointmentEventPreview(info.event.extendedProps)}
    >
      <Tooltip
        arrow
        placement="top-end"
        title={
          <React.Fragment>
            <Typography color="inherit">{info.event?.title}</Typography>
            {Notes.map((n) => (
              <Typography 
              key={n}
              component="div">-{n}</Typography>
            ))}
          </React.Fragment>
        }
      >
        <EventBlock>
          <div style={{ display: "flex" }}>
            <i>{info.timeText}</i>
          </div>
          <div>{info.event.extendedProps?.Meta?.Title}</div>

          <Comment color={Notes.length ? "success" : "disabled"} />
        </EventBlock>
      </Tooltip>
    </EventBlockContainer>
  );
};

export default CommentEventBlock;
