import React, { useReducer, useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import store from "store";
import styled from "styled-components";
import dayjs from "dayjs";
import calendar from "dayjs/plugin/calendar";
import PublicLayout from "../../../layouts/public";
import { reducer, initialState } from "./reducer";
import colors from "../../../library/styled-components/colors";
import SchedulesBlock from "../../../components/blocks/schedule-block";
import PublicRegisterBlock from "../../../components/blocks/public-register-block";
import LoginBlock from "../../../components/blocks/login-block";
import ResetBlock from "../../../components/blocks/reset-block";
import PinCodeBlock from "../../../components/blocks/pin-code-block";
import { SubmitBtn } from "../../../components/common";
import { FadeDiv } from "../../../library/styled-components";
import { NavigateBefore, NavigateNext } from "styled-icons/material";
import CalIcon from "../../../icon/calendar";
import loginPng from "../../../img/login.png";
import { addDays, setDay } from "date-fns";
import {
  fetchData,
  selectSlot,
  changeDate,
  checkBooking,
  registerUser,
  loginUser,
  sendPinCode,
  sendResetPassword,
  fetchSlots,
  fetchNewWeekSlots,
} from "./actions";
import TermsModal from "../../../components/terms";
import { checkAuthStatus } from "../../../actions/public";
import { PUBLIC_NS } from "../../../config";

dayjs.extend(calendar);

const Container = styled.div`
  @media (min-width: 481px) {
    width: 481px;
  }
  @media (max-width: 481px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em;
`;

const DateBlock = styled.div`
  display: grid;
  grid-template-columns: 1.6em auto 1.6em;
  position: relative;
  text-align: center;
  color: #ffffff;
  margin: 1rem;
  align-items: center;
`;

const CenterDate = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3em auto;
  grid-gap: 1em;
  justify-content: center;
`;

const DateText = styled(FadeDiv)`
  margin: auto 0;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 2rem;
`;

const NavItem = styled.div`
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  border-radius: 50%;
  color: ${(props) => (props.active ? colors.txtColor : colors.primColor)};
  :hover {
    background-color: rgba(256, 256, 256, 0.2);
  }
`;

const Body = styled.div`
  display: grid;
  grid-template-rows: auto 8em 2em;
  justify-items: center;
  align-items: center;
  margin: 2rem;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.2rem;
`;

export default function RegisterBooking({
  match,
  user = { data: null, meta: { updating: false } },
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [practiceId] = useState(match.params.uuid);
  const [resetPin, setResetPin] = useState(false);
  const [terms, showTerms] = useState(false);
  const [slideState, setSlideState] = useState({
    current: match.params.step,
    in: match.params.step,
    out: null,
  });

  const { schedules, practices, appointment, date, firstdate, slot, list } =
    state;

  const selectedSlot =
    slot &&
    slot.data &&
    schedules?.data?.find((s) => s.data.ScheduleId === slot.data);

  const futureSchedules = useMemo(
    () =>
      state.schedules &&
      state.schedules?.data
        ?.map((s) => ({
          ...s.data,
          dateTime:
            setDay(new Date(), s.data.Day) < new Date()
              ? addDays(setDay(new Date(), s.data.Day), 7)
              : setDay(new Date(), s.data.Day),
          Day: s.data.Day === 0 ? 7 : s.data.Day,
        }))
        .filter((a) => a.TypeId === 1)
        .sort((a, b) => a.Day - b.Day),
    [state.schedules]
  );

  const scheduleMap = useMemo(
    () => [...new Set(futureSchedules?.map((s) => s.Day))],
    [futureSchedules]
  );
 
  const [fadeState, setFadeState] = useState({
    in: false,
    out: false,
  });

  async function onNavigateDate(direction, active = true) {
    if (!active) return false;
    await setFadeState({ out: true, in: false });
    await new Promise((resolve) => setTimeout(resolve, 500));
    const dayIndex = scheduleMap.indexOf(date.data.isoWeekday());

    if (direction === "forward") {
      const nextIndex = scheduleMap[dayIndex + 1] || scheduleMap[0];
      let next = null;
      if (!nextIndex) {
        next = dayjs().add(1, "week");
        await fetchNewWeekSlots({ data: next }, practiceId)(dispatch);
      } else if (scheduleMap[dayIndex + 1]) {
        next = date.data.day(nextIndex);
        await fetchSlots({ data: next }, practiceId)(dispatch);
        dispatch({
          type: "REPLACE",
          context: "date",
          data: next,
          updating: false,
        });
      } else {
        next = date.data.add(1, "week").day(nextIndex);
        await fetchSlots({ data: next }, practiceId)(dispatch);
        dispatch({
          type: "REPLACE",
          context: "date",
          data: next,
          updating: false,
        });
      }
    }

    if (direction === "back") {
      const prevIndex =
        scheduleMap[dayIndex - 1] || scheduleMap[scheduleMap.length - 1];
      let prev = null;
      if (scheduleMap[dayIndex - 1]) {
        prev = date.data.day(prevIndex);
      } else {
        prev = date.data.subtract(1, "week").day(prevIndex);
      }
      dispatch({
        type: "REPLACE",
        context: "date",
        data: prev,
        updating: false,
      });
      await fetchSlots({ data: prev }, practiceId)(dispatch);
    }

    await setFadeState({ out: false, in: true });
  }

  const history = useHistory();

  async function loadData() {
    await fetchData(dayjs(), practiceId)(dispatch);
  }

  useEffect(() => {
    loadData();
    handleRoute();
  }, []);

  useEffect(() => {
    if (match.params.step === "pincode") {
      window.history.pushState(null, null, location.href);
      window.onpopstate = function (event) {
        history.go(1);
      };
    }
  }, [match.params.step]);

  async function handleRoute() {
    if (match.params && match.params.step) {
      ["register", "login"].includes(match.params.step) &&
        !state.slot.data &&
        navigateTo("calendar");
      match.params.step !== "calendar" &&
        !state.user.data &&
        navigateTo("calendar");
    }
  }

  async function navigateTo(step, abs = false) {
    const out = match.params.step;
    history.push({
      pathname: abs ? step : `./${step}`,
      state: { out },
    });
    setSlideState({
      current: step,
      out,
      in: step,
    });
    window.scrollTo(0, 0);
  }

  history.listen((location) => {
    const { pathname, state } = location;
    const step = pathname.split("/").pop();
    setSlideState({
      current: step,
      out: state && state.out,
      in: step,
    });
  });

  const practice = useMemo(
    () => practices?.data[0].data,
    [practices?.data[0].data]
  );

  const practitioner = useMemo(
    () =>
      list?.data.find(
        (l) =>
          l.data.PractitionerId ===
          schedules?.data.find((s) => s.data.ScheduleId === slot?.data)?.data
            .PractitionerId
      ),
    [schedules, list, slot?.data]
  );

  return (
    <PublicLayout
      menu={true}
      menuContent={
        <DateBlock bgColor={colors.primColor}>
          {!selectedSlot ? (
            <NavItem
              colors={colors}
              active={dayjs(firstdate?.data).isBefore(dayjs(date?.data))}
              onClick={() =>
                onNavigateDate(
                  "back",
                  dayjs(firstdate?.data).isBefore(dayjs(date?.data))
                )
              }
            >
              <NavigateBefore size="24"></NavigateBefore>
            </NavItem>
          ) : (
            <div />
          )}
          <CenterDate>
            <CalIcon size="48" color="#ffffff" />
            <DateText fade={fadeState}>
              {date.data.format("ddd MMM D, YYYY")}{" "}
              {selectedSlot && selectedSlot.data.Start.split(":")[0]}
              {selectedSlot && ":"}
              {selectedSlot && selectedSlot.data.Start.split(":")[1]}
            </DateText>
          </CenterDate>
          {!selectedSlot ? (
            <NavItem
              colors={colors}
              active={dayjs(date?.data).isBefore(
                dayjs(firstdate?.data).add(90, "days")
              )}
              onClick={() =>
                onNavigateDate(
                  "forward",
                  dayjs(date?.data).isBefore(
                    dayjs(firstdate?.data).add(90, "days")
                  )
                )
              }
            >
              <NavigateNext size="24"></NavigateNext>
            </NavItem>
          ) : (
            <div />
          )}
        </DateBlock>
      }
    >
      {!schedules.meta.updating && slideState.current === "calendar" && (
        <SchedulesBlock
          date={date.data}
          meta={user && user.meta}
          slectedSlot={slot.data}
          schedules={futureSchedules
            ?.filter((s) => s.Day === date.data.isoWeekday())
            .sort((a, b) => a.Start.split(":")[0] - b.Start.split(":")[0])}
          practitioners={list && list.data.filter((p) => p.data.Profile === "active")}
          selectSlot={async (uuid) => {
            selectSlot(uuid)(dispatch);
            // *** check AUTH
            const status = await checkAuthStatus(PUBLIC_NS);
            if (status.status === "PIN") {
              dispatch({
                type: "UPDATE",
                context: "user",
                meta: store.get(PUBLIC_NS)?.user.meta,
                data: store.get(PUBLIC_NS)?.user.data,
              });
              navigateTo("pincode");
            } else if (status.status === "NOTOKEN") {
              store.remove(PUBLIC_NS);
              navigateTo("register");
            } else if (status.status === "NOAUTH") {
              store.remove(PUBLIC_NS);
              navigateTo("login");
            } else {
              checkBooking(
                user,
                futureSchedules.find((s) => s.ScheduleId === uuid),
                state.date,
                practiceId
              )(dispatch, navigateTo);
            }
          }}
          primColor={colors.primColor}
          colors={colors}
          changeDate={(date) => changeDate(date)(dispatch)}
          fadeState={fadeState}
          fade={{
            current: "calendar",
            out: slideState.out === "calendar",
            in: slideState.in === "calendar",
          }}
        />
      )}
      {!appointment.meta.updating && slideState.current === "register" && (
        <Container>
          <Header>
            <div>
              <p>
                Please register with Dentiz to complete your appointment booking
                with:{" "}
              </p>
              <p>
                <strong>{practitioner?.data.Name}</strong> at{" "}
                <strong>{practice?.PracticeName}</strong>
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <PublicRegisterBlock
            registerUser={(user) =>
              registerUser(user, practiceId)(dispatch, navigateTo)
            }
            showTerms={() => showTerms(true)}
            colors={colors}
            meta={state.user.meta}
            goToLogin={() => navigateTo("login")}
            fade={{
              current: "register",
              out: slideState.out === "register",
              in: slideState.in === "register",
            }}
          />
        </Container>
      )}
      {!appointment.meta.updating && slideState.current === "login" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Login below</strong> to your Dentiz and continue booking
                your online appointment!
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <LoginBlock
            loginUser={(user) =>
              loginUser(
                user,
                state.schedules?.data?.find(
                  (s) => s.data.ScheduleId === slot.data
                ).data,
                state.date,
                practiceId
              )(dispatch, navigateTo)
            }
            goToRegister={() => navigateTo("register")}
            goToReset={() => navigateTo("reset")}
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "login",
              out: slideState.out === "login",
              in: slideState.in === "login",
            }}
          />
        </Container>
      )}
      {slideState.current === "reset" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Reset your password</strong>, we will send reset
                instruction to you email.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>

          <ResetBlock
            goToLogin={() => navigateTo("login")}
            resetPassword={(email) =>
              sendResetPassword(email)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}
      {slideState.current === "reset-sent" && (
        <Container>
          <Header>
            <div>
              <p>
                Password Reset Request Successful. Please Check your email to
                continue.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
        </Container>
      )}

      {!appointment.meta.updating && slideState.current === "pincode" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Pincode</strong> Please enter the pin code sent to your
                email to activate your account
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <PinCodeBlock
            colors={colors}
            reset={resetPin}
            meta={state.user.meta}
            sendPinCode={(code) => {
              const callback = () => {
                setTimeout(() => {
                  dispatch({
                    type: "UPDATE",
                    context: "user",
                    meta: {
                      updating: false,
                      warning: null,
                      error: null,
                    },
                  });
                  setResetPin(!resetPin);
                }, 1000);
              };
              sendPinCode(
                state.user,
                state.schedules?.data?.find(
                  (s) => s.data.ScheduleId === slot.data
                ).data,
                state.date,
                practiceId,
                code
              )(dispatch, navigateTo, callback);
            }}
          />
        </Container>
      )}
      {!appointment.meta.updating &&
        slideState.current === "booked" &&
        appointment.data && (
          <Container>
            <Header>
              <div>
                <h2>Your appointment booking is complete!</h2>
                <p>
                  You will recieve an SMS shortly confirming your appointment
                  for{" "}
                  <strong>{dayjs(appointment.data.Start).calendar()}</strong>
                </p>
              </div>
              <img src={loginPng} height="128" alt="" />
            </Header>

            <Body>
              <SubmitBtn
                onClick={() =>
                  window
                    .open(
                      `${process.env.REACT_APP_DOMAIN}/#/public/home/list`,
                      "_blank"
                    )
                    .focus()
                }
                size="0.9"
              >
                Go to my Appointments
              </SubmitBtn>
            </Body>
          </Container>
        )}
      <TermsModal terms={terms} closeTerms={() => showTerms(false)} />
    </PublicLayout>
  );
}
