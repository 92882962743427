import React, { useEffect, useMemo, useReducer, useState } from "react";
import dayjs from "dayjs";
import colors from "../../../../../library/styled-components/colors";
import { NavigateBefore, NavigateNext } from "styled-icons/material";
import CalIcon from "../../../../../icon/calendar";
import { DateBlock, NavItem, CenterDate, DateText } from "./styles";
import {
  fetchNewWeekSlots,
  fetchSchedules,
  fetchSlots,
  loadPractitioners,
} from "../../actions";
import { selectSlot, checkBooking } from "../../../booking/actions";
import { setDay } from "date-fns";
import { Fixed } from "../../../../../layouts/lib/common";
import SchedulesBlock from "../../../../../components/blocks/schedule-block";
import { initialState, reducer } from "../../../booking/reducer";

export default function BookingBlock({
  slot,
  gDate,
  changeDate,
  practiceId,
  navigateTo,
  user,
  slideState,
  replaceAppointment,
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { schedules, practitioners, date, firstdate } = state;
  const futureSchedules = useMemo(
    () =>
      schedules &&
      schedules?.data
        ?.map((s) => ({
          ...s.data,
          dateTime: setDay(new Date(), s.data.Day),
        }))
        .filter((a) => a.TypeId === 1),
    [schedules]
  );

  const scheduleMap = useMemo(
    () => [...new Set(futureSchedules?.map((s) => s.Day))],
    [futureSchedules]
  );

  const selectedSlot =
    slot &&
    slot.data &&
    futureSchedules.find((s) => s.ScheduleId === slot.data);

  const [fadeState, setFadeState] = useState({
    in: false,
    out: false,
  });

  async function onNavigateDate(direction, active = true) {
    if (!active) return false;
    await setFadeState({ out: true, in: false });
    await new Promise((resolve) => setTimeout(resolve, 500));
    const dayIndex = scheduleMap.indexOf(date.data.isoWeekday());

    if (direction === "forward") {
      const nextIndex = scheduleMap[dayIndex + 1] || scheduleMap[0];
      let next = null;
      if (!nextIndex) {
        next = dayjs().add(1, "week");
        await fetchNewWeekSlots({ data: next }, practiceId)(dispatch);
      } else if (scheduleMap[dayIndex + 1]) {
        next = date.data.day(nextIndex);
        await fetchSlots({ data: next }, practiceId)(dispatch);
        dispatch({
          type: "REPLACE",
          context: "date",
          data: next,
          updating: false,
        });
      } else {
        next = date.data.add(1, "week").day(nextIndex);
        await fetchSlots({ data: next }, practiceId)(dispatch);
        dispatch({
          type: "REPLACE",
          context: "date",
          data: next,
          updating: false,
        });
      }
    }

    if (direction === "back") {
      const prevIndex =
        scheduleMap[dayIndex - 1] || scheduleMap[scheduleMap.length - 1];
      let prev = null;
      if (scheduleMap[dayIndex - 1]) {
        prev = date.data.day(prevIndex);
      } else {
        prev = date.data.subtract(1, "week").day(prevIndex);
      }
      dispatch({
        type: "REPLACE",
        context: "date",
        data: prev,
        updating: false,
      });
      await fetchSlots({ data: prev }, practiceId)(dispatch);
    }

    await setFadeState({ out: false, in: true });
  }

  useEffect(() => {
    fetchSchedules(dayjs(), practiceId)(dispatch, navigateTo);
    loadPractitioners(practiceId)(dispatch, navigateTo);
  }, []);

  return (
    <div>
      <DateBlock color={colors.primary}>
        {!selectedSlot ? (
          <NavItem
            colors={colors}
            active={dayjs(firstdate?.data).isBefore(dayjs(date?.data))}
            onClick={() =>
              onNavigateDate(
                "back",
                dayjs(firstdate?.data).isBefore(dayjs(date?.data))
              )
            }
          >
            <NavigateBefore size="24"></NavigateBefore>
          </NavItem>
        ) : (
          <div />
        )}
        <CenterDate>
          <CalIcon size="48" color={colors.primary} />
          <DateText fade={fadeState}>
            {date.data.format("ddd MMM D, YYYY")}{" "}
            {selectedSlot && selectedSlot.data.Start.split(":")[0]}
            {selectedSlot && ":"}
            {selectedSlot && selectedSlot.data.Start.split(":")[1]}
          </DateText>
        </CenterDate>
        {!selectedSlot ? (
          <NavItem
            colors={colors}
            active={dayjs(date?.data).isBefore(
              dayjs(firstdate?.data).add(90, "days")
            )}
            onClick={() =>
              onNavigateDate(
                "forward",
                dayjs(date?.data).isBefore(
                  dayjs(firstdate?.data).add(90, "days")
                )
              )
            }
          >
            <NavigateNext size="24"></NavigateNext>
          </NavItem>
        ) : (
          <div />
        )}
      </DateBlock>
      <SchedulesBlock
        date={date.data}
        meta={user && user.meta}
        slectedSlot={slot && slot.data}
        schedules={futureSchedules
          ?.filter((s) => s.Day === date.data.isoWeekday())
          .sort((a, b) => a.Start.split(":")[0] - b.Start.split(":")[0])}
        practitioners={practitioners && practitioners.data}
        selectSlot={(uuid) => {
          selectSlot(uuid)(dispatch);
          checkBooking(
            user,
            futureSchedules.find((s) => s.ScheduleId === uuid),
            date,
            practiceId,
            replaceAppointment
          )(dispatch, navigateTo);
        }}
        primColor={colors.primColor}
        colors={colors}
        changeDate={(date) => changeDate(date)(dispatch)}
        fadeState={fadeState}
        fade={{
          current: "booking",
          out: slideState.out === "booking",
          in: slideState.in === "booking",
        }}
      />
      {replaceAppointment && (
        <Fixed
          style={{
            background: colors.info,
            color: colors.txtColor,
            textAlign: "center",
            padding: "1em",
          }}
        >
          <div>Rebooking Apppointment </div>
          <div>
            on {dayjs(replaceAppointment.Start).format("ddd, MMM Do YYYY")}
          </div>
        </Fixed>
      )}
    </div>
  );
}
