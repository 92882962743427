import React, { useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import StandardLayout from "layouts/standard";
import { Button, ButtonGroup, Tooltip } from "@mui/material";
import { ADMIN_NS } from "config";
import { fetchLogs } from "actions/logs";
import { addYears, endOfYear, format, startOfMonth, subMonths } from "date-fns";
import EmailIcon from "@mui/icons-material/Email";
import SmsIcon from "@mui/icons-material/SmsOutlined";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DateWrap } from "../../../../components/common";

const templateIds = {
  "d-8072d680eb034aa7a147daf254811298": "New User",
  "d-a2ad76fa40884586af0c410e8db9f7f2": "New Practitioner User",
  "d-16f20409f2364de4b9b07673eaeef2e9": "New Patient User",
  "d-16f0f93a80da44f98d7617a274c272e5": "New Patient User No Password",
  "d-53c47ed071214f7db6211dcb52e7a680": "Reset Password",
  "d-77c66a815e3646bea3a860c74f5b46fb": "New Appointment Message",
  "d-ed400bb87ade4e6784894fe586dd7385": "Updated Appointment Message",
  "d-52a1e59a02a44a90adea463aeb905f45": "Appointment Reminder Message",
  "d-7115e84c29304b378fe95b63f2efa271": "Appointment Cancelled Message",
  "d-f2436cdb348c4db48f588cdc5df2ad11": "Recalled Patient Message",
  "d-0ff023ede1cc4d3196d8793fbac9ca85": "New Patient Invoice Message",
};

const smsTypes = {
  1: "Outgoing - Automated",
  2: "Outgoing - System",
  3: "Outgoing - Practice User",
  4: "Incoming - Patient",
  5: "Outgoing - Recall User",
};

export default function AdminLogs({ user }) {
  const [filterType, setFilterType] = React.useState("email");
  const [logs, setLogs] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [limit, setLimit] = React.useState(20);
  const [offset, setOffset] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [start, setStart] = React.useState(
    startOfMonth(subMonths(new Date(), 1))
  );
  const [end, setEnd] = React.useState(endOfYear(addYears(new Date(), 1)));

  async function getLogs(
    start,
    end,
    offset = 0,
    limit = 10,
    filterType = "email"
  ) {
    setLoading(true);
    const response = await fetchLogs(
      ADMIN_NS,
      format(start, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      format(end, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
      offset,
      limit,
      filterType
    );
    if (response?.code === "SUCCESS") {
      const newlogs = response.data.map((log) => ({
        ...log,
        TypeId:
          log.Type === "EMAIL" ? templateIds[log.TypeId] : smsTypes[log.TypeId],
        Attachment: log?.Attachment || "-",
        Status: log?.event || "-",
      }));

      setTotal(response?.count || 0);
      setLogs(newlogs);
      setLoading(false);
    }
  }

  useEffect(() => {
    getLogs(start, end, offset, limit, filterType);
  }, [start, end, offset, limit, filterType]);

  const leftToLoad = Math.abs(total - logs.length);

  const emailColor = filterType === "email" ? "primary" : "default";
  const emailVariant = filterType === "email" ? "contained" : "outlined";

  const smsColor = filterType === "sms" ? "primary" : "default";
  const smsVariant = filterType === "sms" ? "contained" : "outlined";

  return (
    <StandardLayout
      menu={true}
      user={user}
      meta={{ updating: false }}
      nosync={true}
    >
      <div
        style={{
          margin: "1rem",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "end",
          }}
        >
          <ButtonGroup
            style={{
              marginBottom: "1rem",
            }}
          >
            <Button
              variant={emailVariant ?? "outlined"}
              onClick={() => {
                setFilterType("email");
              }}
              startIcon={<EmailIcon size="small"></EmailIcon>}
            >
              Emails
            </Button>
            <Button
              variant={smsVariant ?? "outlined"}
              onClick={() => {
                setFilterType("sms");
              }}
              startIcon={<SmsIcon size="small"></SmsIcon>}
            >
              Sms
            </Button>
          </ButtonGroup>
          <div>
            <div style={{ display: "flex" }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateWrap>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="From date"
                    disabled={loading}
                    value={start}
                    onChange={(date) => setStart(date)}
                  />
                </DateWrap>
                <DateWrap>
                  <DatePicker
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    label="To Date"
                    disabled={loading}
                    value={end}
                    onChange={(date) => setEnd(date)}
                  />
                </DateWrap>
              </LocalizationProvider>
            </div>
          </div>
        </div>

        <TableContainer
          style={{
            height: "calc(100vh - 200px)",
          }}
          component={Paper}
        >
          <Table stickyHeader size="small" aria-label="logs table">
            <TableHead>
              <TableRow>
                <TableCell>Type</TableCell>
                <TableCell></TableCell>
                <TableCell align="right">Patient</TableCell>
                <TableCell align="right">Recipient</TableCell>
                <TableCell align="right">TypeId</TableCell>
                <TableCell align="left">Message</TableCell>
                <TableCell align="right">Attachment</TableCell>
                <TableCell align="right">Status</TableCell>
                <TableCell align="right">Sent</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={7}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <div>
                        <DoubleBounce size={20} color={colors.primary} />
                      </div>{" "}
                      &nbsp;
                      <span> Loading Logs ....</span>
                    </div>
                  </TableCell>
                </TableRow>
              ) : null}
              {logs?.map((row) => (
                <TableRow key={row.id}>
                  <TableCell component="th" scope="row">
                    {row.Type === "EMAIL" ? (
                      <EmailIcon size="small"></EmailIcon>
                    ) : (
                      <SmsIcon size="small"></SmsIcon>
                    )}
                  </TableCell>
                  <TableCell>{row.Type}</TableCell>
                  <TableCell align="right">{row.PatientName}</TableCell>
                  <TableCell align="right">{row.Recipient}</TableCell>
                  <TableCell align="right">{row.TypeId}</TableCell>
                  <TableCell align="left">
                    <div
                      style={{
                        maxWidth: "300px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      <Tooltip
                        arrow
                        placement="top-end"
                        title={
                          <React.Fragment>
                            {row.Message?.split(" ").map((word, index) => (
                              <span key={index}>
                                {word}
                                {index !== row.Message?.split(" ").length - 1
                                  ? " "
                                  : ""}
                              </span>
                            ))}
                          </React.Fragment>
                        }
                      >
                        <div>
                          {" "}
                          {row.Message?.substring(0, 44)}
                          {row.Message?.length > 44 ? "..." : ""}
                        </div>
                      </Tooltip>
                    </div>
                  </TableCell>
                  <TableCell align="right">{row.Attachment}</TableCell>
                  <TableCell align="right">{row.Status}</TableCell>
                  <TableCell align="right">
                    {format(new Date(row.createdAt), "do MMMM yyyy, HH:mm")}
                  </TableCell>
                </TableRow>
              )) || (
                <TableRow>
                  <TableCell colSpan={7}>No logs found</TableCell>
                </TableRow>
              )}
            </TableBody>
            <caption>
              <div
                style={{
                  display: "flex",
                }}
              >
                {leftToLoad ? (
                  <div>({leftToLoad}) more logs on server</div>
                ) : (
                  <div>No more logs on server!</div>
                )}
                <div style={{ marginLeft: "auto" }}>
                  {leftToLoad > 0 ? (
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={async () => {
                        const newLimit = limit === 0 ? 10 : limit + 10;
                        setLimit(newLimit);
                      }}
                    >
                      Load More
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </caption>
          </Table>
        </TableContainer>
      </div>
    </StandardLayout>
  );
}
