import store from "store";
import {
  registerAdmin,
  validatePin,
  resetPassword,
  updatePassword,
} from "../../../../actions/register";
import { ADMIN_NS } from "../../../../config";

export async function getRegisterConfig() {
  return await fetch(
    `${process.env.REACT_APP_APP_API}/public/register/config`,
    {
      method: "get",
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getProducts() {
  return await fetch(
    `${process.env.REACT_APP_APP_API}/public/register/products`,
    {
      method: "get",
    }
  )
    .then((response) => response.json())
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const setToken = (token) => async (dispatch) => {
  let storeState = store.get(ADMIN_NS);
  store.set(ADMIN_NS, {
    ...storeState,
    user: {
      meta: { updating: false },
      data: {
        token,
      },
    },
  });

  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: false },
    data: { token },
  });
};

export const registerUser =
  (user, practiceId) => async (dispatch, navigateTo) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: true },
    });

    const userData = {
      user,
      PracticeId: practiceId,
    };

    const response = await registerAdmin(userData);

    if (response.code == "REGISTERED") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false },
        data: {
          ...response,
          Email: user.Email.val,
        },
      });

      let storeState = store.get(ADMIN_NS);

      store.set(ADMIN_NS, {
        ...storeState,
        user: {
          meta: { updating: false },
          data: {
            ...response,
            Email: user.Email.val,
          },
        },
      });

      navigateTo("/admin/access/pincode", "pincode");
    }

    if (response.code == "ALREADY_REGISTERED") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, warning: "User email already registered!" },
      });
    }

    if (response.code == "INVALID_PHONE") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: {
          updating: false,
          warning: "Phone number is invalid or unsupported!",
        },
      });
    }
  };

export const sendPinCode = (code) => async (dispatch, navigateTo, callback) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await validatePin(ADMIN_NS, code);
  if (response && response.code == "VALID") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
      data: response
    });

    let storeState = store.get(ADMIN_NS);

    store.set(ADMIN_NS, {
      ...storeState,
      user: {
        meta: { updating: false },
        data: response
      },
    });

    navigateTo("/admin/home/calendar");
  }
  if (response && response.code == "INVALID_PIN") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Invalid Pin, please retry" },
    });
    callback();
  }
  if (response && response.code == "OLD_PIN") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "This account is already active" },
    });
    callback();
  }
  if (!response) {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, error: "You are unauthorized" },
    });
    callback();
  }
};

export const sendResetPassword = (email) => async (dispatch, navigateTo) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await resetPassword(ADMIN_NS, email);
  if (response && response.code == "VALID") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
    });
    navigateTo("/admin/access/reset-sent", "reset-sent");
  }
  if (response && response.code == "INVALID_EMAIL") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Invalid Email, please retry" },
    });
  }
};

export const sendUpdatePassword =
  (password) => async (dispatch, navigateTo) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: true },
    });
    const response = await updatePassword(ADMIN_NS, password);
    if (response && response.code == "VALID") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false },
      });
      navigateTo("/admin/access/pincode", "pincode");
    }
    if (response && ["INVALID", "NOAUTH"].includes(response.code)) {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, warning: "Reset Denied" },
      });
    }
  };
