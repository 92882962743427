import React, { useEffect } from "react";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";
import DoubleBounce from "../loaders/double-bounce";

import ToothSelector from "./components/ToothSelector/ToothSelector";

const Container = styled.div`
  display: grid;
  justify-content: center;
  zoom: 0.8;
`;

const UpperWrapper = styled.div`
  display: flex; 
  border-bottom: 1px solid black;
`;

const LowerWrapper = styled.div`
  display: flex; 
`;

const BlockWrapper = styled.div`
  display: flex;
  padding: 1rem;
`;

const SpacerLineTop = styled.div`
  border-left: 1px solid black;
  height: 2rem;
  align-self: flex-end;
`;

const SpacerLineBottom = styled.div`
  border-left: 1px solid black;
  height: 2rem; 
`;

const upperLeft = ["A18", "A17", "A16", "A15", "A14", "A13", "A12", "A11"];

const upperRight = ["A21", "A22", "A23", "A24", "A25", "A26", "A27", "A28"];

const lowerLeft = ["B48", "B47", "B46", "B45", "B44", "B43", "B42", "B41"];

const lowerRight = ["B31", "B32", "B33", "B34", "B35", "B36", "B37", "B38"];

const initialState = [
  ...upperLeft,
  ...upperRight,
  ...lowerLeft,
  ...lowerRight,
].map((id) => ({
  id,
  selected: [],
}));

const DentChart = ({
  chartState,
  setChartState,
  patient,
  selectedAppointment,
  setSelectedAppointment,
}) => {
  useEffect(() => {
    setChartState(initialState);
    if (!selectedAppointment && patient?.Appointments.length) {
      setSelectedAppointment(patient?.Appointments[0].AppointmentId);
    }
  }, [initialState, patient?.Appointments[0], selectedAppointment]);

  if (!chartState) return <DoubleBounce color={colors.primary} margin="20%" />;

  const proceedures = patient?.Appointments.find(
    (app) => app.AppointmentId === selectedAppointment
  )
    ?.Proceedures?.filter((p) => p.Data?.areas)
    .map((p) =>
      p.Data?.areas?.map((s) => ({
        ...s,
        treatmentCode: p.Treatment?.Code,
        Type: p.Treatment?.Category?.Type,
        SubCategory: p.Treatment?.Data?.SubCategory,
        status: p.Data?.status,
      }))
    )
    ?.reduce((acc, curVal) => acc.concat(curVal), []);

  return (
    <Container>
      <UpperWrapper>
        <BlockWrapper>
          {upperLeft.map((id) => (
            <ToothSelector
              id={id}
              state={chartState.find((c) => c.id === id)}
              proceedures={proceedures?.filter((p) => p.id === id)}
              setChartState={(state) => {
                const update = chartState.map((c) => {
                  if (c.id === id) return state;
                  return c;
                });
                setChartState(update);
              }}
            ></ToothSelector>
          ))}
        </BlockWrapper>
        <SpacerLineTop />
        <BlockWrapper>
          {upperRight.map((id) => (
            <ToothSelector
              id={id}
              state={chartState.find((c) => c.id === id)}
              proceedures={proceedures?.filter((p) => p.id === id)}
              setChartState={(state) => {
                const update = chartState.map((c) => {
                  if (c.id === id) return state;
                  return c;
                });
                setChartState(update);
              }}
            ></ToothSelector>
          ))}
        </BlockWrapper>
      </UpperWrapper>

      <LowerWrapper>
        <BlockWrapper>
          {lowerLeft.map((id) => (
            <ToothSelector
              reverse
              id={id}
              state={chartState.find((c) => c.id === id)}
              proceedures={proceedures?.filter((p) => p.id === id)}
              setChartState={(state) => {
                const update = chartState.map((c) => {
                  if (c.id === id) return state;
                  return c;
                });
                setChartState(update);
              }}
            ></ToothSelector>
          ))}
        </BlockWrapper>
        <SpacerLineBottom />
        <BlockWrapper>
          {lowerRight.map((id) => (
            <ToothSelector
              reverse
              id={id}
              state={chartState.find((c) => c.id === id)}
              proceedures={proceedures?.filter((p) => p.id === id)}
              setChartState={(state) => {
                const update = chartState.map((c) => {
                  if (c.id === id) return state;
                  return c;
                });
                setChartState(update);
              }}
            ></ToothSelector>
          ))}
        </BlockWrapper>
      </LowerWrapper>
    </Container>
  );
};

export default DentChart;
