import styled from "styled-components";
import colors from "../../../../../library/styled-components/colors";
import { FadeDiv } from "../../../../../library/styled-components";

export const DateBlock = styled.div`
  display: grid;
  grid-template-columns: 1.6em auto 1.6em;
  position: relative;
  text-align: center;
  color: ${props => props.color};
  margin: 1rem;
  align-items: center;
  height: 4rem;
`;

export const CenterDate = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3em auto;
  grid-gap: 1em;
  justify-content: center;
`;

export const DateText = styled(FadeDiv)`
  margin: auto 0;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 2rem;
`;

export const NavItem = styled.div`
  cursor: ${(props) => (props.active ? "pointer" : "not-allowed")};
  border-radius: 50%;
  color: ${props => props.color};
  :hover {
    background-color: rgba(256, 256, 256, 0.2);
  }
`;
