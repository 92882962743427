import React from "react";
import BuildIcon from "@mui/icons-material/Build";
import { Button } from "@mui/material";
import MarginBlock from "./MarginBlock";
import DepthBlock from "./DepthBlock";
import { BoxWrap, ClickBox } from "../../../common";

const ActionsBlockOne = ({
  updateSettings,
  updateDepth,
  updateMargin,
  depth,
  margin,
  imgs,
}) => {
  return (
    <table style={{ width: "400px", textAlign: "center" }}>
      <tbody>
        <tr>
          {imgs.map((img, i) => (
            <th style={{ width: img.width }}>
              <Button
                variant="contained"
                style={{ minWidth: "auto", margin: "2px", padding: "2px" }}
                color={img.removed ? "secondary" : "default"}
                onClick={() =>
                  updateSettings(
                    imgs.map((_, is) =>
                      is === i ? { ..._, removed: !_.removed } : _
                    )
                  )
                }
              >
                {img?.id}
              </Button>
            </th>
          ))}
        </tr>
        <tr>
          {imgs.map((img, i) => (
            <td style={{ width: img.width }}>
              {!img.removed && (
                <Button
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i ? { ..._, implant: !_.implant } : _
                      )
                    )
                  }
                  color={img.implant ? "primary" : "default"}
                  variant="contained"
                  style={{ minWidth: "auto", margin: "2px", padding: "2px" }}
                >
                  <BuildIcon style={{ width: "12px", height: "12px" }} />
                </Button>
              )}
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((img, i) => (
            <td style={{ width: img.width }}>
              <BoxWrap>
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[0]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [!_.bleed[0], _.bleed[1], _.bleed[2]]
                                : [1, 0, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[1]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [_.bleed[0], !_.bleed[1], _.bleed[2]]
                                : [0, 1, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[2]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [_.bleed[0], _.bleed[1], !_.bleed[2]]
                                : [0, 0, 1],
                            }
                          : _
                      )
                    )
                  }
                />
              </BoxWrap>
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((img, i) => (
            <td style={{ width: img.width }}>
              <BoxWrap>
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[0]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [!_.plaque[0], _.plaque[1], _.plaque[2]]
                                : [1, 0, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[1]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [_.plaque[0], !_.plaque[1], _.plaque[2]]
                                : [0, 1, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[2]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [_.plaque[0], _.plaque[1], !_.plaque[2]]
                                : [0, 0, 1],
                            }
                          : _
                      )
                    )
                  }
                />
              </BoxWrap>
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((_, i) => (
            <td>
              <MarginBlock
                margins={margin?.slice(i * 3, (i + 1) * 3)}
                updateMargin={(seg) => {
                  updateMargin(margin.map((m) => (m[0] === seg[0] ? seg : m))); 
                }}
              />
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((_, i) => (
            <td>
              <DepthBlock
                depths={depth?.slice(i * 3, (i + 1) * 3)}
                margins={margin?.slice(i * 3, (i + 1) * 3)}
                updateDepth={(seg) => {
                  updateDepth(depth.map((m) => (m[0] === seg[0] ? seg : m)));
                }}
              />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default ActionsBlockOne;
