import { oneState, oneSettings } from "./one";
import { twoState, twoSettings } from "./two";
import { threeState, threeSettings } from "./three";
import { fourState, fourSettings } from "./four";
import { fiveState, fiveSettings } from "./five";
import { sixState, sixSettings } from "./six";
import { sevenState, sevenSettings } from "./seven";
import { eightState, eightSettings } from "./eight";

export const settings = {
  one: oneSettings,
  two: { imgs: twoSettings.imgs.reverse() },
  three: threeSettings,
  four: { imgs: fourSettings.imgs.reverse() },
  five: fiveSettings,
  six: { imgs: sixSettings.imgs.reverse() },
  seven: sevenSettings,
  eight: { imgs: eightSettings.imgs.reverse() },
};

export const initialState = {
  one: oneState,
  two: twoState,
  three: threeState,
  four: fourState,
  five: fiveState,
  six: sixState,
  seven: sevenState,
  eight: eightState,
};
