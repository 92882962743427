import React, { useState } from "react";
import styled from "styled-components";
import Editor from "../../editor";
import { formSchema, createButtons } from "./formSettings";
import { FadeDiv } from "../../../library/styled-components";
import colors from "../../../library/styled-components/colors";

const Container = styled(FadeDiv)`
  display: grid;
  grid-template-rows: auto 8em 2em;
  background-color: ${colors.secColor};
`;

function tranformSchema(schema, practitioners) {
  return {
    ...schema,
    Practitioners: {
      ...schema.Practitioners,
      options: practitioners
        ? practitioners?.map((p) => ({
            key: p.PractitionerId,
            name: p.Name,
            value: p.PractitionerId,
          }))
        : [],
    },
  };
}

function RegisterInHouseBlock({ registerUser, fade, colors, meta, practitioners, showTerms }) {
  const [defaultData, setDefaultData] = useState(null);

  return (
    <Container fade={fade} colors={colors}>
      <Editor
        submitError={meta && meta.error}
        warning={meta && meta.warning} 
        formSchema={tranformSchema(formSchema, practitioners)}
        metaData={meta}
        defaultData={defaultData}
        buttons={createButtons}
        sendData={(data) => {
          registerUser(data);
          let perData = {}
          Object.keys(data).forEach(k => {
            perData[k] = data[k].val
          })
          setDefaultData(perData)
        }}
        callBack={(call) => call === "open-terms" && showTerms()}
        colors={colors}
        settings={{
          width: { mobile: "100%", desktop: "100%" },
        }}
      />
    </Container>
  );
}

export default RegisterInHouseBlock;
