import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  InputAdornment,
  TextField,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/DoneAllTwoTone";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import AddBoxRounded from "@mui/icons-material/AddBoxRounded";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import IndeterminateCheckBoxRounded from "@mui/icons-material/IndeterminateCheckBoxRounded";
import colors from "library/styled-components/colors";
import { format } from "date-fns";
import styled from "styled-components";
import {
  AltSubmitBtn,
  DangerSubmitBtn,
  ModalBox,
  ModalContent,
} from "components/common";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";

const useStyles = makeStyles((theme) => ({
  container: { overflow: "auto", height: "46vh" , background: colors.rgbInfoLight},
  bar: {
    display: "flex",
    backgroundColor: colors.rgbGreyDark,
    paddingLeft: "6px",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  iconButton: {
    height: "32px",
  },
  title: {
    flexGrow: 1,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: colors.rgbGreyDark,
    "&:hover": {
      backgroundColor: colors.rgbGreyLight,
    },
    marginLeft: 0,
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(1),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
  leftItem: {
    width: "auto",
  },
  rightItem: {
    textAlign: "end",
    paddingLeft: "1rem",
  },
}));

const InputWrap = styled.div`
  width: 8rem;
  input {
    text-align: end;
  }
`;

const DisplayBlock = styled.div`
  display: flex;
  justify-content: center;
  text-align: center; 
  flex-direction: column;
  margin: 5rem; 
`;

export default function ProceedureList({
  patient,
  selectedAppointment,
  setInvoice,
  updateProceedure,
  removeProceedures,
  updateAppointment,
}) {
  const classes = useStyles();
  const [checked, setChecked] = React.useState([]);
  const [edit, setEdit] = React.useState([]);
  const [changes, setChanges] = useState([]);
  const [search, setSearch] = useState("");
  const [deleteProceedures, setDeleteProceedures] = React.useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const onSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = proceedures.map((n) => n.id);
      setChecked(newSelecteds);
      return;
    }
    setChecked([]);
  };

  const appointment = patient?.Appointments.find(
    (app) => app.AppointmentId === selectedAppointment
  );

  const proceedures = appointment?.Proceedures?.sort((a, b) => a.id - b.id);

  useEffect(() => {
    appointment?.Proceedures && setChanges(appointment?.Proceedures);
  }, [appointment?.Proceedures]);

  return (
    <div className={classes.container}>
      {proceedures?.length ? (
        <div>
          <AppBar position="static">
            <Toolbar className={classes.bar}>
              <Checkbox
                indeterminate={
                  checked?.length > 0 && checked.length < proceedures?.length
                }
                checked={
                  checked?.length > 0 && checked.length === proceedures?.length
                }
                onChange={onSelectAllClick}
                disableRipple
              />
              <Typography className={classes.title} variant="h6" noWrap>
                {appointment?.Start &&
                  format(new Date(appointment.Start), "do MMMM yyyy, HH:mm")}
              </Typography>
              {checked.length ? (
                <ButtonGroup variant="text" size="small">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setInvoice(checked);
                    }}
                    startIcon={<SaveIcon />}
                  >
                    Create Invoice
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setDeleteProceedures(checked)}
                    startIcon={<CancelIcon />}
                  >
                    Delete
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setChecked([])}
                    startIcon={<CancelIcon />}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              ) : (
                <div className={classes.search}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <InputBase
                    placeholder="Search…"
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    inputProps={{ "aria-label": "search" }}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              )}

              <Typography className={classes.rightItem} variant="h6" noWrap>
                {new Intl.NumberFormat("en-IE", {
                  style: "currency",
                  currency: "EUR",
                }).format(
                  proceedures
                    ?.map((p) => p?.Price || 0)
                    .reduce((sum, i) => sum + i, 0)
                )}
              </Typography>
            </Toolbar>
          </AppBar>
        </div>
      ) : (
        <DisplayBlock>
          <Typography variant="h6" noWrap>
            There are no Proceedures applied to this Appointment.
          </Typography>
          <p>
            You can apply treatments from the panel on the right, First Select
            the treatments arreas on the Chart above
          </p>
        </DisplayBlock>
      )}
      {!appointment?.Meta?.inTreatment && !appointment?.Meta?.completed && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            background: colors.rgbGreyDark,
          }}
        >
          <Button
            style={{
              width: "20rem",
              margin: "4px",
              color: colors.white,
              background: colors.secondary,
            }}
            variant="contained"
            onClick={() => {
              const update = {
                ...appointment,
                meta: {
                  ...appointment?.meta,
                  arrived: true,
                  inTreatment: true,
                },
              };
              updateAppointment(update, false);
            }}
            startIcon={<LocalHospitalIcon size="32" />}
          >
            Begin Treatment
          </Button>
        </div>
      )}
      <List component="nav">
        {proceedures
          ?.filter((proc) => {
            const str =
              proc.Treatment?.Code.toLowerCase() +
              " " +
              proc.Treatment?.Name.toLowerCase();
            return str && str.search(search.toLowerCase()) >= 0;
          })
          ?.map((proc) => {
            const color =
              (proc.Data.status == 1 && colors.danger) ||
              (proc.Data.status == 2 && colors.success) ||
              colors.accentColor;

            const letter =
              (proc.Data.status == 0 && "E") ||
              (proc.Data.status == 1 && "P") ||
              "C";

            return (
              <ListItem button key={proc.id} onClick={handleToggle(proc.id)}>
                <Checkbox
                  edge="start"
                  checked={checked.indexOf(proc.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText
                  className={classes.leftItem}
                  primary={
                    <div>
                      <Chip
                        style={{ backgroundColor: color, color: "white" }}
                        size="small"
                        label={letter}
                      ></Chip>{" "}
                      {proc.Data?.areas?.length && (
                        <Chip
                          style={{
                            backgroundColor: colors.dark,
                            color: "white",
                          }}
                          size="small"
                          label={proc.Data?.areas?.map(
                            (a, i, { length }) =>
                              `#${a.id}${i + 1 === length ? "" : ","}`
                          )}
                        ></Chip>
                      )}{" "}
                      {}
                      <span>
                        <span
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          {proc.Treatment?.Code}
                        </span>{" "}
                        {proc.Treatment?.Data?.SubCategory?.Name && (
                          <span
                            style={{
                              fontStyle: "italic",
                            }}
                          >
                            {proc.Treatment?.Data?.SubCategory?.Name}
                          </span>
                        )}
                      </span>
                    </div>
                  }
                  secondary={proc.Treatment?.Name}
                />
                <div
                  style={{ display: "flex" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  {edit.indexOf(proc.id) !== -1 && (
                    <ButtonGroup
                      variant="text"
                      size="small"
                      orientation="vertical"
                    >
                      <IconButton
                        className={classes.iconButton}
                        disabled={
                          proceedures.find(
                            (p) => p.ProceedureId === proc.ProceedureId
                          ).Price !==
                          changes.find(
                            (c) => c.ProceedureId === proc.ProceedureId
                          ).Price
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          const update = changes.map((c) => {
                            if (c.ProceedureId === proc.ProceedureId) {
                              const quantity = (c.Data?.quantity || 1) + 1;
                              const price =
                                proc.Price / (proc.Data?.quantity || 1);
                              return {
                                ...c,
                                Price: parseInt(proc.Price) + parseInt(price),
                                Data: {
                                  ...c.Data,
                                  quantity,
                                },
                              };
                            }
                            return c;
                          });
                          updateProceedure(
                            update.find(
                              (c) => c.ProceedureId === proc.ProceedureId
                            )
                          );
                        }}
                        aria-label="up"
                        size="large">
                        <AddBoxRounded />
                      </IconButton>
                      <IconButton
                        className={classes.iconButton}
                        disabled={
                          proceedures.find(
                            (p) => p.ProceedureId === proc.ProceedureId
                          ).Price !==
                          changes.find(
                            (c) => c.ProceedureId === proc.ProceedureId
                          ).Price
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          const update = changes.map((c) => {
                            if (c.ProceedureId === proc.ProceedureId) {
                              const quantity = (c.Data?.quantity || 1) - 1;
                              if (quantity === 0) return c;
                              const price =
                                proc.Price / (proc.Data?.quantity || 1);
                              return {
                                ...c,
                                Price: parseInt(proc.Price) - parseInt(price),
                                Data: {
                                  ...c.Data,
                                  quantity,
                                },
                              };
                            }
                            return c;
                          });
                          updateProceedure(
                            update.find(
                              (c) => c.ProceedureId === proc.ProceedureId
                            )
                          );
                        }}
                        aria-label="down"
                        size="large">
                        <IndeterminateCheckBoxRounded />
                      </IconButton>
                    </ButtonGroup>
                  )}
                  {edit.indexOf(proc.id) !== -1 ? (
                    <InputWrap>
                      <TextField
                        className={classes.rightItem}
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        value={new Intl.NumberFormat("en-IE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(
                          changes.find(
                            (c) => c.ProceedureId === proc.ProceedureId
                          )?.Price
                        )}
                        onChange={(e) => {
                          const clean = parseFloat(
                            e.target.value
                              .toString()
                              .replaceAll("€", "")
                              .replaceAll(",", "")
                          );
                          if (isNaN(clean)) return;

                          setChanges(
                            changes.map((c) => {
                              if (c.ProceedureId === proc.ProceedureId) {
                                return {
                                  ...c,
                                  Price: clean,
                                };
                              }
                              return c;
                            })
                          );
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <DoneIcon
                                style={{
                                  cursor: "pointer",
                                  color: colors.darkBlue,
                                }}
                                size="small"
                                onClick={() => {
                                  updateProceedure(
                                    changes.find(
                                      (c) =>
                                        c.ProceedureId === proc.ProceedureId
                                    )
                                  );

                                  setEdit(edit.filter((id) => id !== proc.id));
                                }}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                      <ListItemText
                        className={classes.rightItem}
                        secondary={`QTY: ${
                          changes.find(
                            (c) => c.ProceedureId === proc.ProceedureId
                          )?.Data.quantity || 1
                        }`}
                      />
                    </InputWrap>
                  ) : (
                    <ListItemText
                      onClick={(e) => {
                        e.stopPropagation();
                        setEdit([...edit, proc.id]);
                      }}
                      className={classes.rightItem}
                      primary={new Intl.NumberFormat("en-IE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(proc.Price || 0)}
                      secondary={`QTY: ${
                        changes.find(
                          (c) => c.ProceedureId === proc.ProceedureId
                        )?.Data.quantity || 1
                      }`}
                    />
                  )}
                </div>
              </ListItem>
            );
          })}
        <ModalBox show={!!deleteProceedures.length}>
          <ModalContent>
            <h3>Are you sure you want to cancel these proceedures?</h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: "1em",
              }}
            >
              <DangerSubmitBtn
                onClick={() => {
                  removeProceedures(deleteProceedures);
                  setChecked([]);
                  setDeleteProceedures([]);
                }}
              >
                Yes continue
              </DangerSubmitBtn>
              <AltSubmitBtn
                onClick={() => {
                  setDeleteProceedures([]);
                  setChecked([]);
                }}
              >
                No Cancel
              </AltSubmitBtn>
            </div>
          </ModalContent>
        </ModalBox>
      </List>
    </div>
  );
}
