import React, { useContext, useEffect, useState } from 'react'
import store from 'store'
import { getTreatments } from 'actions/treatments'
import { getSchedules } from 'actions/schedules'
import { getAppointmentEvents, getAppointments } from 'actions/appointments'
import { getPatients, getPatientsSms } from 'actions/patients'
import { getPractitioners } from 'actions/practitioners'
import { getCategories } from 'actions/categories'
import { fetcNotifications } from 'actions/notifications'
import { getAuthPractice, getLastEventUuid } from 'actions/practices'
import {
  getItem,
  updateList,
  updateListFromCache,
  updateObjectFromCache
} from 'library/resources'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  LinearProgress
} from '@mui/material'
import { AppContext } from 'hooks/context'
import { ADMIN_NS } from 'config'
import { format, addWeeks, startOfDay, endOfDay } from 'date-fns'
import localforage from 'localforage'
import { getBatchRecall } from 'actions/sms'

const WEEK_LOAD = 1

function isAuthenticated (state) {
  if (!store.get(state)) return false
  const { user } = store.get(state)
  const now = new Date().getTime()
  const expire = new Date(user.data.exp * 1000).getTime()
  return now < expire
}

const fetchAppointmentsForWeek = async weekOffset => {
  const startDate = format(
    startOfDay(addWeeks(new Date(), weekOffset)),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  )
  const endDate = format(
    endOfDay(addWeeks(new Date(), weekOffset + 1)),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  )

  const allData = []
  const limit = 1000
  const maxPages = 1 // Define a reasonable maximum number of pages
  const offsets = Array.from({ length: maxPages }, (_, i) => i * limit)

  // Fetch all appointment pages concurrently
  const appointmentPromises = offsets.map(offset =>
    getAppointments(ADMIN_NS, startDate, endDate, limit, offset)
  )

  const appointmentsPages = await Promise.all(appointmentPromises)

  // Collect data from fetched pages
  for (const appointments of appointmentsPages) {
    if (appointments?.data?.length) {
      allData.push(...appointments.data)
    } else {
      // Break early if a page returns no data
      break
    }
  }

  // Fetch events
  const events = await getAppointmentEvents(ADMIN_NS, startDate, endDate)
  allData.push(...events.data)

  return allData
}

async function loadAppointmentsConcurrently () {
  const promises = []

  for (let weekOffset = 0; weekOffset < WEEK_LOAD; weekOffset++) {
    // Instead of awaiting inside the loop, we push each promise into an array
    promises.push(fetchAppointmentsForWeek(weekOffset))
  }

  // Using Promise.all to wait for all fetches to complete
  const results = await Promise.all(promises)

  // Flatten the results array to get allAppointmentsAndEvents
  const allAppointmentsAndEvents = [].concat(...results)

  // Dispatch the combined data or use it as required
  return {
    code: 'SUCCESS',
    data: allAppointmentsAndEvents
  }
}

const DataLoader = () => {
  const { dispatch, state, app } = useContext(AppContext)
  const [dataText, setDataText] = useState(null)
  const [loaded, setLoaded] = useState(100)
  const [syncOpen, setSyncOpen] = useState(false)
  const [lostConnectionOpen, setLostConnectionOpen] = useState(false)

  async function initData () {
    const auth = await isAuthenticated(ADMIN_NS)
    if (!auth) {
      location.href = '/#/admin/logout'
    }

    const pageLoaded = sessionStorage.getItem('pageLoaded')
    console.log('Page loaded: ', pageLoaded)

    if (!pageLoaded) {
      setSyncOpen(true)
      setLoaded(0)
      setDataText('all calendar appointments')

      const fetchTasks = [
        {
          promise: loadAppointmentsConcurrently().then(async appointments => {
            await localforage.setItem('appointments', appointments.data)
            dispatch({
              type: 'LOAD',
              context: 'appointments',
              data: appointments.data
            })
          }),
          text: 'all calendar appointments'
        },
        {
          promise: updateList('list', getPractitioners)(dispatch),
          text: 'your practitioner data'
        },
        {
          promise: updateList('patients', async () => {
            const limit = 10000
            const maxPages = 1 // Adjust based on expected maximum number of pages
            const offsets = Array.from(
              { length: maxPages },
              (_, i) => i * limit
            )

            // Fetch all pages concurrently
            const patientPromises = offsets.map(offset =>
              getPatients(ADMIN_NS, limit, offset)
            )

            const patientPages = await Promise.all(patientPromises)

            // Collect data from fetched pages
            let allData = []
            for (const patients of patientPages) {
              if (patients?.data?.length) {
                allData.push(...patients.data)
              } else {
                break // Break early if a page returns no data
              }
            }

            return {
              code: 'SUCCESS',
              data: allData
            }
          })(dispatch),
          text: 'the patients data'
        },
        {
          promise: updateList('sms', async () => {
            const limit = 10000
            const maxPages = 1 // Adjust based on expected maximum number of pages
            const offsets = Array.from(
              { length: maxPages },
              (_, i) => i * limit
            )

            // Fetch all pages concurrently
            const smsPromises = offsets.map(offset =>
              getPatientsSms(ADMIN_NS, limit, offset)
            )

            const smsPages = await Promise.all(smsPromises)

            // Collect data from fetched pages
            let allData = []
            for (const sms of smsPages) {
              if (sms?.data?.length) {
                allData.push(...sms.data)
              } else {
                break // Break early if a page returns no data
              }
            }

            return {
              code: 'SUCCESS',
              data: allData
            }
          })(dispatch),
          text: 'the SMS data'
        },

        {
          promise: updateList('schedules', getSchedules)(dispatch),
          text: 'the schedules data'
        },
        {
          promise: updateList('treatments', getTreatments)(dispatch),
          text: 'the treatments data'
        },
        {
          promise: updateList('categories', getCategories)(dispatch),
          text: 'the categories data'
        },
        {
          promise: updateList('notifications', fetcNotifications)(dispatch),
          text: 'the notifications data'
        },
        {
          promise: getItem('practice', getAuthPractice)(dispatch),
          text: 'the practice data'
        },
        {
          promise: updateList('batchqueues', getBatchRecall)(dispatch),
          text: 'batch recall data'
        }
      ]

      const totalFetches = fetchTasks.length
      let completedFetches = 0

      const updateProgress = text => {
        completedFetches++
        setLoaded((completedFetches / totalFetches) * 100)
        setDataText(`Synchronizing ${text}...`)
      }

      const fetchPromises = fetchTasks.map(task =>
        task.promise.then(() => {
          updateProgress(task.text)
        })
      )

      await Promise.all(fetchPromises)

      setLoaded(100)
      setSyncOpen(false)
      setDataText(null)
      sessionStorage.setItem('pageLoaded', 'true')
    } else {
      conssole.log('Page load without refresh detected')
      await updateListFromCache('appointments')(dispatch)
      await updateListFromCache('list')(dispatch)
      await updateListFromCache('patients')(dispatch)
      await updateListFromCache('sms')(dispatch)
      await updateListFromCache('schedules')(dispatch)
      await updateListFromCache('treatments')(dispatch)
      await updateListFromCache('categories')(dispatch)
      await updateObjectFromCache('practice')(dispatch)
      await updateObjectFromCache('meta')(dispatch)
    }
  }

  useEffect(async () => {
    const handleBeforeUnload = e => {
      localforage.clear()
      sessionStorage.removeItem('pageLoaded')
    }
    const pageLoaded = sessionStorage.getItem('pageLoaded')
    console.log('Page loaded: ', pageLoaded)
    if (!pageLoaded) {
      await initData()
      console.log('Page load or refresh detected')
    }
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  useEffect(() => {
    if (state?.meta?.data?.disconnected) {
      setLostConnectionOpen(true)
    } else {
      setLostConnectionOpen(false)
    }
  }, [state?.meta?.data?.disconnected])

  if (!!syncOpen)
    return (
      <SynchronizationDialog
        open={syncOpen}
        loaded={loaded}
        dataText={dataText}
      />
    )

  if (!!lostConnectionOpen)
    return (
      <LostConnectionDialog open={lostConnectionOpen} dataText={dataText} />
    )

  return <></>
}

const SynchronizationDialog = ({ open, loaded, dataText }) => (
  <Dialog open={open} color='primary' maxWidth='lg' fullWidth>
    <LinearProgress variant='buffer' value={loaded} valueBuffer={loaded + 10} />
    <div style={{ textAlign: 'center' }}>
      <DialogTitle>{'Data Synchronization with the cloud!'}</DialogTitle>
      <DialogContent>
        <DialogContentText>Please wait while we are</DialogContentText>
        <DialogContentText>
          synchronizing <strong>{dataText}</strong> with the server ...
        </DialogContentText>
      </DialogContent>
    </div>
    <LinearProgress variant='buffer' value={loaded} valueBuffer={loaded + 10} />
  </Dialog>
)

const LostConnectionDialog = ({ open }) => (
  <Dialog open={open} color='primary' maxWidth='lg' fullWidth>
    <LinearProgress color='primary' />
    <div style={{ textAlign: 'center' }}>
      <DialogTitle>{'Data Synchronization with the cloud!'}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please wait while we are fetching updates from the server ...
        </DialogContentText>
      </DialogContent>
    </div>
    <LinearProgress color='primary' />
  </Dialog>
)

export default DataLoader
