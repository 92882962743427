import React, { useEffect, useState, useContext } from "react";
import {
  Button,
  LinearProgress,
  FormControl,
  DialogTitle,
  Dialog,
  DialogContentText,
  DialogActions,
  DialogContent,
} from "@mui/material";
import { get, set } from "idb-keyval";
import { verifyPermission } from "../../hooks/init";
import { filesUpload } from "../../actions/patients";
import store from "store";
import { ADMIN_NS } from "../../config";
import { AppContext } from "../../hooks/context";
import DropdownSearch from "../../components/dropdown-search";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import PreviewFileViewer from "./components/PreviewFileViewer";
import styled from "styled-components";

let formData = new FormData();

const UploadWrapper = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: 0.5rem;
`;

const getDirHandle = async () =>
  get("dirHandle").then((dirHandle) =>
    verifyPermission(dirHandle).then(() => dirHandle)
  );

const SyncFiles = () => {
  const { state, app, dispatch } = useContext(AppContext);
  const { patients } = state;
  const [selectedPatient, setSelectedPatient] = app.selectedPatient;
  const [showPrompt, setShowPrompt] = app.showPrompt;
  const [showUpload, setShowUpload] = app.showUpload;
  const [previewFile, setPreviewFile] = app.previewFile;

  const [openPatientList, setOpenPatientList] = useState(false);
  const [loaded, setLoaded] = useState(100);

  useEffect(() => {
    store.get("sync")
      ? getDirHandle().then((dirHandle) => {
          const hasPerm = dirHandle
            ?.queryPermission({})
            .then((p) => p === "granted");

          const getPerm = dirHandle
            ?.requestPermission({})
            .then((p) => p === "granted");

          if (hasPerm || getPerm) {
            setShowPrompt(false);
          } else {
            setShowPrompt(true);
          }
        })
      : setShowPrompt(false);
    return () => setShowPrompt(false);
  }, []);

  function delay(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  const uploadUnhandledFiles = (dirHandle) => async (setShowUpload) => {
    const handled = (await store.get("handled")) || [];
    for await (const entry of dirHandle.values()) {
      if (!handled.includes(entry.name)) {
        store.set("handled", [...(handled || []), entry.name]);
        const fileHandle = await dirHandle.getFileHandle(entry.name, {
          create: false,
        });
        const file = await fileHandle.getFile();
        setShowUpload(true);
        formData.append("file", file);
        var src = URL.createObjectURL(file);
        setPreviewFile({ src, type: entry.name.split(".").pop() });
      }
    }
    delay(2000).then(() => uploadUnhandledFiles(dirHandle)(setShowUpload));
  };

  if (!showPrompt) return <></>;

  return (
    <>
      {loaded < 100 && (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 6rem)",
          }}
        >
          <LinearProgress
            variant="buffer"
            value={loaded}
            valueBuffer={loaded + 10}
          />
        </div>
      )}

      <Dialog open={showPrompt} aria-labelledby="responsive-dialog-title">
        <DialogTitle>{"Synchronise X-ray Files to Dentiz?"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Let Dentiz access your x-ray file system so that you can your Xrays
            visable in Dentiz. If you restart your browser you will be re
            promted to allow access.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setShowPrompt(false)}>
            Disagree
          </Button>
          <Button
            onClick={async () => {
              if (!("showOpenFilePicker" in self)) {
                throw new Error("unsupported browser");
              }

              const dirHandle = await window.showDirectoryPicker();
              set("dirHandle", dirHandle);
              uploadUnhandledFiles(dirHandle)(setShowUpload);
              setShowPrompt(false);
            }}
            variant="contained"
            color="primary"
            autoFocus
          >
            Allow Access
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={showUpload} aria-labelledby="responsive-dialog-title">
        <DialogTitle>{"New X-Ray"}</DialogTitle>
        <DialogContent>
          <UploadWrapper>
            <FormControl>
              {previewFile && <PreviewFileViewer data={previewFile} />}
            </FormControl>
            <FormControl>
              {!selectedPatient && (
                <DropdownSearch
                  onFocus={() => setOpenPatientList(true)}
                  open={openPatientList}
                  placeholder={"Search Patient"}
                  options={patients ? patients.data.map((p) => p.data) : []}
                  selectedUser={selectedPatient}
                  setSelectedUser={(item) => {
                    setSelectedPatient(item);
                    setOpenPatientList(false);
                  }}
                  loading={false}
                  dim={{
                    position: "absolute",
                    height: 200,
                    width: 387,
                    itemSize: 10,
                    margin: "0rem",
                  }}
                />
              )}
              {selectedPatient && (
                <ListItem
                  component="nav"
                  button
                  alignitems="flex-start"
                  onClick={() => setSelectedPatient(null)}
                >
                  <ListItemAvatar>
                    <Avatar alt={selectedPatient && selectedPatient.Name}>
                      {selectedPatient &&
                        selectedPatient.Name.split(" ")
                          .map((i) => i.charAt(0).toUpperCase())
                          .slice(0, 2)}
                    </Avatar>
                  </ListItemAvatar>

                  <ListItemText
                    primary={
                      <Typography component="div">
                        {selectedPatient && selectedPatient.Name}
                      </Typography>
                    }
                    secondary={"Click here to change patient!"}
                  />
                </ListItem>
              )}
            </FormControl>
            <FormControl>
              <Button
                disabled={!selectedPatient}
                variant="contained"
                color="primary"
                onClick={() => {
                  filesUpload(
                    ADMIN_NS,
                    formData,
                    selectedPatient,
                    (ProgressEvent) => {
                      setLoaded(
                        (ProgressEvent.loaded / ProgressEvent.total) * 100
                      );
                    }
                  )
                    .then((response) => {
                      setLoaded(100);
                      dispatch({
                        type: "ADD",
                        context: "documents",
                        data: response.data.data,
                        meta: { updating: false },
                        updating: false,
                      });
                      setShowUpload(false);
                    })
                    .catch((err) => {
                      console.error(err);
                      // then print response status
                      console.error("upload fail");
                    });
                }}
              >
                Sync To Patient Documents
              </Button>
            </FormControl>
          </UploadWrapper>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SyncFiles;
