import * as React from "react";
import Popover from "@mui/material/Popover";
import Button from "@mui/material/Button";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateTimeField } from "@mui/x-date-pickers";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers";
import styled from "styled-components";
import Divider from "@mui/material/Divider";
import { PickersDay } from "@mui/x-date-pickers/PickersDay";
import { isWithinInterval, addMonths, startOfMonth } from "date-fns";
import colors from "library/styled-components/colors";
import { CircularProgress } from "@mui/material";

const DateLayoutWrapper = styled.div`
  display: grid;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
`;

const DateLayout = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 2;
`;

const DateColLayout = styled.div`
  display: flex;
  margin: 0px auto;
`;

const InputContainer = styled.div`
  margin-bottom: 1rem;
`;

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)`
  ul {
    max-height: fit-content;
  }
`;

const StyledDateCalander = styled(DateCalendar)`
  & .MuiPickersDay-root {
    background-color: inherit;
    color: inherit;
    border-radius: inherit;
    border: inherit;
  }

  & .MuiPickersDay-root.Mui-selected {
    background-color: inherit;
    color: inherit;
    border-radius: inherit;
    border: inherit;
  }

  & .MuiPickersDay-root.Mui-selected:hover,
  & .MuiPickersDay-root.Mui-selected:focus,
  & .MuiPickersDay-root.Mui-selected:active {
    background-color: inherit;
    color: inherit;
    border-radius: inherit;
    border: inherit;
  }
`;

function Day(props) {
  const { day, start, end, onMouseEnter, clickCount, ...other } = props;

  let dayIsBetween = false;
  let isStartOfRange = false;
  let isEndOfRange = false;

  isStartOfRange = start.toDateString() === day.toDateString();
  isEndOfRange = end.toDateString() === day.toDateString();
  const isSameDay = end.toDateString() === start.toDateString();

  try {
    dayIsBetween = isWithinInterval(day, { start, end });
  } catch (error) {
    console.log(day, start, end);
    console.log(error);
  }

  let styleProps = {};

  // determin style
  if (dayIsBetween) {
    styleProps = {
      backgroundColor: `${colors.lightBlue} !important`,
      color: `${colors.primary} !important`,
      borderRadius: "2px !important",
    };
  }

  if (isEndOfRange) {
    styleProps = {
      backgroundColor:
        clickCount === 0
          ? `${colors.accentColor} !important`
          : clickCount === 1
          ? `${colors.primary} !important`
          : clickCount === 3
          ? `${colors.primary} !important`
          : "green !important",
      color: "white !important",
      borderRadius: isSameDay ? "50% !important" : "2px 50% 50% 2px !important",
    };
  }
  if (isStartOfRange) {
    styleProps = {
      backgroundColor:
        clickCount === 0
          ? `${colors.accentColor} !important`
          : clickCount === 1
          ? `${colors.primary} !important`
          : clickCount === 3
          ? `${colors.primary} !important`
          : "green !important",
      color: "white !important",
      borderRadius: isSameDay ? "50% !important" : "50% 2px 2px 50% !important",
    };
  }

  return (
    <PickersDay
      sx={styleProps}
      {...other}
      day={day}
      onMouseEnter={onMouseEnter}
    />
  );
}

export default function DateTimeRangePicker({
  disableAppointment,
  start: startProp,
  end: endProp,
  onChange,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const start =
    startProp instanceof Date && !isNaN(startProp) ? startProp : new Date();
  const end = endProp instanceof Date && !isNaN(endProp) ? endProp : new Date();
  const [clickCount, setClickCount] = React.useState(3);
  const [defaultCalendarMonth, setDefaultCalendarMonth] = React.useState(
    addMonths(start, 1)
  );

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  function handleDateHover(date) {
    const newStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      start.getHours(),
      start.getMinutes(),
      start.getSeconds()
    );
    const newEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      end.getHours(),
      end.getMinutes(),
      end.getSeconds()
    );

    // Only modify end if start and end are on the same day
    if (newStart.toDateString() === newEnd.toDateString()) {
      // Make sure end is at least 5 minutes greater than start
      if (newEnd.getTime() - newStart.getTime() < 5 * 60 * 1000) {
        newEnd.setTime(newStart.getTime() + 5 * 60 * 1000);
      }
    }

    if (clickCount === 0) {
      if (newStart <= start) {
        //setClickCount(0);
        //onChange(newStart, newEnd);
      } else {
        onChange(start, newEnd);
        //setClickCount(1);
      }
    } else if (clickCount === 1) {
      //onChange(newStart, newEnd);
      //setClickCount(0);
    }
  }

  function handleDateChange(date) {
    const newStart = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      start.getHours(),
      start.getMinutes(),
      start.getSeconds()
    );
    const newEnd = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      end.getHours(),
      end.getMinutes(),
      end.getSeconds()
    );

    // Only modify end if start and end are on the same day
    if (newStart.toDateString() === newEnd.toDateString()) {
      // Make sure end is at least 5 minutes greater than start
      if (newEnd.getTime() - newStart.getTime() < 5 * 60 * 1000) {
        newEnd.setTime(newStart.getTime() + 5 * 60 * 1000);
      }
    }

    if (clickCount === 0) {
      if (newStart <= start) {
        setClickCount(1); 
        onChange(newStart, newEnd);
      } else {
        onChange(start, newEnd);
        setClickCount(1);
      }
    } else if (clickCount === 1) {
      onChange(newStart, newEnd);
      setClickCount(3);
    } else if (clickCount === 3) {
      onChange(newStart, newEnd);
      setClickCount(0);
    }
  }

  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputContainer>
          <DateTimeField
            disabled={disableAppointment}
            label="Start"
            format="dd/MM/yyyy HH:mm"
            ampm={false}
            sx={{
              bgcolor: "white",
              width: "100%",
            }}
            value={start ?? new Date()}
            onChange={(date) => onChange(date, end)}
            onClick={handleClick}
          />
        </InputContainer>
      </LocalizationProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <InputContainer>
          <DateTimeField
            disabled={disableAppointment}
            label="End"
            format="dd/MM/yyyy HH:mm"
            ampm={false}
            sx={{
              bgcolor: "white",
              width: "100%",
            }}
            value={end ?? new Date()}
            onChange={(date) => onChange(start, date)}
            onClick={handleClick}
          />{" "}
        </InputContainer>
      </LocalizationProvider>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div
            style={{
              display: "flex",
            }}
          >
            <DateLayoutWrapper>
              <DateLayout>
                <DateColLayout>
                  <StyledMultiSectionDigitalClock
                    ampm={false}
                    sx={{ width: "auto" }}
                    value={start}
                    onChange={(date) => {
                      const newStart = new Date(
                        start.getFullYear(),
                        start.getMonth(),
                        start.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds()
                      );

                      const newEnd = new Date(
                        end.getFullYear(),
                        end.getMonth(),
                        end.getDate(),
                        end.getHours(),
                        end.getMinutes(),
                        end.getSeconds()
                      );

                      // Only modify end if start and end are on the same day
                      if (newStart.toDateString() === newEnd.toDateString()) {
                        // Make sure end is at least 5 minutes greater than start
                        if (
                          newEnd.getTime() - newStart.getTime() <
                          5 * 60 * 1000
                        ) {
                          newEnd.setTime(newStart.getTime() + 5 * 60 * 1000);
                        }
                      }
                      onChange(newStart, newEnd);
                    }}
                  />

                  <Divider orientation="vertical" flexItem />

                  <StyledDateCalander
                    onMonthChange={(month) => {
                      setDefaultCalendarMonth(null);
                      setTimeout(() => {
                        setDefaultCalendarMonth(addMonths(month, 1));
                      }, 0);
                    }}
                    onYearChange={(year) => {
                      setDefaultCalendarMonth(null);
                      setTimeout(() => {
                        setDefaultCalendarMonth(addMonths(year, 1));
                      }, 0);
                    }}
                    onChange={handleDateChange}
                    defaultCalendarMonth={start}
                    slots={{ day: Day }}
                    slotProps={{
                      day: {
                        start: start,
                        end: end,
                        clickCount,
                        onMouseEnter: (e, date) => {
                          handleDateHover(date);
                        },
                      },
                    }}
                  />
                </DateColLayout>
              </DateLayout>
            </DateLayoutWrapper>

            <DateLayoutWrapper>
              <DateLayout>
                <DateColLayout>
                  {defaultCalendarMonth ? (
                    <StyledDateCalander
                      onChange={handleDateChange}
                      slots={{ day: Day }}
                      defaultCalendarMonth={defaultCalendarMonth}
                      minDate={startOfMonth(defaultCalendarMonth)}
                      slotProps={{
                        day: {
                          start: start,
                          end: end,
                          clickCount,
                          onMouseEnter: (e, date) => {
                            handleDateHover(date);
                          },
                        },
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        width: "320px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <CircularProgress />
                    </div>
                  )}
                  <Divider orientation="vertical" flexItem />
                  <StyledMultiSectionDigitalClock
                    ampm={false}
                    sx={{ width: "auto" }}
                    value={end}
                    onChange={(date) => {
                      const newStart = new Date(
                        start.getFullYear(),
                        start.getMonth(),
                        start.getDate(),
                        start.getHours(),
                        start.getMinutes(),
                        start.getSeconds()
                      );

                      const newEnd = new Date(
                        end.getFullYear(),
                        end.getMonth(),
                        end.getDate(),
                        date.getHours(),
                        date.getMinutes(),
                        date.getSeconds()
                      );

                      // Only modify end if start and end are on the same day
                      if (newStart.toDateString() === newEnd.toDateString()) {
                        // Make sure end is at least 5 minutes greater than start
                        if (
                          newEnd.getTime() - newStart.getTime() <
                          5 * 60 * 1000
                        ) {
                          newEnd.setTime(newStart.getTime() + 5 * 60 * 1000);
                        }
                      }
                      onChange(newStart, newEnd);
                    }}
                  />
                </DateColLayout>
              </DateLayout>
            </DateLayoutWrapper>
          </div>
        </LocalizationProvider>
        <Divider flexItem />
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            margin: "0.5rem",
          }}
        >
          <Button onClick={handleClose} variant="outlined">
            OK
          </Button>
        </div>
      </Popover>
    </div>
  );
}
