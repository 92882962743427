import one from "./img/teeth/table7/48b.png";
import oneImplant from "./img/teeth/table7/implants/48b.png";
import oneRemoved from "./img/teeth/table7/removed/48b.png";

import two from "./img/teeth/table7/47b.png";
import twoImplant from "./img/teeth/table7/implants/47b.png";
import twoRemoved from "./img/teeth/table7/removed/47b.png";

import three from "./img/teeth/table7/46b.png";
import threeImplant from "./img/teeth/table7/implants/46b.png";
import threeRemoved from "./img/teeth/table7/removed/46b.png";

import four from "./img/teeth/table7/45b.png";
import fourImplant from "./img/teeth/table7/implants/45b.png";
import fourRemoved from "./img/teeth/table7/removed/45b.png";

import five from "./img/teeth/table7/44b.png";
import fiveImplant from "./img/teeth/table7/implants/44b.png";
import fiveRemoved from "./img/teeth/table7/removed/44b.png";

import six from "./img/teeth/table7/43b.png";
import sixImplant from "./img/teeth/table7/implants/43b.png";
import sixRemoved from "./img/teeth/table7/removed/43b.png";

import seven from "./img/teeth/table7/42b.png";
import sevenImplant from "./img/teeth/table7/implants/42b.png";
import sevenRemoved from "./img/teeth/table7/removed/42b.png";

import eight from "./img/teeth/table7/41b.png";
import eightImplant from "./img/teeth/table7/implants/41b.png";
import eightRemoved from "./img/teeth/table7/removed/41b.png";

export const sevenSettings = {
  imgs: [
    {
      id: 18,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      },
      pos: "0 25px",
      width: "61px",
    },
    {
      id: 17,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      },
      pos: "0 25px",
      width: "61px",
    },
    {
      id: 16,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      },
      pos: "0 23px",
      width: "69px",
    },
    {
      id: 15,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 22px",
      width: "45px",
    },
    {
      id: 14,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 15px",
      width: "41px",
    },
    {
      id: 13,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "0 11px",
      width: "39px",
    },
    {
      id: 12,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 15px",
      width: "37px",
    },
    {
      id: 11,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 19px",
      width: "35px",
    },
  ],
};

export const sevenState = {
  margin: [
    [7, 63],
    [27.5, 63],
    [48, 63],

    [62, 63],
    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  depth: [
    [7, 63],
    [27.5, 63],
    [48, 63],
    [62, 63],

    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  imgState: [
    {
      id: 18,

      furcation: [{ state: null, pos: [26, 74] }],
    },
    {
      id: 17,

      furcation: [{ state: null, pos: [82, 74] }],
    },
    {
      id: 16,

      furcation: [{ state: null, pos: [142, 74] }],
    },
    {
      id: 15,
    },
    {
      id: 14,
    },
    {
      id: 13,
    },
    {
      id: 12,
    },
    {
      id: 11,
    },
  ],
};
