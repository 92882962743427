import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "styled-components";

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const SelectUi = ({ InputData, LabelData, onChange, options }) => {
  //console.log(LabelData, InputData)
  return (
    <InputContainer>
      <FormControl variant="outlined">
        <InputLabel id={LabelData.text}>{LabelData.text}</InputLabel>
        <Select
          labelId={LabelData.text}
          id={InputData.id}
          value={(InputData.value ? InputData.value : InputData.defaultValue)}
          displayEmpty
          disabled={InputData.disable}
          onChange={(val) => onChange(val.target.value)}
          label={LabelData.text}
        >
          <MenuItem value={InputData.defaultValue}>
            <em> {InputData.defaultValue}</em>
          </MenuItem>
          {options.map((o) => (
            <MenuItem key={o.key} value={o.value}>
              {o.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormHelperText>{LabelData.error}</FormHelperText>
    </InputContainer>
  );
};

export { SelectUi };
