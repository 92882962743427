import React from "react";
import * as Joi from "@hapi/joi";
import LockFeaIcon from "../../../icon/fea-icons/lock";

const validation = {
  Password: Joi.string().min(10).max(255).required(),
  Accept: Joi.string().min(7).max(7).required(),
};

export const formSchema = {
  Password: {
    domRef: null,
    isRequired: true,
    password: true,
    text: "Password",
    type: "password",
    val: "",
    icon: <LockFeaIcon size="16" />,
    placeholder: "Please enter a secure password",
    validation: Joi.object({ Password: validation.Password }),
  },
  Accept: {
    domRef: null,
    text: "I confirm that I have read the Practice Privacy Statement for Dentiz. I agree to OPT IN to the terms and conditions as outlined in the statement which include the practice contacting me via phone, email or SMS. I understand that I may revoke this consent at any time.",
    link: "Privacy Policy",
    type: "checkbox",
    call: "show-terms",
    val: "",
    validation: Joi.object({ Accept: validation.Accept }),
  },
};

export const createButtons = [
  {
    text: "Create Password",
    type: "Submit",
  },
];
