import localforage from "localforage";

const notificationsCreate = (eventData) => async (dispatch) => {
  const { Notification } = eventData;
  const notifications = await localforage.getItem("notifications");
  const data = [...notifications, Notification];
  dispatch({
    type: "LOAD",
    context: "notifications",
    data,
  });
  await localforage.setItem("notifications", data);
};

export default {
  create: notificationsCreate,
};
