import { handleResponse, authHeader } from "../../library/handler";

export async function getScheduleById(state, uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules/id/${uuid}`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
}

export const getSchedules = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const getPractitionerSchedules = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules/${uuid}`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const createSchedule = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const deleteSchedule = async (state, practitionerId, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules/${practitionerId}/${uuid}`, {
    method: "delete",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const updateSchedule = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/schedules/update/${uuid}`, {
    method: "put",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};
