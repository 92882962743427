import React, { createRef, useEffect, useMemo } from "react";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import styled from "styled-components";
import {
  mapBusinessHours,
  mapSimpleAppointments,
  mapSimpleEvents,
} from "../../../library/calendar";
import { PreviewCalendarStyles } from "../../common";
import { addMinutes } from "date-fns";
import { openingHours } from "config";

const StyledEventContent = styled.div`
  &:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-style: ${(props) => (props.isNewEventChanged ? "dashed" : "solid")};
    border-radius: 3px;
    pointer-events: none; // Prevents the pseudo-element from interfering with mouse events
  }
`;
function renderEventContent(eventInfo) {
  const { event } = eventInfo;
  const isNewEventChanged = event.extendedProps.isNewEventChanged; // Adjust as needed

  return (
    <StyledEventContent
      key={event?.id}
      isNewEventChanged={isNewEventChanged}
      style={{
        backgroundColor: event.backgroundColor,
        borderColor: event.borderColor,
        borderStyle: event.borderStyle,
        color: event.textColor,
      }}
    />
  );
}

const Container = styled(PreviewCalendarStyles)`
  display: grid;
  background: inherit;
  position: relative !important;
  height: fit-content;
  code {
    font-family: "Nunito", sans-serif;
  }
`;

const AppointmentPreviewCalendar = ({
  appointmentId,
  eventId,
  practitionerName,
  publicHolidays,
  defaultAppointmentLength,
  color,
  form,
  schedules,
  appointments,
  updateTime,
}) => {
  const previewComponentRef = createRef();

  const businessHours = mapBusinessHours(schedules);

  const events = useMemo(
    () =>
      eventId
        ? mapSimpleEvents(appointments, { ...form, eventId })
        : mapSimpleAppointments(appointments, { ...form, appointmentId }),
    [(appointments, form)]
  );

  useEffect(() => {
    if (!form?.start) return;
    let calendarApi = previewComponentRef.current.getApi();
    calendarApi.gotoDate(form.start);
  }, [form.start]);

  return (
    <Container color={color}>
      <div
        className="preview"
        style={{
          backgroundColor: "white",
        }}
      >
        <FullCalendar
          slotMinTime={openingHours.slotMinTime}
          slotMaxTime={openingHours.slotMaxTime}
          ref={previewComponentRef}
          plugins={[interactionPlugin, timeGridPlugin]}
          timeZone={"local"}
          themeSystem="bootstrap"
          initialView={
            appointmentId || eventId ? "timeGridDay" : "timeGridWeek"
          }
          dayHeaderFormat={{ weekday: "long" }}
          height="48rem"
          weekends={true}
          editable={true}
          selectable={true}
          slotEventOverlap={true}
          eventOverlap={(stillEvent, movingEvent) => {
            return stillEvent.allDay && movingEvent.allDay;
          }}
          validRange={{
            start: ((!appointmentId || !eventId) && new Date()) || undefined,
          }}
          allDaySlot={true}
          headerToolbar={{
            left: "prev,name",
            center: "title",
            right: "next",
          }}
          businessHours={businessHours}
          eventConstraint="businessHours"
          selectConstraint="businessHours"
          customButtons={{
            name: {
              text: (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "4px",
                  }}
                >
                  <div> {practitionerName} </div>
                </div>
              ),
            },
          }}
          hiddenDays={schedules.filter((s) => s.TypeId === 4).map((s) => s.Day)}
          events={[...events, ...(publicHolidays || [])]}
          eventContent={renderEventContent} // custom render function
          dateClick={(e) => {
            updateTime({
              start: e.date,
              end: addMinutes(e.date, defaultAppointmentLength || 30),
            });
          }}
          eventClick={(e) => {
            updateTime({
              start: e.event.start,
              end: e.event.end,
            });
          }}
          eventChange={(calEvent) => {
            const {
              event: { start, end },
            } = calEvent;
            updateTime({
              start,
              end,
            });
          }}
        />
      </div>
    </Container>
  );
};

export default AppointmentPreviewCalendar;
