import React, { createContext, useReducer, useState, useEffect } from "react";
import { reducer, initialState } from "../containers/admin/reducer";
import { initSockets } from "./init";
import stateHandler from "./states/stateHandler";

const initialAppState = {
  overlay: null,
  language: null,
};

const AppContext = createContext({
  app: initialAppState,
  state: initialState,
});

const AppProvider = ({ practiceId, token, children }) => {
  const [socket, setSocket] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [overlay, setOverlay] = useState(0);
  const [showSms, setShowSms] = useState(false);
  const [showPrompt, setShowPrompt] = useState(true);
  const [showUpload, setShowUpload] = useState(false);
  const [previewFile, setPreviewFile] = useState(null);
  const [newEvents, setNewEvents] = useState({
    registered: null,
    sms: null,
    appointment: null,
  });
  const [language, setLanguage] = useState("en");
  const [scroll, setLayoutScrollTo] = useState(false);

  const app = {
    overlay: [overlay, setOverlay],
    language: [language, setLanguage],
    sms: [showSms, setShowSms],
    events: [newEvents, setNewEvents],
    scrollEvent: [scroll, setLayoutScrollTo],
    socket: socket,
    selectedPatient: [selectedPatient, setSelectedPatient],
    showPrompt: [showPrompt, setShowPrompt],
    showUpload: [showUpload, setShowUpload],
    previewFile: [previewFile, setPreviewFile],
  };

  function registeredPatient(data) {
    const { Name, PractitionerName, PatientId, Start } = data;

    dispatch({
      type: "ADD",
      context: "patients",
      data: data,
      updating: false,
    });

    dispatch({
      type: "ADD",
      context: "sms",
      data: data,
      updating: false,
    });

    setNewEvents({
      ...newEvents,
      registered: {
        Name,
        PractitionerName,
        Time: Start,
        PatientId,
      },
    });

    setTimeout(async () => {
      setNewEvents({
        ...newEvents,
        registered: null,
      });
    }, 2000);
  }

  async function recieveSms(data) {
    setNewEvents({
      ...newEvents,
      messageRecieved: data,
    });
  }

  function updateSmsEvent(data) {
    const smsUser = state.sms.data.find(
      (s) => s?.data?.UserId === data.UserId
    )?.data;
    // find sms messsage by message id and update it
    if (smsUser) {
      const smsMessages = smsUser.SmsMessages.map((m) => {
        if (m.Sid === data.Sid) {
          return { ...m, ...data };
        }
        return m;
      });

      dispatch({
        type: "UPDATE_ITEM_CHILD",
        context: "sms",
        childContext: "SmsMessages",
        updating: false,
        data: {
          SmsMessages: smsMessages,
        },
        uuid: { UserId: data.UserId },
      });
    }
  }

  function recieveAppointment(data) {
    dispatch({
      type: "ADD",
      context: "appointments",
      data: data,
      updating: false,
    });

    setNewEvents({
      ...newEvents,
      appointment: data,
    });

    setTimeout(async () => {
      setNewEvents({
        ...newEvents,
        appointment: null,
      });
    }, 2000);
  }

  async function patientArrived(data) {
    const { AppointmentId, Name, PractitionerName, PatientId, Start } = data;
    setNewEvents({
      ...newEvents,
      arrived: {
        AppointmentId,
        Name,
        PractitionerName,
        Time: Start,
        PatientId,
      },
    });

    setTimeout(async () => {
      setNewEvents({
        ...newEvents,
        arrived: null,
      });
    }, 2000);
  }

  async function patientRemoved(data) {
    setNewEvents({
      ...newEvents,
      removed: true,
    });

    setTimeout(async () => {
      setNewEvents({
        ...newEvents,
        removed: false,
      });
    }, 2000);
  }

  async function patientInTreatment(data) {
    const { AppointmentId, Name, PractitionerName, PatientId, Start } = data;
    setNewEvents({
      ...newEvents,
      inTreatment: {
        AppointmentId,
        Name,
        PractitionerName,
        Time: Start,
        PatientId,
      },
    });

    setTimeout(async () => {
      setNewEvents({
        ...newEvents,
        inTreatment: null,
      });
    }, 2000);
  }

  useEffect(() => {
    token &&
      initSockets({
        setSocket,
        events: {
          registeredPatient,
          recieveSms,
          updateSmsEvent,
          recieveAppointment,
          patientArrived,
          patientRemoved,
          patientInTreatment,
          stateHandler,
          dispatch,
        },
        practiceId,
        token,
      });
  }, [initSockets, token]);

  return (
    <AppContext.Provider value={{ app, state, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export { AppProvider, AppContext };
