import React from "react";
import { Wrapper, Container } from "./styles";

export default function InfoLayout(props) {
  return (
    <Wrapper {...props}>
      <Container>{props.children}</Container>
    </Wrapper>
  );
}
