import React, { useState } from "react";
import styled from "styled-components";
import colors from "../../../library/styled-components/colors";
import moment from "moment";

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const InputField = styled.input`
  font-size: 16px;
  border: 1px solid ${(props) => (props.changed ? colors.primary : "#bdc4c9")};
  background-color: ${(props) =>
    props.changed ? colors.primLight : "#ffffff"};
  height: 3em;
  width: 4rem;

  padding: 0 0.5rem;
  border-radius: 4px;
  &:focus {
    outline: none;
    border-color: #0070e0;
  }
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
`;

const InputFieldContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: 0.5rem;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
  color: ${colors.accentColor};
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
  text-align: right;
`;

const Input = ({ InputData, LabelData, onChange, icon, changed }) => {
  const [date, setDate] = useState(
    InputData.value
      ? moment(InputData.value)
      : (InputData.defaultValue && moment(InputData.defaultValue)) || moment()
  );
  
  return (
    <InputContainer>
      <LabelContainer>
        <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
        {LabelData.error && (
          <ErrorMessage htmlFor={LabelData.text}>
            {LabelData.error}
          </ErrorMessage>
        )}
      </LabelContainer>
      <LabelContainer>
        <Label>month</Label>
        <Label>day</Label>
        <Label>year</Label>
      </LabelContainer>
      <InputFieldContainer>
        <InputField
          changed={changed}
          type="number"
          placeholder="month"
          value={InputData.value ? moment(InputData.value).month() + 1 : null}
          onChange={(e) => {
            e.preventDefault();
            const val = e.target.value;

            if (val !== "" && val > 0 && val <= 12) {
              setDate(date.set("month", val - 1));
              onChange(date);
            }
            if (val === "") onChange(null);
          }}
        />
        <InputField
          changed={changed}
          type="number"
          placeholder="month"
          value={InputData.value ? moment(InputData.value).date() : null}
          onChange={(e) => {
            e.preventDefault();
            const val = e.target.value;

            if (val !== "" && val >= 0 && val <= 31) {
              setDate(date.set("date", val));
              onChange(date);
            }
            if (val === "") onChange(null);
          }}
        />
        <InputField
          changed={changed}
          type="number"
          placeholder="year"
          value={InputData.value ? moment(InputData.value).year() : null}
          onChange={(e) => {
            e.preventDefault();
            const val = e.target.value;

            if (val !== "" && val > 0 && val <= 99999) {
              setDate(date.set("year", val));
              onChange(date);
            }
            if (val === "") onChange(null);
          }}
        />
      </InputFieldContainer>
    </InputContainer>
  );
};

export default Input;
