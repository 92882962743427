import React from "react";
import { useHistory } from "react-router-dom";

import TodayIcon from "@mui/icons-material/Today";
import GroupIcon from "@mui/icons-material/Group";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import SettingsIcon from "@mui/icons-material/Settings";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import ControlPointIcon from "@mui/icons-material/ControlPoint";

import {
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import colors from "library/styled-components/colors";

export const Menu = ({ setShowPop, showPop, show, setShow, user }) => {
  const history = useHistory();
  async function navigateTo(pathname) {
    history.push({
      pathname: pathname,
    });
  }
  return (
    <>
      <Drawer anchor={"left"} open={show} onClose={() => setShow(false)}>
        <div style={{ width: "18rem" }}>
          <List>
            <ListItem
              button
              disabled={window.location.hash.includes("calendar")}
              onClick={() => navigateTo("/admin/home/calendar")}
            >
              <ListItemIcon>
                <TodayIcon
                  style={{
                    color: window.location.hash.includes("calendar")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Appointments"} />
            </ListItem>

            <ListItem
              button
              disabled={window.location.hash.includes("practitioners")}
              onClick={() => navigateTo("/admin/home/practitioners")}
            >
              <ListItemIcon>
                <SupervisedUserCircleIcon
                  style={{
                    color: window.location.hash.includes("practitioners")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Practitioners"} />
            </ListItem>

            <ListItem
              button
              disabled={window.location.hash.includes("patients")}
              onClick={() => navigateTo("/admin/home/patients")}
            >
              <ListItemIcon>
                <GroupIcon
                  style={{
                    color: window.location.hash.includes("patients")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Patients"} />
            </ListItem>

            <ListItem
              button
              disabled={window.location.hash.includes("treatments")}
              onClick={() => navigateTo("/admin/home/treatments")}
            >
              <ListItemIcon>
                <AssignmentIcon
                  style={{
                    color: window.location.hash.includes("treatments")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Treatments"} />
            </ListItem>

            <ListItem
              style={{
                display: user?.Permissions?.canAccessReportsPage
                  ? "inline-flex"
                  : "none",
              }}
              button
              disabled={window.location.hash.includes("reports")}
              onClick={() => navigateTo("/admin/home/reports")}
            >
              <ListItemIcon>
                <AccountBalanceIcon
                  style={{
                    color: window.location.hash.includes("reports")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Reports"} />
            </ListItem>

            <ListItem
              style={{
                display: user?.Permissions?.canAccessOverViewPage
                  ? "inline-flex"
                  : "none",
              }}
              button
              disabled={window.location.hash.includes("overview")}
              onClick={() => navigateTo("/admin/home/overview")}
            >
              <ListItemIcon>
                <EqualizerIcon
                  style={{
                    color: window.location.hash.includes("overview")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Overview"} />
            </ListItem>
            <ListItem
              style={{ display: "none" }}
              button
              onClick={(e) => {
                setShowPop(!showPop);
                setShowSms(false);
              }}
            >
              <ListItemIcon>
                <ControlPointIcon
                  style={{
                    color: colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Invite Patients"} />
            </ListItem>
          </List>

          <Divider />

          <List end>
            <ListItem
              button
              disabled={window.location.hash.includes("settings")}
              onClick={() => navigateTo("/admin/home/settings")}
            >
              <ListItemIcon>
                <SettingsIcon
                  style={{
                    color: window.location.hash.includes("settings")
                      ? colors.softGreen
                      : colors.primary,
                  }}
                />
              </ListItemIcon>
              <ListItemText primary={"Settings"} />
            </ListItem>

            <ListItem button onClick={() => navigateTo("/admin/logout")}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
        </div>
      </Drawer>
    </>
  );
};
