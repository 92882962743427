import React, { useMemo } from "react";
import styled from "styled-components";
import FeaCloseCircleIcon from "../../../icon/fea-icons/close-circle";
import FeaCheckIcon from "../../../icon/fea-icons/check";
import colors from "../../../library/styled-components/colors";
import { DynamicIconBtn, BtnMarginContainer, PosIcon } from "../../common";
import { differenceInHours, differenceInMinutes } from "date-fns";
import DoubleBounce from "../../loaders/double-bounce";

const BlockWrap = styled.div`
  display: grid;
  width: 100%;
`;

const AppointmentActions = ({
  id,
  row,
  updateAppointment,
  appointmentData,
  setCancel,
  showAccounts,
  setCreateInvoice,
}) => {
  if (!appointmentData)
    return <DoubleBounce color={colors.primary} margin="20%" />;

  const startTimeDate = appointmentData.Start;
  const endTimeDate = appointmentData.End;

  const form = useMemo(
    () => ({
      start: appointmentData.Date,
      startTime: startTimeDate,
      hours: differenceInHours(endTimeDate, startTimeDate) || 0,
      minutes:
        differenceInMinutes(endTimeDate, startTimeDate, endTimeDate) % 60,
      patientId: appointmentData.PatientId,
      practitionerId: appointmentData.PractitionerId,
      treatmentId: appointmentData.TreatmentId,
      meta: appointmentData.Meta,
      sendSms: false,
      sendMail: false,
    }),
    [appointmentData]
  );

  const isComplete = appointmentData && appointmentData.Meta?.completed;

  return (
    <BlockWrap>
      {id && (
        <BtnMarginContainer row={row}>
          <DynamicIconBtn
            color={colors.accentColor}
            textColor={colors.white}
            marked={form?.meta?.arrived}
            disabled={isComplete}
            onClick={() => {
              const update = {
                ...form,
                meta: {
                  ...form.meta,
                  arrived: !form.meta?.arrived,
                  inTreatment: false,
                },
              };
              updateAppointment(update, appointmentData.AppointmentId);
            }}
          >
            <PosIcon>
              {form?.meta?.arrived ? (
                <FeaCheckIcon size="16" />
              ) : (
                <FeaCloseCircleIcon size="16" />
              )}
            </PosIcon>
            {form?.meta?.arrived
              ? "Patient in Waiting"
              : "Mark Patient arrived"}
          </DynamicIconBtn>

          <DynamicIconBtn
            color={colors.secondary}
            textColor={colors.white}
            marked={form?.meta?.inTreatment}
            disabled={isComplete}
            onClick={() => {
              const update = {
                ...form,
                meta: {
                  ...form.meta,
                  arrived: true,
                  inTreatment: !form.meta?.inTreatment,
                },
              };
              updateAppointment(update, appointmentData.AppointmentId);
            }}
          >
            <PosIcon>
              {form?.meta?.inTreatment ? (
                <FeaCheckIcon size="16" />
              ) : (
                <FeaCloseCircleIcon size="16" />
              )}
            </PosIcon>
            {form?.meta?.inTreatment ? "In Treatment" : "Start Treatment"}
          </DynamicIconBtn>

          <DynamicIconBtn
            color={
              appointmentData.CancelationId
                ? colors.lightGrey
                : colors.softRed
            }
            textColor={
              appointmentData.CancelationId ? colors.black : colors.white
            }
            onClick={setCancel}
          >
            <PosIcon>
              {appointmentData.CancelationId ? (
                <FeaCheckIcon size="16" />
              ) : (
                <FeaCloseCircleIcon size="16" />
              )}
            </PosIcon>
            {appointmentData.CancelationId ? "Uno Cancellation" : "Cancel"}
          </DynamicIconBtn>

          {!isComplete && (
            <DynamicIconBtn color={colors.softGreen} onClick={setCreateInvoice}>
              <PosIcon>
                <FeaCloseCircleIcon size="16" />
              </PosIcon>
              Pay Invoice
            </DynamicIconBtn>
          )}

          {isComplete && (
            <DynamicIconBtn color={colors.rgbInfoLight} onClick={showAccounts}>
              <PosIcon>
                <FeaCheckIcon size="16" />
              </PosIcon>
              Invoice Paid
            </DynamicIconBtn>
          )}
        </BtnMarginContainer>
      )}
    </BlockWrap>
  );
};

export default AppointmentActions;
