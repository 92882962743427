import { handleResponse, authHeader } from "../../library/handler";

export async function getUserById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/users/id/${uuid}`)
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function getUserByPracticeId(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/practice/id/${uuid}`)
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function createUser(data) {
  return fetch(`${process.env.REACT_APP_APP_API}/users/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function inviteUser(state, data) {
  return fetch(`${process.env.REACT_APP_APP_API}/users/invite`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function checkAuthStatus(state) {
  return fetch(`${process.env.REACT_APP_APP_API}/users/check`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}
