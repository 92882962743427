import React, { useState } from "react";
import { Note, NoteHeader, NoteName, NoteText } from "components/common";
import { Button, TextField, Typography } from "@mui/material";
import { Send, EditOutlined } from "@mui/icons-material";
import colors from "library/styled-components/colors";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { Close } from "styled-icons/material";
import { formatDistance } from "date-fns";

const CommentBlockItem = ({
  user,
  comment,
  id,
  form,
  setForm,
  updateAppointment,
  setDeleteItem,
}) => {
  const [editItem, setEditItem] = useState(null);
  const [editNoteText, setEditNoteText] = useState("");

  return (
    <Note key={comment.id}>
      <NoteHeader>
        <div>
          <NoteName>{comment.user}</NoteName>
          {comment?.updatedAt || comment?.createdAt || comment?.created ? (
            <Typography
              variant="caption"
              style={{
                minWidth: "6rem",
                textAlign: "right",
              }}
            >
              last updated:{" "}
              {formatDistance(
                new Date(),
                new Date(
                  comment?.updatedAt || comment?.createdAt || comment?.created
                ),
                {
                  includeSeconds: true,
                }
              )}{" "}
              ago
            </Typography>
          ) : (
            ""
          )}
        </div>

        {editItem === comment.id ? (
          <DeleteForeverIcon
            onClick={() => setDeleteItem(comment.id)}
            style={{
              color: colors.errorColor,
              cursor: "pointer",
            }}
          />
        ) : (
          <EditOutlined
            fontSize="small"
            onClick={() => {
              setEditItem(comment.id);
              setEditNoteText(comment.text);
            }}
            style={{
              color: colors.primary,
              cursor: "pointer",
            }}
          />
        )}
      </NoteHeader>
      {editItem === comment.id ? (
        <div
          style={{
            width: "100%",
            marginTop: "4px",
          }}
        >
          <TextField
            style={{
              width: "100%",
              background: colors.lightGrey,
            }}
            value={editNoteText}
            multiline
            placeholder="This comment is empty"
            variant="outlined"
            onChange={(e) => setEditNoteText(e.target.value)}
          />
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "auto auto",
              marginTop: "4px",
              gridGap: "4px",
            }}
          >
            <Button
              size="small"
              disabled={editNoteText === comment.text}
              onClick={() => {
                const meta = {
                  ...form?.meta,
                  Comments: form?.meta.Comments.map((n) =>
                    n.id === comment.id
                      ? {
                          ...n,
                          text: editNoteText,
                          user: user?.data?.Name,
                          updatedAt: new Date(),
                        }
                      : n
                  ),
                };
                setForm({
                  ...form,
                  meta,
                });

                if (!!id)
                  updateAppointment(
                    {
                      ...form,
                      meta,
                    },
                    false
                  );
                setEditItem(null);
                setEditNoteText("");
              }}
              style={{
                background:
                  editNoteText === comment.text
                    ? colors.grey
                    : colors.accentColor,
                color: "white",
              }}
              startIcon={<Send />}
            >
              Save
            </Button>

            <Button
              onClick={() => {
                setEditItem(null);
                setEditNoteText("");
              }}
              style={{ background: colors.lightGrey }}
              startIcon={<Close />}
            >
              Cancel
            </Button>
          </div>
        </div>
      ) : (
        <NoteText>{comment.text}</NoteText>
      )}
    </Note>
  );
};

export default CommentBlockItem;
