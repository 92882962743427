import { applyMiddleware, createStore } from "redux";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import React from "react";
import { render } from "react-dom";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/browser";
//import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import App from "./App";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";
import { StyledEngineProvider } from "@mui/system";

import reducer from "./reducers";
import "./index.css";
import UpdateNotifier from "UpdateNotifier";

const isSentryDisabled = process.env.REACT_APP_DISABLE_SENTRY === 'true';

if (!isSentryDisabled) {
  Sentry.init({
    dsn: "https://3504b3f6b7f74a16a40b58dd61ae2358@o4504332493324288.ingest.sentry.io/4504332513771520",
    integrations: [new BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const middleware = applyMiddleware(thunk);
const store = createStore(reducer, middleware);

const theme = createTheme();

render(
  <Provider store={store}>
    <HashRouter>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
          <UpdateNotifier />
        </ThemeProvider>
      </StyledEngineProvider>
    </HashRouter>
  </Provider>,
  document.getElementById("root")
);

// Register the service worker
// serviceWorkerRegistration.register();
