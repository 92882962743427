import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";

export const Container = styled.div`
  display: grid;
  box-sizing: border-box;
  background-color: ${colors.light};
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props.home ? `4.6em auto 4.6em` : `10em auto 4.6em`};
  background: ${colors.primary};
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  transition: all 0.5s ease;
  min-height: 74px;
  text-align: center;
  color: ${colors.primary};
  height: 3rem;
  a {
    color: ${colors.primary};
    margin: 0.6em;
  }
`;

export const MenuHeader = styled.div`
  display: grid;
  grid-template-columns: 8fr 2fr;
  margin: 0.2rem 0;
`;

export const Content = styled.div`
  display: grid;
`;

export const ContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 3rem;
  background: ${colors.primary};
  width: 100%;
`;

export const ContentWrapper = styled.div``;

export const InputContainer = styled.div`
  width: 18rem;
  display: grid;
  position: relative !important;
  border-radius: 4px;
`;

export const InputField = styled.input`
  font-size: 16px;
  border: 1px solid ${(props) => (props.changed ? colors.primary : "#bdc4c9")};
  background-color: ${(props) =>
    props.changed ? colors.primLight : "#ffffff"};
  height: 3em;
  border-radius: 4px;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  &:focus {
    outline: none;
    border-color: #0070e0;
  }
`;

export const Icon = styled.span`
  position: absolute;
  top: 14px;
  left: 14px;
`;

export const RightIcon = styled.span`
  display: ${(props) => (props.active ? "show" : "none")};
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 14px;
`;

export const RightTopIcon = styled.span`
  display: ${(props) => (props.active ? "show" : "none")};
  position: absolute;
  cursor: pointer;
  background: ${colors.light};
  display: flex;
  border-radius: 50%;
  top: -8px;
  right: -8px;
`;

export const HeadItem = styled.li`
  display: list-item;
  position: relative;
  list-style-type: none;
  height: 3rem;
  text-align: -webkit-match-parent;
  i {
    color: ${colors.white};
  }

  span,
  a {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
    padding: 1em;
    font-size: 16px;
    height: auto;
    text-decoration: none;
    min-height: 40px;
    width: 4rem;
  }
  i {
    font-size: 16px;
    margin-right: 10px;
    min-width: 20px;
  }

  color: ${colors.white};
  background-color: ${colors.primColor};
`;

export const Bubble = styled.div`
  position: absolute;
  background-color: red;
  color: white;
  top: 0.3rem;
  font-size: 0.8rem;
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 1rem;
  text-align: center;
`;

export const Item = styled.li`
  display: list-item;
  position: relative;
  list-style-type: none;
  height: 52px;
  text-align: -webkit-match-parent;
  i {
    color: ${colors.grey};
  }
  ${(props) =>
    props.show &&
    !props.active &&
    css`
       {
        i,
        a,
        span {
          color: ${colors.primColor};
        }
      }
    `}
  span,
  a {
    cursor: pointer;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: left;
    color: ${colors.primColor};
    padding: 1em;
    font-size: 16px;
    height: auto;
    text-decoration: none;
    min-height: 40px;
    width: 4rem;
  }
  i {
    font-size: 16px;
    margin-right: 10px;
    min-width: 20px;
  }

  ${(props) =>
    props.active &&
    css`
      i,
      a {
        color: ${colors.primColor};
        background-color: ${colors.light};
      }
    `}
`;

export const Text = styled.div`
  margin-left: 1em;
  display: ${(props) => (props.show ? "block" : "none")};
`;


export const List = styled.ul`
  align-self: ${(props) => (props.end ? `end` : `start`)};
  font-size: 16px;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: relative;
`;
