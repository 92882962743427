import React from "react";
import SelectCell from "./SelectCell";

const MarginBlock = ({ margins, offset = 97, updateMargin }) => {
  const reverse = offset === 63;
  const values = margins?.map((p) =>
    p ? (reverse ? -(p[1] - offset) / 6 : (p[1] - offset) / 6) : 0
  );
  return (
    <SelectCell
      values={values}
      updateCell={(val, ui) => {
        const m = margins.find((_, i) => i === ui);
        updateMargin([m[0], val * 6 + m[1]]);
      }}
    />
  );
};

export default MarginBlock;
