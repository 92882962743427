import React, { useContext, useEffect, useState, useMemo } from "react";
import { PreviewContainer } from "components/common";

import AppointmentForm from "components/calendar/components/AppointmentForm";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";
import {
  addMinutes,
  getHours,
  getMinutes,
  isBefore,
  setHours,
  setMinutes,
  startOfHour,
} from "date-fns";
import { AppContext } from "hooks/context";
import {
  createAppointment,
  updatePatientAppointment,
} from "actions/appointments";
import { ADMIN_NS } from "config";
import { updateItem } from "library/resources";
import { Close, HighlightOff } from "@mui/icons-material";
import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const transformEditorData = ({
  practitionerId,
  patientId,
  categoryId,
  start,
  end,
  meta,
  sendSms = false,
  sendMail = false,
  reminder,
}) => {
  return {
    PractitionerId: {
      val: practitionerId,
    },
    PatientId: {
      val: patientId,
    },
    CategoryId: {
      val: categoryId,
    },
    Start: { val: start },
    End: { val: end },
    Meta: {
      val: meta,
    },
    SendSms: {
      val: sendSms,
    },
    SendMail: {
      val: sendMail,
    },
    Reminder: {
      val: reminder || null,
    },
  };
};

export default function CreateAppointment({
  show,
  handleClose,
  formProps,
  appointmentData,
}) {
  const { state, dispatch } = useContext(AppContext);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);
  const [prePop, setPrePop] = useState({
    Date: new Date(),
    Start: startOfHour(new Date()),
    End: addMinutes(startOfHour(new Date()), 30),
    PatientId: state.patient?.data?.PatientId,
    PractitionerId: state.list?.data[0]?.data?.PractitionerId,
    CategoryId: state.categories?.data[0]?.data?.CategoryId,
  });

  useEffect(() => {
    formProps &&
      setPrePop({
        ...prePop,
        PatientId: state.patient?.data.PatientId,
        PractitionerId: state.list?.data[0]?.data?.PractitionerId,
        CategoryId: state.categories?.data[0]?.data?.CategoryId,
        ...formProps,
      });
  }, [formProps]);

  async function handleAppointment(rawData, close, uuid = null) {
    setError(null);

    if (!rawData.patientId) {
      setError("you must select a patient");
      return null;
    }

    if (!rawData.practitionerId) {
      setError("you must select a practitioner");
      return null;
    }

    const start = setMinutes(
      setHours(Date.parse(rawData.start), getHours(rawData.startTime)),
      getMinutes(rawData.startTime)
    );

    const end = addMinutes(start, rawData.hours * 60 + rawData.minutes);

    setUpdating(true);
    const data = transformEditorData({ ...rawData, start, end });
    let appointments = null;

    if (uuid) {
      const response = await updatePatientAppointment(ADMIN_NS, data, uuid);
      close && handleClose();
      if (response?.code === "SUCCESS")
        if (state.patient) {
          appointments = state.patient.data.Appointments.map((app) => {
            if (app.AppointmentId === response.data.AppointmentId) {
              return {
                ...app,
                ...response.data,
              };
            }
            return app;
          });
        }
    } else {
      const response = await createAppointment(ADMIN_NS, data);
      close && handleClose();
      if (response?.code === "SUCCESS")
        if (state.patient)
          appointments = [...state.patient.data.Appointments, response.data];
    }

    setError(null);
    close && handleClose();
    setUpdating(false);
    if (state.patient)
      await updateItem("patient", {
        ...state.patient.data,
        Appointments: appointments,
      })(dispatch);
  }

  const backgroundColor = useMemo(() => {
    if (!!prePop?.Meta?.Recall || !!appointmentData?.Meta?.Recall) {
      return colors.veryLightOrange;
    }
    return colors.vertLightPrimary;
  }, [!!prePop?.Meta, !!appointmentData?.Meta]);

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      fullScreen={true}
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          background: backgroundColor,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <IconButton aria-label="close" onClick={handleClose} size="large">
            <Close />
          </IconButton>
        </div>

        <PreviewContainer>
          {!appointmentData?.AppointmentId && prePop && (
            <AppointmentForm
              error={error}
              prePop={prePop}
              updateAppointment={(data, close) => {
                handleAppointment(data, close);
              }}
              cancelMethod={() => {
                handleClose();
              }}
              
            />
          )}
          {appointmentData && (
            <AppointmentForm
              error={error}
              prePop={undefined}
              id={appointmentData ? appointmentData.AppointmentId : null}
              appointmentData={appointmentData}
              updateAppointment={(data, close) => {
                handleAppointment(data, close, appointmentData?.AppointmentId);
              }}
              cancelMethod={() => {
                handleClose();
              }}
              
            />
          )}
        </PreviewContainer>
        {updating && (
          <div style={{ position: "absolute", margin: "50%", zIndex: "100" }}>
            <DoubleBounce color={colors.primary} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}
