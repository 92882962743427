import React from "react";
import { Box, Typography } from "@mui/material";
import { WrapMessage, Message } from "components/sms-client/styles";
import colors from "library/styled-components/colors";

const recallSms = `According to our dental records you are now due your dental checkup.\nPlease call 021-4821133 to arrange your next appointment.\nGlanmire Dental Practice.`;

const GenerateRecallStep = ({ sendDate, patientTotal }) => {
  return (
    <div
      style={{
        display: "grid",
        paddingTop: "1rem",
      }}
    >
      <Typography variant="body2">
        The sms message below will be sent to{" "}
        <strong>{patientTotal} </strong>patients on{" "}
        <strong>
          {sendDate.toDateString()} at {sendDate.toLocaleTimeString()}
        </strong>
      </Typography>

      <Box margin={2}>
        <WrapMessage
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: colors.lightGrey,
            padding: "1rem",
            borderRadius: "4px",
            whiteSpace: "pre-line",
          }}
        >
          <Message type={1}>{recallSms}</Message>
        </WrapMessage>
      </Box>
      <Typography variant="body2">
        To continue, click the generate recall button below.
      </Typography>
    </div>
  );
};

export default GenerateRecallStep;
