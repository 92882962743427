import store from "store";
import {
  registerCustomer,
  loginCustomer,
  validatePin,
  resetPassword,
  updatePassword,
} from "../../../../actions/register";

import { PUBLIC_NS } from "../../../../config";

export const setToken = (token) => async (dispatch) => {
  store.set(PUBLIC_NS, {
    user: {
      meta: { updating: false },
      data: {
        token,
      },
    },
  });
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: false },
    data: { token },
  });
};

export const registerUser = (user, practiceId) => async (
  dispatch,
  navigateTo
) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });

  const userData = {
    user,
    PracticeId: practiceId,
  };

  const response = await registerCustomer(userData);

  if (response.code == "REGISTERED") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
      data: { ...userData.user, token: response.token },
    });

    store.set(PUBLIC_NS, {
      user: {
        meta: { updating: false },
        data: {
          ...response,
          Email: user.Email.val,
        },
      },
    });

    navigateTo("pincode");
  }

  if (response.code == "ALREADY_REGISTERED") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "User email already registered!" },
    });
  }
};

export const loginUser = (user, schedule, date, practiceId) => async (
  dispatch,
  navigateTo
) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });

  const response = await loginCustomer(user);

  if (response && response.code == "SUCCESS") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
      data: {
        Email: user.Email.val,
        token: response.token,
        active: response.active,
      },
    });

    store.set(PUBLIC_NS, {
      user: {
        meta: { updating: false },
        data: {
          ...response,
          Email: user.Email.val,
        },
      },
    });

    if (!response.active) navigateTo("pincode");
    if (response.active) navigateTo("/public/home/list", true);
  } else {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, error: "Incorrect credentials" },
    });
  }
};

export const sendPinCode = (code) => async (dispatch, navigateTo, callback) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await validatePin(PUBLIC_NS, code);

  if (response && response.code == "VALID") navigateTo("/public/home/list", true);
  if (response && response.code == "INVALID_PIN") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Invalid Pin, please retry" },
    });
    callback();
  }
  if (!response) {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, error: "You are unauthorized" },
    });
    callback();
  }
};

export const sendResetPassword = (email) => async (dispatch, navigateTo) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await resetPassword(PUBLIC_NS, email);
  if (response && response.code == "VALID") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
    });
    navigateTo("reset-sent");
  }
  if (response && response.code == "INVALID_EMAIL") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Invalid Email, please retry" },
    });
  }
};

export const sendUpdatePassword = (password) => async (
  dispatch,
  navigateTo
) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await updatePassword(PUBLIC_NS, password);
  if (response && response.code == "VALID") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
    });
    navigateTo("pincode");
  }
  if (response && ["INVALID", "NOAUTH"].includes(response.code)) {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Reset Denied" },
    });
  }
};
