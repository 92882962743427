import React, { useReducer, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LoginLayout from "../../../layouts/login";
import { reducer, initialState } from "./reducer";
import colors from "../../../library/styled-components/colors";
import LoginBlock from "../../../components/blocks/login-block";
import ResetBlock from "../../../components/blocks/reset-block";
import PinCodeBlock from "../../../components/blocks/pin-code-block";
import UpdatePasswordBlock from "../../../components/blocks/update-password-block";
import {
  loginUser,
  sendPinCode,
  sendResetPassword,
  sendUpdatePassword,
  setToken,
} from "./actions";

import loginPng from "../../../img/login.png";
import UpdatePasswordTermsBlock from "../../../components/blocks/update-password-terms-block";

const Container = styled.div`
  @media (min-width: 481px) {
    width: 481px;
  }
  @media (max-width: 481px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em 1em 0em 1em;
`;

const Body = styled.div`
  display: grid;
  grid-template-rows: auto 8em 2em;
  justify-items: center;
  align-items: center;
  font-size: 1.6rem;
  text-align: center;
  line-height: 2.2rem;
`;

export default function LoginPublic({ match }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [resetPin, setResetPin] = useState(false);
  const [slideState, setSlideState] = useState({
    current: match.params.step,
    in: match.params.step,
    out: null,
  });

  const history = useHistory();

  useEffect(() => {
    handleRoute();
  }, []);

  async function handleRoute() {
    if (
      match.params &&
      ["pincode", "reset-token", "update-password"].includes(match.params.step)
    ) {
      if (match.params.id) setToken(match.params.id)(dispatch);
      else navigateTo("/public/access/login", "login");
    }
  }

  async function navigateTo(step, abs = false) {
    const out = match.params.step;
    history.push({
      pathname: abs ? step : `./${step}`,
      state: { out },
    });
    setSlideState({
      current: step,
      out,
      in: step,
    });
    window.scrollTo(0, 0);
  }

  history.listen((location) => {
    const { pathname, state } = location;
    const step = pathname.split("/").pop();
    setSlideState({
      current: step,
      out: state && state.out,
      in: step,
    });
  });

  const { schedules, date, slot, user } = state;
  return (
    <LoginLayout
      menu={true}
      meta={schedules.meta}
      user={user}
      slideState={slideState}
    >
      {slideState.current === "login" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Login</strong> to your Dentiz to view your and manage
                your dental appointments.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <LoginBlock
            loginUser={(user) => loginUser(user)(dispatch, navigateTo)}
            goToReset={() => navigateTo("reset")}
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "login",
              out: slideState.out === "login",
              in: slideState.in === "login",
            }}
          />
        </Container>
      )}

      {slideState.current === "reset" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Reset your password</strong>, we will send reset
                instruction to you email.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>

          <ResetBlock
            goToLogin={() => navigateTo("login")}
            resetPassword={(email) =>
              sendResetPassword(email)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}

      {slideState.current === "reset-sent" && (
        <Container>
          <Header>
            <div>
              <p>
                Password Reset Request Successful. Please Check your email to
                continue.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
        </Container>
      )}

      {slideState.current === "reset-token" && (
        <Container>
          <Header>
            <div>
              <p>
                A request to reset your password has been send, Please enter
                your new password here
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <UpdatePasswordBlock
            resetPassword={(password) =>
              sendUpdatePassword(password)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}

      {slideState.current === "update-password" && (
        <Container>
          <Header>
            <div>
              <p>Welcome to Dentiz.com</p>
              <p>Please create your password and accept terms to continue!</p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <UpdatePasswordTermsBlock
            resetPassword={(password) =>
              sendUpdatePassword(password)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}

      {slideState.current === "pincode" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Pincode</strong> Please enter the pin code sent to your
                email to activate your account
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <PinCodeBlock
            colors={colors}
            reset={resetPin}
            meta={state.user.meta}
            sendPinCode={(code) => {
              const callback = () => {
                setTimeout(() => {
                  dispatch({
                    type: "UPDATE",
                    context: "user",
                    meta: {
                      updating: false,
                      warning: null,
                      error: null,
                    },
                  });
                  setResetPin(!resetPin);
                }, 1000);
              };
              sendPinCode(code)(dispatch, navigateTo, callback);
            }}
          />
        </Container>
      )}
    </LoginLayout>
  );
}
