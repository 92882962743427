import { handleResponse, authHeader } from "../../library/handler";

export const fetchLogs = async (state, start, end, offset, limit, type) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/logs/${start}/${end}/${offset}/${limit}/${type}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
