import { handleResponse, authHeader } from "../../library/handler";

export async function getAppointmentById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/id/${uuid}`)
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getAppointments = async (state, from, to, limit, offset) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/load/${from}/${to}/${limit}/${offset}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getAppointmentEvents = async (state, from, to) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/events/${from}/${to}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getAppointmentsPaginate = async (state, from, to) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/paginate/${from}/${to}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getAppointmentsBySearchStr = async (state, string, start, end) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/search/${
      string || " "
    }/${start}/${end}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getAppointmentsByPatientId = async (state, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/patient/${uuid}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createAppointment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createCancelation = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/cancel`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const undoCancelation = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/cancel/undo`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createPatientCancelation = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/patient/cancel`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export async function unDoPatientCancelation(state, data) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/patient/cancel/undo`,
    {
      method: "post",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function checkAppointmentAvailability(state, data) {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/check`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const updateAppointment = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/update/${uuid}`, {
    method: "put",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updatePatientAppointment = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/patient/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const sendReminderPatientAppointment = async (state, data) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/patient/reminder`,
    {
      method: "post",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateAppointmentEvent = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/appointments/event/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createAppointmentEvent = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/event/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteAppointmentEvent = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/event/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteAppointment = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const addProceedure = async (state, data, appointmentId, practiceId) => {
  return fetch(`${process.env.REACT_APP_APP_API}/proceedures/create`, {
    method: "post",
    body: JSON.stringify({
      data,
      PracticeId: practiceId,
      AppointmentId: appointmentId,
    }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateProceedure = async (
  state,
  data,
  uuid,
  appointmentId,
  practiceId
) => {
  return fetch(`${process.env.REACT_APP_APP_API}/proceedures/update/${uuid}`, {
    method: "put",
    body: JSON.stringify({
      data,
      PracticeId: practiceId,
      AppointmentId: appointmentId,
    }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const removeProceedures = async (
  state,
  data,
  appointmentId,
  practiceId
) => {
  return fetch(`${process.env.REACT_APP_APP_API}/proceedures`, {
    method: "delete",
    body: JSON.stringify({
      data,
      PracticeId: practiceId,
      AppointmentId: appointmentId,
    }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
