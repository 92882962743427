import styled from "styled-components";
import colors from "./colors";

export const btn = styled.button`
  padding: 8px 20px;
  outline: none;
  text-decoration: none;
  font-size: 16px;
  letter-spacing: 0.5px;
  transition: all 0.3s;
  font-weight: 600;
  border-radius: 3px;
  &:focus {
    box-shadow: none !important;
  }
`;

export const btnPrimary = styled(btn)`
  background-color: ${colors.primary};
  border: 1px solid ${colors.primary};
  color: ${colors.white};
  &:disabled {
    background-color: ${colors.light};
    border: 1px solid ${colors.rgbGrey};
    color: ${colors.rgbGrey};
    cursor: not-allowed;
  }
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus {
    background-color:  ${colors.primary};
    border-color: ${colors.primary};
    color: ${colors.white};
    filter: brightness(110%);
    &:disabled {
      background-color: ${colors.light};
      border: 1px solid ${colors.rgbGrey};
      color: ${colors.rgbGrey}; 
      filter: brightness(100%);
    }
  }
`;


export const btnWarning = styled(btn)`
  background-color: ${colors.warning};
  border: 1px solid ${colors.warning};
  color: ${colors.white};
  &:disabled {
    background-color: ${colors.light};
    border: 1px solid ${colors.rgbGrey};
    color: ${colors.rgbGrey};
    cursor: not-allowed;
  }
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus {
    background-color:  ${colors.warning};
    border-color: ${colors.warning};
    color: ${colors.white};
    filter: brightness(110%);
    &:disabled {
      background-color: ${colors.light};
      border: 1px solid ${colors.rgbGrey};
      color: ${colors.rgbGrey}; 
      filter: brightness(100%);
    }
  }
`;

export const btnDanger = styled(btn)`
  background-color: ${colors.danger};
  border: 1px solid ${colors.danger};
  color: ${colors.white};
  &:disabled {
    background-color: ${colors.light};
    border: 1px solid ${colors.rgbGrey};
    color: ${colors.rgbGrey};
    cursor: not-allowed;
  }
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus {
    background-color:  ${colors.danger};
    border-color: ${colors.danger};
    color: ${colors.white};
    filter: brightness(110%);
    &:disabled {
      background-color: ${colors.light};
      border: 1px solid ${colors.rgbGrey};
      color: ${colors.rgbGrey}; 
      filter: brightness(100%);
    }
  }
`;


export const btnSecondary = styled(btn)`
  background-color: ${colors.secondary} !important;
  border: 1px solid ${colors.secondary} !important;
  color: ${colors.white} !important;
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus {
    background-color:  ${colors.secondary};
    border-color: ${colors.secondary};
    color: ${colors.white} !important;
    filter: brightness(110%);
  }
`;

export const btnPrimaryInvert = styled(btn)`
  background-color: ${colors.white} !important;
  border: 1px solid ${colors.primary} !important;
  color: ${colors.primary} !important;
  box-shadow: 0 3px 5px 0 rgba($value, 0.3);
  &:hover,
  &:focus,
  &:active,
  &.active,
  &.focus {
    background-color: ${colors.white} !important;
    border-color: ${colors.altColor} !important;
    color: ${colors.altColor} !important;
    filter: brightness(110%);
  }
`;
