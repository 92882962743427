import { handleResponse, authHeader } from '../../library/handler'

export const fetchIncomeReport = async (
  state,
  start,
  end,
  selectedMethods,
  format = 'json'
) => {
  // Append the format query string based on the desired output format
  const url = `${process.env.REACT_APP_APP_API}/reports/${start}/${end}/${selectedMethods}?format=${format}`

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: await authHeader(state)
    })

    if (format === 'excel') {
      // If the format is 'excel', handle the response differently
      // Assuming the server sends a file
      if (!response.ok) throw new Error('Failed to download Excel file')

      // Extract file name from content-disposition header or default to a generic name
      const disposition = response.headers.get('Content-Disposition')
      const filename = disposition
        ? disposition.split('filename=')[1].replace(/"/g, '')
        : 'report.xlsx'

      // Create a Blob from the response data and download it
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    }

    // Process JSON response
    return handleResponse(response)
  } catch (err) {
    console.log('Fetch Error :-S', err)
    throw err // Rethrow to allow the calling function to handle the error
  }
}

export const fetchPatientIncomeReport = async (
  state,
  start,
  end,
  practitionerId = null,
  selectedMethods,
  format = 'json'
) => {
  // Append the format query string based on the desired output format
  const url = `${process.env.REACT_APP_APP_API}/reports/${start}/${end}/${practitionerId}/${selectedMethods}?format=${format}`

  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: await authHeader(state)
    })

    if (format === 'excel') {
      // If the format is 'excel', handle the response differently
      // Assuming the server sends a file
      if (!response.ok) throw new Error('Failed to download Excel file')

      // Extract file name from content-disposition header or default to a generic name
      const disposition = response.headers.get('Content-Disposition')
      const filename = disposition
        ? disposition.split('filename=')[1].replace(/"/g, '')
        : 'report.xlsx'

      // Create a Blob from the response data and download it
      const blob = await response.blob()
      const downloadUrl = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = downloadUrl
      a.download = filename
      document.body.appendChild(a)
      a.click()
      a.remove()
    } else {
      // Process JSON response
      return handleResponse(response)
    }
  } catch (err) {
    console.log('Fetch Error :-S', err)
    throw err // Rethrow to allow the calling function to handle the error
  }
}
