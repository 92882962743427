import {
  handleResponse,
  authHeader
} from "../../library/handler";

export async function getCategoryById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/categories/id/${uuid}`)
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
}

export const getCategories = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/categories`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const createCategory= async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/categories/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const updateCategory = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/categories/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state)
    }
  )
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};


export const deleteCategory = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/categories/${uuid}`, {
    method: "delete",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
}; 