import React, { useState } from "react";
import styled from "styled-components";
import { ModalBox } from "../../../common";

const Wrap = styled.div`
  display: inline-flex;
  min-width: auto;
  position: relative !important;
`;

const Circle = styled.div`
  //cursor: pointer;
  font-size: 12px;
  width: 15px;
  text-align: center;
`;

const CircleInput = styled.input`
  width: 26px;
  position: absolute;
  z-index: 104;
`;

const Input = ({ value, updateCell }) => {
  const [inputState, setInputState] = useState(false);
  const [diff, setDiff] = useState(value);

  return (
    <div>
      {!!inputState && (
        <CircleInput
          onChange={(e) => {
            setDiff(e.target.value);
          }}
          type="number"
          step="1"
          value={diff}
        />
      )}
      <Circle
        onClick={() => {
          //setInputState(true);
        }}
      >
        {value}
      </Circle>
      <ModalBox
        show={inputState}
        onClick={() => {
          setInputState(false);
          diff && updateCell(diff - value);
        }}
      ></ModalBox>
    </div>
  );
};

const SelectCell = ({ values, updateCell }) => {
  return (
    <Wrap>
      {values?.map((v, i) => (
        <Input value={v} updateCell={(val) => updateCell(val, i)} />
      ))}
    </Wrap>
  );
};

export default SelectCell;
