const colors = {
  primColor: "#3A7DE1",
  darkBlue: "#3880ff",
  secColor: "#ffffff",
  accentColor: "#17a2b8",
  accentLight: "rgb(178 244 254)",
  altColor: "#17a2b8",
  altLightColor: "#A3B18A",
  softGreen: "rgb(73, 226, 190)",
  green: "#0e7a01",
  softRed: "rgb(238,105,105)",
  red: "#9f0404",
  txtColor: "#ffffff",
  errorColor: "#b91f1f",
  borderColor: "#b4b4b4",
  disableColor: "#c4c4c4",
  disableLightColor: "#e4e4e4",
  primary: "#3A7DE1",
  primLight: "#f5f6fc",
  secondary: "#5a6d90",
  success: "#2eca8b",
  warning: "#f17425",
  info: "#17a2b8",
  veryDark: "#1f2002",
  danger: "#e43f52",
  dark: "#3c4858",
  black: "#161c2d",
  muted: "#8492a6",
  light: "#f8f9fc",
  white: "#ffffff",
  grey: "#888",
  veryLightOrange: "#ffede2",
  vertLightPrimary: "rgb(222, 236, 255)",
  lightGrey: "#E8E8E8",
  mediumGrey: "#eeeeee",
  rgbGreyDark: "rgba(60, 72, 88, 1)",
  rgbGrey: "rgba(60, 72, 88, 0.7)",
  rgbGreyLight: "rgba(60, 72, 88, 0.2)",
  rgbInfo: "rgba(23, 162, 184, 0.9);",
  rgbInfoLight: "rgba(23, 162, 184, 0.2);",
  themebyId: ["#2d5b6b", "#c47a53", "#8f4731", "#75C7EA", "#52494c", "#7b7d2a"],
  cancelled: "#FF6B6B",
  lightBlue: "#CFEEFA",
  lightPurple: "#F2E9F7",
  yellow: "#f1e5bc",
  veryLightYellow: "#fff9e6", // rgb(241, 229, 188)
};

export default colors;
