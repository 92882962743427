import store from "store";
import dayjs from "dayjs";
import {
  getSlots,
  bookSlot,
  resetPassword,
  updatePassword,
  cancelSlot,
} from "../../../../actions/public";
import { registerCustomer, loginCustomer } from "../../../../actions/register";
import { getPublicPractice } from "../../../../actions/practices";
import { getPractitioners, getPublicPractitioners } from "../../../../actions/practitioners";
import { PUBLIC_NS } from "../../../../config";
import { getList } from "library/resources";

const getPractitionerList = async (uuid) => await getPublicPractitioners(uuid);

export const fetchSlots = (date, practiceId) => async (dispatch) => {
  const slots = await getSlots(practiceId, {
    date: {
      start: date.data.format("YYYY-MM-DD"),
      end: date.data.clone().add(7, "day").format("YYYY-MM-DD"),
      // day: date.data.clone().add(1, "day").isoWeekday(),
    },
  });

  dispatch({
    type: "LOAD",
    context: "schedules",
    data: slots && slots.data ? slots.data : [],
    updating: false,
  });
};

export const fetchNewWeekSlots = (date, practiceId) => async (dispatch) => {
  const slots = await getSlots(practiceId, {
    date: {
      start: date.data.format("YYYY-MM-DD"),
      end: date.data.clone().add(7, "day").format("YYYY-MM-DD"),
      // day: date.data.clone().add(1, "day").isoWeekday(),
    },
  });

  dispatch({
    type: "LOAD",
    context: "schedules",
    data: slots && slots.data ? slots.data : [],
    updating: false,
  });

  const slotDate =
    slots && slots.data.length > 0 && dayjs().isoWeekday(slots.data[0].Day);

  const firstdate =
    slotDate && slotDate < date.data ? slotDate.add(1, "week") : date.data;

  dispatch({
    type: "REPLACE",
    context: "date",
    data: firstdate,
    updating: false,
  });

  dispatch({
    type: "REPLACE",
    context: "firstdate",
    data: firstdate.clone(),
    updating: false,
  });
};

export const fetchData = (now, practiceId) => async (dispatch) => {
  const practice = await getPublicPractice(practiceId);

  dispatch({
    type: "LOAD",
    context: "practices",
    data: [practice],
    updating: false,
  });

  const slots = await getSlots(practiceId, {
    date: {
      start: now.format("YYYY-MM-DD"),
      end: now.clone().add(7, "days").format("YYYY-MM-DD"),
    },
  });

  dispatch({
    type: "LOAD",
    context: "schedules",
    data: slots && slots.data ? slots.data : [],
    updating: false,
  });

  const date =
    slots && slots.data.length > 0 && dayjs().isoWeekday(slots.data[0].Day);

  const firstdate = date && date < dayjs() ? date.add(1, "week") : dayjs();

  dispatch({
    type: "REPLACE",
    context: "date",
    data: firstdate,
    updating: false,
  });

  dispatch({
    type: "REPLACE",
    context: "firstdate",
    data: firstdate.clone(),
    updating: false,
  });

  await getList("list", getPractitioners)(dispatch); 
};

export const selectSlot = (uuid) => async (dispatch) => {
  dispatch({
    type: "UPDATE",
    context: "slot",
    data: uuid,
  });
};

export const changeDate = (newDate) => async (dispatch) => {
  dispatch({
    type: "REPLACE",
    context: "date",
    data: newDate,
    updating: false,
  });
  dispatch({
    type: "REPLACE",
    context: "slot",
    data: null,
  });
};

export const cancelBooking = (user, appointment) => async (dispatch) => {
  if (user && user.data) {
    const {
      data: { token },
    } = user;
    if (token && appointment.Active) {
      const data = await cancelSlot(token, appointment);
      data &&
        data.code === "SUCCESS" &&
        (dispatch({
          type: "REMOVE_ITEM",
          context: "appointments",
          uuid: { AppointmentId: appointment.AppointmentId },
          updating: false,
        }));
    }
  }
  return null;
};

const handleBooking =
  ({ token, practiceId, appointment, code, replace }) =>
  async (dispatch, navigateTo, callback) => {
    let cancel = null;
    if (replace) {
      cancel = await cancelSlot(token, replace);
      cancel &&
        cancel.code === "SUCCESS" &&
        (dispatch({
          type: "REMOVE_ITEM",
          context: "appointments",
          uuid: { AppointmentId: replace.AppointmentId },
          updating: false,
        }));
    }

    const response = await bookSlot(token, {
      appointment,
      PracticeId: practiceId,
      code,
    });
    if (response.code == "BOOKED_SUCCESS") {
      dispatch({
        type: "ADD",
        context: "appointments",
        data: response && response.data,
        updating: false,
      });

      dispatch({
        type: "REPLACE",
        context: "appointment",
        data: response && response.data,
        updating: false,
      });

      if (cancel && cancel.code == "SUCCESS") {
        navigateTo("rebooked");
      } else {
        navigateTo("booked");
      }
    }

    if (response.code == "INVALID_PIN") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, warning: "Invalid Pin, please retry" },
      });
      callback && callback();
    }

    if (response.code == "BOOKED_FAIL") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: {
          updating: false,
          error: "Something went wrong, please try again",
        },
      });
      callback && callback();
    }
  };

const appointmentObject = (
  { PractitionerId, ScheduleId, Start, End },
  date
) => ({
  PractitionerId,
  ScheduleId,
  Start: `${date.data.format("YYYY-MM-DD")} ${Start}`,
  End: `${date.data.format("YYYY-MM-DD")} ${End}`,
});

export const checkBooking =
  (user, schedule, date, practiceId, replaceAppointment) =>
  (dispatch, navigateTo) => {
    if (user && user.data) {
      const {
        data: { token, active },
      } = user;

      if (token)
        handleBooking({
          token,
          practiceId,
          appointment: appointmentObject(schedule, date),
          replace: replaceAppointment,
        })(dispatch, navigateTo);
      else if (active) navigateTo("pincode");
    } else navigateTo("register");
  };

export const registerUser =
  (user, practiceId) => async (dispatch, navigateTo) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: true },
    });

    const userData = {
      user,
      PracticeId: practiceId,
    };

    const response = await registerCustomer(userData);

    if (response.code === "REGISTERED") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false },
        data: { ...userData.user, token: response.token, active: true },
      });

      store.set(PUBLIC_NS, {
        user: {
          meta: { updating: false },
          data: {
            ...response,
            active: true,
            Email: user.Email.val,
          },
        },
      });

      navigateTo("pincode");
    }

    if (response.code == "ALREADY_REGISTERED") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, warning: "User email already registered!" },
      });
    }

    if (response.code == "INVALID_PHONE") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: {
          updating: false,
          warning: "Phone number is invalid or unsupported!",
        },
      });
    }

    if (response.code == "INVALID") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: {
          updating: false,
          warning: "Data is invalid or unsupported!",
        },
      });
    }
  };

export const loginUser =
  (user, schedule, date, practiceId) => async (dispatch, navigateTo) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: true },
    });

    const response = await loginCustomer(user);

    if (response && response.code == "SUCCESS") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false },
        data: {
          Email: user.Email.val,
          token: response.token,
          active: response.active,
        },
      });

      store.set(PUBLIC_NS, {
        user: {
          meta: { updating: false },
          data: {
            ...response,
            Email: user.Email.val,
          },
        },
      });

      if (!response.active) navigateTo("pincode");

      if (response.active)
        await handleBooking({
          token: response.token,
          practiceId,
          appointment: appointmentObject(schedule, date),
        })(dispatch, navigateTo);
    } else {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, error: "Incorrect credentials" },
      });
    }
  };

export const sendPinCode =
  (user, schedule, date, practiceId, code) =>
  (dispatch, navigateTo, callback) => {
    if (!user.data) {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, error: "Invalid" },
      });
      callback();
    } else {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: true },
      });
      handleBooking({
        token: user.data.token,
        practiceId,
        appointment: appointmentObject(schedule, date),
        code,
      })(dispatch, navigateTo, callback);
    }
  };

export const sendResetPassword = (email) => async (dispatch, navigateTo) => {
  dispatch({
    type: "UPDATE",
    context: "user",
    meta: { updating: true },
  });
  const response = await resetPassword(PUBLIC_NS, email);
  if (response && response.code == "VALID") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false },
    });
    navigateTo("reset-sent");
  }
  if (response && response.code == "INVALID_EMAIL") {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: "Invalid Email, please retry" },
    });
  }
};

export const sendUpdatePassword =
  (password) => async (dispatch, navigateTo) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: true },
    });
    const response = await updatePassword(PUBLIC_NS, password);
    if (response && response.code == "VALID") {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false },
      });
      navigateTo("pincode");
    }
    if (response && ["INVALID", "NOAUTH"].includes(response.code)) {
      dispatch({
        type: "UPDATE",
        context: "user",
        meta: { updating: false, warning: "Reset Denied" },
      });
    }
  };
