import React from "react";
import styled from "styled-components";
import { Comment, Event, Block, AllInclusive } from "@mui/icons-material";
import colors from "library/styled-components/colors";
import { Button, Dialog, DialogContent, Slide } from "@mui/material";

const Container = styled.div`
  display: grid;
  font-weight: normal;
  padding: 1rem;
`;

const BlockWrap = styled.div`
  display: grid;
`;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DateClickModal = ({
  close,
  open,
  setComment,
  setAppointment,
  setRecall,
  setBlock,
}) => {
  return (
    <Dialog open={open || false} onClose={close} TransitionComponent={Transition}>
      <DialogContent>
        <Container>
          <BlockWrap
            style={{
              gridGap: "0.5rem",
            }}
          >
            <Button
              onClick={setAppointment}
              variant="contained"
              color="primary"
              startIcon={<Event />}
              style={{ background: colors.primColor }}
            >
              Create Appointment
            </Button>
            <Button
              variant="contained"
              style={{
                background: colors.warning,
                color: colors.white,
                borderColor: colors.warning,
              }}
              startIcon={<AllInclusive />}
              onClick={() => {
                setRecall(true);
              }}
            >
              Create Recall
            </Button>
            <Button
              onClick={setBlock}
              variant="contained"
              color="primary"
              startIcon={<Block />}
              style={{ background: colors.rgbGreyLight, color: colors.dark }}
            >
              Block Out
            </Button>
            <Button
              onClick={setComment}
              variant="contained"
              color="primary"
              startIcon={<Comment />}
              style={{ background: colors.yellow, color: colors.dark }}
            >
              {" "}
              Create Event
            </Button>
          </BlockWrap>
        </Container>
      </DialogContent>
    </Dialog>
  );
};

export default DateClickModal;
