import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <g id="top5" transform="translate(-8.644 112.432)">
      <g id="bounds">
        <line
          id="bound"
          x2="70"
          transform="translate(8.644 133.568)"
          fill="none"
        ></line>
        <line
          id="bound-2"
          data-name="bound"
          x2="70"
          transform="translate(8.644 -112.432)"
          fill="none"
        ></line>
      </g>
      <g id="surface" transform="translate(-656.526 -206.952)">
        <path
          id="shape"
          d="M30.141,39.921c-5.718,3.238-17.225,1.092-23.914.717,0,0-5.441-1.911-6.218-11.957C-.233,25.538,4.11,21.83,3.6,18.16,2.577,10.863,7.647,2.026,12.684.7,25.756-2.731,32.2,7.1,35.641,15.529c3.152,7.716-.225,18.31-5.5,24.392Z"
          transform="translate(722.735 290.318) rotate(92)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#707070"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M30.141,39.921c-5.718,3.238-13.436,1.434-20.125,1.06,0,0,.255-7.413,3-10.774,1.547-1.9,2.914,1.134,5.06,1.567s2.751-2.045,4.823-1.047,1.5,2.241,3.466,5.042c1.328,1.879,4.341,3.505,3.78,4.152Z"
          transform="translate(722.735 290.318) rotate(92)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M30.343,39.665a22.974,22.974,0,0,1-3.921-4,17.232,17.232,0,0,1-2.014-3.645c-.263-.634,1.232-3.577.906-5.634-.256-1.6-2.32-2.394-2.282-4.68.038-2.256,1.579-3.727,1.9-6.365.226-1.882-1.545-4.44-1.3-5.7.318-1.653,6.246-3.991,6.246-3.991s4.662,6.44,6.4,11.938a22.975,22.975,0,0,1,.643,5.115c.4,5.733-2.235,11.511-6.275,16.631-.035.048-.294.336-.294.336Z"
          transform="translate(722.735 290.318) rotate(92)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M10.01,40.94s-2.719-.242-3.783-.3c0,0-5.441-1.911-6.218-11.957C-.233,25.538,4.11,21.83,3.6,18.16A19.789,19.789,0,0,1,5.591,7.673c.291-.628.065,1.852,1.353,3.176,1.871,1.9,6.645,5.733,7.2,11.961.37,4.168-2.24,7.817-3.234,11.892a36.931,36.931,0,0,0-.9,6.238Z"
          transform="translate(722.735 290.318) rotate(92)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M6.19,6.573C7.778,3.766,10.235,1.346,12.684.7c7.265-1.908,12.481.279,16.667,4.46-.007-.01.467.522.467.522a51.509,51.509,0,0,0-4.666,2.26c-2.475,1.36-.842,2.835-4.562,4s-6.31.553-10.316.66C7.191,12.687,5.1,8.49,6.19,6.573Z"
          transform="translate(722.735 290.318) rotate(92)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M9.3,1.88s1.8,3.62,1.125,5.475-1.967,3-2.077,5.53,1.45,3.171,1.757,4.18c.307.992-.224,4.916-1,5.6-.322.274-1.456-1.609-2.532-1.609-.8.01-1.659,1.5-3.312,1.222-1.043-.175-2.052-1.2-3.129-1.731A2.692,2.692,0,0,0-1.96,20.51S.4,16.266-.22,12.01-4.6,3.354-4.6,3.354s7.569.551,9.115.383A19.87,19.87,0,0,0,9.3,1.88Z"
          transform="translate(712.959 304.026) rotate(89.026)"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown">
        <path
          id="main"
          d="M8.11,4.127C6.986,9.051-.43,18.593.02,30.288s-.337,11.8,11.237,15.7,8.652,4.822,14.158,2.77S45.079,38.9,43.056,26.7,36.764,10.18,36.089,4.127,9.235-.8,8.11,4.127Z"
          transform="translate(22)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root" transform="translate(22)">
        <path
          id="rb1"
          d="M1394.03,306.969s-8.513-88.83-19.21-87.846-8.608,88.087-8.608,88.087,1.616-2.192,7.127-2.979,11.093-.979,14.916-.172a11.552,11.552,0,0,1,5.775,2.91Z"
          transform="translate(-1358.081 -303.524)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r2"
          d="M1358.42,214.808c-2.3.277,2.291,9.875,3.291,22.486,1.619,20.428,1.3,44.815,1.3,44.815s-.133.6,3.04,0,3.04-1.414,3.04-1.414,1.4-5.534,0-17.57-1.716-18.6-3.985-30.714S1359.29,214.7,1358.42,214.808Z"
          transform="translate(-1340.323 -289.213)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M1366.14,229.312c-6.158.744-2.883,55.07-2.883,55.07a19.31,19.31,0,0,0,4.238-.506c3.173-.6,3.1-.707,3.1-.707s-.52-6.06-3.1-19.9c-1.439-7.722-1.355-18.652-1.355-18.652S1367,229.208,1366.14,229.312Z"
          transform="translate(-1350.144 -287.213)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(12.644 -97.71)">
          <g id="abscess" transform="translate(-3 -12)">
            <g
              id="p1"
              transform="translate(21)"
              fill="none"
              stroke="#000"
              stroke-width="4"
            >
              <circle cx="10" cy="10" r="10" stroke="none"></circle>
              <circle cx="10" cy="10" r="8" fill="none"></circle>
            </g>
          </g>
          <g id="diastema" transform="translate(51.667 132.504)">
            <path
              id="p2"
              d="M1178.17,630.863V578.089"
              transform="translate(-1178.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M1178.17,630.863V578.089"
              transform="translate(-1168.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="cracked" transform="translate(20.607 -0.436) rotate(11)">
            <path
              id="p1-3"
              data-name="p1"
              d="M1099.67,572.241l1.28-10.947,8.533,2.32,3.523-8.122"
              transform="translate(-1070.5 -433.112)"
              fill="none"
              stroke="#000"
              stroke-linecap="square"
              stroke-width="5"
            ></path>
          </g>
          <g id="recession">
            <path
              id="p3"
              d="M1116.44,696.006s6.063-6,17.753-6.062,17.985,6.063,17.985,6.063"
              transform="translate(-1102.5 -594.41)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
        </g>
      )}
      {props.proceedures && (
        <g id="procedures">
          <g id="hyg" transform="translate(15.144 1.876)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc">
            <path
              id="rctpc-2"
              data-name="rctpc"
              d="M587.2,20.687s2.174,25.424,2.566,27.6-9.706,2.056-9.951.685,2.659-28.161,2.659-28.161Z"
              transform="translate(-540.856 -20.799)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(22.095 -71.091)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="seal" transform="translate(33.907 90.979)">
            <path
              id="seal-2"
              data-name="seal"
              d="M720.533,535.828s20.229-2.566,20.229-6.089c0-1.867-6.778-3.731-12.178-4.932-4.52-1-7.709-1.331-8.052-3.07-.73-3.7,17.311-6.794,17.311-6.794"
              transform="translate(-720.511 -514.944)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="implant" transform="translate(27.508 -82.139)">
            <path
              id="p5"
              d="M806.184,381.864l-1.553,15.719s6.208,8.37,10.95,11.2,3.052,2.77,7.914,0,11.485-10.96,11.485-10.96l-.133-6.876Z"
              transform="translate(-803 -313.148)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.7,365.23l-1.823,10.682,27.12,8.6L832.449,373Z"
              transform="translate(-803.154 -312.004)"
              fill="red"
            ></path>
            <path
              id="p3-2"
              data-name="p3"
              d="M810.34,348.775l-1.51,10.675,23.281,7.444-1.27-11.536Z"
              transform="translate(-803.289 -310.871)"
              fill="red"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M812.1,332.484l-1.124,10.534,19.313,5.9-1.545-11.1Z"
              transform="translate(-803.437 -309.75)"
              fill="red"
            ></path>
            <path
              id="p1-4"
              data-name="p1"
              d="M828.272,331.155l-15.521-4.7s.492-19.875,8.041-18.857S828.272,331.155,828.272,331.155Z"
              transform="translate(-803.559 -308.035)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(12.144 -1.015)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H70"
              transform="translate(-3.5 24.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H70"
              transform="translate(-3.5 104.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(30.977 -40.287)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(24.391 -102.925)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-4"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-6"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
