import {
  handleResponse,
  authHeader,
  authOnlyHeader
} from "../../library/handler";

export async function getPractitionerById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/practitioners/id/${uuid}`)
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
}

export const getPractitioners = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/practitioners`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const getPublicPractitioners = async (uuid) => {
  return await fetch(`${process.env.REACT_APP_APP_API}/practitioners/${uuid}`, {
    method: "get",
  })
    .then(response => response.json())
    .catch(err => console.log("Fetch Error :-S", err));
};

export const createPractitioner = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/practitioners/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const updatePractitioner = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/practitioners/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state)
    }
  )
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const uploadProfileImage = async (data, state) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/practitioners/upload/profile`,
    {
      method: "post",
      headers: authOnlyHeader(state),
      body: data
    }
  )
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};


export const deletePractitioner = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/practitioners/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
