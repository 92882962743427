import React, { useState, useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { InfoMessage, WarningMessage } from "../../components/common";

import { AppContext } from "../../hooks/context";
import colors from "library/styled-components/colors";

export default function EventInfo() {
  const { app } = useContext(AppContext);
  const history = useHistory();
  const [newEvents, setNewEvents] = app.events;

  async function navigateTo(pathname) {
    history.push({
      pathname: pathname,
    });
  }

  return (
    <>
      {newEvents?.registered && (
        <InfoMessage
          fade={{ in: newEvents?.registered, out: !newEvents?.registered }}
          onMouseLeave={() =>
            setNewEvents({
              ...newEvents,
              registered: null,
            })
          }
        >
          <h3>New Patient Registration!</h3>
          <p style={{ borderTop: "1px solid white" }}>
            <strong>{newEvents.registered.Name}</strong> has registered
            {newEvents.registered.PractitionerName && (
              <strong>with {newEvents.registered.PractitionerName}</strong>
            )}
            {newEvents.registered.Time && (
              <strong>
                {" "}
                for {moment(newEvents.registered.Time).format("Hh:mm")}
              </strong>
            )}
          </p>
        </InfoMessage>
      )}

      {newEvents?.removed && (
        <WarningMessage
          fade={{ in: newEvents?.removed, out: !newEvents?.removed }}
          onMouseLeave={() =>
            setNewEvents({
              ...newEvents,
              removed: null,
            })
          }
        >
          <h3>Patient Removed!</h3>
        </WarningMessage>
      )}

      {newEvents?.arrived && (
        <InfoMessage
          style={{ backgroundColor: colors.rgbInfo }}
          fade={{ in: newEvents?.arrived, out: !newEvents?.arrived }}
          onMouseLeave={() =>
            setNewEvents({
              ...newEvents,
              arrived: null,
            })
          }
        >
          <h3>Patient Arrvived!</h3>
          <p style={{ borderTop: "1px solid white" }}>
            <strong>{newEvents.arrived.Name}</strong> has arrived
            {newEvents.arrived.PractitionerName && (
              <strong> to see {newEvents.arrived.PractitionerName}</strong>
            )}
            {newEvents.arrived.Time && (
              <strong>
                {" "}
                at {moment(newEvents.arrived.Time).format("Hh:mm")}
              </strong>
            )}
          </p>
        </InfoMessage>
      )}

      {newEvents?.inTreatment && (
        <InfoMessage
          style={{ backgroundColor: colors.secondary }}
          fade={{ in: newEvents?.inTreatment, out: !newEvents?.inTreatment }}
          onMouseLeave={() =>
            setNewEvents({
              ...newEvents,
              inTreatment: null,
            })
          }
        >
          <h3>Patient in Treatment!</h3>
          <p style={{ borderTop: "1px solid white" }}>
            <strong>{newEvents.inTreatment.Name}</strong> has started Treatment
            {newEvents.inTreatment.PractitionerName && (
              <strong> with {newEvents.inTreatment.PractitionerName}</strong>
            )}
            {newEvents.inTreatment.Time && (
              <strong>
                {" "}
                at {moment(newEvents.inTreatment.Time).format("Hh:mm")}
              </strong>
            )}
          </p>
        </InfoMessage>
      )}

      {newEvents?.appointment && (
        <InfoMessage
          fade={{ in: newEvents?.appointment, out: !newEvents?.appointment }}
          onMouseLeave={() =>
            setNewEvents({
              ...newEvents,
              appointment: null,
            })
          }
        >
          <h3>New Online Booking!</h3>
          <p style={{ borderTop: "1px solid white" }}>
            <strong>{newEvents.appointment.Patient.User.Name}</strong> has
            booked an appointment
            {newEvents.appointment.Practitioner.Name && (
              <strong>with {newEvents.appointment.Practitioner.Name}</strong>
            )}
            {newEvents.appointment.Start && (
              <strong>
                {" "}
                for {moment(newEvents.appointment.Start).format("Hh:mm")}
              </strong>
            )}
          </p>
        </InfoMessage>
      )}
    </>
  );
}
