import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="74.234"
      height="246"
      viewBox="0 0 74.234 246"
    >
      <g id="bottom5" transform="translate(-798.183 -498.367)">
        <g id="bounds">
          <line
            id="bound"
            x2="70"
            transform="translate(799.5 744.367)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="70"
            transform="translate(799.5 498.367)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(813.596 493.396) rotate(17)">
          <path
            id="shape"
            d="M26.238,40.3a22.178,22.178,0,0,0,.824-10.432c-.558-3.4-1.908-6.556-3.216-10.648a24.765,24.765,0,0,0-6.42-10.249,12.564,12.564,0,0,0-12-2.983A26.552,26.552,0,0,0-4.8,11.563c-3.529,2.852-7.232,5.132-8.947,8.468-1.74,3.352-1.534,7.752-1.611,12.415-.036,4.672-.378,9.623,1.771,12.967,2.132,3.319,6.773,5.054,11.159,7.252,4.4,2.223,8.592,4.916,12.485,4.651,3.9-.307,7.5-3.573,10.3-6.877A29.466,29.466,0,0,0,26.238,40.3Z"
            transform="translate(61.88 18.677) rotate(73.976)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="m"
            d="M-7.785,50.05c1.443.767,3.426,1.646,5.36,2.615,4.4,2.223,8.592,4.916,12.485,4.651,3.9-.307,7.5-3.573,10.3-6.877.771-.907,1.477-1.815,2.3-2.983.8-1.134-8.01-6.377-16.572-5.437C-1.221,42.821-8.337,49.757-7.785,50.05Z"
            transform="translate(61.88 18.677) rotate(73.976)"
            fill="#f5f5f5"
          ></path>
          <path
            id="b"
            d="M26.238,40.3a22.178,22.178,0,0,0,.824-10.432,52.286,52.286,0,0,0-3.748-12.079c-.223-.491-1.5-2.266-1.5-2.266s-1.67-.155-4.38,1.74a25.288,25.288,0,0,0-6.46,5.841c-1.493,2,.455,6.063.169,9.7s-.961,2.973-.835,5.295a12.416,12.416,0,0,0,.827,3.925,34.276,34.276,0,0,1,7.157,2.019c2.9,1.323,4.441,3.332,4.61,3.089A24.883,24.883,0,0,0,26.238,40.3Z"
            transform="translate(61.88 18.677) rotate(73.976)"
            fill="#f5f5f5"
          ></path>
          <path
            id="d"
            d="M21.735,15.461a26.49,26.49,0,0,0-4.31-6.488,12.564,12.564,0,0,0-12-2.983A26.552,26.552,0,0,0-4.8,11.563c-1.8,1.455-3.645,2.76-5.482,4.336-.584.5,21.584,6.733,21.584,6.733s3.6-4.108,6.584-5.617S21.735,15.461,21.735,15.461Z"
            transform="translate(61.88 18.677) rotate(73.976)"
            fill="#f5f5f5"
          ></path>
          <path
            id="l"
            d="M-10.4,15.926a12.972,12.972,0,0,0-3.339,4.1c-1.74,3.352-1.534,7.752-1.611,12.415-.036,4.672-.378,9.623,1.771,12.967,1.222,1.9,3.12,3.194,5.747,4.587.18.1,1.116-1.388,2.651-2.686.758-.634,3.667-2.646,3.667-2.646a4.91,4.91,0,0,1-.88-3.89c.3-2.267,2.171-5.965,2.01-8.592C-.57,29.135-2,25.868-2.711,22.6a16.657,16.657,0,0,1-.069-3.938l-6.391-2.151Z"
            transform="translate(61.88 18.677) rotate(73.976)"
            fill="#f5f5f5"
          ></path>
          <path
            id="o"
            d="M18.331,29.363c-.342.408,2.58,2.373,4.089,5.283a21.773,21.773,0,0,1,1.944,6.359c.453.486,3.852-1.9,3.852-1.9a25.648,25.648,0,0,0,5.879-.841c3-.823,3.811-1.8,6.11-2.451s3.022.953,3.061-.137-.01-14.305-.01-14.305-.537-.783-3.771.792-4.336,3.6-8.8,4.963-6.007-.035-9.043.491S18.673,28.955,18.331,29.363Z"
            fill="#eaeaea"
          ></path>
        </g>
        <g id="crown">
          <path
            id="crown-2"
            d="M1374.52,176.791s4.593-13.817,8.387-22.108,0-17.112,0-17.112-15.547-20.195-19.526-17.112-22.95,8.4-24.338,24.34,7.033,31.886,7.033,31.886a46.119,46.119,0,0,0,13.092,1.973,81.293,81.293,0,0,0,15.352-1.867Z"
            transform="translate(-525.815 460.772)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root">
          <path
            id="rb1"
            d="M29.729-.488s-8.9,80.787-14.75,83.253-4.742-.136-5.952-7.269c-.572-3.374.053-20.523-2.187-37.079C4.341,19.953,1.268.825,1.268.825A54.117,54.117,0,0,0,14.891,2.091,74.231,74.231,0,0,0,29.729-.488Z"
            transform="translate(819.043 636.482) rotate(3)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M3895.63,2847.44c.282.839,2.21,18.62-3.033,34.022s-4.089,32.533-5.252,31.816-1.646-27.06,2.289-41.165-.554-22.314.21-24.672c.331-1.019,1.354-.207,2.753-.307C3893.7,2847.03,3895.25,2846.32,3895.63,2847.44Z"
            transform="translate(-3058.946 -2200.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(821.401 540.089)">
            <g id="abscess" transform="translate(-13.154 175.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(38.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="cracked" transform="translate(15.036 63.105) rotate(-138)">
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(-12 97.133)">
              <path
                id="p1-4"
                data-name="p1"
                d="M1110.9,670.327s12.889,8.54,25.173,8.54,23.962-8.54,23.962-8.54"
                transform="translate(-1107.654 -674.222)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(807.748 592.675)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc">
              <path
                id="rctpc-2"
                data-name="rctpc"
                d="M13.54,8.125,5.551,43.092,3.6,43.126l-2.1.037L-2.447,8.4C-2.447,6.8,13.54,6.523,13.54,8.125Z"
                transform="translate(831.6 596.319) rotate(0.974)"
                fill="red"
              ></path>
            </g>
            <g id="resection">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982,33.023,107"
                transform="translate(814.284 613.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="seal" transform="translate(149.97)">
              <path
                id="seal-2"
                data-name="seal"
                d="M746.776,514.944s-26.265,3.332-26.265,7.906c0,2.424,8.8,4.844,15.811,6.4,5.868,1.3,10.008,1.728,10.454,3.986.947,4.8-22.476,8.82-22.476,8.82"
                transform="translate(-48.338 5.995)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="implant" transform="translate(818.628 623.499)">
              <path
                id="p5"
                d="M806.2,410.878l-1.569-15.719s6.272-8.37,11.062-11.2,3.083-2.77,8,0,11.6,10.96,11.6,10.96l-.134,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M808.716,384.517l-1.844-10.682,27.432-8.6-1.561,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3"
                d="M810.36,366.894l-1.53-10.675,23.593-7.444-1.287,11.536Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M812.121,348.918l-1.142-10.534,19.625-5.9-1.57,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-803.705 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(801.683 441.784)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0H72.317"
                transform="translate(-3.5 174.716)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H72.317"
                transform="translate(-3.5 91.716)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(848.568 675.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(811.93 612.874)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2-2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-7"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
