import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LoginLayout from "../../../layouts/login";
import colors from "../../../library/styled-components/colors";
import LoginBlock from "../../../components/blocks/login-block";
import ResetBlock from "../../../components/blocks/reset-block";
//import RegisterBlock from "../../../components/blocks/register-block";
import PinCodeBlock from "../../../components/blocks/pin-code-block";
import PaymentBlock from "../../../components/blocks/payment-block";
import UpdatePasswordBlock from "../../../components/blocks/update-password-block";
import store from "store";
import loginPng from "../../../img/login.png";
import moment from "moment";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import {
  sendPinCode,
  sendResetPassword,
  sendUpdatePassword,
  setToken,
  getRegisterConfig,
  getProducts,
} from "./actions";
import { AppContext } from "hooks/context";
import { loginAdmin } from "actions/register";
import { ADMIN_NS } from "config";
import stateHandler from "hooks/states/stateHandler";

const Container = styled.div`
  @media (min-width: 481px) {
    width: 60rem;
  }
  @media (max-width: 481px) {
    width: 100%;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em 1em 0em 1em;
`;

export default function RegisterAdmin({ match }) {
  const { state, dispatch } = useContext(AppContext);
  const [resetPin, setResetPin] = useState(false);
  const [products, setProducts] = useState(null);
  const [publishableKey, setPublishableKey] = useState(null);
  const [offer, setOffer] = useState(null);
  const [slideState, setSlideState] = useState({
    current: match.params.step,
    in: match.params.step,
    out: null,
  });

  const history = useHistory();

  useEffect(() => {
    handleRoute();
  }, []);

  async function loadData(paramsIid) {
    const cfg = await getRegisterConfig();
    const prds = await getProducts();
    if (!prds) return;
    setProducts(prds.data);
    setPublishableKey(cfg.publishableKey);
    if (paramsIid) {
      try {
        const ofr = JSON.parse(atob(paramsIid));
        if (moment().isBefore(moment(ofr.expiresAt))) setOffer(ofr);
      } catch (error) {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    loadData(match.params.id);
  }, [match.params.id]);

  async function handleRoute() {
    if (
      match.params &&
      ["pincode", "reset-token"].includes(match.params.step)
    ) {
      if (match.params.id) setToken(match.params.id)(dispatch);
      else navigateTo("/public/access/login", "login");
    }
  }

  async function navigateTo(path, step) {
    const out = match.params.step;
    history.push({
      pathname: path,
      state: { out },
    });
    setSlideState({
      current: step,
      out,
      in: step,
    });
    window.scrollTo(0, 0);
  }

  history.listen((location) => {
    dispatch({
      type: "UPDATE",
      context: "user",
      meta: { updating: false, warning: null },
    });
    const { pathname, state } = location;
    const step = pathname.split("/").pop();
    setSlideState({
      current: step,
      out: state && state.out,
      in: step,
    });
  });

  const { schedules, user } = state;

  return (
    <LoginLayout
      menu={true}
      meta={schedules.meta}
      user={user}
      slideState={slideState}
    >
      {slideState.current === "1beb4928-3fa2-4372-86fc-df0fa708880b" &&
        publishableKey && (
          <Container>
            <PaymentBlock
              products={products}
              publishableKey={publishableKey}
              meta={state.user.meta}
            />
          </Container>
        )}

      {slideState.current === "login" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Login</strong> to your Dentiz Practice Managment System
                and manage online patient appointments, recalls, reminders,
                online marketing and social media.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          {match.params?.id === "error" ? (
            <Stack spacing={2}>
              <Alert severity="warning">
                There was an issue connecting to the server. Please login again.
              </Alert>
            </Stack>
          ) : null}
          {match.params?.id === "unauthorized" ? (
            <Stack spacing={2}>
              <Alert severity="warning">
                You are not authorized to access this page. Please login again.
              </Alert>
            </Stack>
          ) : null}
          <LoginBlock
            loginUser={async (user) => {
              dispatch({
                type: "UPDATE",
                context: "user",
                meta: { updating: true },
              });

              const response = await loginAdmin(user);

              if (response && response.code === "SUCCESS") {
                dispatch({
                  type: "UPDATE",
                  context: "user",
                  meta: { updating: false },
                  data: response,
                });

                let storeState = store.get(ADMIN_NS);

                store.set(ADMIN_NS, {
                  ...storeState,
                  user: {
                    meta: { updating: false },
                    data: response,
                  },
                });

                if (!response.active)
                  navigateTo("/admin/access/pincode", "pincode");

                if (response.active) navigateTo("/admin/home/calendar");
              } else {
                dispatch({
                  type: "UPDATE",
                  context: "user",
                  meta: { updating: false, error: "Incorrect credentials" },
                });
              }
            }}
            goToReset={() => navigateTo("/admin/access/reset", "reset")}
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "login",
              out: slideState.out === "login",
              in: slideState.in === "login",
            }}
          />
        </Container>
      )}
      {slideState.current === "reset" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Reset your password</strong>, we will send reset
                instruction to you email.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>

          <ResetBlock
            goToLogin={() => navigateTo("/admin/access/login", "login")}
            resetPassword={(email) =>
              sendResetPassword(email)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}

      {slideState.current === "reset-sent" && (
        <Container>
          <Header>
            <div>
              <p>
                Password Reset Request Successful. Please Check your email to
                continue.
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
        </Container>
      )}

      {slideState.current === "reset-token" && (
        <Container>
          <Header>
            <div>
              <p>
                A request to reset your password has been send, Please enter
                your new password here
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <UpdatePasswordBlock
            resetPassword={(password) =>
              sendUpdatePassword(password)(dispatch, navigateTo)
            }
            colors={colors}
            meta={state.user.meta}
            fade={{
              current: "reset",
              out: slideState.out === "reset",
              in: slideState.in === "reset",
            }}
          />
        </Container>
      )}
      {/* 
      {slideState.current === "setup" && match.params.id && offer && (
        <Container>
          <RegisterBlock
            registerUser={(user) => registerUser(user)(dispatch, navigateTo)}
            colors={colors}
            products={products}
            publishableKey={publishableKey}
            meta={state.user.meta}
            offer={offer}
            goToLogin={() => navigateTo("/admin/access/login", "login")}
            fade={{
              current: "setup",
              out: slideState.out === "setup",
              in: slideState.in === "setup",
            }}
          />
        </Container>
      )} */}

      {slideState.current === "pincode" && (
        <Container>
          <Header>
            <div>
              <p>
                <strong>Pincode</strong> Please enter the pin code sent to your
                email to activate your account
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <PinCodeBlock
            colors={colors}
            reset={resetPin}
            meta={state.user.meta}
            sendPinCode={(code) => {
              const callback = () => {
                setTimeout(() => {
                  dispatch({
                    type: "UPDATE",
                    context: "user",
                    meta: {
                      updating: false,
                      warning: null,
                      error: null,
                    },
                  });
                  setResetPin(!resetPin);
                }, 1000);
              };
              sendPinCode(code)(dispatch, navigateTo, callback);
            }}
          />
        </Container>
      )}
    </LoginLayout>
  );
}
