import React, { useReducer, useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { AccessTime, Cancel } from "styled-icons/material";
import loginPng from "../../../img/login.png";
import CalIcon from "../../../icon/calendar";
import moment from "moment";
import startOfDay from "date-fns/startOfDay";
import {
  Overlay,
  Body,
  Header2Col,
  Fixed,
  FixedTopLeft,
  CancelBox,
  OverflowBody,
} from "../../../layouts/lib/common";
import StandardLayout from "../../../layouts/standard";
import InfoPanel from "../../../layouts/components/info";
import colors from "../../../library/styled-components/colors";
import {
  SubmitIconBtn,
  WarnIconBtn,
  Container,
  SlotsContainer,
  Slot,
  SlotItems,
  TimeCont,
  SubmitBtn,
  ModalBox,
  ModalContent,
  AltSubmitBtn,
  DangerSubmitBtn,
} from "../../../components/common";
import ChevRightFeaIcon from "../../../icon/fea-icons/chevron-right";
import DentIcon from "../../../icon/dentist";
import { reducer, initialState } from "./reducer";
import { fetchData } from "./actions";
import { cancelBooking } from "../booking/actions";
import BookingBlock from "./components/booking";

export default function PublicHome({ match, user }) {
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [date, setDate] = useState(null);
  const [selectAppointment, setSelectAppointment] = useState(null);
  const [lock, setLock] = useState(true);
  const [overlay, setOverlay] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [slideState, setSlideState] = useState({
    current: match.params.step,
    in: match.params.step,
    out: null,
  });

  const { appointment, appointments, patients, practitioners, slot } = state;

  async function navigateTo(step, abs = false) {
    const out = match.params.step;
    history.push({
      pathname: abs ? step : `./${step}`,
      state: { out },
    });
    setSlideState({
      current: step,
      out,
      in: step,
    });
    window.scrollTo(0, 0);
  }

  async function loadData() {
    dispatch({
      type: "UPDATE",
      context: "appointments",
      meta: { updating: true },
    });
    await fetchData()(dispatch, navigateTo);
    setLock(false);
  }

  useEffect(() => {
    loadData();
    navigateTo("list");
  }, []);

  const patient = useMemo(
    () => patients?.data?.length > 0 && patients.data[0].data,
    [patients]
  );

  const activeAppointments = useMemo(
    () =>
      appointments &&
      appointments?.data?.filter(
        (a) =>
          !a.data?.Cancelation &&
          startOfDay(Date.parse(a.data.Start)) >= startOfDay(new Date())
      ),
    [appointments]
  );

  if (lock) return null;
  return (
    <StandardLayout
      home={match.params.step !== "list"}
      homeTo={"list"}
      meta={appointments?.meta}
      user={user}
      smsActive={false}
      noSync={true}
    >
      <OverflowBody>
        {match.params.step === "list" && (
          <div>
            {!lock && activeAppointments && activeAppointments.length <= 0 ? (
              <InfoPanel>
                <h3>Hi {patient && patient.Name}</h3>
                <p>
                  You are registered as a patient at <br />
                  <strong>{patient && patient.Practice.PracticeName}</strong>
                </p>
                <p> You currently have no appointments!</p>
              </InfoPanel>
            ) : (
              <InfoPanel>
                <h3>Hi {patient && patient.Name}</h3>
                <p>
                  You are registered as a patient at <br />
                  <strong>{patient && patient.Practice.PracticeName}</strong>
                </p>
                <p>Your appointments are listed below</p>{" "}
              </InfoPanel>
            )}
            {!patient && (
              <InfoPanel>
                <h2>You are not registered with any Dentiz Dental Practice!</h2>
              </InfoPanel>
            )}

            {!lock && activeAppointments && activeAppointments.length > 0 && (
              <SlotsContainer bottom="5em">
                {activeAppointments.map((a) => (
                  <Slot
                    key={a.data.AppointmentId}
                    onClick={() => {
                      setSelectAppointment(a.data);
                      setOverlay(1);
                    }}
                    colors={colors}
                  >
                    <DentIcon size="48" />
                    <h4>{a.data.Practitioner && a.data.Practitioner.Name}</h4>
                    <SlotItems>
                      <div>
                        <AccessTime size="24" />
                      </div>
                      <div>
                        {moment(a.data.Start, [
                          moment.ISO_8601,
                          "HH:mm:ss",
                        ]).format("hh:mm")}
                      </div>

                      <TimeCont>
                        <div>
                          {moment(a.data.End, [
                            moment.ISO_8601,
                            "HH:mm:ss",
                          ]).diff(
                            moment(a.data.Start, [moment.ISO_8601, "HH:mm:ss"]),
                            "minutes"
                          )}
                        </div>
                        <div>min</div>
                      </TimeCont>
                    </SlotItems>
                    <div style={{ padding: "0.5em", fontSize: "1.2rem" }}>
                      {moment(a.data.Start).format("ddd, MMM Do YYYY")}
                    </div>
                  </Slot>
                ))}
              </SlotsContainer>
            )}
            <Fixed>
              <InfoPanel>
                {patient && patient.PracticeId && (
                  <SubmitIconBtn
                    onClick={() => {
                      setOverlay(0);
                      setSelectAppointment(null);
                      navigateTo("booking");
                    }}
                    center={true}
                  >
                    Book New appointment <ChevRightFeaIcon size="22" />
                  </SubmitIconBtn>
                )}
              </InfoPanel>
            </Fixed>
          </div>
        )}
        {match.params.step === "booking" && patient && (
          <SlotsContainer bottom={selectAppointment ? "9em" : "5em"}>
            <BookingBlock
              gDate={date}
              changeDate={(date) => setDate(date)}
              slot={slot}
              user={user}
              practitioners={practitioners}
              practiceId={patient.PracticeId}
              dispatch={dispatch}
              navigateTo={navigateTo}
              slideState={slideState}
              replaceAppointment={selectAppointment}
            />
          </SlotsContainer>
        )}

        {appointment && match.params.step === "booked" && (
          <Container>
            <Header2Col>
              <div>
                <h2>Your appointment booking is complete!</h2>
                <p>
                  You will recieve an SMS shortly confirming your appointment
                  for{" "}
                  <strong>{moment(appointment.data.Start).calendar()}</strong>
                </p>
              </div>
              <img src={loginPng} height="184" alt="" />
            </Header2Col>

            <Body>
              <SubmitBtn
                onClick={() => {
                  setSelectAppointment(null);
                  loadData();
                  navigateTo("list", true);
                }}
                size="0.9"
              >
                Go to my Appointments!
              </SubmitBtn>
            </Body>
          </Container>
        )}

        {appointment && selectAppointment && match.params.step === "rebooked" && (
          <Container>
            <Header2Col>
              <div>
                <h2>Your appointment has been sucesssfully rescheduled!</h2>

                <p>
                  The appointment for{" "}
                  <strong>{moment(selectAppointment.Start).calendar()} </strong>
                  is no longer valid.
                </p>

                <p>
                  You will recieve an SMS shortly confirming your new
                  appointment for{" "}
                  <strong>{moment(appointment.data.Start).calendar()}</strong>
                </p>
              </div>
              <img src={loginPng} height="184" alt="" />
            </Header2Col>

            <Body>
              <SubmitBtn
                onClick={() => {
                  setSelectAppointment(null);
                  navigateTo("list", true);
                }}
                size="0.9"
              >
                Go to My Appointments
              </SubmitBtn>
            </Body>
          </Container>
        )}
      </OverflowBody>
      <Overlay key="overlay" status={overlay}>
        <ModalBox show={showModal}>
          <ModalContent>
            <h3>Are you sure you want to cancel this appointment?</h3>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "auto auto",
                gridGap: "1em",
              }}
            >
              <DangerSubmitBtn
                onClick={async () => {
                  await cancelBooking(user, selectAppointment)(dispatch);
                  setSelectAppointment(null);
                  setShowModal(false);
                  setOverlay(2);
                }}
              >
                Yes continue
              </DangerSubmitBtn>
              <AltSubmitBtn
                onClick={() => {
                  setShowModal(false);
                }}
              >
                No Cancel
              </AltSubmitBtn>
            </div>
          </ModalContent>
        </ModalBox>
        <FixedTopLeft>
          <CancelBox
            size="44"
            onClick={() => {
              setOverlay(2);
              setSelectAppointment(null);
            }}
            center={true}
          />
        </FixedTopLeft>
        <Body>
          <InfoPanel>
            {selectAppointment && (
              <Container style={{ marginTop: "3em", color: colors.dark }}>
                <CalIcon size="65" />
                <div>
                  <p>
                    Welcome {patient && patient.Name},<br />
                    you have an upcoming appointment at <br />
                    <strong>{patient && patient.Practice.PracticeName}</strong>
                  </p>
                </div>
                <div>
                  <h4>
                    <span>
                      <span
                        style={{
                          borderRight: "2px solid black",
                          marginRight: "4px",
                        }}
                      >
                        {selectAppointment.Practitioner.Name}{" "}
                      </span>
                      <span>
                        {moment(selectAppointment.Start, [
                          moment.ISO_8601,
                          "HH:mm:ss",
                        ]).format("hh:mm")}
                      </span>
                    </span>
                  </h4>{" "}
                  <h4>
                    {moment(selectAppointment.Start).format("ddd, MMM Do YYYY")}
                  </h4>
                </div>
                <p>
                  If you want to change or cancel your apppointment, you can do
                  so 24 hours before your appointment time.
                </p>
                <SubmitIconBtn
                  style={{ width: "100%" }}
                  onClick={() => {
                    navigateTo("booking");
                    setOverlay(2);
                  }}
                  center={true}
                >
                  <span>Change appointment </span> <AccessTime size="22" />
                </SubmitIconBtn>
                <WarnIconBtn
                  style={{ marginTop: "1rem", width: "100%" }}
                  onClick={() => {
                    setShowModal(true);
                  }}
                  center={true}
                >
                  <span>Cancel appointment </span> <Cancel size="22" />
                </WarnIconBtn>
              </Container>
            )}
          </InfoPanel>
        </Body>
      </Overlay>
    </StandardLayout>
  );
}
