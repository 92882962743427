import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import FullCalendar from "@fullcalendar/react";
import timeGridWeekPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { ModalBox } from "../../components/common";
import PopOut from "../../components/popout/PopOut";
import colors from "../../library/styled-components/colors";
import SlotForm from "./SlotForm";
import RemoveSlotForm from "./RemoveSlotForm";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  setHours,
  setMinutes,
  setSeconds,
  getDay,
  addMinutes,
  format,
  setDay,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { dayjs, openingHours } from "../../config";

const DayListOptions = ({ top, left, start, width, onSelect }) => (
  <PopOut overlayData={{ x: left, y: top }} close={() => close()}>
    <List
      style={{
        width,
      }}
      aria-label="Practioners"
    >
      <ListItem button onClick={() => onSelect({ start, typeId: 3 })}>
        <ListItemText primary="Available all day" />
      </ListItem>

      <ListItem button onClick={() => onSelect({ start, typeId: 4 })}>
        <ListItemText primary="Unavailable" />
      </ListItem>
    </List>
  </PopOut>
);

const Container = styled.div`
  display: grid;
  position: relative !important;
  code {
    font-family: "Nunito", sans-serif;
  }

  button {
    :focus {
      box-shadow: none !important;
    }
  }
  .fc .fc-toolbar.fc-header-toolbar {
    margin: 0;
    height: 3rem;
    font-size: 1.2rem;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-button-group button {
    background-color: ${(props) => props.color};
    color: ${(props) => (!!props.color ? colors.dark : colors.white)};
    :hover {
      ${(props) =>
        props.color &&
        css`
          filter: brightness(120%);
        `}
    }
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-button-group button.fc-button-active {
    ${(props) =>
      props.color &&
      css`
        filter: brightness(120%);
      `}
  }

  .fc .fc-toolbar.fc-header-toolbar {
    margin: 0;
    background-color: #2c3e50;
    height: 3rem;
    font-size: 1.2rem;
    background-color: ${(props) => props.color};
    color: ${(props) => (!!props.color ? colors.dark : colors.white)};
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-title {
    background-color: ${(props) => props.color};
    color: ${colors.white};
    font-size: 1.2rem;
    font-weight: 300;
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-filterButton-button,
  .fc .fc-toolbar.fc-header-toolbar .fc-titleButton-button {
    width: 12rem;
    background-color: ${(props) => props.color};
    color: ${(props) => (!!props.color ? colors.dark : colors.white)};
    &:hover {
      filter: brightness(90%);
    }
  }

  .fc .fc-toolbar.fc-header-toolbar .fc-button {
    border-radius: 0;
    outline: none;
    border-style: none !important;
  }

  .out-of-range,
  .out-of-range.fc-today {
    background: rgb(226, 226, 226, 0.4) !important;
  }

  .out-of-range-secondary,
  .out-of-range.fc-today {
    background: rgba(90, 109, 144, 0.4) !important;
  }

  .fc-media-screen .fc-timegrid-event {
    cursor: pointer;
  }
`;

const IconWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  &:hover {
    svg {
      color: ${colors.danger};
    }
  }
`;
const Title = styled.div`
  position: absolute;
  left: 0;
  top: 0;
`;

function Scheduler({ practitioner, createMethod, updateMethod, removeMethod }) {
  const [updating, setUpdating] = useState(false);
  const [addSlot, setAddSlot] = useState(null);
  const [editSlot, setEditSlot] = useState(null);
  const [removeSlot, setRemoveSlot] = useState(null);
  const [dayList, setDayList] = useState(null);

  const createAllDayEvent = async (params) => {
    const start = new Date(params.start);
    const data = {
      PractitionerId: {
        val: practitioner.PractitionerId,
      },
      TypeId: { val: params.typeId },
      Day: { val: getDay(start) },
      Start: { val: format(start, "HH:mm") },
      End: { val: format(start, "HH:mm") },
    };

    setUpdating(true);
    await createMethod(data);
    setAddSlot(null);
    setRemoveSlot(null);
    setDayList(null);
    setUpdating(false);
  };

  const changeScheduleEvent = async (event) => {
    const { id, start, end } = event.event;
    const data = {
      PractitionerId: {
        val: practitioner.PractitionerId,
      },
      Day: { val: getDay(start) },
      Start: { val: format(start, "HH:mm") },
      End: { val: format(end, "HH:mm") },
    };

    setUpdating(true);
    await updateMethod(data, id);
    setUpdating(false);
  };

  const updateScheduleEvent = async (form) => {
    const { start, typeId, hours, minutes } = form;
    const duration = hours * 60 + minutes;

    const data = {
      PractitionerId: {
        val: practitioner.PractitionerId,
      },
      TypeId: { val: typeId },
      Day: { val: getDay(start) },
      Start: { val: format(start, "HH:mm") },
      End: { val: format(addMinutes(start, duration), "HH:mm") },
    };

    setUpdating(true);
    await updateMethod(data, editSlot.id);
    setEditSlot(null);
    setUpdating(false);
  };

  const createScheduleEvent = async (form) => {
    const { start, typeId, hours, minutes } = form;
    const duration = hours * 60 + minutes;
    const data = {
      PractitionerId: {
        val: practitioner.PractitionerId,
      },
      TypeId: { val: typeId },
      Day: { val: getDay(start) },
      Start: { val: format(start, "HH:mm") },
      End: { val: format(addMinutes(start, duration), "HH:mm") },
    };

    setUpdating(true);
    await createMethod(data);
    setAddSlot(null);
    setUpdating(false);
  };

  const events = useMemo(() => {
    const unDays = practitioner?.Schedules?.filter((s) => s.TypeId === 4).map(
      (s) => s.Day
    );

    const avDays = practitioner?.Schedules?.filter((s) => s.TypeId === 3).map(
      (s) => s.Day
    );

    return practitioner?.Schedules?.filter(
      (s) => !unDays.includes(s.Day) || s.TypeId === 4
    )
      .filter(
        (s) => !avDays.includes(s.Day) || s.TypeId === 3 || s.TypeId === 1
      )
      .map((s) => {
        let backgroundColor = colors.lightGrey;
        let textColor = colors.white;
        let borderColor = backgroundColor;
        switch (s.TypeId) {
          case 1:
            backgroundColor = colors.success;
            break;
          case 2:
          case 3:
            backgroundColor = colors.secondary;
            borderColor = colors.white;
            break;
          case 4:
            borderColor = colors.dark;
            textColor = colors.dark;
            break;
          default:
            break;
        }

        const date = setDay(new Date(), parseInt(s.Day));
        const sParts = s.Start.split(":");
        const eParts = s.End.split(":");
        const start = setSeconds(
          setMinutes(setHours(date, sParts[0]), sParts[1]),
          sParts[2]
        );
        const end = setSeconds(
          setMinutes(setHours(date, eParts[0]), eParts[1]),
          eParts[2]
        );

        return {
          id: s.ScheduleId,
          backgroundColor,
          borderColor,
          textColor,
          start,
          end,
          title:
            (s.TypeId === 1 && "Online") ||
            (s.TypeId === 2 && "Calendar") ||
            (s.TypeId === 3 && "Full Day") ||
            (s.TypeId === 4 && "Unavailable") ||
            "",
          allDay: s.TypeId > 2,
          typeId: s.TypeId,
          PractitionerId: s.PractitionerId,
          editable: true,
        };
      });
  }, [practitioner.Schedules]);

  const close = () => {
    setAddSlot(null);
    setEditSlot(null);
    setDayList(null);
    setRemoveSlot(null);
  };

  return (
    <Container
      onClick={() => setRemoveSlot(null)}
      color={
        practitioner && colors.themebyId[practitioner.id.toString().slice(-1)]
      }
    >
      <div style={{ position: "relative" }}>
        {addSlot && (
          <PopOut
            overlayData={{ x: addSlot.clientX - 160, y: addSlot.clientY }}
            close={() => close()}
          >
            <SlotForm
              start={addSlot.start}
              hideCal={addSlot.hideCal}
              updateSlot={createScheduleEvent}
            />
          </PopOut>
        )}
      </div>
      <div style={{ position: "relative" }}>
        {editSlot && (
          <PopOut
            overlayData={{ x: editSlot.clientX - 160, y: editSlot.clientY }}
            close={() => close()}
          >
            <SlotForm
              id={editSlot.id}
              start={editSlot.start}
              hours={editSlot.hours}
              minutes={editSlot.minutes - editSlot.hours * 60}
              typeId={editSlot.typeId || 2}
              updateSlot={updateScheduleEvent}
              removeSlot={(id) => {
                removeMethod(practitioner.PractitionerId, id);
                setEditSlot(null);
              }}
            />
          </PopOut>
        )}
      </div>
      <div style={{ position: "relative" }}>
        {removeSlot && (
          <PopOut
            overlayData={{
              x: removeSlot.clientX,
              y: removeSlot.clientY,
            }}
            close={() => close()}
          >
            <RemoveSlotForm
              id={removeSlot.id}
              removeSlot={(id) => {
                removeMethod(practitioner.PractitionerId, id);
                setRemoveSlot(null);
              }}
            />
          </PopOut>
        )}
      </div>
      <ModalBox
        show={!!addSlot || !!editSlot || !!dayList}
        onClick={close}
      ></ModalBox>
      <div style={{ position: "relative" }}>
        {dayList && (
          <DayListOptions
            top={dayList.clientY - 10}
            left={dayList.clientX - dayList.width / 2 + 22}
            width={dayList.width}
            start={dayList.start}
            onSelect={createAllDayEvent}
            close={close}
          />
        )}
      </div>
      <h5>{practitioner.Name}</h5>
      {practitioner && (
        <FullCalendar
          // ref={calendarComponentRef}
          slotMinTime={openingHours.slotMinTime}
          slotMaxTime={openingHours.slotMaxTime}
          plugins={[interactionPlugin, timeGridWeekPlugin]}
          initialView="timeGridWeek"
          dayHeaderFormat={{ weekday: "long" }}
          height="calc(100vh - 12rem)"
          timeZone={"local"}
          themeSystem="bootstrap"
          weekends={true}
          agendaEventMinHeight="29"
          editable={true}
          selectable={true}
          slotEventOverlap={true}
          eventOverlap={(stillEvent, movingEvent) => {
            return (
              stillEvent.allDay &&
              stillEvent.extendedProps.typeId === 3 &&
              movingEvent.extendedProps.typeId === 1
            );
          }}
          selectAllow={(select) => {
            return !practitioner?.Schedules?.find(
              (s) => s.Day === select.start.getDay() && s.TypeId === 4
            );
          }}
          dayCellClassNames={(cell) => {
            if (
              practitioner?.Schedules?.find(
                (s) => s.Day === cell.dow && s.TypeId == 3
              )
            ) {
              return ["out-of-range-secondary"];
            }
            if (
              practitioner?.Schedules?.find(
                (s) => s.Day === cell.dow && s.TypeId === 4
              )
            ) {
              return ["out-of-range"];
            }
          }}
          headerToolbar={false}
          dateClick={(event) => {
            const {
              allDay,
              dayEl,
              jsEvent: { clientX, clientY },
            } = event;
            const localDateTime = dayjs(event.date);
            if (
              practitioner?.Schedules?.find(
                (s) => s.Day === localDateTime.get("day") && s.TypeId === 4
              )
            )
              return;

            if (
              practitioner?.Schedules?.find(
                (s) =>
                  s.Day === localDateTime.get("day") && s.TypeId === 3 && allDay
              )
            )
              return;

            const box = dayEl.getBoundingClientRect();
            if (allDay) {
              setDayList({
                clientX: box.left,
                clientY: box.top,
                start: event.date,
                width: box.width,
              });
            } else {
              setAddSlot({
                clientX,
                clientY,
                start: event.date,
                hideCal: !!practitioner?.Schedules?.find(
                  (s) => s.Day === localDateTime.get("day") && s.TypeId === 3
                ),
              });
            }
          }}
          eventClick={(calEvent) => {
            const {
              jsEvent,
              el,
              event: { allDay, start, end, id, extendedProps },
            } = calEvent;
            jsEvent.stopPropagation();
            setRemoveSlot(null);
            const box = el.getBoundingClientRect();
            if (!allDay) {
              setEditSlot({
                id,
                clientX: box.x,
                clientY: box.y,
                start,
                hours: differenceInHours(end, start),
                minutes: differenceInMinutes(end, start),
                typeId: extendedProps.typeId,
              });
            } else {
              setRemoveSlot({
                id,
                clientX: box.x - 40,
                clientY: box.y,
              });
            }
          }}
          eventChange={changeScheduleEvent}
          events={events?.filter((e) => !e.Cancelation)}
        />
      )}
    </Container>
  );
}

export default Scheduler;
