import React from "react";
import { Container, Action } from "./styles";

export default function AddLayout({
  meta,
  children,
  actions,
  position = "bottom-right",
}) {
  return (
    <Container position={position}>
      {actions.map(
        (i) =>
          i.active && (
            <Action key={i.key} onClick={i.method}>
              {i.icon}
            </Action>
          )
      )}
    </Container>
  );
}
