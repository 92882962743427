import styled from "styled-components";
import colors from "../../library/styled-components/colors";

export const SideContainer = styled.div`
  display: grid;
  position: absolute;
  right: 0;
  height: 100vh;
  overflow-x: auto;
  grid-template-rows: 0 auto;
  grid-template-areas: "header" "content";
  background-color: ${colors.secColor};
  border-left: 1px solid ${colors.primColor};
  @media (min-width: 481px) {
    width: 30vw;
  }
  @media (max-width: 481px) {
    width: 100vw;
  }
`;

export const SideHeader = styled.div`
  grid-area: header;
  height: 0;
`;

export const SideContent = styled.div`
  grid-area: content;
`;

export const Heading = styled.div`
  border: 1px solid ${colors.primColor};
  font-size: 22px;
  margin: 10px;
  padding: 10px;
  color: ${colors.primColor};
  text-align: center;
`;
