import React from "react";
import * as Joi from "@hapi/joi";
import LockFeaIcon from "../../../icon/fea-icons/lock";

const validation = {
  Password: Joi.string().min(10).max(255).required(),
};

export const formSchema = {
  Password: {
    domRef: null,
    isRequired: true,
    password: true,
    text: "Password",
    type: "password",
    val: "",
    icon: <LockFeaIcon size="16" />,
    placeholder: "Please enter a secure password",
    validation: Joi.object({ Password: validation.Password }),
  },
};

export const createButtons = [
  {
    text: "Reset Password",
    type: "Submit",
  },
];
