import localforage from "localforage";
import store from "store";
import { ADMIN_NS, PUBLIC_NS } from "../../config";

const handleResponse = async (response) => {
  if (response.status >= 200 && response.status < 400) {
    const resp = await response.json();
    return resp;
  } else if (!response || response.status === 401) {
    store.remove(ADMIN_NS);
    store.remove(PUBLIC_NS);
    //redirect to logout
    window.location.href = "#/admin/logout?reason=unauthorized";
    return { code: "NOAUTH" };
  } else throw response.status;
};

const authHeader = async (state) => {
  const context = store.get(state);
  const meta = await localforage.getItem("meta");

  return {
    Authorization: `Bearer ${
      context && context.user && context.user.data && context.user.data.token
    }`,
    Meta: JSON.stringify(meta),
    "Content-Type": "application/json; charset=utf-8",
  };
};

const authFileHeader = (state) => {
  const context = store.get(state);
  return {
    Authorization: `Bearer ${
      context && context.user && context.user.data && context.user.data.token
    }`,
    Accept:
      "application/json, application/xml, text/plain, text/html, image/png, *.*;",
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "multipart/form-data; boundary=${form._boundary}",
    "x-goog-resumable": "start",
  };
};

const authBody = (data, state) => {
  const {
    user: {
      data: { PracticeId },
    },
  } = store.get(state);
  return { PracticeId, data };
};

const authOnlyHeader = (state) => {
  const { user } = store.get(state);
  return {
    Authorization: `Bearer ${user && user.data && user.data.token}`,
  };
};

export { handleResponse, authHeader, authOnlyHeader, authBody, authFileHeader };
