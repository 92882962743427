import React from "react";
import colors from "library/styled-components/colors";
import { addMonths, addMinutes, startOfHour, startOfMinute } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import {
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers";
import styled from "styled-components";

const DateLayoutWrapper = styled.div`
  display: grid;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
`;

const DateLayout = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 2;
`;

const DateColLayout = styled.div`
  display: flex;
  margin: 0px auto;
`;

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)`
  ul {
    max-height: fit-content;
  }
`;

const SelectDateStep = ({sendDate, setSendDate}) => {
  const [sendSlot, setSendSlot] = React.useState(1);

  return (
    <div>
      <Typography
        variant="body2"
        style={{
          color: colors.dark,
          marginTop: "1rem",
          marginBottom: "1rem",
        }}
      >
        <strong>When would you like to send this recall?</strong>
        <br />
        You can send it now, or schedule it for a later date.
      </Typography>
      <div>
        <ButtonGroup
          spacing="0.5rem"
          aria-label="spacing button group"
          variant="contained"
          style={{
            marginBottom: "1rem",
          }}
        >
          <Button
            variant={sendSlot === 1 ? "contained" : "outlined"}
            onClick={() => {
              setSendSlot(1);
              setSendDate(
                addMinutes(
                  startOfMinute(new Date()),
                  Math.ceil((new Date().getMinutes() + 1) / 5) * 5 -
                    new Date().getMinutes()
                )
              );
            }}
          >
            now
          </Button>
          <Divider />
          <Button
            variant={sendSlot === 3 ? "contained" : "outlined"}
            onClick={() => {
              setSendSlot(3);
              setSendDate(
                addMonths(startOfHour(new Date().setHours(9, 0, 0, 0)), 3)
              );
            }}
          >
            3 months
          </Button>{" "}
          <Divider />
          <Button
            variant={sendSlot === 6 ? "contained" : "outlined"}
            onClick={() => {
              setSendSlot(6);
              setSendDate(
                addMonths(startOfHour(new Date().setHours(9, 0, 0, 0)), 6)
              );
            }}
          >
            6 months
          </Button>{" "}
          <Divider />
          <IconButton
            color={sendSlot === 0 ? "primary" : "default"}
            onClick={() => {
              setSendSlot(0);
            }}
          >
            <CalendarMonthIcon />
          </IconButton>
        </ButtonGroup>
      </div>
      <div
        style={{
          border: `1px solid ${colors.dark}`,
          padding: "1rem",
          borderRadius: "5px",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <div
            style={{
              display: "flex",
            }}
          >
            <DateLayoutWrapper>
              <DateLayout>
                <DateColLayout>
                  <DateCalendar
                    value={sendDate}
                    minDate={new Date()}
                    disabled={sendSlot !== 0}
                    onChange={(date) => {
                      setSendDate(date);
                    }}
                  />

                  <Divider orientation="vertical" flexItem />
                  <StyledMultiSectionDigitalClock
                    ampm={false}
                    sx={{ width: "auto" }}
                    value={sendDate}
                    disabled={sendSlot !== 0}
                    onChange={(date) => {
                      setSendDate(date);
                    }}
                  />
                </DateColLayout>
              </DateLayout>
            </DateLayoutWrapper>
          </div>
        </LocalizationProvider>
      </div>
    </div>
  );
};

export default SelectDateStep;
