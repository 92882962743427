import { handleResponse, authHeader } from "../../library/handler";

export const getSlots = async (uuid, data) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/public/booking/slots/${uuid}`,
    {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const bookSlot = async (token, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/public/booking`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};



export const cancelSlot = async (token, data) => {
  return await fetch(`${process.env.REACT_APP_APP_API}/public/booking/${data.AppointmentId}`, {
    method: "delete",
    body: JSON.stringify(data),
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export async function resetPassword(state, email) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/reset/password`, {
    method: "post",
    headers: await authHeader(state),
    body: JSON.stringify({ email, state }),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function updatePassword(state, password) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/update/password`, {
    method: "post",
    body: JSON.stringify({ password, state }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function checkAuthStatus(state) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/check`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}