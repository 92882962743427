import colors from "../styled-components/colors";
import moment from "moment";
import {
  setHours,
  setMinutes,
  parse,
  format,
  differenceInDays,
} from "date-fns";
import dayjs from "dayjs";
import { key } from "localforage";

export const mapBusinessHours = (schedules) =>
  schedules?.length
    ? schedules
        ?.filter((s) => s.TypeId <= 2 || s.TypeId === 3)
        .map((s) => {
          const sParts = s.Start.split(":");
          const eParts = s.End.split(":");

          if (s.TypeId === 3) {
            return {
              daysOfWeek: [s.Day], // Monday, Tuesday, Wednesday
              startTime: `00:00:00`, // 8am
              endTime: `23:00:00`, // 6pm
            };
          } else {
            const start = dayjs()
              .hour(sParts[0])
              .minute(sParts[1])
              .second(sParts[2]);
            const end = dayjs()
              .hour(eParts[0])
              .minute(eParts[1])
              .second(eParts[2]);

            return {
              daysOfWeek: [s.Day], // Monday, Tuesday, Wednesday
              startTime: start.format("HH:mm:ss"), // 8am
              endTime: end.format("HH:mm:ss"), // 6pm
            };
          }
        })
    : [];

const statusBgColor = (data) => {
  if (data?.CancelationId) return colors.softRed;
  if (data?.Meta?.completed) return colors.softGreen;
  if (data?.Meta?.updating) return colors.white;
  if (data?.Meta?.inTreatment) return colors.secondary;
  if (data?.Meta?.arrived) return colors.accentColor;
  return colors.lightBlue;
};

const statusTextColor = (data) => {
  if (data.Meta?.updating) return colors.dark;
  if (data.Meta?.inTreatment) return colors.white;
  if (data.Meta?.arrived) return colors.white;
  if (data.Meta?.completed) return colors.dark;
  if (data.Cancelation) return colors.white;
  return colors.dark;
};

export const mapScheduleEvents = (schedules) => {
  return schedules?.flatMap((s) => {
    const events = [];
    for (let i = 0; i < 52; i++) {
      // Create a new date for the current week's day according to s.data.Day
      const date = new Date();
      const currentDay = date.getDay();
      const difference = s.data.Day - currentDay + 7 * i;
      date.setDate(date.getDate() + difference);

      const start = parse(s.data.Start, "HH:mm:ss", date);
      const end = parse(s.data.End, "HH:mm:ss", date);

      const event = {
        id: `${s.data.ScheduleId}-${i}`, // Unique ID for each event
        title: "Online Slot Available",
        Active: true,
        backgroundColor: colors.white,
        borderColor: colors.softGreen,
        textColor: colors.dark,
        start: format(start, "yyyy-MM-dd HH:mm:ss"),
        end: format(end, "yyyy-MM-dd HH:mm:ss"),
        PractitionerId: s.data.PractitionerId,
        editable: false,
        ...s.data,
        Start: format(start, "yyyy-MM-dd HH:mm:ss"),
        End: format(end, "yyyy-MM-dd HH:mm:ss"),
      };

      events.push(event);
    }

    return events;
  });
};

const cancelledReasons = [
  { key: 1, name: "Forgot", value: "FORGOT" },
  { key: 2, name: "Emergency", value: "EMERGENCY" },
  { key: 3, name: "Did not Attend", value: "FAILED" },
  { key: 4, name: "Illness", value: "SICK" },
  { key: 5, name: "Work", value: "WORK" },
  { key: 6, name: "Personal reasons", value: "PERSONAL" },
];

export const mapEvents = (appointments, appointmentId = null) =>
  appointments?.data
    ?.filter((a) => a.data?.Active)
    .map((a) => {
      const backgroundColor = statusBgColor(a.data);
      const textColor = statusTextColor(a.data);

      const isAllDay =
        differenceInDays(new Date(a.data?.End), new Date(a.data?.Start)) > 0;

      switch (eventBlockType(a.data)) {
        case "Appointment":
          let title = `${a.data.Patient?.User?.Name} [${
            a.data?.Treatment?.Name || a.data?.Category?.Name || ""
          } ${a.data?.Treatment?.Code || ""}]`;
          if (a.data?.Cancelation) {
            const resaon =
              cancelledReasons.find(
                (r) => r.value === a.data?.Cancelation?.Reason
              )?.name || "No Reason";
            title = `${a.data.Patient?.User?.Name} [${
              a.data?.Treatment?.Name || a.data?.Category?.Name || ""
            } ${a.data?.Treatment?.Code || ""}] - ${resaon} [${
              a.data?.Cancelation?.Information || ""
            }]`;
          }
          return {
            ...a.data,
            id: a.data.AppointmentId,
            title,
            backgroundColor,
            borderColor:
              a.data.AppointmentId === appointmentId
                ? colors.default
                : colors.grey,
            textColor,
            start: a.data.Start,
            end: a.data.End,
            PractitionerId: a.data.PractitionerId,
            allDay: isAllDay,
            editable: a.data?.Meta?.updating ? false : true,
            CancelationId: a.data?.CancelationId,
            Cancelation: a.data?.Cancelation,
          };
        case "Comment":
          return {
            ...a.data,
            id: a.data?.EventId,
            title:
              (a.data?.Meta?.Comments?.length &&
                a.data?.Meta?.Comments[0].text) ||
              (a.data?.Meta?.Notes && a.data?.Meta?.Notes[0]) ||
              "",
            borderColor:
              a.data?.EventId === appointmentId ? colors.default : colors.grey,
            backgroundColor: colors.yellow,
            textColor,
            start: a.data.Start,
            end: a.data.End,
            PractitionerId: a.data.PractitionerId,
            editable: a.data?.Meta?.updating ? false : true,
            allDay: isAllDay,
          };
        case "Block":
          return {
            ...a.data,
            id: a.data?.EventId,
            title:
              a.data?.Meta?.Block ||
              (a.data?.Meta?.Notes && a.data?.Meta?.Notes[0]) ||
              "",
            borderColor:
              a.data?.EventId === appointmentId ? colors.default : colors.grey,
            backgroundColor: colors.lightGrey,
            textColor,
            start: a.data.Start,
            end: a.data.End,
            PractitionerId: a.data.PractitionerId,
            editable: a.data?.Meta?.updating ? false : true,
            allDay: isAllDay,
          };
      }
    });

export const mapSimpleAppointments = (appointments, form) => {
  const start = new Date(form.start);
  const end = new Date(form.end);

  const isAllDay = differenceInDays(end, start) > 0;

  const newEvent = {
    id: 0,
    backgroundColor: colors.primLight,
    borderColor: colors.primary,
    textColor: colors.dark,
    Start: start,
    End: end,
    start: start,
    end: end,
    allDay: isAllDay,
    //PractitionerId: a.data.PractitionerId,
    editable: true,
  };

  // in appointments.data find the event that is being edited
  const event = appointments.data.find(
    (a) => a.data.AppointmentId === form.appointmentId
  )?.data;

  // check if the event start and end time is changed
  const isNewEventChanged =
    event?.AppointmentId === form?.appointmentId &&
    (new Date(event?.Start).getTime() !== new Date(form.start).getTime() ||
      new Date(event?.End).getTime() !== new Date(form.end).getTime());

  const events = appointments.data
    .map((a) => {
      if (a.data.AppointmentId === form.appointmentId) {
        return { data: { ...a.data, PractitionerId: form.practitionerId } };
      } else return a;
    })
    .filter(
      (a) =>
        form.practitionerId === a.data.PractitionerId &&
        !a.data?.Cancelation &&
        a.data.AppointmentId
    )
    .map((a) => {
      const isAllDay =
        differenceInDays(new Date(a.data.End), new Date(a.data.Start)) > 0;

      return {
        ...a.data,
        id: a.data.AppointmentId,
        //title: patient && patient.data && patient.data.Name,
        isNewEventChanged,
        backgroundColor:
          form.appointmentId === a.data.AppointmentId
            ? isNewEventChanged
              ? colors.primLight
              : colors.primary
            : colors.softRed,
        borderColor:
          form.appointmentId === a.data.AppointmentId
            ? isNewEventChanged
              ? colors.primLight
              : colors.primary
            : colors.softRed,
        textColor: isNewEventChanged ? colors.dark : colors.white,
        start: a.data.Start,
        end: a.data.End,
        allDay: isAllDay,
        //PractitionerId: a.data.PractitionerId,
        editable: a.data?.Meta?.updating ? false : true,
        Start: a.data.Start,
        End: a.data.End,
      };
    });
  return [...events, isNewEventChanged || !form.appointmentId ? newEvent : {}];
};

export const mapSimpleEvents = (appointments, form) => {
  const start = new Date(form.start);
  const end = new Date(form.end);

  const isAllDay = differenceInDays(end, start) > 0;

  const newEvent = {
    id: 0,
    backgroundColor: colors.primLight,
    borderColor: colors.primary,
    textColor: colors.dark,
    Start: start,
    End: end,
    start: start,
    end: end,
    allDay: isAllDay,
    //PractitionerId: a.data.PractitionerId,
    editable: true,
  };

  // in appointments.data find the event that is being edited
  const event = appointments.data.find(
    (a) => a.data.EventId === form.eventId
  )?.data;

  // check if the event start and end time is changed
  const isNewEventChanged =
    event?.EventId === form?.eventId &&
    (new Date(event?.Start).getTime() !== new Date(form.start).getTime() ||
      new Date(event?.End).getTime() !== new Date(form.end).getTime());

  const events = appointments.data
    .map((a) => {
      if (a.data.EventId === form.eventId) {
        return { data: { ...a.data, PractitionerId: form.practitionerId } };
      } else return a;
    })
    .filter(
      (a) =>
        form.practitionerId === a.data.PractitionerId &&
        !a.data?.Cancelation &&
        a.data.EventId
    )
    .map((a) => {
      const isAllDay =
        differenceInDays(new Date(a.data.End), new Date(a.data.Start)) > 0;

      return {
        ...a.data,
        id: a.data.EventId,
        //title: patient && patient.data && patient.data.Name,
        isNewEventChanged,
        backgroundColor:
          form.eventId === a.data.EventId
            ? isNewEventChanged
              ? colors.primLight
              : colors.primary
            : colors.softRed,
        borderColor:
          form.eventId === a.data.EventId
            ? isNewEventChanged
              ? colors.primLight
              : colors.primary
            : colors.softRed,
        textColor: isNewEventChanged ? colors.dark : colors.white,
        start: a.data.Start,
        end: a.data.End,
        allDay: isAllDay,
        //PractitionerId: a.data.PractitionerId,
        editable: a.data?.Meta?.updating ? false : true,
        Start: a.data.Start,
        End: a.data.End,
      };
    });
  return [...events, isNewEventChanged || !form.eventId ? newEvent : {}];
};

export const mergeDateTime = (date, time) =>
  setMinutes(setHours(date, parseInt(time.split(":")[0])), time.split(":")[1]);

export function inBusinessHours(date, hours) {
  let hit = false;
  let dateDay = date.getDay();
  for (var i = 0; i < hours.length; i++) {
    // check if date should conform to this dict's start and end times
    if (hours[i].daysOfWeek.includes(dateDay)) {
      // toLocaleTimeString with arg it-IT will return 24hr time
      let timeStr = date.toLocaleTimeString("it-IT");

      const parts = hours[i].startTime.split(":");
      const startTime = `${parseInt(parts[0]).toString().padStart(2, "0")}:${
        parts[1]
      }:${parts[2]}`;

      // if time after or equal to business hour start for day
      if (startTime <= timeStr) {
        const parts = hours[i].endTime.split(":");
        const endTime = `${parseInt(parts[0]).toString().padStart(2, "0")}:${
          parts[1]
        }:${parts[2]}`;

        // if time before or equal to business hour end for day
        if (endTime > timeStr) {
          hit = true;
        }
      }
      //  if the day matches, but any of the times do not, dont check any more
      //    days and just return false immediately
      // hit = false;
    }
  }
  // if date clicked's weekday is not in businessHours at all
  return hit;
}

export function transformAppointment(appointment) {
  if (!appointment) return;
  return {
    ...appointment,
    PractitionerId: appointment?.PractitionerId,
    Date: appointment.Start,
    Start: appointment.Start,
    End: appointment.End,
    Patient: appointment.Patient,
    Practitioners: appointment.PractitionerId,
    Communicate: {
      SendMail: {
        val: appointment.SendMail,
      },
      SendSms: {
        val: appointment.SendSms,
      },
    },
  };
}

export function eventBlockType(eventData) {
  if (!!eventData?.AppointmentId) {
    return "Appointment";
  }
  if (!!eventData?.EventId && !!eventData?.Meta?.Block) {
    return "Block";
  }
  if (!!eventData?.EventId && !!eventData?.Meta?.Comments) {
    return "Comment";
  }
  if (eventData?.ScheduleId && eventData.TypeId === 1) {
    return "Online";
  }
  if (eventData.TypeId === 5) {
    return "Holiday";
  }
  return "Comment";
}

export const startHours = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
];

export const startMinutes = [
  { value: 0, label: "00" },
  { value: 1, label: "01" },
  { value: 2, label: "02" },
  { value: 3, label: "03" },
  { value: 4, label: "04" },
  { value: 5, label: "05" },
  { value: 6, label: "06" },
  { value: 7, label: "07" },
  { value: 8, label: "08" },
  { value: 9, label: "09" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
  { value: 24, label: "24" },
  { value: 25, label: "25" },
  { value: 26, label: "26" },
  { value: 27, label: "27" },
  { value: 28, label: "28" },
  { value: 29, label: "29" },
  { value: 30, label: "30" },
  { value: 31, label: "31" },
  { value: 32, label: "32" },
  { value: 33, label: "33" },
  { value: 34, label: "34" },
  { value: 35, label: "35" },
  { value: 36, label: "36" },
  { value: 37, label: "37" },
  { value: 38, label: "38" },
  { value: 39, label: "39" },
  { value: 40, label: "40" },
  { value: 41, label: "41" },
  { value: 42, label: "42" },
  { value: 43, label: "43" },
  { value: 44, label: "44" },
  { value: 45, label: "45" },
  { value: 46, label: "46" },
  { value: 47, label: "47" },
  { value: 48, label: "48" },
  { value: 49, label: "49" },
  { value: 50, label: "50" },
  { value: 51, label: "51" },
  { value: 52, label: "52" },
  { value: 53, label: "53" },
  { value: 54, label: "54" },
  { value: 55, label: "55" },
  { value: 56, label: "56" },
  { value: 57, label: "57" },
  { value: 58, label: "58" },
  { value: 59, label: "59" },
];

export const minutesOptions = [
  { value: 0, label: "0 min" },
  { value: 5, label: "5 min" },
  { value: 10, label: "10 min" },
  { value: 15, label: "15 min" },
  { value: 20, label: "20 min" },
  { value: 25, label: "25 min" },
  { value: 30, label: "30 min" },
  { value: 35, label: "35 min" },
  { value: 40, label: "40 min" },
  { value: 45, label: "45 min" },
  { value: 50, label: "50 min" },
  { value: 55, label: "55 min" },
];

export function calculatePublicHolidays(year, practitionerId) {
  const holidays = [];

  // New Year's Day
  holidays["New Year's Day"] = new Date(year, 0, 1);

  // First Monday in February or 1 February if it's Friday
  let firstMondayFebruary = new Date(year, 1, 1);
  while (firstMondayFebruary.getDay() !== 1) {
    firstMondayFebruary.setDate(firstMondayFebruary.getDate() + 1);
  }
  if (firstMondayFebruary.getDate() === 1) {
    holidays["Bank Holiday - February"] = new Date(year, 1, 1);
  } else {
    holidays["Bank Holiday - February"] = firstMondayFebruary;
  }

  // Saint Patrick's Day
  holidays["Saint Patrick's Day"] = new Date(year, 2, 17);

  // Easter Monday
  holidays["Easter Monday"] = calculateEasterMonday(year);

  // First Monday in May
  holidays["Bank Holiday - May"] = calculateFirstMonday(year, 4);

  // First Monday in June
  holidays["Bank Holiday - June"] = calculateFirstMonday(year, 5);

  // First Monday in August
  holidays["Bank Holiday - August"] = calculateFirstMonday(year, 7);

  // Last Monday in October
  holidays["Bank Holiday - October"] = calculateLastMonday(year, 9);

  // Christmas Day
  holidays["Christmas Day"] = new Date(year, 11, 25);

  // Saint Stephen's Day
  holidays["Saint Stephen's Day"] = new Date(year, 11, 26);

  const formatedEvents = Object.keys(holidays).map((k) => ({
    id: `holiday-${k}-${year}-${practitionerId}`,
    title: k,
    backgroundColor: colors.white,
    borderColor: colors.primary,
    textColor: colors.primary,
    start: format(holidays[k], "yyyy-MM-dd HH:mm:ss"),
    end: format(holidays[k], "yyyy-MM-dd 23:59:59"),
    Start: format(holidays[k], "yyyy-MM-dd HH:mm:ss"),
    End: format(holidays[k], "yyyy-MM-dd 23:59:59"),
    PractitionerId: practitionerId,
    allDay: true,
    Active: true,
    editable: false,
    TypeId: 5,
  }));

  return formatedEvents;
}

function calculateFirstMonday(year, month) {
  let date = new Date(year, month, 1);
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() + 1);
  }
  return date;
}

function calculateLastMonday(year, month) {
  let date = new Date(year, month + 1, 0);
  while (date.getDay() !== 1) {
    date.setDate(date.getDate() - 1);
  }
  return date;
}

// Function to calculate Easter Monday
function calculateEasterMonday(year) {
  const a = year % 19;
  const b = Math.floor(year / 100);
  const c = year % 100;
  const d = Math.floor(b / 4);
  const e = b % 4;
  const f = Math.floor((b + 8) / 25);
  const g = Math.floor((b - f + 1) / 3);
  const h = (19 * a + b - d - g + 15) % 30;
  const i = Math.floor(c / 4);
  const k = c % 4;
  const l = (32 + 2 * e + 2 * i - h - k) % 7;
  const m = Math.floor((a + 11 * h + 22 * l) / 451);
  const month = Math.floor((h + l - 7 * m + 114) / 31) - 1;
  const day = ((h + l - 7 * m + 114) % 31) + 1;

  const easterDay = new Date(year, month, day);
  easterDay.setDate(easterDay.getDate() + 1); // Easter Monday
  return easterDay;
}

export const mapPublicHolidays = (practitioners) => {
  // Get the current year and next year
  const year = new Date().getFullYear();
  const nextYear = year + 1;

  // Iterate over each practitioner and accumulate a flat list of bank holidays
  return (
    practitioners?.reduce((acc, practitioner) => {
      const currentYearHolidays = calculatePublicHolidays(
        year,
        practitioner?.data?.PractitionerId
      );
      const nextYearHolidays = calculatePublicHolidays(
        nextYear,
        practitioner?.data?.PractitionerId
      );
      return acc.concat(currentYearHolidays, nextYearHolidays);
    }, []) || []
  );
};
