import React from "react";
import { PreviewFile } from "../../../layouts/lib/common";
import styled from "styled-components";

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PreViewImage = ({ src }) => (
  <PreviewFile>
    <Img src={src} />
  </PreviewFile>
);

const Doc = styled.embed`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PreViewDoc = ({ src }) => (
  <PreviewFile>
    <Doc src={src} />;
  </PreviewFile>
);

const DcmCanvas = styled.canvas`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;

const PreviewFileViewer = ({ data }) => {
  if (!data.src) {
    return <PreviewFile>Corrupted Upload</PreviewFile>;
  }

  switch (data.type) {
    case "png":
    case "jpg":
    case "jpeg":
      return <PreViewImage src={data.src} />;
    case "pdf":
    case "text":
    case "doc":
      return <PreViewDoc src={data.src} />;
    default:
      break;
  }
  return <PreviewFile>Unspported by Viewer</PreviewFile>;
};

export default PreviewFileViewer;
