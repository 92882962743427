import localforage from "localforage";

const appointmentSmsUpdate = (eventData) => async (dispatch) => {
  const appointments = await localforage.getItem("appointments");
  let data = appointments.map((a) => {
    if (a.AppointmentId === eventData.AppointmentId) {
      return {
        ...a,
        SmsMessages: (a.SmsMessages || []).map((m) => {
          if (m.Sid === eventData.Sid) {
            return { ...m, ...eventData };
          }
          return m;
        }),
      };
    }
    return a;
  });
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const appointmentSmsAdd = (eventData) => async (dispatch) => {
  const appointments = await localforage.getItem("appointments");
  let data = appointments.map((a) => {
    if (a.AppointmentId === eventData.AppointmentId) {
      return { ...a, SmsMessages: [...(a.SmsMessages || []), eventData] };
    }
    return a;
  });
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const appointmentUpdate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const appointments = await localforage.getItem("appointments");
  let data = appointments.map((a) => {
    if (a.AppointmentId === Appointment.AppointmentId) {
      return { ...a, ...Appointment, Meta: { ...a.Meta, ...Appointment.Meta } };
    }
    return a;
  });

  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
  await patientAppointmentUpdate(eventData)(dispatch);
};

const appointmentEventUpdate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const appointments = await localforage.getItem("appointments");
  let data = appointments.map((a) => {
    if (a.EventId === Appointment.EventId) {
      return { ...a, ...Appointment };
    }
    return a;
  });
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const patientAppointmentUpdate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Appointment.PatientId) return;
  const data = {
    ...patient,
    Appointments: patient.Appointments.map((a) => {
      if (a.AppointmentId === Appointment.AppointmentId) {
        return {
          ...a,
          ...Appointment,
          Meta: { ...a.Meta, ...Appointment.Meta },
        };
      }
      return a;
    }),
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const appointmentCreate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const appointments = await localforage.getItem("appointments");
  const data = [
    ...appointments?.filter(
      (a) => a.AppointmentId !== Appointment.AppointmentId
    ),
    Appointment,
  ];
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
  await patientAppointmentCreate(eventData)(dispatch);
};

const appointmentEventCreate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const appointments = await localforage.getItem("appointments");
  const data = [
    ...appointments.filter((a) => a.EventId !== Appointment.EventId),
    Appointment,
  ];
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const appointmentEventDelete = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const appointments = await localforage.getItem("appointments");
  const data = appointments.filter((a) => a.EventId !== Appointment.EventId);
  dispatch({
    type: "LOAD",
    context: "appointments",
    data,
  });
  await localforage.setItem("appointments", data);
};

const patientAppointmentCreate = (eventData) => async (dispatch) => {
  const { Appointment } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Appointment.PatientId) return;

  const data = {
    ...patient,
    Appointments: [
      ...patient.Appointments.filter(
        (a) => a.AppointmentId !== Appointment.AppointmentId
      ),
      Appointment,
    ],
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

export default {
  create: appointmentCreate,
  update: appointmentUpdate,
  updateSms: appointmentSmsUpdate,
  addSms: appointmentSmsAdd,
  updateEvent: appointmentEventUpdate,
  createEvent: appointmentEventCreate,
  deleteEvent: appointmentEventDelete,
};
