import React, { useMemo } from "react";
import DateTimePicker from "react-datetime-picker";
import styled from "styled-components";
import colors from "../../../library/styled-components/colors";

const PickerContainer = styled.div`
  position: relative !important;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const InputField = styled.div`
  .react-datetime-picker {
    width: 100%;
  }
  .react-datetime-picker__wrapper {
    font-size: 16px;

    border: 1px solid ${(props) => (props.changed ? colors.primary : "#bdc4c9")};
    background-color: ${(props) =>
      props.changed ? colors.primary : "#ffffff"};
    height: 3em;
    border-radius: 4px;
    padding-left: 3rem !important;
    padding-right: 1rem !important;
    &:focus {
      outline: none;
      border-color: #0070e0;
    }
  }
  .react-datetime-picker__calendar-button.react-datetime-picker__button {
    top: 8px;
    left: 8px;
    position: absolute;
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: 2.2em;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const Icon = styled.span`
  position: absolute;
  top: 46px;
  left: 14px;
`;

const ErrorMessage = styled.label`
  color: ${colors.warning};
  line-height: 0.8rem;
  font-size: 0.8rem;
  margin: 1rem 0 0.2rem 0;
  font-weight: 400;
  text-align: right;
`;

export default function TimePicker({ InputData, onChange, LabelData, icon }) {
  const datetime = useMemo(() => {
    return InputData.value != "" ? new Date(InputData.value) : new Date();
  }, [InputData.value]);

  return (
    <PickerContainer>
      <InputContainer>
        <LabelContainer>
          <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
          {LabelData.error && (
            <ErrorMessage htmlFor={LabelData.text}>
              {LabelData.error}
            </ErrorMessage>
          )}
        </LabelContainer>
        <Icon>{icon}</Icon>
        <InputField>
          <DateTimePicker
            value={datetime}
            onChange={(date) => onChange(date)}
          />
        </InputField>
      </InputContainer>
    </PickerContainer>
  );
}
