import styled from "styled-components";

export const Header = styled.div`
  display: grid;
  justify-items: center;
  align-items: center;
  font-size: 1rem;
  text-align: center;
  line-height: 1.2rem;
  color: #fff;
`;

export const Body = styled.div`
  position: relative !important;
  display: grid;
  padding: 1rem;
  overflow: scroll;
  height: calc(100vh - 8rem);
  grid-template-rows: 5rem auto;
`;

export const TitleHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;