import React, { useState } from "react";
import Popover from "../../popover";
import ConfirmModal from "./modal";
import TrashFeaIcon from "../../../icon/fea-icons/trash";
import styled from "styled-components";
import colors from "../../../library/styled-components/colors";

const ContextOption = styled.div`
  padding: 0.2rem 0.2rem;
  width: 6rem;
  cursor: pointer;
  display: grid;
  grid-template-columns: 1.5rem auto;
  font-size: 16px;
  svg {
    justify-self: center;
  }
  &:hover {
    background: ${colors.light};
    color: ${colors.primary};
  }
`;

const deletePop = ({ showPop, setShowPop, removeClick }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <Popover visible={showPop} right="1rem" header="Options">
      <ContextOption
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
        }}
      >
        <TrashFeaIcon size="16" /> <div>Remove</div>
      </ContextOption>
      <ConfirmModal
        showModal={showModal}
        hideModal={(e) => {
          e.stopPropagation();
          setShowModal(false);
          setShowPop(false);
        }}
        continueClick={removeClick}
      />
    </Popover>
  );
};

export default deletePop;
