import React, { useContext, useEffect, useState } from "react";
import { Close } from "@mui/icons-material";
import {
  Dialog,
  DialogContent,
  Slide,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Table,
  TextField,
  Checkbox,
  Button,
  CircularProgress,
  IconButton,
} from "@mui/material";
import colors from "library/styled-components/colors";
import { ADMIN_NS } from "config";
import {
  getPatientChargesById,
  getPatientInvoicesById,
  getPatientPaymentsById,
} from "actions/patients";
import { format } from "date-fns";
import DropdownSearch from "components/dropdown-search";
import { AccountBalanceWallet } from "@mui/icons-material";
import { AppContext } from "hooks/context";
import styled, { css } from "styled-components";
import CreatePayment from "components/calendar/components/AppointmentCharge/CreatePayment";
import CreateCharge from "components/calendar/components/AppointmentCharge/CreateCharge";
import AppointmentCharge from "components/calendar/components/AppointmentCharge/AppointmentCharge";
import TreatmentListDrawer from "containers/admin/home/patient/components/treaments/TreatmentListDrawer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  createCharge,
  createNoChargePayment,
  deleteCharge,
  deleteNoChargePayment,
} from "actions/payments";
import ConfirmModal from "components/editor/more-popover/modal";
import DoubleBounce from "components/loaders/double-bounce";
import { useHistory } from "react-router-dom";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InputContainer = styled.div`
  margin: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
  input {
    text-align: end;
  }
  ${(props) => css`
    input {
      color: ${colors[props.color]};
    }
  `}
`;

const FixedRow = styled.div`
  position: fixed;
  background: ${colors.lightGrey};
  display: flex;
  justify-content: space-between;
  width: 100%;
  bottom: 0;
  left: 0;
`;

const LeftActions = styled.div`
  display: flex;
  justify-content: start;
`;

const FlexRow = styled.div`
  display: flex;
  justify-content: end;
`;

const columns = [
  { id: "checked", label: "", minWidth: 10 },
  { id: "date", label: "Date", minWidth: 90 },
  {
    id: "type",
    label: "",
    minWidth: 80,
  },
  {
    id: "ref",
    label: "Ref",
    minWidth: 80,
  },
  {
    id: "description",
    label: "Description",
    minWidth: 200,
  },
  {
    id: "debit",
    label: "Debit",
    minWidth: 100,
    align: "right",
  },
  {
    id: "credit",
    label: "Credit",
    minWidth: 100,
    align: "right",
  },
  {
    id: "balance",
    label: "Balance",
    minWidth: 100,
    align: "right",
  },
];

const Accounts = ({ selectedPractitionerId, show, close, patient }) => {
  let history = useHistory();
  const [invoices, setInvoices] = useState([]);
  const [charges, setCharges] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [chargesSelected, setChargesSelected] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const [paymentsSelected, setPaymentsSelected] = useState([]);
  const [showInvoice, setShowInvoice] = useState(false);
  const [treatmentList, setTreatmentList] = useState(false);
  const [openPatientList, setOpenPatientList] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [search, setSearch] = useState("");
  const { state, dispatch } = useContext(AppContext);
  const { patients, list, treatments } = state;

  React.useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Return true to allow transition, false to block
        close();
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, []);

  async function getInvoices(patientId) {
    const response = await getPatientInvoicesById(ADMIN_NS, patientId);
    setInvoices(response.data);
  }

  async function getCharges(patientId) {
    const response = await getPatientChargesById(ADMIN_NS, patientId);
    setCharges(response.data);
  }

  async function getPayments(patientId) {
    const response = await getPatientPaymentsById(ADMIN_NS, patientId);
    setPayments(response.data);
  }

  async function loadData(patient) {
    setLoading(true);
    await getInvoices(patient?.PatientId);
    await getCharges(patient?.PatientId);
    await getPayments(patient?.PatientId);
    await setSelectedPatient(patient);
    setLoading(false);
  }

  useEffect(() => {
    loadData({
      PatientId: patient.PatientId,
      Name: patient?.User.Name,
      Phone: patient?.User.Phone,
      DateOfBirth: patient?.User.DateOfBirth,
    });
  }, [patient]);

  if (!selectedPatient) return null;

  const invoiceRows = invoices
    .map((invoice) => {
      return [...invoice.Charges, ...invoice.Payments].map((row) => ({
        ...row,
        id: row.ChargeId || row.PaymentId,
        key: row.ChargeId || row.PaymentId,
        Type: `${row.ChargeId ? "Charge" : "Payment"} - Invoice`,
        Code: invoice?.Code,
        PractitionerName: row?.Practitioner?.Name,
        Description: `${row?.Practitioner?.Name} | ${
          row?.Treatment?.Name ?? row?.Method
        }
        `,
        Date: row?.Date,
      }));
    })
    .flat();

  const chargesRows = charges.map((charge) => ({
    ...charge,
    id: charge.ChargeId,
    key: charge.ChargeId,
    Description: `${selectedPatient.Name} - ${
      list?.data.find((p) => p.data?.PractitionerId === charge?.PractitionerId)
        ?.data?.Name ?? ""
    } | ${
      treatments?.data.find((p) => p.data?.TreatmentId === charge?.TreatmentId)
        ?.data?.Name ?? ""
    }`,
    Type: "Charge",
    Code: charge.id,
    Date: charge?.Date,
    showCheck: true,
  }));

  const paymentRows = payments.map((payment) => ({
    ...payment,
    id: payment.PaymentId,
    key: payment.PaymentId,
    Description: `${selectedPatient.Name} - ${
      list?.data.find((p) => p.data?.PractitionerId === payment?.PractitionerId)
        ?.data?.Name || ""
    } | ${payment.Method ?? ""}`,
    Type: "Payment",
    Code: payment.id,
    Date: payment?.Date,
    showCheck: true,
  }));

  const accountRowsWithBalance = [
    ...invoiceRows,
    ...chargesRows,
    ...paymentRows,
  ]
    .sort((a, b) => new Date(a.Date) - new Date(b.Date))
    .reduce((acc, row) => {
      const lastBalance = acc[acc.length - 1]?.Balance ?? 0;
      const balance = row.PaymentId
        ? lastBalance - row.Amount
        : row.Amount + lastBalance;
      return [...acc, { ...row, Balance: balance }];
    }, []);

  const handleChargeSelectedClick = (selected, id) => {
    if (selected) {
      setChargesSelected([...chargesSelected, id]);
      return;
    }
    setChargesSelected(chargesSelected.filter((n) => n !== id));
  };

  const handlePaymentPaymentClick = (selected, id) => {
    if (selected) {
      setPaymentsSelected([...paymentsSelected, id]);
      return;
    }
    setPaymentsSelected(paymentsSelected.filter((n) => n !== id));
  };

  return (
    <Dialog
      open={show}
      onClose={close}
      fullScreen={true}
      maxWidth={"lg"}
      TransitionComponent={Transition}
      style={{ padding: "0px 0px 0px 0px" }}
    >
      <DialogContent style={{ padding: "0px 0px 0px 0px" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "0.3rem 1rem",
            alignItems: "center",

            background: colors.rgbGreyDark,
          }}
        >
          <h3 style={{ color: "white" }}>
            Accounts for {selectedPatient?.Name} ({selectedPatient?.Phone || ""}
            )
            {selectedPatient?.DateOfBirth
              ? " Dob: " +
                format(new Date(selectedPatient?.DateOfBirth), "yyy-MM-dd")
              : ""}
          </h3>
          <div style={{ display: "flex" }}>
            <DropdownSearch
              onFocus={() => setOpenPatientList(true)}
              open={openPatientList}
              placeholder={"Change Patient"}
              options={
                patients
                  ? patients.data
                      .filter((p) => {
                        if (!search) return true;
                        const name = p?.data?.Name.toLowerCase();
                        const phone = p?.data?.Phone.toLowerCase();
                        const searchLower = search.toLowerCase();
                        return (
                          name.includes(searchLower) ||
                          phone.includes(searchLower)
                        );
                      })
                      ?.map((p) => p.data)?.slice(0, 10) ?? []
                  : []
              }
              search={search}
              setSearch={setSearch}
              selectedUser={patient}
              setSelectedUser={(item) => {
                loadData(item);
                setOpenPatientList(false);
              }}
              loading={false}
              dim={{
                position: "absolute",
                width: "18rem",
                margin: "0",
                height: "10rem",
                itemSize: 10,
                top: null,
              }}
            />
            <IconButton aria-label="close" onClick={close} size="large">
              <Close style={{ color: "white" }} />
            </IconButton>
          </div>
        </div>
        <TableContainer style={{ maxHeight: "80vh" }}>
          <Table stickyHeader size="small">
            <TableHead
              style={{
                background: colors.vertLightPrimary,
              }}
            >
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {accountRowsWithBalance.map((row) => (
                <TableRow
                  hover={!row.showCheck ? true : false}
                  tabIndex={-1}
                  key={row.key}
                  disabled={!row.showCheck}
                  onClick={(e) => {
                    if (row.showCheck) {
                      if (row.ChargeId)
                        handleChargeSelectedClick(
                          !chargesSelected.includes(row.id),
                          row.id
                        );
                      if (row.PaymentId)
                        handlePaymentPaymentClick(
                          !paymentsSelected.includes(row.id),
                          row.id
                        );
                    }
                  }}
                  style={{
                    cursor: row.showCheck ? "pointer" : "default",
                    background: [
                      ...chargesSelected,
                      ...paymentsSelected,
                      ,
                    ].includes(row.id)
                      ? colors.accentLight
                      : !row.showCheck
                      ? colors.lightPurple
                      : colors.white,
                  }}
                >
                  <TableCell padding="checkbox">
                    {row.loading ? (
                      <CircularProgress size={20} />
                    ) : row.showCheck ? (
                      <Checkbox
                        checked={[
                          ...chargesSelected,
                          ...paymentsSelected,
                          ,
                        ].includes(row.id)}
                        disabled={!row.showCheck}
                        onChange={(e) => {
                          if (row.showCheck) {
                            if (row.ChargeId)
                              handleChargeSelectedClick(
                                !chargesSelected.includes(row.id),
                                row.id
                              );
                            if (row.PaymentId)
                              handlePaymentPaymentClick(
                                !paymentsSelected.includes(row.id),
                                row.id
                              );
                          }
                        }}
                      />
                    ) : (
                      <>
                        <DeleteForeverIcon
                          onClick={() => setDeleteItem(row)}
                          style={{
                            color: colors.errorColor,
                            cursor: "pointer",
                          }}
                        />
                      </>
                    )}
                  </TableCell>
                  <TableCell align="left">
                    {row.Date ? format(new Date(row.Date), "dd/MM/yyyy") : ""}
                  </TableCell>
                  <TableCell align="left">{row?.Type}</TableCell>
                  <TableCell align="left">{row?.Code}</TableCell>
                  <TableCell align="left">{row.Description}</TableCell>
                  <TableCell align="right" style={{ color: "red" }}>
                    {row.ChargeId &&
                      new Intl.NumberFormat("en-IE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(row.Amount)}
                  </TableCell>
                  <TableCell align="right" style={{ color: "green" }}>
                    {row.PaymentId &&
                      new Intl.NumberFormat("en-IE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(row.Amount)}
                  </TableCell>
                  <TableCell
                    align="right"
                    style={{ color: row.Balance >= 0 ? "green" : "red" }}
                  >
                    {new Intl.NumberFormat("en-IE", {
                      style: "currency",
                      currency: "EUR",
                    }).format(row.Balance)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        {loading ? <DoubleBounce color={colors.primary} /> : null}

        <FixedRow>
          <LeftActions>
            <InputContainer>
              <CreatePayment
                selectedPractitionerId={selectedPractitionerId}
                selectedPatient={selectedPatient}
                createPayment={async (data) => {
                  const postData = {
                    Amount: { val: data.amount },
                    Method: { val: data.method },
                    Currency: { val: "EUR" },
                    PatientId: { val: selectedPatient?.PatientId },
                    PractitionerId: { val: data.practitionerId },
                  };
                  setPayments([
                    ...payments,
                    {
                      Amount: parseInt(data.amount),
                      Method: data.method,
                      Currency: "EUR",
                      PatientId: selectedPatient?.PatientId,
                      PractitionerId: data.practitionerId,
                      createdAt: new Date().toISOString(),
                      loading: true,
                    },
                  ]);

                  const response = await createNoChargePayment(
                    ADMIN_NS,
                    postData
                  );
                  response?.data && setPayments([...payments, response.data]);
                }}
              />
            </InputContainer>
            <InputContainer>
              <CreateCharge
                selectedPatient={selectedPatient}
                createCharge={async ({
                  amount,
                  practitionerId,
                  proceedure,
                  patientId,
                }) => {
                  const postData = {
                    Amount: { val: amount },
                    PractitionerId: { val: practitionerId },
                    TreatmentId: { val: proceedure.TreatmentId },
                    Price: { val: proceedure.Price },
                    Data: { val: { quantity: 1, status: 2 } },
                    PatientId: { val: patientId },
                    Currency: { val: "EUR" },
                  };
                  setCharges([
                    ...charges,
                    {
                      Amount: parseInt(amount),
                      Currency: "EUR",
                      PatientId: selectedPatient?.PatientId,
                      PractitionerId: practitionerId,
                      TreatmentId: proceedure.TreatmentId,
                      Amount: proceedure.Price,
                      createdAt: new Date().toISOString(),
                      loading: true,
                    },
                  ]);

                  const response = await createCharge(ADMIN_NS, postData);
                  response?.data && setCharges([...charges, response.data]);
                }}
              />
            </InputContainer>
            {[...chargesSelected, ...paymentsSelected].length ? (
              <InputContainer>
                <Button
                  variant="contained"
                  color="success"
                  size="small"
                  startIcon={<AccountBalanceWallet />}
                  onClick={() => {
                    setShowInvoice(true);
                  }}
                  style={{
                    background: colors.softGreen,
                  }}
                >
                  Create Invoice
                </Button>
              </InputContainer>
            ) : null}
          </LeftActions>
          <FlexRow>
            <InputContainer>
              <TextField
                value={
                  new Intl.NumberFormat("en-IE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    accountRowsWithBalance
                      .filter((i) => i.ChargeId)
                      .map((c) => c.Amount)
                      .reduce((sum, i) => sum + i, 0)
                  ) ?? ""
                }
                label="Total Cost"
                variant="outlined"
              />
            </InputContainer>
            <InputContainer color="green">
              <TextField
                value={
                  new Intl.NumberFormat("en-IE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    accountRowsWithBalance
                      .filter((i) => i.PaymentId)
                      .map((c) => c.Amount)
                      .reduce((sum, i) => sum + i, 0)
                  ) ?? ""
                }
                label="Total Paid"
                variant="outlined"
              />
            </InputContainer>
            <InputContainer color="red">
              <TextField
                value={
                  new Intl.NumberFormat("en-IE", {
                    style: "currency",
                    currency: "EUR",
                  }).format(
                    accountRowsWithBalance
                      .filter((i) => i.ChargeId)
                      .map((c) => c.Amount)
                      .reduce((sum, i) => sum + i, 0) -
                      accountRowsWithBalance
                        .filter((i) => i.PaymentId)
                        .map((c) => c.Amount)
                        .reduce((sum, i) => sum + i, 0)
                  ) ?? ""
                }
                label="Total Owed"
                variant="outlined"
              />
            </InputContainer>
          </FlexRow>
        </FixedRow>
      </DialogContent>
      <AppointmentCharge
        show={showInvoice}
        isAppointment={false}
        invoiceData={{
          id: patient?.Patient?.User.id,
          PatientId: selectedPatient.PatientId,
          Proceedures: chargesRows
            .filter((c) => chargesSelected.includes(c.id))
            .map((c) => ({
              ...c,
              Data: {
                val: { ...c.Data },
              },
              Price: c.Amount,
              Treatment: {
                Name: treatments.data.find(
                  (t) => t.data.TreatmentId === c.TreatmentId
                )?.data.Name,
                Code: treatments.data.find(
                  (t) => t.data.TreatmentId === c.TreatmentId
                )?.data.Code,
              },
            })),
          Payments: paymentRows
            .filter((p) => paymentsSelected.includes(p.id))
            .map((p) => ({
              ...p,
              Price: p.Amount,
              isPay: true,
            })),
        }}
        selectedUser={patient}
        selectedPractice={patient?.Practice}
        close={(hide = true) => {
          if (hide) setShowInvoice(false);
          loadData(selectedPatient);
          setPaymentsSelected([]);
          setChargesSelected([]);
        }}
        setList={setTreatmentList}
      ></AppointmentCharge>
      <TreatmentListDrawer
        open={!!treatmentList}
        status={2}
        list={treatments.data}
        closeList={() => setTreatmentList(false)}
        addProceedure={async (proceedures) => {
          const data = proceedures.map((treatment) => ({
            AppointmentId: { val: appointmentData.AppointmentId },
            TreatmentId: { val: treatment.data.TreatmentId },
            PatientId: { val: appointmentData.PatientId },
            Data: {
              val: {
                quantity: treatment.qty,
                status: treatment.status,
              },
            },
            Price: { val: treatment.data.Price * treatment.qty },
          }));

          const response = await addProceedure(
            ADMIN_NS,
            data,
            appointmentData.AppointmentId,
            appointmentData.PatientId
          );

          if (response?.code === "SUCCESS")
            await updateLegacyRow(
              "appointments",
              appointments.data.map((a) =>
                a.AppointmentId === response.data.AppointmentId
                  ? {
                      ...a.data,
                      Proceedures: [
                        ...(a?.data.Proceedures || []),
                        ...response.data,
                      ],
                    }
                  : a
              )
            )(dispatch);

          setTreatmentList(false);
          setOverlayData(null);
          setDateOverlay(null);
        }}
      />
      <ConfirmModal
        showModal={deleteItem}
        hideModal={() => setDeleteItem(false)}
        updating={updating}
        continueClick={async () => {
          setUpdating(true);
          if (deleteItem.ChargeId) {
            await deleteCharge(ADMIN_NS, deleteItem.ChargeId);
          }
          if (deleteItem.PaymentId) {
            await deleteNoChargePayment(ADMIN_NS, deleteItem.PaymentId);
          }
          setDeleteItem(false);
          dispatch({
            type: "LOAD_ONE",
            context: "patient",
            data: {
              ...patient,
            },
            updating: false,
          });
          setUpdating(false);
        }}
      />
    </Dialog>
  );
};

export default Accounts;
