import React, { createRef, useState, useEffect } from "react";
import { DynamicPopOut } from "../common";
import { RightTopIcon } from "../../layouts/standard/styles";
import CloseCircleFeaIcon from "../../icon/fea-icons/close-circle";
import colors from "../../library/styled-components/colors";

const PopOut = ({ close, overlayData, children }) => {
  const overlayComponentRef = createRef();
  const [overlayPosition, setOverlayPosition] = useState({
    x: overlayData.x,
    y: overlayData.y,
  });

  useEffect(() => {
    if (!overlayComponentRef.current) return;
    const overlayBox = overlayComponentRef.current.getBoundingClientRect();
    let position = overlayPosition;
    if (overlayBox.top < 0) {
      console.log("Top is out of viewport");
    }

    if (overlayBox.left < 0) {
      console.log("Left side is out of viewoprt");
    }

    if (overlayBox.bottom > window.innerHeight) {
      console.log("Bottom is out of viewport");
      position = {
        ...position,
        y: position.y - overlayBox.height,
      };
    }

    if (overlayBox.right > window.innerWidth - overlayData.height) {
      console.log("Right side is out of viewport");
      position = {
        ...position,
        x: position.x - overlayBox.width,
      };
    }
    setOverlayPosition(position);
  }, [overlayData]);

  return (
    <DynamicPopOut ref={overlayComponentRef} pos={overlayPosition}>
      <RightTopIcon active={true}>
        <CloseCircleFeaIcon
          onClick={() => close()}
          color={colors.primary}
          background={colors.white}
          size="28"
        />
      </RightTopIcon>
      {children}
    </DynamicPopOut>
  );
};

export default PopOut;
