import { getAppointments } from "../../../../actions/appointments";
import { getPatients } from "../../../../actions/patients";
import { getSlots } from "../../../../actions/public";
import { getPublicPractitioners } from "../../../../actions/practitioners";
import { PUBLIC_NS } from "../../../../config";
import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";

dayjs.extend(isoWeek);

const loadAppointments = () => async (dispatch) => {
  const { code, data } = await getAppointments(PUBLIC_NS, from, to);
  data &&
    code === "SUCCESS" &&
    dispatch({
      type: "LOAD",
      context: "appointments",
      data,
      updating: false,
    });
};

const loadPatients = () => async (dispatch, navigateTo) => {
  const { code, data } = await getPatients(PUBLIC_NS);
  data &&
    code === "SUCCESS" &&
    dispatch({
      type: "LOAD",
      context: "patients",
      data,
      updating: false,
    });
};

export const fetchSlots =
  (date, practiceId) => async (dispatch, navigateTo) => {
    const slots = await getSlots(practiceId, {
      date: {
        start: date.data.format("YYYY-MM-DD"),
        end: date.data.clone().add(7, "day").format("YYYY-MM-DD"),
        // day: date.data.clone().add(1, "day").isoWeekday(),
      },
    });

    dispatch({
      type: "LOAD",
      context: "schedules",
      data: slots && slots.data ? slots.data : [],
      updating: false,
    });
  };

export const fetchSchedules = (now, practiceId) => async (dispatch) => {
  const slots = await getSlots(practiceId, {
    date: {
      start: now.format("YYYY-MM-DD"),
      end: now.clone().add(7, "day").format("YYYY-MM-DD"),
    },
  });

  dispatch({
    type: "LOAD",
    context: "schedules",
    data: slots && slots.data ? slots.data : [],
    updating: false,
  });

  const date =
    slots && slots.data.length > 0 && dayjs().isoWeekday(slots.data[0].Day);

  const firstdate = date && date < dayjs() ? date.add(1, "week") : dayjs();

  dispatch({
    type: "REPLACE",
    context: "date",
    data: firstdate,
    updating: false,
  });

  dispatch({
    type: "REPLACE",
    context: "firstdate",
    data: firstdate.clone(),
    updating: false,
  });
};

export const fetchNewWeekSlots = (date, practiceId) => async (dispatch) => {
  const slots = await getSlots(practiceId, {
    date: {
      start: date.data.format("YYYY-MM-DD"),
      end: date.data.clone().add(7, "day").format("YYYY-MM-DD"),
      // day: date.data.clone().add(1, "day").isoWeekday(),
    },
  });

  dispatch({
    type: "LOAD",
    context: "schedules",
    data: slots && slots.data ? slots.data : [],
    updating: false,
  });

  const slotDate =
    slots && slots.data.length > 0 && dayjs().isoWeekday(slots.data[0].Day);

  const firstdate =
    slotDate && slotDate < date.data ? slotDate.add(1, "week") : date.data;

  dispatch({
    type: "REPLACE",
    context: "date",
    data: firstdate,
    updating: false,
  });

  dispatch({
    type: "REPLACE",
    context: "firstdate",
    data: firstdate.clone(),
    updating: false,
  });
};

const getPractitionerList = async (uuid) => await getPublicPractitioners(uuid);

export const loadPractitioners =
  (practiceId) => async (dispatch, navigateTo) => {
    const practitioners = await getPractitionerList(practiceId);

    dispatch({
      type: "LOAD",
      context: "practitioners",
      data:
        practitioners && practitioners.practitioners
          ? practitioners.practitioners
          : [],
      updating: false,
    });
  };

export const selectSlot = (uuid) => async (dispatch) => {
  dispatch({
    type: "UPDATE",
    context: "slot",
    data: uuid,
  });
};

export const fetchData = () => async (dispatch, navigateTo) => {
  await loadAppointments()(dispatch, navigateTo);
  await loadPatients()(dispatch, navigateTo);
};
