import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Step,
  StepLabel,
  Stepper,
  LinearProgress,
} from "@mui/material";
import PropTypes from "prop-types";
import styled from "@mui/material/styles/styled";
import EventIcon from "@mui/icons-material/Event";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ScheduleSendIcon from "@mui/icons-material/ScheduleSend";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Check from "@mui/icons-material/Check";
import colors from "library/styled-components/colors";
import { sendBatchSms } from "../../../../../actions/sms";
import { ADMIN_NS } from "../../../../../config";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import SelectDateStep from "./SelectDateStep";
import GenerateRecallStep from "./GenerateRecallStep";
import { addMinutes, startOfMinute } from "date-fns";
import SuccessStep from "./SuccessStep";

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      //green linear-gradient( 95deg,rgb(39,226,116) 0%,rgb(23, 162, 184) 50%,rgb(23, 162, 184) 100%)
      backgroundImage:
        "linear-gradient( 95deg,rgb(39,226,116) 0%,rgb(23, 162, 184) 50%,rgb(23, 162, 184) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 95deg,rgb(39,226,116) 0%,rgb(23, 162, 184) 50%,rgb(23, 162, 184) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(39,226,116) 0%, rgb(23, 162, 184) 50%, rgb(23, 162, 184) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, rgb(39,226,116) 0%, rgb(23, 162, 184) 50%, rgb(23, 162, 184) 100%)",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <GroupAddIcon />,
    2: <EventIcon />,
    3: <ScheduleSendIcon />,
    4: <CheckCircleOutlineIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = [
  "Select Patients",
  "Choose send date",
  "Generate Recall",
  "Complete",
];

const RecallWizard = ({ open, close, complete, filteredList }) => {
  console.log(filteredList);
  const [recallsSending, setRecallsSending] = React.useState(0);
  const [activeStep, setActiveStep] = React.useState(1);
  const [sendDate, setSendDate] = React.useState(
    addMinutes(
      startOfMinute(new Date()),
      Math.ceil((new Date().getMinutes() + 1) / 5) * 5 - new Date().getMinutes()
    )
  );

  return (
    <>
      <Dialog
        open={open}
        onClose={() => close()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <>
          <DialogContent>
            <Stack sx={{ width: "100%" }} spacing={4}>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>

            {activeStep === 1 && (
              <SelectDateStep sendDate={sendDate} setSendDate={setSendDate} />
            )}

            {activeStep === 2 && (
              <GenerateRecallStep
                sendDate={sendDate}
                patientTotal={filteredList.length}
              />
            )}

            {activeStep === 3 && (
              <SuccessStep
                completeWizard={complete}
              />
            )}
          </DialogContent>
          <DialogActions>
            {activeStep !== 3 && (
              <Button
                onClick={() => {
                  if (activeStep === 1) close();
                  else setActiveStep(activeStep - 1);
                }}
              >
                Go Back
              </Button>
            )}
            {activeStep === 1 && (
              <Button
                onClick={() => {
                  setActiveStep(2);
                }}
                autoFocus
                style={{
                  background: colors.accentColor,
                  color: colors.white,
                  borderColor: colors.accentColor,
                }}
                size="small"
                startIcon={<ScheduleSendIcon />}
              >
                Preview
              </Button>
            )}
            {activeStep === 2 && (
              <Button
                onClick={async () => {
                  try {
                    setRecallsSending(10);
                    await sendBatchSms(
                      ADMIN_NS,
                      filteredList.map((p) => ({
                        PatientId: p.data?.PatientId,
                        UserId: p.data?.UserId,
                        Phone: p.data?.Phone,
                        AppointmentId: null,
                        SendDate: sendDate,
                        Message: "batch-recall",
                      }))
                    );
                    setActiveStep(3);
                    setRecallsSending(100);
                  } catch (err) {
                    setRecallsSending(0);
                    console.log(err);
                  }
                }}
                autoFocus
                variant="outlined"
                size="small"
                startIcon={<ScheduleSendIcon />}
                style={{
                  background: colors.primary,
                  color: colors.white,
                  borderColor: colors.primary,
                }}
              >
                Generate Recalls
              </Button>
            )}
          </DialogActions>
          {recallsSending > 0 && recallsSending < 100 && (
            <LinearProgress
              variant="determinate"
              value={recallsSending}
              style={{ width: "100%" }}
            />
          )}
        </>
      </Dialog>
    </>
  );
};

export default RecallWizard;
