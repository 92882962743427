import { ADMIN_NS } from "config";
import localforage from "localforage";

export const checkSynced = async () => !!(await localforage.getItem("list"));

export const getList =
  (context, method, id = false, force = false) =>
  async (dispatch) => {
    let storeState = await localforage.getItem(context);
    if (!storeState || force) {
      const { code, data } = await method(ADMIN_NS, id);
      code === "SUCCESS" && localforage.setItem(context, data);
      storeState = data;
    }
    dispatch({
      type: "LOAD",
      context,
      data: storeState,
    });
  };

export const updateList =
  (context, method, id = false) =>
  async (dispatch) => {
    let storeState = await localforage.getItem(context);
    const response = await method(ADMIN_NS, id);

    if (response?.code === "SUCCESS") {
      // Create a Map object from the current storeState
      const stateMap = storeState
        ? new Map(storeState.map((item) => [item.id, item]))
        : new Map();

      // Wait for all asynchronous operations to finish
      await Promise.all(
        response?.data?.map(async (newItem) => {
          stateMap.set(newItem.id, newItem);
        })
      );

      // Convert the Map back to an array
      const updatedState = Array.from(stateMap.values());

      await localforage.setItem(context, updatedState);

      dispatch({
        type: "LOAD",
        context,
        data: updatedState.filter((d) => d.deleted !== true),
      });

      return response?.data;
    } else return null;
  };

export const updateListFromCache = (context) => async (dispatch) => {
  let storeState = await localforage.getItem(context);
  dispatch({
    type: "LOAD",
    context,
    data: storeState?.filter((d) => d.deleted !== true),
  });
};

export const updateObjectFromCache = (context) => async (dispatch) => {
  let storeState = await localforage.getItem(context);
  dispatch({
    type: "LOAD_ONE",
    context,
    data: storeState,
  });
};

export const addToList =
  (context, method, id = false) =>
  async (dispatch) => {
    let storeState = await localforage.getItem(context);

    const { code, data } = await method(ADMIN_NS, id);
    code === "SUCCESS" && localforage.setItem(context, data);

    dispatch({
      type: "LOAD",
      context,
      data: [...(storeState || []), ...data],
    });
  };

export const clearItem = async (context) => localforage.removeItem(context);

export const getItem =
  (context, method, id = false) =>
  async (dispatch) => {
    let storeState = await localforage.getItem(context);
    if (!storeState) {
      const { code, data } = await method(ADMIN_NS, id);
      code === "SUCCESS" && localforage.setItem(context, data);
      storeState = data;
    }
    dispatch({
      type: "REPLACE",
      context,
      data: storeState,
    });
  };

export const updateItem = (context, data) => async (dispatch) => {
  await localforage.setItem(context, data);
  dispatch({
    type: "LOAD_ONE",
    context: context,
    data,
  });
};

export const updateLegacyRow = (context, data) => async (dispatch) => {
  await localforage.setItem(context, data);
  dispatch({
    type: "LOAD_ONE",
    context: context,
    data: data.map((data) => ({
      data,
    })),
  });
};

export const addRow = (context, data) => async (dispatch) => {
  let storeState = await localforage.getItem(context);
  const update = await localforage.setItem(context, [...storeState, data]);
  dispatch({
    type: "LOAD",
    context,
    data: update,
  });
};
