import React, { useState, useMemo, useEffect } from "react";
import SearchInput from "../search-input";
import DoubleBounce from "../loaders/double-bounce";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import { format } from "date-fns";
import { getAppointmentsBySearchStr } from "actions/appointments";
import { updateList } from "library/resources";
import { AppContext } from "hooks/context";
import { useContext } from "react";
import { ADMIN_NS } from "config";
import { LinearProgress } from "@mui/material";

export const Panel = styled.div`
  display: grid;
  background: white;
  position: absolute;
  z-index: 104;
  border: 1px solid ${colors.lightGrey};
  left: ${(props) => props.left || 0};
`;

const Search = styled.div`
  display: grid;
  margin: 1rem 1rem 0 1rem;
`;

const StyledList = styled(List)`
  overflow: ${(props) => (props.disableScroll ? "hidden" : "auto")};
  height: 20rem;
`;

const Row = ({ item, selectedUser, setSelectedUser }) => {
  if (!item) return <div></div>;
  if (!item.Patient.User.Name) return <div></div>;

  // Get current date at start of day
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  return (
    <div
      style={{
        listStyle: "none",
        backgroundColor:
          new Date(item.Start) < today ? colors.lightGrey : "white",
      }}
    >
      <Divider component="li" />

      <ListItem
        component="nav"
        button
        onClick={() => setSelectedUser(item)}
        selected={selectedUser && selectedUser.UserId === item.UserId}
        alignitems="flex-start"
      >
        <ListItemAvatar>
          <Avatar alt={item.Patient.User.Name}>
            {item.Patient.User.Name.split(" ")
              .map((i) => i.charAt(0).toUpperCase())
              .slice(0, 2)}
          </Avatar>
        </ListItemAvatar>

        <ListItemText
          style={{ width: "13rem" }}
          primary={
            <Typography color="primary">{item.Patient.User.Name}</Typography>
          }
          secondary={
            <React.Fragment>
              <span>{item.Patient.User.Phone}</span>
              <br />
              {format(new Date(item.Start), "dd MMM yyyy, hh:mm a")}
            </React.Fragment>
          }
        />
        <Divider
          style={{ marginRight: "1rem" }}
          orientation="vertical"
          flexItem
        />
        <ListItemText
          style={{ marginRight: "1rem" }}
          primary={
            <Typography color="default">
              {item.Practitioner && item.Practitioner.Name}
            </Typography>
          }
          secondary={
            <React.Fragment>
              {item.Treatment && item.Treatment.Name}
            </React.Fragment>
          }
        />
      </ListItem>
    </div>
  );
};

const AppointmentSearch = ({
  onFocus,
  open,
  dim,
  placeholder = "Search",
  options,
  selectedUser,
  loading,
  setSelectedUser,
}) => {
  const { dispatch } = useContext(AppContext);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [loadedCount, setLoadedCount] = useState(0);
  const [loader, setLoader] = useState(false);
  const itemsPerPage = 5;

  async function fetchOldAppointments(searchStr, start, end) {
    if (loader) return;
    setLoader(true);
    const data = await updateList("appointments", () =>
      getAppointmentsBySearchStr(ADMIN_NS, searchStr, start, end)
    )(dispatch);

    if (data?.length === 10) {
      setLoadedCount(0);
    }
    setLoader(false);
  }

  const itemSearchData = useMemo(
    () =>
      options &&
      options.filter((item) => {
        const str =
          item.Patient &&
          item.Patient.User &&
          item.Patient.User.Name.toLowerCase() +
            " " +
            item.Patient.User.Phone.toLowerCase();
        return str && str.search(search.toLowerCase()) >= 0;
      }),
    [options, search]
  );

  const itemData = useMemo(
    () => itemSearchData && itemSearchData.slice(0, page * itemsPerPage),
    [itemSearchData, page]
  );
 
  useEffect(() => {
    if (itemData.length === itemSearchData.length && loadedCount === 0) {
      fetchOldAppointments(search.toLowerCase(), itemSearchData.length, 10);
      setLoadedCount(1);
    }
  }, [itemData.length, itemSearchData.length, loadedCount]);

  useEffect(() => {
    const list = document.getElementById("myList");
    if (!list) {
      return;
    }

    const scrollHandler = () => {
      const { scrollTop, scrollHeight, clientHeight } = list;
      const bottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;

      if (bottom && !loadedCount && loader === false) {
        setPage((prev) => prev + 1);
      }
    };

    list.addEventListener("scroll", scrollHandler);

    return () => {
      list.removeEventListener("scroll", scrollHandler);
    };
  }, []);

  return (
    <Panel left={dim?.left}>
      <Search>
        <SearchInput
          autoFocus={!selectedUser}
          search={search}
          placeholder={placeholder}
          setSearch={(str) => {
            setSearch(str);
            setPage(1);
            setLoadedCount(0);
            setLoader(false);
          }}
          onFocus={onFocus}
        />
      </Search>
      {loading && <DoubleBounce color={colors.primary} margin="20%" />}
      {open && (
        <StyledList id="myList" disablescroll={loader.toString()}>
          {itemData.map((item, index) => (
            <Row
              key={item.id} // assuming item has an id property
              item={item}
              selectedUser={selectedUser}
              setSelectedUser={setSelectedUser}
            />
          ))}
        </StyledList>
      )}
      {loader ? <LinearProgress /> : null}
    </Panel>
  );
};

export default AppointmentSearch;
