import moment from "moment";
// import store from "store";

const { schedules, appointment, user, slot } =  {
  slot: { data: null, meta: { updating: false } },
  schedules: { data: [], meta: { updating: true } },
  appointment: { data: null, meta: { updating: false } },
  user: { data: null, meta: { updating: false } }
};

export const initialState = {
  date: { data: moment(), meta: { updating: false } },
  schedules: { ...schedules, meta: { updating: false } },
  appointment: { ...appointment, meta: { updating: false } },
  user: { ...user, meta: { updating: false } },
  slot: { ...slot, meta: { updating: false } }
};

export function reducer(state, action) {
  let saveState = null;
  switch (action.type) {
    case "REPLACE":
      saveState = {
        ...state,
        [action.context]: {
          data: action.data,
          meta: { updating: action.updating }
        }
      };
      //store.set("state", saveState);
      return saveState;
    case "LOAD":
      const data = action.data.map(data => ({
        data,
        meta: { updating: false }
      }));
      saveState = {
        ...state,
        [action.context]: { data, meta: { updating: false } }
      };
      //store.set("state", saveState);
      return saveState;
    case "ADD":
      saveState = {
        ...state,
        [action.context]: {
          ...state[action.context],
          data: action.data,
          meta: { updating: false }
        }
      };
      //store.set("state", saveState);
      return saveState;
    case "UPDATE":
      saveState = {
        ...state,
        [action.context]: {
          ...state[action.context],
          data: action.data ? action.data : state[action.context].data,
          meta: action.meta ? action.meta : state[action.context].meta
        }
      };
      //store.set("state", saveState);
      return saveState;
    default:
      return state;
  }
}
