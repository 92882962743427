import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import bgimg from "./img/background-graphic.png";
import bgimgInv from "./img/background-graphic-inv.png";

const Container = styled.div`
  position: relative;
  height: 180px;
`;

const Wrapper = styled.div`
  z-index: 2;
  width: 400px;
  height: 160px;
  position: absolute;
  background: url(${(props) => (props.inverse ? bgimgInv : bgimg)}) repeat-x;
`;

const Tooth = styled.div`
  z-index: 1;
  background: url(${(props) => props.src.reg});
  background-repeat: no-repeat;
  background-size: auto;
  width: ${(props) => props.width};
  background-position: ${(props) => props.pos};
`;

const ToothImplant = styled.div`
  z-index: 1;
  background: url(${(props) => props.src.implant});
  background-repeat: no-repeat;
  background-size: auto;
  width: ${(props) => props.width};
  background-position: ${(props) => props.pos};
`;

const ToothRemoved = styled.div`
  z-index: 1;
  background: url(${(props) => props.src.removed});
  background-repeat: no-repeat;
  background-size: auto;
  width: ${(props) => props.width};
  background-position: ${(props) => props.pos};
`;

const ToothSvg = styled.svg`
  position: absolute;
  z-index: 2;
  left: 0;
  height: 160px;
`;

const ToothSvgMarginPolyline = styled.polyline`
  stroke: #ff5722;
  stroke-width: 2px;
  stroke-opacity: 1;
  fill: none;
  position: relative;
`;

const ToothSvgDepthPolyline = styled.polyline`
  stroke: #0276aa;
  stroke-width: 2px;
  stroke-opacity: 1;
  fill: none;
  position: relative;
  z-index: 505;
`;

const ToothSvgPolygon = styled.polygon`
  stroke: none;
  fill: #0276aa;
  fill-opacity: 0.5;
  position: relative;
`;

const DotBorder = styled.circle`
  display: ${(props) => (props.selected ? "block" : "none")};
`;

const Dot = styled.circle`
  opacity: ${(props) => (props.selected ? 1 : 0)};
  &:hover {
    opacity: 1;
  }
`;

const CircleDot = styled.circle`
  display: ${(props) => (props.selected ? "block" : "none")};
`;

const HalfCircle = styled.svg`
  display: ${(props) => (props.selected ? "block" : "none")};
`;

const Circle = styled.circle`
  opacity: ${(props) => (props.selected ? 1 : 0)};
  &:hover {
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  position: absolute;
  z-index: 1;
  display: grid;
  grid-template-columns: ${(props) => props.grid};
  width: 400px;
  height: 160px;
`;

const PositionWrapper = styled.div`
  position: relative;
`;

const switchTooth = (img, i) => {
  if (img.removed) return <ToothRemoved key={i} {...img}></ToothRemoved>;
  else if (img.implant) return <ToothImplant key={i} {...img}></ToothImplant>;
  else return <Tooth key={i} {...img}></Tooth>;
};

let curPos = null;

const TeethBlock = ({
  inverse,
  margin,
  depth,
  updateSettings,
  imgState,
  imgs,
  offset = 97,
}) => {
  const [selected, setSelected] = useState(null);
  const [tmpPos, setTmpPos] = useState(null);

  const svgRef = useRef(null);
  useEffect(() => {
    svgRef.current.addEventListener("mousedown", startDrag);
    svgRef.current.addEventListener("mousemove", drag);
    svgRef.current.addEventListener("mouseup", endDrag);
    svgRef.current.addEventListener("mouseleave", () => setSelected(null));
    let active = false;
    function startDrag(e) {
      active = true;
    }
    function drag(e) {
      if (active && selected !== null) {
        let rect = svgRef.current.getBoundingClientRect();
        let x = e.clientX - rect.left; //x position within the element.
        let y = e.clientY - rect.top; //y position within the element.
        if (Math.floor(y) > 0) {
          setTmpPos({ x, y: Math.floor(y) });
          curPos = Math.floor(y);
        }
      }
    }

    function endDrag(e) {
      const reverse = offset === 63;
      const snapPos = offset + Math.ceil((curPos - offset) / 6) * 6;
      const newMargin = margin;
      const newDepth = depth;

      if (
        curPos &&
        selected?.type === "margin" &&
        (reverse
          ? depth[selected.i][1] >= curPos
          : depth[selected.i][1] <= curPos)
      ) {
        active = false;

        newMargin[selected.i] = [newMargin[selected.i][0], snapPos];
        //updateState({ depth, margin: newMargin, imgState });
        updateSettings({ depth, margin: newMargin, imgState });
      }

      if (
        curPos &&
        selected?.type === "depth" &&
        (reverse
          ? margin[selected.i][1] <= curPos
          : margin[selected.i][1] >= curPos)
      ) {
        newDepth[selected.i] = [newDepth[selected.i][0], snapPos];
        //updateState({ depth: newDepth, margin, imgState });
        updateSettings({ depth: newDepth, margin, imgState });
      }
      setTmpPos(null);
      curPos = null;
    }

    return () => {
      const cleanup = svgRef;
      if (cleanup?.current) {
        cleanup.current.removeEventListener("mousedown", startDrag);
        cleanup.current.removeEventListener("mousemove", drag);
        cleanup.current.removeEventListener("mouseup", endDrag);
        cleanup.current.removeEventListener("mouseleave", endDrag);
      }
    };
  }, [svgRef, selected]);

  const reversed = [...depth];
  const polygonPoints = [margin, reversed.reverse()].reduce(
    (a, b) => a.concat(b),
    []
  );

  return (
    <Container>
      <PositionWrapper>
        <Wrapper inverse={inverse}> </Wrapper>
        <ImageWrapper grid={imgs?.map((img) => img.width).join(" ")}>
          {imgState &&
            imgs?.map((img, i) => switchTooth({ ...img, ...imgState[i] }, i))}
        </ImageWrapper>

        <ToothSvg ref={svgRef} width="400" selected={selected}>
          <ToothSvgPolygon points={polygonPoints.toString()} />
          <ToothSvgMarginPolyline points={margin.toString()} />
          <ToothSvgDepthPolyline points={depth.toString()} />

          {imgState?.map(
            (img, i) =>
              img.furcation?.length &&
              !img.implant &&
              img.furcation.map((imgF, fi) => (
                <>
                  <Circle
                    selected={imgF.state === 0 || imgF.state === 1}
                    cx={imgF.pos[0]}
                    cy={imgF.pos[1]}
                    r="8"
                    stroke="#000"
                    strokeWidth="2"
                    fillOpacity="0"
                    fill="#c"
                    draggable="true"
                    onClick={() => {
                      let val = null;
                      switch (imgF.state) {
                        case null:
                          val = 0;
                          break;
                        case 0:
                          val = 1;
                          break;
                        case 1:
                          val = 2;
                          break;
                        default:
                          break;
                      }

                      updateSettings({
                        depth,
                        margin,
                        imgState: imgState.map((img, is) =>
                          i === is
                            ? {
                                ...img,
                                furcation: [
                                  ...img.furcation.filter((_, i) => i !== fi),
                                  { ...imgF, state: val },
                                ],
                              }
                            : img
                        ),
                      });
                    }}
                  ></Circle>

                  <HalfCircle
                    selected={imgF.state === 1}
                    x={imgF.pos[0] - 7.5}
                    y={imgF.pos[1] - 7.5}
                    onClick={() =>
                      updateSettings({
                        depth,
                        margin,
                        imgState: imgState.map((img, is) =>
                          i === is
                            ? {
                                ...img,
                                furcation: [
                                  ...img.furcation.filter((_, i) => i !== fi),
                                  { ...imgF, state: 2 },
                                ],
                              }
                            : img
                        ),
                      })
                    }
                  >
                    <path d="M7.5,15a7.5,7.5,0,1,1,0-15" />
                  </HalfCircle>

                  <CircleDot
                    selected={imgF.state === 2}
                    cx={imgF.pos[0]}
                    cy={imgF.pos[1]}
                    r="8"
                    stroke="none"
                    strokeWidth="0"
                    fill="#000"
                    onClick={() =>
                      updateSettings({
                        depth,
                        margin,
                        imgState: imgState.map((img, is) =>
                          i === is
                            ? {
                                ...img,
                                furcation: [
                                  ...img.furcation.filter((_, i) => i !== fi),
                                  { ...imgF, state: null },
                                ],
                              }
                            : img
                        ),
                      })
                    }
                  ></CircleDot>
                </>
              ))
          )}

          {margin.map((m, i) => (
            <>
              <DotBorder
                selected={selected?.type === "margin" && selected.i === i}
                cx={m[0]}
                cy={tmpPos?.y ? tmpPos.y : m[1]}
                r="6.5"
                stroke="#ff5722"
                strokeWidth="1"
                fillOpacity="0"
                fill="#ffffff"
                draggable="true"
              ></DotBorder>
              <Dot
                selected={selected?.type === "margin" && selected.i === i}
                cx={m[0]}
                cy={tmpPos?.y ? tmpPos.y : m[1]}
                r="4"
                stroke="none"
                strokeWidth="0"
                fill="#ff5722"
                onClick={() =>
                  setSelected(selected?.i === i ? null : { type: "margin", i })
                }
              ></Dot>
            </>
          ))}

          {depth.map((m, i) => (
            <>
              <DotBorder
                selected={selected?.type === "depth" && selected.i === i}
                cx="20"
                cx={m[0]}
                cy={tmpPos?.y ? tmpPos.y : m[1]}
                r="6.5"
                stroke="#0276aa"
                strokeWidth="1"
                fillOpacity="0"
                fill="#ffffff"
                draggable="true"
              ></DotBorder>
              <Dot
                selected={selected?.type === "depth" && selected.i === i}
                cx="20"
                cx={m[0]}
                cy={tmpPos?.y ? tmpPos.y : m[1]}
                r="4"
                stroke="none"
                strokeWidth="0"
                fill="#0276aa"
                onClick={() =>
                  setSelected(selected?.i === i ? null : { type: "depth", i })
                }
              ></Dot>
            </>
          ))}
        </ToothSvg>
      </PositionWrapper>
    </Container>
  );
};

export default TeethBlock;
