import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <g id="top2" transform="translate(-835.5 -87.579)">
      <g id="bounds" transform="translate(835.5 87.861)">
        <line
          id="bound"
          x2="70"
          transform="translate(0 245.718)"
          fill="none"
        ></line>
        <line id="bound-2" data-name="bound" x2="70" fill="none"></line>
      </g>
      <g id="surface" transform="translate(845.034 275.771)">
        <path
          id="shape"
          d="M10.979.506C-1.431,3.767.055,38.76.055,38.76,4.023,47.232,45.9,40.773,42.8,30.913,37.393,13.7,24.926-3.158,10.98.506Z"
          transform="matrix(-0.985, -0.174, 0.174, -0.985, 42.322, 49.521)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#707070"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M7.331.508C4.065,1.366,1.762,4.422.012,8.823-.362,9.757,8.223,21.2,13.967,20.157S23.9,5.383,22.988,4.635C18.484.941,13.031-.985,7.332.508Z"
          transform="matrix(-0.985, -0.174, 0.174, -0.985, 38.728, 48.887)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M11.2,30.937a13.5,13.5,0,0,0,3.983,1.415,13.511,13.511,0,0,0,3.666-2.558,4.2,4.2,0,0,0,1.06-3.83A63.647,63.647,0,0,0,11.554,8.277,64.186,64.186,0,0,0,4.092,0,54.772,54.772,0,0,1,1.524,7.8,24.86,24.86,0,0,1,.053,10.6C-.671,11.818,6.236,27.1,11.2,30.937Z"
          transform="matrix(-0.985, -0.174, 0.174, -0.985, 20.631, 40.663)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M3.618,0A79.611,79.611,0,0,0,.653,13.769,124.966,124.966,0,0,0,.045,29.9,5.237,5.237,0,0,0,1.9,31.757c1.267.863,5.072,1.57,5.072,1.57a6.064,6.064,0,0,0,1.151-1.309c3.151-5.891,2.9-23.13,2.018-23.9A29.806,29.806,0,0,1,7.376,5.386,34.176,34.176,0,0,1,3.618,0Z"
          transform="matrix(-0.985, -0.174, 0.174, -0.985, 43.854, 40.787)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M15.706,6.09C11.238,7.264,3.133,4.774,3.133,4.774a32.069,32.069,0,0,1-1.2,5.151A6.882,6.882,0,0,1,0,12.894a43.264,43.264,0,0,0,7.337.489,74.229,74.229,0,0,0,9.489-.934A59.952,59.952,0,0,0,27.126,9.9a27.929,27.929,0,0,0,4.638-2.169,6.957,6.957,0,0,1-5.294-1.57A27.393,27.393,0,0,1,21.959,0s-2.138,5-6.252,6.086Z"
          transform="matrix(-0.985, -0.174, 0.174, -0.985, 40.635, 19.475)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M14.323,16.446s1.1,3.3,2,6.759a60.978,60.978,0,0,1,1.334,7.252s3.14-3.691,6.208-3.423,6.353,4.206,6.353,4.206a74.412,74.412,0,0,1,1.1-8.47A70.577,70.577,0,0,1,33.5,14.676S25.809,10.8,20.907,11.312A8.278,8.278,0,0,0,14.323,16.446Z"
          transform="translate(4.902 -0.404)"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown" transform="translate(849.144 193.658)">
        <path
          id="crown-2"
          data-name="crown"
          d="M36.9,3.723S37.83,22.8,40.619,36.05,36.59,52.134,30.856,54.184,1.877,49.611.173,39.2,6.389,5.451,6.389,5.451s1.68-3.575,2.683-3.907C11.789.642,15.766-.3,22.649.287A60.433,60.433,0,0,1,36.9,3.723Z"
          transform="translate(0.067 -0.105)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root" transform="translate(856.411 103.674)">
        <path
          id="rb1"
          d="M1391.27,254.934s.05.043,0-.562c-.447-5.37-5.266-62.715-6.667-78.028-1.475-16.119-8.892-12.955-8.892-12.955s-5.693-10.2-7.986,14.5c-2.1,22.644-6.353,77.546-6.353,77.546s.6-1.355,3.848-2.037a37.74,37.74,0,0,1,9.762-.76,87.858,87.858,0,0,1,16.288,2.3Z"
          transform="translate(-1361.576 -161.302)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M1369.22,172.125c-.489-11.6-3.806-15.95-5.2-15.672s.367-.985-.394,16.784c-.7,16.293-2.78,55.024-1.7,54.534s6.82-.123,7.906,0C1372.34,227.882,1369.7,183.722,1369.22,172.125Z"
          transform="translate(-1351.648 -146.645)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(851.247 87.579)">
          <g id="abscess" transform="translate(10.253)">
            <g id="p1" fill="none" stroke="#000" stroke-width="4">
              <circle cx="10" cy="10" r="10" stroke="none"></circle>
              <circle cx="10" cy="10" r="8" fill="none"></circle>
            </g>
          </g>
          <g id="diastema" transform="translate(39.919 145.504)">
            <path
              id="p2"
              d="M1178.17,630.863V578.089"
              transform="translate(-1178.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M1178.17,630.863V578.089"
              transform="translate(-1168.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g
            id="cracked"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 14.69, 138.708)"
          >
            <path
              id="p1-3"
              data-name="p1"
              d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
              fill="none"
              stroke="#000"
              stroke-linecap="square"
              stroke-width="5"
            ></path>
          </g>
          <g id="recession" transform="translate(0 105.133)">
            <path
              id="p3"
              d="M1116.25,689.872s4.063-7.329,17.079-7.329a53.058,53.058,0,0,1,23.58,5.991"
              transform="translate(-1116.247 -682.544)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
        </g>
      )}
      {props.proceedures && (
        <g id="procedures" transform="translate(835.5 103.488)">
          <g id="hyg" transform="translate(6.5 98.677)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc" transform="translate(27.4 90.177)">
            <path
              id="rctpc-2"
              data-name="rctpc"
              d="M587.271,20.356s3.448,28.128,2.747,29.4-11.216.7-12.045-.956,5.661-28.743,5.661-28.743Z"
              transform="translate(-577.9 -20.06)"
              fill="red"
            ></path>
          </g>
          <g id="rct" transform="translate(31.793)">
            <path
              id="rct-2"
              data-name="rct"
              d="M768.674,25.975s1.2-41.175-.042-67.575c-.64-12,2.312-10.361-1.134-21.871-.589-2.247.235-2.339.683-1.7,2.621,3.733,3.795,1.65,5.5,20.184,2.424,26.321-1.433,71.168-1.433,71.168l-2.554-.206Z"
              transform="translate(-767.293 65.514)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(15.451 5.71)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="implant" transform="translate(20.495 8.182)">
            <path
              id="p5"
              d="M806.184,381.864l-1.553,15.719s6.208,8.37,10.95,11.2,3.052,2.77,7.914,0,11.485-10.96,11.485-10.96l-.133-6.876Z"
              transform="translate(-804.63 -312.669)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.7,365.23l-1.823,10.682,27.12,8.6L832.449,373Z"
              transform="translate(-804.785 -311.524)"
              fill="red"
            ></path>
            <path
              id="p3-2"
              data-name="p3"
              d="M810.34,348.775l-1.51,10.675,23.281,7.444-1.27-11.536Z"
              transform="translate(-804.919 -310.392)"
              fill="red"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M812.1,332.484l-1.124,10.534,19.313,5.9-1.545-11.1Z"
              transform="translate(-805.067 -309.271)"
              fill="red"
            ></path>
            <path
              id="p1-4"
              data-name="p1"
              d="M828.272,331.155l-15.521-4.7s.492-19.875,8.041-18.857S828.272,331.155,828.272,331.155Z"
              transform="translate(-805.189 -307.555)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(0 120.191)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H70"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H70"
              transform="translate(0 80)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(24.333 56.236)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(15.747 -6.402)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-4"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-6"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
