import React, { useState, useMemo, useContext } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ImageIcon from "@mui/icons-material/CalendarToday";
import { format } from "date-fns";
import FeaPlusCircleIcon from "icon/fea-icons/plus-circle";
import { AppContext } from "hooks/context";
import CreateAppointment from "../preview/CreateAppointment";
import colors from "library/styled-components/colors";

export default function AppointmentsList({ change, selected }) {
  const { state } = useContext(AppContext);
  const [showPreview, setShowPreview] = useState(false);

  const patient = useMemo(() => state.patient?.data, [state.patient?.data]);
  return (
    <>
      <div
        style={{
          display: "flex",
        }}
      >
        <Button
          style={{
            width: "100%",
            margin: "4px",
            color: colors.white,
            background: colors.rgbGrey,
          }}
          variant="contained"
          onClick={() => {
            setShowPreview(true);
          }}
          startIcon={<FeaPlusCircleIcon size="32" />}
        >
          Create Appointment
        </Button>
      </div>
      <div>
        {patient?.Appointments.sort((a, b) => b.id - a.id).map((app) => (
          <ListItem
            button
            key={app.AppointmentId}
            selected={app.AppointmentId === selected}
            onClick={() => {
              change(app.AppointmentId);
            }}
          >
            <ListItemAvatar>
              <Avatar>
                <ImageIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={format(new Date(app.Start), "do MMMM yyyy")}
              secondary={patient.User.Name}
            />
          </ListItem>
        ))}
      </div>
      <CreateAppointment
        show={showPreview}
        handleClose={() => {
          setShowPreview(false);
        }}
      />
    </>
  );
}
