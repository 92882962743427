import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <g id="top1" transform="translate(-886.5 -84.265)">
      <g id="bounds" transform="translate(886.5 84.543)">
        <line
          id="bound"
          x2="70"
          transform="translate(0 245.722)"
          fill="none"
        ></line>
        <line id="bound-2" data-name="bound" x2="70" fill="none"></line>
      </g>
      <g id="surface" transform="translate(913.475 262.616) rotate(30)">
        <path
          id="shape"
          d="M8.9,1.586C20.032-4.449,40.871,7.867,44.957,19.508c5.863,16.7-35.341,37.378-42.325,22.71C-1.969,32.557-.933,6.916,8.9,1.586Z"
          transform="translate(45.526 47.274) rotate(180)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#717171"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M6.887,1.564c3.678-1.994,8.418-1.986,13.7-.516.848.234,4.333,15.242-3.468,19.606C9.954,24.664-.434,13.285.014,11.778,1.339,7.321,3.63,3.329,6.887,1.564Z"
          transform="translate(43.513 47.252) rotate(180)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M.467.036C9.835,2.8,19.436,10.636,22.116,18.27c1.366,3.891.177,8-2.7,12.05-.407.574-1.659,2.058-1.659,2.058S5.8,25.278,1.713,15.078C.474,11.984,1.4,10.462,1.282,6.567S-.9-.369.467.036Z"
          transform="translate(22.687 46.036) rotate(180)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M19.618,33.958c-.132.037-.265.072-.7.186-6.9,1.822-13.808,1.107-16.312-4.152C-.318,23.856-.967,11.274,1.615,1c.033-.137.271-1,.271-1A28.61,28.61,0,0,0,6.978,5.55c1.19,1.03,5.688,2.675,8.848,9.776S22.272,33.225,19.618,33.958Z"
          transform="translate(45.498 35.048) rotate(180)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M8.926,6.681C11.943,5.046,15.187,0,15.187,0l5.642,4.044a50.343,50.343,0,0,1-9.159,7.391A56.386,56.386,0,0,1,.462,16.352a14.358,14.358,0,0,0,.3-3.18A15.977,15.977,0,0,0,0,9.309,22.651,22.651,0,0,0,8.926,6.681Z"
          transform="translate(25.745 17.649) rotate(180)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M0,9.541A18.863,18.863,0,0,1,5.957,2.832C9.222.769,15.084,0,15.084,0S16.828,5.967,18.6,10.2a32.059,32.059,0,0,0,5.812,8.694s-4.613-2.455-7.9-.91-4.25,4.157-5.24,7.088a39,39,0,0,0-4.534-8.917A32.261,32.261,0,0,0,0,9.541Z"
          transform="translate(10.551 8.334)"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown" transform="translate(897.789 192.862)">
        <path
          id="crown-2"
          data-name="crown"
          d="M1571.82,569.007s4.878,24.457,7.215,35.725S1575.86,620.9,1564,620.849s-32.677-.2-32.677-12.933,4.069-35.42,9.625-39.094S1571.82,569.007,1571.82,569.007Z"
          transform="translate(-1531.323 -567.236)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root" transform="translate(904.95 100.593)">
        <path
          id="rb1"
          d="M1617.19,230.122s-.369-57.869-12.375-84.022c-3.89-8.474-5.7-10.17-7.843-10.33-3.5-.335-4.508,10.33-4.508,10.33l-2.24,30.284-6.473,56.3s.376-4.252,9.143-4.515S1617.19,230.122,1617.19,230.122Z"
          transform="translate(-1583.751 -135.762)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M1594.28,203.261c.754-1.554-.116-41.4-3.9-60.1-1.227-6.062-1.8-7.275-2.473-7.389-1.1-.24-1.422,7.389-1.422,7.389l-.706,21.663s-2.762,35.309-1.909,37.663c.341.942,2.477-.262,4.563,0C1591,202.81,1593.83,204.194,1594.28,203.261Z"
          transform="translate(-1572.4 -117.172)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.proceedures && (
        <g id="procedures" transform="translate(886.5 98.884)">
          <g id="hyg" transform="translate(6.5 99.967)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc" transform="translate(30.988 94.085)">
            <path
              id="rctpc-2"
              data-name="rctpc"
              d="M587.289,20.16s3.625,26.7,3.06,28.172-12.256.943-12.821,0,5.066-28.541,5.066-28.541Z"
              transform="translate(-577.488 -19.791)"
              fill="red"
            ></path>
          </g>
          <g id="rct" transform="translate(30.641 1.413)">
            <path
              id="rct-2"
              data-name="rct"
              d="M767.538,26.486s3.08-39.682,1.608-62.7-7-30.2-7-30.2,1.205-.644,1.653,0c2.621,3.751,6.831,13.887,8.538,32.507,2.424,26.449-.082,60.6-.082,60.6l-2.554-.207Z"
              transform="translate(-762.141 66.703)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(15.451)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="implant" transform="translate(20.495 10.472)">
            <path
              id="p5"
              d="M806.184,381.864l-1.553,15.719s6.208,8.37,10.95,11.2,3.052,2.77,7.914,0,11.485-10.96,11.485-10.96l-.133-6.876Z"
              transform="translate(-804.63 -312.669)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.7,365.23l-1.823,10.682,27.12,8.6L832.449,373Z"
              transform="translate(-804.785 -311.524)"
              fill="red"
            ></path>
            <path
              id="p3"
              d="M810.34,348.775l-1.51,10.675,23.281,7.444-1.27-11.536Z"
              transform="translate(-804.919 -310.392)"
              fill="red"
            ></path>
            <path
              id="p2"
              d="M812.1,332.484l-1.124,10.534,19.313,5.9-1.545-11.1Z"
              transform="translate(-805.067 -309.271)"
              fill="red"
            ></path>
            <path
              id="p1"
              d="M828.272,331.155l-15.521-4.7s.492-19.875,8.041-18.857S828.272,331.155,828.272,331.155Z"
              transform="translate(-805.189 -307.555)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(0 121.338)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H70"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H70"
              transform="translate(0 80)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(23.333 57.526)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(15.747 -5.112)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-3"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(898.654 84.265)">
          <g id="abscess" transform="translate(8.846)">
            <g
              id="p1-4"
              data-name="p1"
              fill="none"
              stroke="#000"
              stroke-width="4"
            >
              <circle cx="10" cy="10" r="10" stroke="none"></circle>
              <circle cx="10" cy="10" r="8" fill="none"></circle>
            </g>
          </g>
          <g id="diastema" transform="translate(38.513 145.504)">
            <path
              id="p2-4"
              data-name="p2"
              d="M1178.17,630.863V578.089"
              transform="translate(-1178.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M1178.17,630.863V578.089"
              transform="translate(-1168.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g
            id="cracked"
            transform="matrix(0.94, 0.342, -0.342, 0.94, 12.283, 140.708)"
          >
            <path
              id="p1-6"
              data-name="p1"
              d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
              fill="none"
              stroke="#000"
              stroke-linecap="square"
              stroke-width="5"
            ></path>
          </g>
          <g id="recession" transform="translate(0 106.65)">
            <path
              id="p3-2"
              data-name="p3"
              d="M1111.65,693.68a37.019,37.019,0,0,1,22.037-7.62c13.017,0,26.55,8.5,26.55,8.5"
              transform="translate(-1111.654 -686.06)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
