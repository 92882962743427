import one from "./img/teeth/table6/38.png";
import oneImplant from "./img/teeth/table6/implants/38.png";
import oneRemoved from "./img/teeth/table6/removed/38.png";

import two from "./img/teeth/table6/37.png";
import twoImplant from "./img/teeth/table6/implants/37.png";
import twoRemoved from "./img/teeth/table6/removed/37.png";

import three from "./img/teeth/table6/36.png";
import threeImplant from "./img/teeth/table6/implants/36.png";
import threeRemoved from "./img/teeth/table6/removed/36.png";

import four from "./img/teeth/table6/35.png";
import fourImplant from "./img/teeth/table6/implants/35.png";
import fourRemoved from "./img/teeth/table6/removed/35.png";

import five from "./img/teeth/table6/34.png";
import fiveImplant from "./img/teeth/table6/implants/34.png";
import fiveRemoved from "./img/teeth/table6/removed/34.png";

import six from "./img/teeth/table6/33.png";
import sixImplant from "./img/teeth/table6/implants/33.png";
import sixRemoved from "./img/teeth/table6/removed/33.png";

import seven from "./img/teeth/table6/32.png";
import sevenImplant from "./img/teeth/table6/implants/32.png";
import sevenRemoved from "./img/teeth/table6/removed/32.png";

import eight from "./img/teeth/table6/31.png";
import eightImplant from "./img/teeth/table6/implants/31.png";
import eightRemoved from "./img/teeth/table6/removed/31.png";

export const sixSettings = {
  imgs: [
    {
      id: 28,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      }, 
      pos: "0 -5px",
      width: "66px",
    },
    {
      id: 27,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      }, 
      pos: "0 -5px",
      width: "62px",
    },
    {
      id: 26,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      }, 
      pos: "0 -1px",
      width: "66px",
    },
    {
      id: 25,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 2px",
      width: "44px",
    },
    {
      id: 24,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 2px",
      width: "42px",
    },
    {
      id: 23,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "0 6px",
      width: "40px",
    },
    {
      id: 22,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 2px",
      width: "35px",
    },
    {
      id: 21,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 1px",
      width: "35px",
    },
  ],
};

export const sixState = {
  margin: [
    [7, 97],
    [27.5, 97],
    [48, 97],

    [62, 97],
    [82.5, 97],
    [102, 97],

    [114.5, 97],
    [142.5, 97],
    [167.5, 97],

    [180, 97],
    [195, 97],
    [210, 97],

    [223, 97],
    [238, 97],
    [253, 97],

    [267.5, 97],
    [280, 97],
    [294, 97],

    [312.5, 97],
    [324, 97],
    [337, 97],

    [355, 97],
    [372, 97],
    [387.5, 97],
  ],
  depth: [
    [7, 97],
    [27.5, 97],
    [48, 97],
    [62, 97],

    [82.5, 97],
    [102, 97],

    [114.5, 97],
    [142.5, 97],
    [167.5, 97],

    [180, 97],
    [195, 97],
    [210, 97],

    [223, 97],
    [238, 97],
    [253, 97],

    [267.5, 97],
    [280, 97],
    [294, 97],

    [312.5, 97],
    [324, 97],
    [337, 97],

    [355, 97],
    [372, 97],
    [387.5, 97],
  ],
  imgState: [
    {
      id: 28,

      furcation: [{ state: null, pos: [26, 74] }],
    },
    {
      id: 27,

      furcation: [{ state: null, pos: [82, 74] }],
    },
    {
      id: 26,

      furcation: [{ state: null, pos: [142, 74] }],
    },
    {
      id: 25,
    },
    {
      id: 24,
    },
    {
      id: 23,
    },
    {
      id: 22,
    },
    {
      id: 21,
    },
  ],  
};
