import React from "react";
import styled from "styled-components";
import ToogleItem from "./toggle-item";
import colors from "../../../../library/styled-components/colors";

const Container = styled.div`
  margin-bottom: 1em;
  position: relative !important;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`;

const CheckGroupContiner = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  justify-content: center;
  border: 1px solid #bdc4c9;
  border-radius: 4px;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 6fr;
  margin-left: 0.5em;
`;

const Icon = styled.span`
  position: absolute;
  right: 14px;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
  color: ${colors.danger};
  line-height: 0.8rem;
  font-size: 0.8rem;
  margin: 1rem 0 0.2rem 0;
  font-weight: 400;
  text-align: right;
`;

const Scheduler = ({ onCallback, LabelData, group, icon }) => {
  return (
    <Container>
      <LabelContainer>
        <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
        {LabelData.error && (
          <ErrorMessage htmlFor={LabelData.text}>
            {LabelData.error}
          </ErrorMessage>
        )}
      </LabelContainer>
      <CheckGroupContiner>
        {group.map((element) => (
          <ToogleItem
            key={element.key}
            index={element.key}
            label={element.label}
            active={element.active}
            icon={icon}
            onCallback={onCallback}
          />
        ))}
      </CheckGroupContiner>
    </Container>
  );
};

export default Scheduler;
