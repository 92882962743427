import moment from "moment";
// import store from "store";
import { baseReducer } from "../../../../library/reducer";
import { PUBLIC_NS } from "../../../../config";

const namespace = PUBLIC_NS;

const { schedules, appointments, appointment, user, slot, patients } =  {
  slot: { data: null, meta: { updating: false } },
  schedules: { data: [], meta: { updating: true } },
  appointments: { data: null, meta: { updating: false } },
  appointment: { data: {}, meta: { updating: false } },
  user: { data: null, meta: { updating: false } },
  patients: { data: null, meta: { updating: false } }
};

export const initialState = {
  date: { data: moment(), meta: { updating: false } },
  schedules: { ...schedules, meta: { updating: false } },
  appointments: { ...appointments, meta: { updating: false } },
  appointment: { ...appointment, meta: { updating: false } },
  user: { ...user, meta: { updating: false } },
  slot: { ...slot, meta: { updating: false } },
  patients: { ...patients, meta: { updating: false } }
};
 

const reducer = baseReducer(namespace);

export { reducer };
