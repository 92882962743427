import { TextField, Box } from "@mui/material";
import { Autocomplete } from "@mui/lab";
import React from "react";
import isoCodes from "./iso-codes";

const defaultValue = {
  label: process.env.REACT_APP_DEFAULT_COUNTRY || "Ireland",
  phone: process.env.REACT_APP_DEFAULT_CODE || "353",
  code: process.env.REACT_APP_DEFAULT_ISO || "IE",
};
// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== "undefined"
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const transformDefaultValueToIsoCode = (defaultValue) => {
  const { label: country, phone: code, code: iso } = defaultValue;
  return { country, iso, code };
};

const defaultIsoCode = transformDefaultValueToIsoCode(defaultValue);

const PrefixList = ({ code, onChange }) => {
  return (
    <Autocomplete
      options={isoCodes}
      //autoHighlight
      //blurOnSelect
      onChange={onChange}
      getOptionLabel={(option) => option.country}
      value={code || defaultIsoCode}
      defaultValue={defaultIsoCode}
      renderOption={(props, option) => {
        return (
          <Box
            component="li"
            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
            {...props}
          >
            <span
              style={{
                marginRight: "0.5rem",
              }}
            >
              {countryToFlag(option.iso)}
            </span>
            <span
              style={{
                fontSize: "0.9rem",
              }}
            >
              {option.country} ({option.iso}) +{option.code}
            </span>
          </Box>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Country"
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default PrefixList;
