import React from "react";
import SelectCell from "./SelectCell";

const DepthBlock = ({ margins, depths, updateDepth, offset = 97 }) => {
  const marginVals = margins?.map((p) => (p ? (p[1] - offset) / 6 : 0));
  const values = depths?.map((d, i) =>
    Math.ceil(Math.abs((d ? (d[1] - offset) / 6 : 0) - marginVals[i]))
  );

  return (
    <SelectCell
      values={values}
      updateCell={(val, ui) => {
        //if (val < marginVals[ui]) return;
        const d = depths.find((_, i) => i === ui);
        updateDepth([d[0], Math.abs(val * 6 + d[1])]);
      }}
    />
  );
};

export default DepthBlock;
