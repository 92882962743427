import React, { useState } from "react";
import styled from "styled-components";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"; // choose your lib
import { TimePicker, LocalizationProvider } from "@mui/lab";
import {
  FormControl,
  Button,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  padding: 1rem;
  font-size: 0.9em;
  text-align: left;
`;

const PickerContainer = styled.div`
  position: relative !important;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

const SlotForm = ({
  id,
  start,
  hours,
  minutes,
  typeId,
  updateSlot,
  removeSlot,
  hideCal,
}) => {
  const [form, setForm] = useState({
    start,
    typeId: typeId || 1,
    hours: hours || 0,
    minutes: minutes || 30,
  });

  return (
    <FormContainer>
      <PickerContainer>
        <InputContainer>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <TimePicker
              variant="inline"
              label="Start Time"
              ampm={false}
              autoOk={true}
              LabelData="Start Time"
              inputVariant="outlined"
              disabled={false}
              inputAdornmentProps={{ position: "start" }}
              value={form.start}
              onChange={(start) => setForm({ ...form, start })}
            />
          </LocalizationProvider>
        </InputContainer>
      </PickerContainer>
      <Row>
        <FormControl variant="outlined">
          <InputContainer>
            <InputLabel>Hours</InputLabel>
            <Select
              value={form.hours}
              onChange={(e) =>
                setForm({ ...form, hours: e.target.value, minutes: 0 })
              }
              label="Booking"
            >
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
              <MenuItem value={4}>4</MenuItem>
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={6}>6</MenuItem>
              <MenuItem value={7}>7</MenuItem>
              <MenuItem value={8}>8</MenuItem>
              <MenuItem value={9}>9</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={11}>11</MenuItem>
              <MenuItem value={12}>12</MenuItem>
            </Select>
          </InputContainer>
        </FormControl>
        <FormControl variant="outlined">
          <InputContainer>
            <InputLabel>Minutes</InputLabel>
            <Select
              value={form.minutes}
              onChange={(e) => setForm({ ...form, minutes: e.target.value })}
              label="Booking"
            >
              {form.hours !== 0 && <MenuItem value={0}>0 min</MenuItem>}
              <MenuItem value={15}>15 min</MenuItem>
              <MenuItem value={30}>30 min</MenuItem>
              <MenuItem value={45}>45 min</MenuItem>
            </Select>
          </InputContainer>
        </FormControl>
      </Row>
      <FormControl variant="outlined">
        <InputContainer>
          <InputLabel>Booking</InputLabel>
          <Select
            value={form.typeId}
            onChange={(e) => setForm({ ...form, typeId: e.target.value })}
            label="Booking"
          >
            <MenuItem value={1}>Online Slot</MenuItem>
            {!hideCal && <MenuItem value={2}>Calendar</MenuItem>}
          </Select>
        </InputContainer>
      </FormControl>

      <Row>
        <FormControl>
          <Button
            onClick={() => updateSlot(form)}
            variant={id ? "outlined" : "contained"}
          >
            {id ? "Update" : "Create"}
          </Button>
        </FormControl>
        {id && (
          <FormControl>
            <Button
              onClick={() => removeSlot(id)}
              variant="contained"
              color="secondary"
            >
              Remove
            </Button>
          </FormControl>
        )}
      </Row>
    </FormContainer>
  );
};

export default SlotForm;
