import React from "react";
import RichTextEditor from "react-rte";
import styled from "styled-components";
import { SubmitBtn, AltSubmitBtn } from "../common";

const Stick = styled.div`
  flex-shrink: 0;
  .public-DraftEditor-content {
    height: 6rem;
    font-family: "Nunito", sans-serif;
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  margin: 0.2rem 0;
  button {
    width: 100%;
  }
`;

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: [
    "INLINE_STYLE_BUTTONS",
    "BLOCK_TYPE_BUTTONS",
    "LINK_BUTTONS",
    "BLOCK_TYPE_DROPDOWN",
    "HISTORY_BUTTONS", 
  ],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "custom-css-class" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

function TextEditor({ saveText, defaultData }) {
  const [editorState, setEditorState] = React.useState(
    () => RichTextEditor.createEmptyValue() || defaultData
  );

  return (
    <Stick>
      <RichTextEditor
        placeholder="Enter patient note here! you can format and add links"
        toolbarConfig={toolbarConfig}
        value={editorState}
        onChange={setEditorState}
      />
      <BtnContainer>
        <SubmitBtn
          fontSize="1rem"
          height="2.8rem"
          onClick={() => {
            setEditorState(RichTextEditor.createEmptyValue());
            saveText(editorState.toString("html"));
          }}
        >
          Post Note
        </SubmitBtn>
      </BtnContainer>
    </Stick>
  );
}

export default TextEditor;
