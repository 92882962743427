import React from "react";
import styled from "styled-components";
import Editor from "../../editor";
import { formSchema, createButtons } from "./formSettings";
import { FadeDiv } from "../../../library/styled-components";
import { Link } from "../../common";

const ResetBlockContainer = styled(FadeDiv)`
  display: grid;
  grid-template-rows: auto 8em 2em;
  background-color: ${(props) => props.colors.secColor};
`;

function ResetBlock({ resetPassword, goToLogin, fade, colors, meta }) {
  return (
    <ResetBlockContainer fade={fade} colors={colors}>
      <Editor
        submitError={meta && meta.error}
        warning={meta && meta.warning}
        formSchema={formSchema}
        metaData={meta}
        buttons={createButtons}
        sendData={resetPassword}
        colors={colors}
        settings={{
          width: { mobile: "100%", desktop: "100%" },
        }}
      />
      <div>
        <Link onClick={goToLogin} colors={colors} size="1.1">
          Return to Login?
        </Link>
      </div>
    </ResetBlockContainer>
  );
}

export default ResetBlock;
