import React, { useState, useContext, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { inviteUser } from "../../actions/users";
import { ModalBox } from "../../components/common";
import SideLayout from "../side";
import { Menu } from "../menu";
import colors from "../../library/styled-components/colors";
import DoubleBounce from "../../components/loaders/double-bounce";
import dentlogo from "../../img/dent_w_logo.png";
import BookSimpleLogo from "../../logos/BookSimpleLogo";
import { Logo } from "../../components/common";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import {
  Container,
  Content,
  ContentHeader,
  ContentWrapper,
  InputContainer,
  InputField,
  Icon,
  RightIcon,
  RightTopIcon,
} from "./styles";
import { format } from "date-fns";
import Popover from "../../components/popover";
import SmsClient from "../../components/sms-client";
import ReactCountryFlag from "react-country-flag";
import SendIcon from "../../icon/fea-icons/send";
import CloseCircleFeaIcon from "../../icon/fea-icons/close-circle";
import TodayIcon from "@mui/icons-material/Today";
import MenuIcon from "@mui/icons-material/Menu";
import GroupIcon from "@mui/icons-material/Group";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import { ADMIN_NS } from "../../config";
import { AppContext } from "../../hooks/context";
import SyncFiles from "../../components/file-handlers/SyncFiles";
import { OverlayBackGround } from "layouts/lib/common";
import EventInfo from "./EventInfo";
import DataLoader from "components/data-loader";
import {
  Button,
  Badge,
  MenuItem,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  ListItemIcon,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";
import {
  markNotificationsAsRead,
  markNotificationsAsUnread,
} from "actions/notifications";

// Custom styles
const useStyles = makeStyles((theme) => ({
  listItemText: {
    "&, & *": {
      fontSize: "0.8rem",
    },
  },
  accordionSummary: {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
      margin: "0 !important", // Override default styles
    },
  },
  accordionDetails: {
    flexDirection: "column", // Adjust layout for better readability
    padding: 0,
  },
  expanded: {
    margin: "0 !important", // Override default styles
  },
}));

// Main component
export default function Layout({
  meta,
  children,
  menu = false,
  openSide,
  user,
  smsActive = true,
  noSync = false,
}) {
  const { state, app, dispatch } = useContext(AppContext);
  const { sms, notifications } = state;
  const [top, body, side] = Array.isArray(children) ? children : [children];
  const [showPop, setShowPop] = useState(false);
  const classes = useStyles();
  const [sendActive, setSendActive] = useState(false);
  const [error, setError] = useState(false);
  const [popNumber, setPopNumber] = useState("");
  const [show, setShow] = useState(false);
  const history = useHistory();

  const [showSms, setShowSms] = app.sms;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Group notifications by type
  const successNotifications =
    notifications?.data?.filter((n) => n.data.Type.includes("sent")) || [];
  const failureNotifications =
    notifications?.data?.filter((n) => n.data.Type.includes("failed")) || [];

  async function navigateTo(pathname) {
    history.push({
      pathname: pathname,
    });
  }

  const [currentVersion, setCurrentVersion] = useState(null);

  // Load current version on mount
  useEffect(() => {
    fetch("/manifest.json")
      .then((response) => response.json())
      .then((data) => setCurrentVersion(data.version));
  }, []);

  const messageCount = useMemo(
    () =>
      sms.data
        ?.map((a) =>
          a.data?.SmsMessages?.map((m) =>
            m && m.TypeId === 4 && !m.ReadUserId ? 1 : 0
          ).reduce((sum, i) => sum + i, 0)
        )
        .reduce((sum, i) => sum + i, 0),
    [sms]
  );

  return (
    <>
      {!noSync && <DataLoader />}
      <ContentHeader>
        <div
          style={{
            display: "flex",
            marginLeft: "1.5rem",
          }}
        >
          {menu && (
            <IconButton
              onClick={(e) => {
                setShow(!show);
              }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              size="large"
            >
              <MenuIcon style={{ color: colors.white }} />
            </IconButton>
          )}
          {menu && (
            <Button
              style={{ marginLeft: "0.5rem" }}
              onClick={(e) => {
                navigateTo("/admin/home/calendar");
              }}
              edge="start"
              color="inherit"
              aria-label="open drawer"
              disabled={window.location.hash.includes("calendar")}
              startIcon={
                <TodayIcon
                  style={{
                    color: window.location.hash.includes("calendar")
                      ? colors.softGreen
                      : colors.white,
                  }}
                />
              }
            >
              <div
                style={{
                  color: window.location.hash.includes("calendar")
                    ? colors.softGreen
                    : colors.white,
                  textTransform: "capitalize",
                }}
              >
                Appointments
              </div>
            </Button>
          )}
          {menu && (
            <Button
              onClick={(e) => {
                navigateTo("/admin/home/patients");
              }}
              edge="start"
              color="inherit"
              disabled={window.location.hash.includes("patients")}
              aria-label="open drawer"
              startIcon={
                <GroupIcon
                  style={{
                    color: window.location.hash.includes("patients")
                      ? colors.softGreen
                      : colors.white,
                  }}
                />
              }
            >
              <div
                style={{
                  color: window.location.hash.includes("patients")
                    ? colors.softGreen
                    : colors.white,
                  textTransform: "capitalize",
                }}
              >
                Patients
              </div>
            </Button>
          )}
          {menu && (
            <Button
              onClick={(e) => {
                navigateTo("/admin/home/logs");
              }}
              edge="start"
              color="inherit"
              disabled={window.location.hash.includes("logs")}
              aria-label="open drawer"
              startIcon={
                <MailOutlineIcon
                  size="small"
                  style={{
                    color: window.location.hash.includes("logs")
                      ? colors.softGreen
                      : colors.white,
                  }}
                />
              }
            >
              <div
                style={{
                  color: window.location.hash.includes("logs")
                    ? colors.softGreen
                    : colors.white,
                  textTransform: "capitalize",
                }}
              >
                Message logs
              </div>
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
          }}
        >
          <MenuItem>
            <IconButton
              size="large"
              aria-label={`show ${
                state?.notifications?.data?.filter((n) => !n.data.ReadUserId)
                  .length || 0
              } new notifications`}
              color="inherit"
              disabled={!state?.notifications?.data?.length}
              onClick={handleClick}
            >
              {state?.notifications?.data?.filter((n) => !n.data.ReadUserId)
                .length > 0 ? (
                <Badge
                  badgeContent={
                    state?.notifications?.data?.filter(
                      (n) => !n.data.ReadUserId
                    ).length || 0
                  }
                  color="warning"
                >
                  <CircleNotificationsIcon style={{ color: colors.white }} />
                </Badge>
              ) : (
                <CircleNotificationsIcon style={{ color: 
                    !state?.notifications?.data?.length ? colors.rgbGreyDark : colors.white 
                }} 
                  disabled={!state?.notifications?.data?.length}
                />
              )}
            </IconButton>
            <MuiPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <List
                style={{
                  margin: 0,
                }}
              >
                <Accordion
                  sx={{
                    "&.Mui-expanded": {
                      margin: "0 !important",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      root: classes.accordionSummary,
                      expanded: classes.expanded,
                    }}
                  >
                    {successNotifications.filter((n) => !n.data.ReadUserId)
                      .length > 0 ? (
                      <ListItemIcon>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const unreadNotificationIds = successNotifications
                              .filter((n) => !n.data.ReadUserId)
                              .map((n) => n.data.NotificationId);
                            dispatch({
                              type: "UPDATE",
                              context: "notifications",
                              data: state.notifications.data.map((n) => {
                                if (
                                  unreadNotificationIds.includes(
                                    n.data.NotificationId
                                  )
                                ) {
                                  return {
                                    ...n,
                                    data: {
                                      ...n.data,
                                      ReadUserId: user.data.Practice.UserId, // Mark as read
                                    },
                                  };
                                }
                                return n;
                              }),
                            });

                            markNotificationsAsRead(ADMIN_NS, {
                              NotificationIds: unreadNotificationIds,
                            });
                          }}
                        >
                          <MarkEmailUnreadIcon color="primary" />
                        </IconButton>
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();

                            const readNotificationIds =
                              successNotifications.map(
                                (n) => n.data.NotificationId
                              );

                            dispatch({
                              type: "UPDATE",
                              context: "notifications",
                              data: state.notifications.data.map((n) => {
                                if (
                                  readNotificationIds.includes(
                                    n.data.NotificationId
                                  )
                                ) {
                                  return {
                                    ...n,
                                    data: {
                                      ...n.data,
                                      ReadUserId: null, // Mark as unread
                                    },
                                  };
                                }
                                return n;
                              }),
                            });

                            markNotificationsAsUnread(ADMIN_NS, {
                              NotificationIds: readNotificationIds,
                            });
                          }}
                        >
                          <MarkEmailReadIcon />
                        </IconButton>
                      </ListItemIcon>
                    )}
                    <ListItemText
                      primary={
                        <>
                          <span
                            style={{
                              color: "#1976d2", // Background color of the badge
                              fontWeight: "bold",
                              marginRight: "0.2rem",
                            }}
                          >
                            {successNotifications.length}
                          </span>
                          Sms Reminders Sent
                        </>
                      }
                      secondary={`There were ${successNotifications.length} notifications successfully sent`}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <List>
                      {successNotifications.map((notification) => (
                        <ListItem
                          key={notification.data.NotificationId}
                          className={classes.listItem}
                          sx={{
                            cursor: "pointer",
                            backgroundColor: !notification.data.ReadUserId
                              ? colors.lightBlue
                              : colors.white,
                            "&:hover": {
                              opacity: 0.8,
                            },
                          }}
                        >
                          <ListItemIcon>
                            <CheckCircleIcon color="success" />
                          </ListItemIcon>

                          <ListItemText
                            primary={notification.data.Message}
                            secondary={`sent at ${format(
                              new Date(notification.data.createdAt),
                              "dd/MM/yyyy HH:mm"
                            )}`}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  sx={{
                    "&.Mui-expanded": {
                      margin: "0 !important",
                    },
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    classes={{
                      root: classes.accordionSummary,
                      expanded: classes.expanded,
                    }}
                  >
                    {failureNotifications.filter((n) => !n.data.ReadUserId)
                      .length > 0 ? (
                      <ListItemIcon>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            const unreadNotificationIds = failureNotifications
                              .filter((n) => !n.data.ReadUserId)
                              .map((n) => n.data.NotificationId);
                            dispatch({
                              type: "UPDATE",
                              context: "notifications",
                              data: state.notifications.data.map((n) => {
                                if (
                                  unreadNotificationIds.includes(
                                    n.data.NotificationId
                                  )
                                ) {
                                  return {
                                    ...n,
                                    data: {
                                      ...n.data,
                                      ReadUserId: user.data.Practice.UserId, // Mark as read
                                    },
                                  };
                                }
                                return n;
                              }),
                            });

                            markNotificationsAsRead(ADMIN_NS, {
                              NotificationIds: unreadNotificationIds,
                            });
                          }}
                        >
                          <MarkEmailUnreadIcon color="primary" />
                        </IconButton>
                      </ListItemIcon>
                    ) : (
                      <ListItemIcon>
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();

                            const readNotificationIds =
                              failureNotifications.map(
                                (n) => n.data.NotificationId
                              );

                            dispatch({
                              type: "UPDATE",
                              context: "notifications",
                              data: state.notifications.data.map((n) => {
                                if (
                                  readNotificationIds.includes(
                                    n.data.NotificationId
                                  )
                                ) {
                                  return {
                                    ...n,
                                    data: {
                                      ...n.data,
                                      ReadUserId: null, // Mark as unread
                                    },
                                  };
                                }
                                return n;
                              }),
                            });

                            markNotificationsAsUnread(ADMIN_NS, {
                              NotificationIds: readNotificationIds,
                            });
                          }}
                        >
                          <MarkEmailReadIcon />
                        </IconButton>
                      </ListItemIcon>
                    )}

                    <ListItemText
                      primary={
                        <>
                          <span
                            style={{
                              color: "#d32f2f", // Background color of the badge
                              fontWeight: "bold",
                              marginRight: "0.2rem",
                            }}
                          >
                            {failureNotifications.length}
                          </span>
                          Sms Reminders Failed
                        </>
                      }
                      secondary={`There were ${failureNotifications.length} notifications that failed to send`}
                    />
                  </AccordionSummary>
                  <AccordionDetails className={classes.accordionDetails}>
                    <List>
                      {failureNotifications.map((notification) => (
                        <ListItem
                          key={notification.data.NotificationId}
                          className={classes.listItem}
                        >
                          <ListItemIcon>
                            {" "}
                            <ErrorIcon color="error" />
                          </ListItemIcon>
                          <ListItemText
                            primary={notification.data.Message}
                            secondary={`failed at ${notification.data.createdAt}`}
                            className={classes.listItemText}
                          />
                        </ListItem>
                      ))}
                    </List>
                  </AccordionDetails>
                </Accordion>
              </List>
            </MuiPopover>
          </MenuItem>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: "1rem",
              color: "white",
            }}
          >
            <strong>v:{currentVersion}</strong>
          </div>

          {process.env.REACT_APP_NAME === "book-simple" ? (
            <BookSimpleLogo
              style={{ height: "38px", placeSelf: "center" }}
              size="38px"
            />
          ) : (
            <Logo src={dentlogo} alt="logo" height="38px" />
          )}
          <div
            style={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigateTo("/admin/logout")}
          >
            {/*  <QuickSearch /> */}

            <ExitToAppIcon
              style={{
                color: "white",
                margin: "0 1rem",
              }}
            />
          </div>
        </div>
      </ContentHeader>

      <Container menu={menu} show={show} side={!!side} open={openSide}>
        <Content>
          <ContentWrapper>
            {meta && meta.updating && (
              <OverlayBackGround status={meta.updating ? 1 : 0}>
                <DoubleBounce color={colors.primColor} margin="50%" />
              </OverlayBackGround>
            )}
            {meta && !meta.updating && top}
            {meta && !meta.updating && body}
          </ContentWrapper>
        </Content>
        {!!side && openSide && <SideLayout meta={meta}>{side}</SideLayout>}
        <EventInfo></EventInfo>
        <Popover
          visible={showPop}
          left={show ? "12rem" : "4rem"}
          top="22rem"
          header="Options"
        >
          <div>
            <RightTopIcon active={true}>
              <CloseCircleFeaIcon
                onClick={() => setShowPop(false)}
                color={colors.primary}
                background={colors.white}
                size="28"
              />
            </RightTopIcon>
          </div>

          <label
            style={{
              padding: ".2em 0",
              color: error ? colors.errorColor : colors.primary,
            }}
            htmlFor="tel"
          >
            {!error ? "Enter phone number:" : "Invalid phone number!"}
          </label>
          <InputContainer id="tel" name="tel">
            <Icon>
              <ReactCountryFlag
                aria-label="+353"
                countryCode="IE"
                svg
                style={{
                  width: "1.6em",
                }}
              />
            </Icon>
            <InputField
              type="tel"
              placeholder="086 123 4567"
              pattern="[0-9]{3} [0-9]{3} [0-9]{4}"
              maxlength="10"
              title="Phone Number"
              value={popNumber}
              onChange={(e) => {
                if (e.target.value.length <= 10) setPopNumber(e.target.value);
                const regex = /08[\d]\d{7}/;
                const found = e.target.value.match(regex);
                if (found) setSendActive(true);
                else setSendActive(false);
              }}
            />
            <RightIcon active={sendActive}>
              <SendIcon
                onClick={async () => {
                  setError(false);
                  const response = await inviteUser(ADMIN_NS, {
                    Phone: {
                      tel: true,
                      text: "Phone",
                      type: "tel",
                      val: popNumber,
                    },
                  });
                  if (response.code === "SUCCESS") {
                    setShowPop(false);
                    setPopNumber("");
                  } else {
                    setError(true);
                  }
                }}
                color={colors.primary}
                size="16"
              />
            </RightIcon>
          </InputContainer>
        </Popover>

        {smsActive && <SmsClient />}

        <ModalBox show={showSms} onClick={() => setShowSms(false)}></ModalBox>

        <SyncFiles />
      </Container>
      {menu ? (
        <Menu
          setShow={setShow}
          show={show}
          setShowPop={setShowPop}
          showPop={showPop}
          messageCount={messageCount}
          user={user?.data}
        />
      ) : null}
    </>
  );
}
