import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.17"
      height="246"
      viewBox="0 0 70.17 246"
    >
      <g id="bottom4" transform="translate(-868.5 -483.367)">
        <g id="bounds" transform="translate(868.5 419.367)">
          <line
            id="bound"
            x2="69"
            transform="translate(0 310)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="69"
            transform="translate(0 64)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(881.103 494.457)">
          <path
            id="shape"
            d="M37.311,5.7a24.126,24.126,0,0,1,4.851,1.858,24.814,24.814,0,0,1,5.936,4.3,16.059,16.059,0,0,1,4.581,12.68c-.423,4.685-3.1,9.2-6.4,12.388s-7.245,5.065-10.948,7.684a24.408,24.408,0,0,1-4.893,2.59C26.877,48.657,5.343,41.5,3,38.474.691,35.449.767,30.534.4,25.324S-.771,14.642.984,10.339A14.731,14.731,0,0,1,11.623,2.021c.82-.16,1.668-.359,2.814-.64C18.033.5,22.219-.706,25.54.148,29.569,1.226,33.313,4.692,37.311,5.7Z"
            transform="translate(51.863 42.528) rotate(164.991)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="o"
            d="M6.587,16.3a59.752,59.752,0,0,1,2.132,8.731,51.636,51.636,0,0,1,.609,9.426,33.4,33.4,0,0,1,11.06-2.37,34.934,34.934,0,0,1,10.794,1.751,38.472,38.472,0,0,1-.228-8.046,26.487,26.487,0,0,1,1.929-8.224A44.877,44.877,0,0,0,19.258,14.8,38.4,38.4,0,0,0,6.587,16.3Z"
            transform="translate(2.259)"
            fill="#eaeaea"
          ></path>
          <path
            id="m"
            d="M6.277-.024a26.35,26.35,0,0,1,6.544,4.538A16.085,16.085,0,0,1,17.7,17.272c-.392,4.693-3.128,9.2-6.516,12.358A60.283,60.283,0,0,1,1.565,36.5,38.806,38.806,0,0,1,0,22.069C1.087,11.585,5.234-.519,6.277-.024Z"
            transform="translate(16.41 44.565) rotate(164.991)"
            fill="#f5f5f5"
          ></path>
          <path
            id="d"
            d="M1.188,32.677c-.558-2.353-.4-5.612-.673-8.89C.084,18.559-.807,13.064.97,8.768A14.428,14.428,0,0,1,11.711.568C12.56.416,13.3.19,14.493-.081,16.526-.544,16.8,12.7,12.509,22.143,9.168,29.5,1.388,33.528,1.188,32.677Z"
            transform="translate(51.308 41.014) rotate(164.991)"
            fill="#f5f5f5"
          ></path>
          <path
            id="b"
            d="M23.287,6.15A13.29,13.29,0,0,1,26.7,7.324a19.089,19.089,0,0,0-2.4,4.921c-.491,1.4-1.309,3.871-1.8,5.553-.454,1.566-5.482,1.412-11.324.043s-10.155-3.729-9.87-5.722c.168-1.167.3-4.376.3-5.954C1.6,2.778.738,1.616.738,1.616,4.352.784,7.662-.549,11.005.312,15.193,1.433,19.132,5.1,23.287,6.15Z"
            transform="translate(38.084 46.377) rotate(164.991)"
            fill="#f5f5f5"
          ></path>
          <path
            id="l"
            d="M34.649,15.094a23.452,23.452,0,0,1-6.219,3.559c-.936.445-5.691-.5-11.944-2.3C10.313,14.564,2.73,11.521,1.449,9.929A10.516,10.516,0,0,1-.285,5.765,9.287,9.287,0,0,0,3.4,4.05C5.339,2.612,6.827.408,7.829-.022c.539-.232,2.077,1.247,5.094,2.928a48.4,48.4,0,0,0,8.645,3.423c6.959,2.1,10.977,1.03,11.469,1.73s.661,2.433.914,4.239a11.24,11.24,0,0,0,.7,2.8Z"
            transform="translate(42.843 15.303) rotate(164.991)"
            fill="#f5f5f5"
          ></path>
        </g>
        <g id="crown" transform="translate(869.5 419.788)">
          <path
            id="crown-2"
            data-name="crown"
            d="M3759.5,545.1s16.773-26.921,4.927-36.9-18.091-23.634-23.477-21.533-24.77,15.493-23.05,27.833,11.1,31.74,11.1,31.74,8.924,4.343,15.924,3.621S3759.5,545.1,3759.5,545.1Z"
            transform="translate(-3708.689 -344.451)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root" transform="translate(878.164 595.543)">
          <path
            id="rb1"
            d="M30.179,0s1.566,102.233-15.974,100.778c-6.459-.536-3.7-13.1-5.276-26.994C6.855,55.5,1.576,33.5.542,19.76.433,18.309,0,4.4,0,4.4A32.588,32.588,0,0,0,14.654,6.058C22.2,4.957,30.179,0,30.179,0Z"
            transform="translate(12.284 21.393) rotate(7)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M11.947,17.29c.108,18.1-2.075,43.146-3.763,52.642S5.827,65.62,5.2,55.274c-.829-13.615-.749-23.9-2.048-36.9S.734,5.751,2.016,3.029s3.334-1.176,5.312-1.6C10.345.605,11.839-.813,11.947,17.29Z"
            transform="translate(20.284 37.271) rotate(7)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(887.654 525.089)">
            <g id="abscess" transform="translate(-13.154 182.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(38.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="cracked" transform="translate(15.036 63.105) rotate(-138)">
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(-12 97.133)">
              <path
                id="p1-4"
                data-name="p1"
                d="M1110.9,670.327s12.889,8.54,25.173,8.54,23.962-8.54,23.962-8.54"
                transform="translate(-1107.654 -674.222)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(876 577.675)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc">
              <path
                id="rctpc-2"
                data-name="rctpc"
                d="M13.54,8.125,5.417,43.383l-2.506.044-1.819.032L-2.447,8.4C-2.447,6.8,13.54,6.523,13.54,8.125Z"
                transform="translate(900.852 581.319) rotate(0.974)"
                fill="red"
              ></path>
            </g>
            <g id="resection">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982,33.023,107"
                transform="translate(881.536 598.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(883.247 601.874)">
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
            <g id="seal" transform="translate(217.222 -15)">
              <path
                id="seal-2"
                data-name="seal"
                d="M750.875,514.944s-30.363,3.852-30.363,9.139c0,2.8,10.173,5.6,18.278,7.4,6.784,1.508,11.57,2,12.085,4.607,1.1,5.551-25.982,10.2-25.982,10.2"
                transform="translate(-52.44 4.764)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="implant" transform="translate(889.88 608.499)">
              <path
                id="p5"
                d="M806.2,410.878l-1.569-15.719s6.272-8.37,11.062-11.2,3.083-2.77,8,0,11.6,10.96,11.6,10.96l-.134,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M808.716,384.517l-1.844-10.682,27.432-8.6-1.561,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3"
                d="M810.36,366.894l-1.53-10.675,23.593-7.444-1.287,11.536Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M812.121,348.918l-1.142-10.534,19.625-5.9-1.57,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-7"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-803.705 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(868.5 451.5)">
              <path
                id="Path_2-2"
                data-name="Path 2"
                d="M0,0H70"
                transform="translate(0 150)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M0,0H70"
                transform="translate(0 67)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(914.885 659.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
