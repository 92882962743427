import { combineReducers } from 'redux'
import search from './search'
import booking from './booking'
import appointments from './appointments'
import practitioners from './practitioners'

const App = combineReducers({
  search,
  booking,
  appointments,
  practitioners
})

export default App
