import styled, { css } from "styled-components";
import { SubmitBtn } from "../../../components/common";

export const Container = styled.div`
  position: fixed;
  z-index:1;
  ${(props) =>
    props.position === "bottom-right" &&
    css`
      bottom: 0;
      right: 0;
    `};
`;
export const Action = styled(SubmitBtn)`
  margin: 0.6em;
`;
