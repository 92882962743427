import { handleResponse, authHeader } from "../../library/handler";

export const fetcNotifications = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/notifications`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const markNotificationsAsRead = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/notifications/mark-as-read`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const markNotificationsAsUnread = async (state, data) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/notifications/mark-as-unread`,
    {
      method: "post",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
