import React from "react";
import styled from "styled-components";
import TeethBlock from "./components/teeth/TeethBlock";
import LeftColumnOne from "./components/left-margin/LeftColumnOne";
import ActionsBlockOne from "./components/actions/ActionsBlockOne";
import ActionsBlockTwo from "./components/actions/ActionsBlockTwo";
import ActionsBlockThree from "./components/actions/ActionsBlockThree";
import LeftColumnThree from "./components/left-margin/LeftColumnThree";
import LeftColumnTwo from "./components/left-margin/LeftColumnTwo";
import LeftColumnFour from "./components/left-margin/LeftColumnFour";
import ActionsBlockFour from "./components/actions/ActionsBlockFour";
import { Avatar, Chip } from "@mui/material";

const Container = styled.div`
  display: grid;
  justify-content: center;
  height: 74rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-areas:
    "a b"
    "c d";
`;

const LeftBlockWrapper = styled.div`
  display: grid;
  grid-template-columns: 70px 400px;
  text-align: right;
  font-size: 14px;
`;

const RightBlockWrapper = styled.div`
  display: grid;
  text-align: left;
  font-size: 14px;
`;

const Section = styled.div`
  height: auto;
`;

const DataWrap = styled.div`
  padding-left: 70px;
  margin: 1rem;
  text-align: center;
  div {
    margin: 0.2rem;
  }
`;

const Chart = ({ teethState, setTeethState, teethSettings }) => {
  const plaque = Object.entries(teethState)
    .map((a) =>
      a[1].imgState
        .map((b) => b?.plaque?.reduce((sum, i) => sum + i, 0) || 0)
        .reduce((sum, i) => sum + i, 0)
    )
    .reduce((sum, i) => sum + i, 0);

  const bleed = Object.entries(teethState)
    .map((a) =>
      a[1].imgState
        .map((b) => b?.bleed?.reduce((sum, i) => sum + i, 0) || 0)
        .reduce((sum, i) => sum + i, 0)
    )
    .reduce((sum, i) => sum + i, 0);

  const marginTotals = Object.entries(teethState)
    .map((a, i) =>
      a[1].margin
        ?.map((p) =>
          p ? (p[1] - ([0, 1, 4, 5].includes(i) ? 97 : 63)) / 6 : 0
        )
        .reduce((sum, i) => sum + i, 0)
    )
    .reduce((sum, i) => sum + i, 0);

  const probeTotals = Object.entries(teethState)
    .map((a, i) =>
      a[1].depth
        ?.map((p) =>
          p ? (p[1] - ([0, 1, 4, 5].includes(i) ? 97 : 63)) / 6 : 0
        )
        .reduce((sum, i) => sum + i, 0)
    )
    .reduce((sum, i) => sum + i, 0);

  return (
    <Container>
      <Wrapper>
        <LeftBlockWrapper>
          <LeftColumnOne />
          <Section>
            <ActionsBlockOne
              imgs={teethState.one.imgState}
              margin={teethState.one.margin}
              depth={teethState.one.depth}
              updateSettings={(imgState) =>
                setTeethState({
                  ...teethState,
                  one: { ...teethState.one, imgState },
                })
              }
              updateMargin={(margin) =>
                setTeethState({
                  ...teethState,
                  one: { ...teethState.one, margin },
                })
              }
              updateDepth={(depth) =>
                setTeethState({
                  ...teethState,
                  one: { ...teethState.one, depth },
                })
              }
            />
            <TeethBlock
              {...teethSettings.one}
              {...teethState.one}
              margin={teethState.one.margin}
              depth={teethState.one.depth}
              updateSettings={(one) => setTeethState({ ...teethState, one })}
            />
          </Section>
        </LeftBlockWrapper>

        <RightBlockWrapper>
          <Section>
            <ActionsBlockOne
              imgs={teethState.two.imgState}
              margin={teethState.two.margin}
              depth={teethState.two.depth}
              updateSettings={(imgState) =>
                setTeethState({
                  ...teethState,
                  two: { ...teethState.two, imgState },
                })
              }
              updateMargin={(margin) =>
                setTeethState({
                  ...teethState,
                  two: { ...teethState.two, margin },
                })
              }
              updateDepth={(depth) =>
                setTeethState({
                  ...teethState,
                  two: { ...teethState.two, depth },
                })
              }
            />
            <TeethBlock
              {...teethSettings.two}
              {...teethState.two}
              margin={teethState.two.margin}
              depth={teethState.two.depth}
              updateSettings={(two) => setTeethState({ ...teethState, two })}
            />
          </Section>
        </RightBlockWrapper>

        <LeftBlockWrapper>
          <LeftColumnTwo />
          <Section>
            <TeethBlock
              inverse={true}
              offset={63}
              {...teethSettings.three}
              {...teethState.three}
              margin={teethState.three.margin}
              depth={teethState.three.depth}
              updateSettings={(three) =>
                setTeethState({ ...teethState, three })
              }
            />
            <ActionsBlockTwo
              imgs={teethState.three.imgState}
              margin={teethState.three.margin}
              depth={teethState.three.depth}
              updateSettings={(imgState) =>
                setTeethState({
                  ...teethState,
                  three: { ...teethState.three, imgState },
                })
              }
              updateMargin={(margin) =>
                setTeethState({
                  ...teethState,
                  three: { ...teethState.three, margin },
                })
              }
              updateDepth={(depth) =>
                setTeethState({
                  ...teethState,
                  three: { ...teethState.three, depth },
                })
              }
            />
          </Section>
        </LeftBlockWrapper>

        <RightBlockWrapper>
          <Section>
            <TeethBlock
              inverse={true}
              offset={63}
              {...teethSettings.four}
              {...teethState.four}
              margin={teethState.four.margin}
              depth={teethState.four.depth}
              updateSettings={(four) => setTeethState({ ...teethState, four })}
            />
            <ActionsBlockTwo
              imgs={teethState.four.imgState}
              margin={teethState.four.margin}
              depth={teethState.four.depth}
              updateSettings={(imgState) =>
                setTeethState({
                  ...teethState,
                  four: { ...teethState.four, imgState },
                })
              }
              updateMargin={(margin) =>
                setTeethState({
                  ...teethState,
                  four: { ...teethState.four, margin },
                })
              }
              updateDepth={(depth) =>
                setTeethState({
                  ...teethState,
                  four: { ...teethState.four, depth },
                })
              }
            />
          </Section>
        </RightBlockWrapper>
      </Wrapper>
      <DataWrap>
        <Chip
          label={`${
            probeTotals
              ? ((marginTotals - probeTotals) / 192).toPrecision(1)
              : 0
          } (mm) Mean probing depth`}
          variant="outlined"
        />

        <Chip
          label={`${
            probeTotals ? (probeTotals / 192).toPrecision(1) : 0
          } (mm) Mean Attachment level`}
          variant="outlined"
        />

        <Chip
          label="(%) Plaque"
          variant="outlined"
          color="primary"
          avatar={
            <Avatar>{plaque ? Math.round((plaque / 192) * 100) : 0}</Avatar>
          }
        />

        <Chip
          label="(%) Bleeding on probing"
          variant="outlined"
          color="secondary"
          avatar={
            <Avatar>{bleed ? Math.round((bleed / 192) * 100) : 0}</Avatar>
          }
        />
      </DataWrap>
      <Wrapper>
        <LeftBlockWrapper>
          <LeftColumnThree />
          {teethState.five && (
            <Section>
              <ActionsBlockThree
                imgs={teethState.five.imgState}
                margin={teethState.five.margin}
                depth={teethState.five.depth}
                updateSettings={(imgState) =>
                  setTeethState({
                    ...teethState,
                    five: { ...teethState.five, imgState },
                  })
                }
                updateMargin={(margin) =>
                  setTeethState({
                    ...teethState,
                    five: { ...teethState.five, margin },
                  })
                }
                updateDepth={(depth) =>
                  setTeethState({
                    ...teethState,
                    five: { ...teethState.five, depth },
                  })
                }
              />
              <TeethBlock
                {...teethSettings.five}
                {...teethState.five}
                margin={teethState.five.margin}
                depth={teethState.five.depth}
                updateSettings={(five) =>
                  setTeethState({ ...teethState, five })
                }
              />
            </Section>
          )}
        </LeftBlockWrapper>
        <RightBlockWrapper>
          {teethState.six && (
            <Section>
              <ActionsBlockThree
                imgs={teethState.six.imgState}
                margin={teethState.six.margin}
                depth={teethState.six.depth}
                updateSettings={(imgState) =>
                  setTeethState({
                    ...teethState,
                    six: { ...teethState.six, imgState },
                  })
                }
                updateMargin={(margin) =>
                  setTeethState({
                    ...teethState,
                    six: { ...teethState.six, margin },
                  })
                }
                updateDepth={(depth) =>
                  setTeethState({
                    ...teethState,
                    six: { ...teethState.six, depth },
                  })
                }
              />
              <TeethBlock
                {...teethSettings.six}
                {...teethState.six}
                margin={teethState.six.margin}
                depth={teethState.six.depth}
                updateSettings={(six) => setTeethState({ ...teethState, six })}
              />
            </Section>
          )}
        </RightBlockWrapper>
        <LeftBlockWrapper>
          <LeftColumnFour />
          {teethState.seven && (
            <Section>
              <TeethBlock
                inverse={true}
                offset={63}
                {...teethSettings.seven}
                {...teethState.seven}
                margin={teethState.seven.margin}
                depth={teethState.seven.depth}
                updateSettings={(seven) =>
                  setTeethState({ ...teethState, seven })
                }
              />
              <ActionsBlockFour
                imgs={teethState.seven.imgState}
                margin={teethState.seven.margin}
                depth={teethState.seven.depth}
                updateSettings={(imgState) =>
                  setTeethState({
                    ...teethState,
                    seven: { ...teethState.seven, imgState },
                  })
                }
                updateMargin={(margin) =>
                  setTeethState({
                    ...teethState,
                    seven: { ...teethState.seven, margin },
                  })
                }
                updateDepth={(depth) =>
                  setTeethState({
                    ...teethState,
                    seven: { ...teethState.seven, depth },
                  })
                }
              />
            </Section>
          )}
        </LeftBlockWrapper>
        <RightBlockWrapper>
          {teethState.seven && (
            <Section>
              <TeethBlock
                inverse={true}
                offset={63}
                {...teethSettings.eight}
                {...teethState.eight}
                margin={teethState.eight.margin}
                depth={teethState.eight.depth}
                updateSettings={(eight) =>
                  setTeethState({ ...teethState, eight })
                }
              />
              <ActionsBlockFour
                imgs={teethState.eight.imgState}
                margin={teethState.eight.margin}
                depth={teethState.eight.depth}
                updateSettings={(imgState) =>
                  setTeethState({
                    ...teethState,
                    eight: { ...teethState.eight, imgState },
                  })
                }
                updateMargin={(margin) =>
                  setTeethState({
                    ...teethState,
                    eight: { ...teethState.eight, margin },
                  })
                }
                updateDepth={(depth) =>
                  setTeethState({
                    ...teethState,
                    eight: { ...teethState.eight, depth },
                  })
                }
              />
            </Section>
          )}
        </RightBlockWrapper>{" "}
      </Wrapper>
    </Container>
  );
};

export default Chart;
