import {
  deleteAppointment,
  createCancelation,
  undoCancelation,
} from "../../../../../actions/appointments";
import { sendInvoice } from "../../../../../actions/payments";

import { getPractitionerSchedules } from "../../../../../actions/schedules";
import { createPatient } from "../../../../../actions/patients";
import { ADMIN_NS } from "../../../../../config";
import { getList } from "library/resources";

const handleResponse =
  (response, id = null) =>
  async (dispatch, navigateTo) => {
    if (response && response.code === "EXPIRED") {
      dispatch({
        type: "REPLACE",
        context: "user",
        data: null,
        updating: false,
      });
      navigateTo("/admin/access/login");
    }

    !!id &&
      response &&
      response.code === "SUCCESS" &&
      dispatch({
        context: "patients",
        type: "UPDATE_ITEM",
        updating: false,
        data: response.data,
        meta: { updating: false, warning: null, error: null },
        uuid: { PatientId: id },
      });

    !id &&
      response &&
      response.code === "SUCCESS" &&
      dispatch({
        type: "ADD",
        context: "patients",
        data: response.data,
      });

    response &&
      response.code === "INVALID_PHONE" &&
      dispatch({
        type: "UPDATE",
        context: "patients",
        meta: {
          updating: false,
          warning: "Phone number is invalid or unsupported!",
        },
      });

    response &&
      response.code == "INVALID" &&
      dispatch({
        type: "UPDATE",
        context: "patients",
        meta: {
          updating: false,
          warning: "Data is invalid!",
        },
      });

    response &&
      response.code === "ALREADY_REGISTERED" &&
      dispatch({
        type: "UPDATE",
        context: "patients",
        meta: {
          updating: false,
          warning: "Email already exists",
        },
      });

    response &&
      (response.code === "ERROR" || response.code === "INVALID") &&
      dispatch({
        type: "UPDATE",
        context: "patients",
        meta: {
          updating: false,
          error: "something went wrong",
        },
      });

    return response;
  };

export const fetchData = (practitionerId) => async (dispatch) => {
  practitionerId &&
    (await getList(
      "schedules",
      getPractitionerSchedules,
      practitionerId
    )(dispatch));
};

export const createPatientData = (data) => async (dispatch, navigateTo) => {
  const response = await createPatient(ADMIN_NS, data);
  return await handleResponse(response)(dispatch, navigateTo);
};

export const removeAppointment = (AppointmentId) => async (dispatch) => {
  const data = await deleteAppointment(ADMIN_NS, AppointmentId);
  data &&
    data.code === "SUCCESS" &&
    dispatch({
      type: "REMOVE_ITEM",
      context: "appointments",
      uuid: { AppointmentId },
      updating: false,
    });
};

export const cancelAppointment = (data, AppointmentId) => async (dispatch) => {
  const response = await createCancelation(ADMIN_NS, data);
  response &&
    response.code === "SUCCESS" &&
    dispatch({
      context: "appointments",
      type: "UPDATE_ITEM",
      updating: false,
      data: response.data,
      uuid: { AppointmentId },
    });
};

export const unCancelAppointment = (data, AppointmentId) => async (dispatch) => {
  const response = await undoCancelation(ADMIN_NS, data);
  response &&
    response.code === "SUCCESS" &&
    dispatch({
      context: "appointments",
      type: "UPDATE_ITEM",
      updating: false,
      data: response.data,
      uuid: { AppointmentId },
    });
};

export const reSendInvoice = (data) => sendInvoice(ADMIN_NS, data);
