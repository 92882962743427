import React, { useReducer, useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import DentIcon from "../../../../icon/dentist";
import { Header, Body } from "../styles";
import DataTable from "../../../../components/table";
import Editor from "../../../../components/editor";
import Scheduler from "../../../../components/scheduler";
import StandardLayout from "../../../../layouts/standard";
import AddLayout from "../../../../layouts/components/add";
import InfoPanel from "../../../../layouts/components/info";
import {
  ModalBox,
  WrapIcon,
  ModalContent,
  SubmitIconBtn,
} from "../../../../components/common";
import styled from "styled-components";
import {
  editData,
  newSchedule,
  editSchedule,
  removeSchedule,
  removePractitioner,
} from "./actions";
import {
  editorEditFormSchema,
  editorCreateFormSchema,
  editorCreateButtons,
  editorUpdateButtons,
  scheduleFormSchema,
  scheduleCreateButtons,
  scheduleUpdateButtons,
} from "./formSettings";
import { Add, Edit, CalendarToday } from "styled-icons/material";
import EditFeaIcon from "../../../../icon/fea-icons/edit";
import CloseCircleFeaIcon from "../../../../icon/fea-icons/close-circle";
import {
  Overlay,
  OverlayBackGround,
  AdminContainer,
  AdminColumn,
} from "../../../../layouts/lib/common";
import ChevRightFeaIcon from "../../../../icon/fea-icons/chevron-right";
import Context from "../../../../components/context";
import colors from "../../../../library/styled-components/colors";
import DoubleBounce from "../../../../components/loaders/double-bounce";
import { getList } from "library/resources";
import { getPractitioners } from "actions/practitioners";
import { AppContext } from "hooks/context";

const EditAction = styled.button`
  display: grid;
  width: 4rem;
  grid-template-columns: 1em auto;
  align-items: center;
  cursor: pointer;
  background: ${colors.grey};
  border: none;
  outline: none;
  color: white;
  border-radius: 0.2rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;

const SchedulerAction = styled.button`
  display: grid;
  width: 6rem;
  grid-template-columns: 1em auto;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-right: 0.4rem;
  background: ${colors.secondary};
  border: none;
  color: white;
  border-radius: 0.2rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;

export default function AdminPracitioners({ match, user }) {
  const { state, dispatch } = useContext(AppContext);
  const [PractitionerId, setPractitionerId] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [overlay, setOverlay] = useState(0);
  const [schedulePopUp, setSchedulePopUp] = useState(0);
  const [loading, setLoading] = useState(true);
  const { list } = state;

  const practitioners = list?.data.filter((p) => p.data.Profile === "active");

  const history = useHistory();

  async function navigateTo(pathname) {
    history.push({
      pathname: pathname,
    });
  }

  async function loadData(param) {
    await setLoading(true);
    if (param.id) {
      await setPractitionerId(param.id);
      await setOverlay(1);
    }
    await setLoading(false);
  }

  async function removeScheduleItem(practitionerId, uuid) {
    await removeSchedule(practitionerId, uuid)(dispatch);
  }

  const callbackHandler = async (call, data) => {
    call === "remove-schedule" && removeItem(data);
    if (call === "show-schedule") {
      setPractitionerId(PractitionerId);
      setScheduleOverlay(1);
    }
  };

  async function removeItem() {
    const uuid = PractitionerId;
    await setPractitionerId(null);
    await removePractitioner(uuid)(dispatch);
    dispatch({
      type: "UPDATE",
      context: "list",
      meta: { updating: false, warning: null },
    });
    setOverlay(2);
  }

  useEffect(() => {
    loadData(match && match.params);
  }, [match]);

  return (
    <StandardLayout
      menu={true}
      meta={list.meta}
      user={user}
      menuContent={
        <Header>
          <DentIcon size="48" color="#ffffff" />
        </Header>
      }
    >
      <OverlayBackGround status={overlay}>
        <Overlay key="editorOverlay" status={overlay}>
          {updating && (
            <div style={{ position: "absolute", margin: "50%", zIndex: "100" }}>
              <DoubleBounce color={colors.primary} />
            </div>
          )}
          <AdminColumn>
            <Editor
              warning={list.meta.warning}
              reset={overlay === 1}
              defaultData={
                PractitionerId &&
                practitioners.find((l) => l.data.PractitionerId === PractitionerId)
                  .data
              }
              metaData={{
                updating: list.meta.updating,
                disabled: !list.meta.updating,
              }}
              formSchema={
                PractitionerId ? editorEditFormSchema : editorCreateFormSchema
              }
              buttons={
                PractitionerId ? editorUpdateButtons : editorCreateButtons
              }
              sendData={async (data) => {
                setUpdating(true);
                const response = await editData(data, PractitionerId)(
                  dispatch,
                  navigateTo
                );
                if (response && response.code === "SUCCESS") {
                  dispatch({
                    type: "UPDATE",
                    context: "list",
                    meta: { updating: false, warning: null },
                  });
                  setOverlay(2);
                  setPractitionerId(null);
                }
                setUpdating(false);
              }}
              cancelMethod={() => {
                setOverlay(2);
                setPractitionerId(null);
                dispatch({
                  type: "UPDATE",
                  context: "list",
                  meta: { updating: false, warning: null },
                });
              }}
              callBack={callbackHandler}
              settings={{
                absolute: false,
                side: true,
              }}
              more="practitioner"
              removeClick={removeItem}
              customClick={callbackHandler}
            />
          </AdminColumn>
        </Overlay>
      </OverlayBackGround>
      <Body>
        <Context
          targetId="data-contentid"
          header="Options"
          menu={[
            {
              text: "Edit",
              icon: <Edit size="18" />,
              callback: (uuid) => {
                setPractitionerId(uuid);
                setOverlay(1);
              },
            },
            {
              text: "Edit Schedule",
              icon: <CalendarToday size="18" />,
              callback: async (uuid) => {},
            },
          ]}
        />
        {list && practitioners.length > 0 ? (
          <DataTable
            data={{
              title: "Practitioners",
              placeholder: "Search for Practitioners",
            }}
            list={practitioners}
            uuid={PractitionerId}
            editItem={async (uuid) => {
              setPractitionerId(uuid);
              setOverlay(1);
            }}
            actions={[
              <SchedulerAction
                key="consult"
                onClick={async (e) => {
                  e.preventDefault();
                  const uuid = e.target.parentNode.parentNode.id;
                  setPractitionerId(uuid);
                  setSchedulePopUp(true);
                  setOverlay(0);
                }}
              >
                <CalendarToday size="14" />
                <span>Scheduler</span>
              </SchedulerAction>,
              <EditAction
                key="edit"
                onClick={(e) => {
                  e.preventDefault();
                  const uuid = e.target.parentNode.parentNode.id;
                  setPractitionerId(uuid);
                  setOverlay(1);
                }}
              >
                <EditFeaIcon size="14" />
                <span>Edit</span>
              </EditAction>,
            ]}
            schema={{
              title: "Name",
              content: [
                { key: "Name", label: "Name" },
                { key: "Qualification", label: "Qualification" },
                { key: "RegistrationNo", label: "DCRN" },
                { key: "County", label: "County" },
                { key: "Address", label: "Address" },
                { key: "Email", label: "Email" },
                { key: "Phone", label: "Phone" },
              ],
              image: "Profile",
              link: { path: "admin/home/practitioners", id: "PractitionerId" },
            }}
          />
        ) : (
          !loading && (
            <InfoPanel>
              <h1>Add a Dental Practitioner</h1>
              <p>
                You must have at lease one Dental Practitioner before you can
                start
              </p>
              <SubmitIconBtn onClick={() => setOverlay(1)} center={true}>
                Create Practitioner <ChevRightFeaIcon size="22" />
              </SubmitIconBtn>
            </InfoPanel>
          )
        )}
        <AddLayout
          actions={[
            {
              key: "add",
              active: true,
              method: () => {
                setOverlay(1);
                setPractitionerId(null);
              },
              icon: <Add size="24" />,
            },
          ]}
        />

        {!!schedulePopUp && (
          <ModalBox show={schedulePopUp}>
            <WrapIcon
              onClick={() => {
                setSchedulePopUp(false);
                setPractitionerId(null);
              }}
            >
              <CloseCircleFeaIcon size="44" />
            </WrapIcon>
            <ModalContent
              width="calc(100vw - 10rem)"
              height="calc(100vh - 8rem)"
            >
              <AdminContainer>
                <AdminColumn>
                  <Scheduler
                    practitioner={
                      PractitionerId &&
                      practitioners.find(
                        (l) => l.data.PractitionerId === PractitionerId
                      ).data
                    }
                    formSchema={scheduleFormSchema}
                    createButtons={scheduleCreateButtons}
                    updateButtons={scheduleUpdateButtons}
                    createMethod={async (send) => newSchedule(send)(dispatch)}
                    updateMethod={async (send, uuid) =>
                      await editSchedule(send, uuid)(dispatch)
                    }
                    removeMethod={removeScheduleItem}
                    goBack={() => setScheduleOverlay(false)}
                    effectMethod={async () => {}}
                    callBack={callbackHandler}
                  />
                </AdminColumn>
              </AdminContainer>
            </ModalContent>
          </ModalBox>
        )}
      </Body>
    </StandardLayout>
  );
}
