import React from "react";
import {
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  Typography,
  TextField,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  OutlinedInput,
  FormControlLabel,
  Checkbox,
  FormControl,
  IconButton,
  Collapse,
  Button,
} from "@mui/material";
import styled from "styled-components";
import { format, isValid, parseISO } from "date-fns";
import { InputContainer } from "components/common";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { AppContext } from "hooks/context";
import PrefixList from "library/countryCodes";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { createPatient, updateOnePatient } from "actions/patients";
import { ADMIN_NS } from "config";
import { Alert } from "@mui/lab";
import DoubleBounce from "components/loaders/double-bounce";
import { deletePatient } from "actions/patients";
import ConfirmModal from "components/editor/more-popover/modal";

const Form = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const emailRegex = new RegExp(
  "^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
);

const phoneRegex = new RegExp("^[0-9]*$");

const responseErrorList = {
  INVALID_PHONE: "Phone number is invalid or unsupported",
  ALREADY_REGISTERED: "User is already registered",
  INVALID: "Phone number is invalid",
  ERROR: "An error occured",
};

const PatientForm = ({ extendedProps, close }) => {
  const { state } = React.useContext(AppContext);
  const [password, setPassword] = React.useState(true);
  const [removePatient, setRemovePatient] = React.useState(false);
  const [responseError, setResponseError] = React.useState(null);
  const [error, setError] = React.useState(null);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [updating, setUpdating] = React.useState(false);
  const [openRelatives, setOpenRelatives] = React.useState(false);
  const [userGroupLimit, setUserGroupLimit] = React.useState(5);
  const [date, setDate] = React.useState(
    format(
      new Date(extendedProps?.Patient?.User?.DateOfBirth || null),
      "yyyy-MM-dd"
    )
  );

  async function handleChange(e) {
    e.preventDefault();
    e.stopPropagation();
    setResponseError(null);
    setUpdating(true);
    const response = await updateOnePatient(
      ADMIN_NS,
      form,
      extendedProps.Patient.PatientId
    );

    if (response?.code === "SUCCESS") {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
    } else setResponseError(responseErrorList[response?.code]);
    setUpdating(false);
  }

  async function handleCreate(e) {
    e.preventDefault();
    e.stopPropagation();
    setResponseError(null);
    setUpdating(true);
    const response = await createPatient(ADMIN_NS, form);
    if (response?.code === "SUCCESS") {
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        close();
      }, 3000);
    } else setResponseError(responseErrorList[response?.code]);
    setUpdating(false);
  }

  function validateForm(form) {
    let error = null;
    setError(null);
    setResponseError(null);

    if (!form?.Phone) {
      error = "Phone is required";
    }
    if (form?.Phone?.length > 0 && !phoneRegex.test(form?.Phone)) {
      error = "Phone must be a number";
    }
    if (form?.Email && !emailRegex.test(form?.Email)) {
      error = "Email is not valid";
    }
    if ((form?.Name?.length || 0) < 3) {
      error = "Name must be at least 3 characters";
    }
    setError(error);
    return error;
  }

  const [form, setForm] = React.useState({
    Name: extendedProps?.Patient?.User?.Name,
    DateOfBirth: extendedProps?.Patient?.User?.DateOfBirth,
    Email: extendedProps?.Patient?.User?.Email,
    Phone: extendedProps?.Patient?.User?.Phone,
    MetaData: extendedProps?.Patient?.User?.MetaData,
    County: extendedProps?.Patient?.User?.County,
    Address: extendedProps?.Patient?.User?.Address,
    SocialSecurityNumber: extendedProps?.Patient?.User?.SocialSecurityNumber,
  });
 

  const patient = state?.patients?.data?.find(
    (item) => item.data.PatientId === extendedProps?.Patient?.PatientId
  )?.data;

  React.useEffect(() => {
    const form = {
      Name: patient?.Name,
      DateOfBirth: patient?.DateOfBirth,
      Email: patient?.Email,
      Phone: patient?.Phone,
      MetaData: patient?.MetaData,
      County: patient?.County,
      Address: patient?.Address,
      SocialSecurityNumber: patient?.SocialSecurityNumber,
    };
    setForm(form);
    validateForm(form);
  }, [patient]);
 
  const userGroup = state.patients.data
    ?.filter(
      (p) =>
        p.data.Phone === patient?.Phone &&
        p.data.PatientId !== patient.PatientId
    )
    ?.slice(0, userGroupLimit);

  return (
    <Form>
      <InputContainer>
        <TextField
          error={form?.Name?.length < 3}
          helperText={
            form?.Name?.length < 3 && "Name must be at least 3 characters"
          }
          value={form?.Name}
          label="Patient Name"
          variant="outlined"
          onChange={(e) => {
            setForm({
              ...form,
              Name: e.target.value,
            });
          }}
          onBlur={() => validateForm(form)}
        />
      </InputContainer>
      <InputContainer>
        <div
          style={{
            display: "flex",
            gap: "1rem",
          }}
        >
          <TextField
            value={date.split("-")[2]}
            variant="outlined"
            label="(DoB) - Day"
            onChange={(e) => {
              const year = date?.split("-")[0];
              const month = date?.split("-")[1];
              const day = e.target.value;
              setDate(`${year}-${month}-${day}`);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              const year = date.split("-")[0];
              const month = date.split("-")[1];
              let DateOfBirth = null;
              if (
                date.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
              ) {
                DateOfBirth = date;
              } else {
                DateOfBirth = `${year}-${month}-01`;
              }
              if (DateOfBirth) {
                setDate(DateOfBirth);
                setForm({
                  ...form,
                  DateOfBirth,
                });
                validateForm({
                  ...form,
                  DateOfBirth,
                });
              }
            }}
          />
          <TextField
            value={date.split("-")[1]}
            variant="outlined"
            label="Month"
            onChange={(e) => {
              const year = date?.split("-")[0];
              const month = e.target.value;
              const day = date?.split("-")[2];
              setDate(`${year}-${month}-${day}`);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={(e) => {
              const year = date.split("-")[0];
              const day = date.split("-")[2];
              let DateOfBirth = null;

              if (
                date.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
              ) {
                DateOfBirth = date;
              } else {
                DateOfBirth = `${year}-01-${day}`;
              }

              if (DateOfBirth) {
                setDate(DateOfBirth);
                setForm({
                  ...form,
                  DateOfBirth,
                });
                validateForm({
                  ...form,
                  DateOfBirth,
                });
              }
            }}
          />
          <TextField
            value={date.split("-")[0]}
            variant="outlined"
            label="Year"
            onChange={(e) => {
              const year = e.target.value;
              const month = date?.split("-")[1];
              const day = date?.split("-")[2];
              setDate(`${year}-${month}-${day}`);
            }}
            onFocus={(e) => {
              e.target.select();
            }}
            onBlur={() => {
              const month = date.split("-")[1];
              const day = date.split("-")[2];
              let DateOfBirth = null;
              if (
                date.match(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
              ) {
                DateOfBirth = date;
              } else {
                DateOfBirth = `1900-${month}-${day}`;
              }
              if (DateOfBirth) {
                setDate(DateOfBirth);
                setForm({
                  ...form,
                  DateOfBirth,
                });
                validateForm({
                  ...form,
                  DateOfBirth,
                });
              }
            }}
          />
        </div>
      </InputContainer>
      {userGroup.length > 0 && (
        <InputContainer>
          <div
            style={{
              border: "1px solid lightgrey",
              borderRadius: "5px",
              padding: "0 1rem",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <InputLabel style={{ marginTop: "1rem" }}>
                Relatives: ({userGroup.length})
              </InputLabel>
              <IconButton
                onClick={() => setOpenRelatives(!openRelatives)}
                size="large"
              >
                {openRelatives ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </div>
            <Collapse in={openRelatives}>
              <div>
                {userGroup.map((item) => (
                  <div>
                    <ListItem component="nav" alignitems="flex-start">
                      <ListItemAvatar>
                        <Avatar alt={item?.data?.Name}>
                          {item?.data?.Name.split(" ")
                            .map((i) => i.charAt(0).toUpperCase())
                            .slice(0, 2)}
                        </Avatar>
                      </ListItemAvatar>

                      <ListItemText
                        primary={
                          <Typography component="div">
                            {item?.data?.Name}
                          </Typography>
                        }
                        secondary={
                          <React.Fragment>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                                width: "100%",
                                alignItems: "end",
                              }}
                            >
                              Dob:{" "}
                              {item?.data?.DateOfBirth &&
                              isValid(
                                new Date(parseISO(item?.data?.DateOfBirth))
                              )
                                ? format(
                                    new Date(parseISO(item?.data?.DateOfBirth)),
                                    "yyy-MM-dd"
                                  )
                                : "NA"}
                            </div>
                          </React.Fragment>
                        }
                      />
                      <FormControl
                        variant="outlined"
                        style={{ width: "6.4rem" }}
                      >
                        <InputLabel>Relation</InputLabel>
                        <Select
                          value={
                            form?.MetaData?.Relatives?.find(
                              (r) => r.PatientId === item?.data?.PatientId
                            )?.Relation || ""
                          }
                          label="Relation"
                          variant="outlined"
                          onChange={(e) => {
                            const relatives =
                              form?.MetaData?.Relatives?.filter(
                                (i) => i.PatientId !== item?.data?.PatientId
                              ) || [];

                            const newRelative = {
                              PatientId: item?.data?.PatientId,
                              Relation: e.target.value,
                            };
                            setForm({
                              ...form,
                              MetaData: {
                                Relatives: [...relatives, newRelative],
                              },
                            });
                            validateForm({
                              ...form,
                              MetaData: {
                                Relatives: [...relatives, newRelative],
                              },
                            });
                          }}
                        >
                          <MenuItem disabled value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="Parent">Parent</MenuItem>
                          <MenuItem value="Child">Child</MenuItem>
                          <MenuItem value="Other">Other</MenuItem>
                        </Select>
                      </FormControl>
                    </ListItem>
                  </div>
                ))}
                {userGroup.length >= userGroupLimit && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "1rem",
                    }}
                  >
                    <Button
                      onClick={() => setUserGroupLimit(userGroupLimit + 5)}
                      variant="outlined"
                    >
                      Show More
                    </Button>
                  </div>
                )}
              </div>
            </Collapse>
          </div>
        </InputContainer>
      )}
      <InputContainer>
        <TextField
          error={form?.Email?.length > 0 && !form?.Email?.match(emailRegex)}
          helperText={
            form?.Email?.length > 0 && !form?.Email?.match(emailRegex)
              ? "Invalid Email"
              : ""
          }
          value={form?.Email}
          label="Patient Email"
          variant="outlined"
          onChange={(e) => {
            setForm({
              ...form,
              Email: e.target.value,
            });
          }}
          onBlur={() => validateForm(form)}
        />
      </InputContainer>
      <InputContainer>
        <div
          style={{
            display: "grid",
            gap: "1rem",
            gridTemplateColumns: "1fr 1fr",
          }}
        >
          <div>
            <PrefixList
              code={form?.MetaData?.PhonePrefix}
              onChange={(_, PhonePrefix) => {
                setForm({
                  ...form,
                  MetaData: {
                    ...form?.MetaData,
                    PhonePrefix,
                  },
                });
                validateForm({
                  ...form,
                  MetaData: {
                    ...form?.MetaData,
                    PhonePrefix,
                  },
                });
              }}
            />
          </div>
          <TextField
            error={form?.Phone?.length > 0 && !phoneRegex.test(form?.Phone)}
            helperText={
              form?.Phone?.length > 0 && !phoneRegex.test(form?.Phone)
                ? "Phone must be a number"
                : ""
            }
            value={form?.Phone}
            label="Patient Phone"
            variant="outlined"
            onChange={(e) => {
              setForm({
                ...form,
                Phone: e.target.value,
              });
            }}
            onBlur={() => validateForm(form)}
          />
        </div>
      </InputContainer>
      <InputContainer>
        <TextField
          value={form?.Address}
          label="Patient Address"
          variant="outlined"
          onChange={(e) => {
            setForm({
              ...form,
              Address: e.target.value,
            });
          }}
          onBlur={() => validateForm(form)}
        />
      </InputContainer>
      <InputContainer>
        <TextField
          value={form?.County}
          label="Patient County"
          variant="outlined"
          onChange={(e) => {
            setForm({
              ...form,
              County: e.target.value,
            });
          }}
          onBlur={() => validateForm(form)}
        />
      </InputContainer>
      <InputContainer>
        <OutlinedInput
          autocomplete="off"
          disabled={password}
          value={
            password
              ? form?.SocialSecurityNumber?.length
                ? "*".repeat(form?.SocialSecurityNumber?.length)
                : ""
              : form?.SocialSecurityNumber
          }
          type={"text"}
          startAdornment={
            <InputAdornment position="start">Pps:</InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              {password ? (
                <>
                  <VisibilityIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setPassword(false)}
                  />
                </>
              ) : (
                <>
                  <VisibilityOffIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => setPassword(true)}
                  />
                </>
              )}
            </InputAdornment>
          }
          onChange={(e) => {
            setForm({
              ...form,
              SocialSecurityNumber: e.target.value,
            });
          }}
          onBlur={() => validateForm(form)}
        />
      </InputContainer>
      <InputContainer>
        <div
          style={{
            display: "flex",
            width: "100%",
          }}
        >
          <FormControlLabel
            onClick={() => {
              const MetaData = {
                ...form?.MetaData,
                HasMedicalCard: !form?.MetaData?.HasMedicalCard
                  ? "checked"
                  : false,
              };
              setForm({
                ...form,
                MetaData,
              });
              validateForm({
                ...form,
                MetaData,
              });
            }}
            control={
              <Checkbox
                style={{ alignSelf: "baseline" }}
                checked={form?.MetaData?.HasMedicalCard === "checked"}
                name="Medical Card"
                color="primary"
              />
            }
            label={"Medical Card"}
          />
          <FormControlLabel
            onClick={() => {
              const MetaData = {
                ...form?.MetaData,
                Lfield3: !form?.MetaData?.Lfield3 ? "YES" : false,
              };
              setForm({
                ...form,
                MetaData,
              });
              validateForm({
                ...form,
                MetaData,
              });
            }}
            control={
              <Checkbox
                style={{ alignSelf: "baseline" }}
                checked={form?.MetaData?.Lfield3 === "YES"}
                name="GDPR"
                color="primary"
              />
            }
            label={"GDPR"}
          />
        </div>
      </InputContainer>
      <div
        style={{
          display: "flex",
        }}
      >
        <Button
          style={{ marginRight: "1rem", width: "100%" }}
          variant="contained"
          onClick={close}
        >
          Close
        </Button>
        <Button
          style={{ marginRight: "1rem", width: "100%" }}
          color="secondary"
          variant="contained"
          onClick={() => {
            setRemovePatient(true);
          }}
        >
          Delete
        </Button>

        {extendedProps ? (
          <Button
            disabled={updating || !!error}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={handleChange}
          >
            {updating ? <DoubleBounce color="white" /> : "Update"}
          </Button>
        ) : (
          <Button
            disabled={updating || !!error}
            color="primary"
            variant="contained"
            style={{ width: "100%" }}
            onClick={handleCreate}
          >
            Create
          </Button>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginTop: "1rem",
        }}
      >
        {error && <Alert severity="warning">{error}</Alert>}
        {updating && <Alert severity="info">Updating Patient Data ....</Alert>}
        {responseError && <Alert severity="error">{responseError}</Alert>}
        {showSuccess && (
          <Alert severity="success">Patent Data Updated Successfully!</Alert>
        )}
      </div>
      <ConfirmModal
        showModal={removePatient}
        hideModal={() => setRemovePatient(false)}
        continueClick={async () => {
          await deletePatient(ADMIN_NS, extendedProps?.Patient?.PatientId);
          close();
        }}
      />
    </Form>
  );
};

export default PatientForm;
