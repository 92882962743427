import axios from "axios";
import {
  handleResponse,
  authHeader,
  authFileHeader,
} from "../../library/handler";

export const getPayments = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/payments`, {
    method: "post",
    headers: await authHeader(state),
    body: JSON.stringify(data),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const getCharges = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/charges`, {
    method: "post",
    headers: await authHeader(state),
    body: JSON.stringify(data),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createUnFixPayment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/payment/unfixed`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createNoChargePayment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/payment/nocharge`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteNoChargePayment = async (state, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/payments/payment/nocharge/${uuid}`,
    {
      method: "delete",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateUnFixPayment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/payment/unfixed`, {
    method: "put",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createPayment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/appointments/payment`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createCharge = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/charge`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteCharge = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/payments/charge/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createInvoice = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/invoices/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateInvoice = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/invoices/update/${uuid}`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteInvoice = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/invoices/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const sendInvoice = async (state, data) => {
  const form = new FormData();
  form.append("file", data.content);
  return axios
    .post(`${process.env.REACT_APP_APP_API}/payments/invoice/send`, form, {
      headers: {
        ...authFileHeader(state),
        user: JSON.stringify(data.user),
        practice: JSON.stringify(data.practice),
      },
      onUploadProgress: (ProgressEvent) => {
        console.log((ProgressEvent.loaded / ProgressEvent.total) * 100);
      },
    })
    .then((response) => response.data)
    .catch((err) => console.log("Fetch Error :-S", err));
};
