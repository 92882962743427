import A18 from "./A18";
import A17 from "./A17";
import A16 from "./A16";
import A15 from "./A15";
import A14 from "./A14";
import A13 from "./A13";
import A12 from "./A12";
import A11 from "./A11";

import A21 from "./A21";
import A22 from "./A22";
import A23 from "./A23";
import A24 from "./A24";
import A25 from "./A25";
import A26 from "./A26";
import A27 from "./A27";
import A28 from "./A28";

export default {
  A18,
  A17,
  A16,
  A15,
  A14,
  A13,
  A12,
  A11,
  A21,
  A22,
  A23,
  A24,
  A25,
  A26,
  A27,
  A28, 
};
