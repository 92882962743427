import React, { useState, useCallback, useRef, useEffect } from "react";
import { useEventListener } from "./handler";
import styled from "styled-components";

const ContextMenu = styled.div`
  position: fixed;
  display: ${(props) => (props.show ? `grid` : `none`)};
  background: white;
  box-shadow: 0px 2px 10px #999999;
  z-index: 1;
`;

const ContextHeader = styled.div`
  padding: 6px 50px 5px 10px;
  font-size: 12px;
  font-weight: 600;
`;

const ContextOption = styled.div`
  padding: 6px 50px 5px 10px;
  cursor: default;
  font-size: 12px;
  &:hover {
    background: linear-gradient(to top, #555, #333);
    color: white;
  }

  &:active {
    color: #e9e9e9;
    background: linear-gradient(to top, #555, #444);
  }
`;

const Context = ({ targetId, header, menu }) => {
  const ctxEl = useRef(0);
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, [ctxEl]);

  // Event handler utilizing useCallback ...
  // ... so that reference never changes.
  const handler = useCallback(
    (e) => {
      const getContId = (t) =>
        t &&
        t.attributes &&
        (t.attributes.getNamedItem(targetId)
          ? t.attributes.getNamedItem(targetId).value
          : getContId(t.parentNode));

      const id = getContId(e.target);
      if (!id) return null;
      e.preventDefault();
      e.stopImmediatePropagation();
      setVisible(id);

      const clickX = e.clientX;
      const clickY = e.clientY;
      const screenW = window.innerWidth;
      const screenH = window.innerHeight;
      const rootW = ctxEl.current.offsetWidth;
      const rootH = ctxEl.current.offsetHeight;

      const right = screenW - clickX > rootW;
      const left = !right;
      const top = screenH - clickY > rootH;
      const bottom = !top;

      if (right) {
        ctxEl.current.style.left = `${clickX + 5}px`;
      }

      if (left) {
        ctxEl.current.style.left = `${clickX - rootW - 5}px`;
      }

      if (top) {
        ctxEl.current.style.top = `${clickY + 5}px`;
      }

      if (bottom) {
        ctxEl.current.style.top = `${clickY - rootH - 5}px`;
      }
    },
    [setVisible, ctxEl, targetId]
  );

  const clickHandler = useCallback(
    (e) => {
      const wasOutside = !(e.target.contains === ctxEl);
      if (wasOutside && visible) setVisible(false);
    },
    [visible]
  );
  // Add event listener using our hook
  useEventListener("contextmenu", handler);
  useEventListener("click", clickHandler);

  return (
    <ContextMenu ref={ctxEl} show={visible}>
      {header && <ContextHeader>{header}</ContextHeader>}
      {menu.map((m) => (
        <ContextOption
          key={m.text}
          onClick={() => visible && m.callback(visible)}
        >
          {m.icon} {m.text}
        </ContextOption>
      ))}
    </ContextMenu>
  );
};

export default Context;
