import React, { useEffect, useRef, useContext } from "react";
import colors from "../../library/styled-components/colors";
import DoubleBounce from "../../components/loaders/double-bounce";
import { Container, Header, Content, ContentWrapper } from "./styles";
import { AppContext } from "../../hooks/context";

export default function Layout({
  meta = { updating: false },
  children,
  menu = false,
  home = true,
  menuContent = null,
  openSide,
}) {
  const layoutTopRef = useRef(null);
  const {
    app: {
      scrollEvent: [scroll, setLayoutScrollTo],
    },
  } = useContext(AppContext);

  const scrollToTop = () => {
    layoutTopRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    if (scroll) {
      scrollToTop();
      setLayoutScrollTo(false);
    }
  }, [scroll]);

  return (
    <Container open={openSide}>
      {menu && (
        <Header home={home}>{menuContent ? menuContent : <div />}</Header>
      )}
      <div ref={layoutTopRef} />
      <Content>
        <ContentWrapper>
          {meta && meta.updating && (
            <div>
              <DoubleBounce color={colors.primColor} margin="50%" />
            </div>
          )}
          {meta && !meta.updating && children}
        </ContentWrapper>
      </Content>
    </Container>
  );
}
