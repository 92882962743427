import React from "react";
import { Tooltip, Typography } from "@mui/material";
import { Comment, BlockOutlined } from "@mui/icons-material";
import { EventBlock, EventBlockContainer } from "./Common";

const BlockEventBlock = ({ info, setAppointmentBlockPreview }) => {
  const Notes =
    info.event.extendedProps?.Meta?.Comments?.map((c) => c.text) || [];

  if (!Notes.length) {
    return (
      <EventBlockContainer
        onClick={() => setAppointmentBlockPreview(info.event.extendedProps)}
      >
        <EventBlock>
          <div style={{ display: "flex" }}>
            <div>
              <BlockOutlined />
            </div>
          </div>
          <div>{info.event.title}</div>

          <Comment color={"disabled"} />
        </EventBlock>
      </EventBlockContainer>
    );
  }

  return (
    <EventBlockContainer
      onClick={() => setAppointmentBlockPreview(info.event.extendedProps)}
    >
      <Tooltip
        arrow
        placement="top-end"
        title={
          <React.Fragment>
            <Typography color="inherit">
              {info.event.extendedProps?.AppointmentId ? info.event.title : ""}
            </Typography>
            {Notes.map((n) => (
              <Typography key={n} component="div">
                - {n}
              </Typography>
            ))}
          </React.Fragment>
        }
      >
        <EventBlock>
          <div style={{ display: "flex" }}>
            <div>
              <BlockOutlined />
            </div>
          </div>
          <div>{info.event.title}</div>

          <Comment color={"success"} />
        </EventBlock>
      </Tooltip>
    </EventBlockContainer>
  );
};

export default BlockEventBlock;
