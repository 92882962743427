import one from "./img/teeth/table4/28b.png";
import oneImplant from "./img/teeth/table4/implants/28b.png";
import oneRemoved from "./img/teeth/table4/removed/28b.png";

import two from "./img/teeth/table4/27b.png";
import twoImplant from "./img/teeth/table4/implants/27b.png";
import twoRemoved from "./img/teeth/table4/removed/27b.png";

import three from "./img/teeth/table4/26b.png";
import threeImplant from "./img/teeth/table4/implants/26b.png";
import threeRemoved from "./img/teeth/table4/removed/26b.png";

import four from "./img/teeth/table4/25b.png";
import fourImplant from "./img/teeth/table4/implants/25b.png";
import fourRemoved from "./img/teeth/table4/removed/25b.png";

import five from "./img/teeth/table4/24b.png";
import fiveImplant from "./img/teeth/table4/implants/24b.png";
import fiveRemoved from "./img/teeth/table4/removed/24b.png";

import six from "./img/teeth/table4/23b.png";
import sixImplant from "./img/teeth/table4/implants/23b.png";
import sixRemoved from "./img/teeth/table4/removed/23b.png";

import seven from "./img/teeth/table4/22b.png";
import sevenImplant from "./img/teeth/table4/implants/22b.png";
import sevenRemoved from "./img/teeth/table4/removed/22b.png";

import eight from "./img/teeth/table4/21b.png";
import eightImplant from "./img/teeth/table4/implants/21b.png";
import eightRemoved from "./img/teeth/table4/removed/21b.png";

export const fourSettings = {
  imgs: [
    {
      id: 18,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      }, 
      pos: "0 23px",
      width: "51px",
    },
    {
      id: 17,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      }, 
      pos: "0 25px",
      width: "57px",
    },
    {
      id: 16,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      }, 
      pos: "0 23px",
      width: "63px",
    },
    {
      id: 15,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 16px",
      width: "43px",
    },
    {
      id: 14,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 16px",
      width: "44px",
    },
    {
      id: 13,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "0 16px",
      width: "44px",
    },
    {
      id: 12,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 16px",
      width: "45px",
    },
    {
      id: 11,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 13px",
      width: "48px",
    },
  ],
};

export const fourState = {
  margin: [
    [7, 63],
    [27.5, 63],
    [48, 63],

    [62, 63],
    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  depth: [
    [7, 63],
    [27.5, 63],
    [48, 63],
    [62, 63],

    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  imgState: [
    {
      id: 21,
    },
    {
      id: 22,
    },
    {
      id: 23,
    },
    {
      id: 24,
      furcation: [
        { state: null, pos: [168, 98] },
        { state: null, pos: [149, 92] },
      ],
    },
    {
      id: 25,
    },
    {
      id: 26, 
      furcation: [
        { state: null, pos: [278, 94] },
        { state: null, pos: [241, 84] },
      ],
    },
    {
      id: 27, 
      furcation: [
        { state: null, pos: [334, 94] },
        { state: null, pos: [309, 98] },
      ],
    },
    {
      id: 28,
      furcation: [
        { state: null, pos: [386, 82] },
        { state: null, pos: [360, 88] },
      ],
    },
  ],
};
