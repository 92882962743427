import { handleResponse, authHeader } from "../../library/handler";

export async function getConsultationById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/id/${uuid}`)
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getConsultations = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/find/${uuid}`, {
    method: "get",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createConsultation = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createNote = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/notes/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const createChart = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/chart/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateChart = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/consultations/chart/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteChart = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/chart/${uuid}`, {
    method: "delete",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteDocument = async (state, uuid, patientId) => {
  return fetch(`${process.env.REACT_APP_APP_API}/files/${uuid}/${patientId}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const addCommentTag = async (state, data, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/comment/tag`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const updateConsultation = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/consultations/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const deleteConsultation = async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/consultations/${uuid}`, {
    method: "delete",
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
