import React from "react";
import styled from "styled-components";
import Editor from "../../editor";
import { formSchema, createButtons } from "./formSettings";
import { FadeDiv } from "../../../library/styled-components";
import { Link } from "../../common";

const LoginBlockContainer = styled(FadeDiv)`
  display: grid;
  grid-template-rows: auto 8em 2em;
  background-color: ${(props) => props.colors.secColor};
`;

function LoginBlock({
  loginUser,
  goToRegister,
  goToReset,
  fade,
  colors,
  meta,
}) {
  return (
    <LoginBlockContainer fade={fade} colors={colors}>
      <Editor
        submitError={meta && meta.error}
        warning={meta && meta.warning}
        formSchema={formSchema}
        metaData={meta}
        buttons={createButtons}
        sendData={loginUser}
        colors={colors}
        settings={{
          width: { mobile: "100%", desktop: "100%" },
        }}
      />
      <div>
        {goToReset && (
          <Link onClick={goToReset} colors={colors} size="1.1">
            Forgot your Password?
          </Link>
        )}
        {goToRegister && (
          <Link onClick={goToRegister} colors={colors} size="1.1">
            Register new Account
          </Link>
        )}
      </div>
    </LoginBlockContainer>
  );
}

export default LoginBlock;
