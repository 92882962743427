import { handleResponse, authHeader, authBody } from "../../library/handler";

export const sendSms = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/sms/send`, {
    method: "post",
    body: JSON.stringify(authBody(data, state)),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const sendBatchSms = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/sms-queue/batch/create`, {
    method: "post",
    body: JSON.stringify(authBody(data, state)),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const changeBatchStatus = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/sms-queue/batch/${data.batchId}`, {
    method: "put",
    body: JSON.stringify(authBody(data, state)),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getBatchRecall = async (state) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/sms-queue/batch`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export const getPatientSmsFeed = async (state, data) => {
  return await fetch(
    `${process.env.REACT_APP_APP_API}/patients/smsfeed/${data.patientId}`,
    {
      method: "get",
      headers: await authHeader(state),
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

export const marekAsRead = async (state, data) => {
  return await fetch(`${process.env.REACT_APP_APP_API}/sms/read`, {
    method: "post",
    body: JSON.stringify(authBody(data, state)),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};
