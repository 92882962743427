import React from "react";
import { Button, Popover } from "@mui/material";
import { AccountBalanceOutlined } from "@mui/icons-material";
import { useContext } from "react";
import { AppContext } from "hooks/context";
import { useState } from "react";
import ChargeForm from "containers/admin/home/reports/components/ChargeForm";

const CreateCharge = ({ selectedPatient, createCharge }) => {
  const { state, dispatch } = useContext(AppContext);
  const { list, patients, treatments } = state;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [chargeError, setChargeError] = useState(null);

  const openPop = Boolean(anchorEl);
  const popId = openPop ? "simple-popover" : undefined;

  const handlePopClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="success"
        size="small"
        startIcon={<AccountBalanceOutlined />}
        onClick={handlePopClick}
      >
        Add Charge
      </Button>
      <Popover
        id={popId}
        open={openPop}
        anchorEl={anchorEl}
        onClose={handlePopClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <ChargeForm
          patientId={selectedPatient.PatientId}
          patients={patients}
          practitioners={list}
          treatments={treatments}
          error={chargeError}
          createCharge={(data) => {
            const { amount, practitionerId, proceedure, patientId } = data;
            const check = amount && practitionerId && proceedure && patientId;
            setChargeError(null);
            if (check) {
              createCharge({ amount, practitionerId, proceedure, patientId });
              setAnchorEl(null);
            } else {
              setChargeError("Missing data");
            }
          }}
          cancelMethod={() => {
            setAnchorEl(null);
          }}
        />
      </Popover>
    </>
  );
};

export default CreateCharge;
