import styled, { keyframes, css } from "styled-components";

const SlideOutOfFrame = keyframes`
  0%{
    transform: translate(0);
    -webkit-transform:: translate(0);
  }
  100% {
    transform: translate(-100vw);
    -webkit-transform: translate(-100vw);
  }
`;

const SlideIntoFrame = keyframes`
  0%{
    transform: translate(100vw);
    -webkit-transform: translate(100vw);
  }
  100% {
    transform: translate(0);
    -webkit-transform: translate(0);
  }
`;

const FadeAway = keyframes`
  0% {
    opacity:1;
  }
  100% {
    opacity:0;
  }
`;

const FadeIn = keyframes`
  0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const FadeDiv = styled.div`
  ${props =>
    props.fade.out &&
    css`
      animation: ${FadeAway} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
      -webkit-animation: ${FadeAway} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
    `};
  ${props =>
    props.fade.in &&
    css`
      animation: ${FadeIn} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
      -webkit-animation: ${FadeIn} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
    `};
  animation-fill-mode: cubic-bezier;
  -webkit-animation-fill-mode: cubic-bezier;
`;

const SlideDiv = styled.div`
  display: none;
  ${props =>
    props.slideOut &&
    css`
      animation: ${SlideOutOfFrame} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
    `};
  ${props =>
    props.slideIn &&
    css`
      display: block;
      animation: ${SlideIntoFrame} 500ms cubic-bezier(0.1, 0.7, 1, 0.1);
    `};
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
`;

const SlideCont = styled.div`
  ${props =>
    props.slide.out &&
    css`
      animation: ${SlideOutOfFrame} 500ms forwards;
    `};
  ${props =>
    props.slide.in &&
    css`
      animation: ${SlideIntoFrame} 500ms forwards;
    `};
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
`;

const FadeAwayEL = el => styled[el]`
  animation: ${FadeAway} 500ms forwards;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
`;

const FadeInEl = el => styled[el]`
  animation: ${FadeIn} 500ms forwards;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
`;

export { SlideDiv, FadeDiv, FadeInEl, FadeAwayEL, SlideCont };
