import React, { useRef, useEffect, useMemo } from "react";
import TextEditor from "components/text-editor";
import parse from "html-react-parser";
import colors from "library/styled-components/colors";
import InfoPanel from "layouts/components/info";
import moment from "moment";
import { Note, NoteDate, NoteHeader, NoteName, Notes, NotesContainer, NoteText } from "components/common"; 

const NotesTab = ({ notes, createNote }) => {
  const messagesEndRef = useRef(null);

  const sorted = useMemo(() => {
    return notes
      ?.sort((a, b) => a.id - b.id)
  }, [notes]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "end",
      inline: "nearest",
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [notes]);

  return (
    <NotesContainer>
      <Notes>
        {sorted &&
          sorted
            .sort(function (a, b) {
              var c = new Date(a.createdAt);
              var d = new Date(b.createdAt);
              return c - d;
            })
            .map((note) => (
              <Note key={note.id}>
                <NoteHeader>
                  <NoteName>{note.User.Name}</NoteName>
                  <NoteDate>{moment(note.createdAt).fromNow()}</NoteDate>
                </NoteHeader>
                <NoteText>{parse(note.Note)}</NoteText>
              </Note>
            ))}
        {(!sorted || sorted.length === 0) && (
          <InfoPanel style={{ color: colors.grey }}>
            <h1>Consultation Notes</h1>
            <p>
              If you wish to write notes concerning the patient,
              <br /> you can type in the box below!
            </p>
          </InfoPanel>
        )}
        <div ref={messagesEndRef} />
      </Notes>
      <TextEditor
        saveText={createNote}
      />
    </NotesContainer>
  );
};

export default NotesTab;
