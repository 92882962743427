import React from "react";
import CloseCircleFeaIcon from "../../icon/fea-icons/close-circle";
import { ModalBox, ModalContent, WrapIcon } from "../../components/common";

const TermsModal = ({ terms, closeTerms }) => {
  return (
    <ModalBox show={terms}>
      <WrapIcon onClick={closeTerms}>
        <CloseCircleFeaIcon size="44" />
      </WrapIcon>
      <ModalContent
        width="calc(100vw - 10rem)"
        height="calc(100vh - 8rem)"
      >
        <div style={{ textAlign: "left" }}>
          <h1>Privacy Policy</h1>
          <p>
            We seek your consent to obtain and process personal data for the
            purpose of providing you with dental treatment safely and to the
            highest standards. This Statement is your guide to the principles of
            privacy and confidentiality which govern the collection, use,
            storage, disclosure and destruction of your personal data in this
            practice. The data compliance officer in this practice is Dr Gary
            Squires.
          </p>
          <p>
            Obtaining personal information <br />
            It is important to obtain, use and store information about you, your
            general and your dental health in order to provide dental care
            efficiently and reduce the risk of injury or other damage to your
            health. This personal data includes:
            <ul>
              <li>
                Personal details such as your name, age, address, telephone
                numbers, email address
              </li>
              <li>Your doctor and relevant Medical Consultant(s)</li>
              <li> Your medical and dental history</li>
              <li>X-rays, clinical photographs and study models</li>
              <li>
                Information about proposed treatment, options, consent to
                treatment, treatment provided and its cost
              </li>
              <li>
                Notes of conversations or incidents that might occur for which a
                record needs to be kept
              </li>
              <li>
                Any correspondence with other healthcare professionals relating
                to you including agreed referrals to other healthcare
                professionals
              </li>
            </ul>
            Your personal information is stored on computer. This practice is
            registered with the Data Protection Commissioner. Your personal data
            is retained in your manual record. We will regularly update your
            personal data, including your medical care, to keep it relevant. We
            ask that you please inform us of any significant changes, such as a
            change of address or other contact details, at your earliest
            convenience. It is important to know that the collection, use or
            possible disclosure of this data may be crucial to our ability to
            safely provide you with the care you require; without your agreement
            to this process it may not be possible to undertake treatment. You
            have access to a copy of your personal data upon written request and
            the right to have data rectified if incorrect.
          </p>

          <p>
            Personal data is kept for specified, explicit and lawful purposes
            <br />
            Your personal data is obtained, kept and used primarily for the
            purpose of providing you with healthcare efficiently and safely at
            all times. Staff within the practice will have access to the data on
            a ‘need-to-know’ basis to ensure you receive the highest standard of
            care. In the course of your care, members of the dental team may
            access your records:
            <ul>
              <li>To prepare for and to complete your dental care</li>
              <li>To identify and print a prescription</li>
              <li> To generate a work certificate</li>
              <li>
                To type, if dictated or print a referral letter to another
                healthcare professional
              </li>
              <li>
                To open correspondence or any other documents from other
                healthcare professionals
              </li>
              <li>
                To print or photocopy your records if you instruct us to forward
                them to another healthcare professional
              </li>
              <li>
                To collate, print, photocopy and post insurance or medico-legal
                reports. It is practice policy to send you a reminder of when
                your next ‘check-up’ is due. This reminder is sent to you by
                post, electronic mail and text message. We seek your consent to
                use your personal data for this propose and advise you of your
                right to refuse to have your data used for this purpose.
              </li>
            </ul>
          </p>

          <p>
            Personal data is only used and disclosed for the purpose of your
            care
            <br />
            All members of the dental team adhere to the practice’s Code on
            Confidentiality in compliance with the Data Protection Acts, 1988
            and 2003, and the Dental Council’s Code of Practice relating to
            Professional Behaviour and Ethical Conduct, 2012. Any disclosure of
            personal data, without your consent, can only be done for specified,
            legitimate reasons (8 (a-h), Data Protection Act, 1988; Section 10,
            Dental Council’s Code of Practice relating to Professional Behaviour
            and Ethical Conduct, 2012). Access to your personal data is on a
            ‘need-to-know’ basis. This prohibits the release of your information
            to a spouse, partner or family member without your explicit consent.
            A guardian or carer may have the right to access information in the
            case of vulnerable adults or those with diminished mental capacity.
            A parent or guardian will have access to your personal information
            if you are less than 16 years of age. A copy of your dental records
            will be transferred to another practice or healthcare professional
            upon your written request. Your consent will be sought before the
            release of any data to other healthcare professionals and then only
            the relevant part of your records will be released. All healthcare
            professionals are required to treat your personal data to the same
            standard of privacy as outlined in this statement. Your consent will
            be sought in the case of:
            <ul>
              <li>A report to dental insurance company</li>
              <li> A medico-legal report</li>
              <li>
                Any documentation relating to a “third party” Dental Scheme
                (e.g. Medical Card or PRSI scheme) There are certain activities
                where patient information may be used but where the information
                is anonymized, eliminating patient identification:
              </li>
              <li>Teaching</li>
              <li>
                Continuing Professional Development. Case studies are a very
                useful learning tool
              </li>
              <li>
                Quality Assurance/Internal audit. Audit is a necessary tool in
                assessing and assuring the quality of your care
              </li>
              <li>Research </li>
            </ul>
            If your dentist should cease practice or should die while still a
            practicing dentist, the dental team will be guided by the Dental
            Council’s Code of Practice relating to Professional Behaviour and
            Ethical Conduct in informing you, safeguarding your personal data
            and ensuring continuity of care where possible. Every effort is made
            to ensure disclosed personal data is accurate and transferred
            securely.
          </p>

          <p>
            Personal data is kept safely
            <br />
            Dr Gary Squires is responsible for data security in this practice.
            Obtained personal data is accessed on a ‘need-to-know’ basis and
            thereafter, is stored securely:
            <ul>
              <li>
                There is no access for unauthorized persons to manual records,
                fax machines, computers or computer monitors within the
                practice.
              </li>
              <li>
                The dental team is trained in the secure use of fax machines,
                email and the internet
              </li>
              <li>
                The dental team is compliant with the practice’s security
                measures
              </li>
              <li>Manual records are stored under lock and key</li>
              <li>The practice premises is securely locked when unoccupied</li>
              <li> The practice software is legally owned</li>
              <li>
                The practice software is updated regularly and password
                protected
              </li>
              <li> Software security is audited </li>
            </ul>
            Dr Gary Squires is responsible for dealing with any incident where
            personal data has been put at risk of unauthorized disclosure, loss,
            destruction or alteration. Management of any breach incident will
            comply with the advice of the Data Protection Commissioner (Personal
            Data Security Breach Code of Practice).
          </p>

          <p>
            Personal data is kept accurate, complete and up-to-date <br />A
            staff member will review your personal information with you on a
            regular basis to ensure we hold accurate, high quality records for
            you. Any changes to your personal details, your medical or dental
            status will be recorded in your records. We ask you to let us know
            of any changes in contact details at your earliest convenience.
          </p>

          <p>
            Personal data is adequate, relevant and not excessive
            <br />s Every effort is made to ensure that the information we
            collect and retain for you is in keeping with our aim to provide you
            with an efficient service and to care for you safely. We will
            explain the purpose of any information sought if you are not sure
            why. Personal data is retained for no longer than necessary We
            retain all adult records for 8 years after the last treatment. In
            the case of children and young adults, the records are kept until
            the patient’s 25th birthday; or their 26th birthday if the young
            person was 17 when they finished treatment. If a patient dies before
            their 18th birthday, records are kept for 8 years. All records are
            disposed by a secure, certified, method of destruction (Dental
            Council Code of Practice relating to Professional Behaviour and
            Ethical Conduct, 2012).
          </p>

          <p>
            Your rights
            <br />
            You are legally entitled to a photocopy of your personal data upon
            written request. As well as a right of access you also have the
            right to have any inaccuracies in your data rectified and to have
            the data erased. You will be provided with a photocopy of an x-ray
            in response to an access request. All written requests should be
            addressed to: Dr Gary Squires
            <br />
            Glanmire Dental Practice
            <br />
            3 Old Court
            <br />
            Riverstown
            <br />
            Glanmire
            <br />
            Co. Cork
          </p>

          <p>
            Your request will be dealt with in a timely manner.
            <br />
            It is your right to have your name removed from all practice
            marketing information including ‘check-up’ recalls if you do not
            consider this information to be in your best interest. If you wish
            to be removed from our appointment texting service or our
            marketing/mailing lists please advise our reception staff. If you do
            not wish to have your personal data collected, used or disclosed as
            described in this Statement please discuss this matter with Dr Gary
            Squires. It is important to know that the collection, use or
            possible disclosure of this data may be crucial to our ability to
            safely provide you with the care you require; without your agreement
            to this process it may not be possible to undertake treatment. If
            you have a complaint or concern with any aspect of how we process
            your personal information we would hope that you would notify Dr
            Gary Squires in the first place. You retain the right to make a
            complaint to the Data Protection Commissioner at all times. If you
            have any questions in relation to this Statement or any issue that
            arises from it please speak with Dr Gary Squires.
          </p>
        </div>
      </ModalContent>
    </ModalBox>
  );
};

export default TermsModal;
