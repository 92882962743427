import React, { useContext, useState } from "react";
import styled, { css } from "styled-components";
import Input from "./input";
import Card from "./card";
import { SelectUi } from "./select-ui";
import Scheduler from "./scheduler";
import CheckBox from "./checkbox";
import Slider from "../../slider";
import TimeSlider from "../../slider/time-slider";
import FileUpload from "../../upload/file";
import DatePicker from "../../calendar/picker";
import DateInput from "./date";
import TimePicker from "../../calendar/picker/time";
import DateTimePicker from "../../calendar/picker/date-time";
import CheckButton from "../../check-button";
import CheckButtonGroup from "../../check-button-group";
import DoubleBounce from "../../loaders/double-bounce";
import FormButton from "../../form-button";
import { SubmitBtn, AltSubmitBtn } from "../../../components/common";
import { AppContext } from "../../../hooks/context";

const FormContainer = styled.div`
  display: grid;
  margin-bottom: 1rem;
  font-size: 0.9em;
`;

const FormField = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
  flex: 0 0 100%;
  max-width: 100%;
  text-align: left;
`;

const Buttons = styled.div`
  display: grid;
  width: inherit;
  grid-template-columns: 1fr;
  ${(props) =>
    props.settings.side &&
    css`
      grid-template-columns: auto auto;
      margin: 1em 0;
    `}
  ${(props) =>
    props.settings.fixed &&
    css`
      grid-template-columns: ${(props) => props.buttons.map(() => "1fr ")};
      bottom: 0;
      right: 0;
      @media (min-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.desktop};
      }
      @media (max-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.mobile};
      }
      position: fixed;
      width: 100%;
    `}
  ${(props) =>
    props.settings.absolute &&
    css`
      grid-template-columns: ${(props) => props.buttons.map(() => "1fr ")};
      bottom: 0;
      right: 0;
      @media (min-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.desktop};
      }
      @media (max-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.mobile};
      }
      position: absolute;
      width: 100%;
    `}

    ${(props) =>
    props.settings.sticky &&
    css`
      grid-template-columns: ${(props) => props.buttons.map(() => "1fr ")};
      bottom: 0;
      right: 0;
      @media (min-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.desktop};
      }
      @media (max-width: 481px) {
        width: ${(props) =>
          props.settings.width && props.settings.width.mobile};
      }
      position: sticky;
      margin-top: 1em;
    `}
`;

const Form = ({
  enabled,
  defaultData,
  prePopData,
  metaData,
  changeForm,
  formData,
  buttons,
  completeForm,
  cancelMethod,
  settings = { fixed: false },
  callBack,
  onChange,
}) => {
  const [error, setError] = useState({});
  const {
    app: {
      scrollEvent: [_, setLayoutScrollTo],
    },
  } = useContext(AppContext);

  const checkForm = (inputData, name) => {
    if (inputData.validation) {
      const check = inputData.validation.validate({
        [name]: inputData.val,
      });
      return (check.error && check.error.message) || null;
    }
  };

  function submitForm() {
    let submitData = Object.assign({}, formData);
    const valid = Object.keys(formData).map((element) => {
      if (submitData[element].ignore) return false;
      submitData[element].val = formData[element].val;
      // ? formData[element].val
      // : defaultData && defaultData[element];
      const value = checkForm(submitData[element], element);
      setError((prevState) => ({
        ...prevState,
        [element]: value,
      }));
      setLayoutScrollTo(true);
      return value;
    });
    if (valid.every((v) => !v)) {
      completeForm(submitData);
      setError({});
    }
  }

  return (
    <FormContainer>
      <FormField>
        {formData &&
          Object.keys(formData)
            .map((element) => {
              switch (formData[element].type) {
                case "text":
                case "textarea":
                case "tel":
                case "email":
                case "password":
                case "time":
                case "number":
                  return (
                    <Input
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      onChange={(val) => changeForm(element, "val", val)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        disable: formData[element].disable,
                        value: formData[element].val
                          ? formData[element].val
                          : "",
                      }}
                    />
                  );
                case "card":
                  return (
                    <Card
                      onChange={(val) => changeForm(element, "val", val)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                    />
                  );
                case "time-picker":
                  return (
                    <TimePicker
                      ampm={false}
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      onChange={(val) => changeForm(element, "val", val)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        disable: formData[element].disable,
                        value: formData[element].val
                          ? formData[element].val
                          : "",
                      }}
                    />
                  );
                case "date-time-picker":
                  return (
                    <DateTimePicker
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      onChange={(val) => changeForm(element, "val", val)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        value: formData[element].val
                          ? formData[element].val
                          : "",
                      }}
                    />
                  );
                case "time-slider":
                  return (
                    <TimeSlider
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      time={formData[element].time}
                      setTime={(val) => changeForm(element, "time", val)}
                      defaultTime={defaultData ? defaultData[element] : null}
                    />
                  );
                case "date-picker":
                  return (
                    <DatePicker
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        disable: formData[element].disable,
                        value: formData[element].val
                          ? formData[element].val
                          : "",
                      }}
                      icon={formData[element].icon}
                      onCallback={(val) =>
                        callBack && callBack(formData[element].call, val)
                      }
                      onChange={(val) => {
                        changeForm(element, "val", val);
                      }}
                      defaultDate={
                        defaultData && defaultData[element]
                          ? defaultData[element].val
                          : null
                      }
                    />
                  );
                case "basic-date-picker":
                  return (
                    <DateInput
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      onChange={(val) => changeForm(element, "val", val)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                        value: formData[element].val
                          ? formData[element].val
                          : "",
                      }}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        defaultValue: formData[element].defaultValue,
                        value: formData[element].val,
                      }}
                      defaultDate={
                        defaultData && defaultData[element]
                          ? defaultData[element]
                          : null
                      }
                    />
                  );
                case "checkbox":
                  return (
                    <CheckBox
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      DomRef={formData[element].DomRef}
                      onChange={(val) => {
                        changeForm(element, "val", val);
                      }}
                      openTerms={() => callBack("open-terms")}
                      LabelData={{
                        text: formData[element].text,
                        link: formData[element].link,
                        error: error[element],
                      }}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        options: formData[element].options,
                        value: formData[element].val
                          ? formData[element].val
                          : null,
                      }}
                    />
                  );
                case "slider":
                  return (
                    <Slider
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      label={formData[element].text}
                      slide={formData[element].slide}
                      setSlider={(val) => changeForm(element, "slide", val)}
                      defaultSlide={defaultData ? defaultData[element] : null}
                    />
                  );

                case "select":
                  return (
                    <SelectUi
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      color={formData[element].color}
                      onChange={(val) => {
                        changeForm(element, "val", val);
                        formData[element].call &&
                          callBack &&
                          callBack(formData[element].call, val);
                      }}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        defaultValue: formData[element].defaultValue,
                        value: formData[element].val,
                        disable: formData[element].disable,
                      }}
                      options={formData[element].options}
                    />
                  );
                case "button":
                  return (
                    <FormButton
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      color={formData[element].color}
                      onCallback={() => callBack(formData[element].call)}
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      InputData={{
                        type: formData[element].type,
                        placeholder: formData[element].placeholder,
                        value: formData[element].val,
                      }}
                    >
                      <div> {formData[element].text}&nbsp;</div>
                      {formData[element].icon}
                    </FormButton>
                  );
                case "scheduler":
                  return (
                    <Scheduler
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      color={formData[element].color}
                      onCallback={(data) =>
                        callBack(formData[element].call, data)
                      }
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      val={formData[element].val}
                      group={formData[element].group}
                      onChange={(val) => changeForm(element, "val", val)}
                      defaultUrl={defaultData ? defaultData[element] : null}
                    >
                      <div> {formData[element].text}&nbsp;</div>
                      {formData[element].icon}
                    </Scheduler>
                  );
                case "check-button":
                  return (
                    <CheckButton
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      val={formData[element].val}
                      onChange={(val) => changeForm(element, "val", val)}
                      defaultUrl={defaultData ? defaultData[element] : null}
                    />
                  );
                case "check-button-group":
                  return (
                    <CheckButtonGroup
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      icon={formData[element].icon}
                      val={formData[element].val}
                      group={formData[element].group}
                      onChange={(val) => changeForm(element, "val", val)}
                      defaultUrl={defaultData ? defaultData[element] : null}
                    />
                  );
                case "file-upload":
                  return (
                    <FileUpload
                      changed={!!(prePopData && prePopData[element])}
                      key={
                        formData[element].key
                          ? formData[element].key
                          : formData[element].text
                      }
                      LabelData={{
                        text: formData[element].text,
                        error: error[element],
                      }}
                      onChange={(val) => changeForm(element, "val", val)}
                      defaultUrl={defaultData ? defaultData[element] : null}
                    />
                  );
              }
            })
            .sort(function (a, b) {
              return parseInt(a.key, 10) < parseInt(b.key, 10)
                ? -1
                : parseInt(a.key, 10) > parseInt(b.key, 10)
                ? 1
                : 0;
            })}
      </FormField>
      <Buttons settings={settings} buttons={buttons}>
        {buttons &&
          buttons.map((btn) => {
            switch (btn.type) {
              case "Submit":
                return (
                  <SubmitBtn
                    style={{ margin: "0.1em" }}
                    onClick={() => submitForm()}
                    fixed={btn.fixed}
                    key={btn.text}
                    disabled={!enabled}
                  >
                    <span>
                      {metaData && metaData.updating ? (
                        <DoubleBounce size={20} />
                      ) : (
                        btn.text
                      )}
                    </span>
                  </SubmitBtn>
                );
              case "Revert":
                return (
                  <AltSubmitBtn
                    style={{ margin: "0.1em" }}
                    onClick={() => {
                      setError({});
                      cancelMethod();
                    }}
                    key={btn.text}
                  >
                    {btn.text}
                  </AltSubmitBtn>
                );
              default:
                break;
            }
          })}
      </Buttons>
    </FormContainer>
  );
};

export default Form;
