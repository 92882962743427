import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import { lighten } from "@mui/system";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import SendIcon from "@mui/icons-material/Send";
import FilterListIcon from "@mui/icons-material/FilterList";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import StandardLayout from "../../../../layouts/standard";
import Button from "@mui/material/Button";
import { AppContext } from "../../../../hooks/context";
import { Body, TitleHeader } from "../styles";
import { getTargetsList } from "./actions";
import {
  addMonths,
  endOfMonth,
  format,
  startOfMonth,
} from "date-fns";
import {
  FormContainer,
  InputContainer,
  ModalBox,
  PopToSideTop,
  Row,
  DateWrap,
} from "../../../../components/common";
import {
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Avatar,
} from "@mui/material";
import FeaCheckIcon from "../../../../icon/fea-icons/check";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import colors from "../../../../library/styled-components/colors";
import SearchInput from "../../../../components/search-input";

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const schema = ["Name", "Reason", "Information", "Phone"];

const headCells = [
  {
    id: "name",
    numeric: false,
    disablePadding: true,
    label: "Patient Name",
  },
  { id: "reason", numeric: false, disablePadding: false, label: "Reason" },
  { id: "info", numeric: false, disablePadding: false, label: "Info" },
  {
    id: "date",
    numeric: false,
    disablePadding: false,
    label: "Appointment Date",
  },
  {
    id: "date-cancel",
    numeric: false,
    disablePadding: false,
    label: "Date Cancelled",
  },
  { id: "fee", numeric: true, disablePadding: false, label: "€ Cancel Fee" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const FilterPicker = ({ showFilter, setShowFilter, createFilter }) => {
  const [form, setForm] = useState({
    filter: null,
    choice: null,
  });

  const [selectedStartDate, setSelectedStartDate] = React.useState(
    startOfMonth(new Date())
  );
  const [selectedEndDate, setSelectedEndDate] = React.useState(
    endOfMonth(new Date())
  );

  const dataLists = useMemo(() => {
    const Dates = ({ start, end }) => (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateWrap>
          <DatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Select from date"
            value={start}
            inputAdornmentProps={{ position: "start" }}
            onChange={(date) => setSelectedStartDate(date)}
          />
        </DateWrap>
        <DateWrap>
          <DatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label="Select to date"
            defaultValue={new Date()}
            value={end}
            inputAdornmentProps={{ position: "start" }}
            onChange={(date) => setSelectedEndDate(date)}
          />
        </DateWrap>
      </LocalizationProvider>
    );
    return {
      cancelled: <Dates start={selectedStartDate} end={selectedEndDate} />,
      overdue: (
        <Dates start={selectedStartDate} end={addMonths(selectedEndDate, 6)} />
      ),
    };
  }, [selectedStartDate, selectedEndDate]);

  const formChoices = useMemo(
    () => form.filter && dataLists[form.filter],
    [form.filter, dataLists]
  );

  return (
    <div>
      <div style={{ position: "relative" }}>
        {showFilter && (
          <PopToSideTop top="-2.5rem" left="1rem">
            <FormContainer>
              <FormControl variant="outlined">
                <InputContainer>
                  <InputLabel>Metrics</InputLabel>
                  <Select
                    disabled={false}
                    value={form.filter}
                    onChange={(e) => {
                      setForm({
                        ...form,
                        filter: e.target.value,
                        color: colors[e.target.value],
                      });
                    }}
                    label="Criteria"
                  >
                    <MenuItem value="cancelled">Cancellations</MenuItem>
                    <MenuItem value="outstanding">Outstaning Payments</MenuItem>
                    <MenuItem value="appointments">
                      Appointments By Date
                    </MenuItem>
                    <MenuItem value="overdue">Not seen in 6 months</MenuItem>
                  </Select>
                </InputContainer>
              </FormControl>

              {formChoices}
              {form.filter && (
                <Row>
                  <FormControl>
                    <Button
                      onClick={() => {
                        createFilter({
                          metric: form.filter,
                          from: selectedStartDate,
                          to: selectedEndDate,
                          color: form.color,
                        });
                        setForm({ filter: null, choice: null });
                        setShowFilter(false);
                      }}
                      variant="contained"
                      color="primary"
                    >
                      Create
                    </Button>
                  </FormControl>
                  <FormControl>
                    <Button
                      onClick={() => {
                        setForm({ filter: null, choice: null });
                        setShowFilter(false);
                      }}
                      variant="contained"
                      
                    >
                      Cancel
                    </Button>
                  </FormControl>
                </Row>
              )}
            </FormContainer>
          </PopToSideTop>
        )}
      </div>
      <ModalBox
        show={showFilter}
        onClick={() => setShowFilter(false)}
      ></ModalBox>
    </div>
  );
};

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {numSelected} selected{" "}
          <Tooltip title="Send Mail">
            <IconButton aria-label="send" size="large">
              <SendIcon />
            </IconButton>
          </Tooltip>
        </Typography>
      ) : (
        <div>Search Criteria:</div>
      )}

      {props.filterList.length === 0 && (
        <Tooltip title="Search Criteria">
          <IconButton
            onClick={() => props.setShowFilter(true)}
            aria-label="Search Criteria"
            size="large"
          >
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}

      <FilterPicker
        showFilter={props.showFilter}
        setShowFilter={props.setShowFilter}
        createFilter={props.createFilter}
      />

      {props.filterList.length > 0 && (
        <div style={{ padding: "1rem" }}>
          {props.filterList.map((l) => (
            <Chip
              key={l.metric}
              icon={<FeaCheckIcon size="14" />}
              label={`Appointments that were cancelled between ${format(
                new Date(l.from),
                "Mo MMMM yyyy"
              )} and the
                    ${format(new Date(l.to), "Mo MMMM yyyy")}`}
              onDelete={props.deleteChip}
              avatar={
                <Avatar style={{ opacity: 0.7 }}>{l.metric.charAt(0)}</Avatar>
              }
              style={{ background: l.color }}
            ></Chip>
          ))}
        </div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

export default function AdminTarget({ match, user }) {
  const history = useHistory();
  const location = useLocation();
  const { state, dispatch } = useContext(AppContext);
  const { targets } = state;
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [showFilter, setShowFilter] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [search, setSearch] = useState("");

  async function navigateTo(pathname, state = false) {
    history.push({
      pathname: pathname,
      state,
    });
  }

  useEffect(() => {
    if (location.state) {
      getTargetsList(location.state)(dispatch, navigateTo);
      setFilterList([...filterList, location.state]);
    }
  }, [location]);

  const createFilter = (data) => {
    getTargetsList(data)(dispatch, navigateTo);
    setFilterList([...filterList, data]);
  };

  console.log(targets);

  const rows = useMemo(() => {
    return targets?.data.map((r) => r.data) || [];
  }, [targets]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.CancelationId);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <StandardLayout menu={true} meta={targets && targets.meta} user={user}>
      <Body>
        <TitleHeader>
          <h2>Target Patients</h2>
          <div
            style={{
              alignSelf: "end",
              justifySelf: "end",
              marginBottom: "1rem",
            }}
          >
            {filterList.length > 0 && (
              <SearchInput
                search={search}
                setSearch={setSearch}
                placeholder={"Search list"}
              />
            )}
          </div>
        </TitleHeader>
        <Paper className={classes.paper}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            createFilter={createFilter}
            filterList={filterList}
            deleteChip={() => {
              setFilterList([]);
              setSelected([]);
              dispatch({
                type: "LOAD",
                context: "targets",
                data: [],
                updating: false,
              });
            }}
          />

          {filterList.length > 0 ? (
            <TableContainer>
              <Table
                className={classes.table}
                aria-labelledby="filtered List"
                size="small"
                aria-label="enhanced table"
              >
                <EnhancedTableHead
                  classes={classes}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />

                <TableBody>
                  {stableSort(
                    rows.filter((row) => {
                      const str = schema
                        .map((c) => row[c])
                        .join()
                        .toLowerCase();
                      return str && str.search(search.toLowerCase()) >= 0;
                    }),
                    getComparator(order, orderBy)
                  )
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.CancelationId);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          onClick={(event) =>
                            handleClick(event, row.CancelationId)
                          }
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.CancelationId}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {row?.Name}
                          </TableCell>
                          <TableCell>{row?.Reason}</TableCell>
                          <TableCell>{row?.Information}</TableCell>
                          <TableCell>
                            {format(
                              new Date(row?.createdAt),
                              "MMM dd yyyy hh:ss"
                            )}
                          </TableCell>
                          <TableCell>{row?.createdAt}</TableCell>
                          <TableCell align="right">{row?.Amount}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <div style={{ display: "grid" }}>
              <Card
                className={classes.root}
                style={{
                  textAlign: "center",
                  justifySelf: "center",
                  width: "32rem",
                }}
              >
                <CardContent>
                  <Typography className={classes.title} gutterBottom>
                    There are no search filters created
                  </Typography>
                  <Typography color="textSecondary">
                    To target Patients please create a filter search
                  </Typography>
                </CardContent>
                <CardActions
                  style={{ justifyContent: "center", marginBottom: "1rem" }}
                >
                  <Button
                    onClick={() => setShowFilter(true)}
                    variant="contained"
                    color="primary"
                  >
                    Create Search filter
                  </Button>
                </CardActions>
              </Card>
            </div>
          )}
          {rows.length > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </Paper>
      </Body>
    </StandardLayout>
  );
}
