import React, { useState } from "react";
import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";
import AppointmentBlockForm from "components/calendar/components/AppointmentBlockForm";
import { Close, HighlightOff } from "@mui/icons-material";
import colors from "library/styled-components/colors";
import {
  createAppointmentEvent,
  updateAppointmentEvent,
  deleteAppointmentEvent,
} from "actions/appointments";
import {
  addMinutes,
  getHours,
  getMinutes,
  setHours,
  setMinutes,
} from "date-fns";
import DoubleBounce from "components/loaders/double-bounce";
import { ADMIN_NS } from "config";

const transformEditorData = ({
  practitionerId,
  patientId,
  start,
  end,
  meta,
}) => {
  return {
    PractitionerId: {
      val: practitionerId,
    },
    PatientId: {
      val: patientId,
    },
    Start: { val: start },
    End: { val: end },
    Meta: {
      val: meta,
    },
  };
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const BlockModal = ({ user, open, close, prePop, appointmentData }) => {
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);

  async function handleUpdateEvent(rawData, doClose, uuid = null) {
    setError(null);

    if (!rawData?.meta?.Block) {
      setError("you must select a Reason");
      return null;
    }

    if (!rawData.practitionerId) {
      setError("you must select a practitioner");
      return null;
    }

    setUpdating(true);
    const data = transformEditorData(rawData);

    if (uuid) {
      await updateAppointmentEvent(ADMIN_NS, data, uuid);
    } else {
      await createAppointmentEvent(ADMIN_NS, data);
    }

    setError(null);
    doClose && close();
    setUpdating(false);
  }

  if (!appointmentData && !prePop) return null;

  return (
    <Dialog
      open={open || false}
      onClose={close}
      fullScreen={true}
      maxWidth={"lg"}
      TransitionComponent={Transition}
    >
      <DialogContent
        style={{
          background: colors.lightGrey,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <IconButton aria-label="close" onClick={close} size="large">
            <Close />
          </IconButton>
        </div>

        {appointmentData ? (
          <AppointmentBlockForm
            user={user}
            error={error}
            id={appointmentData.EventId}
            appointmentData={appointmentData}
            removeItem={() => {
              deleteAppointmentEvent(ADMIN_NS, appointmentData.EventId);
              close && close();
            }}
            updateAppointment={(data, close) => {
              handleUpdateEvent(data, close, appointmentData.EventId);
            }}
            cancelMethod={close}
            
          />
        ) : (
          <AppointmentBlockForm
            user={user}
            error={error}
            prePop={prePop}
            updateAppointment={(data, close) => {
              handleUpdateEvent(data, close);
            }}
            cancelMethod={close}
            
          />
        )}
        {updating && (
          <div style={{ position: "absolute", margin: "50%", zIndex: "100" }}>
            <DoubleBounce color={colors.primary} />
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default BlockModal;
