import localforage from "localforage";

const practiceUpdate = (eventData) => async (dispatch) => {
  const { Practice } = eventData;
  const practice = await localforage.getItem("practice");

  if (practice.PracticeId === Practice.PracticeId) {
    dispatch({
      type: "LOAD_ONE",
      context: "practice",
      data: Practice,
    });
    await localforage.setItem("practice", Practice);
  }
};

export default {
  update: practiceUpdate,
};
