import React from "react";
import Drawer from "@mui/material/Drawer";
import TreatmentList from "./TreatmentList";

export default function TreatmentListDrawer({
  open,
  status,
  list,
  closeList,
  addProceedure,
  singleSelect = false,
}) {
  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"right"}
          open={open || false}
          sx={{
            zIndex: 1300,
          }}
        >
          <TreatmentList
            status={status}
            list={list}
            closeList={closeList}
            addProceedure={addProceedure}
            singleSelect={singleSelect}
          ></TreatmentList>
        </Drawer>
      </React.Fragment>
    </div>
  );
}
