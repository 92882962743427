import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";
import { Cancel } from "styled-icons/material";

export const AdminContainer = styled.div`
  display: grid;
  align-items: center;
`;

export const PreviewFile = styled.div`
  padding: 1rem;
  border-radius: 1rem;
  border: 1px solid ${colors.lightGrey};
  border-radius: 4px;
  width: 100%;
  height: 100%;
`;

export const AdminColumn = styled.div``;

export const OverflowBody = styled.div`
  display: grid;
  font-weight: normal;
  justify-items: center;
  overflow-x: auto;
  height: calc(100vh - 8rem);
`;

export const Body = styled.div`
  display: grid;
  font-weight: normal;
  justify-items: center;
`;

export const Header2Col = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
  margin: 1em;
  @media (min-width: 481px) {
    grid-template-columns: auto auto;
  }
`;

export const CancelBox = styled(Cancel)`
  cursor: pointer;
  color: #999;
  :hover {
    color: #333;
  }
`;

export const Fixed = styled.div`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  background: ${colors.white};

  @media (max-width: 481px) {
    border: 1px solid ${colors.borderColor};
  }
`;

export const FixedTopLeft = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
`;

export const OverlayBackGround = styled.div`
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: absolute;
  z-index: 102;
  display: ${(props) => (props.status === 1 ? "block" : "none")};
  background: ${colors.rgbGreyLight};
`;

export const Overlay = styled.div`
  background-color: ${colors.secColor};
  position: fixed;
  z-index: 102;
  box-shadow: 0 0 12px rgba(60, 72, 88, 0.15) !important;
  transition: all 1s ease;
  right: 100%;
  top: 0;

  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  padding-bottom: 0;

  animation: ${(props) => props.status === 1 && `slideUp 500ms forwards `};
  animation: ${(props) => props.status === 2 && `slideDown 1000ms forwards`};

  @keyframes slideUp {
    0% {
      right: -100%;
    }
    100% {
      right: 0%;
    }
  }
  @keyframes slideDown {
    0% {
      right: 0%;
    }
    100% {
      right: -100%;
    }
  }

  @media (max-width: 481px) {
    width: 100%;
  }
  @media (min-width: 481px) {
    width: 28em;
    padding-top: 1rem;
    margin-top: 3rem;
    ${(props) =>
      props.wide &&
      css`
        width: 60em;
      `}
  }
`;

export const EditAction = styled.button`
  display: grid;
  grid-template-columns: 1em auto;
  align-items: center;
  cursor: pointer;
  background: ${colors.grey};
  border: none;
  outline: none;
  color: white;
  border-radius: 0.2rem;
  margin-right: 0.4rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const DeleteAction = styled.button`
  display: grid;
  width: 4rem;
  grid-template-columns: 1em auto;
  align-items: center;
  cursor: pointer;
  background: ${colors.danger};
  border: none;
  outline: none;
  color: white;
  border-radius: 0.2rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const ConsultationAction = styled.button`
  display: grid;
  width: auto;
  grid-template-columns: 1em auto;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-right: 0.4rem;
  border-radius: 0.2rem;
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;

export const MessageAction = styled.button`
  display: grid;
  width: auto;
  background: ${colors.secondary};
  color: white;
  grid-template-columns: 1em auto;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-right: 0.4rem;
  border-radius: 0.2rem;
  border: 1px solid ${colors.secondary};
  span {
    margin: 0.2rem;
  }
  :hover {
    opacity: 0.9;
  }
`;
