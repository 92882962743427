import React, { useState } from "react";
import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";
import { makeStyles } from "@mui/styles";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { ConsultationAction } from "../../layouts/lib/common";
import SearchInput from "../search-input";

const Container = styled.div`
  @media (max-width: 481px) {
    width: 70vw;
  }
  max-width: 96vw;
  text-align: left;
  .Mui-selected {
    background: red;
  }
  ${(props) =>
    props.pointer &&
    css`
      tr {
        cursor: pointer;
      }
    `}
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: 16rem auto auto;
  align-items: start;
  align-items: center;
`;

const ActionsWrapper = styled.div`
  display: flex;
  ${ConsultationAction} {
    background: ${(props) => (props.active ? colors.primary : colors.white)};
    color: ${(props) => (props.active ? colors.white : colors.primary)};
    border: 1px solid ${colors.primary};
  }
`;

const rowsPerPageOptions = [10, 50, 100];

const useStyles = makeStyles({
  tableRow: {
    "&$selected, &$selected:hover": {
      backgroundColor: colors.rgbInfoLight,
      color: colors.darkBlue,
    },
  },
  tableCell: {
    "$selected & * ": {
      color: "white",
    },
  },
  selected: {},
});

const DataTable = ({
  schema,
  actions,
  list,
  uuid,
  data,
  addItem = null,
  clickable = false,
  navigateTo,
  filterComponent = "",
}) => {
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  return (
    <Container pointer={clickable}>
      <Header>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          <h2>{data?.title}</h2>
 
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "baseline",
          }}
        >
          {filterComponent}
        </div>
        <div
          style={{
            alignSelf: "end",
            justifySelf: "end",
            marginBottom: "1rem",
          }}
        >
          <SearchInput
            search={search}
            setSearch={setSearch}
            placeholder={data?.placeholder}
          />
        </div>
      </Header>

      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                {schema.content.map((header) => (
                  <TableCell key={header.key}>{header.label}</TableCell>
                ))}
                {actions && <TableCell></TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {list
                .filter((row) => {
                  const str = schema.content
                    .map((c) => row.data[c.key])
                    .join()
                    .toLowerCase();
                  return str && str.search(search.toLowerCase()) >= 0;
                })
                .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
                .map((row) => (
                  <TableRow
                    hover
                    style={{
                      background: row.data.disabled ? colors.lightBlue : "",
                    }}
                    selected={uuid === row.data[schema.link.id]}
                    key={row.data[schema.link.id]}
                    onClick={() =>
                      clickable &&
                      navigateTo(`/admin/home/patient/${row.data.PatientId}`)
                    }
                  >
                    {schema.content.map((c) => (
                      <TableCell key={c.key}>{row.data[c.key]}</TableCell>
                    ))}
                    {actions && (
                      <TableCell id={row.data[schema.link.id]}>
                        <ActionsWrapper
                          id={row.data[schema.link.id]}
                          active={
                            schema.active &&
                            row.data[schema.active.key] &&
                            row.data[schema.active.key].find(
                              (a) => a && a[schema.active.find] === true
                            )
                          }
                        >
                          {actions}
                        </ActionsWrapper>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {list.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component="div"
            count={list.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(e, page) => setPage(page)}
            onRowsPerPageChange={(e) =>
              setRowsPerPage(parseInt(e.target.value, 10))
            }
          />
        )}
      </Paper>
    </Container>
  );
};

export default DataTable;
