export default [
  { iso: 'AD', country: 'Andorra', code: '376' },
  {
    iso: 'AE',
    country: 'United Arab Emirates',
    code: '971',
  },
  { iso: 'AF', country: 'Afghanistan', code: '93' },
  {
    iso: 'AG',
    country: 'Antigua and Barbuda',
    code: '1-268',
  },
  { iso: 'AI', country: 'Anguilla', code: '1-264' },
  { iso: 'AL', country: 'Albania', code: '355' },
  { iso: 'AM', country: 'Armenia', code: '374' },
  { iso: 'AO', country: 'Angola', code: '244' },
  { iso: 'AQ', country: 'Antarctica', code: '672' },
  { iso: 'AR', country: 'Argentina', code: '54' },
  { iso: 'AS', country: 'American Samoa', code: '1-684' },
  { iso: 'AT', country: 'Austria', code: '43' },
  {
    iso: 'AU',
    country: 'Australia',
    code: '61',
    suggested: true,
  },
  { iso: 'AW', country: 'Aruba', code: '297' },
  { iso: 'AX', country: 'Alland Islands', code: '358' },
  { iso: 'AZ', country: 'Azerbaijan', code: '994' },
  {
    iso: 'BA',
    country: 'Bosnia and Herzegovina',
    code: '387',
  },
  { iso: 'BB', country: 'Barbados', code: '1-246' },
  { iso: 'BD', country: 'Bangladesh', code: '880' },
  { iso: 'BE', country: 'Belgium', code: '32' },
  { iso: 'BF', country: 'Burkina Faso', code: '226' },
  { iso: 'BG', country: 'Bulgaria', code: '359' },
  { iso: 'BH', country: 'Bahrain', code: '973' },
  { iso: 'BI', country: 'Burundi', code: '257' },
  { iso: 'BJ', country: 'Benin', code: '229' },
  { iso: 'BL', country: 'Saint Barthelemy', code: '590' },
  { iso: 'BM', country: 'Bermuda', code: '1-441' },
  { iso: 'BN', country: 'Brunei Darussalam', code: '673' },
  { iso: 'BO', country: 'Bolivia', code: '591' },
  { iso: 'BR', country: 'Brazil', code: '55' },
  { iso: 'BS', country: 'Bahamas', code: '1-242' },
  { iso: 'BT', country: 'Bhutan', code: '975' },
  { iso: 'BV', country: 'Bouvet Island', code: '47' },
  { iso: 'BW', country: 'Botswana', code: '267' },
  { iso: 'BY', country: 'Belarus', code: '375' },
  { iso: 'BZ', country: 'Belize', code: '501' },
  {
    iso: 'CA',
    country: 'Canada',
    code: '1',
    suggested: true,
  },
  {
    iso: 'CC',
    country: 'Cocos (Keeling) Islands',
    code: '61',
  },
  {
    iso: 'CD',
    country: 'Congo, Democratic Republic of the',
    code: '243',
  },
  {
    iso: 'CF',
    country: 'Central African Republic',
    code: '236',
  },
  {
    iso: 'CG',
    country: 'Congo, Republic of the',
    code: '242',
  },
  { iso: 'CH', country: 'Switzerland', code: '41' },
  { iso: 'CI', country: "Cote d'Ivoire", code: '225' },
  { iso: 'CK', country: 'Cook Islands', code: '682' },
  { iso: 'CL', country: 'Chile', code: '56' },
  { iso: 'CM', country: 'Cameroon', code: '237' },
  { iso: 'CN', country: 'China', code: '86' },
  { iso: 'CO', country: 'Colombia', code: '57' },
  { iso: 'CR', country: 'Costa Rica', code: '506' },
  { iso: 'CU', country: 'Cuba', code: '53' },
  { iso: 'CV', country: 'Cape Verde', code: '238' },
  { iso: 'CW', country: 'Curacao', code: '599' },
  { iso: 'CX', country: 'Christmas Island', code: '61' },
  { iso: 'CY', country: 'Cyprus', code: '357' },
  { iso: 'CZ', country: 'Czech Republic', code: '420' },
  {
    iso: 'DE',
    country: 'Germany',
    code: '49',
    suggested: true,
  },
  { iso: 'DJ', country: 'Djibouti', code: '253' },
  { iso: 'DK', country: 'Denmark', code: '45' },
  { iso: 'DM', country: 'Dominica', code: '1-767' },
  {
    iso: 'DO',
    country: 'Dominican Republic',
    code: '1-809',
  },
  { iso: 'DZ', country: 'Algeria', code: '213' },
  { iso: 'EC', country: 'Ecuador', code: '593' },
  { iso: 'EE', country: 'Estonia', code: '372' },
  { iso: 'EG', country: 'Egypt', code: '20' },
  { iso: 'EH', country: 'Western Sahara', code: '212' },
  { iso: 'ER', country: 'Eritrea', code: '291' },
  { iso: 'ES', country: 'Spain', code: '34' },
  { iso: 'ET', country: 'Ethiopia', code: '251' },
  { iso: 'FI', country: 'Finland', code: '358' },
  { iso: 'FJ', country: 'Fiji', code: '679' },
  {
    iso: 'FK',
    country: 'Falkland Islands (Malvinas)',
    code: '500',
  },
  {
    iso: 'FM',
    country: 'Micronesia, Federated States of',
    code: '691',
  },
  { iso: 'FO', country: 'Faroe Islands', code: '298' },
  {
    iso: 'FR',
    country: 'France',
    code: '33',
    suggested: true,
  },
  { iso: 'GA', country: 'Gabon', code: '241' },
  { iso: 'GB', country: 'United Kingdom', code: '44' },
  { iso: 'GD', country: 'Grenada', code: '1-473' },
  { iso: 'GE', country: 'Georgia', code: '995' },
  { iso: 'GF', country: 'French Guiana', code: '594' },
  { iso: 'GG', country: 'Guernsey', code: '44' },
  { iso: 'GH', country: 'Ghana', code: '233' },
  { iso: 'GI', country: 'Gibraltar', code: '350' },
  { iso: 'GL', country: 'Greenland', code: '299' },
  { iso: 'GM', country: 'Gambia', code: '220' },
  { iso: 'GN', country: 'Guinea', code: '224' },
  { iso: 'GP', country: 'Guadeloupe', code: '590' },
  { iso: 'GQ', country: 'Equatorial Guinea', code: '240' },
  { iso: 'GR', country: 'Greece', code: '30' },
  {
    iso: 'GS',
    country: 'South Georgia and the South Sandwich Islands',
    code: '500',
  },
  { iso: 'GT', country: 'Guatemala', code: '502' },
  { iso: 'GU', country: 'Guam', code: '1-671' },
  { iso: 'GW', country: 'Guinea-Bissau', code: '245' },
  { iso: 'GY', country: 'Guyana', code: '592' },
  { iso: 'HK', country: 'Hong Kong', code: '852' },
  {
    iso: 'HM',
    country: 'Heard Island and McDonald Islands',
    code: '672',
  },
  { iso: 'HN', country: 'Honduras', code: '504' },
  { iso: 'HR', country: 'Croatia', code: '385' },
  { iso: 'HT', country: 'Haiti', code: '509' },
  { iso: 'HU', country: 'Hungary', code: '36' },
  { iso: 'ID', country: 'Indonesia', code: '62' },
  { iso: 'IE', country: 'Ireland', code: '353' },
  { iso: 'IL', country: 'Israel', code: '972' },
  { iso: 'IM', country: 'Isle of Man', code: '44' },
  { iso: 'IN', country: 'India', code: '91' },
  {
    iso: 'IO',
    country: 'British Indian Ocean Territory',
    code: '246',
  },
  { iso: 'IQ', country: 'Iraq', code: '964' },
  {
    iso: 'IR',
    country: 'Iran, Islamic Republic of',
    code: '98',
  },
  { iso: 'IS', country: 'Iceland', code: '354' },
  { iso: 'IT', country: 'Italy', code: '39' },
  { iso: 'JE', country: 'Jersey', code: '44' },
  { iso: 'JM', country: 'Jamaica', code: '1-876' },
  { iso: 'JO', country: 'Jordan', code: '962' },
  {
    iso: 'JP',
    country: 'Japan',
    code: '81',
    suggested: true,
  },
  { iso: 'KE', country: 'Kenya', code: '254' },
  { iso: 'KG', country: 'Kyrgyzstan', code: '996' },
  { iso: 'KH', country: 'Cambodia', code: '855' },
  { iso: 'KI', country: 'Kiribati', code: '686' },
  { iso: 'KM', country: 'Comoros', code: '269' },
  {
    iso: 'KN',
    country: 'Saint Kitts and Nevis',
    code: '1-869',
  },
  {
    iso: 'KP',
    country: "Korea, Democratic People's Republic of",
    code: '850',
  },
  { iso: 'KR', country: 'Korea, Republic of', code: '82' },
  { iso: 'KW', country: 'Kuwait', code: '965' },
  { iso: 'KY', country: 'Cayman Islands', code: '1-345' },
  { iso: 'KZ', country: 'Kazakhstan', code: '7' },
  {
    iso: 'LA',
    country: "Lao People's Democratic Republic",
    code: '856',
  },
  { iso: 'LB', country: 'Lebanon', code: '961' },
  { iso: 'LC', country: 'Saint Lucia', code: '1-758' },
  { iso: 'LI', country: 'Liechtenstein', code: '423' },
  { iso: 'LK', country: 'Sri Lanka', code: '94' },
  { iso: 'LR', country: 'Liberia', code: '231' },
  { iso: 'LS', country: 'Lesotho', code: '266' },
  { iso: 'LT', country: 'Lithuania', code: '370' },
  { iso: 'LU', country: 'Luxembourg', code: '352' },
  { iso: 'LV', country: 'Latvia', code: '371' },
  { iso: 'LY', country: 'Libya', code: '218' },
  { iso: 'MA', country: 'Morocco', code: '212' },
  { iso: 'MC', country: 'Monaco', code: '377' },
  {
    iso: 'MD',
    country: 'Moldova, Republic of',
    code: '373',
  },
  { iso: 'ME', country: 'Montenegro', code: '382' },
  {
    iso: 'MF',
    country: 'Saint Martin (French part)',
    code: '590',
  },
  { iso: 'MG', country: 'Madagascar', code: '261' },
  { iso: 'MH', country: 'Marshall Islands', code: '692' },
  {
    iso: 'MK',
    country: 'Macedonia, the Former Yugoslav Republic of',
    code: '389',
  },
  { iso: 'ML', country: 'Mali', code: '223' },
  { iso: 'MM', country: 'Myanmar', code: '95' },
  { iso: 'MN', country: 'Mongolia', code: '976' },
  { iso: 'MO', country: 'Macao', code: '853' },
  {
    iso: 'MP',
    country: 'Northern Mariana Islands',
    code: '1-670',
  },
  { iso: 'MQ', country: 'Martinique', code: '596' },
  { iso: 'MR', country: 'Mauritania', code: '222' },
  { iso: 'MS', country: 'Montserrat', code: '1-664' },
  { iso: 'MT', country: 'Malta', code: '356' },
  { iso: 'MU', country: 'Mauritius', code: '230' },
  { iso: 'MV', country: 'Maldives', code: '960' },
  { iso: 'MW', country: 'Malawi', code: '265' },
  { iso: 'MX', country: 'Mexico', code: '52' },
  { iso: 'MY', country: 'Malaysia', code: '60' },
  { iso: 'MZ', country: 'Mozambique', code: '258' },
  { iso: 'NA', country: 'Namibia', code: '264' },
  { iso: 'NC', country: 'New Caledonia', code: '687' },
  { iso: 'NE', country: 'Niger', code: '227' },
  { iso: 'NF', country: 'Norfolk Island', code: '672' },
  { iso: 'NG', country: 'Nigeria', code: '234' },
  { iso: 'NI', country: 'Nicaragua', code: '505' },
  { iso: 'NL', country: 'Netherlands', code: '31' },
  { iso: 'NO', country: 'Norway', code: '47' },
  { iso: 'NP', country: 'Nepal', code: '977' },
  { iso: 'NR', country: 'Nauru', code: '674' },
  { iso: 'NU', country: 'Niue', code: '683' },
  { iso: 'NZ', country: 'New Zealand', code: '64' },
  { iso: 'OM', country: 'Oman', code: '968' },
  { iso: 'PA', country: 'Panama', code: '507' },
  { iso: 'PE', country: 'Peru', code: '51' },
  { iso: 'PF', country: 'French Polynesia', code: '689' },
  { iso: 'PG', country: 'Papua New Guinea', code: '675' },
  { iso: 'PH', country: 'Philippines', code: '63' },
  { iso: 'PK', country: 'Pakistan', code: '92' },
  { iso: 'PL', country: 'Poland', code: '48' },
  {
    iso: 'PM',
    country: 'Saint Pierre and Miquelon',
    code: '508',
  },
  { iso: 'PN', country: 'Pitcairn', code: '870' },
  { iso: 'PR', country: 'Puerto Rico', code: '1' },
  {
    iso: 'PS',
    country: 'Palestine, State of',
    code: '970',
  },
  { iso: 'PT', country: 'Portugal', code: '351' },
  { iso: 'PW', country: 'Palau', code: '680' },
  { iso: 'PY', country: 'Paraguay', code: '595' },
  { iso: 'QA', country: 'Qatar', code: '974' },
  { iso: 'RE', country: 'Reunion', code: '262' },
  { iso: 'RO', country: 'Romania', code: '40' },
  { iso: 'RS', country: 'Serbia', code: '381' },
  { iso: 'RU', country: 'Russian Federation', code: '7' },
  { iso: 'RW', country: 'Rwanda', code: '250' },
  { iso: 'SA', country: 'Saudi Arabia', code: '966' },
  { iso: 'SB', country: 'Solomon Islands', code: '677' },
  { iso: 'SC', country: 'Seychelles', code: '248' },
  { iso: 'SD', country: 'Sudan', code: '249' },
  { iso: 'SE', country: 'Sweden', code: '46' },
  { iso: 'SG', country: 'Singapore', code: '65' },
  { iso: 'SH', country: 'Saint Helena', code: '290' },
  { iso: 'SI', country: 'Slovenia', code: '386' },
  {
    iso: 'SJ',
    country: 'Svalbard and Jan Mayen',
    code: '47',
  },
  { iso: 'SK', country: 'Slovakia', code: '421' },
  { iso: 'SL', country: 'Sierra Leone', code: '232' },
  { iso: 'SM', country: 'San Marino', code: '378' },
  { iso: 'SN', country: 'Senegal', code: '221' },
  { iso: 'SO', country: 'Somalia', code: '252' },
  { iso: 'SR', country: 'Suriname', code: '597' },
  { iso: 'SS', country: 'South Sudan', code: '211' },
  {
    iso: 'ST',
    country: 'Sao Tome and Principe',
    code: '239',
  },
  { iso: 'SV', country: 'El Salvador', code: '503' },
  {
    iso: 'SX',
    country: 'Sint Maarten (Dutch part)',
    code: '1-721',
  },
  {
    iso: 'SY',
    country: 'Syrian Arab Republic',
    code: '963',
  },
  { iso: 'SZ', country: 'Swaziland', code: '268' },
  {
    iso: 'TC',
    country: 'Turks and Caicos Islands',
    code: '1-649',
  },
  { iso: 'TD', country: 'Chad', code: '235' },
  {
    iso: 'TF',
    country: 'French Southern Territories',
    code: '262',
  },
  { iso: 'TG', country: 'Togo', code: '228' },
  { iso: 'TH', country: 'Thailand', code: '66' },
  { iso: 'TJ', country: 'Tajikistan', code: '992' },
  { iso: 'TK', country: 'Tokelau', code: '690' },
  { iso: 'TL', country: 'Timor-Leste', code: '670' },
  { iso: 'TM', country: 'Turkmenistan', code: '993' },
  { iso: 'TN', country: 'Tunisia', code: '216' },
  { iso: 'TO', country: 'Tonga', code: '676' },
  { iso: 'TR', country: 'Turkey', code: '90' },
  {
    iso: 'TT',
    country: 'Trinidad and Tobago',
    code: '1-868',
  },
  { iso: 'TV', country: 'Tuvalu', code: '688' },
  {
    iso: 'TW',
    country: 'Taiwan, Republic of China',
    code: '886',
  },
  {
    iso: 'TZ',
    country: 'United Republic of Tanzania',
    code: '255',
  },
  { iso: 'UA', country: 'Ukraine', code: '380' },
  { iso: 'UG', country: 'Uganda', code: '256' },
  {
    iso: 'US',
    country: 'United States',
    code: '1',
    suggested: true,
  },
  { iso: 'UY', country: 'Uruguay', code: '598' },
  { iso: 'UZ', country: 'Uzbekistan', code: '998' },
  {
    iso: 'VA',
    country: 'Holy See (Vatican City State)',
    code: '379',
  },
  {
    iso: 'VC',
    country: 'Saint Vincent and the Grenadines',
    code: '1-784',
  },
  { iso: 'VE', country: 'Venezuela', code: '58' },
  {
    iso: 'VG',
    country: 'British Virgin Islands',
    code: '1-284',
  },
  {
    iso: 'VI',
    country: 'US Virgin Islands',
    code: '1-340',
  },
  { iso: 'VN', country: 'Vietnam', code: '84' },
  { iso: 'VU', country: 'Vanuatu', code: '678' },
  { iso: 'WF', country: 'Wallis and Futuna', code: '681' },
  { iso: 'WS', country: 'Samoa', code: '685' },
  { iso: 'XK', country: 'Kosovo', code: '383' },
  { iso: 'YE', country: 'Yemen', code: '967' },
  { iso: 'YT', country: 'Mayotte', code: '262' },
  { iso: 'ZA', country: 'South Africa', code: '27' },
  { iso: 'ZM', country: 'Zambia', code: '260' },
  { iso: 'ZW', country: 'Zimbabwe', code: '263' },
];