import React, { useState, useContext } from "react";
import styled from "styled-components";
import {
  Card,
  CardContent,
  FormGroup,
  FormControlLabel,
  Switch,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import StandardLayout from "../../../../layouts/standard";
import Button from "@mui/material/Button";
import CopyIcon from "@mui/icons-material/FileCopy";
import Alert from "@mui/lab/Alert";
import store from "store";
import { AppContext } from "../../../../hooks/context";

const Container = styled.div`
  margin: 1rem;
`;

const CodeBlock = styled.pre`
  background: #212d63;
  color: #a4cdfe;
  padding: 1rem;
  width: 50rem;
  border-radius: 0.2rem;
  .blue {
    color: #7fd3ed;
  }
  .green {
    color: #85d996;
  }
  .orange {
    color: #f8b886;
  }
`;

const Settings = ({ user }) => {
  const { app } = useContext(AppContext);
  const [open1, setOpen1] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [sync, setSync] = useState(store.get("sync"));
  const [showPrompt, setShowPrompt] = app.showPrompt;

  const handleClose = () => {
    setOpen1(false);
  };

  const copyText = {
    iframe: `<iframe 
                  width="100%" height="900px"
                  src="${process.env.REACT_APP_DOMAIN}/#/booking/${user.data.PracticeId}/calendar"
                  title="Dentiz Online Booking">
              </iframe>`,
    script: `<script src="https://www.gstatic.com/dialogflow-console/fast/messenger-cx/bootstrap.js?v=1"></script>
              <df-messenger
                df-cx="true"
                location="europe-west2"
                chat-title="book an appointment"
                agent-id="${user.data.PracticeId}"
                language-code="en"
              ></df-messenger>`,
  };

  return (
    <StandardLayout menu={true} openSide={false} meta={user.meta} user={user}>
      <Container style={{ marginBottom: "3rem" }}>
        <Card>
          <CardContent>
            <FormGroup row>
              <FormControlLabel
                onClick={(event) => event.stopPropagation()}
                control={
                  <Switch
                    checked={sync}
                    onChange={() => {
                      store.set("sync", !sync);
                      setSync(!sync);
                      setShowPrompt(!sync);
                    }}
                    name="x-ray"
                    color="primary"
                  />
                }
                label={`Turn ${!sync ? "on" : "off"} X-ray File Scychronisation`}
              />
            </FormGroup>
          </CardContent>
        </Card>
        <Card>
          <CardContent>
            <Typography variant="h6" component="h6">
              You can add the <strong>dentiz booking system</strong> to your
              website!
            </Typography>

            <Typography variant="body2" component="p">
              Simply copy the code below ans paste it onto your website page. If
              you need help you with this please contact us.
            </Typography>

            <CodeBlock>
              <span className="blue">
                &#x3C;iframe <br />
              </span>
              {"   "}width=&#x22;<span className="orange">100%</span>&#x22;
              height=&#x22;<span className="orange">900px</span>&#x22;
              <br />
              {"   "}src=&#x22;
              <span className="green">{`${process.env.REACT_APP_DOMAIN}/#/booking/${user.data.PracticeId}/calendar`}</span>
              &#x22;
              <br />
              {"   "}title=&#x22;
              <span className="orange">Dentiz Online Booking</span>&#x22;&#x3E;
              <br />
              <span className="blue">&#x3C;/iframe&#x3E;</span>
              <br />
            </CodeBlock>

            <Button
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(copyText.iframe);
                setOpen1(true);
                setTimeout(() => {
                  setOpen1(false);
                }, 3000);
              }}
            >
              <CopyIcon fontSize="small" />
              Copy
            </Button>
          </CardContent>
        </Card>
        {open1 && (
          <Alert
            onClose={handleClose}
            severity="success"
            elevation={1}
            variant="filled"
            style={{ width: "10rem", position: "fixed", marginTop: "0.2rem" }}
          >
            Code copied
          </Alert>
        )}
        <Card>
          <CardContent>
            <Typography variant="h6" component="h6">
              You can also add the{" "}
              <strong>dentiz chat bot booking system</strong> to your website!
            </Typography>

            <Typography variant="body2" component="p">
              Simply copy the code below ans paste it onto your website page. If
              you need help you with this please contact us.
            </Typography>

            <CodeBlock>
              <span className="blue">&#x3C;script</span> src=&#x22;
              <span className="green">
                https://www.gstatic.com/dialogflow-console/fast/messenger-cx/bootstrap.js?v=1
              </span>
              &#x22;&#x3E;
              <br />
              <span className="blue">&#x3C;/script&#x3E;</span>
              <br />
              <span className="blue">&#x3C;df-messenger </span> <br />
              {"   "}df-cx=&#x22;<span className="orange">true</span>&#x22;
              <br />
              {"   "}location=&#x22;<span className="orange">europe-west2</span>
              &#x22;
              <br />
              {"   "}chat-title=&#x22;
              <span className="orange">Dentiz Online Chat Bot</span>&#x22;
              <br />
              {"   "}agent-id=&#x22;
              <span className="orange">
                00a21923-bfca-4c06-8127-57722bbd43ac
              </span>
              &#x22;
              <br />
              {"   "}language-code=&#x22;<span className="orange">en</span>
              &#x22; &#x3E;
              <br />
              <span className="blue">&#x3C;/df-messenger&#x3E;</span>
            </CodeBlock>

            <Button
              size="small"
              onClick={() => {
                navigator.clipboard.writeText(copyText.script);
                setOpen2(true);
                setTimeout(() => {
                  setOpen2(false);
                }, 3000);
              }}
            >
              <CopyIcon fontSize="small" />
              Copy
            </Button>
          </CardContent>
        </Card>
        {open2 && (
          <Alert
            onClose={handleClose}
            severity="success"
            elevation={1}
            variant="filled"
            style={{ width: "10rem", marginTop: "0.2rem" }}
          >
            Code copied
          </Alert>
        )}
      </Container>
    </StandardLayout>
  );
};

export default Settings;
