import {
  InputAdornment,
  Input,
  FormControl,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: 2.2em;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const InputComponent = ({
  InputData,
  LabelData,
  onChange,
  onBlur,
  icon,
  changed,
}) => {
  const [password, setPassword] = useState(InputData.type === "password");
  const type =
    InputData.type === "password"
      ? !password
        ? "text"
        : InputData.type
      : InputData.type;

  return (
    <InputContainer>
      <FormControl>
        <InputLabel>{LabelData.text}</InputLabel>

        <Input
          startAdornment={
            <InputAdornment position="start">{icon}</InputAdornment>
          }
          endAdornment={
            InputData.type === "password" && (
              <InputAdornment position="start">
                {password ? (
                  <VisibilityIcon
                    style={{ pointer: "cursor" }}
                    onClick={() => setPassword(false)}
                  />
                ) : (
                  <VisibilityOffIcon
                    style={{ pointer: "cursor" }}
                    onClick={() => setPassword(true)}
                  />
                )}
              </InputAdornment>
            )
          }
          disabled={InputData.disable}
          changed={changed}
          type={type}
          placeholder={LabelData.text}
          defaultValue={InputData.defaultValue}
          value={InputData.value}
          onChange={(val) => onChange(val.target.value)}
          onBlur={onBlur}
          helperText={LabelData.error}
          error={LabelData.error ? true : false}
        />
        <FormHelperText>
          {LabelData.error || InputData.placeholder}
        </FormHelperText>
      </FormControl>
    </InputContainer>
  );
};

export default InputComponent;
