import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <g id="top7" transform="translate(2.447 112.551)">
      <g id="bounds">
        <line
          id="bound"
          x2="70"
          transform="translate(-2.447 133.449)"
          fill="none"
        ></line>
        <line
          id="bound-2"
          data-name="bound"
          x2="70"
          transform="translate(-2.447 -112.551)"
          fill="none"
        ></line>
      </g>
      <g id="surface" transform="translate(42 25.752)">
        <path
          id="shape"
          d="M26.465.107C7.24-1.228-4.346,9.93,1.536,32.966A30.848,30.848,0,0,0,2.91,37.074C6.394,45.657,14.126,52.4,23.255,50.854c9.016-1.526,14.876.581,17.691-13.208C43.586,24.716,46.7,1.513,26.465.107Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#707070"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M13.083,49.1a21.833,21.833,0,0,0,4.381,1.631,14.951,14.951,0,0,0,5.792.118c1.5-.254,3.763-.429,5.786-.693,1.638-.214,2.947-.5,2.947-.5s-2.885-.365-4.9-4.7S25.127,34.024,23.56,33.11s-4.67-3.933-7.379.261S13.083,49.1,13.083,49.1Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="ml"
          d="M31.9,49.678c4.115-.836,7.217-3.1,9.041-12.032.121-.6.422-2.146.422-2.146s-1.783.737-5.4-1.116c-3.535-1.814-3.329-4.589-6.171-4.7s-5.48,4.492-5.48,4.492.634,2.26.844,3.472a26.739,26.739,0,0,0,2.384,8.2C28.982,48.5,31.391,49.783,31.9,49.678Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M41.363,35.518a99.41,99.41,0,0,0,1.444-11.093c.041-1.425.036-2.851-.025-4.591-.025-.736-9.33-5.308-12.129-2.708s.933,13.109.933,13.109a35.193,35.193,0,0,0,2.363,2.654,12.282,12.282,0,0,0,2.782,1.839,7.057,7.057,0,0,0,4.632.79Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="dl"
          d="M29.43,14.457c2.246,1.871,3.184,1.825,3.184,1.825l3.132.423a17.028,17.028,0,0,1,7.069,3.016,4.426,4.426,0,0,0,0-.6c-.3-7.675-2.922-15.245-11.559-18.144C31.065.9,21.691,8.011,29.43,14.457Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M26.465.107A33.719,33.719,0,0,0,13.123,1.626c-.267.088-1.358.516-1.358.516s1.741,15.592,8.4,15.766c4.173.11,5.63-8.078,6.5-11.028S31.183.971,31.183.971s-.373-.1-.656-.187A16.192,16.192,0,0,0,26.465.107Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="db"
          d="M11.68,2.179s-.311.132-.735.323C5.232,5.045,1.261,10.223.211,18.117c-.254,1.9,11.72,3.327,15.243-1.369,1.3-1.732-.009-1.456-1.442-5.115A56.025,56.025,0,0,1,11.68,2.179Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M.167,18.592s-.015.132-.054.508A40.626,40.626,0,0,0,1.536,32.966c.124.48.259.958.511,1.754.112.362,16.619-5.853,15.781-13.134-.258-2.24-1.13-1.515-3.525-2.487-1.416-.58-3.454.542-5.981.722a18.117,18.117,0,0,1-4.128,0A13.492,13.492,0,0,1,.167,18.592Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="mb"
          d="M2.1,34.877c.178.571.456,1.355.805,2.2a24.386,24.386,0,0,0,9.9,11.862l.36.206A51.653,51.653,0,0,1,14.234,39.03c1.085-4.623,3.141-7.981,2.317-9.1a5.149,5.149,0,0,0-2.494-1.556A37.667,37.667,0,0,1,8.9,31.838C5.43,33.871,2.044,34.684,2.1,34.877Z"
          transform="translate(16.319 51.088) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M-16.2,67.648c.69,1.378,1.538,1.475,1.2,3.38s-1.9,3.274-2.548,4.238c.449,1.228,3,2.192,3.459,3.7a3.767,3.767,0,0,1,.725,1.756c.033.455-.363.906-.243,1.629-.076.126.453-.205,2.791-.613s5.062-1.469,7.83-1.017,2.852,2.588,2.852,2.588A14.738,14.738,0,0,1,3.8,78.725a16.177,16.177,0,0,1,3.67-1.452S.3,75.415-1.176,72.85.783,67.142.783,67.142a3.96,3.96,0,0,1-1.761-.907c-.7-.574-1.413-2.255-1.479-1.65-.03.281-.547,1.3-.71,2.108a3.015,3.015,0,0,1-.773,1.773c-.266.305-1.2.325-2.054.449-2.338.339-4.793-2.786-6.156-3.847-.458.625-.185,1.7-1.2,2.342S-15,67.463-16.2,67.648Z"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown">
        <path
          id="main"
          d="M7.28,2.856C6.548,6.058-4.356,28.983,1.96,36.77s8.444,12.3,13.364,11.5,8.511-7.351,17.154-.946,16.69-2.184,16.69-2.184,15.359-8.37,8.51-25.84S51.3,7.222,51.362,2.855,8.011-.347,7.28,2.856Z"
          transform="translate(3)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root">
        <path
          id="rb3"
          d="M782.726,307.847s-.416-32.7-4.19-50.272-6.735-26.837-10.9-20c-2.306,3.781.4,17.331-1.468,29.8-1.442,9.6-6.254,24.96-6.254,24.96s-1.2,13.11,1.175,13.038a93.526,93.526,0,0,1,21.641,2.474Z"
          transform="translate(-728.368 -305.437)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="rb2"
          d="M793.2,258.678c3.874,11.361-.453,31.876-6,34.026s-9.375-36.916-7.874-45.422S789.324,247.317,793.2,258.678Z"
          transform="translate(-755.384 -311.343)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="rb1"
          d="M741.007,248.542c-.541,13.733-2.034,51.742-2.307,58.694l-.028.706c7.1-1.424,10.1-2.292,17.649-2.569h4.408s-.636.685-.547-4.417,2.2-12.769.9-15.992c-2.372-5.91-7.863-20.276-9.2-26.559C748.1,240.648,741.6,233.467,741.007,248.542Z"
          transform="translate(-728.368 -305.437)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r3"
          d="M455.733,315.647c1.291,2.384,9.37,2.552,10.18,0a113.982,113.982,0,0,0,0-18.819c-.637-8.31-1.5-17.172-1.929-20.871-.446-3.883-2.6-10.408-3.86-4.5-.709,3.313.87,15.333,0,26.569C459.479,306.356,455.166,314.6,455.733,315.647Z"
          transform="translate(-417.438 -325.415)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r2"
          d="M493.093,295.737c-.541,8.667-2.412,15.117-2.839,15.213-.677.152-3.177-7.024-4.09-15.213-.338-3.03-1.745-13.276-.371-14.957a.78.78,0,0,1,.371-.238C488.016,279.435,493.3,292.422,493.093,295.737Z"
          transform="translate(-457.484 -340.357)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M461.563,286.269c-.811-12.152-3.291-16.368-4.388-14.934s0,14.033,0,25.806-1.071,20.3,0,21.285,10.838-.649,10.779-3.5c-.022-1.16-.833-2.452-1.94-4.406a23.248,23.248,0,0,1-2.139-4.838C462.351,300.6,462.015,293.037,461.563,286.269Z"
          transform="translate(-440.438 -325.415)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(0.553 -96.829)">
          {props.abscess && (
            <g id="abscess">
              <g
                id="p1"
                transform="translate(21)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
          )}
          {props.abscess && (
            <g id="diastema" transform="translate(51.667 132.504)">
              <path
                id="p2"
                d="M1178.17,630.863V578.089"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,630.863V578.089"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          )}
          {props.cracked && (
            <g id="cracked">
              <path
                id="p1-3"
                data-name="p1"
                d="M1099.67,572.241l1.28-10.947,8.533,2.32,3.523-8.122"
                transform="translate(-1070.5 -433.112)"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
          )}
          {props.recession && (
            <g id="recession">
              <path
                id="p1-4"
                data-name="p1"
                d="M1110.7,693.429s14.059-3.394,27.075-3.394a115.674,115.674,0,0,1,25.341,3.168"
                transform="translate(-1105.5 -595.41)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          )}
        </g>
      )}
      {props.proceedures && (
        <g id="procedures">
          <g id="hyg" transform="translate(-0.947 -1.134)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc" transform="translate(3.053 -0.134)">
            <path
              id="shape2"
              d="M577.366,30.884c-3.772,6.918-4.581,14.555,2.577,21.054,2.855,2.593,7.962-2.412,12.411-1.782,5.636.807,10.968,5.921,16.219,1.782,8.735-6.886,3.824-14.135.438-21.054s-9.353-6.628-13.98-6.62C590.261,24.364,581.138,23.966,577.366,30.884Z"
              transform="translate(-563.264 -22.47)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(8.004 -71.21)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="seal" transform="translate(19.816 83.977)">
            <path
              id="seal-2"
              data-name="seal"
              d="M720.54,542.711s26.9-3.412,26.9-8.1c0-2.482-9.012-4.96-16.192-6.557-6.009-1.336-10.249-1.77-10.705-4.081-.97-4.918,23.017-9.033,23.017-9.033"
              transform="translate(-720.511 -514.944)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="implant" transform="translate(14.553 -89.516)">
            <path
              id="p5"
              d="M806.3,381.864l-1.668,16.881s6.666,8.989,11.759,12.024,3.278,2.974,8.5,0S837.222,399,837.222,399l-.142-7.384Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.83,365.231,806.872,376.7,836,385.942l-1.657-12.363Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p3"
              d="M810.451,348.775l-1.622,11.464,25,7.994-1.364-12.388Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M812.186,332.484,810.979,343.8l20.74,6.335-1.659-11.916Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M829.419,332.9l-16.668-5.053s.528-21.344,8.635-20.25S829.419,332.9,829.419,332.9Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(1.053 -1.134)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H70"
              transform="translate(-3.5 24.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H70"
              transform="translate(-3.5 104.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(17.886 -40.406)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(13.3 -104.044)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-6"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-4"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-7"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
