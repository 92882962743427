import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <g id="top4" transform="translate(-740.5 -84.367)">
      <g id="bounds" transform="translate(740.5 84.367)">
        <line
          id="bound"
          x2="70"
          transform="translate(0 246)"
          fill="none"
        ></line>
        <line id="bound-2" data-name="bound" x2="70" fill="none"></line>
      </g>
      <g id="surface" transform="translate(752.829 276.837)">
        <path
          id="shape"
          d="M-50.437-83.268c3.447,1.947,7.124,3.039,8.986,5.577a13.36,13.36,0,0,1,2.279,8.8,21.888,21.888,0,0,1-2.585,9.058A17.326,17.326,0,0,1-48.9-52.246c-3.308,1.685-7.672,2.122-12.064,2.144-4.364.022-8.784-.35-12.426-1.882a18.8,18.8,0,0,1-8.506-7.2,14.059,14.059,0,0,1-2.391-9.452c.417-3.129,2.2-6.06,4.392-8.686,2.2-2.6,3.252-2.531,6.5-4.587,3.225-2.057,8.617-6.265,12.4-6.2S-53.911-85.215-50.437-83.268Z"
          transform="translate(84.367 88.112)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#707070"}
          stroke-width="0.5"
        ></path>
        <path
          id="m"
          d="M-77.682-54.521a18.645,18.645,0,0,1-4.215-4.66,14.059,14.059,0,0,1-2.391-9.452c.417-3.129,2.2-6.06,4.392-8.686,1.465-1.737,14.128,5.827,13.647,12.329-.23,3.115-4.691,3.191-7.549,5.808S-77.267-54.2-77.682-54.521Z"
          transform="translate(84.367 88.112)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
        <path
          id="l"
          d="M-43.028-57.7A15.611,15.611,0,0,1-48.9-52.246c-3.308,1.685-7.672,2.122-12.064,2.144-4.364.022-8.784-.35-12.426-1.882a18.776,18.776,0,0,1-3.7-2.09c-.466-.328.5-2.906,3.7-5.5a23.92,23.92,0,0,1,4.333-2.254,24.129,24.129,0,0,1,5.913-2.1,16.719,16.719,0,0,1,7.652,0c2.824.814,3.476,2.8,6.592,4.351S-42.714-58.185-43.028-57.7Z"
          transform="translate(84.367 88.112)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
        <path
          id="d"
          d="M-45.982-81.026a12.09,12.09,0,0,1,4.532,3.335,13.36,13.36,0,0,1,2.279,8.8,21.888,21.888,0,0,1-2.585,9.058l-1.177,1.919a14.534,14.534,0,0,1-5.279-1.247c-.807-.343-4.357-3.855-3.747-9.73C-51.434-73.966-46.776-81.419-45.982-81.026Z"
          transform="translate(84.367 88.112)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
        <path
          id="b"
          d="M-50.437-83.268c1.241.7,4.209,2.144,4.209,2.144a9.04,9.04,0,0,1-6.2,3.9c-2.211.282-5.817-2.344-9.059-2.008-3.5.362-5.385,2.945-7.72,3.682-2.828.891-5.394-.2-7.5-1.383a9.737,9.737,0,0,0-2.834-.781,8.624,8.624,0,0,1,1.45-1.317c.94-.7,3.23-1.949,4.7-2.88,3.225-2.057,8.617-6.265,12.4-6.2S-53.911-85.215-50.437-83.268Z"
          transform="translate(84.367 88.112)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
        <path
          id="o"
          d="M19.316,38.966a5.257,5.257,0,0,0,.6-5.422c-1.422-3.007-6.583-7.118-6.583-7.118a10.143,10.143,0,0,0,4.864-.669c1.843-.776,3.689-2.931,6.761-3.325,2.8-.359,5.911,1.651,8.613,1.941a6.993,6.993,0,0,0,4.485-1.116s-3.23,5.668-3.392,10.286a13.949,13.949,0,0,0,2.075,7.832A11.894,11.894,0,0,0,29.692,37.4C25.268,36.768,19.316,38.966,19.316,38.966Z"
          transform="translate(-2.398 -13.503)"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown" transform="translate(747.239 196.101)">
        <path
          id="crown-2"
          data-name="crown"
          d="M39.77,2.9s4.13,9.26,7.245,15.858a15.911,15.911,0,0,1,0,12.889S36.121,46.223,23.8,50.059.421,34.1.012,27.555,9.525,2.9,9.525,2.9A40.445,40.445,0,0,1,23.8,0C31.356,0,39.77,2.9,39.77,2.9Z"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root" transform="translate(756.79 102.504)">
        <path
          id="rb1"
          d="M922.656,182.372s-10.192-33.379-8.379-63.882.479-31.113-6.294-32.878a6.475,6.475,0,0,0-3.217,1.044,32.96,32.96,0,0,0-3.517,3.267c-2.441,2.787-4.143,16.672-6.32,30.923-2.057,13.467-.512,49.875-1.94,58.719-.032.212-.62,2.326-.62,2.326a38.532,38.532,0,0,1,14.188-2.742C914.129,179.268,922.656,182.372,922.656,182.372Z"
          transform="translate(-892.369 -85.612)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r2"
          d="M904.089,152.77c2.137-.21-5.741-23.438-5.145-45.242s-.011-22.733-1.444-21.916-3.574,15-4.29,25.185c-.676,9.626-1.265,39.708-.628,41.973S901.953,152.98,904.089,152.77Z"
          transform="translate(-880.035 -64.869)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M900.953,107.528c.784-21.8,3.2-31.781,1.317-30.964s-5.142,20.777-6.084,30.964c-.889,9.626-1.626,28.56-1.909,37.185s2.057,7.767,4.158,7.759C901.71,152.558,900.169,129.332,900.953,107.528Z"
          transform="translate(-890.011 -63.814)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(750.608 86.089)">
          <g id="abscess" transform="translate(12.892)">
            <g id="p1" fill="none" stroke="#000" stroke-width="4">
              <circle cx="10" cy="10" r="10" stroke="none"></circle>
              <circle cx="10" cy="10" r="8" fill="none"></circle>
            </g>
          </g>
          <g id="diastema" transform="translate(46.558 145.504)">
            <path
              id="p2"
              d="M1178.17,630.863V578.089"
              transform="translate(-1178.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M1178.17,630.863V578.089"
              transform="translate(-1168.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="cracked" transform="translate(18.329 140.708) rotate(19.993)">
            <path
              id="p1-3"
              data-name="p1"
              d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
              fill="none"
              stroke="#000"
              stroke-linecap="square"
              stroke-width="5"
            ></path>
          </g>
          <g id="recession" transform="translate(0 107.859)">
            <path
              id="p3"
              d="M1111.61,695.1s6.935-5.834,19.952-5.834a49.237,49.237,0,0,1,22.864,5.834"
              transform="translate(-1111.608 -689.269)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
        </g>
      )}
      {props.proceedures && (
        <g id="procedures" transform="translate(740.5 102.779)">
          <g id="hyg" transform="translate(2.5 95.896)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc" transform="translate(26.792 93.377)">
            <path
              id="rctpc-2"
              data-name="rctpc"
              d="M587.755,20.871s2.688,24.576,1.446,26.316-8.036,1.326-9.03,0,2.022-26.6,2.022-26.6,1.036-.026,2.422,0a30.233,30.233,0,0,1,3.141.281Z"
              transform="translate(-579.969 -20.578)"
              fill="red"
            ></path>
          </g>
          <g id="rct" transform="translate(26.798)">
            <path
              id="rct-2"
              data-name="rct"
              d="M765.531,44.1s-2.468-25.092-2.215-50.59c.268-27.066,4.5-47.855,6.48-41.617,1.749,5.506-1.524,20.083-1.509,41.617.018,24.947,2.744,50.786,2.744,50.786s-1.117-.078-3.1-.2A10.136,10.136,0,0,0,765.531,44.1Z"
              transform="translate(-763.298 49.221)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(10.451 4.929)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="seal" transform="translate(25.263 182.487)">
            <path
              id="seal-2"
              data-name="seal"
              d="M720.536,538.586s22.9-2.905,22.9-6.893c0-2.113-7.673-4.223-13.787-5.583-5.117-1.138-8.727-1.507-9.115-3.475-.826-4.187,19.6-7.691,19.6-7.691"
              transform="translate(-720.511 -514.944)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="implant" transform="translate(16.495 11.4)">
            <path
              id="p5"
              d="M806.184,381.864l-1.553,15.719s6.208,8.37,10.95,11.2,3.052,2.77,7.914,0,11.485-10.96,11.485-10.96l-.133-6.876Z"
              transform="translate(-804.63 -312.669)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.7,365.23l-1.823,10.682,27.12,8.6L832.449,373Z"
              transform="translate(-804.785 -311.524)"
              fill="red"
            ></path>
            <path
              id="p3-2"
              data-name="p3"
              d="M810.34,348.775l-1.51,10.675,23.281,7.444-1.27-11.536Z"
              transform="translate(-804.919 -310.392)"
              fill="red"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M812.1,332.484l-1.124,10.534,19.313,5.9-1.545-11.1Z"
              transform="translate(-805.067 -309.271)"
              fill="red"
            ></path>
            <path
              id="p1-4"
              data-name="p1"
              d="M828.272,331.155l-15.521-4.7s.492-19.875,8.041-18.857S828.272,331.155,828.272,331.155Z"
              transform="translate(-805.189 -307.555)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(0 117.968)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H70"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H70"
              transform="translate(0 80)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(22.333 53.733)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(12.747 -8.905)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-4"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-6"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
