import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";

export const Container = styled.div`
  display: grid;
  background-color: ${colors.secColor};
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-rows: 6rem 0 auto;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: auto;
  background: ${colors.primary};
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  transition: all 0.5s ease;
  min-height: 74px;
  text-align: center;
  color: ${colors.primary};
  a {
    color: ${colors.primary};
    margin: 0.6em;
  }
`;

export const MenuHeader = styled.div`
  display: grid;
  grid-template-columns: 8fr 2fr;
  margin: 0.2rem 0;
`;

export const Content = styled.div`
  display: grid;
  @media (min-width: 481px) {
    justify-content: center;
  }
  @media (max-width: 481px) {
    justify-content: stretch;
  }
`;

export const ContentWrapper = styled.div`
  width: 100%;
`;
