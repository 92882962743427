import localforage from "localforage";

const invoicesUpdate = (eventData) => async (dispatch) => {
  const { Invoice } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Invoice.PatientId) return;
  let data = {
    ...patient,
    Invoices: patient.Invoices.map((i) => {
      if (i.InvoiceId === Invoice.InvoiceId) {
        return Invoice;
      }
      return i;
    }),
  };

  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const invoicesCreate = (eventData) => async (dispatch) => {
  const { Invoice } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Invoice.PatientId) return;
  let data = {
    ...patient,
    Invoices: [...patient.Invoices.filter((i) => i.id !== Invoice.id), Invoice],
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const invoicesDelete = (eventData) => async (dispatch) => {};

export default {
  create: invoicesCreate,
  update: invoicesUpdate,
  delete: invoicesDelete,
};
