import B48 from "./B48";
import B47 from "./B47";
import B46 from "./B46";
import B45 from "./B45";
import B44 from "./B44";
import B43 from "./B43";
import B42 from "./B42";
import B41 from "./B41";

import B31 from "./B31";
import B32 from "./B32";
import B33 from "./B33";
import B34 from "./B34";
import B35 from "./B35";
import B36 from "./B36";
import B37 from "./B37";
import B38 from "./B38";

export default {
  B48,
  B47,
  B46,
  B45,
  B44,
  B43,
  B42,
  B41,
  B31,
  B32,
  B33,
  B34,
  B35,
  B36,
  B37,
  B38,
};
