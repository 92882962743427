import styled, { css } from "styled-components";
import colors from "../../library/styled-components/colors";

export const Container = styled.div`
  position: relative;
  z-index: 104;
  display: grid;
  overflow: hidden;
  ${(props) =>
    props.open
      ? css`
          grid-template-columns: 30rem 50rem;
        `
      : css`
          grid-template-columns: 30rem 0rem;
        `};

  height: 80vh;
  min-width: 30rem;
  bottom: 0;
  background: white;
  border-radius: 4px;
`;

export const MessagePanel = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  background: ${colors.lightGrey};
  justify-content: end;
`;

export const WrapMessage = styled.div`
  display: grid;
`;

export const Time = styled.div`
  color: ${colors.grey};
  justify-self: center;
`;

export const Message = styled.div`
  justify-self: ${(props) => (props.type === 4 ? "start" : "end")};
  background: ${(props) =>
    props.type === 0
      ? colors.mediumGrey
      : props.type === 4
      ? colors.white
      : props.type === 3
      ? colors.primary
      : colors.softGreen};
  color: ${(props) => (props.type === 3 ? colors.white : colors.dark)};
  border-radius: 20px;
  padding: 8px 15px;
  margin-top: 5px;
  margin-bottom: 5px;
  display: inline-block;
  width: 16rem;
  margin: 1rem;
  position: relative;

  :before {
    content: "";
    position: absolute;
    z-index: 0;
    bottom: 0;
    ${(props) =>
      props.type
        ? css`
            left: -7px;
          `
        : css`
            right: -8px;
          `};
    height: 20px;
    width: 20px;
    background: ${(props) =>
      props.type === 0
        ? colors.mediumGrey
        : props.type === 4
        ? colors.white
        : props.type === 3
        ? colors.primary
        : colors.softGreen};
    ${(props) =>
      props.type
        ? css`
            border-bottom-right-radius: 15px;
          `
        : css`
            border-bottom-left-radius: 15px;
            background-attachment: fixed;
          `};
  }

  :after {
    content: "";
    position: absolute;
    z-index: 1;
    bottom: 0;
    ${(props) =>
      props.type
        ? css`
            left: -10px;
          `
        : css`
            right: -10px;
          `};
    width: 10px;
    height: 20px;
    background: ${colors.lightGrey};
    ${(props) =>
      props.type
        ? css`
            border-bottom-right-radius: 10px;
          `
        : css`
            border-bottom-left-radius: 10px;
          `};
  }
`;

export const Header = styled.div`
  height: 2rem;
`;
export const Body = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  bottom: 0.5rem;
  position: relative;
  background: ${colors.mediumGrey};
`;
