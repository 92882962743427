import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={246}
    {...props}
  >
    <g id="top8" transform="translate(7.5 111.417)">
      <g id="bounds">
        <line
          id="bound"
          x2="70"
          transform="translate(-7.5 134.583)"
          fill="none"
        ></line>
        <line
          id="bound-2"
          data-name="bound"
          x2="70"
          transform="translate(-3.5 -111.417)"
          fill="none"
        ></line>
      </g>
      <g id="surface" transform="translate(2.293 65.829)">
        <path
          id="shape"
          d="M26.607.1C7.028-1.265-4.394,11.963,1.6,35.422,4.363,46.254,13.5,53.16,24.1,51.366c9.182-1.554,15.265-6.9,18.132-20.946C44.926,17.252,47.218,1.527,26.607.1Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#717171"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1"
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M27.476,14.834a12.1,12.1,0,0,1-.957,4.6c-.807,1.536-2.13,3.182-2.582,5.084a5.921,5.921,0,0,0,1,4.391,24.912,24.912,0,0,1,3.445,1.555,17.66,17.66,0,0,1,2.672,1.672,6.909,6.909,0,0,0,2.578,1.477,31,31,0,0,0,7.575,1.1s.7-2.678,1.03-4.293c.359-1.759.913-4.839,1.281-7.468.277-1.975.372-3.513.372-3.513a13.32,13.32,0,0,1-8.465,0C31.74,18.14,27.642,13.685,27.476,14.834Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M.423,18.27c-.77,3.886-.336,10.082.893,16,.066.322,3.337-.98,6.573-3.415a41.839,41.839,0,0,0,5.012-5.2c3.116-3.364.326-7.655.326-7.655a55.87,55.87,0,0,1-5.716.412C4.311,18.477.488,17.936.423,18.27Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="dl"
          d="M43.9,19.062c.456-7.275-1.13-13.975-9.21-17.28-1.084-.445-7,2.245-8.791,6.43-.481,1.1.924,1.617,1.313,2.875s-.319,2.8.352,3.422c.078.073.69.377,1.672,1.047a30.09,30.09,0,0,0,2.421,1.835c2.975,1.932,3.172,2.077,6.135,2.62a12.326,12.326,0,0,0,5.718-.448.646.646,0,0,0,.39-.5Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M26.607.1C19.969-.366,14.27.85,9.63,3.721c-.245.151-1.616,3.6-.482,5.948.817,1.706,5.761,5.958,8.528,5.519.86-.136,4.452-4.706,5.479-5.287,1.414-.787,2.429-.682,2.548-1.2.333-1.386,1.93-3.267,4.1-4.977s4.881-1.977,4.265-2.206A27.475,27.475,0,0,0,26.607.1Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="db"
          d="M8.917,4.229c-4.04,2.7-7.123,7.405-8.362,13.657-.121.609,5.792.64,5.792.64s6.758-.21,6.843-.763c.189-.678-.78-1.4-1.613-3.279C10.8,12.713,9.654,11.258,8.9,8.869,8.244,6.8,9.374,3.921,8.917,4.229Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M23,5.443c3.28,1.076,9.455-.629,10.489-.414S30.445,7.379,28.7,8.951s-1.152,2.581-.03,4.9c1.152,2.317,2.807,5.957,1.271,6.9-1.566.943-6.353-.794-8.982-2.9-2.63-2.085-3.1-4.534-4.934-5.378-1.861-.86-5.082-.116-8.51-.347C4.089,11.913.454,10.7.041,9.382s2.393-2.8,5.378-3.458c2.955-.678,6.087-.563,8.332-1.986C16.026,2.531,17.414-.414,18.33.049S19.69,4.368,23,5.443Z"
          transform="matrix(0.927, -0.375, 0.375, 0.927, 8.437, 27.646)"
          fill={props.surface?.l.fill || "#dedede"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
        <path
          id="ml"
          d="M35.172,45.969c2.435-2.339,4.375-5.8,5.891-10.891.007-.023.117-.422.117-.422s-7.059.05-9.6-2.071c-1.69-1.41-6.143-3.866-6.508-3.539-1.05.939,1.788,4.178,2.309,7.294a16.01,16.01,0,0,1,0,4.589l4.2,3.25a26.061,26.061,0,0,0,2.7,1.273C34.938,45.7,35.016,46.119,35.172,45.969Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M12.941,49.914a18.446,18.446,0,0,0,11.164,1.452,19.719,19.719,0,0,0,10.787-5.122c.856-.807-3.513-1.193-6.681-4.815-1.174-1.344-1.889.951-2.961,0-.358-.325-3.53-2.51-4.551-4.85a38.249,38.249,0,0,0-2.025-3.55,16.341,16.341,0,0,0-6.122,5.907A27.842,27.842,0,0,0,9.8,47.986S11.888,49.4,12.941,49.914Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="mb"
          d="M1.316,34.4s.149.5.281,1.017C2.947,40.7,5.3,44.656,9.366,47.7c.952.715.729-4.458,2.884-8.325a19.25,19.25,0,0,1,6.351-6.3,9.514,9.514,0,0,0-4.154-3.433,22.488,22.488,0,0,1-2.141-1.033,3.874,3.874,0,0,1-1.1-.872A25.9,25.9,0,0,1,6.74,31.71,27.163,27.163,0,0,1,1.316,34.4Z"
          transform="translate(53.356 9.54) rotate(90)"
          fill={props.surface?.l.fill || "#f5f5f5"}
          stroke={props.surface?.l.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
      </g>
      <g id="crown">
        <path
          id="main"
          d="M8.708,4S-2.442,24.646.492,33.678s12.226,16.881,18.78,13.333,11.15,0,11.15,0,6.162,6.344,13.4,0S58.3,37.656,56.538,25.291A57.305,57.305,0,0,0,48.908,4s-9.74-4-19.789-4S8.708,4,8.708,4Z"
          transform="translate(3)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root">
        <path
          id="rb3"
          d="M496.269,329.857s.677-65.057-7.533-65.135-2.02,19.18-7.323,34.706a116.33,116.33,0,0,1-13.888,27.4A51.96,51.96,0,0,1,481,326.4c7.186.757,9.255.822,15.273,3.457Z"
          transform="translate(-444.381 -325.919)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="rb2"
          d="M503.028,300.425c-1.309,11.726-5.833,20.452-6.867,20.582-1.638.206-7.683-9.5-9.891-20.582-.817-4.1,1.209-15.27,4.531-17.545a2.313,2.313,0,0,1,.9-.322C496.178,281.061,503.529,295.94,503.028,300.425Z"
          transform="translate(-463.512 -338.367)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="rb1"
          d="M477.712,308.64s-5.074-5.107-7.864-16.348c-2.31-9.307-2.216-24.558-4.2-29.12-4.253-9.79-9.54,11.5-9.54,24.011s-.073,42.769-.073,42.769a29.2,29.2,0,0,1,4.855-1.656c2.878-.723,6.731-1.56,6.731-1.56a85.784,85.784,0,0,0,5.126-8.177C475.264,313.977,477.712,308.64,477.712,308.64Z"
          transform="translate(-444.381 -325.919)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r3"
          d="M483.06,257.979c-1.36-1.863-.686,12.9-3.579,24.217s-8.622,17.735-7.991,21.03c.364,1.92,6.491,2.019,9.9,0,2.311-1.36,3.9-12.856,4.314-24.168S484.42,259.841,483.06,257.979Z"
          transform="translate(-437.903 -309.684)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r2"
          d="M493.093,293.047c-.541,6.912-2.412,12.056-2.839,12.133-.677.121-3.177-5.6-4.09-12.133-.338-2.416.5-9,1.874-10.342a.812.812,0,0,1,.371-.19C490.261,281.633,493.3,290.4,493.093,293.047Z"
          transform="translate(-458.537 -330.453)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M463.762,282.159c-1.66-6.69-1.818-13.527-3.242-16.807-3.057-7.037-3.344,4.61-3.344,13.606s1.9,28.267,3.971,27.747,7.118-8.385,7.059-10.54C468.114,292.555,465.128,287.661,463.762,282.159Z"
          transform="translate(-441.491 -309.202)"
          fill="none"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.proceedures && (
        <g id="procedures">
          <g id="hyg">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>

          <g id="rctpc" transform="translate(-2.351 3)">
            <path
              id="shape2"
              d="M581.43,30.729c-3.684,6.756-8.6,14.214-1.615,20.56,2.788,2.532,7.776-2.355,12.12-1.74,5.5.788,10.711,5.782,15.839,1.74,8.531-6.725,3.734-13.8.428-20.56s-9.134-6.472-13.652-6.465C589.892,24.362,585.114,23.972,581.43,30.729Z"
              transform="translate(-560 -24.784)"
              fill="red"
            ></path>
          </g>

          <g id="seal" transform="translate(18.763 82.111)">
            <path
              id="seal-2"
              data-name="seal"
              d="M720.54,542.711s26.9-3.412,26.9-8.1c0-2.482-9.012-4.96-16.192-6.557-6.009-1.336-10.249-1.77-10.705-4.081-.97-4.918,23.017-9.033,23.017-9.033"
              transform="translate(-720.511 -514.944)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>

          <g id="implant" transform="translate(14.5 -88.382)">
            <path
              id="p5"
              d="M806.3,381.864l-1.668,16.881s6.666,8.989,11.759,12.024,3.278,2.974,8.5,0S837.222,399,837.222,399l-.142-7.384Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.83,365.231,806.872,376.7,836,385.942l-1.657-12.363Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p3"
              d="M810.451,348.775l-1.622,11.464,25,7.994-1.364-12.388Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p2"
              d="M812.186,332.484,810.979,343.8l20.74,6.335-1.659-11.916Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p1"
              d="M829.419,332.9l-16.668-5.053s.528-21.344,8.635-20.25S829.419,332.9,829.419,332.9Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
          </g>

          <g id="bridge" transform="translate(-4)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H74"
              transform="translate(-3.5 24.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H74"
              transform="translate(-3.5 104.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>

          <g id="crr" transform="translate(16.833 -41.272)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>

          <g id="resection" transform="translate(8.004 -71.21)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,118.788,45.338,66.982"
              transform="translate(-3.415 -66.982)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>

          <g id="sinuslift" transform="translate(13.247 -102.91)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-3"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
