import React from "react";
import styled, { keyframes } from "styled-components";
import colors from "library/styled-components/colors";
import { Button } from "@mui/material";

// CSS keyframes for the checkmark animation
const checkmarkAnimation = keyframes`
  0% {
    stroke-dashoffset: 50px;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Container = styled.div`
  text-align: center;
  padding: 20px;
`;

const Checkmark = styled.svg`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: ${colors.accentColor};
  stroke-miterlimit: 10;
  margin: 20px auto;
  box-shadow: inset 0px 0px 0px ${colors.accentColor};
  animation: fill 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;

  path {
    stroke-dasharray: 50px;
    animation: ${checkmarkAnimation} 0.3s ease-in-out 0.7s both;
  }
`;

const Title = styled.h1`
  color: ${colors.accentColor};
  margin-top: 20px;
`;

const Details = styled.p`
  color: #333;
`;

const SuccessStep = ({ completeWizard }) => {
  return (
    <Container>
      <Checkmark viewBox="0 0 52 52">
        <circle cx="26" cy="26" r="25" fill="none" />
        <path fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
      </Checkmark>
      <Title>Success!</Title>
      <Details>Sms Recalls has been generated successfully.</Details>
      <Details>
        {" "}
        You can click below to monitor the progress of the recall.
      </Details>
      <Button variant="outlined" color="primary" onClick={() => completeWizard()}>
        Show Recall Progress
      </Button>
    </Container>
  );
};

export default SuccessStep;
