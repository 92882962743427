import {
  handleResponse,
  authHeader
} from "../../library/handler";
 

export const searchTargets = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/targets/search/${data.metric}`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};