import styled from "styled-components";

export const EventBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: start;
  font-size: 0.8rem;
`;

export const EventBlockContainer = styled.div`
  height: inherit;
`;
