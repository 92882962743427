import {
  createPractitioner,
  updatePractitioner,
  deletePractitioner,
} from "../../../../../actions/practitioners";
import {
  getPractitionerSchedules,
  createSchedule,
  updateSchedule,
  deleteSchedule,
} from "../../../../../actions/schedules";

import { ADMIN_NS } from "../../../../../config";

const handleResponse =
  (response, id = null) =>
  async (dispatch, navigateTo) => {
    if (response && response.code === "EXPIRED") {
      dispatch({
        type: "REPLACE",
        context: "user",
        data: null,
        updating: false,
      });
      navigateTo("/admin/access/login");
    }

    !!id &&
      response &&
      response.code === "SUCCESS" &&
      (dispatch({
        context: "list",
        type: "UPDATE_ITEM",
        updating: false,
        data: response.data,
        uuid: { PractitionerId: id },
      }));

    !id &&
      response &&
      response.code === "SUCCESS" &&
      (dispatch({
        type: "ADD",
        context: "list",
        data: response.data,
      }));

    response &&
      response.code == "INVALID_PHONE" &&
      (dispatch({
        type: "UPDATE",
        context: "list",
        meta: {
          updating: false,
          warning: "Phone number is invalid or unsupported!",
        },
      }));

    response &&
      response.code == "INVALID" &&
      (dispatch({
        type: "UPDATE",
        context: "list",
        meta: {
          updating: false,
          warning: "Data is invalid!",
        },
      }));

    response &&
      response.code == "ALREADY_REGISTERED" &&
      (dispatch({
        type: "UPDATE",
        context: "list",
        meta: {
          updating: false,
          warning: "Email already exists",
        },
      }));

    response &&
      response.code == "ERROR" &&
      (dispatch({
        type: "UPDATE",
        context: "list",
        meta: {
          updating: false,
          warning: "something went wrong",
        },
      }));

    return response;
  };

export const editData =
  (data, PractitionerId) => async (dispatch, navigateTo) => {
    if (PractitionerId) {
      dispatch({
        context: "list",
        type: "UPDATE_ITEM",
        updating: true,
        uuid: { PractitionerId },
      });
      const response = await updatePractitioner(ADMIN_NS, data, PractitionerId);
      return await handleResponse(response, PractitionerId)(
        dispatch,
        navigateTo
      );
    } else {
      const response = await createPractitioner(ADMIN_NS, data, PractitionerId);
      return await handleResponse(response, PractitionerId)(
        dispatch,
        navigateTo
      );
    }
  };

export const newSchedule = (send) => async (dispatch) => {
  const data = await createSchedule(ADMIN_NS, send);
  data &&
    (dispatch({
      type: "ADD",
      context: "schedules",
      data,
      updating: false,
    }));
};

export const removeSchedule = (PractitionerId, ScheduleId) => async (dispatch) => {
  const data = await deleteSchedule(ADMIN_NS, PractitionerId, ScheduleId);
  data &&
    data.code === "SUCCESS" &&
    (dispatch({
      type: "REMOVE_ITEM",
      context: "schedules",
      uuid: { ScheduleId },
      updating: false,
    }));
};

export const editSchedule = (send, ScheduleId) => async (dispatch) => {
  dispatch({
    context: "schedules",
    type: "UPDATE_ITEM",
    updating: true,
    uuid: { ScheduleId },
  });
  const data = await updateSchedule(ADMIN_NS, send, ScheduleId);
  data &&
    (dispatch({
      context: "schedules",
      type: "UPDATE_ITEM",
      updating: false,
      data,
      uuid: { ScheduleId },
    }));
};

export const removePractitioner = (PractitionerId) => async (dispatch) => {
  const data = await deletePractitioner(ADMIN_NS, PractitionerId);
  data &&
    data.code === "SUCCESS" &&
    (dispatch({
      type: "REMOVE_ITEM",
      context: "list",
      uuid: { PractitionerId },
      updating: false,
    }));
};
