import React from "react";
import CreditCardFeaIcon from "../../../../icon/fea-icons/credit-card";
import colors from "../../../../library/styled-components/colors";
import * as Joi from "@hapi/joi";
import UserFeaIcon from "../../../../icon/fea-icons/user";
import ClipBoardFeaIcon from "../../../../icon/fea-icons/clipboard";
import EuroFeaIcon from "../../../../icon/fea-icons/euro";

const validation = {
  Filter: Joi.string().required(),
  Choice: Joi.string().required(),
  Amount: Joi.number().min(1).required(),
  Method: Joi.string().min(4).required(),
  Price: Joi.number().required(),
  Description: Joi.string().min(4).required(),
  Patients: Joi.string().min(1).max(255).required(),
  Practitioners: Joi.string().min(1).max(255).required(),
  Treatments: Joi.string().min(1).max(255).required(),
};

export const filterFormSchema = {
  Filter: {
    key: 1,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Filter",
    type: "select",
    options: [
      { key: 1, name: "Practitioner", value: "Practitioner" },
      { key: 2, name: "Treatment", value: "Treatment" },
      { key: 3, name: "Method", value: "Method" },
    ],
    defaultValue: "choose",
    call: "filter",
    icon: <CreditCardFeaIcon size="16" />,
    validation: Joi.object({ Filter: validation.Filter }),
  },
};

export const choiceFormSchema = {
  Choice: {
    key: 2,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Choice",
    type: "select",
    options: [],
    defaultValue: "choose",
    icon: <CreditCardFeaIcon size="16" />,
    validation: Joi.object({ Choice: validation.Choice }),
  },
};

export const createButtons = [
  {
    text: "Pay",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const chargeSchema = {
  Patients: {
    key: 1,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Patients",
    type: "select",
    options: [],
    defaultValue: "Choose",
    call: "select-patient",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Patients: validation.Patients }),
  },
  Practitioners: {
    key: 2,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Practitioners",
    placeholder: "Select Practitioner",
    type: "select",
    options: [],
    defaultValue: "Choose",
    call: "select-practitioner",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Practitioners: validation.Practitioners }),
  },
  Treatments: {
    key: 3,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Treatments",
    placeholder: "Select Treatment",
    type: "select",
    options: [],
    defaultValue: "Choose",
    call: "select-practitioner",
    icon: <ClipBoardFeaIcon size="16" />,
    validation: Joi.object({ Treatments: validation.Treatments }),
  },
  Price: {
    key: 4,
    domRef: null,
    type: "number",
    text: "Amount",
    placeholder: "Amount",
    val: null,
    disable: false,
    icon: <EuroFeaIcon size="16" />,
    validation: Joi.object({ Price: validation.Price }),
  },
};

export const paymentFormSchema = {
  Method: {
    key: 1,
    domRef: null,
    isRequired: true,
    ignore: false,
    color: colors.errorColor,
    text: "Method",
    type: "select",
    defaultValue: "Choose Method",
    call: "focus",
    options: [
      { key: 1, name: "Cash", value: "CASH" },
      { key: 2, name: "Credit Card", value: "CREDIT" },
      { key: 3, name: "Debit Card", value: "DEBIT" },
      { key: 4, name: "Invoice", value: "INVOICE" },
      { key: 5, name: "Practice Plan", value: "PLAN" },
      { key: 6, name: "Cheque", value: "CHEQUE" },
      { key: 7, name: "Refund", value: "REFUND" },
    ],
    icon: <CreditCardFeaIcon size="16" />,
    validation: Joi.object({ Method: validation.Method }),
  },
  Amount: {
    key: 4,
    domRef: null,
    type: "number",
    text: "Amount",
    placeholder: "Amount",
    val: null,
    disable: false,
    max: "5",
    icon: <EuroFeaIcon size="16" />,
    validation: Joi.object({ Amount: validation.Amount }),
  },
};
