import React from "react";
import styled from "styled-components";

const LeftMargin = styled.div`
  height: fit-content;
`;

const LeftMarginTable = styled.table`
  position: relative;
`;

const LeftMarginTableBoldRow = styled.tr`
  height: 12.5rem;
`;

const LeftColumnThree = () => {
  return (
    <LeftMargin>
      <LeftMarginTable>
        <tbody>
          <tr>
            <td>Bleeding</td>
          </tr>
          <tr>
            <td>Plaque</td>
          </tr>
          <tr>
            <td>margin</td>
          </tr>
          <tr>
            <td>depth</td>
          </tr>
          <LeftMarginTableBoldRow>
            <th>Lingual</th>
          </LeftMarginTableBoldRow>
        </tbody>
      </LeftMarginTable>
    </LeftMargin>
  );
};

export default LeftColumnThree;
