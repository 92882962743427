import {
  Dialog,
  DialogContent,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  AppBar,
  Toolbar,
  IconButton,
  Table,
  Paper,
  TableContainer,
  Box,
  Typography,
  Button,
  DialogActions,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { format } from "date-fns";
import { changeBatchStatus } from "actions/sms";
import { ADMIN_NS } from "config";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import PauseCircleOutlineIcon from "@mui/icons-material/PauseCircleOutline";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import colors from "library/styled-components/colors";
import { AppContext } from "hooks/context";
import { useContext } from "react";

const RecallHistory = ({ show, close }) => {
  const {
    state: { batchqueues },
  } = useContext(AppContext);
  const [changeStatus, setChangeStatus] = useState(null);

  const calculateProgress = (total, sent, failed) => {
    return total > 0 ? ((sent + failed) / total) * 100 : 0;
  };

  const calculateSent = (total, sent) => {
    return total > 0 ? (sent / total) * 100 : 0;
  };

  const calculateBuffer = (total, sent, failed) => {
    return total > 0 ? ((sent + failed) / total) * 100 : 0;
  };

  const ActionStatusComponent = ({ data }) => {
    switch (data.Status) {
      case "completed":
        return (
          <div
            style={{
              padding: "8px",
            }}
          >
            <CheckCircleOutlineIcon style={{ color: colors.success }} />
          </div>
        );
      case "failed":
        return (
          <div
            style={{
              padding: "8px",
            }}
          >
            <CheckCircleOutlineIcon style={{ color: colors.danger }} />
          </div>
        );
      case "pending":
        return (
          <IconButton
            color="primary"
            onClick={() =>
              setChangeStatus({
                status: "cancelled",
                batchId: data.BatchQueueId,
              })
            }
          >
            <StopCircleIcon />
          </IconButton>
        );
      case "processing":
        return (
          <IconButton
            color="default"
            onClick={() =>
              setChangeStatus({
                status: "paused",
                batchId: data.BatchQueueId,
              })
            }
          >
            <PauseCircleOutlineIcon />
          </IconButton>
        );
      case "cancelled":
      case "paused":
      default:
        return (
          <IconButton
            color="default"
            onClick={() =>
              setChangeStatus({ status: "pending", batchId: data.BatchQueueId })
            }
          >
            <PlayCircleIcon />
          </IconButton>
        );
    }
  };

  const ConfirmStatusChaneDialog = ({ open, close, status, confirm }) => {
    let dialogtext = null;
    switch (status) {
      case "cancelled":
        dialogtext = "Are you sure you want to cancel this batch recall?";
        break;
      case "paused":
        dialogtext = "Are you sure you want to pause this batch recall?";
        break;
      case "pending":
        dialogtext = "Are you sure you want to resume this batch recall?";
        break;
      default:
        break;
    }

    return (
      <Dialog
        open={open}
        onClose={close}
        maxWidth={"lg"}
        style={{ padding: "0px 0px 0px 0px" }}
      >
        <DialogTitle>Recall Change</DialogTitle>
        <DialogContent>
          <DialogContentText>{dialogtext}</DialogContentText>
          <DialogActions>
            <Button onClick={close}>Cancel</Button>
            <Button onClick={confirm} color="primary">
              Confirm
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <Dialog
      open={show}
      onClose={close}
      fullScreen={true}
      maxWidth={"lg"}
      //TransitionComponent={Transition}
      style={{ padding: "0px 0px 0px 0px" }}
    >
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={close}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Generated Recall History Log
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent style={{ padding: "0px 0px 0px 0px" }}>
        <DialogContent>
          <TableContainer component={Paper}>
            <Table aria-label="SMS Batch Queue">
              <TableHead>
                <TableRow>
                  <TableCell>Created</TableCell>
                  <TableCell align="left">Action</TableCell>
                  <TableCell align="left">Status</TableCell>
                  <TableCell align="right">Total</TableCell>
                  <TableCell align="right">Sent</TableCell>
                  <TableCell align="right">Failed</TableCell>
                  <TableCell align="right">Progress</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {batchqueues?.data?.map((row) => (
                  <TableRow key={row?.data?.BatchQueueId}>
                    <TableCell component="th" scope="row">
                      {format(
                        new Date(row?.data?.CreatedAt),
                        "dd MMM yyyy HH:mm"
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <ActionStatusComponent data={row?.data || {}} />
                    </TableCell>
                    <TableCell align="left">{row?.data?.Status}</TableCell>
                    <TableCell align="right">
                      {row?.data?.TotalMessages}
                    </TableCell>
                    <TableCell align="right">
                      {row?.data?.SentMessages}
                    </TableCell>
                    <TableCell align="right">
                      {row?.data?.FailedMessages}
                    </TableCell>
                    <TableCell align="right" width={800}>
                      <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                          <LinearProgress
                            variant="buffer"
                            value={calculateSent(
                              row?.data?.TotalMessages,
                              row?.data?.SentMessages
                            )}
                            valueBuffer={calculateBuffer(
                              row?.data?.TotalMessages,
                              row?.data?.SentMessages,
                              row?.data?.FailedMessages
                            )}
                            sx={{
                              backgroundColor: "grey.300",
                              borderRadius: 4,
                              height: 6,
                              [`& .${linearProgressClasses.bar}`]: {
                                borderRadius: 4,
                                backgroundColor: colors.success,
                              },
                              [`& .${linearProgressClasses.dashedColorPrimary}`]:
                                {
                                  backgroundImage:
                                    "radial-gradient(circle, red 0%, red 16%, transparent 42%)",
                                  backgroundSize: "10px 10px",
                                  backgroundPosition: "0 -23px",
                                },
                              [`& .${linearProgressClasses.bar2Buffer}`]: {
                                backgroundColor: colors.danger,
                              },
                            }}
                          />
                        </Box>
                        <Box minWidth={35}>
                          <Typography
                            variant="body2"
                            color="textSecondary"
                          >{`${Math.round(
                            calculateProgress(
                              row?.data?.TotalMessages,
                              row?.data?.SentMessages,
                              row?.data?.FailedMessages
                            )
                          )}%`}</Typography>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
                {batchqueues?.data?.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No Recall History
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </DialogContent>
      <ConfirmStatusChaneDialog
        close={() => setChangeStatus(null)}
        status={changeStatus?.status}
        open={changeStatus !== null}
        confirm={async () => {
          await changeBatchStatus(ADMIN_NS, changeStatus);
          setChangeStatus(null);
        }}
      />
    </Dialog>
  );
};

export default RecallHistory;
