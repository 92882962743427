import React, { useState } from 'react'
import {
  Checkbox,
  ListItemText,
  MenuItem,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions
} from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList'
import { Label } from 'recharts'

const PaymentMethodDialog = ({
  selectedMethods,
  setSelectedMethods,
  paymentMethods = [
    { key: 1, name: 'Cash', value: 'cash' },
    { key: 2, name: 'Card', value: 'card' },
    { key: 3, name: 'Bank Transfer', value: 'bank-transfer' },
    { key: 4, name: 'Cheque', value: 'cheque' },
    { key: 5, name: 'Other', value: 'other' }
  ]
} = {}) => {
  const [open, setOpen] = useState(false)
  const [tempSelectedMethods, setTempSelectedMethods] = useState([
    ...selectedMethods
  ])

  const handleToggle = value => () => {
    const currentIndex = tempSelectedMethods.indexOf(value)
    const newChecked = [...tempSelectedMethods]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setTempSelectedMethods(newChecked)
  }

  const handleAllToggle = () => {
    if (tempSelectedMethods.length === paymentMethods.length) {
      setTempSelectedMethods([])
    } else {
      setTempSelectedMethods(paymentMethods.map(method => method.value))
    }
  }

  const handleOk = () => {
    setSelectedMethods(tempSelectedMethods)
    setOpen(false)
  }

  return (
    <div>
      <Button
        sx={{ height: '56px' }}
        variant='outlined'
        onClick={() => setOpen(true)}
        startIcon={<FilterListIcon />}
      >
        {`${selectedMethods.length} selected`}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Select Payment Methods</DialogTitle>
        <DialogContent>
          <MenuItem key='all' onClick={handleAllToggle}>
            <Checkbox
              checked={tempSelectedMethods.length === paymentMethods.length}
            />
            <ListItemText
              primary={`${
                paymentMethods.length === tempSelectedMethods.length
                  ? 'Unselect'
                  : 'Select'
              } All`}
            />
          </MenuItem>
          {paymentMethods.map(method => (
            <MenuItem key={method.key} onClick={handleToggle(method.value)}>
              <Checkbox
                checked={tempSelectedMethods.indexOf(method.value) > -1}
              />
              <ListItemText primary={method.name} />
            </MenuItem>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleOk} color='primary'>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default PaymentMethodDialog
