import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71.396"
      height="246"
      viewBox="0 0 71.396 246"
    >
      <g id="bottom7" transform="translate(-648.925 -498.367)">
        <g id="bounds" transform="translate(649.5 434.367)">
          <line
            id="bound"
            x2="69"
            transform="translate(0 310)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="69"
            transform="translate(0 64)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(659.16 509.398)">
          <path
            id="shape"
            d="M-38.664-75.112c4.6,1.789,8.77,4.443,11.286,7.9,2.552,3.462,3.379,7.7,3.882,12.174.467,4.471.575,9.145-1.617,13.068-2.192,3.894-6.721,7.01-11.753,8.51a30.057,30.057,0,0,1-15.419.087c-4.744-1.24-8.59-3.548-12.867-5.4-4.241-1.846-8.914-3.26-12.364-5.943-3.45-2.654-1.546-17.568,1.474-20.857,2.983-3.289,5.823-6.4,9.6-8.568A29.36,29.36,0,0,1-53.071-77.66a36.4,36.4,0,0,1,14.407,2.543Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="o"
            d="M12.541,30.129c-.075-.79,1.738-2.294,2.238-4.733s-.764-4.521-.37-5.268,1.833-1.061,3.18-2.572,1.386-3.438,2.209-3.47,1.513,1.754,3.461,1.941,3.631-1.667,4.33-1.191.032.953,2.1,2.276,5.683,2.207,6.187,3.016-.123,2.27.827,4.222,3.034,2.788,2.971,3.585-3.759.805-6.015,2.426-2.367,3.955-3.009,4.058c-1.288.206-1.777-1.432-4.927-.834s-7.672,3.225-7.672,3.225c-.888-.013-.972-1.861-2.351-3.532a27.424,27.424,0,0,0-3.159-3.149Z"
            fill="#eaeaea"
          ></path>
          <path
            id="m"
            d="M-77.518-44.707c-3.45-2.654-1.546-17.568,1.474-20.857,1.083-1.194,2.147-2.365,3.457-3.691.32-.324,8.737.5,12.012,4.444s1.982,6.777-.434,10.228-5.1,1.107-9.23,3.576S-76.672-44.05-77.518-44.707Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="mb"
            d="M-65.153-38.764c-3.8-1.652-7.937-2.958-11.5-5.312-.127-.081-.649-.465-.649-.465s.915-1.008,2.688-2.779A22.119,22.119,0,0,1-70.209-51a10.126,10.126,0,0,1,3.593-1.3c.686-.109,3.188-.272,3.188-.272s3.987,5.276,3.556,8.729S-62.656-37.686-65.153-38.764Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="b"
            d="M-46.838-32.488a30.07,30.07,0,0,1-5.448-.882c-4.18-1.093-7.865-3.135-11.842-4.969a6.22,6.22,0,0,0,3.44-2.767,7.586,7.586,0,0,0,.867-3.433s7.717-1.567,10.041-.814C-44.1-43.512-44.925-32.368-46.838-32.488Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="db"
            d="M-26.967-39.422a21.661,21.661,0,0,1-9.9,5.966,28.921,28.921,0,0,1-9.516,1,13.293,13.293,0,0,0,.969-5.9,11.3,11.3,0,0,0-1.721-4.957,10.151,10.151,0,0,1,8.5-3.951C-33.19-47.193-26.121-40.387-26.967-39.422Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="d"
            d="M-23.5-55.034c.467,4.471.575,9.145-1.617,13.068a14.6,14.6,0,0,1-1.475,2.112A37.89,37.89,0,0,0-31.65-44.6a14.958,14.958,0,0,0-4.691-2.314,14.092,14.092,0,0,1-2.135-6.068c-.113-2.984,1.556-5.2,5.073-6.878s9.189.417,9.189.417S-23.578-55.761-23.5-55.034Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="dl"
            d="M-35.114-73.517a21.947,21.947,0,0,1,7.736,6.309,19.738,19.738,0,0,1,3.239,7.9c.074.349-2.645-1.633-7.539-1.2a11.391,11.391,0,0,0-5.039,2.69,5.155,5.155,0,0,0-1.327,2.187s-5.409-4.526-6.295-5.606C-46.98-64.457-35.8-73.868-35.114-73.517Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="l"
            d="M-38.664-75.112A24.126,24.126,0,0,1-35.718-73.8c.758.389-3.684,3.275-6.447,6.2a17.314,17.314,0,0,0-2.649,4.319,13.147,13.147,0,0,1-3.963.223c-4.766-1.087-6.561-14.53-4.3-14.6A36.4,36.4,0,0,1-38.664-75.112Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
          <path
            id="ml"
            d="M-72.274-69.565a28.259,28.259,0,0,1,5.826-4.566,21.7,21.7,0,0,1,10.724-3.25c.07-.006,1.666-.247,2.049-.259.293-.01-.664,1.607-.275,4.538a40.54,40.54,0,0,0,2.072,7.263A21.17,21.17,0,0,1-57.1-62.106a15.008,15.008,0,0,1-1.609.513s-1.551-3.793-4.393-5.343C-67.072-69.1-72.665-69.176-72.274-69.565Z"
            transform="translate(87.823 63.168) rotate(-16.024)"
            fill="#f5f5f5"
          ></path>
        </g>
        <g id="crown" transform="translate(649.5 436.289)">
          <path
            id="crown-2"
            data-name="crown"
            d="M45.6.315c5.708,1.659,15.644,5.115,15.116,18.8s-6.615,35-6.615,35-14.5,2.444-26.8,1.878A86.83,86.83,0,0,1,4.913,51.857a6.349,6.349,0,0,0-.841-3.2c-4.021-5.465-7.915-20.2-5.429-32.675C.447,6.935,10.02,2.334,13.886,2.8c10.253,1.244,8.245,8.847,14.693,4.977S39.889-1.344,45.6.315Z"
            transform="translate(8.45 150)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root" transform="translate(657.726 612.543)">
          <path
            id="rb2"
            d="M52.149,8.8a74.487,74.487,0,0,1-9.21,43.32c-12.356,21.958-20.7,41.492-22.931,27.8s7.236-32.22,6.123-38.64a61.7,61.7,0,0,0-1.609-6.388l1.609-24.278s6.191-.188,12.7-.641S52.149,8.8,52.149,8.8Z"
            transform="translate(2.04 19.116)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="rb1"
            d="M24.394,34.81c-.621-.782-2.135.865-5.494,7.754-6.118,12.548-3.228,40.494-3.228,40.494,0-.713-2.783,17.68-12.8-5.133s-1.6-49.963-1.6-49.963L3.135,6.453S9.789,9.324,22,10.3c1.27.1,4.14.322,4.14.322S25.353,35.81,24.394,34.81Z"
            transform="translate(2.04 19.116)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r3"
            d="M3905.37,2853.25c.662,1.967,1.334,21.36-4.776,35s-9.413,26.179-10.387,25.061,4.349-22.363,8.284-36.468-.985-22.7.143-23.6S3904.71,2851.28,3905.37,2853.25Z"
            transform="translate(-3863.949 -2814.704)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r2"
            d="M3893.47,2851.15c.282.839-.185,8.439-1.211,14.422s-6.58,17.081-6.946,29.168,1.163,19.517,0,18.8-3.935-24.9,0-39,1.607-21.031,2.372-23.389c.33-1.019,1.354-.207,2.753-.307C3891.55,2850.74,3893.1,2850.03,3893.47,2851.15Z"
            transform="translate(-3871.848 -2812.704)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M3893.47,2851.15c.282.839-.185,8.439-1.211,14.422s-3.507,10.462-3.873,22.55,3.571,26.518,2.407,25.8-9.416-25.28-5.481-39.385,1.607-21.031,2.372-23.389c.33-1.019,1.354-.207,2.753-.307C3891.55,2850.74,3893.1,2850.03,3893.47,2851.15Z"
            transform="translate(-3880.949 -2812.704)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(670.432 540.089)">
            <g id="abscess" transform="translate(-8.154 169.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(36.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g
              id="cracked"
              transform="matrix(-0.766, 0.643, -0.643, -0.766, 30.176, 67.549)"
            >
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession">
              <path
                id="p3"
                d="M1107.3,675.785a82.5,82.5,0,0,0,25.639,5.442c14.584.723,32.7-2.549,32.7-2.549"
                transform="translate(-1118.654 -577.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(722.778 593.784)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
                transform="translate(-63 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(668.284 604.485)">
              <path
                id="path1"
                d="M3394.14,507.638s-1.292-2.966-1.9-11.952,4.771-18.861-.245-21.895-5.76,3-10.347,3.553c-3.594.431-13.093-4.862-15.894-1.785-3.709,4.064-1.029,8.307,0,17.05a59.983,59.983,0,0,1,.193,14.369,50.915,50.915,0,0,0,15.7,1.881C3391.37,508.47,3394.14,507.638,3394.14,507.638Z"
                transform="translate(-3360.682 -470.566)"
                fill="red"
              ></path>
            </g>
            <g id="resection" transform="translate(0 10)">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982l41.923,51.806"
                transform="translate(654.314 591.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="seal" transform="translate(0 -2)">
              <path
                id="seal-2"
                data-name="seal"
                d="M750.875,514.944s-30.363,3.852-30.363,9.139c0,2.8,10.173,5.6,18.278,7.4,6.784,1.508,11.57,2,12.085,4.607,1.1,5.551-25.982,10.2-25.982,10.2"
                transform="translate(-52.44 4.764)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="implant" transform="translate(671.284 621.499)">
              <path
                id="p5"
                d="M806.424,410.878l-1.793-15.719s7.166-8.37,12.64-11.2,3.523-2.77,9.136,0,13.258,10.96,13.258,10.96l-.153,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M809.01,384.517l-2.138-10.682,31.8-8.6-1.81,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3-2"
                data-name="p3"
                d="M810.643,366.894l-1.814-10.675,27.966-7.444L835.27,360.31Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M812.375,348.918l-1.4-10.534,24-5.9-1.92,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-4"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-801.474 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(649.109 466.461)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0H70.833"
                transform="translate(0 150.039)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H70.891"
                transform="translate(-0.058 67)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(697.437 675.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(666.798 621.874)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2-2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
