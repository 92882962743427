import React from "react";
import styled, { css } from "styled-components";
import colors from "../../../../../../library/styled-components/colors";

const DataContainer = styled.div`
  border-radius: 4px;
  font-weight: normal;
  color: ${colors.white};
  background-color: ${(props) => props.color};
  transition: all 0.5s ease;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  padding: 0.5rem;
  display: grid;
  grid-template-columns: 5rem auto;
  ${(props) =>
    props.select &&
    css`
      :hover {
        cursor: pointer;
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.4) !important;
        opacity: 0.9;
      }
    `}
`;

const DataContext = styled.div`
  padding: 1em 1em 1em 0;
`;

const Text = styled.div`
  display: block;
  font-size: 1.6em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
`;

const Number = styled.div`
  font-weight: 300;
  font-size: 2.1em;
`;

const Progress = styled.div`
  background: rgba(0, 0, 0, 0.2);
  margin: 5px -10px 5px 0;
  height: 2px;
  border: 0;
  filter: none;
  box-shadow: none;

  border-radius: 0 !important;
`;

const ProgressBar = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  background: #fff;
  border-radius: 0;
  height: 0.15rem;
`;

const IconWrap = styled.div`
  height: 4rem;
  width: 4rem;
  text-align: center;
  align-self: center;
  font-size: 2rem;
  line-height: 74px;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 100%;
`;

const DataBox = ({ color, data, select }) => {
  if (!data) return null;
  const p = parseInt(data.prevMonth);
  const t = parseInt(data.thisMonth);
  const decrease = p > t;
  const c = decrease ? p - t : t - p;
  const percent = (c / (p === 0 ? 1 : p)) * 100;

  return (
    <DataContainer color={color}> 
      <IconWrap>{data.icon}</IconWrap>
      <DataContext>
        <Text>{data.name || null}</Text>
        <Number>{data.thisMonth || data.total || 0}</Number>
        <Progress>
          <ProgressBar
            style={{ width: `${percent <= 100 ? percent : 100}%` }}
          ></ProgressBar>
        </Progress>
        {data.month && (
          <div>
            {(percent && percent.toFixed()) || 0}% {decrease ? "down" : "up"} on{" "}
            {data.month}
          </div>
        )}
        {data.label && <div>{data.label}</div>}
      </DataContext>
    </DataContainer>
  );
};

export default DataBox;
