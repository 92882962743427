import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="73.689"
      height="246"
      viewBox="0 0 73.689 246"
    >
      <g id="bottom3" transform="translate(-919.5 -467.367)">
        <g id="bounds">
          <line
            id="bound"
            x2="70"
            transform="translate(919.5 467.367)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="70"
            transform="translate(919.5 713.367)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(936.959 475.06) rotate(8)">
          <path
            id="shape"
            d="M176.259,617.745c4.011,12.373.417,26.916-9.274,34.1-5.979,4.431-15.938,3.294-22.669,0-7.558-3.7-12.593-11.946-14.941-19.767-2.143-7.139,5.984-22.944,13.645-21.855C159.116,610.9,171.7,603.677,176.259,617.745Z"
            transform="matrix(0.719, -0.695, 0.695, 0.719, -526.29, -318.236)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
            fill-rule="evenodd"
          ></path>
          <path
            id="o"
            d="M27.873,20.449c-.716-.925-2.278,2.913-4.848,3.025S18.484,19.718,17.6,20.9c-.414.55-.209,4.767-.855,9.962S14.9,41.036,15.009,41.678c.2,1.2,1.681-.415,3.947-.057,1.518.244,3.535,1.277,6.012.883,2.8-.445,4.468-2.071,6.788-3.18,1.434-.684,3.926-.2,3.768-.91a92.861,92.861,0,0,1-5.3-10,33.08,33.08,0,0,1-2.351-7.96Z"
            fill="#eaeaea"
          ></path>
          <path
            id="m"
            d="M131.583,637.827a40.254,40.254,0,0,1-2.207-5.751c-2.143-7.139,5.984-22.944,13.645-21.855a75.9,75.9,0,0,0,10.885-.47c1-.1,3.028-.251,3.028-.251l-1.023,6.787-.155,5.445S133.443,641.687,131.583,637.827Z"
            transform="matrix(0.719, -0.695, 0.695, 0.719, -526.29, -318.236)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="b"
            d="M154.106,654.626s-7.2-1.516-9.789-2.783c-5.269-2.579-9.312-7.368-12.325-13.188h0a10.178,10.178,0,0,0,5.118-2.172c.8-.572.409-.061,1.467-1.023,2.011-1.826,5.367,5.672,7.561,6.964,2.169,1.276,10.625,3.109,10.162,4.766C155.109,651.447,154.106,654.626,154.106,654.626Z"
            transform="matrix(0.719, -0.695, 0.695, 0.719, -526.29, -318.236)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="d"
            d="M177.8,625.772c.764,9.948-3.087,20.348-10.81,26.071-2.584,1.915-3.189,1.875-6.386,2.535a19.463,19.463,0,0,1-6.4.106s.843-2.913,1.566-5.225c.936-3,2.554-10.771,4.582-15.981s3.529-4.858,3.529-4.858,6.294-1.728,9-2.712c.609-.225,4.558-2.425,4.558-2.425S177.766,625.382,177.8,625.772Z"
            transform="matrix(0.719, -0.695, 0.695, 0.719, -526.29, -318.236)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="l"
            d="M176.259,617.745a41.168,41.168,0,0,1,1.239,5.548s-13.839,7.783-18.959,4.332-1.523-18.137-1.523-18.137,2.37-.276,5.762-.339a17.8,17.8,0,0,1,8.208,1.345C173.381,611.612,175.268,614.689,176.259,617.745Z"
            transform="matrix(0.719, -0.695, 0.695, 0.719, -526.29, -318.236)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
        </g>
        <g id="crown">
          <path
            id="crown-2"
            data-name="crown"
            d="M36.625,59.306s6.662-28.562,10.189-34.139S46.031,3.272,34.176,1.206,21.047-.136,18.108,2.342-1.291,10.811.081,21.655,11.74,59.306,11.74,59.306a32.463,32.463,0,0,0,11.032,1.947A67.333,67.333,0,0,0,36.625,59.306Z"
            transform="translate(928.469 551.741)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root">
          <path
            id="rb1"
            d="M25.026,6.469,21.669,59.506S16.72,94.577,8.976,91.717,5.953,82.279,3.323,58.341.081,6.617.081,6.617a45.08,45.08,0,0,0,11.26,1.6A62.869,62.869,0,0,0,25.026,6.469Z"
            transform="translate(940.142 604.743)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M7.87,18.747c.048,8.056,2.175,22.192,2.466,30.96C10.679,60.046,9,71.99,7.525,71S6.7,58.284,4.425,45.758.37,32.827-.929,19.827-3.344,7.208-2.061,4.486s3.334-1.176,5.312-1.6C6.268,2.062,7.762.644,7.87,18.747Z"
            transform="translate(951.77 616.814) rotate(7)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(942.173 512.089)">
            <g id="abscess" transform="translate(-12.154 179.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(38.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g
              id="cracked"
              transform="translate(17.786 60.308) rotate(-163.976)"
            >
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(-12 97.133)">
              <path
                id="p3"
                d="M1111.65,686.936a42.443,42.443,0,0,0,22.73,7.045c13.017,0,25.856-7.92,25.856-7.92"
                transform="translate(-1112.654 -688.06)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(923.519 564.675)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(0.039)">
              <path
                id="rctpc-2"
                data-name="rctpc"
                d="M13.54,8.125,5.828,44.241,2.661,44.3l-1.423.251L-2.447,8.4C-2.447,6.8,13.54,6.523,13.54,8.125Z"
                transform="translate(952.332 568.319) rotate(0.974)"
                fill="red"
              ></path>
            </g>
            <g id="resection">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982,33.023,107"
                transform="translate(934.055 586.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="implant" transform="translate(939.9 597.499)">
              <path
                id="p5"
                d="M805.937,410.878l-1.307-15.719s5.222-8.37,9.211-11.2,2.567-2.77,6.657,0,9.661,10.96,9.661,10.96l-.112,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M808.371,384.517l-1.5-10.682,22.3-8.6L827.9,376.743Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3-2"
                data-name="p3"
                d="M810.027,366.894l-1.2-10.675,18.462-7.444-1.007,11.536Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M811.822,348.918l-.843-10.534,14.494-5.9-1.159,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-4"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-806.322 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(923 479.784)">
              <path
                id="Path_3"
                data-name="Path 3"
                d="M0,0H71"
                transform="translate(-3.5 22.716)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H71"
                transform="translate(-3.5 105.716)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(967.405 645.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(930.766 592.874)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3-2"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
