import { handleResponse, authHeader } from "../../library/handler";
import store from "store";

export async function registerAdmin(data) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/register/practice`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function checkRegistration(data) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/register/check`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function registerCustomer(data) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/register/customer`, {
    method: "post",
    body: JSON.stringify(data),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function registerPracticeCustomer(state, user) {
  return fetch(
    `${process.env.REACT_APP_APP_API}/public/register/practice-customer`,
    {
      method: "post",
      body: JSON.stringify(user),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function loginCustomer(user) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/auth/user`, {
    method: "post",
    body: JSON.stringify({ user, role: "CUSTOMER" }),
    headers: {
      "Content-Type": "application/json; charset=utf-8",
    },
  })
    .then((response) => {
      return handleResponse(response);
    })
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function loginAdmin(user) {
  const response = await fetch(
    `${process.env.REACT_APP_APP_API}/public/auth/user`,
    {
      method: "post",
      body: JSON.stringify({ user, role: "ADMIN" }),
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  )
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
  return response;
}

export async function validatePin(state, code) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/validate/pin`, {
    method: "post",
    body: JSON.stringify({ code }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function resetPassword(state, email) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/reset/password`, {
    method: "post",
    headers: await authHeader(state),
    body: JSON.stringify({ email, state }),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}

export async function updatePassword(state, password) {
  return fetch(`${process.env.REACT_APP_APP_API}/public/update/password`, {
    method: "post",
    body: JSON.stringify({ password, state }),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
}
