import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import ConsultationProgress from "./progress";
import Invoices from "./finance";
import Notes from "./notes";
import Documents from "./documentsTab";
import colors from "library/styled-components/colors";
import { Close } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const OverviewTabs = ({
  user,
  patient,
  setSelectedAppointment,
  setComplete,
  showOnlyInvoice,
  setShowPreview,
  setShowRecallPreview,
  createNote,
  setLoading,
  close,
}) => {
  const [page, setPage] = useState(0);

  return <>
    <Box
      style={{
        display: "flex",
        width: "100%",
        background: colors.lightBlue,
        justifyContent: "space-between",
      }}
    >
      <Tabs value={page} onChange={(_, val) => setPage(val)}>
        <Tab label="Appointments" />
        <Tab label="Invoices" />
        <Tab label="Notes" />
        <Tab label="Files" />
      </Tabs>

      <IconButton aria-label="close" onClick={close} size="large">
        <Close style={{ color: colors.dark }} />
      </IconButton>
    </Box>
    {page === 0 && (
      <ConsultationProgress
        user={user}
        patient={patient}
        setAppointment={setSelectedAppointment}
        setComplete={setComplete}
        setShowPreview={setShowPreview}
        setShowRecallPreview={setShowRecallPreview}
        setLoading={setLoading}
        showInvoices={() => setPage(1)}
      />
    )}
    {page === 1 && <Invoices showOnlyInvoice={showOnlyInvoice} />}
    {page === 2 && <Notes notes={patient.Notes} createNote={createNote} />}
    {page === 3 && <Documents />}
  </>;
};

export default OverviewTabs;
