import localforage from "localforage";

function setSchedule(practitioners, Schedule) {
  return practitioners?.map((p) => {
    if (p.PractitionerId === Schedule.PractitionerId) {
      const Schedules = p?.Schedules?.map((s) => {
        if (s.ScheduleId === Schedule?.ScheduleId) {
          return Schedule;
        }
        return s;
      });
      return { ...p, Schedules };
    }
    return p;
  });
}

function addSchedule(practitioners, Schedule) {
  return practitioners?.map((p) => {
    if (p.PractitionerId === Schedule.PractitionerId) {
      const Schedules = [
        ...p?.Schedules?.filter((p) => p.ScheduleId !== Schedule?.ScheduleId),
        Schedule,
      ];
      return { ...p, Schedules };
    }
    return p;
  });
}

function removeSchedule(practitioners, Schedule) {
  return practitioners?.map((p) => {
    if (p.PractitionerId === Schedule.PractitionerId) {
      const Schedules = p?.Schedules?.filter(
        (p) => p.ScheduleId !== Schedule?.ScheduleId
      );
      return { ...p, Schedules };
    }
    return p;
  });
}

const schedulesUpdate = (eventData) => async (dispatch) => {
  const { Schedule } = eventData;
  const practitioners = await localforage.getItem("list");
  let data = setSchedule(practitioners, Schedule);
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data);
};

const schedulesCreate = (eventData) => async (dispatch) => {
  const { Schedule } = eventData;
  const practitioners = await localforage.getItem("list");
  const data = addSchedule(practitioners, Schedule);
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data);
};

const schedulesDelete = (eventData) => async (dispatch) => {
  const { Schedule } = eventData;
  const practitioners = await localforage.getItem("list");
  const data = removeSchedule(practitioners, Schedule);
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data);
};

export default {
  create: schedulesCreate,
  update: schedulesUpdate,
  delete: schedulesDelete,
};
