import React, { useReducer, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import LoginLayout from "../../layouts/login";
import RegisterInHouseBlock from "../../components/blocks/register-inhouse-block";
import RecoveryImg from "../../img/recovery.png";
import loginPng from "../../img/login.png";
import { registerUser, fetchData } from "./actions";
import TermsModal from "../../components/terms";

const Container = styled.div`
  @media (min-width: 481px) {
    width: 481px;
  }
  @media (max-width: 481px) {
    padding: 10px;
  }
`;

const Header = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  margin: 1em 1em 0em 1em;
`;

const ColHeader = styled.div`
  display: grid;
  grid-template-columns: auto;
  margin: 2em;
  align-items: center;
  justify-items: center;
`;

export default function RegisterAdmin({ match }) {
  const [updating, setUpdating] = useState(false);
  const [terms, showTerms] = useState(false);
  const [error, setError] = useState(null);
  const [warning, setWarning] = useState(null);
  const [practice, setPractice] = useState(null);
  const [practitioners, setPractitioners] = useState(null);
  const [slideState, setSlideState] = useState({
    current: match.params.step || "register",
    in: match.params.step,
    out: null,
  });

  const history = useHistory();

  async function loadData() {
    const response = await fetchData(match.params.uuid);
    if (response) {
      setPractice(response.practice);
      setPractitioners(response.practitioners.practitioners);
    }
  }

  useEffect(() => {
    loadData();
    setError(null);
    setWarning(null);
  }, [match]);

  async function navigateTo(path, step) {
    const out = match.params.step;
    history.push({
      pathname: path,
      state: { out },
    });
    setSlideState({
      current: step,
      out,
      in: step,
    });
    window.scrollTo(0, 0);
  }

  history.listen((location) => {
    const { pathname, state } = location;
    const step = pathname.split("/").pop();
    setSlideState({
      current: step,
      out: state && state.out,
      in: step,
    });
  });

  return (
    <LoginLayout menu={true} slideState={slideState}>
      {slideState.current === "register" && (
        <Container>
          <Header>
            <div>
              <h3>Welcome to {practice && practice.PracticeName} </h3>
              <p>
                Please Register with us today and in future you will be able to
                book your appointments online!
              </p>
            </div>
            <img src={loginPng} height="128" alt="" />
          </Header>
          <RegisterInHouseBlock
            registerUser={async (data) => {
              setUpdating(true);

              const response = await registerUser({
                data,
                PracticeId: practice.PracticeId,
              });

              if (response.code == "REGISTERED") {
                navigateTo(`/register/${practice.PracticeId}`, "complete");
              }

              if (response.code == "ALREADY_REGISTERED") {
                setWarning("User email already registered!");
              }

              if (response.code == "INVALID_PHONE") {
                setWarning("Phone number is invalid or unsupported!");
              }

              if (response.code == "INVALID") {
                setError("Something when wrong!");
              }

              setUpdating(false);
            }}
            meta={{
              updating,
              warning,
              error,
              disabled: !updating,
            }}
            practitioners={practitioners}
            goToLogin={() => navigateTo("/admin/access/login", "login")}
            fade={{
              current: "register",
              out: slideState.out === "register",
              in: slideState.in === "register",
            }}
            showTerms={() => showTerms(true)}
          />
        </Container>
      )}

      {slideState.current === "complete" && (
        <Container>
          <ColHeader>
            <img src={RecoveryImg} height="128" alt="" />
            <div>
              <h1>Thank you for Registering!</h1>
              <p>
                You will recieve a notification with information on how to
                access your Dentiz account. Please take a seat and we will be
                with you shortly
              </p>
            </div>
          </ColHeader>
        </Container>
      )}
      <TermsModal terms={terms} closeTerms={() => showTerms(false)} />
    </LoginLayout>
  );
}
