import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width={74}
    height={246}
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.17"
      height="246"
      viewBox="0 0 70.17 246"
    >
      <g id="top3" transform="translate(-795.5 -84.089)">
        <g id="bounds" transform="translate(795.5 330.089)">
          <line id="bound" x2="70" fill="none"></line>
        </g>
        <g id="surface" transform="translate(803.906 266.839)">
          <path
            id="shape"
            d="M.967,6.811c-2.883,10.44.809,23.049,9.378,29.605,5.288,4.046,13.725,3.484,19.319.941,6.281-2.856,10.215-9.715,11.877-16.319,1.517-6.029-2.4-20.481-8.887-19.865C18.918,2.476,4.244-5.057.967,6.811Z"
            transform="translate(24.029 56.874) rotate(-125.025)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
            fill-rule="evenodd"
          ></path>
          <path
            id="l"
            d="M.99,6.833A22.376,22.376,0,0,0,.427,17.766c.329,2.026,16.346,7.458,21.33.392C26.276,11.752,16.873.343,14.987.179,8.443-.394,2.86.06.99,6.833Z"
            transform="translate(24.023 56.906) rotate(-125.025)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="m"
            d="M26.582,17.9c.2-6.24-3.529-17.4-9.242-16.862C11.682,1.577,0,0,0,0A12.065,12.065,0,0,1,2.4,2.284,33.94,33.94,0,0,1,6.5,8.723c1.392,2.828-.749,2.651,4.271,4.946S26.561,18.757,26.582,17.9Z"
            transform="translate(15.348 44.26) rotate(-125.025)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="d"
            d="M0,0S.044.351.112.826A27.359,27.359,0,0,0,10.1,18.444c5.288,4.046,13.725,3.484,19.319.941C31.283,18.41,18.335,6.051,16.368,4.461s-3.64-.4-7.981-1.145A26.268,26.268,0,0,1,0,0Z"
            transform="translate(38.602 46.354) rotate(-125.025)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="b"
            d="M5.5,20.36a2.061,2.061,0,0,1,.514-.331C11.9,17.261,15.449,10.738,17.095,4.2a24.917,24.917,0,0,0,.328-2.727L10.323,0S9.89,5.822,7.268,9.092,0,13.049,0,13.049a33.117,33.117,0,0,1,3.8,4.172A12.825,12.825,0,0,1,5.5,20.36Z"
            transform="translate(23.787 27.191) rotate(-125.025)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="o"
            d="M13.806,17.058A48.366,48.366,0,0,1,15.9,23.817c.653,3.147.928,5.714.928,5.714s2.922-2.792,5.917-2.7,5.947,3.162,5.947,3.162l1.769-12.154s-3.923-3.575-8.093-3.684S13.806,17.058,13.806,17.058Z"
            transform="translate(4.031 1.784)"
            fill="#eaeaea"
          ></path>
        </g>
        <g id="crown" transform="translate(807.825 196.033)">
          <path
            id="crown-2"
            data-name="crown"
            d="M34.051.152s13.989,23.034,10.734,31.5S26.965,49.965,22.626,50.427-.154,41.962,0,32.882,5.58,15.8,5.58,10.874C5.58,8.774,3.526,1.88,9.5.152,17.47-2.152,34.051.152,34.051.152Z"
            transform="translate(0.001)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root" transform="translate(812.733 100.173)">
          <path
            id="rb1"
            d="M1147.78,156.18s-9.409-38.864-6.822-62.957,7.381-26.326-4.174-32.6-18.893,59.836-18.206,81.619c.243,7.668.723,17.607.723,17.607a6.6,6.6,0,0,1,3.657-3.672c1.845-.729,2.963-.9,12.773-.89S1147.78,156.18,1147.78,156.18Z"
            transform="translate(-1118.534 -60.208)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M1128.55,131.885c3.283.617-3.222-29.026-2.336-47.02s2.527-19.662-1.429-24.348-6.469,44.689-6.234,60.958c.083,5.727-1.151,10.734,1.091,10.035S1125.27,131.268,1128.55,131.885Z"
            transform="translate(-1110.302 -43.852)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(808.271 84.089)">
            <g id="abscess" transform="translate(11.229)">
              <g id="p1" fill="none" stroke="#000" stroke-width="4">
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(44.896 145.504)">
              <path
                id="p2"
                d="M1178.17,630.863V578.089"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,630.863V578.089"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g
              id="cracked"
              transform="translate(18.666 139.708) rotate(19.993)"
            >
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(0 109.009)">
              <path
                id="p3"
                d="M1113.27,696.962s8.312-5.462,17.942-6.39,20.579,2.677,20.579,2.677"
                transform="translate(-1113.271 -690.419)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures" transform="translate(795.5 102.254)">
            <g id="hyg" transform="translate(7.5 96.421)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(26.664 93.654)">
              <path
                id="rctpc-2"
                data-name="rctpc"
                d="M587.719,20.59s3.009,24.993,2.541,25.73-9.349.893-9.416,0,1.349-25.73,1.349-25.73h2.82a15.239,15.239,0,0,1,2.707,0Z"
                transform="translate(-580.841 -20.525)"
                fill="red"
              ></path>
            </g>
            <g id="resection" transform="translate(9.451 5.454)">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,118.788,45.338,66.982"
                transform="translate(-3.415 -66.982)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="implant" transform="translate(17.495 10.926)">
              <path
                id="p5"
                d="M806.184,381.864l-1.553,15.719s6.208,8.37,10.95,11.2,3.052,2.77,7.914,0,11.485-10.96,11.485-10.96l-.133-6.876Z"
                transform="translate(-804.63 -312.669)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M808.7,365.23l-1.823,10.682,27.12,8.6L832.449,373Z"
                transform="translate(-804.785 -311.524)"
                fill="red"
              ></path>
              <path
                id="p3-2"
                data-name="p3"
                d="M810.34,348.775l-1.51,10.675,23.281,7.444-1.27-11.536Z"
                transform="translate(-804.919 -310.392)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M812.1,332.484l-1.124,10.534,19.313,5.9-1.545-11.1Z"
                transform="translate(-805.067 -309.271)"
                fill="red"
              ></path>
              <path
                id="p1-4"
                data-name="p1"
                d="M828.272,331.155l-15.521-4.7s.492-19.875,8.041-18.857S828.272,331.155,828.272,331.155Z"
                transform="translate(-805.189 -307.555)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(0 117.968)">
              <path
                id="Path_3"
                data-name="Path 3"
                d="M0,0H70"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H70"
                transform="translate(0 80)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(22.333 53.98)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(12.747 -8.658)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3-2"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
