import {
  IconButton,
  ListItemText,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import IndeterminateCheckBoxRounded from "@mui/icons-material/IndeterminateCheckBoxRounded";
import AddBoxRounded from "@mui/icons-material/AddBoxRounded";
import React from "react";
import CurrencyTextField from "./CurrencyField";
import colors from "library/styled-components/colors";

const RenderChargeItem = ({ proc, form, setForm, practitioners }) => {
  return (
    <TableRow button="true" key={proc.id} disabled={proc.disabled}>
      <TableCell align="right" padding="checkbox">
        <IconButton
          style={{ padding: "2px" }}
          disabled={false}
          onClick={(e) => {
            setForm({
              ...form,
              proceedures: form.proceedures.filter((p) => proc.id !== p.id),
            });
          }}
          aria-label="up"
          size="large">
          <HighlightOffIcon size="small" />
        </IconButton>
      </TableCell>
      <TableCell>
        {proc?.Treatment?.Code || proc?.Method} - {proc?.Treatment?.Name}
      </TableCell>
      <TableCell align="right">
        <Select
          variant="outlined"
          onChange={(e) =>
            setForm({
              ...form,
              proceedures: form.proceedures.map((p) => {
                if (p.id === proc.id) {
                  return {
                    ...p,
                    PractitionerId: e.target.value,
                  };
                }
                return p;
              }),
            })
          }
          label={"Choose Practitioner"}
          defaultValue="Choose Practitioner"
          value={proc?.PractitionerId}
        >
          {practitioners?.data.map((p) => (
            <MenuItem key={p.data.PractitionerId} value={p.data.PractitionerId}>
              {p.data.Name}
            </MenuItem>
          ))}
        </Select>
      </TableCell>
      <TableCell align="right">
        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div
            style={{
              textAlign: "center",
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <ListItemText
              secondary={`QTY: ${
                form.proceedures.find((c) => c.id === proc.id)?.Data
                  ?.quantity || 1
              }`}
            />
            <div>
              <IconButton
                style={{ padding: "2px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  const update = form.proceedures.map((c) => {
                    if (c.id === proc.id) {
                      const quantity = (c.Data?.quantity || 1) - 1;
                      if (quantity === 0) return c;
                      const price = proc.Price / (proc.Data?.quantity || 1);
                      return {
                        ...c,
                        Price: parseFloat(proc.Price) - parseFloat(price),
                        Data: {
                          ...c.Data,
                          quantity,
                        },
                      };
                    }
                    return c;
                  });
                  setForm({
                    ...form,
                    proceedures: update,
                  });
                }}
                aria-label="down"
                size="large">
                <IndeterminateCheckBoxRounded />
              </IconButton>
              <IconButton
                style={{ padding: "2px" }}
                onClick={(e) => {
                  e.stopPropagation();
                  const update = form.proceedures.map((c) => {
                    if (c.id === proc.id) {
                      const quantity = (c.Data?.quantity || 1) + 1;
                      const price = proc.Price / (proc.Data?.quantity || 1);
                      return {
                        ...c,
                        Price: parseFloat(proc.Price) + parseFloat(price),
                        Data: {
                          ...c.Data,
                          quantity,
                        },
                      };
                    }
                    return c;
                  });
                  setForm({
                    ...form,
                    proceedures: update,
                  });
                }}
                aria-label="up"
                size="large">
                <AddBoxRounded />
              </IconButton>
            </div>
          </div>
        </div>
      </TableCell>
      <TableCell align="right">
        <CurrencyTextField
          label="Amount"
          variant="standard"
          style={{ width: "100%", color: colors.red }}
          value={
            form.proceedures.find((c) => c.id === proc.id)?.UnParsedPrice ||
            form.proceedures.find((c) => c.id === proc.id)?.Price
          }
          maximumValue={
            form.proceedures.find((c) => c.id === proc.id)?.Price < 0
              ? "0"
              : "1000000000000000"
          }
          //defaultValue={form.proceedures.find((c) => c.id === proc.id)?.Price}
          currencySymbol="€"
          outputFormat="string"
          decimalCharacter="."
          digitGroupSeparator=","
          //onFocus={(event) => {
          //event.target.select();
          //}}
          selectOnFocus={false}
          onChange={(e, value) => {
            setForm({
              ...form,
              proceedures: form.proceedures.map((c) => {
                if (c.id === proc.id) {
                  return {
                    ...c,
                    UnParsedPrice: value,
                  };
                }
                return c;
              }),
            });
          }}
          onBlur={(e, value) => {
            setForm({
              ...form,
              proceedures: form.proceedures.map((c) => {
                if (c.id === proc.id) {
                  return {
                    ...c,
                    Price: parseFloat(value) || 0,
                    UnParsedPrice: null,
                  };
                }
                return c;
              }),
            });
          }}
        />
      </TableCell>
    </TableRow>
  );
};

export default RenderChargeItem;
