import appointments from "./appointments";
import patients from "./patients";
import practitioners from "./practitioners";
import schedules from "./schedules";
import treatments from "./treatments";
import invoices from "./invoices";
import notes from "./notes";
import files from "./files";
import practice from "./practice";
import meta from "./meta";
import sms from "./sms";
import batchQueues from "./batch-queues";
import notifications from "./notifications";

export default {
  appointments,
  patients,
  practitioners,
  schedules,
  treatments,
  invoices,
  notes,
  files,
  practice,
  meta,
  sms,
  batchQueues,
  notifications,
};
