import React from "react";
import { BoxWrap, ClickBox } from "../../../common";
import DepthBlock from "./DepthBlock";
import MarginBlock from "./MarginBlock";

const TeethBlockTwo = ({
  updateSettings,
  updateDepth,
  updateMargin,
  depth,
  margin,
  imgs,
}) => {
  return (
    <table style={{ width: "400px", textAlign: "center" }}>
      <tbody>
        <tr>
          {imgs.map((_, i) => (
            <td>
              <MarginBlock
                offset={63}
                margins={margin?.slice(i * 3, (i + 1) * 3)}
                updateMargin={(seg) => {
                  updateMargin(margin.map((m) => (m[0] === seg[0] ? seg : m)));
                }}
              />
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((_, i) => (
            <td>
              <DepthBlock
                top={63}
                depths={depth?.slice(i * 3, (i + 1) * 3)}
                margins={margin?.slice(i * 3, (i + 1) * 3)}
                updateDepth={(seg) => {
                  updateDepth(depth.map((m) => (m[0] === seg[0] ? seg : m)));
                }}
              />
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((img, i) => (
            <td style={{ width: img.width }}>
              <BoxWrap>
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[0]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [!_.bleed[0], _.bleed[1], _.bleed[2]]
                                : [1, 0, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[1]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [_.bleed[0], !_.bleed[1], _.bleed[2]]
                                : [0, 1, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="danger"
                  active={img?.bleed?.[2]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              bleed: _.bleed
                                ? [_.bleed[0], _.bleed[1], !_.bleed[2]]
                                : [0, 0, 1],
                            }
                          : _
                      )
                    )
                  }
                />
              </BoxWrap>
            </td>
          ))}
        </tr>
        <tr>
          {imgs.map((img, i) => (
            <td style={{ width: img.width }}>
              <BoxWrap>
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[0]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [!_.plaque[0], _.plaque[1], _.plaque[2]]
                                : [1, 0, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[1]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [_.plaque[0], !_.plaque[1], _.plaque[2]]
                                : [0, 1, 0],
                            }
                          : _
                      )
                    )
                  }
                />
                <ClickBox
                  variant="primary"
                  active={img?.plaque?.[2]}
                  disabled={img.removed}
                  onClick={() =>
                    updateSettings(
                      imgs.map((_, is) =>
                        is === i
                          ? {
                              ..._,
                              plaque: _.plaque
                                ? [_.plaque[0], _.plaque[1], !_.plaque[2]]
                                : [0, 0, 1],
                            }
                          : _
                      )
                    )
                  }
                />
              </BoxWrap>
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default TeethBlockTwo;
