import localforage from "localforage";

const treatmentsUpdate = (eventData) => async (dispatch) => {
  const { Treatment } = eventData;
  const treatments = await localforage.getItem("treatments");
  let data = treatments.map((p) => {
    if (p.TreatmentId === Treatment.TreatmentId) {
       return Treatment;
    }
    return p;
  }); 
  dispatch({
    type: "LOAD",
    context: "treatments",
    data,
  });
  await localforage.setItem("treatments", data); 
};

const treatmentsCreate = (eventData) => async (dispatch) => {
  const { Treatment } = eventData;
  const treatments = await localforage.getItem("treatments");
  const data = [
    ...treatments.filter(
      (p) => p.TreatmentId !== Treatment.TreatmentId
    ),
    Treatment,
  ];
  dispatch({
    type: "LOAD",
    context: "treatments",
    data,
  });
  await localforage.setItem("treatments", data); 
};


const treatmentsDelete = (eventData) => async (dispatch) => {
  const { Treatment } = eventData;
  const treatments = await localforage.getItem("treatments");
  const data =  treatments.filter(
      (p) => p.TreatmentId !== Treatment.TreatmentId
    ) ;
  dispatch({
    type: "LOAD",
    context: "treatments",
    data,
  });
  await localforage.setItem("treatments", data); 
};

export default {
  create: treatmentsCreate,
  update: treatmentsUpdate,
  delete: treatmentsDelete,
}