import React, { useEffect, useState } from "react";
import { getFile } from "../../../actions/patients";
import { ADMIN_NS } from "../../../config";
import colors from "../../../library/styled-components/colors";
import DoubleBounce from "../../loaders/double-bounce";
import PreviewFileViewer from "./PreviewFileViewer";

const PreviewFileLoader = ({ data }) => {
  const [img, setImg] = useState(null);
  const loadFile = async (fileReq) => {
    setImg(null);
    const fileData = await getFile(ADMIN_NS, fileReq);
    const url = URL.createObjectURL(
      new Blob([fileData], { type: data?.Data.mimetype })
    );
    setImg(url);
  };
 
  console.log(data)

  useEffect(() => {
    data &&
      loadFile({
        filename: data.Name,
        PracticeId: data.PracticeId,
        UserId: data.UserId,
      });
  }, [data]);

  return (
    <div style={{ marginLeft: "1rem" }}>
      {img ? (
        <PreviewFileViewer
          data={{
            src: img,
            type: data?.Data.mimetype?.split("/")[1],
          }}
        ></PreviewFileViewer>
      ) : (
        <DoubleBounce color={colors.primColor} margin="50%" />
      )}
    </div>
  );
};

export default PreviewFileLoader;
