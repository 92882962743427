import moment from 'moment'

const initialState = {
  list: [],
}

const formatDate = date => new Date(moment(date))

const appointments = (state = initialState, action) => {
  switch (action.type) {
    case 'LOAD_APPOINTMENT_LIST':
      return {
        ...state,
        list: action.payload.filter(e => e.Start)
          .map(e => ({
            allDay: false,
            id: e.id,
            start: formatDate(e.Start),
            end: formatDate(e.End),
            title: e.PatientId,
          })),
      }
    case 'LOAD_APPOINTMENT_SINGLE':
      return {
        ...state,
        list: [...state.list, {
          allDay: false,
          id: action.payload.id,
          start: formatDate(action.payload.Start),
          end: formatDate(action.payload.End),
          title: action.payload.PatientId,
        }],
      }
    default:
      return state
  }
}

export default appointments
