import React from "react";
import { FormControlLabel, Checkbox } from "@mui/material";
import { Link } from "react-router-dom";

const CheckBox = ({ DomRef, InputData, LabelData, onChange, openTerms }) => {
  const label = (
    <div  >
      {LabelData.text}{" "}
      <Link
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          openTerms(true);
        }}
      >
        {LabelData.link}
      </Link>
    </div>
  );

  return (
    <>
      <FormControlLabel
        onClick={() => onChange(!InputData.value ? "checked" : false)}
        control={
          <Checkbox
            style={{ alignSelf: "baseline" }}
            checked={InputData.value ? "checked" : false}
            onChange={() => {
              onChange(!InputData.value ? "checked" : false);
            }}
            name={LabelData.text}
            color="primary"
          />
        }
        label={label}
      />
    </>
  );
};

export default CheckBox;
