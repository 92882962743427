import socketIOClient from "socket.io-client";
import localforage from "localforage";

const opts = {
  extraHeaders: {
    "X-Custom-Header-For-My-Project": "my-secret-access-token",
    Cookie:
      "user_session=NI2JlCKF90aE0sJZD9ZzujtdsUqNYSBYxzlTsvdSUe35ZzdtVRGqYFr0kdGxbfc5gUOkR9RGp20GVKza; path=/; expires=Tue, 07-Apr-2015 18:18:08 GMT; secure; HttpOnly",
  },
  reconnection: true, // whether to reconnect automatically
  //reconnectionAttempts: 5, // number of reconnection attempts before giving up
  reconnectionDelay: 1000, // how long to initially wait before attempting a new reconnection
  reconnectionDelayMax: 5000, // maximum amount of time to wait between reconnection attempts
  randomizationFactor: 0.5, // randomness added to the reconnection delay. Can be between 0-1
};

export const initSockets = ({ setSocket, events, practiceId, token }) => {
  const socket = socketIOClient(
    `${process.env.REACT_APP_APP_WS}&uuid=${practiceId}&token=${token}`,
    opts
  );
  setSocket(socket);

  // 1. Simple events
  const simpleEventsMapping = {
    "recieve-register": events.registeredPatient,
    "recieve-appointment": events.recieveAppointment,
    "patient-arrived": events.patientArrived,
    "patients-delete": events.patientRemoved,
    "patient-in-treatment": events.patientInTreatment,
  };

  for (const [eventName, handler] of Object.entries(simpleEventsMapping)) {
    socket?.on(eventName, handler);
  }

  // 2. Events with asynchronous delay
  const asyncEventsMapping = {
    "recieve-sms": (data) => {
      events.stateHandler.appointments.addSms(data)(events.dispatch);
      events.stateHandler.sms.addSms(data)(events.dispatch);
    },
    "update-sms": (data) => {
      events.stateHandler.appointments.updateSms(data)(events.dispatch);
      events.stateHandler.sms.updateSms(data)(events.dispatch);
    },
  };

  for (const [eventName, handler] of Object.entries(asyncEventsMapping)) {
    socket?.on(eventName, async (data) => {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      handler(data);
    });
  }

  // 3. Events that access the `events.stateHandler`
  const stateHandlerMapping = {
    "practice-update": events.stateHandler.practice.update,
    "appointment-update": events.stateHandler.appointments.update,
    "appointment-create": events.stateHandler.appointments.create,
    "appointment-event-update": events.stateHandler.appointments.updateEvent,
    "appointment-event-create": events.stateHandler.appointments.createEvent,
    "appointment-event-delete": events.stateHandler.appointments.deleteEvent,
    "patients-update": events.stateHandler.patients.update,
    "patients-delete": events.stateHandler.patients.delete,
    "patients-create": events.stateHandler.patients.create,
    "practitioners-update": events.stateHandler.practitioners.update,
    "practitioners-create": events.stateHandler.practitioners.create,
    "practitioners-delete": events.stateHandler.practitioners.delete,
    "schedules-update": events.stateHandler.schedules.update,
    "schedules-create": events.stateHandler.schedules.create,
    "schedules-delete": events.stateHandler.schedules.delete,
    "treatments-create": events.stateHandler.treatments.create,
    "treatments-update": events.stateHandler.treatments.update,
    "treatments-delete": events.stateHandler.treatments.delete,
    "invoices-create": events.stateHandler.invoices.create,
    "invoices-update": events.stateHandler.invoices.update,
    "invoices-delete": events.stateHandler.invoices.delete,
    "files-create": events.stateHandler.files.create,
    "files-delete": events.stateHandler.files.delete,
    "notes-create": events.stateHandler.notes.create,
    "notes-update": events.stateHandler.notes.update,
    "notes-delete": events.stateHandler.notes.delete,
    "sms-batchqeues-update": events.stateHandler.batchQueues.update,
    "sms-batchqeues-create": events.stateHandler.batchQueues.create,
    "sms-batchqeues-delete": events.stateHandler.batchQueues.delete,
    "notification-create": events.stateHandler.notifications.create,
  };

  let eventQueue = [];

  const withLock = (fn) => {
    let lock = Promise.resolve();
    return async (...args) => {
      await lock;
      lock = (async () => {
        await fn(...args);
      })();
      await lock;
    };
  };

  async function insertEventInOrder(event) {
    let inserted = false;
    for (let i = 0; i < eventQueue.length; i++) {
      if (new Date(event.Time) < new Date(eventQueue[i].Time)) {
        eventQueue.splice(i, 0, event);
        inserted = true;
        break;
      }
    }
    if (!inserted) {
      eventQueue.push(event);
    }
  }

  async function processEventQueue() {
    while (eventQueue.length) {
      const event = eventQueue.shift();
      await event.exec(); // Execute the associated function
    }
  }

  for (const [eventName, handler] of Object.entries(stateHandlerMapping)) {
    socket?.on(
      eventName,
      withLock(async (data) => {
        if (!data?.EventQueueId) return;

        const eventObj = {
          payload: data,
          exec: async () => {
            // Given the handler structure you provided
            const meta = await localforage.getItem("meta");
            await localforage.setItem("meta", {
              ...meta,
              lastConnectUuid: data?.EventQueueId,
            });
            handler(data)(events.dispatch);
          },
        };
        if (data?.BulkIndex) {
          await insertEventInOrder(eventObj);
          if (eventQueue.length === data?.BulkCount) {
            processEventQueue();
          }
        } else {
          await insertEventInOrder(eventObj);
          processEventQueue();
        }
      })
    );
  }

  // Reconnection attempts
  socket?.on("reconnect_attempt", async () => {
    console.log("Attempting to reconnect, UID:");
    // Handle reconnection attempt
  });

  socket.on("connect_error", () => {
    console.log("Connection failed");
  });

  socket.on("reconnecting", (attemptNumber) => {
    console.log("Trying to reconnect, attempt number:", attemptNumber);
  });

  socket.on("reconnect_error", () => {
    console.log("Reconnection failed");
  });

  // Successful reconnection
  socket?.on("reconnect", async () => {
    console.log("Reconnected to the server:", socket.id);
    events.stateHandler.meta.update({ disconnected: false })(events.dispatch);
    // reload page
    //(localforage.clear();
    //window.location.reload();
  });

  // Handle the 'connect' event
  socket.on("connect", async () => {
    console.log("Connected to the server:", socket.id);
    const meta = await localforage.getItem("meta");
    // Handle successful reconnection
    var lastConnectUuid = meta?.lastConnectUuid || null;
    // wait 1 second before sending lastConnectUuid
    console.log("Last connect uuid: ", lastConnectUuid);
    await new Promise((resolve) => setTimeout(resolve, 1000));
    socket.emit("lastConnectUuid", lastConnectUuid);
    console.log("Last connect uuid sent: ", lastConnectUuid);
    // event
  });

  // Handle the 'disconnect' event
  socket.on("disconnect", async (reason) => {
    console.log("Disconnected from the server:", reason);
    const meta = await localforage.getItem("meta");
    events.stateHandler.meta.update({ disconnected: true })(events.dispatch);
  });

  // Handle the 'error' event
  socket.on("error", (error) => {
    console.error("An error occurred:", error);
    window.location.href = "#/admin/logout?reason=error";
  });
};

export async function verifyPermission(handle, readWrite) {
  const options = {};
  if (readWrite) {
    options.mode = "readwrite";
  }
  // Check if permission was already granted. If so, return true.
  if ((await handle?.queryPermission(options)) === "granted") {
    return true;
  }

  // Request permission. If the user grants permission, return true.
  if ((await handle?.requestPermission(options)) === "granted") {
    return true;
  }
  // The user didn't grant permission, so return false.
  return false;
}
