import React from "react";
import styled from "styled-components";
import CheckButton from "../check-button";
import colors from "../../library/styled-components/colors";

const Container = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: 2.2em;
`;

const CheckGroupContiner = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #bdc4c9;
  border-radius: 4px;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const ErrorMessage = styled.label`
  color: ${colors.danger};
  line-height: 0.8rem;
  font-size: 0.8rem;
  margin: 1rem 0 0.2rem 0;
  font-weight: 400;
  text-align: right;
`;

const CheckButtonGroup = ({ onChange, val, LabelData, group }) => {
  return (
    <Container>
      <LabelContainer>
        <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
        {LabelData.error && (
          <ErrorMessage htmlFor={LabelData.text}>
            {LabelData.error}
          </ErrorMessage>
        )}
      </LabelContainer>
      <CheckGroupContiner>
        {group.map((element) => (
          <CheckButton
            key={element.key ? element.key : element.text}
            LabelData={{
              text: element.text,
            }}
            icon={element.icon}
            val={val && val[element.name] && val[element.name].val}
            name={element.name}
            onChange={(update, name) => {
              const change = {
                ...val,
                [element.name]: { val: update },
              };
              onChange(change);
            }}
          />
        ))}
      </CheckGroupContiner>
    </Container>
  );
};

export default CheckButtonGroup;
