import one from "./img/teeth/table8/38b.png";
import oneImplant from "./img/teeth/table8/implants/38b.png";
import oneRemoved from "./img/teeth/table8/removed/38b.png";

import two from "./img/teeth/table8/37b.png";
import twoImplant from "./img/teeth/table8/implants/37b.png";
import twoRemoved from "./img/teeth/table8/removed/37b.png";

import three from "./img/teeth/table8/36b.png";
import threeImplant from "./img/teeth/table8/implants/36b.png";
import threeRemoved from "./img/teeth/table8/removed/36b.png";

import four from "./img/teeth/table8/35b.png";
import fourImplant from "./img/teeth/table8/implants/35b.png";
import fourRemoved from "./img/teeth/table8/removed/35b.png";

import five from "./img/teeth/table8/34b.png";
import fiveImplant from "./img/teeth/table8/implants/34b.png";
import fiveRemoved from "./img/teeth/table8/removed/34b.png";

import six from "./img/teeth/table8/33b.png";
import sixImplant from "./img/teeth/table8/implants/33b.png";
import sixRemoved from "./img/teeth/table8/removed/33b.png";

import seven from "./img/teeth/table8/32b.png";
import sevenImplant from "./img/teeth/table8/implants/32b.png";
import sevenRemoved from "./img/teeth/table8/removed/32b.png";

import eight from "./img/teeth/table8/31b.png";
import eightImplant from "./img/teeth/table8/implants/31b.png";
import eightRemoved from "./img/teeth/table8/removed/31b.png";

export const eightSettings = {
  imgs: [
    {
      id: 18,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      }, 
      pos: "0 25px",
      width: "61px",
    },
    {
      id: 17,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      }, 
      pos: "0 25px",
      width: "61px",
    },
    {
      id: 16,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      }, 
      pos: "0 23px",
      width: "69px",
    },
    {
      id: 15,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 22px",
      width: "45px",
    },
    {
      id: 14,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 15px",
      width: "41px",
    },
    {
      id: 13,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "0 11px",
      width: "39px",
    },
    {
      id: 12,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 15px",
      width: "37px",
    },
    {
      id: 11,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 19px",
      width: "35px",
    },
  ],
};

export const eightState = {
  margin: [
    [7, 63],
    [27.5, 63],
    [48, 63],

    [62, 63],
    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  depth: [
    [7, 63],
    [27.5, 63],
    [48, 63],
    [62, 63],

    [82.5, 63],
    [102, 63],

    [114.5, 63],
    [142.5, 63],
    [167.5, 63],

    [180, 63],
    [195, 63],
    [210, 63],

    [223, 63],
    [238, 63],
    [253, 63],

    [267.5, 63],
    [280, 63],
    [294, 63],

    [312.5, 63],
    [324, 63],
    [337, 63],

    [355, 63],
    [372, 63],
    [387.5, 63],
  ],
  imgState: [
    {
      id: 28,

      furcation: [{ state: null, pos: [26, 74] }],
    },
    {
      id: 27,

      furcation: [{ state: null, pos: [82, 74] }],
    },
    {
      id: 26,

      furcation: [{ state: null, pos: [142, 74] }],
    },
    {
      id: 25,
    },
    {
      id: 24,
    },
    {
      id: 23,
    },
    {
      id: 22,
    },
    {
      id: 21,
    },
  ],
};
