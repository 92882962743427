import one from "./img/teeth/table2/28.png";
import oneImplant from "./img/teeth/table2/implants/28.png";
import oneRemoved from "./img/teeth/table2/removed/28.png";

import two from "./img/teeth/table2/27.png";
import twoImplant from "./img/teeth/table2/implants/27.png";
import twoRemoved from "./img/teeth/table2/removed/27.png";

import three from "./img/teeth/table2/26.png";
import threeImplant from "./img/teeth/table2/implants/26.png";
import threeRemoved from "./img/teeth/table2/removed/26.png";

import four from "./img/teeth/table2/25.png";
import fourImplant from "./img/teeth/table2/implants/25.png";
import fourRemoved from "./img/teeth/table2/removed/25.png";

import five from "./img/teeth/table2/24.png";
import fiveImplant from "./img/teeth/table2/implants/24.png";
import fiveRemoved from "./img/teeth/table2/removed/24.png";

import six from "./img/teeth/table2/23.png";
import sixImplant from "./img/teeth/table2/implants/23.png";
import sixRemoved from "./img/teeth/table2/removed/23.png";

import seven from "./img/teeth/table2/22.png";
import sevenImplant from "./img/teeth/table2/implants/22.png";
import sevenRemoved from "./img/teeth/table2/removed/22.png";
import eight from "./img/teeth/table2/21.png";
import eightImplant from "./img/teeth/table2/implants/21.png";
import eightRemoved from "./img/teeth/table2/removed/21.png";

export const twoSettings = {
  imgs: [
    {
      id: 28,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      },

      pos: "0 -2px",
      width: "54px",
    },
    {
      id: 27,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      },

      width: "57px",
    },
    {
      id: 26,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      },
      pos: "0 4px",
      width: "63px",
    },
    {
      id: 25,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 5px",
      width: "42px",
    },
    {
      id: 24,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 0px",
      width: "46px",
    },
    {
      id: 23,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "top",
      width: "42px",
    },
    {
      id: 22,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 6px",
      width: "38px",
    },
    {
      id: 21,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 10px",
      width: "54px",
    },
  ],
};

export const twoState = {
  margin: [
    [7, 97],
    [22.5, 97],
    [40, 97],

    [60, 97],
    [72, 97],
    [84, 97],

    [104, 97],
    [116, 97],
    [129, 97],

    [145, 97],
    [157, 97],
    [168, 97],

    [188, 97],
    [200, 97],
    [212, 97],

    [225, 97],
    [254, 97],
    [280.5, 97],

    [293, 97],
    [314, 97],
    [334, 97],

    [352, 97],
    [370, 97],
    [387.5, 97],
  ],
  depth: [
    [7, 97],
    [22.5, 97],
    [40, 97],

    [60, 97],
    [72, 97],
    [84, 97],

    [104, 97],
    [116, 97],
    [129, 97],

    [145, 97],
    [157, 97],
    [168, 97],

    [188, 97],
    [200, 97],
    [212, 97],

    [225, 97],
    [254, 97],
    [280.5, 97],

    [293, 97],
    [314, 97],
    [334, 97],

    [352, 97],
    [370, 97],
    [387.5, 97],
  ],
  imgState: [
    {
      id: 21,
    },
    {
      id: 22,
    },
    {
      id: 23,
    },
    {
      id: 24,
    },
    {
      id: 25,
    },
    {
      id: 26,
      furcation: [{ state: null, pos: [255, 74] }],
    },
    {
      id: 27,
      furcation: [{ state: null, pos: [315, 74] }],
    },
    {
      id: 28,
      furcation: [{ state: null, pos: [370, 74] }],
    },
  ],
};
