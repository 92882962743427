import React, { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { CalendarMonth, Close } from "@mui/icons-material";
import colors from "library/styled-components/colors";
import { MultiSectionDigitalClock } from "@mui/x-date-pickers/MultiSectionDigitalClock";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DateCalendar } from "@mui/x-date-pickers";
import styled from "styled-components";
import {
  addMinutes,
  addMonths,
  format,
  startOfHour,
  startOfMinute,
} from "date-fns";
import SMSInputEditor from "./SmsInputEditor";
import { ADMIN_NS } from "config";
import { authHeader, handleResponse } from "library/handler";
import Alert from "@mui/lab/Alert";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledText = styled(Typography)`
  color: ${colors.primary};
  font-weight: 600;
`;

const DateLayoutWrapper = styled.div`
  display: grid;
  grid-auto-columns: max-content auto max-content;
  grid-auto-rows: max-content auto max-content;
`;

const DateLayout = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 2;
  grid-row: 2;
`;

const DateColLayout = styled.div`
  display: flex;
  margin: 0px auto;
`;

const StyledMultiSectionDigitalClock = styled(MultiSectionDigitalClock)`
  ul {
    max-height: fit-content;
  }
`;

const sendSmsMessageToQueue = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/sms-queue/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state),
  })
    .then((response) => handleResponse(response))
    .catch((err) => console.log("Fetch Error :-S", err));
};

const SmsSchedulerModal = ({ open, close, type, appointmentData }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [updating, setUpdating] = useState(false);
  const [sendDate, setSendDate] = useState(new Date());
  const [sendSlot, setSendSlot] = useState(type === "reminder" ? 1 : 6);

  useEffect(() => {
    if (type === "reminder") {
      setSendSlot(1);
      setSendDate(
        addMinutes(
          startOfMinute(new Date()),
          Math.ceil((new Date().getMinutes() + 1) / 5) * 5 -
            new Date().getMinutes()
        )
      );
    } else if (type === "recall") {
      setSendSlot(6);
      setSendDate(addMonths(startOfHour(new Date().setHours(9, 0, 0, 0)), 6));
    }
  }, [type]);

  /* sendReminder={async () => {
    const response =
      await sendReminderPatientAppointment(
        ADMIN_NS,
        info.event?.extendedProps
      );
    setReminderSent(true);
    setTimeout(() => {
      setReminderSent(false);
    }, 3000);
  }}  */

  const headetText =
    type === "reminder"
      ? "Send reminder Sms Message"
      : "Send recall Sms Message";
  const descriptionText =
    type === "reminder"
      ? "Select a date and time to send a reminder to the patient."
      : "Select a date and time to send a recall to the patient.";

  const queueMessages = appointmentData?.Patient?.SMSQueues?.filter(
    (s) => s.Message === type
  );

  return (
    <Dialog
      open={open || false}
      onClose={close}
      TransitionComponent={Transition}
      maxWidth="md"
    >
      <DialogContent
        style={{
          background: colors.white,
        }}
      >
        <div
          style={{
            position: "absolute",
            right: 0,
            top: 0,
          }}
        >
          <IconButton aria-label="close" onClick={close} size="large">
            <Close />
          </IconButton>
        </div>
        <div
          style={{
            display: "grid",
            justifyContent: "center",
            alignItems: "flex-start",
            gridTemplateColumns: "auto auto",
            padding: "20px",
          }}
        >
          <div>
            {" "}
            <StyledText variant="h5">{headetText}</StyledText>
            <Typography
              variant="body2"
              style={{
                color: colors.dark,
                marginTop: "1rem",
                marginBottom: "1rem",
              }}
            >
              {descriptionText}
            </Typography>
            <div>
              <ButtonGroup
                spacing="0.5rem"
                aria-label="spacing button group"
                variant="contained"
                style={{
                  marginBottom: "1rem",
                }}
              >
                <Button
                  variant={sendSlot === 1 ? "contained" : "outlined"}
                  onClick={() => {
                    setSendSlot(1);
                    setSendDate(
                      addMinutes(
                        startOfMinute(new Date()),
                        Math.ceil((new Date().getMinutes() + 1) / 5) * 5 -
                          new Date().getMinutes()
                      )
                    );
                  }}
                >
                  now
                </Button>
                <Divider />
                <Button
                  variant={sendSlot === 3 ? "contained" : "outlined"}
                  onClick={() => {
                    setSendSlot(3);
                    setSendDate(
                      addMonths(startOfHour(new Date().setHours(9, 0, 0, 0)), 3)
                    );
                  }}
                >
                  3 months
                </Button>{" "}
                <Divider />
                <Button
                  variant={sendSlot === 6 ? "contained" : "outlined"}
                  onClick={() => {
                    setSendSlot(6);
                    setSendDate(
                      addMonths(startOfHour(new Date().setHours(9, 0, 0, 0)), 6)
                    );
                  }}
                >
                  6 months
                </Button>{" "}
                <Divider />
                <IconButton
                  color={sendSlot === 0 ? "primary" : "default"}
                  onClick={() => {
                    setSendSlot(0);
                  }}
                >
                  <CalendarMonth />
                </IconButton>
              </ButtonGroup>
            </div>
            <div
              style={{
                border: `1px solid ${colors.dark}`,
                padding: "1rem",
                borderRadius: "5px",
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <DateLayoutWrapper>
                    <DateLayout>
                      <DateColLayout>
                        <DateCalendar
                          value={sendDate}
                          minDate={new Date()}
                          disabled={sendSlot !== 0}
                          onChange={(date) => {
                            setSendDate(date);
                          }}
                        />

                        <Divider orientation="vertical" flexItem />
                        <StyledMultiSectionDigitalClock
                          ampm={false}
                          sx={{ width: "auto" }}
                          value={sendDate}
                          disabled={sendSlot !== 0}
                          onChange={(date) => {
                            setSendDate(date);
                          }}
                        />
                      </DateColLayout>
                    </DateLayout>
                  </DateLayoutWrapper>
                </div>
              </LocalizationProvider>
            </div>
          </div>
          <div
            style={{
              display: "grid",
              paddingTop: "1rem",
            }}
          >
            <div>
              <Typography variant="body2" style={{ margin: "1rem" }}>
                The sms message below will be sent on {sendDate.toDateString()}{" "}
                at {sendDate.toLocaleTimeString()} to{" "}
                <strong>{appointmentData?.Patient?.User?.Name}</strong> on the{" "}
                <strong>
                  {format(
                    new Date(appointmentData?.start),
                    "do MMMM yyyy, h:mm a"
                  )}
                </strong>
              </Typography>
              <Typography variant="body2" style={{ margin: "1rem" }}>
                To continue, click the send to queue button below.
              </Typography>
            </div>
            <SMSInputEditor
              close={close}
              updating={updating}
              date={sendDate}
              patientName={appointmentData?.Patient?.User?.Name || ""}
              sendMessageToQueue={async () => {
                setUpdating(true);

                try {
                  const response = await sendSmsMessageToQueue(ADMIN_NS, {
                    PatientId: appointmentData?.PatientId,
                    UserId: appointmentData?.Patient?.UserId,
                    Phone: appointmentData?.Patient?.User?.Phone,
                    SendDate: sendDate,
                    AppointmentId: appointmentData?.AppointmentId,
                    Message: type,
                  });
                  if (response?.code === "SUCCESS") {
                    setSuccess("Message sent to queue");
                    setTimeout(() => {
                      setSuccess(null);
                    }, 2000);
                  } else {
                    setError("Error sending message");
                  }

                  setUpdating(false);
                } catch (error) {
                  setError("Error sending message");
                  setTimeout(() => {
                    setError(null);
                  }, 2000);
                  console.log(error);
                }
              }}
            />

            {error ? (
              <Alert
                severity="error"
                sx={{
                  margin: "1rem",
                }}
              >
                {error}
              </Alert>
            ) : (
              ""
            )}
            {success ? (
              <Alert severity="success" sx={{ margin: "1rem" }}>
                {success}
              </Alert>
            ) : (
              ""
            )}
          </div>
        </div>

        {queueMessages?.length > 0 ? (
          <div
            style={{
              display: "grid",
              marginTop: "1rem",
            }}
          >
            <TableContainer
              component={Paper}
              style={{ maxHeight: "20rem", overflow: "auto" }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                      }}
                    >
                      Message
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                      }}
                    >
                      Scheduled{" "}
                    </TableCell>
                    <TableCell
                      align="left"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                      }}
                    >
                      Status
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                      }}
                    >
                      Sent
                    </TableCell>
                    <TableCell
                      align="right"
                      style={{
                        backgroundColor: colors.primary,
                        color: colors.white,
                      }}
                    >
                      Retries
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {queueMessages.map((sms) => (
                    <TableRow
                      key={sms?.SmsQueueId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="left">{sms?.Message}</TableCell>
                      <TableCell align="left">
                        {sms?.SendDate
                          ? format(
                              new Date(sms?.SendDate),
                              "do MMMM yyyy, h:mm a"
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="left">{sms?.Status}</TableCell>
                      <TableCell align="right">
                        {sms?.SentAt
                          ? format(
                              new Date(sms?.SentAt),
                              "do MMMM yyyy, h:mm a"
                            )
                          : ""}
                      </TableCell>
                      <TableCell align="right">{sms?.Retries}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SmsSchedulerModal;
