import {
  registerPracticeCustomer,
} from "../../../actions/register";

import { getPublicPractitioners } from "../../../actions/practitioners";
import { getPublicPractice } from "../../../actions/practices";

import { ADMIN_NS } from "../../../config";

export const fetchData = async (uuid) => ({
  practitioners: await getPractitionerList(uuid),
  practice: await getPractice(uuid),
});

const getPractitionerList = async (uuid) => await getPublicPractitioners(uuid);

const getPractice = async (uuid) => await getPublicPractice(uuid);

export const registerUser = async (user) =>
  await registerPracticeCustomer(ADMIN_NS, user);
