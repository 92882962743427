import React from "react";
import { AccountBalance } from "@mui/icons-material";
import { EventBlock, EventBlockContainer } from "./Common";
import { Tooltip, Typography } from "@mui/material";
import colors from "library/styled-components/colors";

const HolidayBlock = ({ info }) => {
  return (
    <EventBlockContainer>
      <Tooltip
        arrow
        placement="top-end"
        title={
          <React.Fragment>
            <Typography color="inherit">{info.event?.title}</Typography>
          </React.Fragment>
        }
      >
        <EventBlock>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                height: "2rem",
                fontSize: "0.8rem",
              }}
            >
              <AccountBalance sx={{ color: colors.primary }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              height: "2rem",
              fontSize: "0.8rem",
            }}
          >
            {info.event.title}
          </div>
          <div />
        </EventBlock>
      </Tooltip>
    </EventBlockContainer>
  );
};

export default HolidayBlock;
