import React from "react";
import { FormControl, Grid, TextField, Autocomplete } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  addHours,
  addMinutes,
  differenceInHours,
  differenceInMinutes,
  getHours,
  setHours,
  getMinutes,
  setMinutes,
} from "date-fns";

const TimeDropdown = ({ form, setForm }) => {
  const [hoursToAdd, setHoursToAdd] = React.useState(0);
  const [minutesToAdd, setMinutesToAdd] = React.useState(0);
  
  React.useEffect(() => {
    const hoursToAdd = differenceInHours(form.end, form.start);
    const minutesToAdd = differenceInMinutes(form.end, form.start) % 60;
    setHoursToAdd(hoursToAdd);
    setMinutesToAdd(minutesToAdd);
  }, [form.start, form.end]);
  
  const updateEndWithSelectedTime = (hours, minutes) => {
    const updatedEnd = addMinutes(addHours(form.start, hours), minutes);
    if (form.start >= updatedEnd) {
      setForm({
        ...form,
        end: addMinutes(form.start, 30),
      });
    } else {
      setForm({
        ...form,
        end: updatedEnd,
      });
    }
  };

  return (
    <Grid
      container
      spacing={2}
      alignItems="center"
      style={{ marginBottom: "16px" }}
    >
      <Grid item xs={12}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          style={{ background: "white", width: "100%" }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              format="dd/MM/yyyy"
              value={form.start}
              onChange={(newValue) => {
                // Calculate new end time using the currently set hours and minutes
                const hoursToAdd = differenceInHours(form.end, form.start);
                const minutesToAdd =
                  differenceInMinutes(form.end, form.start) % 60;

                const newStart = newValue;
                const newEnd = addMinutes(
                  addHours(newStart, hoursToAdd),
                  minutesToAdd
                );

                if (
                  newStart >= newEnd ||
                  (hoursToAdd === 0 && minutesToAdd === 0)
                ) {
                  setForm({
                    ...form,
                    start: newStart,
                    end: addMinutes(newStart, 30),
                  });
                } else {
                  setForm({ ...form, start: newStart, end: newEnd });
                }
              }}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          style={{ background: "white", width: "100%" }}
        >
          <Autocomplete
            value={getHours(form.start)}
            options={Array.from({ length: 24 }).map((_, index) => index)}
            getOptionLabel={(option) => option.toString().padStart(2, "0")}
            onChange={(e, value) => {
              // set the hour of the start time
              const newStart = setHours(form.start, value);
              const newEnd = addMinutes(
                addHours(newStart, hoursToAdd),
                minutesToAdd
              );

              if (!isNaN(newStart)) {
                if (newStart >= form.end) {
                  setForm({
                    ...form,
                    start: newStart,
                    end: addMinutes(newStart, 30),
                  });
                } else {
                  setForm({
                    ...form,
                    start: newStart,
                    end: newEnd,
                  });
                }
              }
            }}
            onBlur={(e) => {
              // set the hour of the start time
              const newStart = setHours(form.start, e.target.value);
              const newEnd = addMinutes(
                addHours(newStart, hoursToAdd),
                minutesToAdd
              );

              if (!isNaN(newStart)) {
                if (newStart >= form.end) {
                  setForm({
                    ...form,
                    start: newStart,
                    end: addMinutes(newStart, 30),
                  });
                } else {
                  setForm({
                    ...form,
                    start: newStart,
                    end: newEnd,
                  });
                }
              }
            }}
            style={{ background: "white", width: "100%", height: "54px" }}
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Start (Hours)" />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl
          variant="outlined"
          size="small"
          fullWidth
          style={{ background: "white", width: "100%" }}
        >
          <Autocomplete
            value={getMinutes(form.start)}
            options={Array.from({ length: 60 }).map((_, index) => index)}
            getOptionLabel={(option) => option.toString().padStart(2, "0")}
            onChange={(e, value) => {
              // set the minutes of the start time
              const newStart = setMinutes(form.start, value);
              const newEnd = addMinutes(
                addHours(newStart, hoursToAdd),
                minutesToAdd
              );


              if (!isNaN(newStart)) {
                if (newStart >= form.end) {
                  setForm({
                    ...form,
                    start: newStart,
                    end: addMinutes(newStart, 30),
                  });
                } else {
                  setForm({
                    ...form,
                    start: newStart,
                    end: newEnd,
                  });
                }
              }
            }}
            onBlur={(e) => {
              // set the minutes of the start time
              const newStart = setMinutes(form.start, e.target.value);
              const newEnd = addMinutes(
                addHours(newStart, hoursToAdd),
                minutesToAdd
              );

              if (!isNaN(newStart)) {
                if (newStart >= form.end) {
                  setForm({
                    ...form,
                    start: newStart,
                    end: addMinutes(newStart, 30),
                  });
                } else {
                  setForm({
                    ...form,
                    start: newStart,
                    end: newEnd,
                  });
                }
              }
            }}
            style={{ background: "white", width: "100%", height: "54px" }}
            label="Minute"
            fullWidth
            renderInput={(params) => (
              <TextField {...params} label="Start (minutes)" />
            )}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" size="small" fullWidth>
          <Autocomplete
            value={form.end ? differenceInHours(form.end, form.start) : 0}
            options={Array.from({ length: 24 }).map((_, index) => index)}
            getOptionLabel={(option) => option.toString().padStart(2, "0")}
            onChange={(e, value) => {
              // Use value directly from Autocomplete onChange
              const hoursToAdd = value !== null ? parseInt(value, 10) : 0;

              if (!isNaN(hoursToAdd)) {
                // Check if hoursToAdd is not NaN
                const minutes = differenceInMinutes(form.end, form.start) % 60;
                setHoursToAdd(hoursToAdd);
                updateEndWithSelectedTime(hoursToAdd, minutes);
              }
            }}
            onBlur={(e) => {
              // Use value directly from Autocomplete onBlur
              const hoursToAdd =
                e.target.value !== "" ? parseInt(e.target.value, 10) : 0;

              if (!isNaN(hoursToAdd)) {
                // Check if hoursToAdd is not NaN
                const minutes = differenceInMinutes(form.end, form.start) % 60;
                setHoursToAdd(hoursToAdd);
                updateEndWithSelectedTime(hoursToAdd, minutes);
              }
            }}
            style={{ background: "white", width: "100%", height: "54px" }}
            label="Hour"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Hours" />}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl variant="outlined" size="small" fullWidth>
          <Autocomplete
            value={
              form.end
                ? `${differenceInMinutes(form.end, form.start) % 60} min`
                : "0 min"
            }
            options={Array.from({ length: 12 }).map(
              (_, index) => `${index * 5} min`
            )}
            getOptionLabel={(option) => option.toString().padStart(2, "0")}
            onChange={(e, value) => {
              const minutesToAdd = value
                ? parseInt(value.split(" ")[0], 10)
                : 0;

              if (!isNaN(minutesToAdd)) {
                const hours = differenceInHours(form.end, form.start);
                setMinutesToAdd(minutesToAdd);
                updateEndWithSelectedTime(hours, minutesToAdd);
              }
            }}
            onBlur={(e) => {
              const minutesToAdd =
                e.target.value !== ""
                  ? parseInt(e.target.value.split(" ")[0], 10)
                  : 0;

              if (!isNaN(minutesToAdd)) {
                const hours = differenceInHours(form.end, form.start);
                setMinutesToAdd(minutesToAdd);
                updateEndWithSelectedTime(hours, minutesToAdd);
              }
            }}
            style={{ background: "white", width: "100%", height: "54px" }}
            label="Minute"
            fullWidth
            renderInput={(params) => <TextField {...params} label="Minutes" />}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default TimeDropdown;
