import moment from "moment";
// import store from "store";
import { baseReducer } from "../../../library/reducer";
import { ADMIN_NS } from "../../../config";

const namespace = ADMIN_NS;

const slot = // (store.get(namespace) && store.get(namespace).slot) || 
{
  data: null,
  meta: { updating: true },
};

const schedules = //(store.get(namespace) && store.get(namespace).schedules) || 
{
  data: [],
  meta: { updating: true },
};

const appointments = // (store.get(namespace) & store.get(namespace).appointments) || 
{
  data: [],
  meta: { updating: true },
};

const consultations = // (store.get(namespace) & store.get(namespace).appointments) || 
{
  data: [],
  meta: { updating: true },
};


const payments = // (store.get(namespace) & store.get(namespace).appointments) || 
{
  data: [],
  meta: { updating: true },
};

const user = // (store.get(namespace) && store.get(namespace).user) ||
{
  data: null,
  meta: { updating: true },
};

const patients = // (store.get(namespace) && store.get(namespace).patients) || 
{
  data: [],
  meta: { updating: true },
};

const treatments = // (store.get(namespace) && store.get(namespace).patients) || 
{
  data: [],
  meta: { updating: true },
};

const charges = // (store.get(namespace) && store.get(namespace).patients) || 
{
  data: [],
  meta: { updating: true },
};

const list = // (store.get(namespace) && store.get(namespace).list) || 
{
  data: [],
  meta: { updating: true },
};

const documents = // (store.get(namespace) && store.get(namespace).list) || 
{
  data: [],
  meta: { updating: true },
};

const graph = // (store.get(namespace) && store.get(namespace).list) || 
{
  data: [] ,
  meta: { updating: true },
};

export const initialState = {
  date: { data: moment(), meta: { updating: false } },
  schedules: { ...schedules, meta: { updating: false } },
  appointments: { ...appointments, meta: { updating: false } },
  consultations: { ...consultations, meta: { updating: false } },
  payments: { ...payments, meta: { updating: false } },
  patients: { ...patients, meta: { updating: false } },
  sms: { ...patients, meta: { updating: false } },
  treatments: { ...treatments, meta: { updating: false } },
  charges: { ...charges, meta: { updating: false } },
  list: { ...list, meta: { updating: false } },
  user: { ...user, meta: { updating: false } },
  slot: { ...slot, meta: { updating: false } },
  graph: { data: null , meta: { updating: false } },
  targets: { data: [] , meta: { updating: false } },
  tags: { data: [] , meta: { updating: false } },
  documents: { data: [] , meta: { updating: false } },
};

const reducer = baseReducer(namespace);

export { reducer };
