import React, { useContext, useMemo, useState } from "react";
import clsx from "clsx";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import styled from "styled-components";
import colors from "library/styled-components/colors";
import {
  AddWrapIcon,
  BlockWrap,
  DynamicIconBtn,
  FormContainer,
  ModalBox,
  PosIcon,
} from "components/common";
import ClipboardIcon from "icon/fea-icons/clipboard";
import { printInvoice, printMedForm } from "library/pdf/invoice";
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  lighten,
  Toolbar,
  Typography,
  Tooltip,
  TableRow,
  TableSortLabel,
  TablePagination,
  Collapse,
  Box,
  IconButton,
  Popover,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { format } from "date-fns";
import Editor from "components/editor";
import {
  createButtons,
  paymentFormSchema,
} from "containers/admin/home/reports/formSettings";
import SearchInput from "components/search-input";
import { AppContext } from "hooks/context";
import { ADMIN_NS } from "config";
import { createUnFixPayment } from "actions/payments";
import Joi from "@hapi/joi";
import FeaPlusCircleIcon from "icon/fea-icons/plus-circle";
import { Print, InsertDriveFile } from "@mui/icons-material";

const Container = styled.div`
  background-color: #fff;
  padding: 1rem;
  border-radius: 3px;
  height: calc(100% - 8rem);
  overflow-y: auto;
`;

const TopBarContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PatientInfo = styled.div`
  display: flex;
`;

const PatientDetails = styled.div`
  display: flex;
`;

const PercentageBox = styled.div`
  font-size: 3em;
  width: 80px;
  height: 80px;
  margin: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  z-index: 0;

  &&::after {
    content: "${(props) => props.one}%";
    font-size: 14px;
  }

  &&::before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;
    padding: 16px;
    background: linear-gradient(#f3f3f3, #f3f3f3) content-box,
      /* this background will be shown if mask is no supported */
        conic-gradient(
          ${colors.softGreen} 0 ${(props) => props.one}%,
          ${colors.softRed} calc(${(props) => props.one}% + 0.5%)
            calc(${(props) => props.one}% + ${(props) => props.two}%),
          grey 0
        );
    -webkit-mask: radial-gradient(
      farthest-side,
      transparent calc(100% - 16px),
      #fff calc(100% - 15px)
    );
  }
`;

const Search = styled.div`
  display: grid;
`;

const AddContainer = styled.div`
  display: flex;
  position: relative;
  left: 0.5rem;
  cursor: pointer;
  :hover > * {
    color: ${colors.primary};
  }
`;

const Headline = styled.div`
  color: ${colors.grey};
  text-align: left;
  margin-bottom: 0.4rem;
`;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const schema = ["FromDate", "ToDate", "Code", "Name", "Amount"];

const headCells = [
  {
    id: "expand",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "actions",
    numeric: false,
    disablePadding: true,
    label: "",
  },
  {
    id: "Code",
    numeric: false,
    disablePadding: true,
    label: "Invoice Id",
  },
  {
    id: "FromDate",
    numeric: false,
    disablePadding: true,
    label: "Invoice Date",
  },
  { id: "fee", numeric: true, disablePadding: false, label: "€ Amount" },
  { id: "payed", numeric: true, disablePadding: false, label: "€ Payment" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all" }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
    alignSelf: "end",
  },
  highlight:
    theme.palette.mode === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: "1 1 100%",
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography color="inherit" variant="subtitle1" component="div">
          {numSelected} selected{" "}
          <Tooltip title="Send Mail">
            <DynamicIconBtn
              fontSize="1rem"
              color={colors.accentColor}
              textColor={colors.white}
              onClick={() => props.printMedForm()}
            >
              <PosIcon>
                <ClipboardIcon size="18" />
              </PosIcon>
              <div>Med 2 Form</div>
            </DynamicIconBtn>
          </Tooltip>
        </Typography>
      ) : (
        <Search>
          <SearchInput
            autoFocus={false}
            search={props.search}
            placeholder={"Search Invoices"}
            setSearch={props.setSearch}
          />
        </Search>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

function FinanceTab({ showOnlyInvoice }) {
  const {
    dispatch,
    state: { patient },
  } = useContext(AppContext);
  const classes = useStyles();
  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState("FromDate");
  const [showFilter, setShowFilter] = useState(false);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [selected, setSelected] = React.useState([]);
  const [open, setOpen] = React.useState([]);
  const [edit, setEdit] = React.useState([]);
  const [chargedSelected, setChargedSelected] = React.useState([]);
  const [chargesChanged, setChargesChanged] = React.useState([]);
  const [search, setSearch] = useState("");
  const [paymentData, setPaymentData] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const amounts = useMemo(() => {
    const total =
      patient &&
      patient.data.Invoices.reduce(function (acc, i) {
        return (
          acc +
          parseFloat(
            i.Charges.map((p) => p.Amount).reduce((sum, i) => sum + i, 0) || 0
          )
        );
      }, 0);
    const payed =
      patient &&
      patient.data.Invoices.reduce(function (acc, i) {
        return (
          acc +
          parseFloat(
            i.Payments.map((p) => p.Amount).reduce((sum, i) => sum + i, 0) || 0
          )
        );
      }, 0);
    return {
      total,
      payed,
      due: total - payed,
    };
  }, [patient]);

  const rows = useMemo(() => {
    let rows = [];
    patient?.data?.Invoices?.forEach((r) => {
      rows = [
        ...rows,
        {
          ...r,
          id: r.id,
          Code: r.Code,
          FromDate: r.FromDate,
          Amount: r.Charges.reduce(function (acc, a) {
            return acc + parseFloat(a.Amount || 0);
          }, 0),
          ChargeId: r.ChargeId,
          Treatment: `${r.Method}`,
          Practitioner: r.Practitioner?.Name,
          Payment: r?.Payment,
          createdAt: r.createdAt,
          Charges: r?.Charges,
        },
      ];
    }) || [];
    return rows;
  }, [patient]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      const newChargeSelecteds = rows
        .map((n) => n.Charges?.map((c) => c.id))
        .flat();
      setSelected(newSelecteds);
      setChargedSelected(newChargeSelecteds);
      return;
    }
    setSelected([]);
    setChargedSelected([]);
    return;
  };

  const handleOpen = (id) => {
    const selectedIndex = open.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(open, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(open.slice(1));
    } else if (selectedIndex === open.length - 1) {
      newSelected = newSelected.concat(open.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        open.slice(0, selectedIndex),
        open.slice(selectedIndex + 1)
      );
    }
    setOpen(newSelected);
  };

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    const newChargeSelecteds = rows
      .filter((n) => newSelected.includes(n.id))
      .map((n) => n.Charges?.map((c) => c.id))
      .flat();

    setChargedSelected(newChargeSelecteds);
    setSelected(newSelected);
  };

  const handleChargeClick = (id) => {
    const selectedIndex = chargedSelected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(chargedSelected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(chargedSelected.slice(1));
    } else if (selectedIndex === chargedSelected.length - 1) {
      newSelected = newSelected.concat(chargedSelected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        chargedSelected.slice(0, selectedIndex),
        chargedSelected.slice(selectedIndex + 1)
      );
    }

    setChargedSelected(newSelected);
  };

  const handleChangePage = (e, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;
  const isOpen = (name) => open.indexOf(name) !== -1;
  const isChargeSelected = (name) => chargedSelected.indexOf(name) !== -1;

  return (
    <Container>
      <Paper className={classes.paper}>
        <TopBarContainer>
          <div>
            <AddContainer>
              <AddWrapIcon
                onClick={() => {
                  showOnlyInvoice(true);
                }}
              >
                <FeaPlusCircleIcon size="32" />
              </AddWrapIcon>
              <Headline
                onClick={() => {
                  showOnlyInvoice(true);
                }}
                style={{ height: "24px", alignSelf: "center" }}
              >
                Create a new Invoice
              </Headline>
            </AddContainer>
            <EnhancedTableToolbar
              numSelected={selected.length + chargedSelected.length}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              setSearch={setSearch}
              search={search}
              printMedForm={() => printMedForm(patient.data, chargedSelected)}
            />
          </div>
          <PatientInfo>
            {amounts && (
              <PatientDetails>
                <PercentageBox
                  one={((amounts.payed / amounts.total) * 100).toFixed()}
                  two={((amounts.due / amounts.total) * 100).toFixed()}
                ></PercentageBox>
                <div>
                  <TableContainer component={Paper}>
                    <Table aria-label="simple table" size="small">
                      <TableBody>
                        <TableRow>
                          <TableCell align="right">Total</TableCell>
                          <TableCell align="right">
                            {new Intl.NumberFormat("en-IE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(amounts.total || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">Total Payed</TableCell>
                          <TableCell align="right">
                            {new Intl.NumberFormat("en-IE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(amounts.payed || 0)}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell align="right">Total Owed</TableCell>
                          <TableCell align="right">
                            {new Intl.NumberFormat("en-IE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(amounts.due || 0)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>{" "}
                </div>
              </PatientDetails>
            )}
          </PatientInfo>
        </TopBarContainer>
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="filtered List"
            size="small"
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />

            <TableBody>
              {stableSort(
                rows.filter((row) => {
                  const rowStr = schema.map((s) => row[s]);
                  const subRowStr = schema.map((s) => row?.Charges[s]);

                  const str = [...rowStr, ...subRowStr].join().toLowerCase();
                  return str && str.search(search.toLowerCase()) >= 0;
                }),
                getComparator(order, orderBy)
              )
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const isItemOpen = isOpen(row.id);
                  const hasMedCode = row.Charges.some(
                    (c) => !!c?.Treatment?.Data?.MedCode
                  );
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <React.Fragment key={row.id}>
                      <TableRow
                        hover
                        onClick={(e) => {
                          e.preventDefault();
                          hasMedCode && handleClick(row.id);
                        }}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        selected={isItemSelected}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={!hasMedCode}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleOpen(row.id);
                            }}
                          >
                            {isItemOpen ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          Print
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              printInvoice(
                                patient?.data?.Invoices.find(
                                  (i) => i.id === row.id
                                ),
                                patient?.data?.User,
                                patient?.data?.Practice
                              );
                            }}
                            aria-label="back"
                            component="span"
                            size="large">
                            <Print
                              fontSize="small"
                              style={{ color: colors.black }}
                            />
                          </IconButton>
                          <IconButton
                            onClick={(e) => {
                              e.stopPropagation();
                              showOnlyInvoice(true, row.InvoiceId);
                            }}
                            aria-label="back"
                            component="span"
                            size="large">
                            <InsertDriveFile
                              fontSize="small"
                              style={{ color: colors.primary }}
                            />
                          </IconButton>
                          Open
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {row?.Code}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          {format(
                            new Date(row?.FromDate),
                            "EEEE, do MMMM yyyy"
                          )}
                        </TableCell>

                        <TableCell align="right" style={{ color: colors.red }}>
                          {new Intl.NumberFormat("en-IE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(row?.Amount)}
                        </TableCell>
                        <TableCell
                          align="right"
                          style={{ color: colors.green }}
                        >
                          {new Intl.NumberFormat("en-IE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(
                            row?.Payments.map((p) => p?.Amount).reduce(
                              (sum, i) => sum + i,
                              0
                            ) || 0
                          )}
                        </TableCell>
                      </TableRow>
                      {row?.Charges && (
                        <TableRow>
                          <TableCell
                            style={{ paddingBottom: 0, paddingTop: 0 }}
                            colSpan={12}
                          >
                            <Collapse
                              in={isItemOpen}
                              timeout="auto"
                              unmountOnExit
                            >
                              <Box margin={1}>
                                <Table size="small" aria-label="purchases">
                                  <TableHead>
                                    <TableRow>
                                      <TableCell></TableCell>
                                      <TableCell>id</TableCell>
                                      <TableCell>Detail</TableCell>
                                      <TableCell align="right">Date</TableCell>

                                      <TableCell align="right">Qty</TableCell>
                                      <TableCell align="right">
                                        Amount
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {row?.Charges.map((c, i) => {
                                      const isChargeItemSelected =
                                        isChargeSelected(c.id);
                                      const isMedCard =
                                        !!c?.Treatment?.Data?.MedCode;
                                      const labelId = `enhanced-table-checkbox-${i}`;
                                      return (
                                        <TableRow
                                          key={c.id}
                                          hover
                                          onClick={(e) => {
                                            e.preventDefault();
                                            isMedCard &&
                                              handleChargeClick(c.id);
                                          }}
                                          role="checkbox"
                                          aria-checked={
                                            isMedCard && isChargeItemSelected
                                          }
                                          tabIndex={-1}
                                          selected={
                                            isMedCard && isChargeItemSelected
                                          }
                                        >
                                          <TableCell padding="checkbox">
                                            <Checkbox
                                              checked={
                                                isMedCard &&
                                                isChargeItemSelected
                                              }
                                              disabled={!isMedCard}
                                              inputProps={{
                                                "aria-labelledby": labelId,
                                              }}
                                            />
                                          </TableCell>
                                          <TableCell component="th" scope="row">
                                            {c.id}
                                          </TableCell>
                                          <TableCell>
                                            {c?.Treatment?.Code} -{" "}
                                            {c?.Treatment?.Name}
                                            {c?.Treatment?.Data?.MedCode &&
                                              ` - MedCode: ${c?.Treatment?.Data?.MedCode}`}
                                          </TableCell>

                                          <TableCell align="right">
                                            {format(
                                              new Date(c.Date),
                                              "MMM dd yyyy hh:ss"
                                            )}
                                          </TableCell>
                                          <TableCell align="right">
                                            {c?.Data?.quantity || 1}
                                          </TableCell>
                                          <TableCell
                                            align="right"
                                            style={{ color: colors.red }}
                                          >
                                            {new Intl.NumberFormat("en-IE", {
                                              style: "currency",
                                              currency: "EUR",
                                            }).format(c.Amount || 0)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}

                                    {row?.Payments.map((p, i) => {
                                      const labelId = `enhanced-table-checkbox-${i}`;
                                      return (
                                        <TableRow key={p.id} hover>
                                          <TableCell padding="checkbox"></TableCell>
                                          <TableCell component="th" scope="row">
                                            {p.id}
                                          </TableCell>
                                          <TableCell>
                                            Payment by {p?.Method}
                                          </TableCell>

                                          <TableCell align="right">
                                            {format(
                                              new Date(p.createdAt),
                                              "MMM dd yyyy hh:ss"
                                            )}
                                          </TableCell>
                                          <TableCell align="right"></TableCell>
                                          <TableCell
                                            align="right"
                                            style={{ color: colors.green }}
                                          >
                                            {new Intl.NumberFormat("en-IE", {
                                              style: "currency",
                                              currency: "EUR",
                                            }).format(p.Amount || 0)}
                                          </TableCell>
                                        </TableRow>
                                      );
                                    })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </Collapse>
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <Popover
          anchorEl={anchorEl}
          onClose={() => {
            setPaymentData(null);
            setAnchorEl(null);
          }}
          open={Boolean(anchorEl)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
        >
          <FormContainer>
            <BlockWrap>
              <Editor
                metaData={{
                  updating: false,
                }}
                formSchema={{
                  ...paymentFormSchema,
                  Amount: {
                    ...paymentFormSchema.Amount,
                    validation: Joi.object({
                      Amount: Joi.number()
                        .min(1)
                        .max(paymentData?.Amount.val || 100000000000)
                        .required(),
                    }),
                  },
                }}
                prePopData={paymentData && { Amount: paymentData.Amount.val }}
                sendData={async (data) => {
                  const response = await createUnFixPayment(ADMIN_NS, {
                    ...paymentData,
                    Method: {
                      val: data.Method.val,
                    },
                    Amount: {
                      val: data.Amount.val,
                    },
                  });

                  response &&
                    response.code === "SUCCESS" &&
                    dispatch({
                      type: "LOAD_ONE",
                      context: "patient",
                      data: {
                        ...patient,
                        Invoices: patient.data.Invoices.map((inv) =>
                          inv.InvoiceId === response.data.InvoiceId
                            ? {
                                ...inv,
                                Charges: inv.Charges.map((chrg) =>
                                  chrg.ChargeId === response.data.ChargeId
                                    ? response.data
                                    : chrg
                                ),
                              }
                            : inv
                        ),
                      },
                      updating: false,
                    });

                  setPaymentData(null);
                  setAnchorEl(null);
                }}
                cancelMethod={() => {
                  setPaymentData(null);
                  setAnchorEl(null);
                }}
                buttons={createButtons}
                submitError={null}
                settings={{
                  side: true,
                  width: {
                    mobile: "100%",
                    desktop: "100%",
                  },
                }}
              />
            </BlockWrap>
          </FormContainer>
        </Popover>

        {rows.length > rowsPerPage && (
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <ModalBox
        show={!!paymentData}
        onClick={() => setPaymentData(null)}
      ></ModalBox>
    </Container>
  );
}

export default FinanceTab;
