import React from "react";
import { DeleteForever } from "styled-icons/material";
import * as Joi from "@hapi/joi";
import colors from "../../../../library/styled-components/colors";
import MailFeaIcon from "../../../../icon/fea-icons/mail";
import PhoneFeaIcon from "../../../../icon/fea-icons/phone";
import UserFeaIcon from "../../../../icon/fea-icons/user";
import MapFeaIcon from "../../../../icon/fea-icons/map";
import ClipFeaIcon from "../../../../icon/fea-icons/clipboard";
import FileFeaIcon from "../../../../icon/fea-icons/file-text";
import HomeFeaIcon from "../../../../icon/fea-icons/home";
import ReactCountryFlag from "react-country-flag";

const validation = {
  Name: Joi.string().min(5).max(90).required(),
  County: Joi.string().min(1).max(255).required(),
  Qualification: Joi.string().min(1).max(255).required(),
  RegistrationNo: Joi.string().min(4).max(4).required(),
  County: Joi.string().min(1).max(255).required(),
  Address: Joi.string().min(5).max(1020).required(),
  Phone: Joi.number().min(8).required(),
  Email: Joi.string()
    .min(5)
    .max(255)
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    }),
  Password: Joi.string().min(10).max(255).required(),
};

export const editorCreateFormSchema = {
  Name: {
    domRef: null,
    type: "text",
    val: "",
    text: "Name",
    placeholder: "Please enter practitioner name",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Name: validation.Name }),
  },
  Qualification: {
    domRef: null,
    type: "text",
    val: "",
    text: "Qualification",
    placeholder: "Please enter practitioner name",
    icon: <FileFeaIcon size="16" />,
    validation: Joi.object({ Qualification: validation.Qualification }),
  },
  RegistrationNo: {
    domRef: null,
    type: "text",
    val: "",
    text: "RegistrationNo",
    placeholder: "Please enter Dental Council Registered Number ",
    icon: <ClipFeaIcon size="16" />,
    validation: Joi.object({ RegistrationNo: validation.RegistrationNo }),
  },
  County: {
    domRef: null,
    text: "County",
    type: "text",
    val: "",
    placeholder: "Please enter County",
    icon: <MapFeaIcon size="16" />,
    validation: Joi.object({ County: validation.County }),
  },
  Address: {
    domRef: null,
    text: "Address",
    type: "text",
    val: "",
    placeholder: "Please enter Address",
    icon: <HomeFeaIcon size="16" />,
    validation: Joi.object({ Address: validation.Address }),
  },
  Phone: {
    domRef: null,
    tel: true,
    text: "Phone",
    type: "tel",
    val: "",
    placeholder: "Please enter practice phone Number",
    icon: (
      <ReactCountryFlag
        aria-label="+353"
        countryCode="IE"
        svg
        style={{
          width: "1.6em",
        }}
      />
    ),
    validation: Joi.object({ Phone: validation.Phone }),
  },
  Email: {
    domRef: null,
    email: true,
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter Practitioners Email",
    icon: <MailFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email }),
  },
  Profile: {
    domRef: null,
    type: "checkbox",
    text: "Active",
    val: true,
    ignore: true,
  },
};

export const editorEditFormSchema = {
  Name: {
    domRef: null,
    type: "text",
    val: "",
    text: "Name",
    placeholder: "Please enter practitioner name",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Name: validation.Name }),
  },
  Qualification: {
    domRef: null,
    type: "text",
    val: "",
    text: "Qualification",
    placeholder: "Please enter practitioner name",
    icon: <FileFeaIcon size="16" />,
    validation: Joi.object({ Qualification: validation.Qualification }),
  },
  RegistrationNo: {
    domRef: null,
    type: "text",
    val: "",
    text: "RegistrationNo",
    placeholder: "Please enter Dental Council Registered Number ",
    icon: <ClipFeaIcon size="16" />,
    validation: Joi.object({ RegistrationNo: validation.RegistrationNo }),
  },
  County: {
    domRef: null,
    text: "County",
    type: "text",
    val: "",
    placeholder: "Please enter County",
    icon: <MapFeaIcon size="16" />,
    validation: Joi.object({ County: validation.County }),
  },
  Address: {
    domRef: null,
    text: "Address",
    type: "text",
    val: "",
    placeholder: "Please enter Address",
    icon: <HomeFeaIcon size="16" />,
    validation: Joi.object({ Address: validation.Address }),
  },
  Phone: {
    domRef: null,
    tel: true,
    text: "Phone",
    type: "tel",
    val: "",
    placeholder: "Please enter practitioners phone Number",
    icon: (
      <ReactCountryFlag
        aria-label="+353"
        countryCode="IE"
        svg
        style={{
          width: "1.6em",
        }}
      />
    ),
    validation: Joi.object({ Phone: validation.Phone }),
  },
  Email: {
    domRef: null,
    email: true,
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter practitioners Email",
    icon: <PhoneFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email }),
  },
};

export const editorCreateButtons = [
  {
    text: "Create",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const editorUpdateButtons = [
  {
    text: "Update",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const scheduleFormSchema = {
  StartTime: {
    domRef: null,
    type: "time-slider",
    text: "Start Time",
    time: { hour: 9, min: 0 },
    ignore: true,
  },
  InterVal: {
    domRef: null,
    type: "slider",
    text: "Duration",
    slide: {
      min: 0,
      max: 60,
      val: 60,
      step: 15,
      unit: "min",
    },
    ignore: true,
  },
  Remove: {
    domRef: null,
    isRequired: false,
    color: colors.errorColor,
    text: "Remove",
    type: "button",
    call: "remove-schedule",
    icon: <DeleteForever size="18" />,
  },
};

export const scheduleCreateButtons = [
  {
    text: "Save",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];

export const scheduleUpdateButtons = [
  {
    text: "Update",
    type: "Submit",
  },
  {
    text: "Cancel",
    type: "Revert",
  },
];
