import localforage from "localforage";

const filesCreate = (eventData) => async (dispatch) => {
  const { File } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== File.PatientId) return;
  let data = {
    ...patient,
    Files: [...patient.Files, File],
  }; 
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const filesDelete = (eventData) => async (dispatch) => {
  const { File } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== File.PatientId) return;
  let data = {
    ...patient,
    Files: patient.Files.filter((n) => n.FileId !== File.FileId),
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

export default {
  create: filesCreate,
  delete: filesDelete,
};
