import {
  handleResponse,
  authHeader
} from "../../library/handler";

export async function getTreatmentById(uuid) {
  return fetch(`${process.env.REACT_APP_APP_API}/treatments/id/${uuid}`)
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
}

export const getTreatments = async (state) => {
  return fetch(`${process.env.REACT_APP_APP_API}/treatments`, {
    method: "get",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const createTreatment = async (state, data) => {
  return fetch(`${process.env.REACT_APP_APP_API}/treatments/create`, {
    method: "post",
    body: JSON.stringify(data),
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};

export const updateTreatment = async (state, data, uuid) => {
  return fetch(
    `${process.env.REACT_APP_APP_API}/treatments/update/${uuid}`,
    {
      method: "put",
      body: JSON.stringify(data),
      headers: await authHeader(state)
    }
  )
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};


export const deleteTreatment= async (state, uuid) => {
  return fetch(`${process.env.REACT_APP_APP_API}/treatments/${uuid}`, {
    method: "delete",
    headers: await authHeader(state)
  })
    .then(response => handleResponse(response))
    .catch(err => console.log("Fetch Error :-S", err));
};
 