import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="246"
      viewBox="0 0 71 246"
    >
      <g id="bottom8" transform="translate(-569.497 -498.367)">
        <g id="bounds">
          <line
            id="bound"
            x2="70"
            transform="translate(569.5 498.367)"
            fill="none"
          ></line>
          <line
            id="bound-2"
            data-name="bound"
            x2="70"
            transform="translate(569.5 744.367)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(579.252 510.332) rotate(-0.974)">
          <path
            id="shape"
            d="M29.351,50.747C7.632,51.293-4.942,39.166,1.74,16.128,4.826,5.49,15.131-2.038,26.692.431c18.43,3.935,10,14.027,13.4,24.445,4.576,14.011,7.665,25.409-10.741,25.872Z"
            transform="translate(50.883 3.429) rotate(90.974)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
            fill-rule="evenodd"
          ></path>
          <path
            id="m"
            d="M12.459,16.849a36.7,36.7,0,0,1-8.18-.667,29.21,29.21,0,0,1-5.945-1.713C-2.893,13.87,2.818,1.037,7.35.1a3.279,3.279,0,0,1,2.787.535c1.9,1.349,2.393,5.284,4.515,7.7,2.838,3.232,9.974,5.136,9.119,5.817-1.87,1.491-6.823,2.58-11.313,2.7Z"
            transform="translate(16.902 19.167) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="ml"
            d="M16.872,4.07c1.257,5.874,1.142,10.656-2.254,13.817.037-.033-.914-.445-3-1.525-.777-.4-4.454-1.774-6.7-4.7a15.181,15.181,0,0,1-1.494-2.72,19.271,19.271,0,0,0-1.3-2.879C.894,4.165,0,4.07,0,4.07A48.027,48.027,0,0,1,4.5.291C6.03-.4,8.37.147,10.8,1.808,13.627,3.738,16.627,2.924,16.872,4.07Z"
            transform="translate(20.698 28.497) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="l"
            d="M9.654,2.041A25.972,25.972,0,0,0,10.7,7.753c.876,2.986,2.5,8.077,2.5,8.077s-.213-.262-1.869-.567a12.722,12.722,0,0,1-3.074-.709c-1.133-.488-2.482-1.611-4.319-2.147a4.827,4.827,0,0,0-3.071,0A13.613,13.613,0,0,1,.02,6.959C.2,3.971-.269,1.84,1.576.456c1.117-.839,2.662-.474,4.819.709S9.613,1,9.654,2.041Z"
            transform="translate(32.791 32.307) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="dl"
            d="M5.878-.174C7.4.847,9.833,2.839,10.609,5.115c.758,2.211.563,4.842.58,7.3.016,1.9.028,3.505-.225,3.524a6.488,6.488,0,0,1-2.33-.1c-.836-.212-2.16-1.182-3.582-1.4a3.09,3.09,0,0,0-2.476.878s-4.758-2.964-4.663-4.373C-1.752,5.852,5.356-.526,5.878-.174Z"
            transform="translate(47.198 31.061) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="d"
            d="M-1.338,3.656C2.376,1.138,9.091-.506,14,.677a75.99,75.99,0,0,1,7.248,2.34A24.876,24.876,0,0,0,15.98,8.232a18.768,18.768,0,0,0-2.6,6.061s-4.632,1.4-8.614-.343c-.594-.254-1.76-.785-3.281-3.318S-2.19,4.233-1.338,3.656Z"
            transform="translate(50.721 15.591) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="db"
            d="M.446,9.755A19.556,19.556,0,0,1,7.62.9c.751-.531,1.144,3.309,2.931,6.058a12.429,12.429,0,0,0,3.808,3.886s-.515,3.654-1.384,4.994C10.219,20.086-.056,10.988.446,9.755Z"
            transform="translate(47.62 6.025) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="b"
            d="M.517,18.267a24.835,24.835,0,0,1,0-8.17A61.08,61.08,0,0,1,3.177.073,20.522,20.522,0,0,0,8.593,5.531a12.3,12.3,0,0,0,5.166,1.9,11.2,11.2,0,0,1,.827,3.9,12.8,12.8,0,0,1-.828,4.072C9.985,18.2.617,19.216.517,18.267Z"
            transform="translate(38.126 3.098) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="mb"
            d="M14.357,19.776s-2.75-1.083-3.035-1.227C5.429,15.567,1.37,10.67.342,2.7a27.133,27.133,0,0,0,6.835-.457A24.028,24.028,0,0,0,13.35,0,19.473,19.473,0,0,1,17.9,2.541,20.379,20.379,0,0,1,21.5,6.4a32.7,32.7,0,0,0-4.736,6.433,26.287,26.287,0,0,0-2.407,6.943Z"
            transform="translate(22.483 3.038) rotate(90.974)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="o"
            d="M5.578,0A36.135,36.135,0,0,0,9.726.725c.83.088,3.6-.818,3.974-.721s.177,1.379,1.74,2.143,4.356.909,4.525,1.365-.313,2.817-.382,4.816.265,2.444,0,3.2-1.122,1.149-2.055,2.111-1.781,2.941-2.334,2.695a13.328,13.328,0,0,0-4.955-1.015c-2.915-.147-6.069.881-6.481.831A19.233,19.233,0,0,1,.786,12.562c-.908-1.507-.451-1.387-.661-2.438A9.757,9.757,0,0,0-.716,7.89c.261-.667,2.963-3.072,4-4.379C4.721,1.706,4.945-.095,5.578,0Z"
            transform="translate(16.74 16.661) rotate(0.974)"
            fill="#eaeaea"
          ></path>
        </g>
        <g id="crown">
          <path
            id="crown-2"
            data-name="crown"
            d="M3852.28,2632.06s-8.5-16.4-5.312-28.1,8.916-18.836,12.6-19.871,5.317-3.157,11.084.983,7.228,3.1,7.228,3.1,6.1-4.5,12.116-6.262,6.175-3.829,12.841,6.262,1.922,25.355,1.922,25.355-5.235,8.332-5.562,11.541-.7,8.228-.7,8.228a184.57,184.57,0,0,1-24.481,1.362,126.888,126.888,0,0,1-21.741-2.6Z"
            transform="translate(-3271.6 -1992.851)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root">
          <path
            id="rb2"
            d="M3931.66,2854.96a10.4,10.4,0,0,0,.66,1.441c.794,1.544,2.211,6.666.868,24.071-2.1,27.28-21.525,50.9-28.768,52.633-.948.227-3.877.939-5.063,0s-1.674-2.81-1.731-4.485c-.037-1.08.42-2.188-.224-2.346-.352-.087-.244.483-.427.989-.551,1.509,2.248-71.39,9.432-70.976.547.031,1.4.074,2.25.11a156.549,156.549,0,0,0,23-1.437Z"
            transform="translate(-3304.873 -2214.517)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="rb1"
            d="M3901.77,2904.7a130.061,130.061,0,0,1-4.8,22.564c-.551,1.509-.868,4.975-3.851,4.3-3.855-.865-3.388-5.917-3.155-21.073s-3.621-15.733-4.322-26.558-.052-30.147-.052-30.147a165.256,165.256,0,0,0,16.384,2.191c1.255.107,2.833.219,4.428.311a163.508,163.508,0,0,0-4.636,26.632C3901.06,2891.97,3902.97,2893.61,3901.77,2904.7Z"
            transform="translate(-3304.873 -2214.517)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r3"
            d="M3906.52,2858.75c.662,1.967,1.821,15.42-4.289,29.064s-14.869,24.724-15.843,23.6,8.013-13.974,11.948-28.079,1.447-24.59,1.447-24.59.272-1.4,2.447-.84S3905.86,2856.79,3906.52,2858.75Z"
            transform="translate(-3282.402 -2205.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r2"
            d="M3905.38,2858.69c.662,1.967-2.366,20.9-8.476,34.541s-5.718,21.193-6.691,20.075.653-17.377,4.588-31.482,2.716-22.241,3.843-23.134S3904.72,2856.73,3905.38,2858.69Z"
            transform="translate(-3291.777 -2204.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M3892.17,2857.82c.125,1.066-1.114,3.478-1.325,15.492-.125,7.081,1.849,13.02,1.325,20.132-.817,11.093-4.38,20.422-5.551,20.795-.947.3.061-10.106,0-19.552-.056-8.7-3.094-16.847-3.148-20.546-.11-7.473-1.145-17.788,0-18.166a6.627,6.627,0,0,1,4.474-.385C3890.12,2856.15,3892.13,2857.5,3892.17,2857.82Z"
            transform="translate(-3298.873 -2204.161)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(586.654 540.089)">
            <g id="abscess" transform="translate(-14.154 171.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(36.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g
              id="cracked"
              transform="matrix(-0.766, 0.643, -0.643, -0.766, 34.176, 68.549)"
            >
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession">
              <path
                id="p3"
                d="M1107.3,675.785a82.5,82.5,0,0,0,25.639,5.442c14.584.723,32.7-2.549,32.7-2.549"
                transform="translate(-1118.654 -577.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(573 593.784)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(587.507 604.485)">
              <path
                id="path1"
                d="M3393.81,507.548s-.97-2.876-1.573-11.862,4.771-18.861-.245-21.895-5.76,3-10.347,3.553c-3.594.431-13.093-4.862-15.894-1.785-3.709,4.064-1.029,8.307,0,17.05a52.585,52.585,0,0,1-.033,14.41,54.008,54.008,0,0,0,15.927,1.839C3391.37,508.47,3393.81,507.548,3393.81,507.548Z"
                transform="translate(-3360.682 -470.566)"
                fill="red"
              ></path>
            </g>
            <g id="resection" transform="translate(-79.778 7)">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982l41.923,51.806"
                transform="translate(654.314 591.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="seal" transform="translate(-79.778)">
              <path
                id="seal-2"
                data-name="seal"
                d="M750.875,514.944s-30.363,3.852-30.363,9.139c0,2.8,10.173,5.6,18.278,7.4,6.784,1.508,11.57,2,12.085,4.607,1.1,5.551-25.982,10.2-25.982,10.2"
                transform="translate(-52.44 4.764)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="implant" transform="translate(588.507 627.499)">
              <path
                id="p5"
                d="M806.424,410.878l-1.793-15.719s7.166-8.37,12.64-11.2,3.523-2.77,9.136,0,13.258,10.96,13.258,10.96l-.153,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M809.01,384.517l-2.138-10.682,31.8-8.6-1.81,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3-2"
                data-name="p3"
                d="M810.643,366.894l-1.814-10.675,27.966-7.444L835.27,360.31Z"
                transform="translate(-804.92 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M812.375,348.918l-1.4-10.534,24-5.9-1.92,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-4"
                data-name="p1"
                d="M825.673,307.555l-12.922,4.7s.409,19.875,6.7,18.857S825.673,307.555,825.673,307.555Z"
                transform="translate(-801.474 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(572.997 441.784)">
              <path
                id="Path_2"
                data-name="Path 2"
                d="M0,0H71"
                transform="translate(-3.5 174.896)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M0,0H71"
                transform="translate(-3.5 91.716)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(617.882 675.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(584.244 621.874)">
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2-2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
