import React from "react";
import {
  AppBar,
  Toolbar,
  ToggleButton,
  ToggleButtonGroup,
  Badge,
  styled,
  alpha,
} from "@mui/material";
import colors from "library/styled-components/colors";
import ChatBubbleOutline from "@mui/icons-material/ChatBubbleOutline";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import StarIcon from "@mui/icons-material/Star";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  width: "100%",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const FilterToolbar = ({
  unreadCount,
  setSelectedFilter,
  selectedFilter,
  search,
  setSearch,
}) => {
  const handleFilterChange = (event, newFilter) => {
    setSelectedFilter(newFilter);
  };

  // Custom Styled ToggleButton
  const StyledToggleButton = styled(ToggleButton)(({ theme, selected }) => ({
    backgroundColor: selected
      ? theme.palette.secondary.main
      : theme.palette.primary.main,
    color: selected
      ? theme.palette.secondary.contrastText
      : theme.palette.primary.contrastText,
    "&:hover": {
      backgroundColor: selected
        ? theme.palette.secondary.dark
        : theme.palette.primary.dark,
    },
  }));

  return (
    <AppBar position="static">
      <Toolbar
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginRight: "0.5rem" 
        }}
      >
        {/* Filter Buttons */}

        <ToggleButtonGroup
          value={selectedFilter}
          exclusive
          onChange={handleFilterChange}
          aria-label="message filters"
        >
          {/* Each Filter Button */}
          {["all", "unread", "important"].map((filter) => (
            <StyledToggleButton
              key={filter}
              value={filter}
              selected={selectedFilter === filter}
              sx={{
                borderRadius: 1,
                margin: 1,
                padding: 1,
                minWidth: 0,
                width: 40,
                height: 40,
                "&.Mui-selected": {
                  backgroundColor: colors.accentColor,
                },
                "&.Mui-selected:hover": {
                  backgroundColor: colors.accentColor,
                },
              }}
              aria-label={`${filter} messages`}
            >
              {filter === "unread" && (
                <Badge badgeContent={unreadCount} color="secondary">
                  <ChatBubbleOutline />
                </Badge>
              )}
              {filter === "all" && <AllInboxIcon />}
              {filter === "important" && <StarIcon />}
            </StyledToggleButton>
          ))}
        </ToggleButtonGroup>

        {/* Toggle Search Field Button */}
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            inputProps={{ "aria-label": "search" }}
          />
        </Search>
      </Toolbar>
    </AppBar>
  );
};

export default FilterToolbar;
