import {
  getPayments,
  getCharges,
  createCharge,
  createUnFixPayment,
} from "../../../../../actions/payments";
import { ADMIN_NS } from "../../../../../config";

export const getPaymentsList = (input) => async (dispatch, navigateTo) => {
  const data = await getPayments(ADMIN_NS, input);
  data &&
    data.code === "EXPIRED" &&
    dispatch({
      type: "REPLACE",
      context: "user",
      data: null,
      updating: false,
    }) &&
    navigateTo("/admin/access/login");

  data &&
    data.code === "SUCCESS" &&
    dispatch({
      type: "LOAD",
      context: "payments",
      data: data.data,
      updating: false,
    });
};

export const getChargesList = (input) => async (dispatch, navigateTo) => {
  const data = await getCharges(ADMIN_NS, input);
  data &&
    data.code === "EXPIRED" &&
    dispatch({
      type: "REPLACE",
      context: "user",
      data: null,
      updating: false,
    }) &&
    navigateTo("/admin/access/login");

  data &&
    data.code === "SUCCESS" &&
    dispatch({
      type: "LOAD",
      context: "charges",
      data: data.data,
      updating: false,
    });
};

export const payUnfixed = (data) => async (dispatch) => {
  const response = await createUnFixPayment(ADMIN_NS, data);
  response &&
    response.code === "SUCCESS" &&
    dispatch({
      context: "charges",
      type: "UPDATE_ITEM",
      updating: false,
      data: response.data,
      uuid: { ChargeId: response.data.ChargeId },
    });
  return response;
};

export const chargePatient = (data) => async (dispatch) => {
  const response = await createCharge(ADMIN_NS, data);
  response &&
    response.code === "SUCCESS" &&
    dispatch({
      context: "charges",
      type: "ADD",
      updating: false,
      data: response.data,
    });
};
