import React from "react";
import { format } from "date-fns";
import { Typography } from "@mui/material";
import {
  DoneAll as DoneAllIcon,
  ErrorOutline as ErrorOutlineIcon,
  Done as DoneIcon,
} from "@mui/icons-material";
import colors from "library/styled-components/colors";
import { WrapMessage, Message, Time } from "./styles";

const SmsMessageBlock = ({ message, hideTime = false }) => {
  return (
    <WrapMessage key={message.Sid}>
      {!hideTime ? (
        <Time style={{ fontSize: "0.8rem" }}>
          {message.updatedAt &&
            format(new Date(message?.updatedAt), "do MMMM yyyy, h:mm a")}
        </Time>
      ) : null}
      <Message type={message.TypeId}>
        <Typography style={{ whiteSpace: "pre-line" }}>
          {message.Text}
        </Typography>
        {message?.Data?.MessageStatus === "failed" ||
        message?.Data?.MessageStatus === "undelivered" ? (
          <div
            style={{
              position: "absolute",
              bottom: "0.2rem",
              right: "0.5rem",
            }}
          >
            <ErrorOutlineIcon style={{ color: colors.red }} fontSize="small" />
          </div>
        ) : message?.Data?.MessageStatus === "delivered" ? (
          <div
            style={{
              position: "absolute",
              bottom: "0.2rem",
              right: "0.5rem",
            }}
          >
            <DoneAllIcon style={{ color: colors.white }} fontSize="small" />
          </div>
        ) : (
          message?.Data?.MessageStatus === "sent" && (
            <div
              style={{
                position: "absolute",
                bottom: "0.2rem",
                right: "0.5rem",
              }}
            >
              <DoneIcon style={{ color: colors.white }} fontSize="small" />
            </div>
          )
        )}
      </Message>
    </WrapMessage>
  );
};

export default SmsMessageBlock;
