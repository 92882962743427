import React from "react";
import * as Joi from "@hapi/joi";
import UserFeaIcon from "../../../icon/fea-icons/user";
import ReactCountryFlag from "react-country-flag";
import MailFeaIcon from "../../../icon/fea-icons/mail";
import LockFeaIcon from "../../../icon/fea-icons/lock";
import ClockFeaIcon from "../../../icon/fea-icons/clock";
import CalFeaIcon from "../../../icon/fea-icons/calendar";

const validation = {
  Name: Joi.string().min(5).max(90).required(),
  Phone: Joi.number().min(8).required(),
  Email: Joi.string()
    .min(5)
    .max(255)
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    }),
  Password: Joi.string().min(10).max(255).required(),
  Practitioners: Joi.string().min(1).max(255).required(),
  Accept: Joi.string().min(7).max(7).required(),
};

export const formSchema = {
  Name: {
    key: 1,
    domRef: null,
    type: "text",
    val: "",
    text: "Name",
    placeholder: "Please enter your name",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Name: validation.Name }),
  },
  Phone: {
    key: 2,
    domRef: null,
    tel: true,
    text: "Phone",
    type: "tel",
    placeholder: "Please enter your mobile phone Number",
    icon: (
      <ReactCountryFlag
        aria-label="+353"
        countryCode="IE"
        svg
        style={{
          width: "1.6em", 
        }}
      />
    ),
    validation: Joi.object({ Phone: validation.Phone }),
  },
  Email: {
    key: 3,
    domRef: null,
    email: true,
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter your Email address",
    icon: <MailFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email }),
  },
  Password: {
    key: 4,
    domRef: null,
    password: true,
    text: "Password",
    type: "password",
    val: "",
    placeholder: "Please enter a secure password",
    icon: <LockFeaIcon size="16" />,
    validation: Joi.object({ Password: validation.Password }),
  },
  Date: {
    key: 5,
    domRef: null,
    type: "date-picker",
    text: "Appointment Date",
    placeholder: "Your appointment Date",
    ignore: true,
    icon: <CalFeaIcon size="16" />,
  },
  StartTime: {
    key: 6,
    domRef: null,
    type: "time-picker",
    text: "Appointment Time",
    placeholder: "Your appointment Time",
    val: "09:00",
    ignore: false,
    icon: <ClockFeaIcon size="16" />,
  },
  Practitioners: {
    key: 7,
    domRef: null,
    isRequired: true,
    text: "Practitioners",
    type: "select",
    options: [],
    defaultValue: "Who are you seeing today?",
    call: "select-practitioner",
    icon: <UserFeaIcon size="16" />,
    validation: Joi.object({ Practitioners: validation.Practitioners }),
  },
  Accept: {
    domRef: null,
    text: "I confirm that I have read the Practice Privacy Statement for Dentiz. I agree to OPT IN to the terms and conditions as outlined in the statement which include the practice contacting me via phone, email or SMS. I understand that I may revoke this consent at any time.",
    link: "Privacy Policy",
    type: "checkbox",
    call: "show-terms",
    val: "",
    validation: Joi.object({ Accept: validation.Accept }),
  },
};

export const createButtons = [
  {
    text: "Register",
    type: "Submit",
  },
];
