import styled, { keyframes, css } from "styled-components";
import colors from "../../library/styled-components/colors";

export const Container = styled.div`
  overflow-x: scroll;
  display: grid;
  background-color: ${colors.secColor};
  align-items: flex-start;
  grid-template-areas:
    "standard-header"
    "standard-content";
`;

export const Header = styled.div`
  display: grid;
  background: ${colors.primary};
  -webkit-box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  box-shadow: 0 0 3px rgba(60, 72, 88, 0.15);
  transition: all 0.5s ease;
  min-height: 74px;
  text-align: center;
  color: ${colors.primary};
  grid-area: standard-header;
  height: 3rem;
  a {
    color: ${colors.primary};
    margin: 0.6em;
  }
`;

export const HeaderWrap = styled.div`
  display: grid;
  align-self: center;
  justify-self: center;
  grid-template-columns: ${(props) =>
    props.home ? `4.6em auto 4.6em` : `10em auto 4.6em`};
  width: 100%;
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

export const Content = styled.div`
  display: grid;
  grid-area: standard-content;
  align-items: center;
  justify-self: center;
  height: 100vh;
  align-items: flex-start;
`;

export const ContentWrapper = styled.div`
  height: 100vh;
  @media (min-width: 481px) {
  }
`;
