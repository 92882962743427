import localforage from "localforage";

const notesUpdate = (eventData) => async (dispatch) => {
  const { Note } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Note.PatientId) return;
  let data = {
    ...patient,
    Notes: patient.Notes.map((n) => {
      if (n.NoteId === Note.NoteId) {
        return Note;
      }
      return n;
    }),
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const notesCreate = (eventData) => async (dispatch) => {
  const { Note } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Note.PatientId) return;
  let data = {
    ...patient,
    Notes: [...patient.Notes, Note],
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

const notesDelete = (eventData) => async (dispatch) => {
  const { Note } = eventData;
  const patient = await localforage.getItem("patient");
  if (patient?.PatientId !== Note.PatientId) return;
  let data = {
    ...patient,
    Notes: patient.Notes.filter((n) => n.NoteId !== Note.NoteId),
  };
  dispatch({
    type: "LOAD_ONE",
    context: "patient",
    data,
  });
  await localforage.setItem("patient", data);
};

export default {
  create: notesCreate,
  update: notesUpdate,
  delete: notesDelete,
};
