import React, { useContext, useState, useMemo, useLayoutEffect } from "react";
import {
  IconButton,
  Snackbar,
  Dialog,
  DialogContent,
  Slide,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import DentChart from "../../../../components/dent-chart";
import { AppContext } from "../../../../hooks/context";
import DoubleBounce from "../../../../components/loaders/double-bounce";
import colors from "../../../../library/styled-components/colors";
import OverviewTabs from "./components/overview/Tabs";
import CategoryList from "./components/treaments/CategoryList";
import AppointmentsList from "./components/treaments/AppointmentsList";
import ProceedureList from "./components/treaments/ProceedureList";
import CloseIcon from "@mui/icons-material/Close";
import { ADMIN_NS } from "config";
import {
  getPatientAppointmentsById,
  getPatientBasicById,
  getPatientInvoicesById,
  getPatientNotesById,
} from "actions/patients";
import CreateAppointment from "./components/preview/CreateAppointment";
import AppointmentCharge from "components/calendar/components/AppointmentCharge/AppointmentCharge";
import { transformAppointment } from "library/calendar";
import { addMonths, startOfHour } from "date-fns";
import {
  addProceedure,
  removeProceedures,
  updatePatientAppointment,
  updateProceedure,
} from "actions/appointments";
import { OverlayBackGround } from "layouts/lib/common";
import TreatmentListDrawer from "./components/treaments/TreatmentListDrawer";
import { createNote } from "actions/consultations";
import { clearItem, getItem, updateItem } from "library/resources";
import { getDocuments } from "actions/practices";
import PatientForm from "../calendar/components/event-blocks/appointment-event/PatientForm";

const Container = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
`;

const LeftPanel = styled.div`
  height: 100%;
  flex-direction: column;
  display: flex;
  overflow: auto;
  border-right: 1px solid ${colors.rgbGreyDark};
  width: 24rem;
`;

const Center = styled.div`
  flex-grow: 1;
  height: 100%;
  background: white;
  z-index: 0;
`;

const RightPanel = styled.div`
  height: 100%;
  overflow: auto;
  border-left: 1px solid ${colors.rgbGreyDark};
`;

const Name = styled.div`
  margin: 1rem 0 0 0;
`;

const NameWrapper = styled.div`
  display: flex;
  color: white;
`;

const GroupBtns = styled.div`
  margin-left: 1rem;
  margin-top: 0.5rem;
  button {
    margin-right: 0.5rem;
  }
`;

const transformEditorData = ({
  practitionerId,
  patientId,
  treatmentId,
  start,
  end,
  meta,
  sendSms = false,
  sendMail = false,
}) => {
  return {
    PractitionerId: {
      val: practitionerId,
    },
    PatientId: {
      val: patientId,
    },
    TreatmentId: {
      val: treatmentId,
    },
    Start: { val: start },
    End: { val: end },
    Meta: {
      val: meta,
    },
    SendSms: {
      val: sendSms,
    },
    SendMail: {
      val: sendMail,
    },
  };
};

const fetchPatientData = async (ADMIN_NS, id) => {
  let patient = await getPatientBasicById(ADMIN_NS, id);
  const data = {
    ...patient?.data,
    Appointments: await getPatientAppointmentsById(ADMIN_NS, id).then(
      (r) => r.data
    ),
    Invoices: await getPatientInvoicesById(ADMIN_NS, id).then((r) => r.data),
    Notes: await getPatientNotesById(ADMIN_NS, id).then((r) => r.data),
    Files: await getDocuments(ADMIN_NS, id).then((r) => r.data),
  };
  return {
    code: "SUCCESS",
    data,
  };
};

const loadData = (id) => async (dispatch) => {
  await clearItem("patient");
  await getItem("patient", fetchPatientData, id)(dispatch);
};

const PatientDialog = ({
  user,
  show = false,
  close,
  appointmentExtendedProps,
  nav = null,
}) => {
  let history = useHistory();
  const { state, dispatch } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [fetching, setFetching] = useState(true);
  const [menuState, setMenuState] = useState(0);
  const [selectedAppointment, setSelectedAppointment] = useState(
    appointmentExtendedProps.Appointmentid
  );
  const [selectedInvoice, setSelectedInvoice] = useState();
  const [chartState, setChartState] = useState();
  const [warning, setWarning] = useState(null);
  const [list, setList] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [invoiceProceedures, setInvoiceProceedures] = useState([]);
  const [prePop, setPrePop] = useState(null);

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  React.useEffect(() => {
    const unblock = history.block((location, action) => {
      if (action === "POP") {
        // Return true to allow transition, false to block
        close();
        return false;
      }
    });

    return () => {
      unblock();
    };
  }, []);

  const load = async (id, nav) => {
    setFetching(true);
    await loadData(id)(dispatch);
    setFetching(false);
    setLoading(false);
  };

  useLayoutEffect(() => {
    appointmentExtendedProps.PatientId &&
      load(appointmentExtendedProps.PatientId, nav);
  }, []);

  const patient = useMemo(() => state.patient?.data, [state.patient?.data]);

  async function updateSelectedAppointment(id) {
    setSelectedAppointment(id);
  }

  const appointmentData = useMemo(
    () =>
      patient?.Appointments &&
      transformAppointment(
        patient?.Appointments.find(
          (a) => a.AppointmentId === selectedAppointment
        )
      ),
    [patient?.Appointments, selectedAppointment]
  );

  const invoiceData = useMemo(
    () =>
      patient && {
        id: patient?.Invoices?.length || 1,
        PatientId: patient?.PatientId,
        PractitionerId:
          localStorage.getItem("selectedPractitionerId") ||
          state.list.data[1]?.data?.PractitionerId,
        PracticeId: patient.Practice?.PracticeId,
        Proceedures: invoiceProceedures,
        Invoice: selectedInvoice
          ? patient?.Invoices.find((a) => a.InvoiceId === selectedInvoice)
          : null,
      },
    [patient, selectedInvoice]
  );

  async function createProceedure(proceedures, validate = false) {
    if (validate && !chartState?.filter((c) => c.selected.length).length) {
      setWarning("Select treatment area to apply proceedure");
      return;
    }
    setLoading(true);
    const data = proceedures.map((treatment) => ({
      AppointmentId: { val: selectedAppointment },
      TreatmentId: { val: treatment.data.TreatmentId },
      PatientId: { val: patient.PatientId },
      Data: {
        val: {
          quantity: treatment.qty,
          status: treatment.status,
          areas: chartState?.filter((c) => c.selected.length),
        },
      },
      Price: { val: treatment.data.Price * treatment.qty },
    }));

    const response = await addProceedure(
      ADMIN_NS,
      data,
      selectedAppointment,
      patient.Practice.PracticeId
    );

    response &&
      response.code === "SUCCESS" &&
      dispatch({
        type: "LOAD_ONE",
        context: "patient",
        data: {
          ...patient,
          Appointments: patient.Appointments.map((a) =>
            a.AppointmentId === selectedAppointment
              ? {
                  ...a,
                  Proceedures: [...(a.Proceedures || []), ...response.data],
                }
              : a
          ),
        },
        updating: false,
      });

    if (!selectedAppointment) setInvoiceProceedures(response.data);

    setLoading(false);
    setWarning(null);
    setList(null);
  }

  return (
    <Dialog
      open={show}
      onClose={close}
      fullScreen={true}
      maxWidth={"lg"}
      //TransitionComponent={Transition}
      style={{ padding: "0px 0px 0px 0px" }}
    >
      <DialogContent style={{ padding: "0px 0px 0px 0px" }}>
        {loading || fetching || !patient ? (
          <OverlayBackGround status={loading ? 1 : 0}>
            <DoubleBounce margin="25%" />
          </OverlayBackGround>
        ) : (
          <Container>
            <LeftPanel>
              <div
                style={{
                  margin: "1rem 0",
                  padding: "0 1rem",
                }}
              >
                <h4>Update Patient</h4>
                {menuState === 0 && patient?.User && (
                  <PatientForm
                    extendedProps={{ Patient: patient }}
                    close={close}
                  />
                )}
                {menuState === 1 && (
                  <AppointmentsList
                    selected={selectedAppointment}
                    setSelected={updateSelectedAppointment}
                    change={updateSelectedAppointment}
                  ></AppointmentsList>
                )}
              </div>
            </LeftPanel>
            <Center>
              {menuState === 0 && (
                <OverviewTabs
                  user={user}
                  close={close}
                  patient={patient}
                  setSelectedAppointment={updateSelectedAppointment}
                  setComplete={(toggle) => {
                    setInvoice(toggle);
                  }}
                  showOnlyInvoice={(toggle, id) => {
                    setInvoice(toggle);
                    setSelectedAppointment(null);
                    if (id) {
                      setSelectedInvoice(id);
                    } else {
                      setSelectedInvoice(false);
                    }
                  }}
                  setShowPreview={() => {
                    setShowPreview(true);
                    setSelectedAppointment(null);
                    setPrePop({
                      Meta: null,
                    });
                  }}
                  setShowRecallPreview={() => {
                    setShowPreview(true);
                    setPrePop({
                      Meta: { Recall: 6 },
                      Date: addMonths(startOfHour(new Date()), 6),
                      Start: addMonths(startOfHour(new Date()), 6),
                      End: addMonths(startOfHour(new Date()), 6),
                    });
                    setSelectedAppointment(null);
                  }}
                  createNote={async (note) => {
                    const response = await createNote(ADMIN_NS, {
                      Note: note,
                      PatientId: patient.PatientId,
                    });
                    if (response && response.code === "SUCCESS") {
                      dispatch({
                        type: "LOAD_ONE",
                        context: "patient",
                        updating: false,
                        data: {
                          ...patient,
                          Notes: [...(patient.Notes || []), response.data],
                        },
                      });
                    }
                  }}
                  setLoading={setLoading}
                />
              )}
              {menuState === 1 && (
                <>
                  <DentChart
                    chartState={chartState}
                    patient={patient}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={updateSelectedAppointment}
                    setChartState={setChartState}
                  ></DentChart>
                  <ProceedureList
                    patient={patient}
                    selectedAppointment={selectedAppointment}
                    setInvoice={(toggle) => {
                      setInvoice(toggle);
                    }}
                    updateAppointment={async (rawData) => {
                      setLoading(true);
                      const data = transformEditorData(rawData);
                      const response = await updatePatientAppointment(
                        ADMIN_NS,
                        data,
                        selectedAppointment
                      );
                      if (response?.code === "SUCCESS")
                        await updateItem("patient", {
                          ...patient,
                          Appointments: patient.Appointments.map((app) => {
                            if (
                              app.AppointmentId === response.data.AppointmentId
                            ) {
                              return {
                                ...app,
                                ...response.data,
                              };
                            }
                            return app;
                          }),
                        })(dispatch);
                      setLoading(false);
                    }}
                    updateProceedure={async (change) => {
                      setLoading(true);
                      const response = await updateProceedure(
                        ADMIN_NS,
                        change,
                        change.ProceedureId,
                        selectedAppointment,
                        patient.Practice.PracticeId
                      );
                      if (response && response.code === "SUCCESS") {
                        dispatch({
                          type: "LOAD_ONE",
                          context: "patient",
                          updating: false,
                          data: {
                            ...patient,
                            Appointments: patient.Appointments.map((a) =>
                              a.AppointmentId === response.data.AppointmentId
                                ? {
                                    ...a,
                                    Proceedures: a.Proceedures.map((p) =>
                                      p.ProceedureId ===
                                      response.data.ProceedureId
                                        ? response.data
                                        : p
                                    ),
                                  }
                                : a
                            ),
                          },
                        });
                      }
                      setLoading(false);
                    }}
                    removeProceedures={async (ids) => {
                      setLoading(true);
                      const response = await removeProceedures(
                        ADMIN_NS,
                        { ids },
                        selectedAppointment,
                        patient.PracticeId
                      );
                      if (response && response.code === "SUCCESS") {
                        dispatch({
                          type: "LOAD_ONE",
                          context: "patient",
                          updating: false,
                          data: {
                            ...patient,
                            Appointments: patient.Appointments.map((a) =>
                              a.AppointmentId === selectedAppointment
                                ? {
                                    ...a,
                                    Proceedures: a.Proceedures.filter(
                                      (p) => !response.data.includes(p.id)
                                    ),
                                  }
                                : a
                            ),
                          },
                        });
                      }
                      setLoading(false);
                    }}
                  />
                </>
              )}
            </Center>
            {menuState === 1 && (
              <RightPanel>
                <CategoryList
                  list={list}
                  setList={setList}
                  practitionerId={appointmentData?.PractitionerId}
                  addProceedure={createProceedure}
                  changePractitioner={async (e) => {
                    setLoading(true);
                    const data = {
                      PractitionerId: { val: e.target.value },
                    };
                    const response = await updatePatientAppointment(
                      ADMIN_NS,
                      data,
                      selectedAppointment
                    );
                    if (response?.code === "SUCCESS")
                      await updateItem("patient", {
                        ...patient,
                        Appointments: patient.Appointments.map((app) => {
                          if (
                            app.AppointmentId === response.data.AppointmentId
                          ) {
                            return {
                              ...app,
                              ...response.data,
                            };
                          }
                          return app;
                        }),
                      })(dispatch);
                    setLoading(false);
                  }}
                />
              </RightPanel>
            )}
            <Snackbar
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              open={!!warning}
              autoHideDuration={6000}
              onClose={() => {
                setWarning(null);
                setList(null);
              }}
              message={warning}
              action={
                <React.Fragment>
                  <IconButton
                    size="small"
                    aria-label="close"
                    color="inherit"
                    onClick={() => {
                      setWarning(null);
                      setList(null);
                    }}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </React.Fragment>
              }
            />
            <CreateAppointment
              show={showPreview}
              formProps={prePop}
              appointmentData={appointmentData}
              handleClose={() => {
                setShowPreview(false);
              }}
            />

            <AppointmentCharge
              show={invoice}
              isAppointment={!!appointmentData}
              invoiceData={appointmentData || invoiceData}
              selectedUser={patient?.User}
              selectedPractice={patient?.Practice}
              close={() => {
                setInvoice(false);
                setSelectedInvoice(false);
                setInvoiceProceedures([]);
              }}
            ></AppointmentCharge>
          </Container>
        )}

        <TreatmentListDrawer
          open={!!list}
          status={2}
          list={state.treatments.data}
          closeList={() => setList(false)}
          addProceedure={(proceedures, validate) => {
            createProceedure(proceedures, validate);
          }}
        />
      </DialogContent>
    </Dialog>
  );
};

export default PatientDialog;
