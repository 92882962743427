import {
  createTreatment,
  updateTreatment,
  deleteTreatment,
} from "../../../../../actions/treatments";
import { ADMIN_NS } from "../../../../../config";

const handleResponse =
  (response, id = null) =>
  async (dispatch, navigateTo) => {
    if (response && response.code === "EXPIRED") {
      dispatch({
        type: "REPLACE",
        context: "user",
        data: null,
        updating: false,
      });
      navigateTo("/admin/access/login");
    }

    !!id &&
      response &&
      response.code === "SUCCESS" &&
      (dispatch({
        context: "treatments",
        type: "UPDATE_ITEM",
        updating: false,
        data: response.data,
        uuid: { TreatmentId: id },
      }));

    !id &&
      response &&
      response.code === "SUCCESS" &&
      (dispatch({
        type: "ADD",
        context: "treatments",
        data: response.data,
      }));

    response &&
      response.code == "INVALID_PHONE" &&
      (dispatch({
        type: "UPDATE",
        context: "treatments",
        meta: {
          updating: false,
          warning: "Phone number is invalid or unsupported!",
        },
      }));

    response &&
      response.code == "ALREADY_REGISTERED" &&
      (dispatch({
        type: "UPDATE",
        context: "treatments",
        meta: {
          updating: false,
          warning: "Email already exists",
        },
      }));

    response &&
      response.code == "INVALID" &&
      (dispatch({
        type: "UPDATE",
        context: "treatments",
        meta: {
          updating: false,
          warning: "Data is invalid!",
        },
      }));

    response &&
      response.code == "ERROR" &&
      (dispatch({
        type: "UPDATE",
        context: "treatments",
        meta: {
          updating: false,
          error: "something went wrong",
        },
      }));

    return response;
  };

export const editData = (data, uuid) => async (dispatch, navigateTo) => {
  if (uuid) {
    const response = await updateTreatment(ADMIN_NS, data, uuid);
    return await handleResponse(response, uuid)(dispatch, navigateTo);
  } else {
    const response = await createTreatment(ADMIN_NS, data, uuid);
    return await handleResponse(response, uuid)(dispatch, navigateTo);
  }
};

export const removeTreatment = (uuid) => async (dispatch) => {
  const data = await deleteTreatment(ADMIN_NS, uuid);
  data &&
    data.code === "SUCCESS" &&
    (dispatch({
      type: "REMOVE_ITEM",
      context: "treatments",
      uuid: { TreatmentId: uuid },
      updating: false,
    }));
};
