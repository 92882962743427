import React, { useState, useRef } from "react";
import { ADMIN_NS } from "config";
import ChevRightFeaIcon from "icon/fea-icons/chevron-right";
import InfoPanel from "layouts/components/info";
import { SubmitIconBtn } from "components/common";
import LinearProgress from "@mui/material/LinearProgress";
import { Button } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { filesUpload } from "actions/patients";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";

const FileUpload = ({ patientId, practiceId, user, noFiles = false }) => {
  const [loaded, setLoaded] = useState(100);
  const inputFile = useRef(null);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      const data = new FormData();
      data.append("file", files[0]);
      filesUpload(
        ADMIN_NS,
        data,
        { ...user, PatientId: patientId, PracticeId: practiceId },
        (ProgressEvent) => {
          setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
        }
      )
        .then((response) => {
          setLoaded(100);
        })
        .catch((err) => {
          console.error(err);
          // then print response status
          console.error("upload fail");
        });
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <>
      <input
        style={{ display: "none" }}
        // accept=".zip,.rar"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
      />
      {noFiles ? (
        <InfoPanel>
          <h1>No Files</h1>
          <p>
            You need to click below to create a file or drop a file for
            <strong>&nbsp;{user?.Name}</strong>
          </p>

          <SubmitIconBtn onClick={onButtonClick} center={true}>
            Upload File <ChevRightFeaIcon size="22" />
          </SubmitIconBtn>
        </InfoPanel>
      ) : (
        <Button
          onClick={onButtonClick}
          color={"primary"}
          variant="contained"
          style={{
            margin: "0.5rem",
            padding: "0.5rem",
          }}
        >
          {loaded < 100 ? (
            <DoubleBounce color={colors.white} />
          ) : (
            <AddCircleIcon style={{ width: "18px", height: "18px" }} />
          )}
        </Button>
      )}
      {loaded < 100 && (
        <div
          style={{
            position: "absolute",
            width: "calc(100% - 6rem)",
          }}
        >
          <LinearProgress
            variant="buffer"
            value={loaded}
            valueBuffer={loaded + 10}
          />
        </div>
      )}
    </>
  );
};

export default FileUpload;
