import { getGraphData } from "../../../../../actions/practices";
import { ADMIN_NS } from "../../../../../config";

const getGraph = () => async (dispatch, navigateTo) => {
  //let storeState = store.get(ADMIN_NS);
  //if (!!storeState.graph) return storeState.graph;
  dispatch({
    type: "UPDATE",
    context: "graph",
    meta: { updating: true },
  });
  const data = await getGraphData(ADMIN_NS);
  //await store.set(ADMIN_NS, { ...storeState, graph: data });
  data &&
    data.code === "EXPIRED" &&
    (dispatch({
      type: "REPLACE",
      context: "user",
      data: null,
      updating: false,
    })) &&
    navigateTo("/admin/access/login");

  return data;
};


export const fetchGraph = () => async (dispatch, navigateTo) => {
  return await getGraph()(dispatch, navigateTo);
};
