import one from "./img/teeth/table1/18.png";
import oneImplant from "./img/teeth/table1/implants/18.png";
import oneRemoved from "./img/teeth/table1/removed/18.png";

import two from "./img/teeth/table1/17.png";
import twoImplant from "./img/teeth/table1/implants/17.png";
import twoRemoved from "./img/teeth/table1/removed/17.png";

import three from "./img/teeth/table1/16.png";
import threeImplant from "./img/teeth/table1/implants/16.png";
import threeRemoved from "./img/teeth/table1/removed/16.png";

import four from "./img/teeth/table1/15.png";
import fourImplant from "./img/teeth/table1/implants/15.png";
import fourRemoved from "./img/teeth/table1/removed/15.png";

import five from "./img/teeth/table1/14.png";
import fiveImplant from "./img/teeth/table1/implants/14.png";
import fiveRemoved from "./img/teeth/table1/removed/14.png";

import six from "./img/teeth/table1/13.png";
import sixImplant from "./img/teeth/table1/implants/13.png";
import sixRemoved from "./img/teeth/table1/removed/13.png";

import seven from "./img/teeth/table1/12.png";
import sevenImplant from "./img/teeth/table1/implants/12.png";
import sevenRemoved from "./img/teeth/table1/removed/12.png";

import eight from "./img/teeth/table1/11.png";
import eightImplant from "./img/teeth/table1/implants/11.png";
import eightRemoved from "./img/teeth/table1/removed/11.png";

export const oneState = {
  margin: [
    [7, 97],
    [27.5, 97],
    [48, 97],

    [62, 97],
    [82.5, 97],
    [102, 97],

    [114.5, 97],
    [142.5, 97],
    [167.5, 97],

    [180, 97],
    [195, 97],
    [210, 97],

    [223, 97],
    [238, 97],
    [253, 97],

    [267.5, 97],
    [280, 97],
    [294, 97],

    [312.5, 97],
    [324, 97],
    [337, 97],

    [355, 97],
    [372, 97],
    [387.5, 97],
  ],
  depth: [
    [7, 97],
    [27.5, 97],
    [48, 97],
    
    [62, 97],
    [82.5, 97],
    [102, 97],

    [114.5, 97],
    [142.5, 97],
    [167.5, 97],

    [180, 97],
    [195, 97],
    [210, 97],

    [223, 97],
    [238, 97],
    [253, 97],

    [267.5, 97],
    [280, 97],
    [294, 97],

    [312.5, 97],
    [324, 97],
    [337, 97],

    [355, 97],
    [372, 97],
    [387.5, 97],
  ],
  imgState: [
    {
      id: 18,
      furcation: [{ state: null, pos: [26, 74] }],
    },
    {
      id: 17,
      furcation: [{ state: null, pos: [82, 74] }],
    },
    {
      id: 16,
      furcation: [{ state: null, pos: [142, 74] }],
    },
    {
      id: 15,
    },
    {
      id: 14,
    },
    {
      id: 13,
    },
    {
      id: 12,
    },
    {
      id: 11,
    },
  ],
};

export const oneSettings = {
  imgs: [
    {
      id: 18,
      src: {
        reg: one,
        implant: oneImplant,
        removed: oneRemoved,
      },

      pos: "0 -2px",
      width: "54px",
    },
    {
      id: 17,
      src: {
        reg: two,
        implant: twoImplant,
        removed: twoRemoved,
      },
      width: "57px",
    },
    {
      id: 16,
      src: {
        reg: three,
        implant: threeImplant,
        removed: threeRemoved,
      },

      pos: "0 4px",
      width: "63px",
    },
    {
      id: 15,
      src: {
        reg: four,
        implant: fourImplant,
        removed: fourRemoved,
      },
      pos: "0 5px",
      width: "42px",
    },
    {
      id: 14,
      src: {
        reg: five,
        implant: fiveImplant,
        removed: fiveRemoved,
      },
      pos: "0 3px",
      width: "46px",
    },
    {
      id: 13,
      src: {
        reg: six,
        implant: sixImplant,
        removed: sixRemoved,
      },
      pos: "top",
      width: "42px",
    },
    {
      id: 12,
      src: {
        reg: seven,
        implant: sevenImplant,
        removed: sevenRemoved,
      },
      pos: "0 6px",
      width: "38px",
    },
    {
      id: 11,
      src: {
        reg: eight,
        implant: eightImplant,
        removed: eightRemoved,
      },
      pos: "0 13px",
      width: "57px",
    },
  ],
};
