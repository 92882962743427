import React from "react";
import styled from "styled-components";
import colors from "../../../library/styled-components/colors";
import { CardElement, CardNumberElement } from "@stripe/react-stripe-js";

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
`;

const InputField = styled.div`
  font-size: 16px;
  border: 1px solid ${(props) => (props.changed ? colors.primary : "#bdc4c9")};
  background-color: ${(props) =>
    props.changed ? colors.primLight : "#ffffff"};
  height: 3em;
  border-radius: 4px;
  padding-left: 3rem !important;
  padding-right: 1rem !important;

  font-size: 16px;
  border: 1px solid #bdc4c9;
  background-color: #ffffff;
  height: 2.2em;
  border-radius: 4px;
  font-family: "Nunito", sans-serif;
  padding-left: 0.8rem !important;
  padding-top: 0.8rem !important;

  &:focus {
    outline: none;
    border-color: #0070e0;
  }
`;

const LabelContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  height: 2.2em;
`;

const Label = styled.label`
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
`;

const Icon = styled.span`
  position: absolute;
  top: 46px;
  left: 14px;
`;

const ErrorMessage = styled.label`
  color: ${colors.accentColor};
  margin: 0.4rem 0;
  line-height: 1.1rem;
  font-size: 16px;
  font-weight: 700;
  display: inline-block;
  margin-bottom: 0.5rem;
  text-align: right;
`;

const Input = ({ LabelData, onChange, icon, changed }) => (
  <InputContainer>
    <LabelContainer>
      <Label htmlFor={LabelData.text}>{LabelData.text}</Label>
      {LabelData.error && (
        <ErrorMessage htmlFor={LabelData.text}>{LabelData.error}</ErrorMessage>
      )}
    </LabelContainer>
    <Icon>{icon}</Icon>
    <InputField changed={changed}>
      <CardElement
        onChange={onChange}
        options={{
          style: {
            base: {
              fontSize: "14px",
              textAlign: "center",
              lineHeight: "20px",
              backgroundColor: "#fff",
              color: "#808080",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#9e2146",
            },
          },
        }}
      />
    </InputField>
  </InputContainer>
);

export default Input;
