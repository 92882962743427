import React from "react";
import { Public } from "@mui/icons-material";
import { EventBlock, EventBlockContainer } from "./Common";
import { Tooltip, Typography } from "@mui/material";
import colors from "library/styled-components/colors";

const OnlineBlock = ({ info }) => {
  return (
    <EventBlockContainer>
      <Tooltip
        arrow
        placement="top-end"
        title={
          <React.Fragment>
            <Typography color="inherit" style={{ fontWeight: "bold" }}>
              Appointment Slot visible online
            </Typography>
            <Typography color="inherit">
              If you create an appointmnet in this slot it will no longer be
              visible online
            </Typography>
          </React.Fragment>
        }
      >
        <EventBlock>
          <div style={{ display: "flex" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                height: "2rem",
                fontSize: "0.8rem",
              }}
            >
              <Public sx={{ color: colors.softGreen }} />
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              height: "2rem",
              fontSize: "0.8rem",
            }}
          >
            {info.event.title}
          </div>
          <div />
        </EventBlock>
      </Tooltip>
    </EventBlockContainer>
  );
};

export default OnlineBlock;
