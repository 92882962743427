import React from "react";
import * as Joi from "@hapi/joi";
import MailFeaIcon from "../../../icon/fea-icons/mail";

const validation = {
  Email: Joi.string()
    .min(5)
    .max(255)
    .required()
    .email({
      minDomainSegments: 2,
      tlds: { allow: false },
    }),
};

export const formSchema = {
  Email: {
    domRef: null,
    email: true,
    name: "Email",
    text: "Email",
    type: "email",
    val: "",
    placeholder: "Please enter your Email address",
    icon: <MailFeaIcon size="16" />,
    validation: Joi.object({ Email: validation.Email }),
  },
};

export const createButtons = [
  {
    text: "Send Request",
    type: "Submit",
  },
];
