import localforage from "localforage";

const batchqueuesUpdate = (eventData) => async (dispatch) => {
  const { SmsBatchQueue } = eventData;
  const batchqueues = await localforage.getItem("batchqueues");
  let data = batchqueues.map((a) => {
    console.log(a.BatchQueueId, SmsBatchQueue.BatchQueueId);
    if (a.BatchQueueId === SmsBatchQueue.BatchQueueId) {
      return SmsBatchQueue;
    }
    return a;
  });

  dispatch({
    type: "LOAD",
    context: "batchqueues",
    data,
  });
  await localforage.setItem("batchqueues", data);
};

const batchqueuesCreate = (eventData) => async (dispatch) => {
  const { SmsBatchQueue } = eventData;
  const batchqueues = await localforage.getItem("batchqueues");
  let data = [
    ...batchqueues.filter((a) => a.BatchQueueId !== SmsBatchQueue.BatchQueueId),
    SmsBatchQueue,
  ];

  dispatch({
    type: "LOAD",
    context: "batchqueues",
    data,
  });
  await localforage.setItem("batchqueues", data);
};

export default {
  update: batchqueuesUpdate,
  create: batchqueuesCreate,
};
