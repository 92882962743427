import { searchTargets } from "../../../../../actions/targets";
import { ADMIN_NS } from "../../../../../config";
 

export const getTargetsList = (input) => async (dispatch, navigateTo) => {
  const data = await searchTargets(ADMIN_NS, input);
  data &&
    data.code === "EXPIRED" &&
    (dispatch({
      type: "REPLACE",
      context: "user",
      data: null,
      updating: false,
    })) &&
    navigateTo("/admin/access/login");

  data &&
    data.code === "SUCCESS" &&
    (dispatch({
      type: "LOAD",
      context: "targets",
      data: data.data,
      updating: false,
    }));
}; 