import React, { useState, useEffect } from "react";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";

const SlideContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto 3fr;
  margin: 1rem 0;
  grid-column-gap: 6px;
  align-items: center;
`;

const Slide = styled.input`
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 5px;
  outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s;
  border-radius: 2em;
  margin-right: 1em;
  ${props =>
    `background: linear-gradient(90deg, ${colors.primColor} ${(props.value /
      props.max) *
      100}%, ${colors.primColor} ${(props.value / props.max) * 100 + 0.1}%);`}

  &:hover {
    opacity: 1;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 2em;
    height: 2em;
    background: ${colors.accentColor};
    cursor: pointer;
    border-radius: 50%;
  }

  &::-moz-range-thumb {
    width: 2em;
    height: 2em;
    background: ${colors.accentColor};
    cursor: pointer;
    border-radius: 50%;
  }
`;

const SlideVal = styled.span`
  display: inline-block;
  position: relative;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: ${colors.borderColor};
  color: ${colors.primColor};
  padding: 5px 10px;
  margin-left: 12px;
  height: 3em;
  align-items: center;
  display: grid;

  &:after {
    position: absolute;
    top: 1.4em;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid ${colors.borderColor};
    border-bottom: 7px solid transparent;
    content: "";
  }
`;

const Slider = ({ slide, setSlider, label, defaultSlide }) => {
  const [interval, setInterval] = useState({
    min: slide.min,
    max: slide.max,
    val: slide.val,
    step: slide.step,
    unit: slide.unit
  });

  useEffect(() => {
    setInterval({
      ...interval,
      val: defaultSlide ? defaultSlide.slide.val : slide.val
    });
  }, [defaultSlide]);

  return (
    <SlideContainer>
      {label && <label>{label}</label>}
      <Slide
        type="range"
        value={interval.val}
        min={interval.min}
        max={interval.max}
        step={interval.step}
        onChange={e => {
          const val = e.target.value >= 15 ? e.target.value : 15;
          setInterval({
            ...interval,
            val
          });
          setSlider({
            ...interval,
            val
          });
        }}
      />
      <SlideVal>
        {interval.val >= 60 && `${Math.floor(interval.val / 60)} hrs`}
        {interval.val % 60 > 0 && (
          <div>
            {" "}
            {interval.val % 60} {interval.unit}
          </div>
        )}
      </SlideVal>
    </SlideContainer>
  );
};

export default Slider;
