import React from "react";
import { StarBorder, Star } from "@mui/icons-material";
import DoubleBounce from "../loaders/double-bounce";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";
import Badge from "@mui/material/Badge";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import memoize from "memoize-one";
import { formatDistance } from "date-fns";

export const Panel = styled.div`
  .MuiListItem-root.Mui-selected,
  .MuiListItem-root.Mui-selected:hover {
    background: ${colors.vertLightPrimary};
  }
`;

const Rows = ({
  data,
  selectedUser,
  setSelectedUser,
  dim,
  pageSize,
  setPageSize,
  starred,
  toggleItemStarred,
}) => {
  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop - 100 <= e.target.clientHeight;
    if (bottom) {
      setPageSize(pageSize + 10);
    }
  };

  if (!data.items?.length) return <div></div>;

  return (
    <div
      onScroll={handleScroll}
      style={{
        position: (dim && dim.position) || "relative",
        top: dim?.top,
        backgroundColor: colors.white,
        zIndex: 101,
        height: dim?.height,
        width: dim?.width,
        overflowY: "scroll",
      }}
    >
      {data.items.map((item) => {
        const isNew =
          item.SmsMessages?.length > 0 &&
          item.SmsMessages.filter((m) => m.TypeId === 4 && !m.ReadUserId)
            .length;

        const lastMessage =
          item?.SmsMessages?.length &&
          item?.SmsMessages[item?.SmsMessages?.length - 1];

        const lastMessageString = ` - ${
          lastMessage?.Text?.substring(0, 45) || ""
        }...`;

        const isStarred = starred.includes(item?.id);

        const starIcon = isStarred ? (
          <Star style={{ color: "gold" }} />
        ) : (
          <StarBorder />
        );

        return (
          <div
            key={item.UserId}
            style={{
              listStyle: "none",
              background: isNew ? "lightcyan" : "white",
            }}
          >
            <Divider component="li" />

            <ListItem
              component="nav"
              button
              onClick={() => setSelectedUser(item)}
              selected={selectedUser && selectedUser.UserId === item.UserId}
              alignitems="flex-start"
            >
              <ListItemAvatar>
                <Avatar alt={item.Name}>
                  {item.Name.split(" ")
                    .map((i) => i.charAt(0).toUpperCase())
                    .slice(0, 2)}
                </Avatar>
              </ListItemAvatar>

              <ListItemText
                primary={
                  <Badge
                    style={{ width: "100%" }}
                    badgeContent={isNew || null}
                    color="secondary"
                  >
                    <Typography component="div">{item.Name}</Typography>
                  </Badge>
                }
                secondary={
                  <React.Fragment>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "end",
                      }}
                    >
                      {item.Phone}
                      {lastMessageString}
                      {lastMessage?.createdAt ? (
                        <Typography
                          variant="caption"
                          style={{
                            minWidth: "6rem",
                            textAlign: "right",
                          }}
                        >
                          {formatDistance(
                            new Date(),
                            new Date(lastMessage?.createdAt),
                            { includeSeconds: true }
                          )}{" "}
                          <span
                            onClick={(e) => {
                              e.stopPropagation();

                              toggleItemStarred(item?.id);
                            }}
                            size="small"
                          >
                            {starIcon}
                          </span>
                        </Typography>
                      ) : null}
                    </div>
                  </React.Fragment>
                }
              />
            </ListItem>
          </div>
        );
      })}
    </div>
  );
};

const createItemData = memoize((items, toggleItemActive, selectedUser) => ({
  items,
  toggleItemActive,
  selectedUser,
}));

const DropdownSearch = ({
  open,
  dim,
  pageSize,
  setPageSize,
  options,
  selectedUser,
  loading,
  setSelectedUser,
  toggleItemStarred,
  starred,
}) => {
  const itemData = createItemData(
    options,
    (item) => {
      setSelectedUser(item);
    },
    selectedUser
  );

  return (
    <Panel>
      {open && (
        <Rows
          data={itemData}
          selectedUser={selectedUser}
          setSelectedUser={setSelectedUser}
          dim={dim}
          pageSize={pageSize}
          setPageSize={setPageSize}
          starred={starred}
          toggleItemStarred={toggleItemStarred}
        />
      )}
  
      {!loading && !options?.length && (
        <Typography
          style={{
            textAlign: "center",
            marginTop: "1rem",
            color: colors.primary,
          }}
        >
          No results found
        </Typography>
      )}
      {loading && <DoubleBounce color={colors.primary} margin="20%" />}
    </Panel>
  );
};

export default DropdownSearch;
