import React from "react";
import styled from "styled-components";
import Editor from "../../editor";
import { formSchema, createButtons } from "./formSettings";
import { FadeDiv } from "../../../library/styled-components";
import { Link } from "../../common";

const RegisterBlockContainer = styled(FadeDiv)`
  display: grid;
  grid-template-rows: auto 8em 2em;
  background-color: ${(props) => props.colors.secColor};
`;

function RegisterBlock({
  registerUser,
  goToLogin,
  fade,
  colors,
  meta,
  showTerms,
}) {
  return (
    <RegisterBlockContainer fade={fade} colors={colors}>
      <Editor
        submitError={meta && meta.error}
        warning={meta && meta.warning}
        formSchema={formSchema}
        metaData={meta}
        buttons={createButtons}
        sendData={registerUser}
        colors={colors}
        settings={{
          width: { mobile: "100%", desktop: "100%" },
        }}
        callBack={(call) => call === "open-terms" && showTerms()}
      />
      <Link onClick={goToLogin} colors={colors} size="1.1">
        Already Have an account?
      </Link>
    </RegisterBlockContainer>
  );
}

export default RegisterBlock;
