import React, { useContext, useMemo, useState } from "react";
import {
  Avatar,
  Box,
  FormControl,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  MenuItem,
  Radio,
  Select,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import {
  Assessment,
  Forum,
  PhotoFilter, //X-ray
  AirlineSeatFlatAngled, //Anastetic
  Restore, // Restoration
  AirlineSeatFlat, //surgery
  Build, // Implant
  Landscape, // Crown
  PlayForWork, // Root Canal
  FlareTwoTone, // Hygene
  StarTwoTone, // Whitenting
  TimelineTwoTone, // Ortho
} from "@mui/icons-material";
import { AppContext } from "../../../../../../hooks/context";
import colors from "library/styled-components/colors";
import TreatmentListDrawer from "./TreatmentListDrawer";
import styled from "styled-components";
import { InputContainer } from "components/common";

const DropdownWrapper = styled.div`
  display: grid;
  padding: 1rem 1rem 0rem 1rem;
`;

const styles = {
  existing: {
    background: colors.accentColor,
  },
  planned: {
    background: colors.red,
  },
  completed: {
    background: colors.green,
  },
};

const TabItem = styled.div`
  display: grid;
  color: ${(props) => props.color};
  text-transform: uppercase;

  margin: 1rem 1rem 0rem 1rem;
  justify-items: center;
  span {
    color: ${(props) => props.color};
    width: 2rem;
  }
  label {
    color: ${(props) => props.color};
  }
`;

const TabWrap = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${colors.grey};
`;

const categoryIcons = [
  { id: "diag", icon: <Forum /> },
  { id: "watch", icon: <Assessment /> },
  { id: "xray", icon: <PhotoFilter /> },
  { id: "whit", icon: <StarTwoTone /> },
  { id: "ortho", icon: <TimelineTwoTone /> },
  { id: "anast", icon: <AirlineSeatFlatAngled /> },
  { id: "crown", icon: <Landscape /> },
  { id: "imp", icon: <Build /> },
  { id: "hyg", icon: <FlareTwoTone /> },
  { id: "ext", icon: <AirlineSeatFlat /> },
  { id: "rct", icon: <PlayForWork /> },
  { id: "resto", icon: <Restore /> },
];

const CategoryList = ({
  classes,
  addProceedure,
  practitionerId,
  changePractitioner,
}) => {
  const [tabState, setTabState] = useState("2");
  const { state } = useContext(AppContext);
  const { categories, treatments } = state;
  const [list, setList] = useState(false);

  const practitioners = state.list;

  const stateString = useMemo(() => {
    switch (tabState) {
      case "0":
        return "existing";
      case "1":
        return "planned";
      case "2":
        return "completed";
    }
  }, [tabState]);
  return (
    <>
      <DropdownWrapper>
        <FormControl variant="outlined">
          <InputContainer>
            <InputLabel>Practitioner</InputLabel>
            <Select
              value={practitionerId}
              onChange={changePractitioner}
              label="Practitioner"
            >
              {practitioners.data.map((p) => (
                <MenuItem
                  key={p.data.PractitionerId}
                  value={p.data.PractitionerId}
                >
                  {p.data.Name}
                </MenuItem>
              ))}
            </Select>
          </InputContainer>
        </FormControl>
      </DropdownWrapper>
      <Box>
        <TabWrap>
          <TabItem color={colors.accentColor}>
            <FormLabel>Existing</FormLabel>
            <Radio
              checked={tabState === "0"}
              onChange={(e) => setTabState(e.target.value)}
              value="0"
            />{" "}
          </TabItem>
          <TabItem color={colors.red}>
            <FormLabel>Planned</FormLabel>
            <Radio
              checked={tabState === "1"}
              onChange={(e) => setTabState(e.target.value)}
              value="1"
            />
          </TabItem>{" "}
          <TabItem color={colors.green}>
            <FormLabel>Complete</FormLabel>
            <Radio
              checked={tabState === "2"}
              onChange={(e) => setTabState(e.target.value)}
              value="2"
            />{" "}
          </TabItem>
        </TabWrap>
      </Box>
      <List component="nav">
        {categories?.data.map((treat) => (
          <ListItem
            button
            key={treat.data.id}
            onClick={() => setList(treat.data.Type)}
          >
            <ListItemAvatar>
              <Avatar classes={{ root: classes[stateString] }}>
                {
                  categoryIcons.find((icon) => icon.id === treat.data.Type)
                    ?.icon
                }
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={treat.data.Name} />
          </ListItem>
        ))}
      </List>
      <TreatmentListDrawer
        open={!!list}
        status={tabState}
        list={treatments?.data.filter((t) => t.data?.Category?.Type === list)}
        closeList={() => setList(false)}
        addProceedure={(quantity) => {
          addProceedure(quantity, true);
          setList(null);
        }}
      />
    </>
  );
};

export default withStyles(styles)(CategoryList);
