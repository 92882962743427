import React, { createRef, useEffect, useState } from "react";
import styled from "styled-components";
import colors from "../../../library/styled-components/colors";
import { SubmitBtn, Warning, Danger } from "../../../components/common";

const PinCodeBlockContainer = styled.div`
  display: grid;
  grid-template-rows: 8em 2em auto;
  background-color: ${colors.secColor};
  margin: 1em 1em 0em 1em;
  align-content: center;
`;

const Header = styled.h1`
  font-weight: 400;
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  margin: 1em 0;
`;

const PinCodeArea = styled.div`
  display: grid;
  grid-template-columns: repeat(4, auto);
`;

const PinCode = styled.div`
  padding: 0.2rem;
  display: grid;
`;

const PinInput = styled.input`
  text-align: center;
  -webkit-text-security: disc;
  background-color: ${(props) =>
    props.value === "" ? colors.secColor : colors.altColor};
  color: ${(props) => (props.value === "" ? colors.altColor : colors.txtColor)};
  font-size: 1rem;
  line-height: 1rem;

  font-weight: 600;
  border: 1px solid ${colors.borderColor};
  border-radius: 0.4rem;
  padding: 1rem 0.4rem;
  height: 3rem;
  min-width: 3rem;
  &:focus {
    outline: none;
    border-color: ${colors.accentColor};
  }
`;

function PinCodeBlock({ sendPinCode, meta, reset }) {
  const [pin, setPin] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
  });

  const [ready, setReady] = useState(null);

  const pinRef = {
    one: createRef(),
    two: createRef(),
    three: createRef(),
    four: createRef(),
  };

  useEffect(() => pinRef.one.current.focus(), []);

  useEffect(() => {
    setPin({
      one: "",
      two: "",
      three: "",
      four: "",
    });
    pinRef.one.current.focus();
  }, [reset]);

  async function inputChange(e, next) {
    e.preventDefault();
    const regex = RegExp("[0-9]");
    if (regex.test(e.target.value)) {
      setPin({
        ...pin,
        [e.target.name]: e.target.value.charAt(e.target.value.length - 1),
      });
      next && pinRef[next].current.focus();
    }
    const pinArray = Object.values(pinRef).filter(
      (i) => i.current.value !== ""
    );
    if (pinArray.length === 4) {
      setReady(pinArray.map((i) => i.current.value));
    }
    return false;
  }

  async function submitPin() {
    sendPinCode(ready);
  }

  function focusChange(e) {
    setPin({
      ...pin,
      [e.target.name]: "",
    });
  }

  return (
    <PinCodeBlockContainer>
      <PinCodeArea>
        <PinCode>
          <PinInput
            name="one"
            type="tel"
            value={pin.one}
            onClick={focusChange}
            onChange={(e) => inputChange(e, "two")}
            ref={pinRef.one}
          />
        </PinCode>
        <PinCode>
          <PinInput
            name="two"
            type="tel"
            value={pin.two}
            onClick={focusChange}
            onChange={(e) => inputChange(e, "three")}
            ref={pinRef.two}
          />
        </PinCode>
        <PinCode>
          <PinInput
            name="three"
            type="tel"
            value={pin.three}
            onClick={focusChange}
            onChange={(e) => inputChange(e, "four")}
            ref={pinRef.three}
          />
        </PinCode>
        <PinCode>
          <PinInput
            name="four"
            type="tel"
            value={pin.four}
            onClick={focusChange}
            onChange={(e) => inputChange(e)}
            ref={pinRef.four}
          />
        </PinCode>
      </PinCodeArea>
      {ready && (
        <SubmitBtn onClick={() => submitPin()} size="0.9">
          Submit Pin
        </SubmitBtn>
      )}
      <Header>
        {meta.error && <Danger> {meta.error}</Danger>}
        {meta.warning && <Warning> {meta.warning}</Warning>}
      </Header>
    </PinCodeBlockContainer>
  );
}

export default PinCodeBlock;
