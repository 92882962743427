import React from "react";
import styled from "styled-components";
import colors from "../../library/styled-components/colors";
import SearchFeaIcon from "../../icon/fea-icons/search";
import CloseFeaIcon from "../../icon/fea-icons/close";

const InputContainer = styled.div`
  max-width: 40rem;
  display: grid;
  position: relative !important;
`;

const InputField = styled.input`
  font-size: 14px;
  border: 1px solid ${(props) => (props.changed ? colors.primary : "#bdc4c9")};
  background-color: ${(props) =>
    props.changed ? colors.primLight : "#ffffff"};
  height: 3em;
  border-radius: 4px;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
  &:focus {
    outline: none;
    border-color: #0070e0;
  }
`;

const Icon = styled.span`
  position: absolute;
  top: 16px;
  left: 14px;
`;

const RightIcon = styled.span`
  display: ${(props) => (props.active ? "show" : "none")};
  position: absolute;
  cursor: pointer;
  top: 16px;
  right: 14px;
`;

const SearchInput = ({
  search,
  placeholder,
  setSearch,
  onFocus,
}) => {
  return (
    <InputContainer>
      <Icon>
        <SearchFeaIcon color={colors.grey} size="16" />
      </Icon>
      <InputField
        id="search"
        type="text"
        autocomplete="off"
        onFocus={onFocus}
        placeholder={placeholder}
        onChange={(val) => setSearch(val.target.value)}
      />
      <RightIcon active={search && search !== ""}>
        <CloseFeaIcon
          onClick={() => setSearch("")}
          color={colors.grey}
          size="16"
        />
      </RightIcon>
    </InputContainer>
  );
};

export default SearchInput;
