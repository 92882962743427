import localforage from "localforage";

const practitionersUpdate = (eventData) => async (dispatch) => {
  const { Practitioner } = eventData;
  const practitioners = await localforage.getItem("list");
  let data = practitioners?.map((p) => {
    if (p.PractitionerId === Practitioner.PractitionerId) {
      return Practitioner;
    }
    return p;
  });
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data); 
};

const practitionersCreate = (eventData) => async (dispatch) => {
  const { Practitioner } = eventData;
  const practitioners = await localforage.getItem("list");
  const data = [
    ...practitioners.filter(
      (p) => p.PractitionerId !== Practitioner.PractitionerId
    ),
    Practitioner,
  ];
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data); 
};


const practitionersDelete = (eventData) => async (dispatch) => {
  const { Practitioner } = eventData;
  const practitioners = await localforage.getItem("list");
  const data =  practitioners.filter(
      (p) => p.PractitionerId !== Practitioner.PractitionerId
    ) ;
  dispatch({
    type: "LOAD",
    context: "list",
    data,
  });
  await localforage.setItem("list", data); 
};

export default {
  create: practitionersCreate,
  update: practitionersUpdate,
  delete: practitionersDelete,
}