import { Button, Checkbox, Dialog, FormControlLabel } from '@mui/material'
import colors from 'library/styled-components/colors'
import * as React from 'react'
import ReactToPrint from 'react-to-print'
import styled from 'styled-components'
import DoubleBounce from './loaders/double-bounce'
import { Print } from '@mui/icons-material'
import { Close } from '@mui/icons-material'
import { format } from 'date-fns'

const SchedulePreview = styled.div`
  display: grid;
  margin-top: 4rem;
`

const SchedulePrintArea = styled.div`
  background-color: ${colors.white};
  margin: 1rem;
  justify-self: center;
`

const EventBlock = styled.div`
  display: flex;
  align-items: start;
  font-size: 1rem;
  border: 1px solid ${colors.lightGrey};
`

const EventBlockTime = styled.div`
  width: 8rem;
  min-height: 1rem;
  border-right: 1px solid ${colors.lightGrey};
  padding: 4px;
  background-color: ${colors.white};
`

const EventBlockText = styled.div`
  padding: 4px;
  height: 1.5rem;
  font-size: 0.9rem;
  width: 100%;
  background-color: ${colors.veryLightYellow};
`

const generateTimeArray = () => {
  const times = []
  for (let h = 6; h < 22; h++) {
    for (let m = 0; m < 60; m += 30) {
      times.push(
        `${h.toString().padStart(2, '0')}:${m.toString().padStart(2, '0')}`
      )
    }
  }
  return times
}

const timeArray = generateTimeArray()

export default function PrintTest ({
  open,
  setOpen,
  date,
  selectedDate,
  schedules
}) {
  const componentRef = React.useRef(null)
  const buttonRef = React.useRef(null)
  const previewComponentRef = React.useRef()
  const onBeforeGetContentResolve = React.useRef(null)

  const [loading, setLoading] = React.useState(false)
  const [form, setForm] = React.useState({ Orientation: 'true' })
  const [text, setText] = React.useState('old boring text')
  const [unchecked, setUnchecked] = React.useState([])

  const handleAfterPrint = React.useCallback(() => {
    console.log('onAfterPrint called')
  }, [])

  const handleBeforePrint = React.useCallback(() => {
    console.log('onBeforePrint called')
    setOpen(false)
  }, [])

  const handleOnBeforeGetContent = React.useCallback(() => {
    console.log('onBeforeGetContent called')
    setLoading(true)
    setText('Loading new text...')

    return new Promise(resolve => {
      onBeforeGetContentResolve.current = resolve

      setTimeout(() => {
        setLoading(false)
        setText('New, Updated Text!')
        resolve()
      }, 2000)
    })
  }, [setLoading, setText])

  React.useEffect(() => {
    if (
      text === 'New, Updated Text!' &&
      typeof onBeforeGetContentResolve.current === 'function'
    ) {
      onBeforeGetContentResolve.current()
    }
  }, [onBeforeGetContentResolve.current, text])

  const reactToPrintContent = React.useCallback(() => {
    return componentRef.current
  }, [componentRef.current])

  const reactToPrintTrigger = React.useCallback(() => {
    return (
      <Button
        ref={buttonRef}
        variant='contained'
        color={'primary'}
        endIcon={<Print size='small'></Print>}
      >
        Print Schedule
      </Button>
    )
  }, [loading])

  React.useEffect(() => {
    if (previewComponentRef?.current) {
      let calendarApi = previewComponentRef?.current.getApi()
      calendarApi.gotoDate(selectedDate)
    }
  }, [selectedDate])

  return (
    <Dialog
      open={open || false}
      fullScreen={true}
      onClose={() => setOpen(false)}
      color='primary'
      maxWidth='lg'
    >
      <style type='text/css'>
        {`
          @media all {
            .page-break {
              display: none;
            }
          }
          
          @media print {
            html, body {
              height: initial !important;
              overflow: initial !important;
              -webkit-print-color-adjust: exact;
            }
          }
          
          @media print {
            .page-break {
              margin-top: 1rem;
              display: block;
              page-break-before: auto;
            }
          }
          
          @page {
            size: auto;
            margin: 20mm;
          }
        `}
      </style>
      <div
        style={{
          position: 'fixed',
          height: '4rem',
          width: '100%',
          zIndex: 1000,
          display: 'flex',
          gridGap: '0.5rem',
          color: colors.white,
          justifyContent: 'space-between',
          backgroundColor: colors.rgbGrey
        }}
      >
        <div
          style={{
            display: 'flex',
            margin: '1rem',
            gap: '1rem'
          }}
        >
          {schedules.map(schedule => (
            <FormControlLabel
              key={schedule.id}
              control={
                <Checkbox
                  checked={!unchecked.includes(schedule.id)}
                  onChange={e => {
                    if (!e.target.checked) {
                      setUnchecked(prev => [...prev, schedule.id])
                    } else {
                      setUnchecked(prev =>
                        prev.filter(id => id !== schedule.id)
                      )
                    }
                  }}
                  name={schedule.name}
                  color='primary'
                />
              }
              label={schedule.name}
            />
          ))}
        </div>
        <div
          style={{
            display: 'flex',
            margin: '1rem',
            gap: '1rem'
          }}
        >
          <Button
            ref={buttonRef}
            variant='contained'
            endIcon={<Close size='small'></Close>}
            onClick={() => setOpen(false)}
          >
            Close
          </Button>

          <ReactToPrint
            content={reactToPrintContent}
            documentTitle='Daily Schedule'
            onAfterPrint={handleAfterPrint}
            onBeforeGetContent={handleOnBeforeGetContent}
            onBeforePrint={handleBeforePrint}
            removeAfterPrint
            trigger={reactToPrintTrigger}
            events={schedules}
          />
        </div>
      </div>
      <SchedulePreview>
        {loading ? (
          <div>
            <DoubleBounce color={colors.primary} margin='25%' />
          </div>
        ) : (
          <SchedulePrintArea ref={componentRef} orientation={form.Orientation}>
            {schedules.map(schedule => {
              if (unchecked.includes(schedule.id)) return null

              // Assumes you are using 'date-fns' for formatting and parsing
              const parseTime = time => new Date('1970-01-01T' + time + ':00')

              // Map and sort events
              const printEvents = schedule.events
                .map(event => {
                  const Notes =
                    event?.Meta?.Comments?.map(c => `- ${c.text}`) || []
                  return {
                    startTime: format(new Date(event.Start), 'HH:mm'),
                    endTime: format(new Date(event.End), 'HH:mm'),
                    title: event.title,
                    notes: Notes?.join(' - ')
                  }
                })
                .sort((a, b) => parseTime(a.startTime) - parseTime(b.startTime))
 

                
              // Initialize events timeline with the first time slot
              let lastEndTime = timeArray[0]
              const printEventsByTime = []
              const addedEvents = new Set()

              timeArray.forEach((time, index) => {
                const nextTime = timeArray[index + 1] || '22:00'

                // Find overlapping or matching events
                // Find overlapping or matching events
                const eventsDuringThisTime = printEvents.filter(event => {
                  // Convert string times to Date objects for comparison
                  const eventStart = parseTime(event.startTime)
                  const eventEnd = parseTime(event.endTime)
                  const currentTime = parseTime(time)
                  const nextTimeSlot = parseTime(nextTime)

                  return (
                    (eventStart <= currentTime && eventEnd > currentTime) || // Event spans the current time
                    (eventStart >= currentTime && eventStart < nextTimeSlot)
                  ) // Event starts within the current time slot
                })

                // Insert events and check for gaps
                if (eventsDuringThisTime.length) {
                  eventsDuringThisTime.forEach(event => {
                    // Check for a gap before this event starts
                    if (parseTime(lastEndTime) < parseTime(event.startTime)) {
                      printEventsByTime.push({
                        startTime: lastEndTime,
                        endTime: event.startTime,
                        title: '',
                        notes: ''
                      })
                    }
                    if (!addedEvents.has(event)) {
                      printEventsByTime.push(event)
                      addedEvents.add(event)
                    }
                    lastEndTime = event.endTime // Update lastEndTime to the end of the current event
                  })
                } else if (parseTime(lastEndTime) < parseTime(time)) {
                  // Insert a blank period if there was no event covering this time
                  printEventsByTime.push({
                    startTime: lastEndTime,
                    endTime: nextTime,
                    title: '',
                    notes: ''
                  })
                  lastEndTime = nextTime
                }
              })

              return (
                <div
                  key={schedule.id}
                  style={{
                    pageBreakInside: 'avoid',
                    width: '1370px',
                    marginBottom: '8rem'
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <h3>{schedule.name}</h3>
                    &nbsp;{' - '}&nbsp;
                    <div>{date}</div>
                  </div>
                  <div>
                    {printEventsByTime.map((e, idx) => (
                      <EventBlock key={idx}>
                        <EventBlockTime>{`${e.startTime} - ${e.endTime}`}</EventBlockTime>
                        <EventBlockText>
                          <strong>{e.title}</strong>
                          <span>{e.notes}</span>
                        </EventBlockText>
                      </EventBlock>
                    ))}
                  </div>
                </div>
              )
            })}
          </SchedulePrintArea>
        )}
      </SchedulePreview>
    </Dialog>
  )
}
