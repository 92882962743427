import React from "react";
import styled from "styled-components";
import { FadeDiv } from "../../../library/styled-components";
import {
  SlotsContainerNoOverflow,
  SlotDiv,
  SlotItems,
  TimeCont,
  EmptySlots,
  SubmitBtn,
} from "../../common";
import { AccessTime } from "styled-icons/material";
import DentIcon from "../../../icon/dentist";
import dayjs from "dayjs";
import { timeZone } from "../../../config";

const SchedulesBlockContainer = styled(FadeDiv)`
  width: 100%;
  opacity: 1;
  transition: opacity 2s;
  -webkit-transition: opacity 2s;
`;

const ContentWrapper = styled(FadeDiv)`
  display: grid;
`;

const BookingSlot = ({ slot, practitioners, selectSlot, slectedSlot }) => {
  const start = dayjs
    .tz(dayjs(), timeZone)
    .hour(slot.Start.split(":")[0])
    .minute(slot.Start.split(":")[1]);
  const end = dayjs
    .tz(dayjs(), timeZone)
    .hour(slot.End.split(":")[0])
    .minute(slot.End.split(":")[1]); 

  return (
    <SlotDiv key={slot.ScheduleId} active={slectedSlot === slot.ScheduleId}>
      <DentIcon size="48" />
      <h4>
        {
          practitioners.find(
            (p) => p.data.PractitionerId === slot.PractitionerId
          ).data.Name
        }
      </h4>
      <SlotItems>
        <div>
          <AccessTime size="24" />
        </div>
        <div>{dayjs.tz(start, timeZone).format("HH:mm")}</div>
        <TimeCont>
          <div>
            {dayjs.tz(end, timeZone).diff(dayjs.tz(start, timeZone), "minute")}
          </div>
          <div>min</div>
        </TimeCont>
      </SlotItems>
      <SubmitBtn
        onClick={() => {
          selectSlot(slot.ScheduleId);
        }}
        size="0.9"
      >
        Book now!
      </SubmitBtn>
    </SlotDiv>
  );
};

function SchedulesBlock({
  schedules,
  practitioners,
  selectSlot,
  slectedSlot,
  fadeState,
  colors,
  fade,
}) {
  if (!practitioners?.length > 0) return null;

  return (
    <SchedulesBlockContainer fade={fade}>
      <ContentWrapper fade={fadeState}>
        <SlotsContainerNoOverflow bottom="6em">
          {schedules &&
            schedules.map((slot) => (
              <BookingSlot
                slot={slot}
                practitioners={practitioners}
                selectSlot={selectSlot}
                slectedSlot={slectedSlot}
              />
            ))}
        </SlotsContainerNoOverflow>
        {schedules && schedules.length <= 0 && (
          <EmptySlots color={colors.primColor}>
            <p>There are currently no times available for this date</p>
          </EmptySlots>
        )}
      </ContentWrapper>
    </SchedulesBlockContainer>
  );
}

export default SchedulesBlock;
