import React, { useRef, useState } from "react";
import styled, { keyframes, css } from "styled-components";
import Chip from "@mui/material/Chip";
import CopyIcon from "@mui/icons-material/FileCopy";
import dayjs from "dayjs";

const PaymentBlockContainer = styled.div``;

const Header = styled.h1`
  font-weight: 400;
  text-align: center;
  font-size: 1.6rem;
  color: #677294;
  margin: 1em 0;
`;

const PaymentArea = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 33%);
  @media (max-width: 481px) {
    grid-template-columns: repeat(1, auto);
  }
`;

const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    -ms-transform: translateX(-20px);
    transform: translateX(-20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
  }
`;

const PriceBlock = styled.div`
  border: 1px solid #f0f3fa;
  border-radius: 5px;
  background-color: #fcfcff;
  padding: 2.5rem 2.5rem 3.4375rem;
  transition: all 0.3s linear;
  cursor: pointer;
  margin: 1rem;
  visibility: visible;
  animation-delay: 250ms;
  animation-name: ${fadeInLeft};
  text-align: left;
  text-align: center;

  h3 {
    margin-bottom: 0.5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: #4b505e;
    font-size: 1.75rem;
  }

  p {
    font-size: 1rem;
    line-height: 1.1rem;
    color: #8b8b99;
  }
  ul {
    list-style: none;
    padding: 1.4375rem 0px 1.875rem;
    margin-top: 0;
    margin-bottom: 1rem;
    box-sizing: border-box;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
    font-size: 1rem;
    line-height: 2rem;
    color: #727988;
    font-family: "Poppins", sans-serif;
  }

  &:hover {
    border-color: #f0f3fa;
    background: #fff;
    box-shadow: 0px 1.875rem 3.125rem 0px rgba(0, 0, 29, 0.1);
  }

  ${(props) =>
    props.active &&
    css`
      border-color: #f0f3fa;
      background: #fff;
      box-shadow: 0px 1.875rem 3.125rem 0px rgba(0, 0, 29, 0.1);
    `}
`;

const Rate = styled.div`
  font-size: 30px;
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  color: #282835;
  border-bottom: 1px solid #e8e8f0;
  padding: 25px 0px 17px;
  transition: all 0.3s linear;
`;

function PaymentBlock({ products }) {
  const [priceLookupKey, setPriceLookupKey] = useState(null);
  const [customUrl, setCustomUrl] = useState(null);
  let textEl = useRef(null);

  const copyCodeToClipboard = () => {
    textEl.select();
    document.execCommand("copy");
  };

  const creatCustomUrl = (priceId) => {
    setPriceLookupKey(priceId);
    const data = {
      priceId,
      expiresAt: dayjs.tz(dayjs()).add(1, "days"),
    };
    const encoded = btoa(JSON.stringify(data));
    setCustomUrl(
      `${window.location.protocol}//${window.location.hostname}:${window.location.port}/#/admin/access/setup/${encoded}`
    );
  };

  return (
    <PaymentBlockContainer>
      <Header>
        <p>Select your subscription plan</p>
      </Header>
      <PaymentArea>
        {products &&
          products.map((product) => (
            <PriceBlock
              onClick={() => creatCustomUrl(product.price.id)}
              active={priceLookupKey === product.price.id}
            >
              <h3>{product.name}</h3>
              <p>{product.description}</p>
              <Rate>€{product.price.unit_amount / 100}</Rate>
              {product.metadata && (
                <ul>
                  {Object.values(product.metadata).map((label) => (
                    <li key={label}>{label}</li>
                  ))}
                </ul>
              )}
            </PriceBlock>
          ))}
      </PaymentArea>
      {customUrl && (
        <div>
          <div>
            <input
              style={{ opacity: 0 }}
              ref={(text) => (textEl = text)}
              value={customUrl}
            />
          </div>

          <p>Click to copy to clipboard</p>
          <div>
            <Chip
              label={customUrl}
              onClick={() => copyCodeToClipboard()}
              onDelete={() => copyCodeToClipboard()}
              deleteIcon={<CopyIcon />}
            />
          </div>
        </div>
      )}
    </PaymentBlockContainer>
  );
}

export default PaymentBlock;
