import React from "react";
import styled, { keyframes } from "styled-components";

const debounce = keyframes`
  0%,
  100% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
`;

export const Child = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  opacity: 0.6;
  top: 0;
  left: 0;
  animation: ${debounce} 2s infinite ease-in-out;
  ${p => p.isDelay && `animation-delay: 1s;`};
`;

export const DoubleBounceContainer = styled.div`
  width: ${p => p.size}px;
  height: ${p => p.size}px;
  position: relative;
  margin:  ${p => p.margin} auto;
  > ${Child} {
    background-color: ${p => p.color};
  }
`;

const DoubleBounce = ({
  size = 24,
  color = "#0070e0",
  margin = "auto",
  className
}) => {
  return (
    <DoubleBounceContainer
      className={className}
      size={size}
      color={color}
      margin={margin}
    >
      <Child />
      <Child isDelay />
    </DoubleBounceContainer>
  );
};

export default DoubleBounce;
