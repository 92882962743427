import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";
const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="70"
    height="246"
    viewBox="0 0 70 246"
    {...props}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70.453"
      height="246"
      viewBox="0 0 70.453 246"
    >
      <g id="bottom2" transform="translate(-1000.5 -471.367)">
        <g id="bounds" transform="translate(1006.5 412.367)">
          <line
            id="bound-bottom"
            x2="64"
            transform="translate(0 305)"
            fill="none"
          ></line>
          <line
            id="bound-top"
            x2="64"
            transform="translate(0 59)"
            fill="none"
          ></line>
        </g>
        <g id="surface" transform="translate(1023.814 483.677)">
          <path
            id="shape"
            d="M34.28,46.455C24.669,52,5.468,38.75,1.231,26.927-4.85,9.963,30.806-8.882,37.781,6.1c4.6,9.867,4.987,35.465-3.5,40.357Z"
            transform="matrix(-0.829, -0.559, 0.559, -0.829, 20.451, 60.161)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="0.5"
            fill-rule="evenodd"
          ></path>
          <path
            id="o"
            d="M21.609,17.419c-.858-.5-2.085,1.978-4.857,2.283s-2.9-1.562-4.051-1.6c-.523-.016-1.814,3.479-3.776,7.432-2.063,4.156-4.8,8.933-4.84,9.354-.069.772,4.763,2.523,10.007,2.8s10.659-.875,10.968-1.7l-1.8-9.681Z"
            fill="#eaeaea"
          ></path>
          <path
            id="l"
            d="M34.28,46.455c-2.461,1.418-5.549,1.6-9.13.84-.62-.132-2.762-.829-2.762-.829S21.588,34,26.022,31.19s14.1,4.047,14.1,4.047-.431,2.025-.581,2.6C38.539,41.723,36.8,45,34.28,46.455Z"
            transform="matrix(-0.829, -0.559, 0.559, -0.829, 20.451, 60.161)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="m"
            d="M26.913.655C31.435.293,35.784,1.809,37.781,6.1c2.9,6.234,4.145,18.639,2.411,28.809.019-.106-.07.33-.07.33a43.879,43.879,0,0,0-5.167-2.98A23.248,23.248,0,0,0,29.948,30.6S26.012.724,26.913.655Z"
            transform="matrix(-0.829, -0.559, 0.559, -0.829, 20.451, 60.161)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="d"
            d="M22.4,46.479s-.16-.045-.6-.2C13.506,43.3,4.052,34.8,1.231,26.927-.05,23.355.52,19.7,2.5,15.928c.311-.6,20.566,20.468,20.566,20.468a45.8,45.8,0,0,0-.642,4.721,43.88,43.88,0,0,0-.024,5.362Z"
            transform="matrix(-0.829, -0.559, 0.559, -0.829, 20.451, 60.161)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
          <path
            id="b"
            d="M2.514,15.82s.167-.247.378-.616C7.018,7.9,17.621,1.546,26.729.691h0l.977,11.05s-6.73,1.368-10.143,3.477c-2.692,1.664-7.95,7.327-7.95,7.327L5.66,18.666Z"
            transform="matrix(-0.829, -0.559, 0.559, -0.829, 20.451, 60.161)"
            fill="#f5f5f5"
            fill-rule="evenodd"
          ></path>
        </g>
        <g id="crown" transform="translate(1000.5 511.5)">
          <path
            id="crown-2"
            data-name="crown"
            d="M9.171,60.675C8.483,56.832,2.983,18.4,1.036,14.791S-.454-.116,5.389,0,29.8,3.145,38.5,1.748s10.43,13.975,8.826,20.263S34.264,57.065,34.493,60.675,9.858,64.519,9.171,60.675Z"
            transform="translate(11.517 51.457)"
            fill="#f5f5f5"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        <g id="root" transform="translate(1014.487 588.543)">
          <path
            id="rb1"
            d="M22.472,18.514S21.7,38.809,18.6,60.229c-2.862,19.786-3.548,19.9-5.944,26.368C10.577,92.216,1.9,102.164-.02,96.125c-3.177-10-.944-5.56-.944-34.215,0-23.985-1.893-43.4-1.893-43.4s1,3.051,12.422,2.91S22.472,18.514,22.472,18.514Z"
            transform="translate(9.543 16.672)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
          <path
            id="r1"
            d="M11.926,19.449C11.974,27.5,10,37.5,10.294,46.271c.344,10.339-1.861,21.081-3.339,20.094s-2.53-20.607-2.53-20.607,0-12.229-1.3-25.229S.713,7.91,2,5.188s3.334-1.176,5.312-1.6C10.324,2.764,11.818,1.346,11.926,19.449Z"
            transform="translate(14.961 38.271) rotate(7)"
            fill="#f2ecbe"
            stroke="#707070"
            stroke-width="0.5"
          ></path>
        </g>
        {props.diagnoses && (
          <g id="diagnoses" transform="translate(1019.654 518.089)">
            <g id="abscess" transform="translate(-10.154 177.278)">
              <g
                id="p1"
                transform="translate(8 2)"
                fill="none"
                stroke="#000"
                stroke-width="4"
              >
                <circle cx="10" cy="10" r="10" stroke="none"></circle>
                <circle cx="10" cy="10" r="8" fill="none"></circle>
              </g>
            </g>
            <g id="diastema" transform="translate(38.513)">
              <path
                id="p2"
                d="M1178.17,578.089v52.774"
                transform="translate(-1178.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
              <path
                id="p1-2"
                data-name="p1"
                d="M1178.17,578.089v52.774"
                transform="translate(-1168.167 -578.089)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
            <g id="cracked" transform="translate(23.057 66.181) rotate(175)">
              <path
                id="p1-3"
                data-name="p1"
                d="M0,16.749,1.28,5.8,9.813,8.12,13.336,0"
                fill="none"
                stroke="#000"
                stroke-linecap="square"
                stroke-width="5"
              ></path>
            </g>
            <g id="recession" transform="translate(-12 97.133)">
              <path
                id="p3"
                d="M1111.65,686.936a37.019,37.019,0,0,0,22.037,7.62c13.017,0,26.55-8.5,26.55-8.5"
                transform="translate(-1108.654 -685.06)"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-width="5"
              ></path>
            </g>
          </g>
        )}
        {props.proceedures && (
          <g id="procedures">
            <g id="hyg" transform="translate(1006 570.675)">
              <path
                id="Path_1"
                data-name="Path 1"
                d="M14.775,21.538s4.928-8.332,12.591-8.332A15.189,15.189,0,0,0,39.141,7.387s-4.112,7.469-11.776,7.469S14.775,21.538,14.775,21.538Z"
                transform="translate(2 7)"
                fill="red"
              ></path>
            </g>
            <g id="rctpc" transform="translate(-77 1)">
              <path
                id="rctpc-2"
                data-name="rctpc"
                d="M13.54,8.125,5.65,44.352,2.661,44.3l-1.556.254L-2.447,8.4C-2.447,6.8,13.54,6.523,13.54,8.125Z"
                transform="translate(1108.852 581.319) rotate(0.974)"
                fill="red"
              ></path>
            </g>
            <g id="resection" transform="translate(7)">
              <path
                id="resection-2"
                data-name="resection"
                d="M3.415,66.982,33.023,107"
                transform="translate(1007.536 592.874)"
                fill="none"
                stroke="red"
                stroke-linecap="round"
                stroke-width="5"
                stroke-dasharray="10 8"
              ></path>
            </g>
            <g id="sinuslift" transform="translate(1012.247 591.874)">
              <path
                id="Path_1-2"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 74.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_1-3"
                data-name="Path 1"
                d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
                transform="translate(33.253 91.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-2"
                data-name="p2"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 102.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-4"
                data-name="p1"
                d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
                transform="translate(41.539 85.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_2"
                data-name="Path 2"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 47.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="Path_3"
                data-name="Path 3"
                d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
                transform="translate(-6.985 64.493)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p2-3"
                data-name="p2"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 75.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
              <path
                id="p1-5"
                data-name="p1"
                d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
                transform="translate(-9.747 58.541)"
                fill="red"
                stroke="red"
                stroke-linecap="round"
                stroke-width="1"
              ></path>
            </g>
            <g id="implant" transform="translate(1020.017 604.499)">
              <path
                id="p5"
                d="M806.069,410.878l-1.439-15.719s5.751-8.37,10.143-11.2,2.827-2.77,7.331,0,10.639,10.96,10.639,10.96l-.123,6.876Z"
                transform="translate(-804.63 -381.864)"
                fill="red"
              ></path>
              <path
                id="p4"
                d="M808.545,384.517l-1.673-10.682,24.885-8.6-1.416,11.513Z"
                transform="translate(-804.785 -338.838)"
                fill="red"
              ></path>
              <path
                id="p3-2"
                data-name="p3"
                d="M810.2,366.894l-1.365-10.675,21.046-7.444-1.148,11.536Z"
                transform="translate(-804.919 -305.15)"
                fill="red"
              ></path>
              <path
                id="p2-4"
                data-name="p2"
                d="M811.973,348.918l-.994-10.534,17.078-5.9-1.366,11.1Z"
                transform="translate(-805.067 -271.285)"
                fill="red"
              ></path>
              <path
                id="p1-6"
                data-name="p1"
                d="M826.037,307.555l-13.286,4.7s.421,19.875,6.883,18.857S826.037,307.555,826.037,307.555Z"
                transform="translate(-805.189 -229.264)"
                fill="red"
              ></path>
            </g>
            <g id="bridge" transform="translate(1000.5 506.5)">
              <path
                id="Path_3-2"
                data-name="Path 3"
                d="M0,0H70"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
              <path
                id="Path_1-4"
                data-name="Path 1"
                d="M0,0H70"
                transform="translate(0 83)"
                fill="none"
                stroke="#000"
                stroke-width="15"
              ></path>
            </g>
            <g id="crr" transform="translate(1049.885 651.073) rotate(180)">
              <path
                id="Path_32"
                data-name="Path 32"
                d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
                fill="red"
              ></path>
            </g>
          </g>
        )}
      </g>
    </svg>
  </ToothSvg>
);

export default SvgComponent;
