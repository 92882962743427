import localforage from "localforage";

const metaUpdate = (eventData) => async (dispatch) => {
  const meta = await localforage.getItem("meta");
  dispatch({
    type: "LOAD_ONE",
    context: "meta",
    data: { ...(meta || []), ...eventData },
  });
  return await localforage.setItem("meta", { ...(meta || []), ...eventData });
};

export default {
  update: metaUpdate,
};
