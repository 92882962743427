import React from "react";
import styled from "styled-components";
import { FormControl, Button } from "@mui/material";

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
`;

const RemoveSlotForm = ({ id, removeSlot }) => {
  return (
    <FormContainer>
      <Row>
        {id && (
          <FormControl>
            <Button
              onClick={() => removeSlot(id)}
              variant="contained"
              color="secondary"
            >
              Remove
            </Button>
          </FormControl>
        )}
      </Row>
    </FormContainer>
  );
};

export default RemoveSlotForm;
