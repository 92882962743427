import * as React from "react";
import ToothSvg from "../../components/ToothSvg/ToothSvg";

const SvgComponent = (props) => (
  <ToothSvg
    xmlns="http://www.w3.org/2000/svg"
    width="74.081"
    height="246"
    viewBox="0 0 74.081 246"
    {...props}
  >
    <g id="top6" transform="translate(7.071 112.51)">
      <g id="bounds">
        <line
          id="bound"
          x2="70"
          transform="translate(-4.071 133.49)"
          fill="none"
        ></line>
        <line
          id="bound-2"
          data-name="bound"
          x2="70"
          transform="translate(-4.071 -112.51)"
          fill="none"
        ></line>
      </g>
      <g id="surface" transform="translate(-463.239 -218.26)">
        <path
          id="shape"
          d="M26.674.042c-3.9-.27-6.909.819-10.41,1.63C3.843,4.544-3.265,14.578,1.484,33.088c.95,3.7,3.289,6.539,5.692,9.733,3.96,5.272,9.375,9.416,16.255,8.257,9.111-1.535,15.031.584,17.876-13.283,2.667-13,5.819-36.338-14.633-37.752Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#707070"}
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="0.5"
          fill-rule="evenodd"
        ></path>
        <path
          id="m"
          d="M16.186,50.316a13.063,13.063,0,0,0,7.245.761c1.855-.313,3.578-.474,5.546-.706.394-.046-1.667-1.587-1.954-6.728-.153-2.77,2.586-9.78,0-11.226s-7.451.893-10.344,5.442S15.083,49.852,16.186,50.316Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="ml"
          d="M29.152,50.366c5.782-.649,9.918-1.672,12.154-12.572.148-.724-1.943-.218-3.951-1.592s-1.869-3.961-4.082-3.9c-1.816.043-5.255,1.658-5.255,1.658a6.877,6.877,0,0,1,0,2.626c-.285,2.476-1.131,6.4-1,7.8C27.242,46.635,28.7,50.417,29.152,50.366Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="l"
          d="M41.379,37.44a87.134,87.134,0,0,0,1.575-9.873,53.071,53.071,0,0,0,.256-7.526s-.107.4-3.682.692-10.146-4.337-10.616.5c-.44,4.519,5.8,11.045,7.117,13.521S41.379,37.44,41.379,37.44Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="dl"
          d="M43.214,19.64c-.3-8.664-3.3-16.905-13.326-19.161,0,0-1.757,2.242-1.884,6.742-.04,1.422.855,2.547,1.392,4.5.9,3.289-.287,6.944,1.944,7.251a36.486,36.486,0,0,1,6.1,1.557c2.1.647,5.638-.409,5.638-.409S43.229,20.057,43.214,19.64Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="d"
          d="M26.674.042c-3.9-.27-6.909.819-10.41,1.63-.82.19-2.289.634-2.289.634s-1.483,3.956.91,6.866,5.389,6.287,8.822,6.557,2.724-2.4,4.394-5.8c.56-1.139-.3-1.553,0-4A21.975,21.975,0,0,1,29.834.468S27.9.127,26.674.042Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="db"
          d="M13.928,2.287A19.26,19.26,0,0,0,.505,17.042C.086,19,13.391,24.026,16.886,19.9c1.219-1.437,1.568-6.68,1.568-6.68s-3.394-2.947-4.525-5.681S14.1,2.23,13.928,2.287Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="b"
          d="M.4,17.6a30.613,30.613,0,0,0-.326,6.38,51.4,51.4,0,0,0,1.41,9.107c.074.29,12.99-.819,12.936-5.554-.015-1.357-1.726-6.051-1.726-6.051a16.531,16.531,0,0,1-5.508-.741A23.919,23.919,0,0,1,.4,17.6Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="mb"
          d="M1.484,33.088a16.834,16.834,0,0,0,1.64,4.01A57.179,57.179,0,0,0,7.176,42.82a22.875,22.875,0,0,0,8.46,7.265c.5.235-1.167-3.2-.529-7.7s3.341-7.172,1.8-9.749-4.943-1.53-7.977-.559a30.775,30.775,0,0,1-7.446,1.011Z"
          transform="translate(519.533 294.343) rotate(90)"
          fill={props.surface?.shape.fill || "#f5f5f5"}
          stroke={props.surface?.shape.stroke || "#cecece"}
          fill-rule="evenodd"
        ></path>
        <path
          id="o"
          d="M-28.426-45.135A5.441,5.441,0,0,0-26.4-41.018c2.016,1.379,6.2,1.375,6.2,1.375s-3.28,3.344-2.442,6.323c.37,1.314,2.006,1.915,3.544,2.519,1.29.517,2.468.934,2.468.934a9.847,9.847,0,0,1-2.828.911c-1.151.231-3.29.238-4.344.391a3.535,3.535,0,0,0-1.68.57A2.3,2.3,0,0,0-28-29.651,11.556,11.556,0,0,0-34.066-27.6c-1.156.729-4.634,3.121-4.634,3.121a19.465,19.465,0,0,0-.508-2.889,19.254,19.254,0,0,0-1-2.748,2.3,2.3,0,0,0,1.789-2.25A7.834,7.834,0,0,0-40-37.784a15.294,15.294,0,0,0-3.422-3.453s2.252,1.068,3.719.43a3.552,3.552,0,0,0,2.289-2.82,9.261,9.261,0,0,0-.141-2.68s1.273,2.321,2.506,2.68C-33.85-43.243-28.426-45.135-28.426-45.135Z"
          transform="translate(526.187 352.641)"
          fill={props.surface?.l.fill || "#eaeaea"}
          stroke={props.surface?.l.stroke || "#cecece"}
        ></path>
      </g>
      <g id="crown">
        <path
          id="main"
          d="M61.365,4.143s4.4,8.122,3.637,25.021S53.239,49.578,51.091,49.722s-12.735-.722-17.8-5.3S22.5,48.085,17.846,49,1.428,43.126.3,33.593,3.853,2.664,3.853,2.664,6.625.577,13.66-.442A99.993,99.993,0,0,1,32.251-1.611,105.787,105.787,0,0,1,50.955.426C58.3,1.814,61.365,4.143,61.365,4.143Z"
          transform="translate(-2)"
          fill="#f5f5f5"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.5"
        ></path>
      </g>
      <g id="root" transform="translate(0 11.803)">
        <path
          id="rb3"
          d="M1076.01,295.327a140.377,140.377,0,0,1-3.125-42.439c1.794-24.08-17.173-37.96-18.062-37.825s-1.408,1.4-1.834,1.469a41.283,41.283,0,0,0,.325,7.707,52.677,52.677,0,0,1,1.223,8.945c.313,9.213-3.572,27.835-6.5,38.253s-5.213,3.417,0,18.155a119.838,119.838,0,0,1,16.282,1.9,59.205,59.205,0,0,1,11.691,3.835Z"
          transform="translate(-1016.563 -303.133)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.5"
        ></path>
        <path
          id="rb2"
          d="M1120.78,215.251a2.667,2.667,0,0,1,.23.565c.924,3.025-1.18-.609,1.355,13.2.608,3.312.481,15.4-1.586,28.1s-4.741,22.78-9.892,17.444-8.442-39.75-8.478-44.02c-.081-9.545,6.222-20.26,8.478-20.7S1119.28,212.6,1120.78,215.251Z"
          transform="translate(-1084.763 -303.193)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.5"
        ></path>
        <path
          id="rb1"
          d="M1033,227.663c-4.244-10.108-7.312-17.439-10.533-5.938s-5.082,20.753-2.958,32.766-1.119,39.719-1.119,39.719,3.013-3.084,15.615-4.07c2.877-.225,13.943-.411,13.943-.411l-2.689-8.351s2.046-2.792,1.085-5.712c-1.43-4.347-6.773-10.323-7.9-14.069C1034.68,249.065,1037.25,237.771,1033,227.663Z"
          transform="translate(-1016.563 -303.133)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-linecap="round"
          stroke-width="0.5"
        ></path>
        <path
          id="r3"
          d="M455.733,315.647c1.291,2.384,9.37,2.552,10.18,0a113.982,113.982,0,0,0,0-18.819c-.637-8.31.424-16.755,0-20.454-.446-3.883-2.6-10.408-3.86-4.5-.709,3.313-1.059,14.917-1.929,26.152C459.479,306.356,455.166,314.6,455.733,315.647Z"
          transform="translate(-417.076 -338.963)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r2"
          d="M494.6,301.419c-.644,11.894-2.872,20.746-3.381,20.877-.807.208-3.783-9.639-4.87-20.877-.4-4.158,1.309-20.792,2.945-23.1a.934.934,0,0,1,.442-.327C491.94,276.475,494.845,296.87,494.6,301.419Z"
          transform="translate(-462.108 -363.467)"
          fill="#bcb57f"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
        <path
          id="r1"
          d="M460.616,285.492c-.811-12.152-2.344-15.591-3.441-14.157-.565.738-2.14,4.5-1.981,9.838.131,4.42,1.2,14.233,1.2,19.943,0,11.773-.284,16.327.787,17.31s10.838-.649,10.779-3.5c-.022-1.16-.833-2.452-1.94-4.406a23.252,23.252,0,0,1-2.139-4.838,104.84,104.84,0,0,1-3.26-20.19Z"
          transform="translate(-448.062 -340.177)"
          fill="#f2ecbe"
          stroke="#707070"
          stroke-width="0.5"
        ></path>
      </g>
      {props.diagnoses && (
        <g id="diagnoses" transform="translate(-1.071 -97.788)">
          <g id="abscess" transform="translate(-3 -12)">
            <g
              id="p1"
              transform="translate(21)"
              fill="none"
              stroke="#000"
              stroke-width="4"
            >
              <circle cx="10" cy="10" r="10" stroke="none"></circle>
              <circle cx="10" cy="10" r="8" fill="none"></circle>
            </g>
          </g>
          <g id="diastema" transform="translate(51.667 132.504)">
            <path
              id="p2"
              d="M1178.17,630.863V578.089"
              transform="translate(-1178.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
            <path
              id="p1-2"
              data-name="p1"
              d="M1178.17,630.863V578.089"
              transform="translate(-1168.167 -578.089)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="cracked">
            <path
              id="p1-3"
              data-name="p1"
              d="M1099.67,572.241l1.28-10.947,8.533,2.32,3.523-8.122"
              transform="translate(-1070.5 -433.112)"
              fill="none"
              stroke="#000"
              stroke-linecap="square"
              stroke-width="5"
            ></path>
          </g>
          <g id="recession">
            <path
              id="p1-4"
              data-name="p1"
              d="M1103.98,693.976a91.217,91.217,0,0,1,31.523-5.594c16.918.255,34.853,7.065,34.853,7.065"
              transform="translate(-1105.5 -594.41)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
        </g>
      )}
      {props.proceedures && (
        <g id="procedures">
          <g id="hyg" transform="translate(-4.571 -1.093)">
            <path
              id="Path_1"
              data-name="Path 1"
              d="M14.775,24.429S20.709,14.4,29.938,14.4A18.292,18.292,0,0,0,44.119,7.387s-4.953,8.994-14.181,8.994S14.775,24.429,14.775,24.429Z"
              transform="translate(2 7)"
              fill="red"
            ></path>
          </g>
          <g id="rctpc" transform="translate(-1.571 -0.093)">
            <path
              id="shape2"
              d="M577.366,30.884c-3.772,6.918-4.581,14.555,2.577,21.054,2.855,2.593,7.962-2.412,12.411-1.782,5.636.807,10.968,5.921,16.219,1.782,8.735-6.886,3.824-14.135.438-21.054s-9.353-6.628-13.98-6.62C590.261,24.364,581.138,23.966,577.366,30.884Z"
              transform="translate(-563.264 -22.47)"
              fill="red"
            ></path>
          </g>
          <g id="resection" transform="translate(6.38 -71.169)">
            <path
              id="resection-2"
              data-name="resection"
              d="M3.415,122.287,63.621,66.982"
              transform="translate(-13.318 -75.213)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
              stroke-dasharray="10 8"
            ></path>
          </g>
          <g id="seal" transform="translate(18.192 84.018)">
            <path
              id="seal-2"
              data-name="seal"
              d="M720.54,542.711s26.9-3.412,26.9-8.1c0-2.482-9.012-4.96-16.192-6.557-6.009-1.336-10.249-1.77-10.705-4.081-.97-4.918,23.017-9.033,23.017-9.033"
              transform="translate(-720.511 -514.944)"
              fill="none"
              stroke="red"
              stroke-linecap="round"
              stroke-width="5"
            ></path>
          </g>
          <g id="implant" transform="translate(10.929 -91.475)">
            <path
              id="p5"
              d="M806.3,381.864l-1.668,16.881s6.666,8.989,11.759,12.024,3.278,2.974,8.5,0S837.222,399,837.222,399l-.142-7.384Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p4"
              d="M808.83,365.231,806.872,376.7,836,385.942l-1.657-12.363Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p3"
              d="M810.451,348.775l-1.622,11.464,25,7.994-1.364-12.388Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p2-2"
              data-name="p2"
              d="M812.186,332.484,810.979,343.8l20.74,6.335-1.659-11.916Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
            <path
              id="p1-5"
              data-name="p1"
              d="M829.419,332.9l-16.668-5.053s.528-21.344,8.635-20.25S829.419,332.9,829.419,332.9Z"
              transform="translate(-803 -308.035)"
              fill="red"
            ></path>
          </g>
          <g id="bridge" transform="translate(-3.571 -1.093)">
            <path
              id="Path_3"
              data-name="Path 3"
              d="M0,0H73"
              transform="translate(-3.5 24.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
            <path
              id="Path_1-2"
              data-name="Path 1"
              d="M0,0H73"
              transform="translate(-3.5 104.716)"
              fill="none"
              stroke="#000"
              stroke-width="15"
            ></path>
          </g>
          <g id="crr" transform="translate(17.262 -40.365)">
            <path
              id="Path_32"
              data-name="Path 32"
              d="M12.526,111.421a1.749,1.749,0,0,1-1.24-.514L.515,100.135A1.756,1.756,0,1,1,3,97.654l9.531,9.531,9.531-9.531a1.754,1.754,0,0,1,2.481,2.481L13.767,110.907a1.749,1.749,0,0,1-1.241.514Z"
              fill="red"
            ></path>
          </g>
          <g id="sinuslift" transform="translate(9.676 -104.003)">
            <path
              id="Path_1-3"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 74.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_1-4"
              data-name="Path 1"
              d="M13.314-4.108H8.566V.628H7V-4.108H2.266V-5.683H7V-10.42H8.566v4.737h4.748Z"
              transform="translate(33.253 91.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-3"
              data-name="p2"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 102.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-6"
              data-name="p1"
              d="M7.79-7.264H5.416V-4.9H4.634V-7.264H2.266v-.787H4.634V-10.42h.782v2.368H7.79Z"
              transform="translate(41.539 85.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_2"
              data-name="Path 2"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 47.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="Path_3-2"
              data-name="Path 3"
              d="M2.266-4.108H7.014V.628H8.577V-4.108h4.737V-5.683H8.577V-10.42H7.014v4.737H2.266Z"
              transform="translate(-6.985 64.493)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p2-4"
              data-name="p2"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 75.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
            <path
              id="p1-7"
              data-name="p1"
              d="M2.266-7.264H4.64V-4.9h.782V-7.264H7.79v-.787H5.421V-10.42H4.64v2.368H2.266Z"
              transform="translate(-9.747 58.541)"
              fill="red"
              stroke="red"
              stroke-linecap="round"
              stroke-width="1"
            ></path>
          </g>
        </g>
      )}
    </g>
  </ToothSvg>
);

export default SvgComponent;
