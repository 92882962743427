import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  margin: 1em;
  text-align: center;
  h1 {
    letter-spacing: 1px;
    margin-bottom: 0;
  }
  p {
    margin-top: 0.5em;
  }
`;

export const Container = styled.div`
  display: grid;
  justify-self: center;
  align-self: center;
`;
