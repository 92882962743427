import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { FormControl, Button } from "@mui/material";
import Alert from "@mui/lab/Alert";
import Input from "components/model-form/form/input";
import { SelectUi } from "components/model-form/form/select-ui";
import EuroFeaIcon from "icon/fea-icons/euro";
import ClipFeaIcon from "icon/fea-icons/clipboard";
import FileFeaIcon from "icon/fea-icons/file-text";
import * as Joi from "@hapi/joi";
import { MoreCont, MoreIconRight } from "components/common";
import DeletePop from "components/editor/more-popover/delete";
import DoubleBounce from "components/loaders/double-bounce";
import colors from "library/styled-components/colors";
import {
  createTreatment,
  deleteTreatment,
  updateTreatment,
} from "actions/treatments";
import { ADMIN_NS } from "config";
import { AppContext } from "hooks/context";

const FormContainer = styled.div`
  display: grid;
  margin: 1rem;
  font-size: 0.9em;
  text-align: left;
  width: auto;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 1rem;
  margin-top: 1rem;
`;

const InputContainer = styled.div`
  margin-bottom: 1em;
  display: grid;
  position: relative !important;
  input[type="time"]::-webkit-calendar-picker-indicator {
    background: none;
  }
`;

const subCategoryList = {
  resto: [
    {
      id: "resto-amalgam",
      name: "Restoration Amalgam",
    },
    {
      id: "resto-temporary",
      name: "Restoration Temporary",
    },
  ],
  rct: [
    {
      id: "rct-temporary",
      name: "Temporary",
    },
  ],
  crown: [
    {
      id: "crown-porcelain",
      name: "Crown - Porcelain",
    },
    {
      id: "crown-pfm",
      name: "Crown - PFM",
    },
    {
      id: "crown-metal",
      name: "Crown - Metal",
    },
    {
      id: "bridge-porcelain",
      name: "Bridge - Porcelain",
    },
    {
      id: "bridge-pfm",
      name: "Bridge - PFM",
    },
    {
      id: "bridge-metal",
      name: "Bridge - Metal",
    },
    {
      id: "remove-acrylic",
      name: "Removeable - Acrylic",
    },
    {
      id: "remove-metal",
      name: "Removeable - Metal",
    },
    {
      id: "veneer-porcelain",
      name: "Veneer - Porcelain",
    },
  ],
  hyg: [
    {
      id: "hyg-seal",
      name: "Hygiene Sealant",
    },
    {
      id: "hyg-clean",
      name: "Hygiene - Cleaning",
    },
  ],
  ext: [
    {
      id: "ext-resection",
      name: "Surgery Resection",
    },
    {
      id: "ext-grafting",
      name: "Surgery Grafting",
    },
  ],
};

const validation = {
  Name: Joi.object({ Name: Joi.string().min(5).max(1020).required() }),
  Code: Joi.object({ Code: Joi.string().min(1).max(90).required() }),
  Categories: Joi.object({
    Categories: Joi.string().min(1).max(90).required(),
  }),
  SubCategories: Joi.object({ SubCategories: Joi.optional() }),
  Description: Joi.object({
    Description: Joi.string().min(5).max(1020).required(),
  }),
  Price: Joi.object({ Price: Joi.number().required() }),
};

const defaultForm = {
  Name: "",
  Code: "",
  CategoryId: null,
  Data: {
    SubCategory: null,
    MedCode: null,
  },
  Description: "",
  Price: "",
};

const TreatmentForm = ({ cancelMethod, selectedTreatment }) => {
  const { state, dispatch } = useContext(AppContext);
  const { categories } = state;
  const [form, setForm] = useState(defaultForm);
  const [error, setError] = useState();
  const [submitError, setSubmitError] = useState();
  const [moreOpen, setMoreOpen] = useState(false);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    selectedTreatment &&
      setForm({
        ...selectedTreatment,
        CategoryId: selectedTreatment?.Category?.CategoryId,
        Data: {
          MedCode: selectedTreatment?.Data?.MedCode,
          SubCategory: selectedTreatment?.Data?.SubCategory,
        },
      });
  }, [selectedTreatment]);

  const subCategories =
    subCategoryList &&
    form?.CategoryId &&
    subCategoryList[
      categories.data.find((c) => c.data.CategoryId === form.CategoryId).data
        .Type
    ]?.map((c) => ({
      key: c.id,
      name: c.name,
      value: c.id,
    }));

  const checkForm = (name) => {
    if (validation[name]) {
      const check = validation[name].validate({ [name]: form[name] });
      if (check.error && check.error.message) {
        setError({ ...error, [name]: check.error.message });
      } else {
        setError({ ...error, [name]: null });
      }
    }
  };

  async function createUpdateMethod() {
    setUpdating(true);
    let response = null;
    if (selectedTreatment) {
      response = await updateTreatment(
        ADMIN_NS,
        form,
        selectedTreatment.TreatmentId
      );
    } else {
      response = await createTreatment(ADMIN_NS, form);
    }
    setUpdating(false);
    setForm(defaultForm);
    cancelMethod();
  }

  return (
    <FormContainer>
      {submitError && (
        <InputContainer>
          <Alert severity="error">{submitError}</Alert>
        </InputContainer>
      )}
      {selectedTreatment && (
        <MoreCont>
          <MoreIconRight
            size={24}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setMoreOpen(!moreOpen);
            }}
          />

          <DeletePop
            showPop={moreOpen}
            setShowPop={setMoreOpen}
            removeClick={async (e) => {
              e.stopPropagation();
              e.preventDefault();

              const response = await deleteTreatment(
                ADMIN_NS,
                selectedTreatment.TreatmentId
              );
              response &&
                response.code === "SUCCESS" &&
                dispatch({
                  type: "REMOVE_ITEM",
                  context: "treatments",
                  uuid: { TreatmentId: selectedTreatment.TreatmentId },
                  updating: false,
                });
              setMoreOpen(!moreOpen);
              cancelMethod();
            }}
          />
        </MoreCont>
      )}
      <Input
        onChange={(Name) => setForm({ ...form, Name })}
        onBlur={() => checkForm("Name")}
        InputData={{
          type: "text",
          placeholder: "Please enter treatment name",
          value: form?.Name,
        }}
        LabelData={{
          text: "Name",
          error: error?.Name,
        }}
        icon={<ClipFeaIcon size="16" />}
      ></Input>

      <Input
        onChange={(Code) => setForm({ ...form, Code })}
        onBlur={() => checkForm("Code")}
        InputData={{
          type: "text",
          placeholder: "Please enter Code",
          value: form?.Code,
        }}
        LabelData={{
          text: "Code",
          error: error?.Code,
        }}
        icon={<FileFeaIcon size="16" />}
      ></Input>

      <SelectUi
        onChange={(CategoryId) => setForm({ ...form, CategoryId })}
        InputData={{
          placeholder: "Select Category",
          defaultValue: "Choose",
          disable: error?.CategoryId,
          value: form?.CategoryId,
        }}
        LabelData={{
          text: "Category",
          error: error?.CategoryId,
        }}
        options={
          categories
            ? categories.data.map((c) => ({
                key: c.data.CategoryId,
                name: c.data.Name,
                value: c.data.CategoryId,
              }))
            : []
        }
      />

      <SelectUi
        onChange={(Id) =>
          setForm({
            ...form,
            Data: {
              ...form.Data,
              SubCategory: {
                Type: categories.data.find(
                  (c) => c.data.CategoryId === form.CategoryId
                ).data.Type,
                Id,
                Name: subCategoryList[
                  categories.data.find(
                    (c) => c.data.CategoryId === form.CategoryId
                  ).data.Type
                ]?.find((s) => s.id === Id)?.name,
              },
            },
          })
        }
        InputData={{
          placeholder: "Select Sub Category",
          defaultValue: "Choose",
          disable: error?.SubCategory || !subCategories,
          value: form?.Data?.SubCategory?.Id,
        }}
        LabelData={{
          text: "Sub Category",
          error: error?.CategoryId,
        }}
        options={subCategories || []}
      />

      <SelectUi
        onChange={(MedCode) =>
          setForm({
            ...form,
            Data: {
              ...form.Data,
              MedCode,
            },
          })
        }
        InputData={{
          placeholder: "Select Med 2 Code",
          defaultValue: "NA",
          disable: error?.MedCode,
          value: form?.Data?.MedCode,
        }}
        LabelData={{
          text: "Med 2 Code",
          error: error?.MedCode,
        }}
        options={[
          { key: 0, name: "Not Expensible", value: "NA" },
          { key: 1, name: "A - Crowns", value: "A" },
          { key: 2, name: "B - Veneers / Etched Fillings", value: "B" },
          { key: 3, name: "C - Tip Replacing", value: "C" },
          { key: 4, name: "D - Post and Core Build-ups", value: "D" },
          { key: 5, name: "E - Inlays", value: "E" },
          {
            key: 6,
            name: "F - Endodontics - Root Canal Treatment",
            value: "F",
          },
          { key: 7, name: "G - Periodontal Treatment", value: "G" },
          { key: 8, name: "H - Orthodontic Treatment", value: "H" },
          {
            key: 9,
            name: "I - Surgical Extraction of Impacted Wisdom Teeth",
            value: "I",
          },
          { key: 10, name: "J - Bridgework", value: "J" },
        ]}
      />

      <Input
        onChange={(Description) => setForm({ ...form, Description })}
        onBlur={() => checkForm("Description")}
        InputData={{
          type: "text",
          placeholder: "Please enter Description",

          value: form?.Description,
        }}
        LabelData={{
          text: "Description",
          error: error?.Description,
        }}
        icon={<FileFeaIcon size="16" />}
      ></Input>

      <Input
        onChange={(Price) => setForm({ ...form, Price })}
        onBlur={() => checkForm("Price")}
        InputData={{
          type: "number",
          placeholder: "Please enter Price",
          disable: error?.Price,
          value: form?.Price,
        }}
        LabelData={{
          text: "Price",
          error: error?.Price,
        }}
        icon={<EuroFeaIcon size="16" />}
      ></Input>

      {form && (
        <Row>
          <FormControl>
            <Button
              onClick={() => createUpdateMethod()}
              variant="contained"
              color={"primary"}
            >
              {selectedTreatment ? "Update" : "Create"}
              {updating && <DoubleBounce color={colors.primary} />}
            </Button>
          </FormControl>
          <FormControl>
            <Button
              onClick={() => {
                setForm(defaultForm);
                cancelMethod();
              }}
              variant="contained"
              
            >
              Cancel
            </Button>
          </FormControl>
        </Row>
      )}
    </FormContainer>
  );
};

export default TreatmentForm;
